import React, { useEffect } from 'react';
import Button from '../../comopnents/Button';
import zohologo from '../../assets/images/zohologo.png';
import useZohoTokenURL from '../../hooks/useZohoTokenURL';
import useRemoveZoho from '../../hooks/useRemoveZoho';
import { useSearchParams } from 'react-router-dom';
import useZohoSetToken from '../../hooks/useSetZohoTokenURL';
import { useAuth } from '../../hooks/useAuth';
import ConfirmComponent from '../../comopnents/ConfirmComponent';

const Integrations = () => {
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate: getZohoToken, isLoading } = useZohoTokenURL({
    handleSuccess,
  });
  const { mutate: removeZohoMutate, isLoading: isRemoveZohoLoading } =
    useRemoveZoho();
  const { mutate: setZohoTokenMutate } = useZohoSetToken({
    handleSuccess: handleSetTokenSuccess,
  });

  useEffect(() => {
    if (searchParams?.get('code')) {
      const zohoCode = searchParams.get('code');
      const zohoLocation = searchParams.get('location') || '';
      const accountsServer = searchParams.get('accounts-server') || '';
      setZohoTokenMutate({
        code: zohoCode,
        location: zohoLocation,
        accounts_server: accountsServer,
      });
    }
  }, [searchParams]);

  function handleSetTokenSuccess() {
    setSearchParams('');
  }

  function handleSuccess(data) {
    if (data?.data) {
      window.open(data?.data);
    }
  }

  return (
    <div className="w--full h--full integrationPage">
      <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
        Integrations
      </h4>
      <div className="h--full w--full bg--white box-shadow--xs p--md radius--sm overflow--auto d--flex flex--column gap--lg integrationPageBox">
        <div className="w--full d--flex gap--md">
          <div className="d--grid grid--4 w--full gap--lg">
            <div className="w--full bg--white box-shadow--xs p--lg radius--sm d--flex flex--column gap--md">
              <div className="d--flex gap--lg w--full align-items--start">
                <div className="w--full w-max--75">
                  <img src={zohologo} alt="LogoZoho" />
                </div>
                <div className="d--flex flex--column gap--xs w--full border-bottom--black-100 p-b--sm ">
                  <h4 className="font--md font--600     d--flex align-items--center justify-content--between ">
                    Zoho
                    <div className="d--flex align-items--center w-min--8 w-max--8 h-min--8 w-max--8 bg--success radius--full"></div>
                  </h4>
                </div>
              </div>
              <div className="w--full d--flex justify-content--end flex--column gap--md">
                <p className="font--sm text--grey">
                  It is a long established fact that a reader will be distracted
                  by the readable content.
                </p>
                <div className="w--full d--flex justify-content--end">
                  <div className="w-min--120 d--flex gap--md align-items--center">
                    {user?.is_zoho_configure === 1 && (
                      <ConfirmComponent
                        label="Please confirm?"
                        confirmCallback={() => removeZohoMutate()}
                      >
                        <Button
                          type="button"
                          variant="danger"
                          color="white"
                          btnClasses="btn white--space-none"
                          size="sm"
                        >
                          {isRemoveZohoLoading
                            ? 'Please wait..'
                            : 'Remove Zoho'}
                        </Button>
                      </ConfirmComponent>
                    )}
                    {user?.is_zoho_configure === 1 && (
                      <Button
                        type="button"
                        variant="primary"
                        color="white"
                        btnClasses="btn"
                        disabled={true}
                        size="sm"
                      >
                        Configured
                      </Button>
                    )}
                    <Button
                      type="button"
                      variant="primary"
                      color="white"
                      btnClasses="btn white--space-none"
                      onClick={getZohoToken}
                      size="sm"
                    >
                      {isLoading
                        ? 'Please wait'
                        : user?.is_zoho_configure === 1
                          ? 'Re-Configure'
                          : 'Configure'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
