import { agencyList } from '../../api';
import { useQuery } from '@tanstack/react-query';
import { useAlert } from '../useAlert';

export default function useAgencyListing() {
  const { showAlert } = useAlert();
  const Request = useQuery({
    queryFn: () => agencyList({ page: 1 }),
    queryKey: ['agencyList'],
    select: (data) => data?.data?.data || [],
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
