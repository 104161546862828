import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import FormSelect from '../../FormSelect';
import useIcons from '../../../assets/icons/useIcons';
import useDidList from '../../../hooks/useDidList';
import { useAuth } from '../../../hooks/useAuth';
import useSetDefaultDid from '../../../hooks/useSetDefaultDID';
import { useCallSummary } from '../../../hooks/useCallSummary';
import MultiCallImage from '../../../assets/images/web/group-call-ico.png';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const RenderDIDItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.did_number}
      value={item?.did_number}
    >
      {item.did_number}
    </option>
  );
};

const DialpadHeader = ({
  setshow,
  isCallExist,
  callActiveKey,
  setCallActiveKey,
  callSessions = [],
  action,
  setaction,
}) => {
  const { socket = {} } = useContext(SipSocketContext);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    CrossRoundIcon,
    MinimizeRoundIcon,
    UserIcon,
    VoicemailGroupIcon,
    VoicemailIcon,
    UsersIcon,
    MobileIcon,
    CallIcon,
    ArrowRightIcon,
    ExpendIcon,
  } = useIcons();
  const { mutate, data: didListData } = useDidList();
  const { mutate: setDefaultDidMutate } = useSetDefaultDid();
  const { active_number: oldActiveNumber } = useCallSummary();

  const { user } = useAuth();
  const [activeDid, setActiveDid] = useState('');

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    if (user?.caller_id) {
      setActiveDid(user.caller_id);
    }
  }, [user?.caller_id]);

  const { _status = '', _uaSessions } = socket;
  const isSipRegistered = _status === 'registered';

  const forwardIconLookup = {
    EXTENSION: (
      <span>
        <UserIcon />
      </span>
    ),

    VOICEMAIL: (
      <span>
        <VoicemailIcon />
      </span>
    ),
    NUMBER: (
      <span>
        <UserIcon />
      </span>
    ),
    DEPARTMENT: (
      <span>
        <UsersIcon />
      </span>
    ),
    IVR: (
      <span>
        <MobileIcon />
      </span>
    ),
    VOICEMAILGROUP: (
      <span>
        <VoicemailGroupIcon />
      </span>
    ),
  };

  const viaDID = useMemo(() => {
    return (
      _uaSessions?.[callActiveKey]?._request?.headers?.['X-Did']?.[0]?.raw ??
      null
    );
  }, [callActiveKey, _uaSessions]);

  const forwardName = useMemo(() => {
    return (
      _uaSessions?.[callActiveKey]?._request?.headers?.['X-Forwardname']?.[0]
        ?.raw ?? null
    );
  }, [callActiveKey, _uaSessions]);

  const forwardType = useMemo(() => {
    return (
      _uaSessions?.[callActiveKey]?._request?.headers?.['X-Forwardtype']?.[0]
        ?.raw ?? null
    );
  }, [callActiveKey, _uaSessions]);

  const connectedCalls = useMemo(() => {
    return (
      callSessions.filter((item) =>
        ['connected', 'mute'].includes(item?._status),
      ) || []
    );
  }, [callSessions]);

  const holdCalls = useMemo(() => {
    return callSessions.filter((item) => item?._status === 'hold') || [];
  }, [callSessions]);

  const ringingCalls = useMemo(() => {
    return (
      callSessions.filter((item) =>
        ['ringing', 'connecting'].includes(item?._status),
      ) || []
    );
  }, [callSessions]);

  return (
    <>
      {!isCallExist && oldActiveNumber ? (
        <div className="w--full p--md text--c">
          <h3 className="font--600 font--md ">Call Ended</h3>
        </div>
      ) : (
        !isCallExist &&
        !oldActiveNumber && (
          <div className="d--flex align-items--center justify-content--between">
            <div
              className={`bg--${
                isSipRegistered ? 'success' : 'secondary'
              } radius--full p-l--sm  p-r--sm`}
            >
              {isSipRegistered ? 'Online' : 'Offline'}
            </div>
            <div className="d--flex align-items--center">
              <h5 className="font--600 font--sm w-min--75 w-max--75 w--full text--r">
                Call Using
              </h5>
              <FormSelect
                options={didListData?.data?.data || []}
                renderOption={RenderDIDItem}
                value={activeDid}
                onChange={(e) => {
                  setDefaultDidMutate({ did_number: e.target.value });
                  // setActiveDid(e.target.value);
                }}
                extraClasses="border--0 bg--transparent text--white"
                disabled={isCallExist}
              />
            </div>
          </div>
        )
      )}
      {isCallExist && sessionStorage.getItem('cid') && (
        <div
          className="d--flex gap--xs c--pointer position--absolute top--2 right--28 bg--white-400 radius--full w-min--25 h-min--25 d--flex align-items--center justify-content--center"
          onClick={() => {
            const contactInfo =
              searchParams && searchParams?.get('x-cn')
                ? searchParams?.get('x-cn')
                : null;
            navigate(
              `contacts?contactId=${sessionStorage.getItem(
                'cid',
              )}&showDialer=true&x-cn=${contactInfo}`,
            );
          }}
        >
          <ExpendIcon width={16} height={16} />
        </div>
      )}

      <div
        className="d--flex gap--xs c--pointer position--absolute top--0 right--0"
        onClick={() => setshow(false)}
      >
        {isCallExist ? (
          <MinimizeRoundIcon width={30} height={30} />
        ) : (
          <CrossRoundIcon width={30} height={30} />
        )}
      </div>

      {callSessions?.length > 1 && action !== 'callListing' && (
        <div
          className="w--full bg--white-400 radius--sm h-min--28 d--flex justify-content--between align-items--center p-l--sm p-r--sm c--pointer"
          onClick={() => setaction('callListing')}
        >
          <div className="p-l--md p-r--md h-min--20 d--flex align-items--center justify-content--center bg--danger radius--lg ">
            Ringing | {ringingCalls?.length || 0}
          </div>
          <div className="p-l--md p-r--md h-min--20 d--flex align-items--center justify-content--center bg--success radius--lg ">
            Connected | {connectedCalls?.length || 0}
          </div>
          <div className="p-l--md p-r--md h-min--20 d--flex align-items--center justify-content--center bg--warning radius--lg ">
            Hold | {holdCalls?.length || 0}
          </div>
          <div
            className="c--pointer w-max--25 text--white d--flex"
            data-tooltip="View"
            tooltip-position="left"
          >
            <ArrowRightIcon width={20} />
          </div>
        </div>
      )}

      {isCallExist && callActiveKey && (
        <div className="d--flex align-items--center justify-content--between gap--sm">
          {viaDID && (
            <div className="d--flex gap--sm align-items--center">
              <div className="font--sm font--500">Via:</div>
              <div className="font--sm">{viaDID}</div>
            </div>
          )}
          {forwardName || forwardType ? (
            forwardType && forwardType !== 'EXTENSION' ? (
              <div className="d--flex gap--sm align-items--center">
                {forwardType && (
                  <div className="font--sm font--500">
                    {forwardIconLookup?.[forwardType] || <UserIcon />}
                  </div>
                )}
                {forwardName && <div className="font--sm">{forwardName}</div>}
              </div>
            ) : null
          ) : null}
        </div>
      )}
    </>
  );
};

export default DialpadHeader;
