import React from 'react';
import Button from '../Button';

const Tabbing = ({
  tab,
  setTab = () => null,
  tabsLabels = [],
  extraClasses = '',
}) => {
  if (!tabsLabels.length) return null;
  return (
    <div
      className={`w--full d--flex align-items--center gap--sm justify-content--center w-max--300 bg--white radius--xl h-max--36 ${extraClasses}`}
    >
      {tabsLabels.map((label) => {
        return (
          <Button
            key={label?.value}
            variant={`${tab === label?.value ? 'secondary btn' : 'white btn'}`}
            color={`${tab === label?.value ? 'white' : 'primary'}`}
            onClick={() => setTab(label?.value)}
            disabled={label?.isDisabled ?? false}
            size="sm"
            btnClasses="radius--xl white--space-none"
            type="button"
          >
            {label?.label}
          </Button>
        );
      })}
    </div>
  );
};

export default Tabbing;
