import React, { useEffect } from 'react';

const GreetingPlayer = ({ file = null }) => {
  useEffect(() => {
    const audioPlayer = document.getElementById('greetingAudioPlayer');
    if (file) {
      const reader = new FileReader();

      reader.onload = function (event) {
        audioPlayer.src = event.target.result;
      };

      reader.readAsDataURL(file);
    }
  }, [file]);
  return (
    <div>
      {/* <div>{file?.name}</div> */}
      <audio id="greetingAudioPlayer" src="" controls className='h-max--40'></audio>
    </div>
  );
};

export default GreetingPlayer;
