import React from 'react';
import Button from '../../comopnents/Button';
import useIcons from '../../assets/icons/useIcons';
import { useNavigate } from 'react-router-dom';
import FormInput from '../../comopnents/FormInput';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useForgotPassword from '../../hooks/useForgotPassword';

const initialValues = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
});

export default function ForgotPassword() {
  const { BackRoundIcon } = useIcons();
  const navigate = useNavigate();
  const { mutate: forgotPasswordMutate, isLoading: forgotPasswordLoad } =
    useForgotPassword();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  async function onSubmit(values) {
    forgotPasswordMutate({ email: values?.email });
  }
  return (
    <div className="w--full  h--full d--flex align-items--center justify-content--center loginPage">
      <div className="radius--md  gap--xl w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box-shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
        <div className="w--full">
          <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center">
            <span className="d--flex m-b--lg w--full justify-content--between">
              <Button
                onClick={() => navigate(-1)}
                btnClasses="btn bg--primary-100 w-max--40 h-max--40"
                type="button"
                variant="primary"
                icon={<BackRoundIcon width={40} height={40} />}
                color="primary"
                rounded
              />
              <h3 className="font--2xl text--primary">Forgot Password ?</h3>
              <span></span>
            </span>

            <form
              className="w--full d--flex flex--column gap--lg"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="text"
                    label="Enter your email address, we will send you a recovery email."
                    placeholder="example@gmail.com"
                    error={errors?.email?.message}
                    height="40"
                    autoFocus={true}
                  />
                )}
              />

              <div className="form-group w--full">
                <Button
                  type="submit"
                  btnClasses="btn"
                  size="md"
                  variant="primary"
                  color="white"
                  disabled={forgotPasswordLoad || isSubmitting}
                >
                  {forgotPasswordLoad || isSubmitting
                    ? 'Please wait'
                    : 'Submit'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
