import React, { useEffect } from 'react';
import Button from '../../../comopnents/Button';
import useIcons from '../../../assets/icons/useIcons';
// import productbanner from '../../../assets/images/web/productbanner.png';
import productbanner from '../../../assets/images/web/phoneSystem.svg';
import { NavLink } from 'react-router-dom';

const Features = () => {
  const {
    NextArrowIcon,
    PhoneSystemIcon,
    ContactCenterIcon,
    SalesServicesIcon,
    CustomerEngagementIcon,
    AnalyticsIcon,
    AutomationStarIcon,
    SquareFillIcon,
    SquareFill2Icon,
    CallRecordIcon,
    TranferIcon,
    CardIcon,
    SupportIcon,
    DocumentIcon,
    AwardIcon,
    MenuIcon,
    MessageIcon,
    VoicemailIcon,
    CallIcon,
    TickFillIcon,
    MobileIcon,
  } = useIcons();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="productPage w--full">
      <div className="productBanner section p-t--50 p-b--50 position--relative">
        <div className="container">
          <div className="w--full d--flex align-items--center gap--3xl flex-column--xs flex-column--sm ">
            <div className="w--full gap--lg d--flex flex--column position--relative">
              <div className="circleBox position--absolute">
                <div className="text--success position--absolute animate mover">
                  <div className="border-full--success w-min--50 h-min--50 w-max--50 radius--full position--absolute mover animate"></div>
                </div>
                <div className="bg--success-100 w-min--50 h-min--50 w-max--50 radius--full position--absolute bounce--right animate"></div>
              </div>
              <h2 className="font--52 font--700 line-height--1-dot-2">
                The most intelligent business phone system
              </h2>
              <p className="font--md text--grey line-height--1-dot-5">
                Set up your new business phone in minutes, plus unlock Ai tools
                like live transcriptions and Ai-curated call summaries.
              </p>
              <div className="w-max--120 w--full">
                <NavLink
                  to={'/pricing'}
                  className="w-max--120 w--full p-l--sm "
                >
                  <Button
                    type="submit"
                    size="md"
                    variant="primary"
                    color="white"
                    btnClasses="btn white--space-none"
                  >
                    Get Started
                  </Button>
                </NavLink>
              </div>
              {/* <div className="d--flex w--full gap--xl m-t--md">
                <div className="w--full d--flex flex--column gap--sm">
                  <div className="font--md font--700">100+</div>
                  <div className="font--14 font--400">Total Customer</div>
                </div>
                <div className="w--full d--flex flex--column gap--sm">
                  <div className="font--md font--700">250+</div>
                  <div className="font--14 font--400">Real Time Customer</div>
                </div>
                <div className="w--full d--flex flex--column gap--sm">
                  <div className="font--md font--700">100%</div>
                  <div className="font--14 font--400">
                    Customer Satisfaction
                  </div>
                </div>
              </div> */}
            </div>
            <div className="w--full d--flex justify-content--end">
              <img
                src={productbanner}
                alt="productbanner"
                className="w--full w-max--500"
              />
            </div>
          </div>
        </div>
        <div className="bigCircle position--absolute">
          <div className="bg--primary-100 w-min--200 h-min--200 w-max--200 radius--full position--absolute mover animate"></div>
        </div>
      </div>
      <div className="impactFeatures section position--relative overflow--hidden">
        <div className="squareIcon right bottom position--absolute text--purple animate bounce--right">
          <SquareFillIcon width={200} height={200} />
        </div>
        <div className="container">
          <div className="w--full d--flex flex--column gap--xl">
            <div className="w--full  d--flex   justify-content--center ">
              <div className="w--full w-max--800 gap--lg d--flex flex--column">
                <h2 className="font--42 font--700 line-height--1-dot-2 text--c">
                  High Impact Features
                </h2>
                <p className="font--md text--grey line-height--2 text--c">
                  Account comes with more than standard features to streamline
                  communications and help you provide outstanding customer
                  service. Premium features are included in the Plus and Pro
                  plans
                </p>
              </div>
            </div>
            <div className="w--full d--grid gap--lg gridSystem z-index--9 bg--white">
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <PhoneSystemIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Account Management</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  We haven’t forgotten you’ve got a business to run. Our
                  user-friendly design allows you to configure and manage more
                  than 50 Nextere Voice features with a few simple clicks.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <ContactCenterIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Analog Phones</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  If you have analog phones, you can use them with Nextere
                  Voice's VoIP service. Simply connect any traditional analog
                  telephone with Nextere Voice using our Analog Telephone
                  Adapter (ATA).
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <SalesServicesIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Call Analytics</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Pro users will see an account-wide snapshot of caller data
                  with new and returning call break downs. Measure your calls
                  over time by direction, duration, and time of day to track
                  your top performers and busiest hours.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <AutomationStarIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Call Forwarding</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Call Forwarding lets you direct calls to one or many phone
                  numbers, including your home, office, mobile or any phone
                  worldwide. Nextere Voice even allows you to forward based on
                  Caller ID or the time of day a call comes in.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <AnalyticsIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Call Handling Rules</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Using Call Handling Rules, you can route incoming calls to a
                  single destination or different destinations based on the day
                  and time or the Caller ID of the call received.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <CallIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Call Logs</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  View all incoming, outgoing, fax, and missed calls to your
                  Nextere Voice number(s). All calls are completely trackable
                  and filterable to provide you with an accurate, historical
                  account of your business phone communications.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <CallRecordIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Call Recording</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Call recording is included for Pro users and available for
                  other users at just $7.99 a month. Set up and manage your call
                  recordings in your Nextere Voice online account settings.
                  Simply choose between recording All Calls, All Incoming Calls,
                  All Outbound Calls, or a Custom setup.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <TranferIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Call Transfer</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Call transfer is an in-call feature that lets you easily
                  transfer incoming calls from your phone to another phone
                  number or extension. Simply press *2 to transfer a phone call
                  and dial the number you’d like to send it to.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <CardIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Caller ID</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Assign personalized call handling rules for callers (based on
                  their Caller ID) to send them to personalized messages,
                  numbers, voicemail, and more.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <CustomerEngagementIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Click-to-Call</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Embed a little code on your website that'll give customers a
                  quick way to contact your business by only entering their own
                  number. Nextere Voice will then make an outbound call to the
                  customer’s phone number and connect them directly with your
                  business.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <DocumentIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Application Integration</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  For Pro users, Nextere Voice includes an Application
                  Integration tool to enhance business performance across all
                  facets of your business. We offer screen pops, click-to-dial,
                  and call tracking - all from within your browser
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <SupportIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Expert Support</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Nextere Voice's support isn't just there to assist when you're
                  having a problem. Our support agents are fully trained on the
                  Nextere Voice platform and will walk you through setting up
                  any one of our platforms 50+ features.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <AwardIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Greetings</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Greetings let you personalize your Nextere Voice service with
                  audio greetings, messages, music, menus, and voices by
                  uploading your own audio files. Supported file formats for
                  greetings include wav, mp3, and gsm.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <MenuIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Menus</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Nextere Voice Menus are the initial audio greeting telling
                  callers which key to press to reach your phones, groups, or
                  voicemail ("Press 1 for sales..."). Set up your menu with a
                  customized recording giving important information such as
                  business hours, company directory.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <MessageIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Text Messaging (SMS)</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  SMS Messages (text messages) sent to your Nextere Voice number
                  can be received and displayed in your account inbox. Forwarded
                  SMS messages will display on your cell phone just like any
                  regular SMS.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md gridItem">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <VoicemailIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600">Voicemail</h5>
                <p className="font--sm text--grey line-height--2 h-min--180">
                  Nextere Voice lets you listen to voicemail messages directly
                  in your online account, have them sent to your email address
                  as a .mp3 file, or transcribed into text so you can read them.
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="squareIcon left bottom position--absolute text--success animate mover">
          <SquareFill2Icon width={200} height={200} />
        </div>
      </div>
      <div className="bg--contrast p-t--80 p-b--80">
        <div className="container">
          <div className="d--flex justify-content--center">
            <div className="w--full d--flex flex--column justify-content--center align-items--center w-max--900 ">
              <h2 className="font--42 font--700 line-height--1-dot-5 text--c p-b--xl">
                Market with Confidence your <br></br>Nextere Voice Voice
                Experience
              </h2>

              <div className="w--full radius--sm p--xl d--flex  gap--2xl gridItem">
                <div className="w-min--100 h-min--100 w-max--100 h-max--100 bg--white radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <MobileIcon width={60} height={60} />
                </div>
                <div className="d--flex flex--column gap--sm justify-content--center ">
                  <h5 className="font--lg font--600">
                    No more using a personal number for business.
                  </h5>
                  <p className="font--md text--grey line-height--2 ">
                    Business phone numbers create a more professional impression
                    and give you more control over customer relationships.
                  </p>
                </div>
              </div>
              <div className="w--full radius--sm p--xl d--flex  gap--2xl gridItem">
                <div className="w-min--100 h-min--100 w-max--100 h-max--100 bg--white radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <CardIcon width={60} height={60} />
                </div>
                <div className="d--flex flex--column gap--sm justify-content--center ">
                  <h5 className="font--lg font--600">
                    Enterprise features at a fraction of the cost.
                  </h5>
                  <p className="font--md text--grey line-height--2 ">
                    Greetings, Automated Attendants, and Hold Music provides
                    callers with a first-class experience, even if you are a
                    one-person show.
                  </p>
                </div>
              </div>
              <div className="w--full radius--sm p--xl d--flex  gap--2xl gridItem">
                <div className="w-min--100 h-min--100 w-max--100 h-max--100 bg--white radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <AwardIcon width={60} height={60} />
                </div>
                <div className="d--flex flex--column gap--sm justify-content--center ">
                  <h5 className="font--lg font--600">
                    No implementation headaches
                  </h5>
                  <p className="font--md text--grey line-height--2 ">
                    Start communicating immediately after choosing our inventory
                    of over 20 million phone numbers. Use your mobile device,
                    PC, or IP desk phone.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
