import React from 'react';
import useIcons from '../../../../assets/icons/useIcons';

function PlanDetails({ plan_duration = 1, PlanDetails = {} }) {
  const { TickFillIcon } = useIcons();

  return (
    <div className="w--full">
      <div className="d--flex w--full justify-content--between p-t--xl p-b--xl PlanDetails">
        <div className="d--flex flex--column gap--sm p-t--xl priceBoxItem w--full">
          <h4 className="font--md font--700 ">
            {plan_duration === '1' ? (
              <>
                ${PlanDetails?.monthly_cost} /<small>mo</small>
              </>
            ) : (
              <>
                ${PlanDetails?.yearly_cost} /<small>yr</small>
              </>
            )}
          </h4>

          <h4 className="font--xl font--600 text--primary d--flex flex--column gap--sm">
            {PlanDetails.plan_name}
            <div className="font--md font--400 m-b--xl  text--grey">
              {PlanDetails.description}
            </div>
          </h4>
          <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
            <div className="text--success">
              <TickFillIcon width={20} height={20} />
            </div>
            {PlanDetails?.number_of_dids_text ?? ''}
          </p>
          <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
            <div className="text--success">
              <TickFillIcon width={20} height={20} />
            </div>
            {PlanDetails?.number_of_member_text ?? ''}
          </p>
          <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
            <div className="text--success">
              <TickFillIcon width={20} height={20} />
            </div>
            {PlanDetails?.free_calls_text ?? ''}
          </p>
          <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
            <div className="text--success">
              <TickFillIcon width={20} height={20} />
            </div>
            {PlanDetails?.free_sms_text ?? ''}
          </p>
          <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
            <div className="text--success">
              <TickFillIcon width={20} height={20} />
            </div>
            {PlanDetails?.number_of_member_text ?? ''}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PlanDetails;
