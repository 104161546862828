import React, { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import FormInput from '../../../../comopnents/FormInput';
import FormSelect from '../../../../comopnents/FormSelect';
import Switch from '../../../../comopnents/Switch';
import { businessHours } from '../../CallForwarding/constants';
import useCountryList from '../../../../hooks/useCountryList';
import ForwardToInput from '../../../../comopnents/ForwardToInput';

const forwardingOptions = [
  'Extension',
  'Number',
  'IVR',
  'Voicemail',
  'Department Voicemail',
  'Hangup',
];

const RenderTimeZoneItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.zoneName}
      value={item.zoneName}
    >
      {item.zoneName}
    </option>
  );
};

const RenderCountryItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.isoCode}
      value={item.isoCode}
    >
      {item.name}
    </option>
  );
};

const OperationalHours = ({
  watch,
  control,
  errors,
  setValue,
  register,
  memberListAll,
  greetingList,
  ringGroupListData,
  ivrListData,
  greetingListLoad = false,
  memberListLoad = false,
  ringGroupListLoad = false,
  ivrListLoad = false,
}) => {
  const countries = useCountryList();
  const [readView, setReadView] = useState(true);
  const [hourEnableError, setHourEnableError] = useState('');

  const selectedCountry = watch('business_hours_operations.country');
  const timezones = selectedCountry
    ? countries?.find((country) => country.isoCode === selectedCountry)
        ?.timezones
    : [];

  const isOperationalHourEnabled = useMemo(
    () => watch('business_hour_type') === 'custom',
    [watch('business_hour_type')],
  );

  return (
    <form className="w--full h--full">
      <div className="w--full d--flex gap--lg align-items--center m-b--sm h-min--40 justify-content--between">
        <label className="label--control font--sm m-b--0 text--grey d--flex align-items--center w--full white--space-none">
          Do you want to set operational hours ?
        </label>
        <div className="w--full w-max--300 d--flex gap--md">
          <div className="d--flex align-items--center gap--sm ">
            <input
              {...register('business_hour_type')}
              className="radio"
              id="enable_operational_hours_no"
              type="radio"
              value="24h"
              checked={watch('business_hour_type') === '24h'}
            />
            <label
              htmlFor="enable_operational_hours_no"
              className="font--sm c--pointer"
            >
              24 Hrs
            </label>
          </div>
          <div className="d--flex align-items--center gap--sm ">
            <input
              {...register('business_hour_type')}
              className="radio"
              id="enable_operational_hours_yes"
              value="custom"
              type="radio"
              checked={watch('business_hour_type') === 'custom'}
            />
            <label
              htmlFor="enable_operational_hours_yes"
              className="font--sm c--pointer"
            >
              Custom
            </label>
          </div>
          {watch('business_hour_type') === 'custom' && (
            <div
              className="d--flex align-items--center gap--sm c--pointer text--danger font--600"
              onClick={() => setReadView((prev) => !prev)}
            >
              {readView ? 'Edit Hours' : 'View Hours'}
            </div>
          )}
        </div>
      </div>

      {isOperationalHourEnabled && (
        <div className="d--flex flex--column gap--md">
          <div className="w--full">
            <Controller
              name="business_hours_operations.country"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  options={countries || []}
                  renderOption={RenderCountryItem}
                  placeholder="Please select country"
                  label="Country"
                  error={errors?.business_hours_operations?.country?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="business_hours_operations.timezone"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  options={timezones || []}
                  renderOption={RenderTimeZoneItem}
                  placeholder="Please select timezone"
                  label="Timezone"
                  error={errors?.business_hours_operations?.timezone?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <h5 className="font--600 font--sm m-b--md">Set Business Hour</h5>
            <div className="d--flex flex--column w--full gap--lg">
              {businessHours &&
                businessHours.map(({ label, id }) => {
                  return (
                    <div className="w--full d--flex gap--md align-items--center">
                      <div className="w--full d--flex gap--xs font--600 font--sm">
                        {readView ? (
                          <div></div>
                        ) : (
                          <Controller
                            name={`business_hours_operations.custom_hours.${id}.open`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <Switch
                                  {...field}
                                  id={`business_hours_operations.custom_hours.${id}.open`}
                                  disabled={readView}
                                  onChange={(e) => {
                                    if (!e.target.checked) {
                                      setValue(
                                        `business_hours_operations.custom_hours.${id}.start`,
                                        '',
                                      );
                                      setValue(
                                        `business_hours_operations.custom_hours.${id}.end`,
                                        '',
                                      );
                                    }
                                    field.onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        )}
                        {label}
                      </div>
                      <div className="w--full d--flex gap--sm align-items--center">
                        <label className="font--sm font--400 text--grey">
                          From
                        </label>
                        <Controller
                          name={`business_hours_operations.custom_hours.${id}.start`}
                          control={control}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              type="time"
                              error={
                                errors?.business_hours_operations
                                  ?.custom_hours?.[id]?.start?.message
                              }
                              disabled={readView}
                            />
                          )}
                        />
                      </div>
                      <div className="w--full d--flex gap--sm align-items--center">
                        <label className="font--sm font--400 text--grey">
                          To
                        </label>
                        <Controller
                          name={`business_hours_operations.custom_hours.${id}.end`}
                          control={control}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              type="time"
                              error={
                                errors?.business_hours_operations
                                  ?.custom_hours?.[id]?.end?.message
                              }
                              disabled={readView}
                            />
                          )}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="d--flex flex--column  gap--md w--full m-t--sm">
            <label className="label--control font--sm font--500 m-b--xs  text--primary w-min--150">
              During closed hours, forward calls to
            </label>
            <div className="w--full">
              <ForwardToInput
                control={control}
                register={register}
                watch={watch}
                forwardOptions={forwardingOptions}
                forward_type_key="business_hours_operations.closed_business_hour.type"
                forward_value_key="business_hours_operations.closed_business_hour.value"
                memberListData={memberListAll}
                departmentList={ringGroupListData?.data?.data || []}
                ivrList={ivrListData?.data?.data || []}
                greetingList={greetingList}
                setValue={setValue}
                greetingListLoad={greetingListLoad}
                memberListLoad={memberListLoad}
                ringGroupListLoad={ringGroupListLoad}
                ivrListLoad={ivrListLoad}
              />
              <label
                className={`label--control font--sm font--500 m-b--xs text--danger`}
              >
                {errors?.business_hours_operations?.closed_business_hour?.type
                  ?.message ||
                  errors?.business_hours_operations?.closed_business_hour?.value
                    ?.message}
              </label>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default OperationalHours;
