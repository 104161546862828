import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import useIcons from '../../assets/icons/useIcons';
import { USER } from '../../helpers/functions';
import { useAuth } from '../../hooks/useAuth';

const CallCenter = () => {
  const { AudioSettings, DispositionIcon, ScriptsIcon, BlacklistIcon } =
    useIcons();
  const { user } = useAuth();
  const callCenterPageTabs = [
    {
      id: 1,
      label: 'Black list',
      icon: BlacklistIcon,
      path: '/call-center/blacklist',
      access: [USER],
    },
    {
      id: 2,
      label: 'Scripts',
      icon: ScriptsIcon,
      path: '/call-center/scripts',
      access: [USER],
    },
    {
      id: 3,
      label: 'IP White List',
      icon: AudioSettings,
      path: '/call-center/ipwhitelist',
      access: [USER],
    },
    {
      id: 4,
      label: 'Disposition',
      icon: DispositionIcon,
      path: '/call-center/disposition',
      access: [USER],
    },
    {
      id: 8,
      label: 'Leads',
      icon: DispositionIcon,
      path: '/call-center/leads',
      access: [USER],
    },
    // {
    //   id: 6,
    //   label: 'Campaign',
    //   icon: DispositionIcon,
    //   path: '/call-center/campaign',
    //   access: [USER],
    // },
    // {
    //   id: 7,
    //   label: 'Queue',
    //   icon: DispositionIcon,
    //   path: '/call-center/queue',
    //   access: [USER],
    // },
  ];
  return (
    <div className="w--full h--full d--flex gap--md callPage">
      <div className="w-min--200 w-max--200 w--full flex--column d--flex">
        <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
          Settings
        </h4>
        <div className="sideTab w--full h--full w-min--200 w-max--200 bg--white box-shadow--xs radius--sm p--md d--flex flex--column gap--md">
          {callCenterPageTabs &&
            callCenterPageTabs.map(
              ({ id, label, icon: Icon, path, access }, index) => {
                if (![user?.role_id]?.find((role) => access?.includes(role))) {
                  return <React.Fragment key={index}></React.Fragment>;
                }
                return (
                  <NavLink
                    to={path}
                    key={id}
                    data-link-hover
                    className={({ isActive }) =>
                      `${isActive ? 'bg--primary-100' : ''
                      }  d--flex align-items--center justify-content--start text--black  d--flex gap--sm p--sm radius--sm h-min--36 font--500`
                    }
                  >
                    <Icon width={18} /> {label}
                  </NavLink>
                );
              },
            )}
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default CallCenter;
