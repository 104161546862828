import { useEffect, useRef, useState } from 'react';
import useIcons from '../../assets/icons/useIcons';
import moment from 'moment';
import Spinner from '../Spinner';

function parseTime(timeString) {
  if (typeof timeString !== 'string') {
    return 0;
  }
  const parts = timeString.split(':');
  const secondsParts = parts[2].split(',');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const seconds = parseInt(secondsParts[0], 10);
  const milliseconds = parseInt(secondsParts[1], 10);
  return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
}

function convertTimeStampToHHMMSS(timeStamp) {
  if (!timeStamp) return '';
  // Split the timestamp into hours, minutes, seconds, and milliseconds
  var parts = timeStamp.split(':');
  var secondsPart = parts[2].split(',')[0]; // Extract seconds
  var millisecondsPart = parts[2].split(',')[1]; // Extract milliseconds

  // Return the formatted time
  return parts[0] + ':' + parts[1] + ':' + secondsPart;
}

function calculateWordRatio(data) {
  if (!data) return [];
  // Create an object to store the word count for each speaker
  let wordCount = {};

  // Iterate through each segment of the data
  data?.forEach((segment) => {
    // Get the speaker and the words spoken in the segment
    let speaker = segment.speaker;
    let words = segment.text.split(/\s+/).filter((word) => word !== ''); // Split text by whitespace to count words

    // Increment the word count for the speaker
    wordCount[speaker] = (wordCount[speaker] || 0) + words.length;
  });

  // Calculate the total word count
  let totalWords = Object.values(wordCount).reduce(
    (total, count) => total + count,
    0,
  );

  // Calculate the ratio of words spoken by each speaker
  let ratio = [];
  for (let speaker in wordCount) {
    ratio.push({
      name: speaker,
      ratio: Math.round((wordCount[speaker] / totalWords) * 100, 2),
    });
  }

  return ratio;
}

const Transcripts2 = ({
  transcript_file_url = '',
  recording_file_url = '',
}) => {
  const { ForwardIcon, BackwardIcon, PlayCircleIcon } = useIcons();
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [summary, setSummary] = useState('');
  const [speaker, setSpeaker] = useState([]);
  const [topics, setTopics] = useState([]);
  const [totalTime, setTotalTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [speakersArray, setSpeakerArray] = useState([]);

  useEffect(() => {
    if (transcript_file_url) {
      setIsLoading(true);
      fetch(transcript_file_url)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setSpeaker(JSON.parse(data?.speaker));
          setSummary(data?.summary);
          setTopics(data?.keywords?.split('\n') ?? []);
          // setTopics(
          //   data?.keywords?.split('keywords:')?.pop()?.split(',') || [],
          // );

          const ratio = calculateWordRatio(JSON.parse(data?.speaker));
          setSpeakerArray(ratio);
          const end_time =
            JSON.parse(data?.speaker)?.pop()?.end_time || '00:00:00';
          const seconds = parseTime(end_time);
          let minutes = Math.round(seconds / 60);
          setTotalTime(minutes);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [transcript_file_url]);

  useEffect(() => {
    if (audioRef?.current) {
      audioRef?.current.addEventListener('timeupdate', (e) => {
        const currentTime = audioRef?.current?.currentTime ?? null;
        setCurrentTime(currentTime);
      });
      return () => {
        if (audioRef?.current) {
          audioRef?.current.removeEventListener(
            'timeupdate',
            () => null,
            false,
          );
        }
      };
    }
  }, [isLoading]);

  return (
    <div className="w--full h--full p--md transcriptPage">
      {isLoading ? (
        <span className="d--flex align-items--center justify-content--center h--full">
          <Spinner size="md" />
        </span>
      ) : speaker?.length > 0 ? (
        <div className="d--flex gap--md">
          <div className="w--full d--flex flex--column gap--md">
            <div className="w--full p--md radius--sm bg--contrast-600 border-full--contrast-800">
              <h3 className="font--md font--600 m-b--sm">Summary</h3>
              <div className="font--sm font--400 m-b--sm lh--21 white--space-pre-wrap word-break--all">
                {summary}
              </div>
            </div>
            <div className="w--full p--md radius--sm bg--contrast-600 border-full--contrast-800">
              <h3 className="font--md font--600 m-b--sm">Key Topics</h3>
              <div className="d--flex flex--wrap gap--md">
                {topics &&
                  topics?.length > 0 &&
                  topics?.map((topic, index) => {
                    if (!topic) return;
                    return (
                      <div
                        style={{
                          textTransform: 'capitalize',
                        }}
                        className={`p-t--md p-b--md p-l--xl p-r--xl ${
                          index % 2 === 0
                            ? 'bg--primary text--white'
                            : 'bg--white text--black'
                        } font--sm font--400 radius--full`}
                      >
                        {topic}
                      </div>
                    );
                  })}
                {topics?.length === 0 && <div>No Key Topic Found</div>}

                {/* <div className="p-t--md p-b--md p-l--xl p-r--xl font--sm font--400 radius--full">
                  Competition
                </div> */}
                {/* <div className="p-t--md p-b--md p-l--xl p-r--xl bg--primary text--white font--sm font--400 radius--full">
                  ROI
                </div>
                <div className="p-t--md p-b--md p-l--xl p-r--xl bg--white text--black font--sm font--400 radius--full">
                  Source of truth
                </div>
                <div className="p-t--md p-b--md p-l--xl p-r--xl bg--primary text--white font--sm font--400 radius--full">
                  Contracts
                </div>
                <div className="p-t--md p-b--md p-l--xl p-r--xl bg--white text--black font--sm font--400 radius--full">
                  Source of truth
                </div>
                <div className="p-t--md p-b--md p-l--xl p-r--xl bg--white text--black font--sm font--400 radius--full">
                  Source of truth
                </div>
                <div className="p-t--md p-b--md p-l--xl p-r--xl bg--primary text--white font--sm font--400 radius--full">
                  ROI
                </div> */}
              </div>
            </div>
            <div className="w--full p--md radius--sm bg--contrast-600 border-full--contrast-800">
              <h3 className="font--md font--600 m-b--sm">Talk Ratio</h3>
              {speakersArray &&
                speakersArray?.map((item) => {
                  return (
                    <div className="d--flex align-items--center justify-content--between bg--primary-100 border-full--primary-100 gap--md p--md  m-b--md radius--sm">
                      <h4 className="font--2sm font--600">{item?.name}</h4>
                      <h5 className="font--2sm font--400 ">
                        Talk Time |&nbsp;
                        <span className="text--primary">{item?.ratio}%</span>
                      </h5>
                    </div>
                  );
                })}
              {/* <div className="d--flex align-items--center justify-content--between bg--primary-100 border-full--primary-100 gap--md p--md  radius--sm">
                <h4 className="font--2sm font--600">Speaker 1</h4>
                <h5 className="font--2sm font--400 ">
                  Talk Time | <span className="text--primary">25%</span>
                </h5>
              </div> */}
            </div>
            <div className="w--full p--md radius--sm bg--contrast-600 border-full--contrast-800">
              <h3 className="font--md font--600 m-b--sm">Sentiment Filters</h3>
              <div className="d--flex flex--wrap gap--md">
                <div className="p-t--md p-b--md p-l--xl p-r--xl bg--primary-100 text--primary font--sm font--400 radius--full">
                  Neutral . 66%
                </div>
                <div className="p-t--md p-b--md p-l--xl p-r--xl bg--success-100 text--success font--sm font--400 radius--full">
                  Positive . 22%
                </div>
                <div className="p-t--md p-b--md p-l--xl p-r--xl bg--danger-100 text--danger font--sm font--400 radius--full">
                  Negative . 22%
                </div>
              </div>
            </div>
          </div>

          <div className="w--full d--flex flex--column gap--md ">
            <div className="w--full p--md radius--sm bg--contrast-600 border-full--contrast-800 transcriptBox">
              <h3 className="font--md font--600 m-b--sm">Transcripts</h3>
              <h6 className="font--2sm font--400 text--black-600 m-b--md">
                {totalTime} min read
              </h6>
              <div className="transcript-chat-body overflow--auto w--full d--flex flex--column gap--md">
                {speaker &&
                  speaker instanceof Array &&
                  speaker.map((s, index) => {
                    const isCurrentSubtitle =
                      currentTime >=
                        parseTime(
                          typeof s.start_time === Number
                            ? '00:00:00'
                            : s.start_time || '00:00:00',
                        ) && currentTime <= parseTime(s.end_time || '00:00:00');

                    return (
                      <div
                        className={`${
                          index % 2 === 0
                            ? 'incoming-bubble'
                            : 'outgoing-bubble'
                        }  w--full`}
                      >
                        <div className="d--flex gap--sm">
                          <div
                            className={`w--full w-max--4 radius--full ${
                              index % 2 === 0
                                ? 'bg--primary'
                                : 'bg--primary-100'
                            }`}
                          ></div>
                          <div className="w--full">
                            <h5 className="font--2sm font--600 m-b--sm d---flex">
                              {s?.speaker}
                              <span className="text--black-600 m-l--sm">
                                {convertTimeStampToHHMMSS(s?.start_time)}
                              </span>
                            </h5>
                            <p
                              className={`font--sm font--400 lh--21 ${
                                isCurrentSubtitle ? 'text--red' : 'text--black'
                              } `}
                            >
                              {s?.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="w--full p--md radius--sm bg--contrast-600 border-full--contrast-800 d--flex align-items--center justify-content--center">
              <audio
                ref={audioRef}
                src={recording_file_url}
                controls
                preload="metadata"
              />
              {/* <div className="d--flex align-items--center justify-content--center gap--md">
              <span className="c--pointer text--primary">
                <BackwardIcon />
              </span>
              <span className="c--pointer text--primary">
                <PlayCircleIcon width={34} height={34} />
              </span>
              <span className="c--pointer text--primary">
                <ForwardIcon />
              </span>
            </div>
            <div className="d--flex align-items--center justify-content--between gap--md m-t--sm">
              <span className=" text--black">15:42</span>
              <span className="radius--full w--full h--full h-max--5 h-min--5 bg--primary-100"></span>
              <span className=" text--balck">00:00</span>
            </div> */}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {!isLoading && speaker?.length === 0 && (
        <span className="d--flex align-items--center justify-content--center h--full text--black-800 font--md">
          No transcript available
        </span>
      )}
    </div>
  );
};
export default Transcripts2;
