import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="p--xl w--full d--flex justify-content--center flex--column align-items--center">
      <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md"> Privacy Policy </h1>
        <br />
        <span>
          Customer privacy is very important at Nextere. The privacy policy
          disclosed herein describes how Nextere will use and protect any
          information that is provided to Nextere by the customer. The privacy
          policy aims to help Nextere customers and visitors to the site
          understand how their information is protected. Use of Nextere services
          indicates the customer and visitors have read and agree to the privacy
          statement disclosed in this document. Accepting the privacy policy
          indicates that the customer and/or visitor to the site also accept the
          <span className='text--primary c--pointer font--600' onClick={() => navigate('/terms-conditions')}>
            &nbsp;Terms of Service&nbsp;
          </span>
          set forth by Nextere.
        </span>
      </div>
      <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md"> Why Nextere Collects Personal Information</h1>
        <br />
        Nextere collects information which includes name, addresses, phone
        numbers, and email addresses when you visit our website and/or register
        to use the services we offer. The information collected is used to:
        <br />
        Verify customer identity
        <br />
        Meet regulatory requirements
        <br />
        Manage and oversee customer accounts and operations
        <br />
        Maintain communications with customers and provide billing services
        <br />
        Enforce the terms of service and prevent illegal use and illegal access
        to our website and service
        <br />
        Personal Information will not be collected, used, or disclosed for
        purposes other than as set forth in this Privacy Statement except with
        your consent or as otherwise required or permitted by applicable law.
      </div>
      <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md">Information Nextere Collects</h1>
        <br />
        The information we collect is provided by the end user. We require the
        end user to provide personal identifiable information in order to
        provide services. The information provided includes names, addresses,
        phone numbers, and email addresses. Nextere does not store customer's
        credit information. We use a secure payment processor gateway.To use
        Nextere services customers need to provide credit card payments to
        facilitate billing. No financial information is stored.
      </div>
      {/* <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md">Phone Contacts - Nextere</h1>
        <br />
        The Nextere app accesses the phone device address book to sync phone
        contacts so that users can make calls to phone contacts as well as chat
        with other Nextere users. It also enables users to be able to fast dial
        their phonebook contacts. The data is not used for anything else. and is
        only accessible by the Nextere app if the user explicitly accepts the
        phone contacts permissions.
      </div> */}
      <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md">Use of Cookies</h1>
        <br />
        Cookies are small files transmitted to the computer of the user via the
        web browser that helps the website and provider identify the user.
        Nextere uses cookies to help identify how users locate our website,
        monitor user preferences, and help to track and deter fraud.
      </div>
      <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md">Disclosure of Personal Information</h1>
        <br />
        Nextere will not sell or disclose your personal information to anyone
        outside of Nextere.Nextere may disclose your personal information for
        legal or regulatory reasons as necessary or required to investigate
        fraud, to protect other users, or to otherwise protect against the
        unlawful use of the Nextere Service.
        <br />
        The Customer acknowledges that it is Nextere's policy and right to
        respond to all governmental inquiries regarding its service or its
        Customers. In the event that a legal governmental authority asks Nextere
        to retain and provide personal information about a customer, Nextere
        reserves the right to provide any and all assistance and information
        necessary in its opinion to fulfill the request.
      </div>
      <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md">Holding of Personal Information</h1>
        <br />
        Personal Information will be held as long as necessary for the
        fulfillment of those purposes or as otherwise required or permitted by
        applicable law.
      </div>
      <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md">
          Use of Personal Information to Provide Nextere services - Opt Out
          Rights
        </h1>
        <br />
        By accepting this Privacy Statement, registering on the Website, or
        using Nextere's services, you consent to the collection, use, and
        disclosure of your personal information as set forth in this Privacy
        Statement. However, Nextere allows Customers to opt-out of receiving
        Nextere marketing communications from us during registration, and any
        time thereafter by contacting Customer Service or by following the
        unsubscribe procedures in any email marketing communication.
      </div>
      <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md">Children's Privacy Protection</h1>
        <br />
        Children under 13 years of age are not permitted to be Nextere
        Customers. Children under 13 should not send us any information about
        themselves. If a child under 13 submits information through any part of
        Our Website, and we become aware that the person submitting the
        information is a child, we will attempt to delete this information as
        soon as possible. Because we do not knowingly collect any personal
        information about children under 13, we do not knowingly use or disclose
        such information.
      </div>
      <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md">Modifications to This Privacy Statement</h1>
        <br />
        Nextere will update the Privacy Policy occasionally to reflect changes
        in our products/ services and customer feedback. When we make the
        necessary changes to our Privacy Policy, we will make sure that the
        'last revised' date is updated at the bottom of this page. If Nextere
        intends to make any kind of material changes to the Privacy Policy or
        the way we plan to use your personal information we will surely update
        the privacy policy. We encourage you to review this Privacy Policy
        periodically to be informed of how Nextere is collecting, using, as well
        as protecting your information.
      </div>
      <div className="w-max--1000 d--flex flex--column align-items--start justify-content--start w-min--1000">
        <h1 className="m-t--md">Effective Date</h1>
        <br />
        <b> This Privacy Statement is effective as of June, 2024</b>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
