import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { greetingDelete } from '../../api';

export default function useGreetingDelete() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: greetingDelete,
    mutationKey: 'greetingDelete',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['greetingList'],
        exact: false,
      });
      showAlert({
        type: 'success',
        message: data?.message ?? 'Greeting deleted successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
