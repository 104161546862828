import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createContact } from '../../api';
import { useAlert } from '../useAlert';

export default function useAddContact(handleClose, fromDialpad) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const createContactRequest = useMutation({
    mutationFn: createContact,
    mutationKey: 'createContact',
    onSuccess: ({ data }) => {
      if (!fromDialpad) {
        queryClient.invalidateQueries({
          queryKey: ['getContactListing'],
          exact: false,
        });
        queryClient.refetchQueries({
          queryKey: ['getAllNumberDetails'],
          exact: true,
        });
        showAlert({
          type: 'success',
          message: data?.message ?? 'Contact added successfully',
        });
      }
      handleClose(data?.data?.uuid);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...createContactRequest };
}
