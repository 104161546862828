import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../../../comopnents/FormInput';
import FormSelect from '../../../../comopnents/FormSelect';
import useCountryList from '../../../../hooks/useCountryList';
import useProfileUpdate from '../../../../hooks/useProfileUpdate';
import Spinner from '../../../../comopnents/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import {
  AGENCY_OWNER,
  AddressProofTypes,
  IdentityProofTypes,
  USER,
} from '../../../../helpers/functions';
import useUploadMedia from '../../../../hooks/useUploadMedia';
import Button from '../../../../comopnents/Button';
import useIcons from '../../../../assets/icons/useIcons';

const RenderCountryItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.isoCode}
      value={item.isoCode}
    >
      {item.name}
    </option>
  );
};

const RenderItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.value}
      value={item.value}
    >
      {item.label}
    </option>
  );
};

const RenderTimeZoneItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.abbreviation}
      value={item.abbreviation}
    >
      {item.zoneName}
    </option>
  );
};

const initialValues = {
  first_name: '',
  last_name: '',
  country: '',
  timezone: '',
  language: 'en',
  id_number: '',
  personal_tax_id: '',
  dob: '',
  identity_proofs_type: '',
  identity_proofs: [],
  city: '',
  postal_code: '',
  address: '',
  state: '',
  address_proofs_type: '',
  address_proofs: [],
  extension: '',
};

const validationSchema = yup.object().shape({
  first_name: yup.string().required(`First Name is required.`),
  last_name: yup.string().required(`Last Name is required.`),
  country: yup.string().required(`Country field is required.`),
  timezone: yup.string().required(`Timezone field is required.`),
  extension: yup
    .string()
    .required('Extension is required')
    .test(
      'minLength',
      'Extension must be at least 3 characters long',
      (val) => val && val.length >= 3,
    )
    .test(
      'noDecimal',
      'Extension cannot contain decimals',
      (val) => val && !val.includes('.'),
    )
    .test('positive', 'Extension cannot be 0000', (val) => val > 0),
});

export default function EditInfo() {
  const [uploadingFile, setUploadingFile] = useState(false);
  const [timezoneList, setTimezoneList] = useState([]);
  const { user } = useAuth();
  const countries = useCountryList();
  const { mutateAsync: profileUpdateMutate, isLoading } = useProfileUpdate();
  const { mutateAsync: getUploadUrl, isLoading: getUploadUrlLoad } =
    useUploadMedia();

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  console.log('errors', errors);

  const selectedCountry = watch('country');
  useEffect(() => {
    reset({
      first_name: user?.first_name ?? '',
      last_name: user?.last_name ?? '',
      country: user?.country ?? '',
      timezone: user?.timezone ?? '',
      language: 'en',
      id_number: user?.id_number ?? '',
      personal_tax_id: user?.personal_tax_id ?? '',
      dob: user?.dob ?? '',
      identity_proofs: user?.identity_proofs ?? [
        {
          type: '',
          file: null,
        },
      ],
      city: user?.city,
      postal_code: user?.postal_code,
      address: user?.address,
      state: user?.state,
      address_proofs: user?.address_proofs ?? [
        {
          type: '',
          file: null,
        },
      ],
      extension: user?.extension ?? '',
    });
  }, [user]);

  useEffect(() => {
    if (selectedCountry) {
      const timezones =
        countries?.find((country) => country.isoCode === selectedCountry)
          ?.timezones ?? [];
      setTimezoneList(timezones);
    }
  }, [selectedCountry]);

  async function onSubmit(values) {
    let new_identity_proofs = [];
    let new_address_proofs = [];
    setUploadingFile(true);
    for (let index = 0; index < values?.identity_proofs?.length; index++) {
      const proof = values?.identity_proofs[index]?.file;
      const type = values?.identity_proofs[index]?.type;
      const ifProofUpload =
        typeof values?.identity_proofs[index]?.file !== 'string';
      if (ifProofUpload && proof) {
        const payload = {
          uuid: user?.parent_uuid || user?.uuid,
          file_name: proof?.name ?? '',
          type: 'profile',
        };
        const res = await getUploadUrl(payload);
        const { filename, url: uploadUrl } = res?.data?.data || '';
        if (uploadUrl) {
          const uploadFileResponse = await fetch(uploadUrl, {
            method: 'PUT',
            body: proof,
          });

          if (uploadFileResponse.status === 200) {
            new_identity_proofs.push({
              type,
              file: filename,
            });
          }
        }
      } else {
        new_identity_proofs.push({
          type,
          file: values?.identity_proofs?.[index]?.file,
        });
      }
    }
    for (let index = 0; index < values?.address_proofs.length; index++) {
      const proof = values?.address_proofs?.[index]?.file;
      const type = values?.address_proofs?.[index]?.type;
      const ifProofUpload =
        typeof values?.address_proofs[index]?.file !== 'string';
      if (ifProofUpload && proof) {
        const payload = {
          uuid: user?.parent_uuid || user?.uuid,
          file_name: proof?.name ?? '',
          type: 'profile',
        };
        const res = await getUploadUrl(payload);
        const { filename, url: uploadUrl } = res?.data?.data || '';
        if (uploadUrl) {
          const uploadFileResponse = await fetch(uploadUrl, {
            method: 'PUT',
            body: proof,
          });

          if (uploadFileResponse.status === 200) {
            new_address_proofs.push({
              type,
              file: filename,
            });
          }
        }
      } else {
        new_address_proofs.push({
          type,
          file: values?.address_proofs?.[index]?.file,
        });
      }
    }
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      country: values.country,
      timezone: values.timezone,
      lang: values.language,
      id_number: values?.id_number,
      personal_tax_id: values?.personal_tax_id,
      dob: values?.dob,
      identity_proofs: new_identity_proofs,
      city: values?.city,
      postal_code: values?.postal_code,
      address: values?.address,
      state: values?.state,
      address_proofs: new_address_proofs,
      extension: values?.extension,
    };
    setUploadingFile(false);
    profileUpdateMutate(payload);
  }

  const isUpdating = isLoading || getUploadUrlLoad || uploadingFile;

  return (
    <div className="w--full d--flex gap--md flex--column">
      <div className="d--flex w--full gap--xl">
        <div className="w--full">
          <div className="label--control font--sm font--500 d--flex gap--sm align-items--center m-b--xs h-min--36 text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm  m-t--md m-b--lg">
            Profile Information
            <div className="d--flex">{isUpdating && <Spinner />}</div>
          </div>
          <div className="w--full d--flex flex--column gap--lg">
            <div className="d--flex gap--md">
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="text"
                    label="First Name"
                    placeholder="Enter first name"
                    maxLength={30}
                    error={errors?.first_name?.message}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="text"
                    label="Last Name"
                    placeholder="Enter last name"
                    maxLength={30}
                    error={errors?.last_name?.message}
                  />
                )}
              />
            </div>
            <div className="d--flex gap--md">
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    options={countries || []}
                    renderOption={RenderCountryItem}
                    placeholder="Select"
                    label="Country"
                    error={errors?.country?.message}
                    disabled={true}
                  />
                )}
              />
              <Controller
                name="timezone"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    options={timezoneList}
                    renderOption={RenderTimeZoneItem}
                    placeholder="Select"
                    label="Timezone"
                    error={errors?.timezone?.message}
                  />
                )}
              />
            </div>
            {user?.role_id && [USER, AGENCY_OWNER].includes(user?.role_id) && (
              <>
                <div className="d--flex gap--md">
                  <Controller
                    name="id_number"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="number"
                        label="ID number"
                        placeholder="Enter Id number"
                      />
                    )}
                  />
                  <Controller
                    name="personal_tax_id"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="text"
                        label="Personal Tax ID"
                        placeholder="Enter your personal tax Id"
                      />
                    )}
                  />
                </div>
                <div className="d--flex gap--md">
                  <Controller
                    name="dob"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="date"
                        label="Birth date"
                        placeholder="Select date"
                      />
                    )}
                  />
                </div>
                <div className="w--full">
                  <label className="label--control font--sm font--500 m-b--xs d--flex w--full  text--black">
                    Proof Type
                  </label>
                  {Boolean(watch('identity_proofs').length) && (
                    <div className="m-b--sm m-t--md d--flex flex--column gap--sm">
                      <RenderProofTypes
                        {...{
                          getValues,
                          setValue,
                          user,
                          watch,
                          proofName: 'identity_proofs',
                          ProofTypes: IdentityProofTypes,
                        }}
                      />
                    </div>
                  )}
                  <div className="d--flex flex--column gap--sm">
                    <ChooseFileButtons
                      {...{
                        getValues,
                        setValue,
                        watch,
                        ProofTypes: IdentityProofTypes,
                        proofNameType: 'identity_proofs_type',
                        proofName: 'identity_proofs',
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="d--flex gap--md">
              <Controller
                name="extension"
                control={control}
                render={({ field }) => {
                  const { onChange } = field;
                  return (
                    <FormInput
                      {...field}
                      type="number"
                      onChange={(e) => {
                        if (e.target.value < 0) return;
                        if (`${e.target.value}`?.length <= 4) {
                          onChange(e.target.value);
                        } else {
                          return;
                        }
                      }}
                      onKeyDown={(e) => {
                        if (['e', '+', '-'].includes(e.key)) e.preventDefault();
                      }}
                      label="Extension"
                      placeholder="Enter your extension"
                      error={errors?.extension?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="w--full">
          <div className="label--control font--sm font--500 d--flex gap--sm align-items--center m-b--xs h-min--36 text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm  m-t--md m-b--lg">
            Address Information
            <div className="d--flex">{isUpdating && <Spinner />}</div>
          </div>
          <div className="d--flex flex--column gap--lg">
            <div className="d--flex gap--md">
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="text"
                    label="City"
                    error={errors?.city?.message}
                  />
                )}
              />
              <Controller
                name="postal_code"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="text"
                    label="Postal code"
                    error={errors?.postal_code?.message}
                  />
                )}
              />
            </div>
            <div
              className={`d--flex gap--md ${
                [USER, AGENCY_OWNER].includes(user?.role_id)
                  ? 'flex--column'
                  : ''
              }`}
            >
              <div className="w--full">
                <label
                  className={`label--control font--sm font--500 m-b--xs d--flex  ${
                    errors?.address?.message ? 'text--danger' : 'text--black'
                  }`}
                >
                  {errors?.address?.message ?? 'Address'}
                </label>
                <textarea
                  className={`form--control w--full h-min--36 radius--sm p-l--md p-r--md p-t--sm p-b--sm  border-full--black-200
                  ${
                    [USER, AGENCY_OWNER].includes(user?.role_id)
                      ? 'h-min--100'
                      : ''
                  }
                  `}
                  value={watch('address')}
                  onChange={(e) => {
                    setValue('address', e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  name="address"
                  rows="1"
                  cols="150"
                ></textarea>
              </div>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="text"
                    label="State/Province/Region"
                    error={errors?.state?.message}
                  />
                )}
              />
            </div>
            {user?.role_id && [USER, AGENCY_OWNER].includes(user?.role_id) && (
              <>
                <div className="w--full">
                  <div className="w--full d--flex align-items--center justify-content--between m-b--xs">
                    <label className="label--control font--sm font--500  d--flex w--full  text--black">
                      Proof Type
                    </label>
                  </div>
                  {Boolean(watch('address_proofs').length) && (
                    <div className="m-b--sm m-t--md d--flex flex--column gap--sm">
                      <RenderProofTypes
                        {...{
                          getValues,
                          setValue,
                          user,
                          watch,
                          proofName: 'address_proofs',
                          ProofTypes: AddressProofTypes,
                        }}
                      />
                    </div>
                  )}
                  <div className="d--flex flex--column gap--sm">
                    <ChooseFileButtons
                      {...{
                        getValues,
                        setValue,
                        watch,
                        ProofTypes: AddressProofTypes,
                        proofNameType: 'address_proofs_type',
                        proofName: 'address_proofs',
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w--full d--flex align-items--center justify-content--center m-t--md">
        <button
          type="button"
          className="btn font--sm d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary w-max--200 c--pointer"
          disabled={isSubmitting || isUpdating}
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting || isUpdating ? 'Please wait..' : 'Update Profile'}
        </button>
      </div>
    </div>
  );
}

const RenderProofTypes = ({
  proofName = '',
  watch,
  getValues,
  setValue,
  user,
  ProofTypes = [],
}) => {
  const { PDFIcon, CrossRoundIcon } = useIcons();
  return watch(proofName)?.map(({ type, file }, index) => {
    if (!type || !file) return <React.Fragment key={index}></React.Fragment>;
    return (
      <div className="d--flex flex--column gap--sm" key={index}>
        <span className="w--full font--sm font--600">
          {ProofTypes?.find((proof) => proof.value === type)?.label} :{' '}
        </span>
        <div className="d--flex w--full gap--md border-bottom--black-50 p-b--xs">
          {watch(`${proofName}[${index}].file`) && (
            <div className="d--flex gap--md">
              {typeof watch(`${proofName}[${index}].file`) === 'string' ? (
                watch(`${proofName}[${index}].file`).includes('pdf') ? (
                  <div
                    className="d--flex align-items--center gap--sm justify-items--end c--pointer"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_MEDIA_URL}signed-url/${
                          user?.uuid
                        }/profile?filename=${watch(
                          `${proofName}[${index}].file`,
                        )}`,
                      );
                    }}
                  >
                    <div className="text--red">
                      <PDFIcon width={20} height={20} />
                    </div>
                    <div className="font--xs font--500">
                      {watch(`${proofName}[${index}].file`)}{' '}
                    </div>
                  </div>
                ) : (
                  <div
                    className="d--flex align-items--center gap--md justify-items--end c--pointer"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_MEDIA_URL}signed-url/${
                          user?.uuid
                        }/profile?filename=${watch(
                          `${proofName}[${index}].file`,
                        )}`,
                      );
                    }}
                  >
                    <div className="text--red">
                      <img
                        alt="pic"
                        width="30"
                        height="25"
                        src={`${process.env.REACT_APP_MEDIA_URL}signed-url/${
                          user?.uuid
                        }/profile?filename=${watch(
                          `${proofName}[${index}].file`,
                        )}`}
                        className="radius--sm"
                      />
                    </div>
                    <div className="font--xs font--500">
                      {watch(`${proofName}[${index}].file`)}
                    </div>
                  </div>
                )
              ) : watch(`${proofName}[${index}].file`)?.name?.includes(
                  'pdf',
                ) ? (
                <div className="d--flex align-items--center gap--md justify-items--end c--pointer">
                  <div className="text--red">
                    <PDFIcon width={20} height={20} />
                  </div>
                  <div className="font--xs font--500">
                    {watch(`${proofName}[${index}].file`)?.name}
                  </div>
                </div>
              ) : (
                <div className="d--flex align-items--center gap--md justify-items--end c--pointer">
                  <div className="text--red">
                    <img
                      width="30"
                      height="25"
                      src={URL.createObjectURL(
                        watch(`${proofName}[${index}].file`),
                      )}
                      alt="pic"
                      className="radius--sm"
                    />
                  </div>
                  <div className="font--xs font--500">
                    {watch(`${proofName}[${index}].file`)?.name}
                  </div>
                </div>
              )}
            </div>
          )}
          <Button
            onClick={() => {
              const proofs = [...getValues(proofName)];
              proofs.splice(index, 1);
              setValue(proofName, proofs);
            }}
            btnClasses="w--full h--full h-max--32 w-max--32  w-min--32 h-min--32 d--flex align-items--center justify-content--center c--pointer"
            rounded
            size="sm"
            variant="transparent"
            color="danger"
          >
            <CrossRoundIcon width={22} height={22} />
          </Button>
        </div>
      </div>
    );
  });
};

const ChooseFileButtons = ({
  proofName = '',
  proofNameType = '',
  watch,
  getValues,
  setValue,
  ProofTypes = [],
}) => {
  return (
    <div className="d--flex gap--md align-items--center">
      <FormSelect
        options={ProofTypes}
        renderOption={RenderItem}
        placeholder="Select"
        value={watch(proofNameType)}
        onChange={(e) => setValue(proofNameType, e.target.value)}
      />
      <FormInput
        type="file"
        accept="image/jpg,image/jpeg,image/png,application/pdf"
        disabled={!watch(proofNameType)}
        extraClasses={!watch(proofNameType) ? 'c--not-allowed' : 'c--pointer'}
        onChange={(e) => {
          if (getValues(proofNameType)) {
            const proofs = [...getValues(proofName)];
            const proofObject = proofs?.find(
              ({ type = '' }) => type === getValues(proofNameType),
            );
            if (proofObject) {
              const index = proofs.indexOf(proofObject);
              proofs.splice(index, 1, {
                file: e.target.files[0],
                type: getValues(proofNameType),
              });
              setValue(proofName, proofs);
            } else {
              setValue(proofName, [
                ...watch(proofName),
                {
                  file: e.target.files[0],
                  type: getValues(proofNameType),
                },
              ]);
            }
            setValue(proofNameType, '');
          }
          e.target.value = '';
        }}
      />
    </div>
  );
};
