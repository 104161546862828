import React, { useState } from 'react';
import TableManager from '../../TableManager';
import { particularCallHistory, unseenVoicemailList } from '../../../api';
import {
  converDateTimeFormateApis,
  durationToMMSS,
  formatPhoneNumber,
} from '../../../helpers/functions';
import useAllNumberDetails from '../../../hooks/useAllNumberDetails';
import useIcons from '../../../assets/icons/useIcons';
import { capitalizeWords } from '../../../pages/ActivityManagement/TimeLine/Activity';
import useCallRecordingSeen from '../../../hooks/useCallRecordingSeen';
import AudioPlayer from '../../AudioPlayer';
import Button from '../../Button';

const VoicemailSideModal = ({ handleClose = () => null }) => {
  const { getNumberName } = useAllNumberDetails();
  const {
    PlayIcon,
    HoldIcon,
    VoicemailIcon,
    VoicemailGroupIcon,
    CrossRoundIcon,
  } = useIcons();

  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
    uuid: null,
  });

  const { mutate: callRecordingMutateSeen } = useCallRecordingSeen();

  const columns = [
    {
      accessorKey: 'start_stamp',
      header: () => 'Date/Time',
      cell: (props) => {
        return converDateTimeFormateApis(props?.row?.original?.start_stamp);
      },
    },
    {
      accessorKey: 'caller_id_number',
      header: () => 'From',
      cell: (props) => {
        return (
          <div className="d--flex flex--column">
            <div>
              {getNumberName(props?.getValue(), false)?.includes('+')
                ? formatPhoneNumber(props?.getValue())
                : getNumberName(props?.getValue(), false)}
            </div>
            {props?.getValue()?.length < 5 && (
              <div className="font--xs">{`Ext. ${props?.getValue()}`}</div>
            )}
          </div>
        );
      },
      meta: {
        width: '15%',
      },
    },

    {
      accessorKey: 'caller_destination',
      header: () => 'Via Virtual Number',
      cell: (props) => formatPhoneNumber(props?.getValue()),
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },
    {
      accessorKey: 'forward_type',
      header: () => 'To',
      cell: (props) => {
        const elm = props?.row?.original;
        const res =
          props?.getValue() === 'VOICEMAILGROUP' ||
            props?.getValue() === 'VOICEMAIL'
            ? elm?.forward_name
            : props?.getValue();
        return (
          <div className="d--flex align-items--center gap--sm">
            {props?.getValue() === 'VOICEMAILGROUP' ? (
              <VoicemailGroupIcon width={20} height={20} />
            ) : (
              <VoicemailIcon width={20} height={20} />
            )}
            {capitalizeWords(res?.toLowerCase(), ' ')}
          </div>
        );
      },
    },
    {
      accessorKey: 'duration',
      header: () => 'Duration',
      cell: (props) => durationToMMSS(props.getValue()),
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },

    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { recording_file = '', xml_cdr_uuid = '' } = element;
        const isPlaying =
          playingData?.playing &&
          playingData?.file === recording_file &&
          playingData?.uuid === xml_cdr_uuid;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            {isPlaying ? (
              <span
                className="c--pointer"
                onClick={() => {
                  setPlayingData({ playing: false, file: null, uuid: null });
                }}
              >
                <HoldIcon width={20} height={20} />
              </span>
            ) : (
              <span
                className={`c--pointer ${element?.seen_at ? 'text--success' : 'text--red'
                  } `}
                onClick={() => {
                  if (!element?.seen_at) {
                    callRecordingMutateSeen({
                      uuid: element?.xml_cdr_uuid,
                    });
                  }
                  setPlayingData({
                    playing: true,
                    file: recording_file,
                    uuid: xml_cdr_uuid,
                  });
                }}
              >
                <PlayIcon width={20} height={20} />
              </span>
            )}
            {/* <div
              data-tooltip={'Transcripts'}
              tooltip-position="left"
              onClick={() => handleSetTranscript(element)}
            >
              <TranscriptsIcon />
            </div> */}
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];
  const tableActions = [
    {
      id: 1,
      access: true,
      component: (
        <Button
          type="button"
          btnClasses="c--pointer"
          variant="transparent"
          color="primary"
          onClick={() => handleClose()}
          size="sm"
        >
          <CrossRoundIcon width={30} height={30} />
        </Button>
      ),
    },
  ];

  return (
    <div>
      <TableManager
        {...{
          fetcherKey: 'unseenVoicemailList',
          fetcherFn: unseenVoicemailList,
          columns,
          name: `Unseen Voicemails`,
          shouldFilter: false,
          // shouldShowTotalCount: true,
          showMoreOption: (data) => {
            return data?.count > 1;
          },
          enableMoreOption: true,
          moreDataFunction: particularCallHistory,
          moreDataFnPayload: (data) => {
            return data?.xml_cdr_uuid;
          },
          customFilters: false,
          showHeader: true,
          showPagination: true,
          showPageCount: true,
          tableActions,
          showRefresh: false,
          enableDefaultFilter: '',
        }}
      />
      {playingData?.playing && (
        <AudioPlayer
          onClose={() => {
            setPlayingData({ playing: false, file: null, uuid: null });
          }}
          name={playingData?.file}
        />
      )}
    </div>
  );
};

export default VoicemailSideModal;
