import React, { useEffect } from 'react';
import Button from '../../../comopnents/Button';
import { useAuth } from '../../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

function Plan() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['getUserDetails'],
      exact: true,
    });
  }, []);

  return (
    <div className="d--flex flex--column w--full PlanPage">
      <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
        {' '}
        Plan
      </h4>
      <div className="d--flex gap--md flex--column profilePage bg--white p--md radius--sm h--full overflow--auto box-shadow--xs  ">
        <div className="w--full d--flex gap--md flex--column">
          <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
            Plan Details
          </div>
          <div className=" border-full--black-100  d--flex flex--column bg--white  p-r--md  p-t--sm p-b--sm p-l--md radius--sm">
            <div className="d--flex w--full justify-content--between align-items--center border-bottom--black-50 h-min--50 ">
              <div className="font--sm font--600">Plan</div>
              <div className="font--sm d--flex align-items--center white--space-none gap--sm">
                {user?.plan.plan_name}
                <Link to="/upgrade_plan">
                  <Button
                    btnClasses="btn btn--sm"
                    variant="primary"
                    color="white"
                    size="sm"
                  >
                    Change
                  </Button>
                </Link>
              </div>
            </div>
            <div className="d--flex w--full justify-content--between align-items--center border-bottom--black-50 h-min--50 ">
              <div className="font--sm font--600">Plan Cost</div>
              <div className="font--sm ">
                ${user?.plan.plan_cost}
                {user?.plan_duration === 1 ? (
                  <>
                    /<small>mo</small>
                  </>
                ) : (
                  <>
                    /<small>yr</small>
                  </>
                )}
              </div>
            </div>
            <div className="d--flex w--full justify-content--between align-items--center h-min--50 ">
              <div className="font--sm font--600">Plan Renewal Date</div>
              <div className="font--sm ">{user?.plan_expiration_date}</div>
            </div>
          </div>
          <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
            Your Plan Includes Following
          </div>
          <div className="d--flex gap--md w--full">
            <div className="border-full--black-100  d--flex  flex--column bg--white p-r--md  p-t--sm p-b--sm p-l--md radius--sm w--full">
              <div className="d--flex w--full justify-content--between align-items--center  border-bottom--black-50 h-min--50 ">
                <div className="font--sm font--600">
                  Included Free Calling Minutes
                </div>
                <div className="font--sm ">
                  (used) {user?.plan?.free_calls - user?.outgoing_minutes ?? 0}/
                  {user?.plan?.free_calls}
                </div>
              </div>
              <div className="d--flex w--full justify-content--between align-items--center border-bottom--black-50  h-min--50 ">
                <div className="font--sm font--600">Included Free SMS</div>
                <div className="font--sm ">
                  (used) {user?.plan?.free_sms - user?.free_sms_used ?? 0}/
                  {user?.plan?.free_sms}
                </div>
              </div>
              <div className="d--flex w--full justify-content--between align-items--center border-bottom--black-50 h-min--50 ">
                <div className="font--sm font--600">
                  Included Virtual Number
                </div>
                <div className="font--sm ">
                  (used) {user?.plan_detail?.free_did_used ?? 0}/
                  {user?.plan_detail?.free_did}
                </div>
              </div>
              <div className="d--flex w--full justify-content--between align-items--center border-bottom--black-50 h-min--50 ">
                <div className="font--sm font--600">DID Cost</div>
                <div className="font--sm ">
                  ${user?.plan?.did_cost ?? '---'}
                </div>
              </div>
              <div className="d--flex w--full justify-content--between align-items--center border-bottom--black-50 h-min--50 ">
                <div className="font--sm font--600">
                  No. of Agents/Employees
                </div>
                <div className="font--sm ">
                  {user?.plan?.number_of_member ?? '---'}
                </div>
              </div>
              <div className="d--flex w--full justify-content--between align-items--center  h-min--50 ">
                <div className="font--sm font--600">Per Agent Cost</div>
                <div className="font--sm ">
                  ${user?.plan?.per_member_addon_amt ?? '---'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plan;
