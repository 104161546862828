import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../comopnents/FormInput';
import FormSelect from '../../../comopnents/FormSelect';
import Button from '../../../comopnents/Button';
import useSetSettings from '../../../hooks/useSetSettings';
import useGetSettings from '../../../hooks/useGetSettings';
import { useAuth } from '../../../hooks/useAuth';
import { USER } from '../../../helpers/functions';

const initialValues = {
  did_name: '',
  name_incoming_call: 'C',
  name_outgoing_call: 'A',
  custom_name: '',
};

const callerIDNameOptionsIncoming = [
  {
    id: 1,
    label: 'Show the name / number of the caller',
    value: 'C',
  },
];

const callerIDNameOptionsOutgoing = [
  {
    id: 1,
    label: 'Show the name / number',
    value: 'A',
  },
];

const RenderCallerIDItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.value}
      value={item.value}
    >
      {item.label}
    </option>
  );
};

const SettingsModal = ({ handleClose = () => null, showSettingsModal }) => {
  const { user } = useAuth();
  const { mutate: setSettingsMutate, isLoading: setSettingsLoad } =
    useSetSettings({ handleSuccess });
  const { mutate: getSettingsMutate, data: getSettingsData } = useGetSettings();
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (showSettingsModal?.data?.uuid) {
      getSettingsMutate({ uuid: showSettingsModal?.data?.uuid });
    }
  }, [showSettingsModal?.data?.uuid]);

  useEffect(() => {
    if (getSettingsData?.data?.data) {
      const refSettings = getSettingsData?.data?.data?.settings ?? null;
      if (refSettings) {
        reset(refSettings);
      }
    }
  }, [getSettingsData?.data?.data]);

  const onSubmit = (values) => {
    const payload = {
      uuid: showSettingsModal?.data?.uuid,
      settings: values,
    };
    setSettingsMutate(payload);
  };

  function handleSuccess() {
    handleClose();
  }

  return (
    <div className="w--full h--full">
      <div className="overflow--auto dialogScroll d--flex flex--column gap--lg p--md">
        <Controller
          name="did_name"
          control={control}
          render={({ field }) => (
            <FormInput
              {...field}
              type="text"
              label={`Add Name for ${showSettingsModal?.data?.did_number}`}
              placeholder="Enter did name"
              error={errors?.did_name?.message}
              maxLength="11"
            />
          )}
        />
        <Controller
          name="name_incoming_call"
          control={control}
          render={({ field }) => (
            <FormSelect
              {...field}
              options={callerIDNameOptionsIncoming || []}
              renderOption={RenderCallerIDItem}
              placeholder="Select option"
              label="Caller ID for incoming calls"
              error={errors?.name_incoming_call?.message}
            />
          )}
        />
        <Controller
          name="name_outgoing_call"
          control={control}
          render={({ field }) => (
            <FormSelect
              {...field}
              options={callerIDNameOptionsOutgoing || []}
              renderOption={RenderCallerIDItem}
              placeholder="Select option"
              label="Caller ID name outgoing calls"
              error={errors?.name_outgoing_call?.message}
            />
          )}
        />
        {watch('name_outgoing_call') === 'CO' && (
          <div>
            <FormInput
              type="text"
              label="Company Name"
              disabled={true}
              value={
                user?.role_id === USER
                  ? user?.tenant_company
                  : user?.agency_name
              }
            />
          </div>
        )}
        {watch('name_outgoing_call') === 'CU' ? (
          <Controller
            name="custom_name"
            control={control}
            render={({ field }) => (
              <FormInput {...field} type="text" label="Enter custom name" />
            )}
          />
        ) : null}
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="button"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          onClick={handleSubmit(onSubmit)}
          disabled={setSettingsLoad}
        >
          {setSettingsLoad ? 'Please wait..' : 'Submit'}
        </Button>
      </div>
    </div>
  );
};

export default SettingsModal;
