import React, { useContext, useEffect, useState } from 'react';
import ContactDetails from './ContactDetails';
import ContactActivity from './ContactActivity';
import CallLayout from '../../../layouts/CallLayout';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../../comopnents/Button';
import useIcons from '../../../assets/icons/useIcons';
import { useQueryClient } from '@tanstack/react-query';

const ContactDetailsLayout = () => {
  const { RefreshIcon } = useIcons();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [callActiveKey, setCallActiveKey] = useState(null);
  const { socket = {} } = useContext(SipSocketContext);
  const { _uiSessions = {} } = socket;
  const [callSessions, setCallSessions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (_uiSessions && Object.keys(_uiSessions).length > 0) {
      const callarray = [];
      const key = Object.keys(_uiSessions)?.[0];
      const sessionData = _uiSessions?.[key];
      callarray.push({ callID: key, ...sessionData });
      setCallSessions(callarray);
    } else {
      setCallSessions([]);
    }
  }, [JSON.stringify(_uiSessions)]);

  const isCallExist = callSessions.length > 0;
  return (
    <div className="d--flex flex--column h--full">
      <div className="w--full d--flex align-items--center justify-content--between  m-b--sm ">
        <div className="font--md font--400 d--flex align-items--center gap--sm white--space-none">
          <span className="d--flex text--primary font--400">
            Contact activity
          </span>
        </div>
        <div className="d--flex align-items--center justify-content--end gap--sm">
          <Button
            variant="primary"
            color="white"
            size="sm"
            btnClasses="btn w-min--100"
            onClick={() => navigate('/contacts')}
          >
            Back
          </Button>
          <Button
            variant="white"
            btnClasses="btn w-max--36 box-shadow--xs"
            color="primary"
            type="button"
            icon={<RefreshIcon width={20} height={20} />}
            onClick={() => {
              queryClient.invalidateQueries({
                queryKey: ['contactActivityList'],
                exact: false,
              });
            }}
            size="sm"
            data-tooltip="Refresh"
            tooltip-position="top"
          />
        </div>
      </div>
      <div className="w--full h--full d--flex gap--md contactDetailsPage">
        {isCallExist && searchParams.get('showDialer') === 'true' ? (
          <div className="w-min--500 w-max--500 dialog-main h--full">
            <CallLayout
              callActiveKey={callActiveKey}
              setCallActiveKey={setCallActiveKey}
              isModal={false}
              callLayout={true}
            />
          </div>
        ) : (
          <ContactDetails />
        )}
        <ContactActivity />
      </div>
    </div>
  );
};

export default ContactDetailsLayout;
