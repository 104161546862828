import React, { useContext, useEffect, useState } from 'react';
import { SipSocketContext } from '../hooks/useSip/SipSocketContext';
import Modal from '../comopnents/Modal';
import Dialpad from '../comopnents/Dialpad';
import CallPopup from '../comopnents/CallPopup';
import { useCallSummary } from '../hooks/useCallSummary';
import moment from 'moment';
import { useAuth } from '../hooks/useAuth';

const CallLayout = ({
  show,
  setshow = () => null,
  callActiveKey,
  setCallActiveKey = () => null,
  isModal = true,
  callLayout = false,
}) => {
  const { socket = {} } = useContext(SipSocketContext);
  const { _uiSessions = {}, _uaSessions = {} } = socket;
  const { user } = useAuth();
  const [callSessions, setCallSessions] = useState([]);
  const {
    setActiveNumber,
    setEndTime,
    active_number: oldActiveNumber,
    setPhoneInfoPayload,
  } = useCallSummary();

  useEffect(() => {
    if (callSessions && callSessions.length > 0) {
      setCallActiveKey(callSessions?.[0]?.callID);
    }
  }, [callSessions.length]);

  useEffect(() => {
    if (_uiSessions && Object.keys(_uiSessions).length > 0) {
      const callarray = [];

      Object.keys(_uiSessions).forEach((key) => {
        const sessionData = _uiSessions?.[key];
        const { _status } = sessionData;
        // if (_status === 'ringing') {
        //   setCallActiveKey(key);
        // }
        callarray.push({ callID: key, ...sessionData });
      });
      // for 1-1 call
      // const key = Object.keys(_uiSessions)?.[0];
      // const key = Object.keys(_uiSessions)?.[0];
      // const sessionData = _uiSessions?.[key];
      // const { _status } = sessionData;
      // if (_status === 'ringing') {
      // setCallActiveKey(key);
      // }
      // callarray.push({ callID: key, ...sessionData });
      setCallSessions(callarray);
    } else {
      setCallSessions([]);
    }
    if (_uiSessions && Object.keys(_uiSessions).length === 1) {
      if (
        ['hold', 'mute', 'connected'].includes(
          _uiSessions?.[Object.keys(_uiSessions)?.[0]]?._status,
        )
      ) {
        let activeCallKey = Object.keys(_uiSessions)?.[0];
        let activeCallData = _uiSessions?.[activeCallKey];
        const ConfID =
          _uaSessions?.[activeCallKey]?._request?.headers?.['X-Confid']?.[0]
            ?.raw;
        const CID =
          _uaSessions?.[activeCallKey]?._request?.headers?.['X-Cid']?.[0]?.raw;
        let _cid = ConfID || CID || activeCallKey;
        setActiveNumber({
          uuid: _cid,
          number: activeCallData?._number,
          start: activeCallData?._joined_at,
          direction: activeCallData?._direction,
        });
        setPhoneInfoPayload({
          direction: activeCallData?._direction,
          domain: user?.domain,
          phone: activeCallData?._number,
          mode:
            _uaSessions?.[activeCallKey]?._request?.headers?.[
              'X-Forwardtype'
            ]?.[0]?.raw || '',
          uuid: user?.uuid,
          // uuid:
          //   _uaSessions?.[activeCallKey]?._request?.headers?.[
          //     'X-Forwardvalue'
          //   ]?.[0]?.raw || '',
        });
      }
    }
    if (!Object.values(_uiSessions).length && oldActiveNumber) {
      setEndTime(moment());
      return;
    }
  }, [JSON.stringify(_uiSessions)]);

  useEffect(() => {
    if (callSessions.length > 0) {
      callSessions.forEach((session) => {
        const { _status, callID = '' } = session;
        if (_status === 'ringing') {
          setCallActiveKey(callID);
        }
      });
    }
  }, [callSessions?.length]);

  const isCallExist = callSessions.length > 0;

  return (
    <>
      {_uiSessions &&
        Object.keys(_uiSessions).map((_key) => {
          return <audio id={_key} hidden />;
        })}
      {isModal && (
        <>
          {show ? (
            <Modal
              handleClose={() => setshow(false)}
              headerComponent={null}
              footerComponent={null}
              size="400"
            >
              <Dialpad
                setshow={setshow}
                callActiveKey={callActiveKey}
                setCallActiveKey={setCallActiveKey}
                callSessions={callSessions}
                callLayout={callLayout}
              />
            </Modal>
          ) : (
            <>
              {isCallExist && (
                <CallPopup
                  callActiveKey={callActiveKey}
                  callSessions={callSessions}
                  setshow={setshow}
                  setCallActiveKey={setCallActiveKey}
                />
              )}
            </>
          )}
        </>
      )}
      {!isModal && (
        <Dialpad
          setshow={setshow}
          callActiveKey={callActiveKey}
          setCallActiveKey={setCallActiveKey}
          callSessions={callSessions}
          isModal={isModal}
          callLayout={callLayout}
        />
      )}
    </>
  );
};

export default CallLayout;
