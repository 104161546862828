import React from 'react';
import TableManager from '../../../comopnents/TableManager';
import Button from '../../../comopnents/Button';
import { formatPhoneNumber } from '../../../helpers/functions';

const Leads = () => {
  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date',
    },
    {
      accessorKey: 'name',
      header: () => 'Name',
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      cell: (props) => {
        return formatPhoneNumber(props?.getValue());
      },
    },
    {
      accessorKey: 'leads_count',
      header: () => 'Count',
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
    },
  ];
  const tableActions = [
    {
      id: 1,
      label: 'Add',
      access: true,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          size="sm"
        >
          Add
        </Button>
      ),
    },
    {
      id: 2,
      label: 'Upload',
      access: true,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          size="sm"
        >
          Upload
        </Button>
      ),
    },
  ];
  return (
    <div className="w--full h--full">
      <TableManager
        columns={columns}
        name={'Leads'}
        shouldFilter={false}
        tableActions={tableActions}
        isEnabled={false}
      />
    </div>
  );
};

export default Leads;
