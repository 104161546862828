import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import ForwardToInput from '../../../../../comopnents/ForwardToInput';
import Button from '../../../../../comopnents/Button';
import FormSelect from '../../../../../comopnents/FormSelect';

const forwardingOptions = [
  'Extension',
  'Number',
  // 'IVR',
  'Department',
  'Voicemail',
  'Department Voicemail',
  'Hangup',
];

const initialState = { key: '', type: '', value: '' };
const keyArray = [
  { val: '0' },
  { val: '1' },
  { val: '2' },
  { val: '3' },
  { val: '4' },
  { val: '5' },
  { val: '6' },
  { val: '7' },
  { val: '8' },
  { val: '9' },
];

const RenderKeyItem = (item, fields = []) => {
  const isDisabled = fields.some((field) => field.key === item?.val);
  return (
    <option
      className="text--black c--pointer"
      disabled={isDisabled}
      key={item?.val}
      value={item?.val}
    >
      {item?.val}
    </option>
  );
};
const ActionComponent = ({
  register,
  watch,
  memberList = [],
  memberListLoad = false,
  departmentList = [],
  ivrList = [],
  addAction = () => null,
  removeAction = () => null,
  index,
  fields = [],
  greetingList = [],
  control,
  setValue,
  error,
}) => {
  const ivrOptions = watch('ivr_option') || [];
  watch(`ivr_option[${index}].key`);
  return (
    <div className="d--flex  w--full flex--column m-b--sm">
      <div className="w--full d--flex gap--sm align-items--center">
        <span className="w-max--100 w-min--75 h--full">
          <Controller
            name={`ivr_option[${index}].key`}
            control={control}
            render={({ field }) => (
              <FormSelect
                options={keyArray || []}
                renderOption={({ item }) => RenderKeyItem(item, ivrOptions)}
                {...field}
                paddingLeft="xs"
                paddingRight="xs"
                placeholder={'#'}
                error={error?.key?.message}
              />
            )}
          />
        </span>
        <span className="w--full">
          <ForwardToInput
            control={control}
            register={register}
            watch={watch}
            forwardOptions={forwardingOptions}
            forward_type_key={`ivr_option[${index}].type`}
            forward_value_key={`ivr_option.[${index}].value`}
            memberListData={memberList}
            memberListLoad={memberListLoad}
            departmentList={departmentList}
            ivrList={ivrList}
            greetingList={greetingList}
            setValue={setValue}
            forward_type_error={error?.type?.message}
            forward_value_error={error?.value?.message}
          />
        </span>
        {fields?.length !== 1 && ![0, 8].includes(index) && (
          <Button
            type="button"
            variant="contrast"
            rounded
            color="black"
            btnClasses="w-max--28 h-max--28 w-min--28 h-min--28 font--md c--pointer"
            onClick={() => removeAction(index, fields)}
          >
            -
          </Button>
        )}
        <Button
          type="button"
          variant="primary"
          color="white"
          rounded
          btnClasses="w-max--28 h-max--28 w-min--28 h-min--28 font--md c--pointer"
          onClick={() => addAction()}
          disabled={fields?.length === 9}
        >
          +
        </Button>
      </div>
    </div>
  );
};
const KeyActions = ({
  control,
  register,
  watch,
  memberList = [],
  memberListLoad = false,
  departmentList = [],
  ivrList = [],
  greetingList = [],
  setValue,
  errors,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ivr_option',
  });

  const addAction = () => {
    append(initialState);
  };
  const removeAction = (index, fields) => {
    remove(index);
  };
  return (
    <>
      <label className="label--control font--sm font--500 m-b--xs  text--black">
        Key
      </label>
      <div className="addIvrKeyScroll overflow-auto">
        {fields.map((field, index) => {
          return (
            <ActionComponent
              key={field?.id}
              register={register}
              watch={watch}
              memberList={memberList}
              memberListLoad={memberListLoad}
              departmentList={departmentList}
              ivrList={ivrList}
              addAction={addAction}
              removeAction={removeAction}
              index={index}
              fields={fields}
              control={control}
              greetingList={greetingList}
              setValue={setValue}
              error={errors?.ivr_option?.[index]}
            />
          );
        })}
      </div>
    </>
  );
};

export default KeyActions;
