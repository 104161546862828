import React, { useEffect, useState } from 'react';
import TableManager from '../../comopnents/TableManager';
import useIcons from '../../assets/icons/useIcons';
import Button from '../../comopnents/Button';
import AsideModal from '../../comopnents/AsideModal';
import { agencyList } from '../../api';
import Dropdown from '../../comopnents/Dropdown';
import { useAuth } from '../../hooks/useAuth';
import {
  USER,
  formatDateOnly,
  formatPhoneNumber,
} from '../../helpers/functions';
import AddAgencyForm from './AddAgencyForm';
import useDidList from '../../hooks/useDidList';
import Avatar from '../../comopnents/Avatar';
import useAgencyDelete from '../../hooks/useAgencyDelete';
import ConfirmComponent from '../../comopnents/ConfirmComponent';
import MultiDropDown from '../../comopnents/MultiDropdown';
import AgencyMemberList from './AgencyMemberList';
import useAccountVerify from '../../hooks/useAccountVerify';
import { useQueryClient } from '@tanstack/react-query';
import useAgencyActivate from '../../hooks/useAgencyActivate';
import useAgencyDeactivate from '../../hooks/useAgencyDeactivate';

const initialEditInstance = { isEdit: false, editData: {} };

export default function Agency() {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { mutate, data } = useDidList();
  const handleSuccess = () =>
    queryClient.invalidateQueries({
      queryKey: ['getMemberListing'],
      exact: false,
    });
  const { mutate: agencyDeleteMutate } = useAgencyDelete();
  const { mutate: memberAccountVerify } = useAccountVerify({ handleSuccess });
  const { mutate: agencyActivate } = useAgencyActivate({ handleSuccess });
  const { mutate: agencyDeactivate } = useAgencyDeactivate({ handleSuccess });
  const [addContact, setAddContact] = useState(false);
  const [editAgencyInstance, setEditAgencyInstance] =
    useState(initialEditInstance);
  const { MoreVIcon } = useIcons();
  const ACCESS = [user?.role_id]?.find((role) => [USER]?.includes(role));

  const handleDropListClick = ({ value }, data) => {
    switch (value) {
      case 'edit':
        setEditAgencyInstance({ isEdit: true, editData: data });
        setAddContact(true);
        break;
      case 'activate':
        agencyActivate({ uuid: data?.uuid });
        break;
      case 'deactivate':
        agencyDeactivate({ uuid: data?.uuid });
        break;
      default:
        break;
    }
  };

  const handleAgencyDelete = (data) => {
    const { uuid = '' } = data;
    agencyDeleteMutate({ uuid });
  };
  const handleVerifyAccount = (data) => {
    memberAccountVerify({ uuid: data?.user?.uuid });
  };

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: ({ row }) => {
        return formatDateOnly(row?.original?.created_at);
      },
    },

    {
      accessorKey: 'user.agency_name',
      header: () => 'Agency Name',
      cell: (props) => {
        const element = props?.row?.original || {};
        return (
          <span className="d--flex gap--sm  align-items--center">
            <Avatar
              first_name={element?.user?.agency_name?.split(' ')?.[0] || 'U'}
              last_name={element?.user?.agency_name?.split(' ')?.[1] || ''}
              image={element?.user?.agency_logo}
              extension={element?.user?.extension}
              shouldShowPresence={false}
              type="company_logo"
              parent_uuid={element?.owner_uuid}
              size="28"
            />
            {props.getValue()}
          </span>
        );
      },
      meta: {
        width: '15%',
      },
    },
    {
      accessorKey: 'user.first_name',
      header: () => 'First Name',
      cell: (props) => {
        return (
          <span className="d--flex gap--sm  align-items--center">
            {props.getValue()}
          </span>
        );
      },
      meta: {
        width: '10%',
      },
    },
    {
      accessorKey: 'user.last_name',
      header: () => 'Last Name',
      cell: (props) => {
        return (
          <span className="d--flex gap--sm  align-items--center">
            {props.getValue()}
          </span>
        );
      },
      meta: {
        width: '10%',
      },
    },
    {
      accessorKey: 'user.email',
      header: () => 'Email',
      meta: {
        width: '15%',
      },
    },

    {
      accessorKey: 'assigned_did',
      header: () => 'Virtual Number',
      cell: (props) => {
        const element = props?.row?.original || {};
        const { assigned_did = [] } = element;

        return (
          <div className="w--full w-max--120 ">
            {assigned_did?.length === 0 ? (
              '---'
            ) : assigned_did?.length === 1 ? (
              formatPhoneNumber(assigned_did?.[0]?.did_number) || '---'
            ) : (
              <MultiDropDown
                labelKey={'did_number'}
                valueKey={'did_number'}
                value={assigned_did ?? []}
                placeholder={`${assigned_did?.length} number(s)`}
                readOnly={true}
                optionList={assigned_did || []}
                withCaret={true}
                height="28"
                border={false}
                paddingRight="xs"
                paddingLeft="xs"
                extraClasses="no--focus link"
                isSearchable={false}
              />
            )}
          </div>
        );
      },
      meta: {
        width: '10%',
      },
    },
    {
      accessorKey: 'agency_member_count',
      header: () => 'Employees',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <AgencyMemberList agency_uuid={element?.uuid}>
            <div className="w--full d--flex justify-content--center ">
              <div
                className={`text--primary radius--full  bg--primary-100 w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center c--pointer`}
                data-link-hover
              >
                {props.getValue()}
              </div>
            </div>
          </AgencyMemberList>
        );
      },
      meta: {
        textAlign: 'center',
        width: '5%',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        const element = props?.row?.original;

        return (
          <div className="d--flex justify-content--center">
            {element?.user?.verified === 0 && props?.getValue() === 0 ? (
              <div
                data-tooltip={'Invited'}
                tooltip-position="top"
                className="bg--info radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
              >
                I
              </div>
            ) : element?.user?.verified === 1 && props?.getValue() === 1 ? (
              <div
                data-tooltip={'Active'}
                tooltip-position="top"
                className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
              >
                A
              </div>
            ) : props?.getValue() === 0 ? (
              <div
                data-tooltip={'Deactive'}
                tooltip-position="top"
                className="bg--danger radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
              >
                D
              </div>
            ) : (
              <>---</>
            )}
          </div>
        );
      },
      meta: {
        textAlign: 'center',
        width: '5%',
      },
    },

    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const dropListOptions = [
          { title: 'Edit', value: 'edit' },
          {
            title: 'Resend Invitation',
            value: 'resend-invitation',
            shouldConfirm: true,
            access: element.user.verified === 0 && element.status === 0,
          },
          {
            title: 'Deactivate',
            value: 'deactivate',
            access: element.user.verified === 1 && element.status === 1,
          },
          {
            title: 'Activate',
            value: 'activate',
            access: element.user.verified === 1 && element.status === 0,
          },
          {
            title: 'Delete',
            value: 'delete',
            shouldConfirm: true,
          },
        ];
        return ACCESS ? (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <Dropdown
              extraClasses="w-min--150 bg--black-200"
              closeOnClickOutside={false}
              dropList={{
                component: ({ item }) => {
                  if (item?.shouldConfirm) {
                    return (
                      <ConfirmComponent
                        item={item}
                        element={element}
                        label="Please confirm?"
                        confirmCallback={(data) => {
                          if (item?.value === 'delete')
                            handleAgencyDelete(data);
                          else if (item?.value === 'resend-invitation')
                            handleVerifyAccount(data);
                        }}
                      >
                        {item?.title}
                      </ConfirmComponent>
                    );
                  }
                  return (
                    <span
                      className="w--full d--flex  justify-content--start p--2sm"
                      onClick={() => handleDropListClick(item, element)}
                    >
                      {item?.title}
                    </span>
                  );
                },
                data: dropListOptions,
              }}
              showcaret={false}
            >
              <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={13} height={13} />
              </div>
            </Dropdown>
          </div>
        ) : null;
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];

  const tableActions = [
    {
      id: 1,
      access: ACCESS,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddContact(true)}
          size="sm"
        >
          Add Agency
        </Button>
      ),
    },
  ];

  const handleClose = () => {
    setEditAgencyInstance({ isEdit: false, editData: {} });
    setAddContact(false);
    mutate({ all_did: 'Y', page: 1 });
  };

  useEffect(() => {
    mutate({ all_did: 'Y', page: 1 });
  }, []);

  return (
    <div className="h--full w--full">
      <TableManager
        {...{
          fetcherKey: 'agencyList',
          fetcherFn: agencyList,
          columns,
          tableActions,
          name: 'Agency',
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          shouldShowTotalCount: true,
        }}
      />
      {addContact && (
        <AsideModal
          handleClose={handleClose}
          title={`${editAgencyInstance.isEdit ? 'Edit' : 'Add'} Agency`}
          footerComponent={null}
        >
          <AddAgencyForm
            didListData={data}
            editAgencyInstance={editAgencyInstance}
            handleClose={handleClose}
          />
        </AsideModal>
      )}
    </div>
  );
}

const filterInitialValues = {
  filter: [
    {
      key: 'name',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter agency name',
      inputType: 'text',
      inputLabel: 'Agency Name',
    },
    {
      key: 'user_email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter email',
      inputType: 'email',
      inputLabel: 'Email',
    },
  ],
};
