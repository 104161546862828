import { useMutation } from '@tanstack/react-query';
import { userSendAccountVerificationLink } from '../../api';
import { useAlert } from '../useAlert';

export default function useAccountVerify({ handleSuccess = () => {} }) {
  const { showAlert } = useAlert();
  const accountVerifyRequest = useMutation({
    mutationFn: userSendAccountVerificationLink,
    mutationKey: 'memberSendAccountVerificationLink',
    onSuccess: ({ data }) => {
      handleSuccess();
      showAlert({
        type: 'success',
        message: data?.message ?? 'Link Send sucessfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...accountVerifyRequest };
}
