import { dropdownEmployeeListing } from '../../api';
import { useQuery } from '@tanstack/react-query';
import { useAlert } from '../useAlert';

export default function useDropdownEmployeeListing() {
  const { showAlert } = useAlert();
  const Request = useQuery({
    queryFn: dropdownEmployeeListing,
    queryKey: ['dropdownEmployeeListing'],
    select: (data) => data?.data?.data || [],
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
