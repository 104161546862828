import React, { useEffect, useState } from 'react';
import PlanDetails from '../PlanDetails';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../../comopnents/FormInput';
import Button from '../../../../comopnents/Button';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetPlan from '../../../../hooks/useGetPlan';
import useSignup from '../../../../hooks/useSignup';
import useResendOtp from '../../../../hooks/useResendOtp';

const initialValues = {
  email_otp: '',
};

const validationSchema = yup.object().shape({
  email_otp: yup
    .string()
    .matches(/^\d+$/, 'Email OTP must contain numbers only')
    .required('Email OTP is required'),
});
function VerifyOtp() {
  const { mutate: resendOtpMutation } = useResendOtp();
  const [userData, setUserData] = useState(null);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const { mutate: verifyotp, isLoading: verifyotpLoad } = useSignup({
    handleSuccess,
  });
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    let intervalId;

    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            setIsTimerRunning(false);
            clearInterval(intervalId);
            return 0;
          } else {
            return prevSeconds - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isTimerRunning]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const [searchParams] = useSearchParams();
  const planUuid = searchParams.get('plan_uuid') || '';
  const duration = searchParams.get('duration') || '';
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const { mutate, data } = useGetPlan();
  const planDetails = data?.data?.data || {};

  useEffect(() => {
    if (planUuid && duration) {
      mutate({ plan_uuid: planUuid, plan_duration: duration });
    }
  }, [mutate, planUuid, duration]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (locationState?.userData) {
      setUserData(locationState?.userData);
    }
  }, [locationState?.userData]);

  const onSubmit = (values) => {
    const { email_otp = '' } = values;
    const {
      email,
      password,
      first_name,
      last_name,
      phone,
      country,
      tenant_company,
      tenant_npn,
      tenant_fein,
      timezone,
    } = userData || {
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      phone: '',
      country: '',
      tenant_company: '',
      tenant_npn: '',
      tenant_fein: '',
      timezone: '',
    };
    const payload = {
      email_otp,
      planID: planUuid,
      plan_duration: duration,
      email,
      password,
      first_name,
      last_name,
      phone,
      country,
      tenant_company,
      tenant_npn,
      tenant_fein,
      timezone,
      type: 'otp-verification',
    };
    verifyotp(payload);
  };

  function handleSuccess(data) {
    const uuid = data?.data?.uuid || {};
    navigate(
      `/payment-method?plan_uuid=${planUuid}&duration=${duration}&uuid=${uuid}`,
    );
  }

  const handleresendOTP = () => {
    if (isTimerRunning) return;
    resendOtpMutation({ otp_receiver: userData?.email, type: 'E' });
    setIsTimerRunning(true);
    setSeconds(60);
  };

  return (
    <>
      <div className="d--flex h--full oneTimePasswordPage p-t--lg p-b--lg">
        <div className="container">
          <div className="d--flex ">
            <PlanDetails
              PlanDetails={planDetails}
              plan_duration={duration}
              plan_uuid={planUuid}
            />
            <div className="w--full radius--md p-t--3xl m-t--3xl gap--xl w-max--600 p--xl z-index--xs bg--white box-shadow d--flex align-items--center justify-content--center flex--column  h-min--300">
              <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center">
                <h3 className="font--2xl text--primary">
                  One Time Password(OTP) Verification
                </h3>
                <div className="w-100 ">
                  <h5 className="font--md font--400 text--c d--flex align-items--center">
                    A code has been sent to&nbsp;
                    <div className="text--primary font--500">
                      {userData?.email ?? ''}
                    </div>
                  </h5>
                </div>
              </div>
              <form
                className="w--full h--full"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
                  <div>
                    <Controller
                      name="email_otp"
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          label="Email OTP"
                          type="text"
                          maxLength={4}
                          placeholder="Please enter the verification code"
                          error={errors?.email_otp?.message}
                        />
                      )}
                    />
                  </div>

                  <Link
                    className={`heading heading--xs font--600  c-pointer text  ${
                      isTimerRunning
                        ? 'c--not-allowed text--grey'
                        : 'c--pointer  text--info'
                    }`}
                    onClick={() => handleresendOTP(isTimerRunning)}
                  >
                    Resend OTP
                  </Link>

                  {formatTime(seconds)}

                  <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md">
                    <Button
                      type="submit"
                      size=""
                      variant="primary"
                      color="white"
                      btnClasses="btn"
                      disabled={verifyotpLoad}
                    >
                      {verifyotpLoad ? 'Please wait.....' : 'Verify OTP'}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyOtp;
