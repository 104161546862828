import React, { useContext, useEffect, useMemo, useState } from 'react';
import useIcons from '../../assets/icons/useIcons';
import Button from '../Button';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';
import Timer from '../SipComponents/Timer';
import { IncomingRing, toneLookup } from '../../helpers/functions';
import GetName from '../GetName';
import Draggable from 'react-draggable';
import { useAuth } from '../../hooks/useAuth';
import MultipleCallListing from '../Dialpad/MultipleCallListing';
import MultiCallImage from '../../assets/images/web/group-call-ico.png';
import GetNameBySocket from '../GetNameBySocket';
import { useCallSummary } from '../../hooks/useCallSummary';
import useDidList from '../../hooks/useDidList';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Avatar from '../Avatar';
import useAllNumberDetails from '../../hooks/useAllNumberDetails';
import GetNameBySocketObCall from '../GetNameBySocketObCall';

export default function CallPopup({
  callActiveKey,
  callSessions = [],
  setshow,
  setCallActiveKey,
}) {
  const {
    UserIcon,
    CallIcon,
    MobileIcon,
    DialerIcon,
    MoreVIcon,
    VoicemailIcon,
    UsersIcon,
    VoicemailGroupIcon,
    BackAngleIcon,
    ArrowRightIcon,
    RecordIcon,
    HoldIcon,
    MickOffIcon,
    MickIcon,
    PlayIcon,
  } = useIcons();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { getNumberName, getNumberImage } = useAllNumberDetails();
  let [searchParams, setSearchParams] = useSearchParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const activeKey = queryParameters.get('activeKey');
  const sessionQuery = queryParameters.get('session');
  const [showCallListing, setShowCallListing] = useState(false);
  const { setIsCallRecording, isRecording } = useCallSummary();

  callActiveKey = activeKey !== null ? activeKey : callActiveKey;

  callSessions =
    sessionQuery !== null
      ? JSON.parse(decodeURIComponent(sessionQuery))
      : callSessions;

  const activeCallData =
    callSessions.filter((call) => call?.callID === callActiveKey)?.[0] || {};

  const { socket = {} } = useContext(SipSocketContext);
  const { mutate, data } = useDidList();
  const listDID = data?.data?.data || [];
  const {
    _answerCall = () => null,
    _terminate = () => null,
    _uaSessions,
    _referUser,
    _muteCall,
    _toggleHold,
    _sendDtmf,
  } = socket;
  const {
    _status = '',
    _number = '',
    callID = '',
    _joined_at = '',
    _direction = '',
  } = activeCallData;

  const requiredObj =
    listDID &&
    listDID.length > 0 &&
    listDID?.filter((d) => d.did_number === user?.caller_id)?.[0]?.[
      'outgoing_call_recording'
    ]
      ? 'Y'
      : 'N';

  const isUARecordingEnabled =
    _direction === 'inbound'
      ? !!_uaSessions?.[callID]?._request?.headers?.['X-Record']
      : requiredObj === 'Y';

  useEffect(() => {
    mutate({ page: 1 });
  }, []);

  useEffect(() => {
    if (!isRecording) {
      setIsCallRecording(isUARecordingEnabled);
    }
  }, [isUARecordingEnabled]);

  useEffect(() => {
    const myTone =
      _direction === 'inbound'
        ? toneLookup?.find((item) => item?.value === user?.ringtone)?.toneObj ??
          IncomingRing
        : null;

    if (myTone) {
      if (['ringing'].includes(_status)) {
        myTone.play();
      } else {
        myTone.stop();
      }
    }
    return () => {
      if (myTone) {
        myTone.stop();
      }
    };
  }, [_status, _direction]);

  const handleTerminate = (callID) => {
    _terminate(callID);
  };

  const handleActiveCall = (key) => {
    if (callActiveKey === key) return;
    _answerCall(key);
  };

  const forwardIconLookup = {
    EXTENSION: (
      <span>
        <UserIcon />
      </span>
    ),

    VOICEMAIL: (
      <span>
        <VoicemailIcon />
      </span>
    ),
    NUMBER: (
      <span>
        <UserIcon />
      </span>
    ),
    DEPARTMENT: (
      <span>
        <UsersIcon />
      </span>
    ),
    IVR: (
      <span>
        <MobileIcon />
      </span>
    ),
    VOICEMAILGROUP: (
      <span>
        <VoicemailGroupIcon />
      </span>
    ),
  };

  const viaDID = useMemo(() => {
    return (
      _uaSessions?.[callActiveKey]?._request?.headers?.['X-Did']?.[0]?.raw ??
      null
    );
  }, [callActiveKey, _uaSessions]);

  const forwardName = useMemo(() => {
    return (
      _uaSessions?.[callActiveKey]?._request?.headers?.['X-Forwardname']?.[0]
        ?.raw ?? null
    );
  }, [callActiveKey, _uaSessions]);

  const forwardType = useMemo(() => {
    return (
      _uaSessions?.[callActiveKey]?._request?.headers?.['X-Forwardtype']?.[0]
        ?.raw ?? null
    );
  }, [callActiveKey, _uaSessions]);

  const connectedCalls = useMemo(() => {
    return (
      callSessions.filter((item) =>
        ['connected', 'mute'].includes(item?._status),
      ) || []
    );
  }, [callSessions]);

  const holdCalls = useMemo(() => {
    return callSessions.filter((item) => item?._status === 'hold') || [];
  }, [callSessions]);

  const ringingCalls = useMemo(() => {
    return (
      callSessions.filter((item) =>
        ['ringing', 'connecting'].includes(item?._status),
      ) || []
    );
  }, [callSessions]);

  const callRecording = (uuid) => {
    if (_number?.length <= 4) return;
    if (isRecording) {
      _sendDtmf(uuid, `*3`);
      setIsCallRecording(false);
    } else {
      _sendDtmf(uuid, `*2`);
      setIsCallRecording(true);
    }
  };

  return (
    <Draggable axis="both" handle="#one_to_one_call_popup" className="">
      <div
        id="one_to_one_call_popup"
        className="callPopup position--fixed z-index--md"
      >
        {showCallListing ? (
          <div className="border border-full--primary radius--sm p--sm h-min--250 h-max--450 overflow--auto w-min--300 bg--primary">
            <MultipleCallListing
              callActiveKey={callActiveKey}
              setCallActiveKey={setCallActiveKey}
              callSessions={callSessions}
            />
            <div
              // data-tooltip="Back"
              // tooltip-position="top"
              className="c--pointer text--white m-t--sm p-t--xs"
              onClick={() => setShowCallListing(false)}
            >
              <BackAngleIcon />
            </div>
          </div>
        ) : (
          <div className="d--flex align-items--start justify-content--center c--grab">
            {/* <div className="position--absolute p--sm left--0 text--white">
              <MoreVIcon width={6} height={12} />
              <MoreVIcon width={6} height={12} />
            </div> */}

            {['ringing', 'connecting'].includes(_status) ? (
              <div className="innerSec bg--primary w-min--300 w-max--300 h-min--250 w--full d--flex align-items--center justify-content--between p--sm radius--sm gap--xs flex--column ">
                {callSessions?.length > 1 && (
                  <div
                    className="w--full c--pointer bg--white-400 radius--sm h-min--28 d--flex justify-content--between align-items--center p-l--sm p-r--sm m-t--sm "
                    onClick={() => setShowCallListing(true)}
                  >
                    <div className="p-l--sm p-r--sm h-min--20 font--xs text--white d--flex align-items--center justify-content--center bg--danger radius--lg ">
                      Ringing | {ringingCalls?.length || 0}
                    </div>
                    <div className="p-l--sm p-r--sm h-min--20 font--xs text--white d--flex align-items--center justify-content--center bg--success radius--lg ">
                      Connected | {connectedCalls?.length || 0}
                    </div>
                    <div className="p-l--sm p-r--sm h-min--20 font--xs text--white d--flex align-items--center justify-content--center bg--warning radius--lg ">
                      Hold | {holdCalls?.length || 0}
                    </div>
                    <div
                      className="c--pointer w-max--25 text--white d--flex"
                      data-tooltip="View"
                      tooltip-position="left"
                    >
                      <ArrowRightIcon width={20} />
                    </div>
                  </div>
                )}
                <div className="w--full d--flex align-items--center gap--md flex--column">
                  {viaDID && (
                    <div className="d--flex align-items--center justify-content--between gap--lg text--white">
                      {viaDID && (
                        <div className="d--flex gap--sm align-items--center">
                          <div className="font--sm font--500">Via:</div>
                          <div className="font--sm">{viaDID}</div>
                        </div>
                      )}
                      {forwardType || forwardName ? (
                        forwardType && forwardType !== 'EXTENSION' ? (
                          <div className="d--flex gap--sm align-items--center">
                            {forwardType && (
                              <div className="font--sm font--500">
                                {forwardIconLookup?.[forwardType] || (
                                  <UserIcon />
                                )}
                              </div>
                            )}
                            {forwardName && (
                              <div className="font--sm">{forwardName}</div>
                            )}
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  )}
                  <div className="d--flex flex--column gap--sm  justify-content--center align-items--center w--full h-min--250 justify-content--between">
                    <div className="font--lg font--600  m-b--0 d--flex gap--sm align-items--center justify-content--center line-height--1 w--full">
                      <div className="w--full d--flex  gap--sm bg--white radius--sm p--sm justify-content-center align-items--center">
                        <div
                          className="w-min--60 w-max--60 h-min--60 h-max--60 w--full h--full radius--full d--flex  align-items--center justify-content--center text--primary  bg--contrast c--pointer"
                          onClick={() => {
                            if (activeCallData?._number?.length > 5) {
                              if (sessionStorage.getItem('cid')) {
                                navigate(
                                  `contacts?contactId=${sessionStorage.getItem(
                                    'cid',
                                  )}&showDialer=false`,
                                );
                              }
                            }
                          }}
                        >
                          <span>
                            {getNumberName(
                              activeCallData?._number,
                              false,
                              false,
                            )
                              ?.charAt(0)
                              ?.toUpperCase() ? (
                              <Avatar
                                first_name={
                                  getNumberName(
                                    activeCallData?._number,
                                    false,
                                    false,
                                  )?.split(' ')?.[0]
                                }
                                last_name={
                                  getNumberName(
                                    activeCallData?._number,
                                    false,
                                    false,
                                  )?.split(' ')?.[1] || ''
                                }
                                size="60"
                                image={getNumberImage(
                                  activeCallData?._number,
                                  false,
                                )}
                                shouldShowPresence={false}
                              />
                            ) : _direction === 'inbound' ? (
                              <GetNameBySocket
                                number={_number}
                                getAvatar={true}
                                getNumber={false}
                                avatarSize="60"
                              />
                            ) : (
                              <GetNameBySocketObCall
                                number={_number}
                                getAvatar={true}
                                getNumber={false}
                                avatarSize="60"
                              />
                            )}
                          </span>
                        </div>
                        <div className="d--flex flex--column gap--sm font--md">
                          {_number?.length < 5 ? (
                            <GetName number={_number} />
                          ) : _direction === 'inbound' ? (
                            <GetNameBySocket number={_number} />
                          ) : (
                            <GetNameBySocketObCall number={_number} />
                          )}
                          <div className="font--sm m-b--0 font--500 line-height--1">
                            {_number?.length < 5 ? 'EXT :' : ''}
                            &nbsp;{_number}
                          </div>
                          {/* <div className="  m-b--md font--500 line-height--1"></div> */}
                        </div>
                      </div>
                    </div>

                    <div className="w--ful w-max--150 d--flex gap--md">
                      {_direction === 'inbound' && (
                        <Button
                          variant="success"
                          rounded
                          btnClasses="w-min--50 w-max--50 h-min--50 h-max--50 w--full h--full radius--full d--flex align-items--center justify-content--center   c--pointer "
                          onClick={() => {
                            _answerCall(callID);
                          }}
                        >
                          <CallIcon width={25} height={25} />
                        </Button>
                      )}
                      <Button
                        variant="red"
                        rounded
                        style={{ transform: 'rotate(135deg)' }}
                        btnClasses="w-min--50 w-max--50 h-min--50 h-max--50 w--full h--full radius--full d--flex align-items--center bg--red justify-content--center   c--pointer "
                        onClick={() => handleTerminate(callID)}
                      >
                        <CallIcon width={25} height={25} />
                      </Button>
                      {_number?.length > 5 && _direction === 'inbound' && (
                        <Button
                          variant="white"
                          rounded
                          btnClasses="w-min--50 w-max--50 h-min--50 h-max--50 w--full h--full radius--full d--flex text--primary align-items--center bg--white justify-content--center   c--pointer "
                          onClick={() =>
                            _referUser(callActiveKey, `*${user?.extension}`)
                          }
                        >
                          <VoicemailIcon width={25} height={25} />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="innerSec bg--primary w-min--300 w-max--300 h-min--250 w--full d--flex align-items--center justify-content--between p--sm radius--sm gap--xs flex--column ">
                {callSessions?.length > 1 && (
                  <div
                    className="w--full c--pointer bg--white-400 radius--sm h-min--28 d--flex justify-content--between align-items--center p-l--sm p-r--sm m-t--sm "
                    onClick={() => setShowCallListing(true)}
                  >
                    <div className="p-l--sm p-r--sm h-min--20 font--xs text--white d--flex align-items--center justify-content--center bg--danger radius--lg ">
                      Ringing | {ringingCalls?.length || 0}
                    </div>
                    <div className="p-l--sm p-r--sm h-min--20 font--xs text--white d--flex align-items--center justify-content--center bg--success radius--lg ">
                      Connected | {connectedCalls?.length || 0}
                    </div>
                    <div className="p-l--sm p-r--sm h-min--20 font--xs text--white d--flex align-items--center justify-content--center bg--warning radius--lg ">
                      Hold | {holdCalls?.length || 0}
                    </div>
                    <div
                      className="c--pointer w-max--25 text--white d--flex"
                      data-tooltip="View"
                      tooltip-position="left"
                    >
                      <ArrowRightIcon width={20} />
                    </div>
                  </div>
                )}
                <div className="w--full d--flex align-items--center gap--md flex--column">
                  {viaDID && (
                    <div className="d--flex align-items--center justify-content--between gap--lg text--white">
                      {viaDID && (
                        <div className="d--flex gap--sm align-items--center">
                          <div className="font--sm font--500">Via:</div>
                          <div className="font--sm">{viaDID}</div>
                        </div>
                      )}
                      {forwardType || forwardName ? (
                        forwardType && forwardType !== 'EXTENSION' ? (
                          <div className="d--flex gap--sm align-items--center">
                            {forwardType && (
                              <div className="font--sm font--500">
                                {forwardIconLookup?.[forwardType] || (
                                  <UserIcon />
                                )}
                              </div>
                            )}
                            {forwardName && (
                              <div className="font--sm">{forwardName}</div>
                            )}
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  )}
                  <div className="d--flex flex--column gap--sm  justify-content--between align-items--center w--full  h-min--250">
                    <div className="w--full d--flex flex--column gap--sm bg--white radius--sm p--sm justify-content-center align-items--start">
                      <div className="font--md font--600  m-b--0 d--flex gap--sm align-items--start justify-content--center line-height--1">
                        <div
                          className="w-min--60 w-max--60 h-min--60 h-max--60 w--full h--full radius--full d--flex  align-items--center justify-content--center text--primary  bg--contrast c--pointer"
                          onClick={() => {
                            if (activeCallData?._number?.length > 5) {
                              if (sessionStorage.getItem('cid')) {
                                const contactInfo =
                                  searchParams && searchParams?.get('x-cn')
                                    ? searchParams?.get('x-cn')
                                    : null;

                                navigate(
                                  `contacts?contactId=${sessionStorage.getItem(
                                    'cid',
                                  )}&showDialer=true&x-cn=${contactInfo}`,
                                );
                              }
                              if (!sessionStorage.getItem('cid')) {
                                navigate(
                                  `contacts?contactId=""&showDialer=false&numberToSave=${activeCallData?._number}`,
                                );
                              }
                            }
                          }}
                        >
                          {['ringing', 'connecting'].includes(
                            activeCallData?._status,
                          ) ||
                          sessionStorage?.getItem('cid') ||
                          activeCallData?._number?.length < 5 ? (
                            <span>
                              {getNumberName(
                                activeCallData?._number,
                                false,
                                false,
                              )
                                ?.charAt(0)
                                ?.toUpperCase() ? (
                                <Avatar
                                  first_name={
                                    getNumberName(
                                      activeCallData?._number,
                                      false,
                                      false,
                                    )?.split(' ')?.[0]
                                  }
                                  last_name={
                                    getNumberName(
                                      activeCallData?._number,
                                      false,
                                      false,
                                    )?.split(' ')?.[1] || ''
                                  }
                                  size="60"
                                  image={getNumberImage(
                                    activeCallData?._number,
                                    false,
                                  )}
                                  shouldShowPresence={false}
                                />
                              ) : _direction === 'inbound' ? (
                                <GetNameBySocket
                                  number={_number}
                                  getAvatar={true}
                                  avatarSize="60"
                                />
                              ) : (
                                <GetNameBySocketObCall
                                  number={_number}
                                  getAvatar={true}
                                  avatarSize="60"
                                />
                              )}
                            </span>
                          ) : (
                            // <UserIcon width={30} height={30} />
                            <span className="font--xs d--flex justify-content--center">
                              Add <br /> contact
                            </span>
                          )}
                        </div>
                        <div className="d--flex flex--column gap--sm ">
                          {_number?.length < 5 ? (
                            <GetName number={_number} />
                          ) : _direction === 'inbound' ? (
                            <GetNameBySocket number={_number} />
                          ) : (
                            <GetNameBySocketObCall number={_number} />
                          )}
                          <div className="font--sm m-b--0 font--500 line-height--1">
                            {_number?.length < 5 ? 'EXT :' : ''}
                            &nbsp;{_number}
                          </div>
                          <div className="font--xs  m-b--md font--500 line-height--1">
                            <Timer start_time={_joined_at} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d--flex align-items--center w--full justify-content--center gap--xl">
                      <span className="items d--flex flex--column align-items--center justify-content--center gap--sm ">
                        <span
                          className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--transparent  c--pointer"
                          onClick={() => _muteCall(callID)}
                        >
                          {_status === 'mute' ? <MickOffIcon /> : <MickIcon />}
                        </span>
                        <span className="font--sm text--white">
                          {_status === 'mute' ? 'Unmute' : 'Mute'}
                        </span>
                      </span>
                      <span className="items d--flex flex--column align-items--center justify-content--center gap--sm ">
                        <span
                          className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--transparent  c--pointer"
                          onClick={() => _toggleHold(callID)}
                        >
                          {_status === 'hold' ? (
                            <PlayIcon width={20} height={20} />
                          ) : (
                            <HoldIcon width={20} height={20} />
                          )}
                        </span>
                        <span className="font--sm text--white">
                          {_status === 'hold' ? 'Unhold' : 'Hold'}
                        </span>
                      </span>
                      <span
                        className="items d--flex flex--column align-items--center justify-content--center gap--sm "
                        onClick={() => callRecording(callID)}
                      >
                        {_number?.length <= 4 ? (
                          <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center bg--transparent c--not-allowed opacity--sm">
                            <RecordIcon width={20} height={20} />
                          </span>
                        ) : isRecording ? (
                          <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--danger bg--transparent c--pointer">
                            <RecordIcon width={20} height={20} />
                          </span>
                        ) : (
                          <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--transparent  c--pointer">
                            <RecordIcon width={20} height={20} />
                          </span>
                        )}
                        <span className="font--sm text--white">Record</span>
                      </span>
                    </div>

                    <div
                      className="d--flex border-full--contrast align-items--center justify-content--center gap--sm radius--sm p--sm c--pointer w--full"
                      onClick={() => setshow(true)}
                    >
                      <Button
                        variant="contrast"
                        rounded
                        btnClasses="w-min--25 w-max--25 h-min--25 h-max--25 w--full h--full radius--full d--flex align-items--center justify-content--center border-full--contrast c--pointer"
                        color="primary"
                      >
                        <DialerIcon width={16} height={16} />
                      </Button>
                      <div className="text--white">More Dialpad Options</div>
                    </div>
                    <div className="w--ful w-max--150 d--flex gap--md">
                      <Button
                        variant="red"
                        rounded
                        btnClasses="w-min--50 w-max--50 h-min--50 rotate--135 h-max--50 w--full h--full radius--full d--flex align-items--center justify-content--center bg--red   c--pointer "
                        onClick={() => handleTerminate(callID)}
                      >
                        <CallIcon width={25} height={25} />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Draggable>
  );
}
