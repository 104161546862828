import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import PopoverItem from './PopoverItem';

const borderLookup = {
  online: 'success',
  offline: 'danger',
  device_online: 'success',
  device_offline: 'danger',
  call_start: 'success',
  call_end: 'danger',
  voicemail: 'primary',
  voicemailgroup: 'primary',
  missedcall: 'danger',
};

const ActivityItem = ({ activity, hour }) => {
  const activityRef = useRef();
  const [leftPosition, setLeftPosition] = useState(0);

  useEffect(() => {
    calculatePosition();
    window.addEventListener('resize', calculatePosition);
    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
  }, [activity]);

  function calculatePosition() {
    // const activityMomentObject = moment(activity?.timestamp);
    const activityMinutes = moment(activity?.timestamp).minutes();
    const maxWidth = activityRef?.current.parentElement.offsetWidth;
    const minuteWidth = maxWidth / 60;
    const activityLeft = minuteWidth * activityMinutes;
    setLeftPosition(activityLeft);
  }

  function handleActivityView(activity) {
    const isActivityItemExist = activity?.activityItem?.length > 0;
    const activityType = activity?.activity;
    const isAllOffline = activity?.activityItem?.every((item) => {
      return item?.activity?.includes('offline');
    });
    return (
      <div
        className={`  right---10  w--full h--full radius--full  d--flex align-items--center justify-content--center`}
      >
        {!isActivityItemExist && activityType === 'offline' && (
          <>
            <PopoverItem
              id={`offlineActivity${activity?.id}`}
              activity={activity}
            />
          </>
        )}
        {!isActivityItemExist && activityType === 'online' && (
          <>
            <PopoverItem
              id={`onlineActivity${activity?.id}`}
              activity={activity}
            />
          </>
        )}
        {!isActivityItemExist && activityType === 'call_start' && (
          <>
            <PopoverItem
              id={`callStartActivity${activity?.id}`}
              activity={activity}
            />
          </>
        )}
        {!isActivityItemExist && activityType === 'call_end' && (
          <>
            <PopoverItem
              id={`callEndActivity${activity?.id}`}
              activity={activity}
            />
          </>
        )}
        {!isActivityItemExist && activityType === 'voicemail' && (
          <>
            <PopoverItem
              id={`voicemailActivity${activity?.id}`}
              activity={activity}
            />
          </>
        )}
        {!isActivityItemExist && activityType === 'voicemailgroup' && (
          <>
            <PopoverItem
              id={`voicemailGroupActivity${activity?.id}`}
              activity={activity}
            />
          </>
        )}
        {!isActivityItemExist && activityType === 'missedcall' && (
          <>
            <PopoverItem
              id={`missCallActivity${activity?.id}`}
              activity={activity}
            />
          </>
        )}
        {isActivityItemExist &&
          ['device_online', 'device_offline'].includes(activityType) && (
            <PopoverItem
              id={`${activityType}${activity?.id}`}
              activity={activity}
              single={false}
              activityType={activityType}
            />
          )}
      </div>
    );
  }
  const activityType = activity?.activity;
  return (
    <div
      ref={activityRef}
      style={{
        left: leftPosition,
        // top: ,
        transform: `translateY(${
          ['call_start', 'online'].includes(activityType)
            ? '-100'
            : ['call_end', 'offline'].includes(activityType)
            ? '0'
            : '-50'
        }%)`,
      }}
      className="position--absolute d--flex align-items--center justify-content--center"
    >
      {handleActivityView(activity)}
    </div>
  );
};

export default ActivityItem;
