import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { removeZoho } from '../../api';

export default function useRemoveZoho() {
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();
  const removeZohoRequest = useMutation({
    mutationFn: removeZoho,
    mutationKey: 'removeZoho',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(['getUserDetails']);
      showAlert({
        type: 'success',
        message: data?.message ?? '',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...removeZohoRequest };
}
