import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../comopnents/Spinner';
const LoginViaAdmin = () => {
  const { token } = useParams();
  useEffect(() => {
    if (token) {
      localStorage.setItem('nph-acc-tkn', token);
      window.location.href = '/dashboard';
    }
  }, [token]);

  return <Spinner />;
};

export default LoginViaAdmin;
