import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormInput from '../../../../comopnents/FormInput';
import useIcons from '../../../../assets/icons/useIcons';
import AudioPlayer from '../../../../comopnents/AudioPlayer';
import FormSelect from '../../../../comopnents/FormSelect';
import Button from '../../../../comopnents/Button';
import ForwardToInput from '../../../../comopnents/ForwardToInput';
import KeyActions from './KeyActions';
import useIVRUpsert from '../../../../hooks/useIVRUpsert';
import useGreetingList from '../../../../hooks/useGreetingList';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useIVRList from '../../../../hooks/useIVRList';
import useRingGroupList from '../../../../hooks/useRingGroupList';
import useMemberAgentsAllListing from '../../../../hooks/useMemberAgentsAllListing';

const initialValues = {
  name: '',
  greeting_short: '',
  ivr_option: [
    {
      key: '',
      type: '',
      value: '',
    },
  ],
  invalid_count: 3,
  invalid_sound: '',
  exec_on_invalid: {
    type: '',
    value: '',
  },

  no_input_count: 15,
  no_input_sound: '',
  exec_on_no_input: {
    type: '',
    value: '',
  },
};

const RenderGreetingItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.filename}
      value={item.filename}
    >
      {item.name}
    </option>
  );
};
const forwardingOptions = [
  'Extension',
  'Number',
  // 'IVR',
  'Department',
  'Voicemail',
  'Department Voicemail',
  'Hangup',
];

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Name cannot contain numbers'),

  greeting_short: yup.string().required('Menu action message is required'),
  invalid_count: yup
    .number()
    .typeError('Invalid count is required')
    .min(3, 'Minimum value should be 3')
    // .max(10, 'Maximum value should be 10')
    .required('Invalid count is required'),
  exec_on_invalid: yup.object({
    type: yup.mixed().required('This field is required'),
    value: yup.string().when('type', {
      is: (val) => ['Voicemail', 'Extension', 'Number'].includes(val),
      then: yup.string().required(' '),
    }),
  }),
  invalid_sound: yup.string().required('Invalid retry is required'),
  exec_on_no_input: yup.object({
    type: yup.mixed().required('This field is required'),
    value: yup.string().when('type', {
      is: (val) => ['Voicemail', 'Extension', 'Number'].includes(val),
      then: yup.string().required(' '),
    }),
  }),
  ivr_option: yup.array().of(
    yup.object().shape({
      key: yup.string().required('Required'),
      type: yup.string().required('Required'),
      value: yup.string().when('type', {
        is: (res) => ['Extension', 'Number', 'Voicemail', 'IVR'].includes(res),
        then: yup.string().required(' '),
      }),
    }),
  ),
});
const AddIVR = ({ handleClose = () => null, editIVRInstance }) => {
  const [isPlayingData, setIsPlayingData] = useState({
    playing: false,
    playingType: '',
  });
  const { HoldIcon, PlayIcon } = useIcons();
  const [memberList, setMemberList] = useState([]);
  const [greetingList, setGreetingList] = useState([]);
  const {
    mutateAsync,
    data,
    isLoading: memberListLoad,
  } = useMemberAgentsAllListing();
  const {
    mutate,
    data: greetingListData,
    isLoading: greetingListLoad,
  } = useGreetingList();
  const {
    mutate: ringGroupListMutate,
    data: ringGroupListData,
    isLoading: ringGroupListLoad,
  } = useRingGroupList();
  const {
    mutate: ivrListMutate,
    data: ivrListData,
    isLoading: ivrListLoad,
  } = useIVRList();

  const { mutateAsync: ivrUpsertMutate, isLoading: ivrUpsertLoad } =
    useIVRUpsert();

  useEffect(() => {
    if (!greetingListData?.data) {
      mutate();
    } else {
      setGreetingList(greetingListData?.data?.data ?? []);
    }
  }, [greetingListData]);

  useEffect(() => {
    ringGroupListMutate();
    ivrListMutate();
  }, []);
  useEffect(() => {
    if (!data?.data) {
      mutateAsync({ get_owner: 'Y' });
    } else {
      setMemberList(data?.data?.data ?? []);
    }
  }, [data]);

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });
  useEffect(() => {
    if (editIVRInstance && editIVRInstance?.isEdit) {
      const {
        name,
        greeting_short,
        ivr_option,
        invalid_sound,
        max_timeouts,
        max_failures,
        no_input_sound,
        exec_on_max_failures,
        exec_on_max_timeouts,
      } = editIVRInstance?.editData || {};
      setValue('name', name);
      setValue('greeting_short', greeting_short);
      setValue('ivr_option', JSON.parse(ivr_option));
      setValue('invalid_count', max_failures);
      setValue('no_input_count', max_timeouts);
      setValue('invalid_sound', invalid_sound);
      setValue('no_input_sound', no_input_sound);
      setValue('exec_on_invalid', JSON.parse(exec_on_max_failures));
      setValue('exec_on_no_input', JSON.parse(exec_on_max_timeouts));
    }
  }, [
    editIVRInstance,
    memberList,
    ringGroupListData?.data?.data,
    ivrListData?.data?.data,
    greetingList,
  ]);

  const onSubmit = async (values) => {
    const {
      name = '',
      greeting_short = '',
      invalid_sound = '',
      no_input_sound = '',
      invalid_count,
      no_input_count,
      ivr_option,
      exec_on_invalid,
      exec_on_no_input,
    } = values;
    const payload = {
      name,
      greeting_short,
      invalid_sound,
      no_input_sound,
      ivr_option,
      max_failures: invalid_count,
      max_timeouts: no_input_count,
      exec_on_max_failures: exec_on_invalid,
      exec_on_max_timeouts: exec_on_no_input,
    };
    if (editIVRInstance?.isEdit) {
      payload.uuid = editIVRInstance?.editData?.uuid;
    }
    await ivrUpsertMutate(payload);
    handleClose();
  };
  const isGreeting = watch('greeting_short');
  const isInvalidSound = watch('invalid_sound');
  const isNoInputSound = watch('no_input_sound');
  const loading = ivrUpsertLoad;
  return (
    <form
      className="w--full h--full"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--lg p--md">
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="Name"
                placeholder="Enter Name"
                error={errors?.name?.message}
              />
            )}
          />
        </div>
        <div className="d--flex align-items--center gap--md">
          <Controller
            name="greeting_short"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                options={
                  greetingList?.filter((item) => item?.type === 'greeting') ||
                  []
                }
                renderOption={RenderGreetingItem}
                optionListLoad={greetingListLoad}
                placeholder="Select Greeting"
                label="Menu Action Message22"
                error={errors?.greeting_short?.message}
              />
            )}
          />
          {isGreeting && (
            <>
              {isPlayingData.playing &&
              isPlayingData.playingType === 'greeting' ? (
                <div
                  className="c--pointer m-t--xl"
                  onClick={() => {
                    setIsPlayingData({
                      playing: false,
                      playingType: '',
                    });
                  }}
                >
                  <HoldIcon width={20} height={20} />
                  <AudioPlayer
                    onClose={() => {
                      setIsPlayingData({
                        playing: false,
                        playingType: '',
                      });
                    }}
                    type="greeting"
                    name={watch('greeting_short')}
                  />
                </div>
              ) : (
                <div
                  className="c--pointer d--flex h--full align-items--center m-t--xl text--success"
                  onClick={() => {
                    setIsPlayingData({
                      playing: true,
                      playingType: 'greeting',
                    });
                  }}
                >
                  <PlayIcon width={20} height={20} />
                </div>
              )}
            </>
          )}
        </div>
        <div>
          <KeyActions
            control={control}
            register={register}
            watch={watch}
            memberList={memberList}
            memberListLoad={memberListLoad}
            departmentList={ringGroupListData?.data?.data || []}
            ivrList={ivrListData?.data?.data || []}
            errors={errors}
            greetingList={greetingList}
            setValue={setValue}
          />
        </div>
        <div>
          <div className="d--flex flex--column gap--md m-t--md">
            <div className="font--600 font--sm text--primary">
              In case of invalid input
            </div>
            <div className="d--flex w--full">
              <div className="d--flex w--full flex--column">
                <label className="label--control font--sm m-b--xs font--500  text--black w-min--100">
                  Allow
                </label>
                <div className="d--flex w--full align-items--center gap--md">
                  <span className="w-max--150">
                    <Controller
                      name="invalid_count"
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          type="number"
                          error={errors?.invalid_count?.message}
                          min={0}
                        />
                      )}
                    />
                  </span>

                  <span>retries</span>
                </div>
              </div>
              <div className="d--flex   w--full flex--column">
                {/* <label className="label--control font--sm font--500 m-b--xs text--black w-min--100">
                  Play
                </label> */}
                <div className="d--flex w--full align-items--center gap--md">
                  <span className="d--flex gap--md align-items--center">
                    <div className="w-min--150">
                      <Controller
                        name="invalid_sound"
                        control={control}
                        render={({ field }) => (
                          <FormSelect
                            {...field}
                            options={
                              greetingList?.filter(
                                (item) => item?.type === 'greeting',
                              ) || []
                            }
                            optionListLoad={greetingListLoad}
                            renderOption={RenderGreetingItem}
                            placeholder="Select Greeting"
                            label="Play"
                            error={errors?.invalid_sound?.message}
                          />
                        )}
                      />
                    </div>
                    {isInvalidSound && (
                      <>
                        {isPlayingData.playing &&
                        isPlayingData.playingType === 'invalid' ? (
                          <div
                            className="c--pointer m-t--xl"
                            onClick={() => {
                              setIsPlayingData({
                                playing: false,
                                playingType: '',
                              });
                            }}
                          >
                            <HoldIcon width={20} height={20} />
                            <AudioPlayer
                              onClose={() => {
                                setIsPlayingData({
                                  playing: false,
                                  playingType: '',
                                });
                              }}
                              type="greeting"
                              name={watch('invalid_sound')}
                            />
                          </div>
                        ) : (
                          <div
                            className="c--pointer d--flex h--full align-items--center text--success m-t--xl"
                            onClick={() => {
                              setIsPlayingData({
                                playing: true,
                                playingType: 'invalid',
                              });
                            }}
                          >
                            <PlayIcon width={20} height={20} />
                          </div>
                        )}
                      </>
                    )}
                  </span>
                  <span className="m-t--lg">message</span>
                </div>
              </div>
            </div>
            <div className="d--flex  w--full flex--column">
              <label className="label--control font--sm font--500 m-b--xs text--black w-min--100">
                Else forward to
              </label>
              <span className="w--full">
                <ForwardToInput
                  control={control}
                  register={register}
                  watch={watch}
                  forwardOptions={forwardingOptions}
                  forward_type_key="exec_on_invalid.type"
                  forward_value_key="exec_on_invalid.value"
                  memberListData={memberList}
                  memberListLoad={memberListLoad}
                  departmentList={ringGroupListData?.data?.data || []}
                  ivrList={ivrListData?.data?.data || []}
                  greetingList={greetingList}
                  setValue={setValue}
                  forward_type_error={errors?.exec_on_invalid?.type?.message}
                  forward_value_error={errors?.exec_on_invalid?.value?.message}
                  greetingListLoad={greetingListLoad}
                  ivrListLoad={ivrListLoad}
                  ringGroupListLoad={ringGroupListLoad}
                />
              </span>
            </div>
          </div>
        </div>

        <div>
          <div className="d--flex flex--column gap--md  m-t--md">
            <div className="font--600 font--sm text--primary">
              In case of no input
            </div>
            <div className="d--flex w--full">
              <div className="d--flex  w--full flex--column">
                <label className="label--control font--sm font--500 m-b--xs text--black w-min--100">
                  Allow
                </label>
                <div className="d--flex  w--full align-items--center gap--md">
                  <span className="w-max--150 ">
                    <Controller
                      name="no_input_count"
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          type="number"
                          error={errors?.no_input_count?.message}
                          min={0}
                        />
                      )}
                    />
                  </span>

                  <span>retries</span>
                </div>
              </div>
              <div className="d--flex w--full flex--column">
                {/* <label className="label--control font--sm font--500 m-b--xs text--black w-min--100">
                  Play
                </label> */}
                <div className="d--flex  w--full align-items--center gap--md">
                  <span className="d--flex gap--md align-items--center">
                    <div className=" w-min--150">
                      <Controller
                        name="no_input_sound"
                        control={control}
                        render={({ field }) => (
                          <FormSelect
                            {...field}
                            options={
                              greetingList?.filter(
                                (item) => item?.type === 'greeting',
                              ) || []
                            }
                            optionListLoad={greetingListLoad}
                            renderOption={RenderGreetingItem}
                            placeholder="Select Greeting"
                            label="Play"
                            error={errors?.no_input_sound?.message}
                          />
                        )}
                      />
                    </div>
                    {isNoInputSound && (
                      <>
                        {isPlayingData.playing &&
                        isPlayingData.playingType === 'no_input' ? (
                          <div
                            className="c--pointer m-t--xl"
                            onClick={() => {
                              setIsPlayingData({ playing: false, playing: '' });
                            }}
                          >
                            <HoldIcon width={20} height={20} />
                            <AudioPlayer
                              onClose={() => {
                                setIsPlayingData({
                                  playing: false,
                                  playing: '',
                                });
                              }}
                              type="greeting"
                              name={watch('no_input_sound')}
                            />
                          </div>
                        ) : (
                          <div
                            className="c--pointer d--flex h--full align-items--center text--success m-t--xl"
                            onClick={() => {
                              setIsPlayingData({
                                playing: true,
                                playingType: 'no_input',
                              });
                            }}
                          >
                            <PlayIcon width={20} height={20} />
                          </div>
                        )}
                      </>
                    )}
                  </span>
                  <span className="m-t--lg">message</span>
                </div>
              </div>
            </div>
            <div className="d--flex flex--column w--full">
              <label className="label--control font--sm font--500 m-b--xs  text--black w-min--150">
                Else forward to
              </label>
              <span className=" w--full">
                <ForwardToInput
                  control={control}
                  register={register}
                  watch={watch}
                  forwardOptions={forwardingOptions}
                  forward_type_key="exec_on_no_input.type"
                  forward_value_key="exec_on_no_input.value"
                  memberListData={memberList}
                  memberListLoad={memberListLoad}
                  departmentList={ringGroupListData?.data?.data || []}
                  ivrList={ivrListData?.data?.data || []}
                  setValue={setValue}
                  greetingList={greetingList}
                  forward_type_error={errors?.exec_on_no_input?.type?.message}
                  forward_value_error={errors?.exec_on_no_input?.value?.message}
                  greetingListLoad={greetingListLoad}
                  ivrListLoad={ivrListLoad}
                  ringGroupListLoad={ringGroupListLoad}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={loading}
        >
          {loading ? 'Please wait..' : 'Save'}
        </Button>
      </div>
    </form>
  );
};

export default AddIVR;
