import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { memberActivate } from '../../api';

export default function useMemberActivate({ handleSuccess = () => {} }) {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: memberActivate,
    mutationKey: 'memberActivate',
    onSuccess: ({ data }) => {
      handleSuccess();
      showAlert({
        type: 'success',
        message: data?.message ?? 'Member activated successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
