'use client';
import React, { useState } from 'react';
import FormInput from '../../comopnents/FormInput';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import Button from '../../comopnents/Button';
import useLogin from '../../hooks/useLogin';
import useIcons from '../../assets/icons/useIcons';
import { OuterHeader } from '../Website/OuterHeader';
import { OuterFooter } from '../Website/OuterFooter';
import { isElectron } from '../../helpers/functions';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

export default function Login() {
  const { EyeOffIcon, EyeIcon } = useIcons();
  const [visibilityToggle, setvisibilityToggle] = useState(true);
  const { mutate, isLoading } = useLogin();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  function onSubmit(values) {
    mutate({ ...values, device_type: isElectron ? 'D' : 'W' });
  }
  return (
    <div className="w--full  d--flex h--full flex--column  loginPage outerPage">
      {!isElectron && <OuterHeader fromLogin={true} />}

      <div className="w--full  d--flex h--full flex--column align-items--center justify-content--center m-t--md m-b--md">
        <div className="radius--md  gap--xl w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box-shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
          <div className="w--full">
            <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center">
              <h3 className="font--2xl text--primary">Login</h3>
              <h5 className="font--md font--400">Login with Email address</h5>
            </div>

            <form
              className="w--full d--flex flex--column gap--lg"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="text"
                    label="Email"
                    placeholder="Enter your email address"
                    error={errors?.email?.message}
                    height="40"
                    autoFocus
                  />
                )}
              />
              <div className="form-group mb-2 position-relative">
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <div className="position-relative">
                      <label className="label--control font--sm font--500 m-b--xs  text--black d--flex">
                        Password
                      </label>
                      <div className="w--full position--relative">
                        <FormInput
                          {...field}
                          // label="Password"
                          name="password"
                          type={visibilityToggle ? 'password' : 'text'}
                          placeholder="Enter password"
                          errors={errors}
                        />
                        <div
                          className="pointer svg--sm position--absolute top--9 right--10 translate-middle-y inputIcon"
                          onClick={() => setvisibilityToggle(!visibilityToggle)}
                        >
                          {!visibilityToggle ? (
                            <EyeIcon width={20} height={20} />
                          ) : (
                            <EyeOffIcon width={20} height={20} />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>

              <Link
                to="/forgot-password"
                className="text--black font--sm w--full d--flex justify-content--end c--pointer"
              >
                Forgot password?
              </Link>
              <div className="form-group w--full">
                <Button
                  btnClasses="btn"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                  size="md"
                  variant="primary"
                  color="white"
                >
                  {isLoading ? 'Please wait...' : 'Login'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {!isElectron && (
        <div className="w--full z-index--sm">
          <OuterFooter />
        </div>
      )}
    </div>
  );
}
