import React, { useState } from 'react';
import FormInput from '../FormInput';
import Dropdown from '../Dropdown';
import useIcons from '../../assets/icons/useIcons';

const handleGetValue = (value, options = [], valueKey = '', labelKey = '') => {
  const reqObj =
    options?.filter((opt) => `${opt?.[valueKey]}` === `${value}`)?.[0] ?? null;
  if (reqObj) {
    return `${reqObj?.[labelKey]}`;
  }
  return '';
};

const CustomSingleDropdown = ({
  optionList = [],
  labelKey = 'label',
  valueKey = 'value',
  label = '',
  placeholder = '',
  error = '',
  withCaret = false,
  border = true,
  extraClasses = '',
  paddingRight = 'md',
  paddingLeft = 'md',
  RenderOptionItem = () => <></>,
  showCustomLabel = false,
  getCustomLabel = () => null,
  ...rest
}) => {
  const { CaretIcon } = useIcons();
  const [searchValue, setSearchValue] = useState('');

  const dropListNotification = {
    component: ({ item }) => {
      return (
        <div
          onClick={() => {
            setSearchValue('');
            rest?.onChange(`${item?.[valueKey]}`);
          }}
          className="w--full p--sm"
        >
          <RenderOptionItem item={item} />
        </div>
      );
    },
    data: searchValue
      ? optionList.filter((option) =>
          option?.[labelKey]
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()),
        ) || []
      : optionList,
  };
  return (
    <div className="w--full  d--flex flex--column">
      <Dropdown
        closeOnClickOutside={true}
        dropList={dropListNotification}
        showcaret={withCaret}
        caretComponent={CaretIcon}
        extraClasses="w--full"
      >
        <FormInput
          type="text"
          extraClasses={`w--full ${extraClasses}`}
          label={label}
          placeholder={
            showCustomLabel
              ? getCustomLabel(`${rest?.value}`, optionList, valueKey)
              : handleGetValue(`${rest?.value}`, optionList, valueKey, labelKey)
          }
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          error={error}
          border={border}
          paddingRight={paddingRight}
          paddingLeft={paddingLeft}
        />
      </Dropdown>
    </div>
  );
};

export default CustomSingleDropdown;
