import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useSetPassword from '../../../hooks/useSetPassword';
import FormInput from '../../../comopnents/FormInput';
import useIcons from '../../../assets/icons/useIcons';
import Switch from '../../../comopnents/Switch';
import SingleDropdownMain from '../../../comopnents/SingleDropdownMain';
import AsideModal from '../../../comopnents/AsideModal';
import AddGreetingForm from '../../Call/CustomGreetings/AddGreetingForm';
import useGreetingList from '../../../hooks/useGreetingList';
import AudioPlayer from '../../../comopnents/AudioPlayer';
import useProfileUpdate from '../../../hooks/useProfileUpdate';
import { useAuth } from '../../../hooks/useAuth';
import { toneLookup } from '../../../helpers/functions';
import AVSettings from './AVSettings';
import MissedCallSettings from './MissedCallSettings';

const RenderGreetingItem = ({ item, onChange, setSearchValue, setShow }) => {
  return (
    <div
      className="text--black h-min--32 d--flex align-items--center justify-content--start p--sm w--full"
      key={item.filename}
      onClick={() => {
        setSearchValue('');
        onChange(item.filename);
        setShow(false);
      }}
    >
      {item.name}
    </div>
  );
};

const RenderToneItem = ({ item, onChange, setSearchValue, setShow }) => {
  return (
    <div
      className="text--black h-min--32 d--flex align-items--center justify-content--start p--sm w--full"
      key={item.value}
      onClick={() => {
        setSearchValue('');
        onChange(item.value);
        setShow(false);
      }}
    >
      {item.name}
    </div>
  );
};

const validationSchema = yup.object().shape({
  old_password: yup.string().required(`Old password field is required.`),
  new_password: yup
    .string()
    .required('New password field is required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  confirm_password: yup
    .string()
    .required('Confirm password field is required.')
    .when('new_password', {
      is: (newPassword) => newPassword && newPassword.length > 0,
      then: yup
        .string()
        .oneOf([yup.ref('new_password')], 'Passwords do not match'),
    }),
});

const initialValues = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};

const initialValuesVoiceMail = {
  vm_file: 'DEFAULT',
  // email_vm: false,
  enable_vm: false,
};
const initialValuesRingtone = {
  name: '',
};
const initialValuesNotifications = {
  voicemail: {
    email: false,
    nextere: false,
  },
  missed: { email: false, nextere: false },
  sms: { email: false, nextere: false },
};

export default function ChangePassword() {
  const { user } = useAuth();
  const [addGlobalGreeting, setAddGlobalgreeting] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRingtonePlaying, setIsRingtonePlaying] = useState(false);

  const [greetingList, setGreetingList] = useState([]);
  const [visibilityToggle, setVisibilityToggle] = useState({
    old_password: true,
    new_password: true,
    confirm_password: true,
  });
  const { EyeOffIcon, EyeIcon, PlusIcon, PlayIcon, HoldIcon } = useIcons();

  const { mutateAsync: voicemailUpdateMutate, isLoading: voicemailUpdateLoad } =
    useProfileUpdate();
  const { mutateAsync: ringtoneUpdateMutate, isLoading: ringtoneUpdateLoad } =
    useProfileUpdate();
  const {
    mutateAsync: notificationUpdateMutate,
    isLoading: notificationUpdateLoad,
  } = useProfileUpdate();
  const {
    mutate,
    data: greetingListData,
    isLoading: greetingListLoad,
  } = useGreetingList();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const {
    control: controlVoicemail,
    handleSubmit: handleSubmitVoicemail,
    watch,
    setValue,
  } = useForm({
    defaultValues: initialValuesVoiceMail,
    mode: 'onChange',
  });
  const {
    control: ringtoneControl,
    handleSubmit: handleSubmitRingtone,
    watch: ringtoneWatch,
    setValue: ringtoneSetValue,
  } = useForm({
    defaultValues: initialValuesRingtone,
    mode: 'onChange',
  });
  const {
    control: controlNotification,
    handleSubmit: handleSubmitNotifications,
    setValue: notificationSetValue,
  } = useForm({
    defaultValues: initialValuesNotifications,
    mode: 'onChange',
  });

  const { mutate: setPasswordMutate, isLoading } = useSetPassword();

  async function onSubmit({
    old_password = '',
    new_password = '',
    confirm_password = '',
  }) {
    const payload = {
      old_password,
      new_password,
      confirm_password,
    };
    setPasswordMutate(payload);
  }

  useEffect(() => {
    if (!greetingListData?.data) {
      mutate();
    } else {
      setGreetingList(greetingListData?.data?.data ?? []);
    }
  }, [greetingListData]);

  useEffect(() => {
    if (user?.vm_setting) {
      setValue(
        'vm_file',
        user?.vm_setting?.vm_file === 'DEFAULT'
          ? ''
          : user?.vm_setting?.vm_file,
      );
      // setValue('email_vm', user?.vm_setting?.email_vm === 'Y' ? true : false);
      setValue('enable_vm', user?.vm_setting?.enable_vm ? true : false);
    }
  }, [user?.vm_setting]);

  useEffect(() => {
    if (user?.notification_settings) {
      const { sms, missed, voicemail } = user?.notification_settings || {};
      notificationSetValue('missed.email', missed?.email);
      notificationSetValue('missed.nextere', missed?.nextere);
      notificationSetValue('sms.email', sms?.email);
      notificationSetValue('sms.nextere', sms?.nextere);
      notificationSetValue('voicemail.email', voicemail?.email);
      notificationSetValue('voicemail.nextere', voicemail?.nextere);
    }
  }, [user?.notification_settings]);

  useEffect(() => {
    if (user?.ringtone) {
      ringtoneSetValue('name', user?.ringtone || '');
    }
  }, [user?.ringtone]);

  function handleClose() {
    setAddGlobalgreeting(false);
  }
  function submitRingtone(values) {
    ringtoneUpdateMutate({ ringtone: values?.name });
  }
  function submitNotification(values) {
    const { missed, sms, voicemail } = values;
    const payload = {
      notification_settings: {
        voicemail: {
          email: voicemail?.email,
          nextere: voicemail?.nextere,
        },
        missed: {
          email: missed?.email,
          nextere: missed?.nextere,
        },
        sms: {
          email: sms?.email,
          nextere: sms?.nextere,
        },
      },
    };
    notificationUpdateMutate(payload);
  }

  async function onSubmitVoicemail({
    enable_vm = '',
    vm_file = '',
    // email_vm = '',
  }) {
    const payload = {
      enable_vm: enable_vm,
      vm_file: enable_vm ? vm_file : '',
      // email_vm: email_vm ? 'Y' : 'N',
    };

    voicemailUpdateMutate({ vm_setting: payload });
  }

  const isGreeting = watch('vm_file') === 'DEFAULT' ? '' : watch('vm_file');
  const isTone = ringtoneWatch('name');

  return (
    <div className="w--full h--full changePasswordPage">
      <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
        Settings
      </h4>
      <div className="d--flex flex--column gap--xl profilePage bg--white p--md radius--sm h--full overflow--auto box-shadow--xs w--full ">
        <div className="d--flex gap--2xl">
          <div className="w--full d--flex gap--md flex--column">
            <div className="w--full d--flex gap--md flex--column">
              <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
                Password Details
              </div>
            </div>
            <form
              className="w--full h--full d--flex flex--column gap--md"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <div className="w--full h--full d--flex flex--column gap--xl">
                <Controller
                  name="old_password"
                  control={control}
                  render={({ field }) => (
                    <div className="position-relative">
                      <label
                        className={`label--control font--sm font--500 m-b--xs  text--black d--flex
                    ${
                      errors?.old_password?.message
                        ? 'text--red'
                        : 'text--black'
                    }
                    `}
                      >
                        {errors?.old_password?.message ?? 'Old Password'}
                      </label>
                      <div className="w--full position--relative">
                        <FormInput
                          {...field}
                          type={
                            visibilityToggle.old_password ? 'password' : 'text'
                          }
                          maxLength={50}
                          placeholder="Enter your old password"
                        />
                        <div
                          className="pointer svg--sm position--absolute top--9 right--10 translate-middle-y inputIcon"
                          onClick={() => {
                            setVisibilityToggle((prev) => ({
                              ...prev,
                              old_password: !prev.old_password,
                            }));
                          }}
                        >
                          {!visibilityToggle.old_password ? (
                            <EyeIcon width={20} height={20} />
                          ) : (
                            <EyeOffIcon width={20} height={20} />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name="new_password"
                  control={control}
                  render={({ field }) => (
                    <div className="position-relative">
                      <label
                        className={`label--control font--sm font--500 m-b--xs  d--flex
                    ${
                      errors?.new_password?.message
                        ? 'text--red'
                        : 'text--black'
                    }
                    `}
                      >
                        {errors?.new_password?.message ?? 'New Password'}
                      </label>
                      <div className="w--full position--relative">
                        <FormInput
                          {...field}
                          type={
                            visibilityToggle.new_password ? 'password' : 'text'
                          }
                          maxLength={50}
                          placeholder="Enter your new password"
                        />
                        <div
                          className="pointer svg--sm position--absolute top--9 right--10 translate-middle-y inputIcon"
                          onClick={() => {
                            setVisibilityToggle((prev) => ({
                              ...prev,
                              new_password: !prev.new_password,
                            }));
                          }}
                        >
                          {!visibilityToggle.new_password ? (
                            <EyeIcon width={20} height={20} />
                          ) : (
                            <EyeOffIcon width={20} height={20} />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name="confirm_password"
                  control={control}
                  render={({ field }) => (
                    <div className="position-relative">
                      <label
                        className={`label--control font--sm font--500 m-b--xs  d--flex
                    ${
                      errors?.confirm_password?.message
                        ? 'text--red'
                        : 'text--black'
                    }
                    `}
                      >
                        {errors?.confirm_password?.message ??
                          'Confirm Password'}
                      </label>
                      <div className="w--full position--relative">
                        <FormInput
                          {...field}
                          type={
                            visibilityToggle.confirm_password
                              ? 'password'
                              : 'text'
                          }
                          placeholder="Confirm your password"
                        />
                        <div
                          className="pointer svg--sm position--absolute top--9 right--10 translate-middle-y inputIcon"
                          onClick={() => {
                            setVisibilityToggle((prev) => ({
                              ...prev,
                              confirm_password: !prev.confirm_password,
                            }));
                          }}
                        >
                          {!visibilityToggle.confirm_password ? (
                            <EyeIcon width={20} height={20} />
                          ) : (
                            <EyeOffIcon width={20} height={20} />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="w-min--200  p-t--xs d--flex align-items--center justify-content--center">
                <button
                  type="submit"
                  className="btn font--sm  d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary w-max--200 c--pointer"
                  disabled={isSubmitting || isLoading}
                >
                  {isLoading ? 'Please wait..' : 'Change Password'}
                </button>
              </div>
            </form>
          </div>
          <div className="w--full d--flex gap--md flex--column">
            <div className="w--full d--flex gap--md flex--column">
              <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
                Voicemail Setting
              </div>
            </div>
            <form
              className="w--full d--flex h--full flex--column gap--md"
              onSubmit={handleSubmitVoicemail(onSubmitVoicemail)}
              autoComplete="off"
            >
              <div className="d--flex flex--column gap--xl w-max--600 w--full h--full">
                <div className="w--full mb-3 d--flex align-items--center">
                  <label className="label--control font--sm font--500  d--flex w--full  text--black ">
                    Enable Voicemail
                  </label>
                  <div className="w--full  d--flex gap--lg justify-content--end">
                    <Controller
                      name={`enable_vm`}
                      control={controlVoicemail}
                      render={({ field }) => {
                        return <Switch {...field} id={`enable_vm`} />;
                      }}
                    />
                  </div>
                </div>
                {watch('enable_vm') && (
                  <div className="w--full mb-3">
                    <div className="w--full d--flex flex--column">
                      <label className="label--control font--sm font--500 m-b--xs  text--black d--flex align-items--center gap--sm ">
                        Voicemail
                        <span
                          className=" bg--primary-100 text--primary radius--full   w-max--20 w-min--20  h-max--20 h-min--20 d--flex align-items--center justify-content--center"
                          data-tooltip={'Add New Voicemail'}
                          tooltip-position="right"
                          onClick={() => setAddGlobalgreeting(true)}
                        >
                          <PlusIcon width={10} height={10} />
                        </span>
                      </label>
                      <div className="d--flex align-items--center gap--md">
                        <Controller
                          name="vm_file"
                          control={controlVoicemail}
                          render={({ field }) => (
                            <SingleDropdownMain
                              {...field}
                              labelKey={'name'}
                              valueKey="filename"
                              optionList={
                                greetingList?.filter(
                                  (item) => item?.type === 'voicemail',
                                ) || []
                              }
                              optionListLoad={greetingListLoad}
                              component={RenderGreetingItem}
                              error={errors?.greeting?.message}
                            />
                          )}
                        />
                        {isGreeting && (
                          <>
                            {isPlaying ? (
                              <div
                                className="c--pointer"
                                onClick={() => {
                                  setIsPlaying(false);
                                }}
                              >
                                <HoldIcon width={20} height={20} />
                                <AudioPlayer
                                  onClose={() => {
                                    setIsPlaying(false);
                                  }}
                                  type="greeting"
                                  name={watch('vm_file')}
                                />
                              </div>
                            ) : (
                              <div
                                className="c--pointer d--flex h--full align-items--center text--success"
                                onClick={() => {
                                  setIsPlaying(true);
                                }}
                              >
                                <PlayIcon width={20} height={20} />
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      <label className="label--control font--sm font--500 m-b--xs  text--black d--flex align-items--center gap--sm m-t--sm">
                        NOTE: If no voicemail is specified default voicemail
                        will be played.
                      </label>
                    </div>
                  </div>
                )}

                {/* <div className="w--full mb-3 d--flex align-items--center m-b--xs">
                  <label className="label--control font--sm font--500 m-b--xs d--flex w--full  text--black ">
                    Send Voicemail to Email
                  </label>
                  <div className="w--full  d--flex gap--lg justify-content--end">
                    <Controller
                      name={`email_vm`}
                      control={controlVoicemail}
                      render={({ field }) => {
                        return <Switch {...field} id={`email_vm`} />;
                      }}
                    />
                  </div>
                </div> */}
              </div>
              <div className="w-min--200 p-t--xs d--flex align-items--center justify-content--center">
                <button
                  type="submit"
                  className="btn font--sm   d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary w-max--200 c--pointer"
                  disabled={voicemailUpdateLoad}
                >
                  {voicemailUpdateLoad ? 'Please wait' : 'Save'}
                </button>
              </div>
            </form>
          </div>
          <div className="w--full d--flex gap--md flex--column">
            <div className="w--full d--flex gap--md flex--column">
              <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
                Notification Setting
              </div>
            </div>
            <form
              className="w--full h--full d--flex flex--column gap--md"
              onSubmit={handleSubmitNotifications(submitNotification)}
              autoComplete="off"
            >
              <div className="w--full h--full d--flex flex--column gap--xl">
                <div className="w--full d--flex align-items--center justify-content--between gap--sm">
                  <div className="label--control font--sm font--600 m-b--xs d--flex"></div>
                  <div className="d--flex align-items--center justify-content--end gap--3xl m-r--3xl">
                    <label className="label--control gap--sm c--pointer font--sm font--500 m-b--xs d--flex align-items--center text--black">
                      Email
                    </label>
                    <label className="label--control gap--sm c--pointer font--sm font--500 m-b--xs d--flex align-items--center text--black">
                      Nextere
                    </label>
                  </div>
                </div>
                <div className="w--full d--flex align-items--center justify-content--between gap--sm">
                  <div className="label--control font--sm font--600 m-b--xs d--flex text--black">
                    Voicemail
                  </div>
                  <div className="d--flex align-items--center justify-content--end gap--3xl m-r--3xl">
                    <label
                      className="label--control gap--sm c--pointer font--sm font--500 m-b--xs d--flex align-items--center text--black"
                      htmlFor="voicemail_email"
                    >
                      <Controller
                        name={`voicemail.email`}
                        control={controlNotification}
                        render={({ field }) => {
                          return <Switch {...field} id={`voicemail_email`} />;
                        }}
                      />
                    </label>
                    <label
                      className="label--control gap--sm c--pointer font--sm font--500 m-b--xs d--flex align-items--center text--black"
                      htmlFor="voicemail_nextere"
                    >
                      <Controller
                        name={`voicemail.nextere`}
                        control={controlNotification}
                        render={({ field }) => {
                          return <Switch {...field} id={`voicemail_nextere`} />;
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="w--full d--flex align-items--center justify-content--between gap--sm">
                  <div className="label--control font--sm font--600 m-b--xs d--flex text--black">
                    Missed Call
                  </div>
                  <div className="d--flex align-items--center justify-content--end gap--3xl m-r--3xl">
                    <label
                      className="label--control gap--sm c--pointer font--sm font--500 m-b--xs d--flex align-items--center text--black"
                      htmlFor="missed_email"
                    >
                      <Controller
                        name={`missed.email`}
                        control={controlNotification}
                        render={({ field }) => {
                          return <Switch {...field} id={`missed_email`} />;
                        }}
                      />
                    </label>
                    <label
                      className="label--control gap--sm c--pointer font--sm font--500 m-b--xs d--flex align-items--center text--black"
                      htmlFor="missed_nextere"
                    >
                      <Controller
                        name={`missed.nextere`}
                        control={controlNotification}
                        render={({ field }) => {
                          return <Switch {...field} id={`missed_nextere`} />;
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="w--full d--flex align-items--center justify-content--between gap--sm">
                  <div className="label--control font--sm font--600 m-b--xs d--flex text--black">
                    SMS
                  </div>
                  <div className="d--flex align-items--center justify-content--end gap--3xl m-r--3xl">
                    <label
                      className="label--control gap--sm c--pointer font--sm font--500 m-b--xs d--flex align-items--center text--black"
                      htmlFor="sms_email"
                    >
                      <Controller
                        name={`sms.email`}
                        control={controlNotification}
                        render={({ field }) => {
                          return <Switch {...field} id={`sms_email`} />;
                        }}
                      />
                    </label>
                    <label
                      className="label--control gap--sm c--pointer font--sm font--500 m-b--xs d--flex align-items--center text--black"
                      htmlFor="sms_nextere"
                    >
                      <Controller
                        name={`sms.nextere`}
                        control={controlNotification}
                        render={({ field }) => {
                          return <Switch {...field} id={`sms_nextere`} />;
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-min--200  p-t--xs d--flex align-items--center justify-content--center">
                <button
                  type="submit"
                  disabled={notificationUpdateLoad}
                  className="btn font--sm  d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary w-max--200 c--pointer"
                >
                  {notificationUpdateLoad ? 'Please wait' : 'Save'}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="d--flex gap--2xl">
          <div className="w--full d--flex gap--md flex--column">
            <div className="w--full d--flex gap--md flex--column">
              <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
                Ringtone Settings
              </div>
            </div>
            <form
              className="w--full h--full d--flex flex--column gap--md"
              onSubmit={handleSubmitRingtone(submitRingtone)}
              autoComplete="off"
            >
              <div className="w--full h--full d--flex flex--column gap--xl">
                <div className="d--flex align-items--center gap--md">
                  <Controller
                    name="name"
                    control={ringtoneControl}
                    render={({ field }) => (
                      <SingleDropdownMain
                        placeholder="Select your ringtone"
                        {...field}
                        labelKey={'name'}
                        valueKey="value"
                        optionList={toneLookup || []}
                        component={RenderToneItem}
                      />
                    )}
                  />
                  {isTone && (
                    <>
                      {isRingtonePlaying ? (
                        <div
                          className="c--pointer"
                          onClick={() => {
                            setIsRingtonePlaying(false);
                          }}
                        >
                          <HoldIcon width={20} height={20} />
                          <AudioPlayer
                            onClose={() => {
                              setIsRingtonePlaying(false);
                            }}
                            type="greeting"
                            name={
                              toneLookup?.find(
                                (item) => item?.value === ringtoneWatch('name'),
                              )?.path || ''
                            }
                            local={true}
                          />
                        </div>
                      ) : (
                        <div
                          className="c--pointer d--flex h--full align-items--center text--success"
                          onClick={() => {
                            setIsRingtonePlaying(true);
                          }}
                        >
                          <PlayIcon width={20} height={20} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="w-min--200 m-t--sm p-t--xs d--flex align-items--center justify-content--center">
                <button
                  type="submit"
                  disabled={ringtoneUpdateLoad}
                  className="btn font--sm  d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary w-max--200 c--pointer m-t--md"
                >
                  {ringtoneUpdateLoad ? 'Please wait' : 'Set ringtone'}
                </button>
              </div>
            </form>
          </div>
          <div className="w--full d--flex gap--md flex--column">
            <AVSettings />
          </div>
          <div className="w--full d--flex gap--md flex--column">
            <MissedCallSettings />
          </div>
        </div>
      </div>

      {addGlobalGreeting ? (
        <AsideModal
          handleClose={handleClose}
          title={'Add'}
          footerComponent={null}
        >
          <AddGreetingForm handleClose={handleClose} />
        </AsideModal>
      ) : null}
    </div>
  );
}
