import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAllNumberDetails from '../../../../hooks/useAllNumberDetails';
import Avatar from '../../../../comopnents/Avatar';
import { SipSocketContext } from '../../../../hooks/useSip/SipSocketContext';
import { useMutation } from '@tanstack/react-query';
import { smsSocketCount } from '../../../../api';

const ChatListItem = ({
  chatData = {},
  activeChat = '',
  setActiveChat = () => null,
  smsDefaultDID,
}) => {
  const { getNumberName } = useAllNumberDetails();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isActive = activeChat?.uuid === chatData?.uuid;
  const { socket: { unreadInboundCount } = {} } = useContext(SipSocketContext);
  const [unreadCount, setUnreadCount] = useState(0);
  useEffect(() => {
    const newunread =
      unreadInboundCount?.find((item) => {
        return (
          (item?.senderNumber === chatData?.to &&
            smsDefaultDID === item?.didNumber) ||
          (item?.senderNumber === chatData?.caller_number &&
            smsDefaultDID === item?.didNumber)
        );
      })?.count || 0;
    setUnreadCount(newunread);
  }, [JSON.stringify(unreadInboundCount ? unreadInboundCount : {})]);

  const { mutate: smsSocketCountUpdateMutate } = useMutation({
    mutationKey: [`smsSocketCount`],
    mutationFn: smsSocketCount,
  });
  useEffect(() => {
    if (searchParams) {
      const uuid = searchParams.get('chatId') || '';
      if (chatData?.uuid === uuid) {
        setActiveChat(chatData);
      }
    }
  }, [chatData]);

  const first_name = getNumberName(
    chatData?.direction === 'Outbound' ? chatData?.to : chatData?.from,
  )
    .split(' ')
    .at(0);
  const last_name = getNumberName(
    chatData?.direction === 'Outbound' ? chatData?.to : chatData?.from,
  )
    .split(' ')
    .at(1);

  return (
    <div
      className={`chatListItem border-top--black-100 d--flex gap--sm p-l--sm p-r--sm p-b--md p-t--md align-items--center c--pointer ${
        isActive ? 'bg--primary-100' : ''
      }`}
      onClick={() => {
        const didId = searchParams.get('didId') || '';
        const payload = {
          to:
            chatData?.direction !== 'Outbound' ? chatData?.from : chatData?.to,
          from:
            chatData?.direction !== 'Outbound' ? chatData?.to : chatData?.from,
        };
        smsSocketCountUpdateMutate(payload);
        setActiveChat(chatData);

        // getUnreadInboundSMSCount();

        // setUnreadInboundCount((prev) => {
        //   for (let index = 0; index < prev.length; index++) {
        //     const element = prev[index];
        //     const number =
        //       chatData?.direction === 'Outbound'
        //         ? chatData?.to
        //         : chatData?.from;
        //     if (
        //       element?.senderNumber === number &&
        //       element?.didNumber === smsDefaultDID
        //     ) {
        //       element.count = 0;
        //     }
        //   }
        //   return prev;
        // });
        navigate(`/messages?didId=${didId}&chatId=${chatData?.uuid}`);
      }}
    >
      {first_name?.includes('+') || last_name?.includes('+') ? (
        <Avatar first_name={'N'} last_name={'A'} shouldShowPresence={false} />
      ) : (
        <Avatar
          first_name={first_name}
          last_name={last_name}
          shouldShowPresence={false}
        />
      )}
      <div className="w--full d--flex flex--column align-items--center gap--xs">
        <div className="w--full d--flex justify-content--between align-items--center">
          <div
            className="w--full font--sm font--500 w-max--150 ellipsis"
            style={{ textTransform: 'capitalize' }}
          >
            {getNumberName(
              chatData?.direction === 'Outbound'
                ? chatData?.to
                : chatData?.from,
            )}
          </div>
          {unreadCount > 0 ? (
            <div className="w--full font--xs font--300 bg--red text--white w-min--20 w-max--20 h-min--20 h-max--20 radius--full d--flex align-items--center justify-content--center">
              {unreadCount}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ChatListItem;
