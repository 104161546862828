import React, { lazy } from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import {
  AGENCY_AGENT,
  AGENCY_OWNER,
  AGENT,
  EMPLOYEE,
  USER,
} from '../../helpers/functions';
import OuterLayout from '../../layouts/OuterLayout';
import AccountVerification from '../../pages/AccountVerification';
import Agency from '../../pages/Agency';
import Call from '../../pages/Call';
import CallForwarding from '../../pages/Call/CallForwarding';
import CallHistory from '../../pages/Call/CallHistory';
import CallRecording from '../../pages/Call/CallRecording';
import CustomGreetings from '../../pages/Call/CustomGreetings';
import IVR from '../../pages/Call/IVR';
import RingGroup from '../../pages/Call/RingGroup';
import Voicemail from '../../pages/Call/Voicemail';
import Contacts from '../../pages/Contacts';
import Dashboard from '../../pages/Dashboard';
import Error500Page from '../../pages/Error500Page';
import ForgotPassword from '../../pages/ForgotPassword';
import Login from '../../pages/Login';
import MemberAgents from '../../pages/MemberAgents';
import Messages from '../../pages/Messages';
import PageNotFound from '../../pages/PageNotFound';
import Unauthorized from '../../pages/Unauthorized';
import Website from '../../pages/Website';
import Company from '../../pages/Website/Company';
import ContactUs from '../../pages/Website/ContactUs';
import Home from '../../pages/Website/Home';
import Pricing from '../../pages/Website/Pricing';
import PaymentMethod from '../../pages/Website/Pricing/PaymentMethod';
import SignupForm from '../../pages/Website/Pricing/SignupForm';
import VerifyOtp from '../../pages/Website/Pricing/VerifyOtp';
import Integrations from '../../pages/integrations';

import VirtualNumbers from '../../pages/VirtualNumbers';
import PortingRequests from '../../pages/VirtualNumbers/PortingRequests';
import UpsertPortingRequest from '../../pages/VirtualNumbers/PortingRequests/UpsertPortingRequest';

import MyAccountPage from '../../pages/MyAccountPage';
import AccountInfo from '../../pages/MyAccountPage/AccountInfo';
import AgencyInfo from '../../pages/MyAccountPage/AgencyInfo';
import ChangePassword from '../../pages/MyAccountPage/ChangePassword';
import CompanyInfo from '../../pages/MyAccountPage/CompanyInfo';
import Plan from '../../pages/MyAccountPage/Plan';
import Transaction from '../../pages/MyAccountPage/Transaction';
import UpgradePlan from '../../pages/MyAccountPage/UpgradePlan';
import Support from '../../pages/MyAccountPage/support';

import Reports from '../../pages/Reports';
import ReportsCallRecording from '../../pages/Reports/CallRecording';
import Calls from '../../pages/Reports/Calls';
import Employees from '../../pages/Reports/Employees';
import Overview from '../../pages/Reports/Overview';
import SMS from '../../pages/Reports/SMS';
import ReportsVoicemail from '../../pages/Reports/Voicemail';
// import Agents from '../../pages/Reports/Agents';
// import Numbers from '../../pages/Reports/Numbers';

import CallCenter from '../../pages/CallCenter';
import Blacklist from '../../pages/CallCenter/Blacklist';
import Disposition from '../../pages/CallCenter/Disposition';
import IPWhiteList from '../../pages/CallCenter/IPWhiteList';
import Leads from '../../pages/CallCenter/Leads';
import Scripts from '../../pages/CallCenter/Scripts';
// import Campaign from '../../pages/CallCenter/Campaign';
// import Queue from '../../pages/CallCenter/Queue';

import CallsSummary from '../../pages/CallsSummary';
import LiveCalls from '../../pages/LiveCalls';
import LoginViaAdmin from '../../pages/LoginViaAdmin';
import AddFunds from '../../pages/MyAccountPage/AddFunds';
import ManageCards from '../../pages/MyAccountPage/ManageCards';
import ResetPassword from '../../pages/ResetPassword';
import Features from '../../pages/Website/Features';
import PrivacyPolicy from '../../pages/Website/PrivacyPolicy';
import TermsConditions from '../../pages/Website/TermsConditions';

const AuthRemover = lazy(() => import('../../auth/AuthRemover'));
const Access = lazy(() => import('../../auth/Access'));
const AuthProvider = lazy(() => import('../../auth/AuthProvider'));
const AuthLayout = lazy(() => import('../../layouts/AuthLayout'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <AuthLayout />
      </AuthProvider>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        path: 'dashboard',
        element: (
          <Access
            allowedRoles={[USER, AGENCY_OWNER, EMPLOYEE, AGENT, AGENCY_AGENT]}
          >
            <Dashboard />
          </Access>
        ),
        id: 'Dashboard',
      },
      {
        path: 'members',
        element: (
          <Access allowedRoles={[USER, AGENCY_OWNER]}>
            <MemberAgents />
          </Access>
        ),
        id: 'MemberAgents',
      },
      {
        path: 'contacts',
        element: (
          <Access
            allowedRoles={[USER, AGENCY_OWNER, EMPLOYEE, AGENT, AGENCY_AGENT]}
          >
            <Contacts />
          </Access>
        ),
        id: 'Contacts',
      },
      {
        path: 'agency',
        element: (
          <Access allowedRoles={[USER]}>
            <Agency />
          </Access>
        ),
        id: 'Agency',
      },
      {
        path: 'virtual-numbers',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <Access allowedRoles={[USER, AGENCY_OWNER]}>
                <VirtualNumbers />
              </Access>
            ),
            id: 'VirtualNumbers',
          },
          {
            path: 'porting-requests',
            element: (
              <Access allowedRoles={[USER, AGENCY_OWNER]}>
                <PortingRequests />
              </Access>
            ),
            id: 'PortingRequests',
          },
          {
            path: 'add-porting-request',
            element: <UpsertPortingRequest />,
            id: 'addPortingRequest',
          },
          {
            path: 'edit-porting-request/:id',
            element: <UpsertPortingRequest />,
            id: 'editPortingRequest',
          },
        ],
      },
      {
        path: 'upgrade_plan',
        element: (
          <Access allowedRoles={[USER, AGENCY_OWNER]}>
            <UpgradePlan />
          </Access>
        ),
        id: 'UpgradePlan',
      },
      {
        path: 'call-history',

        element: (
          <Access allowedRoles={[AGENT, AGENCY_AGENT, EMPLOYEE]}>
            <CallHistory />
          </Access>
        ),
        id: 'CallForwarding',
      },

      {
        path: 'call-recording',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_AGENT, EMPLOYEE]}>
            <CallRecording />
          </Access>
        ),
        id: 'CallRecording',
      },
      {
        path: 'voicemail',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_AGENT, EMPLOYEE]}>
            <Voicemail />
          </Access>
        ),
        id: 'Voicemail',
      },
      {
        path: 'call',
        element: (
          <Access allowedRoles={[USER, AGENCY_OWNER]}>
            <Call />
          </Access>
        ),
        id: 'Call',
        children: [
          {
            index: true,
            element: (
              <Access allowedRoles={[USER, AGENCY_OWNER]}>
                <CallForwarding />
              </Access>
            ),
            id: 'CallForwarding1',
          },
          {
            path: 'call-forwarding',

            element: (
              <Access allowedRoles={[USER, AGENCY_OWNER]}>
                <CallForwarding />
              </Access>
            ),
            id: 'CallForwarding2',
          },

          {
            path: 'call-recording',
            element: (
              <Access allowedRoles={[USER, AGENCY_OWNER]}>
                <CallRecording />
              </Access>
            ),
            id: 'CallRecording2',
          },
          {
            path: 'voicemail',
            element: (
              <Access allowedRoles={[USER, AGENCY_OWNER]}>
                <Voicemail />
              </Access>
            ),
            id: 'Voicemail3',
          },
          {
            path: 'call-history',
            element: (
              <Access allowedRoles={[USER, AGENCY_OWNER]}>
                <CallHistory />
              </Access>
            ),
            id: 'CallHistory',
          },
          {
            path: 'greetings',
            element: (
              <Access allowedRoles={[USER, AGENCY_OWNER]}>
                <CustomGreetings />
              </Access>
            ),
            id: 'Greetings',
          },
          {
            path: 'department',
            element: (
              <Access allowedRoles={[USER, AGENCY_OWNER]}>
                <RingGroup />
              </Access>
            ),
            id: 'Department',
          },
          {
            path: 'ivr',
            element: (
              <Access allowedRoles={[USER, AGENCY_OWNER]}>
                <IVR />
              </Access>
            ),
            id: 'IVR',
          },
        ],
      },

      {
        path: 'myaccount',
        element: (
          <Access
            allowedRoles={[USER, AGENCY_OWNER, EMPLOYEE, AGENT, AGENCY_AGENT]}
          >
            <MyAccountPage />
          </Access>
        ),
        id: 'MyAccount',
        children: [
          {
            index: true,
            element: (
              <Access
                allowedRoles={[
                  USER,
                  AGENCY_OWNER,
                  EMPLOYEE,
                  AGENT,
                  AGENCY_AGENT,
                ]}
              >
                <AccountInfo />
              </Access>
            ),
            id: 'AccountInfo',
            path: 'account-info',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <Transaction />
              </Access>
            ),
            id: 'MyTransaction',
            path: 'transaction',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <AddFunds />
              </Access>
            ),
            id: 'AddFunds',
            path: 'addfunds',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <ManageCards />
              </Access>
            ),
            id: 'ManageCards',
            path: 'managecards',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <Plan />
              </Access>
            ),
            id: 'MyPlan',
            path: 'plan',
          },
          {
            element: (
              <Access
                allowedRoles={[
                  USER,
                  AGENCY_OWNER,
                  EMPLOYEE,
                  AGENT,
                  AGENCY_AGENT,
                ]}
              >
                <Support />
              </Access>
            ),
            id: 'MySupport',
            path: 'support',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <CompanyInfo />
              </Access>
            ),
            id: 'CompanyInfo',
            path: 'company-info',
          },
          {
            element: (
              <Access allowedRoles={[AGENCY_OWNER]}>
                <AgencyInfo />
              </Access>
            ),
            id: 'AgencyInfo',
            path: 'agency-info',
          },
          {
            element: (
              <Access
                allowedRoles={[
                  USER,
                  AGENCY_OWNER,
                  EMPLOYEE,
                  AGENT,
                  AGENCY_AGENT,
                ]}
              >
                <ChangePassword />
              </Access>
            ),
            id: 'ChangePassword',
            path: 'change-password',
          },
        ],
      },
      {
        path: 'integrations',
        element: (
          <Access
            allowedRoles={[USER, AGENCY_OWNER, EMPLOYEE, AGENT, AGENCY_AGENT]}
          >
            <Integrations />
          </Access>
        ),
        children: [
          {
            path: ':zohoCode',
            element: (
              <Access
                allowedRoles={[
                  USER,
                  AGENCY_OWNER,
                  EMPLOYEE,
                  AGENT,
                  AGENCY_AGENT,
                ]}
              >
                <Integrations />
              </Access>
            ),
          },
        ],
        id: 'Integrations',
      },
      {
        path: 'messages',
        element: (
          <Access allowedRoles={[USER, EMPLOYEE, AGENT]}>
            <Messages />
          </Access>
        ),
        id: 'Messages',
      },
      {
        path: 'reports',
        element: (
          <Access allowedRoles={[USER]}>
            <Reports />
          </Access>
        ),
        id: 'Reports',
        children: [
          {
            index: true,
            element: (
              <Access allowedRoles={[USER]}>
                <Overview />
              </Access>
            ),
            id: 'Overview',
            path: 'overview',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <Calls />
              </Access>
            ),
            id: 'Calls',
            path: 'calls',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <ReportsCallRecording />
              </Access>
            ),
            id: 'ReportsCallRecording',
            path: 'call-recording',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <ReportsVoicemail />
              </Access>
            ),
            id: 'ReportsVoicemail',
            path: 'voicemail',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <SMS />
              </Access>
            ),
            id: 'SMS',
            path: 'sms',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <Employees />
              </Access>
            ),
            id: 'Employees',
            path: 'employees',
          },
          // {
          //   element: (
          //     <Access allowedRoles={[USER]}>
          //       <Agents />
          //     </Access>
          //   ),
          //   id: 'Agents',
          //   path: 'agents',
          // },
          // {
          //   element: (
          //     <Access allowedRoles={[USER]}>
          //       <Numbers />
          //     </Access>
          //   ),
          //   id: 'Numbers',
          //   path: 'numbers',
          // },
        ],
      },
      {
        path: 'call-center',
        element: (
          <Access allowedRoles={[USER]}>
            <CallCenter />
          </Access>
        ),
        id: 'CallCenter',
        children: [
          {
            index: true,
            element: (
              <Access allowedRoles={[USER]}>
                <Blacklist />
              </Access>
            ),
            id: 'Blacklist',
            path: 'blacklist',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <Scripts />
              </Access>
            ),
            id: 'Scripts',
            path: 'scripts',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <IPWhiteList />
              </Access>
            ),
            id: 'IPWhiteList',
            path: 'ipwhitelist',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <Disposition />
              </Access>
            ),
            id: 'Disposition',
            path: 'disposition',
          },
          {
            element: (
              <Access allowedRoles={[USER]}>
                <Leads />
              </Access>
            ),
            path: 'leads',
            id: 'leads',
          },
          // {
          //   element: (
          //     <Access allowedRoles={[USER]}>
          //       <Campaign />
          //     </Access>
          //   ),
          //   id: 'Campaign',
          //   path: 'campaign',
          // },
          // {
          //   element: (
          //     <Access allowedRoles={[USER]}>
          //       <Queue />
          //     </Access>
          //   ),
          //   id: 'Queue',
          //   path: 'Queue',
          // },
        ],
      },
      {
        path: 'live-calls',
        element: (
          <Access allowedRoles={[USER]}>
            <LiveCalls />
          </Access>
        ),
        id: 'LiveCalls',
      },
      {
        path: 'call-summary',
        element: (
          <Access allowedRoles={[]}>
            <CallsSummary />
          </Access>
        ),
        id: 'CallsSummary',
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthRemover>
        <OuterLayout />
      </AuthRemover>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        path: '/',
        element: <Website />,
        id: 'Website',
        children: [
          {
            index: true,
            element: <Home />,
            id: 'Home',
          },
          {
            path: 'company',
            element: <Company />,
            id: 'Company',
          },
          { path: 'features', element: <Features />, id: 'Features' },
          // { path: 'products', element: <Products />, id: 'Products' },
          {
            path: 'pricing',
            element: <Pricing />,
            id: 'Pricing',
          },
          {
            path: 'privacy-policy',
            element: <PrivacyPolicy />,
            id: 'PrivacyPolicy',
          },
          {
            path: 'terms-conditions',
            element: <TermsConditions />,
            id: 'TermsConditions',
          },
          {
            path: 'payment-method',
            element: <PaymentMethod />,
            id: 'PaymentMethod',
          },
          {
            path: 'verifyotp',
            element: <VerifyOtp />,
            id: 'VerifyOtp',
          },
          {
            path: 'signup',
            element: <SignupForm />,
            id: 'PlanDetails',
          },
          {
            path: 'contact-us',
            element: <ContactUs />,
            id: 'ContactUs',
          },
        ],
      },
      {
        path: 'login',
        element: <Login />,
        id: 'Login',
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
        id: 'forgot-password',
      },
      {
        path: 'account-verification/:id',
        element: <AccountVerification />,
        id: 'Account-Verification',
      },
      {
        path: 'reset-password/:id',
        element: <ResetPassword />,
        id: 'Reset-Password',
      },
    ],
  },

  {
    path: '/login-via-admin/:token',
    element: <LoginViaAdmin />,
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);
