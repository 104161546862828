import React, { useEffect } from 'react';

const TermsConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='p--xl w--full d--flex justify-content--center flex--column align-items--center'>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
      <h1 className='m-t--md'>Terms of Service</h1>
      <h4 className='m-t--sm'> IMPORTANT NOTICE:</h4> <br />
        THIS IS A LEGAL AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS AND
        CONDITIONS SER FORTH IN THIS TERMS OF SERVICE, YOU MAY NOT USE NEXTERE
        SERVICES AND/OR THE NEXTERE APP. BY ACCESSING THIS WEBSITE OR THE NEXTERE
        APP, YOU FULLY ACCEPT AND AGREE TO BE BOUND BY THE TERMS AND CONDITIONS.
        ENSURE THAT YOU FULLY UNDERSTAND THIS AGREEMENT PRIOR TO USING OUR SITE
        AND/OR SERVICES.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 1. Parties.</h1> <br />
        This Agreement is entered into between any visitor to Nextere and the
        Nextere app. For the purposes of this agreement, the visitor shall be
        referred to as "User or You" while all references stating "company"
        shall refer to the owners of Nextere.com and the Nextere app.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 2. Acceptance of Terms of Service and Amendments.</h1> <br />
        By accessing Nextere and using its products and services, you agree to be
        bound by the terms stated. The Pinless services and calling cards have
        additional information explicitly stated under the product information.
        This agreement covers your acceptance of the production information as
        well. Also, amendments may be made to the terms of service at any time.
        Whenever an amendment is made, it will be posted on Nextere and the date
        of that change will also be posted.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 3. Regional Restrictions.</h1> <br />
        If you live in a region where the law prohibits the use of any or all of
        the company's services, you may not use the services in that region. It
        is the user's responsibility to verify that the services are not
        prohibited in the user's area of domicile.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 4. Customer Service.</h1> <br />
        Customer service is available to our customers. For faster results
        please contact using our contact page at Contact Nextere. To contact
        Nextere by phone please dial 832-2-NEXTERE (832-2-688246)
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 5. User Account</h1> <br />
        To use Nextere services, the user is required to register an account. The
        user is fully responsible for this account and will not hold Nextere
        liable for any unauthorized access to this account. As part of its
        commitment to ensure user account safety, Nextere provides a "One time
        Password" login code option that helps secure access to each users
        account. Anytime a user tries to login, a onetime code is sent to the
        users registered email. This helps deter unauthorized access to the
        account. Nextere will not be held liable if user allows other people to
        gain access to their passwords.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 6. Termination of Service</h1> <br />
        Nextere has the right to refuse, restrict, and/or limit service to any
        user. If it is deemed that a user has abused or constantly violated the
        terms of service, Nextere may remove and/or terminate the services of the
        user. Nextere reserves the right to cancel any account that has been
        inactive for more than six (6) months. An account terminated for
        violation of service will not receive any refunds whatsoever.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 7. Emergency Calls</h1> <br />
        Nextere services do not support emergency calls. Nextere services are not
        intended to carry emergency calls to any medical facility or government
        agency. For emergency calls, please use your mobile carrier or any other
        land phone service. Nextere does not offer emergency call capability.
        Nextere will not be held liable for user's inability to dial emergency
        numbers.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 8. Payment.</h1> <br />
        Payments to Nextere are made either online, via the app, or through the
        phone by way of IVR. Nextere accepts major credit cards and PayPal. All
        payments are processes through a third party payment processor and
        credit card information is not stored by Nextere servers.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 9. Rates and Rounding.</h1> <br />
        Nextere rates are fully published at Nextere Rates. Nextere rates and
        special promotions/packages may be changed/updated without any advanced
        notice. Nextere uses One (1) minute rounding increments for calls. For
        example, if you make a call that lasts for two (2) minutes and thirty
        five (35) seconds, you will be charged for three (3) minutes. Also the
        minimum connection time is also one (1) minutes. This means if you make
        a call that lasts for only two (seconds), you will be charged one (1)
        minute for that call.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 10. Refunds.</h1> <br />
        If service interruption occurs or the user is not able to get services
        paid for, Nextere will make reasonable efforts to rectify the situation.
        If service is still not restored, Nextere may at its discretion offer
        refunds to you, the customer. This refund will only be given if the user
        is unable to make calls. Refunds will not be given due to bad call
        quality since call quality is dependent on the user's cell
        service/internet and the telecommunication carrier.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 11. User Responsibilities and Prohibited Conduct</h1> <br />
        In order to buy/use Nextere services, the user is required to provide a
        full name, phone number and contact information. Nextere does not market
        services to minors. If you register on this site, you are legally
        stating that you are of legal age to make a purchase. Any user found
        violating this law will be immediately removed from the site. The user
        also agrees to stay away from illegal/fraudulent activity on the site.
        The user shall comply with the laws and regulations governing online
        ecommerce activity. Illegal use of credit cards and
        intentional/fraudulent use of names for deceptive purposes shall be
        reported to the applicable legal and regulatory authorities. User agrees
        to stay away from fraudulent activities.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 12. Special Packages And Unlimited Plans</h1> <br />
        Nextere provides special packages with validity days as well as unlimited
        calling plans. The packages and unlimited calling plans differ from the
        pay as you go plans in that they require at least a 30 day requirement
        or subscription model. The unlimited plans provided by Nextere are for
        normal use and are not to be used commercially. Nextere, at its
        discretion will monitor accounts to check for abuse. Abuse includes
        using the service for Business calls, auto dialers, call centers or
        excessive call patterns beyond normal consumer usage. Excessive call
        patterns include those that exceed the typical usage of approximately
        90% of current customers. Abusive usage may also include using more than
        1000 minutes within a 24 to 48 hour period. Premium and Specialty
        numbers are not included in the unlimited and special packages plans. If
        it is determined that a customer is abusing the package subscribed to,
        Nextere may suspend said account holder. Also, the unlimited plans and
        special packages are limited to one user per account. If a customer
        provides access to the account to multiple users, Nextere may suspend the
        account. Susbcription packages and Unlimited plans automatically renew
        every month. To cancel subscriptions, customers can also contact Nextere.
        Once customer has indicated they no longer wish to be subscribed,
        subscriptions will be cancelled. Unused balances will not be refunded.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 13. Indemnity.</h1> <br />
        User agrees to release, indemnify, defend and hold harmless Nextere, its
        subsidiaries, affiliates, officers, directors, employees, agents and
        advisors, from and against any and all losses, liabilities, claims
        (including claims without legal merit or brought in bad faith), demands,
        damages, costs or expenses, causes of action, suits, proceedings,
        judgments, awards, executions and liens, including reasonable attorneys'
        fees and costs (whether brought by third parties or otherwise)
        (collectively, "Claims") due to or arising out of User's purchase of
        Products on this website and/or use of this website. Nextere reserves the
        right, at its own expense, to assume the exclusive defense and control
        of any matter otherwise subject to indemnification by User hereunder,
        and in such event, User shall have no further obligation to provide
        indemnification for such matter.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'> 14. Representations and Warranties.</h1> <br />
        This website and the products on this site are provided "as is" and "as
        available" with no representations or warranties of any kind, either
        expressed or implied, including, but not limited to, implied warranties
        of merchantability, fitness for a particular purpose, system
        integration, title and non-infringement. In particular, but without
        limitation, Nextere.com does not warrant that the service will be
        uninterrupted or error-free, that defects will be corrected, or that
        this site or the server that makes it available is free of viruses or
        other harmful components. Nextere.com does not warrant or make any
        representations regarding the use or the results of the use of this
        website or the products, in terms of its correctness, accuracy,
        timeliness, reliability or otherwise. User understands and agrees that
        it assumes total responsibility for its use of this website and the
        products on this website.
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'>15. Limited Liability.</h1> <br />
        Nextere.com and its affiliates and content providers and their respective
        shareholders and affiliates shall not be liable for any loss of
        business, loss of use or of data, interruption of business, lost profits
        or goodwill, or other indirect, special, incidental, exemplary or
        consequential damages of any kind arising out of this agreement, even if
        they have been advised of the possibility of such loss and whether or
        not they had any knowledge, actual or constructive, that such damages
        might be incurred, and notwithstanding any failure of essential purpose
        of any limited remedy. This exclusion includes any liability that may
        arise out of third-party claims against user. User further agrees if
        user becomes entitled to any recovery that its recovery shall be limited
        to the amount of fees or payments made to Nextere, if any, for the
        product at issue.
        <br />
        Nextere.com will not be liable for any failure of performance due to
        causes beyond its control, including, but not limited to, acts of god,
        fires, floods or other catastrophes, national emergencies,
        insurrections, riots or wars, strikes, lockouts, work stoppages or other
        labor difficulties, preemption of existing services in compliance with
        any law, order, regulation or other action of any governing authority or
        agency thereof.
        <br />
        Except as specifically provided, Nextere.com will not be liable for any
        act or omission of any company furnishing a portion of the service or of
        any third party, including those vendors participating in offerings made
        to user, or for damages associated with service, channels, or equipment
        that it does not furnish, or for damages that result from the operation
        of customer-provided systems, equipment, facilities or services that are
        interconnected with Nextere.com services.
        <br />
      </div>
      <div className='w-max--1000 w-min--1000 d--flex flex--column align-items--start justify-content--start '>
        <h1 className='m-t--md'>16. Dispute Resolution/Applicable Law.</h1> <br />
        No dispute or claim may be brought as a class action or as a private
        attorney general, and User will not have the right to act as a class
        representative or participate as a member of a class of claimants with
        respect to any dispute or claim. No waiver of the Terms and Conditions
        is valid. <br />
        The Terms and Conditions are binding upon User and Nextere.com and upon,
        respectively, User's and Nextere.com's agents and heirs. If any part or
        provision of these Terms and Conditions is finally determined to be
        invalid or unenforceable under applicable law by a court of competent
        jurisdiction, then that part or provision will be ineffective only to
        the extent of such invalidity or unenforceability, without in any way
        affecting the remaining parts or provisions of these Terms and
        Conditions, which will still be given full force and effect. <br />
        If User has a dispute concerning the Products, User should first contact
        Nextere.com's customer service, as specified on the website at, Contact
        Nextere. If User is unable to resolve the dispute with customer service,
        User agrees that the exclusive forum for resolution shall be through
        arbitration. Arbitration is final and binding. User agrees that all
        disputes arising out of or related to these Terms and Conditions
        (regardless as to whether the dispute is based in contract, tort,
        statute, fraud, misrepresentation, or any other legal or equitable
        theory) will be submitted to the American Arbitration Association
        ("AAA"), for final and binding arbitration. For consumers, the
        arbitration will be conducted under the AAA Arbitration Rules for the
        Resolution of Consumer-Related Disputes Commercial Arbitration Rules
        ("AAA Rules"), as such rules are in effect on the date of commencement
        of the arbitration and as such rules are modified by these Terms and
        Conditions. For businesses that purchase Nextere.com's Products, the
        arbitration will be conducted under the AAA Arbitration Rules for
        Commercial Disputes, as such rules are in effect on the date of
        commencement of the arbitration and as such rules are modified by these
        Terms and Conditions. <br />
        The arbitration will be based only on the written submissions of the
        parties and documents submitted to the arbitrator, unless the parties
        agree or the arbitrator orders otherwise. Additional charges may apply
        for such procedures. The award may be confirmed and enforced in any
        court of competent jurisdiction. Currently, the AAA Rules provide for
        reduced filing fees for consumers. Unless otherwise provided for in the
        AAA Rules or in the arbitration award, all other administrative fees and
        expenses of arbitration, including the fees and expenses of the
        arbitrator, will be divided equally between User and Nextere. Each party
        will bear the cost of preparing and presenting its own case. Any
        in-person arbitration proceedings will be held in Houston, TX, unless
        otherwise mutually agreed upon by the parties. Each dispute will be
        arbitrated on an individual basis and will not be consolidated in any
        action with the disputes or claims of other consumers or customers. No
        dispute or claim may be brought as a class action or as a private
        attorney general, and User will not have the right to act as a class
        representative or participate as a member of a class of claimants with
        respect to any dispute or claim related to the Products. Any dispute or
        claim arising out of or relating to these Terms and Conditions must be
        brought within one (1) year after the date on which the basis for the
        dispute or claim first arises, regardless of applicable statutes or laws
        to the contrary <br />
      </div>
    </div>
  );
};

export default TermsConditions;
