import React from 'react';
import RingingScreen from '../RingingScreen';
import ConnectedScreen from '../ConnectedScreen';
import DTMFKeypadComponent from '../DTTMFKeypadComponent';
import TransferCallComponent from '../TransferCallComponent';
import Notes from '../Notes';
import Tags from '../Tags';
import Assign from '../Assign';
import MultipleCallListing from '../MultipleCallListing';

const CallScreenLayout = ({
  activeCall,
  setaction,
  action,
  callActiveKey,
  setCallActiveKey,
  callSessions,
}) => {
  const { _status } = activeCall;
  const handleScreen = (_status) => {
    switch (_status) {
      case 'ringing':
      case 'connecting':
        return <RingingScreen setaction={setaction} activeCall={activeCall} />;
      case 'hold':
      case 'mute':
      case 'connected':
        return (
          <ConnectedScreen setaction={setaction} activeCall={activeCall} />
        );

      default:
        <div>default</div>;
    }
  };

  const handleActionComponent = (action) => {
    switch (action) {
      case 'add':
        setaction(null);
      case 'transfer':
        return (
          <TransferCallComponent
            setAction={setaction}
            activeCall={activeCall}
          />
        );
      case 'attended_transfer':
        return (
          <TransferCallComponent
            setAction={setaction}
            activeCall={activeCall}
            isAttended={true}
          />
        );
      case 'dial':
        return (
          <DTMFKeypadComponent setAction={setaction} activeCall={activeCall} />
        );

      case 'notes':
        return <Notes setaction={setaction} activeCall={activeCall} />;
      case 'tags':
        return <Tags setaction={setaction} activeCall={activeCall} />;
      case 'assign':
        return <Assign setaction={setaction} activeCall={activeCall} />;
      case 'callListing':
        return (
          <div className='h-min--438 p-t--md p-b--md'>
            <MultipleCallListing
              callActiveKey={callActiveKey}
              setCallActiveKey={setCallActiveKey}
              callSessions={callSessions}
            />
          </div>
        );

      default:
    }
  };

  return <>{action ? handleActionComponent(action) : handleScreen(_status)}</>;
};

export default CallScreenLayout;
