import React, { useContext, useMemo, useState } from 'react';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import useAllNumberDetails from '../../../hooks/useAllNumberDetails';
import Avatar from '../../Avatar';

const MultipleCallListing = ({
  callActiveKey = '',
  setCallActiveKey,
  callSessions = [],
}) => {
  const { socket = {} } = useContext(SipSocketContext);
  const { _toggleHold } = socket;
  const { getNumberName } = useAllNumberDetails();

  const handleActiveCall = ({ callID, _status }) => {
    if (callID == callActiveKey) return;
    setCallActiveKey(callID);
    if (_status === 'hold') {
      _toggleHold(callID);
    }
  };
  const connectedCalls = useMemo(() => {
    return (
      callSessions.filter((item) =>
        ['connected', 'mute'].includes(item?._status),
      ) || []
    );
  }, [callSessions]);

  const holdCalls = useMemo(() => {
    return callSessions.filter((item) => item?._status === 'hold') || [];
  }, [callSessions]);

  const ringingCalls = useMemo(() => {
    return (
      callSessions.filter((item) =>
        ['ringing', 'connecting'].includes(item?._status),
      ) || []
    );
  }, [callSessions]);
  return (
    <div className="bg--white w-min--200 h-min--250 p--sm radius--md">
      {/* <div className='font--sm font--600 m-b--md'>Call Sessions</div> */}
      <div className="d--flex gap--sm flex--column m-t--md">
        {connectedCalls?.length > 0 && (
          <div className="text--md font--600 text--grey">
            Connected calls ({connectedCalls?.length || 0})
          </div>
        )}
        {connectedCalls &&
          connectedCalls.length > 0 &&
          connectedCalls.map((session) => {
            const { _status = '', _direction = '', callID = '' } = session;
            const name = getNumberName(session?._number, true, false);
            return (
              <div
                className={`d--flex w--full p--sm align-items--center c--pointer justify-content--between radius--sm ${
                  callActiveKey === callID ? 'bg--primary-200' : ''
                }`}
                key={callID}
                onClick={() => handleActiveCall({ callID, _status })}
              >
                <div className="d--flex w--full gap--md">
                  <Avatar
                    extension={
                      session?._number?.length < 5 ? session?._number : ''
                    }
                    first_name={name?.split(' ')?.[0] || ''}
                    last_name={name?.split(' ')?.[1] || ''}
                  />

                  <div className="d--flex flex--column">
                    <div
                      className="text--sm font--600"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {name || 'Unknown number'}
                    </div>
                    <div className="text--sm ">{session?._number || ''}</div>
                  </div>
                </div>
                <div>
                  <div style={{ textTransform: 'capitalize' }}>{_status}</div>
                </div>
              </div>
            );
          })}
        {holdCalls.length > 0 && (
          <div className="text--md font--600 text--grey">
            Calls on hold ({holdCalls?.length || 0})
          </div>
        )}
        {holdCalls &&
          holdCalls.length > 0 &&
          holdCalls.map((session) => {
            const { _status = '', _direction = '', callID = '' } = session;
            const name = getNumberName(session?._number, true, false);
            return (
              <div
                className={`d--flex w--full p--sm align-items--center c--pointer justify-content--between radius--sm ${
                  callActiveKey === callID ? 'bg--primary-200' : ''
                }`}
                key={callID}
                onClick={() => handleActiveCall({ callID, _status })}
              >
                <div className="d--flex w--full gap--md">
                  <Avatar
                    extension={
                      session?._number?.length < 5 ? session?._number : ''
                    }
                    first_name={name?.split(' ')?.[0] || ''}
                    last_name={name?.split(' ')?.[1] || ''}
                  />

                  <div className="d--flex flex--column">
                    <div
                      className="text--sm font--600"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {name || 'Unknown number'}
                    </div>
                    <div className="text--sm ">{session?._number || ''}</div>
                  </div>
                </div>
                <div>
                  <div style={{ textTransform: 'capitalize' }}>{_status}</div>
                </div>
              </div>
            );
          })}
        {ringingCalls.length > 0 && (
          <div className="text--md font--600 text--grey">
            Ringing calls ({ringingCalls?.length || 0})
          </div>
        )}
        {ringingCalls &&
          ringingCalls.length > 0 &&
          ringingCalls.map((session) => {
            const { _status = '', _direction = '', callID = '' } = session;
            const name = getNumberName(session?._number, true, false);

            return (
              <div
                className={`d--flex w--full p--sm align-items--center c--pointer justify-content--between radius--sm ${
                  callActiveKey === callID ? 'bg--primary-200' : ''
                }`}
                key={callID}
                onClick={() => handleActiveCall({ callID, _status })}
              >
                <div className="d--flex w--full gap--md">
                  <Avatar
                    extension={
                      session?._number?.length < 5 ? session?._number : ''
                    }
                    first_name={name?.split(' ')?.[0] || ''}
                    last_name={name?.split(' ')?.[1] || ''}
                  />

                  <div className="d--flex flex--column">
                    <div
                      className="text--sm font--600"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {name || 'Unknown number'}
                    </div>
                    <div className="text--sm ">{session?._number || ''}</div>
                  </div>
                </div>
                <div>
                  <div style={{ textTransform: 'capitalize' }}>{_status}</div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MultipleCallListing;
