import { useMutation, useQueryClient } from '@tanstack/react-query';
import { agentDelete } from '../../api';
import { useAlert } from '../useAlert';

export default function useAgentDelete() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const agentDeleteRequest = useMutation({
    mutationFn: agentDelete,
    mutationKey: 'agentDelete',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['agentList'], exact: false });
      showAlert({
        type: 'success',
        message: data?.message ?? 'Member deleted successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...agentDeleteRequest };
}
