import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import Modal from '../Modal';
import { DEFAULT_VOICEMAIL_NAME } from '../../helpers/functions';

const AudioPlayer = ({
  name = '',
  onClose = () => null,
  type = 'recording',
  local = false,
}) => {
  const { user } = useAuth();
  const [mediaUrl, setMediaUrl] = useState('');
  useEffect(() => {
    if (name && !local && DEFAULT_VOICEMAIL_NAME !== name) {
      setMediaUrl(
        `${process.env.REACT_APP_MEDIA_URL}signed-url/${
          user?.parent_uuid || user?.uuid
        }/${type}?filename=${name}`,
      );
    }
    if (name && local && DEFAULT_VOICEMAIL_NAME !== name) {
      setMediaUrl(name);
    }
    if (name && !local && DEFAULT_VOICEMAIL_NAME === name) {
      setMediaUrl(
        `${process.env.REACT_APP_MEDIA_URL}signed-url/default/recording?filename=${name}`,
      );
    }
  }, [name]);

  return (
    <Modal
      headerComponent={null}
      footerComponent={null}
      handleClose={() => onClose()}
      shouldCloseOnClickOutside={true}
      height="75"
      radius="full"
    >
      <div className="w--full h--full h-min--60 d--flex align-items--center justify-content--center">
        <audio
          autoPlay
          src={mediaUrl}
          controls
          className="w--full"
          controlsList="nodownload noplaybackrate"
        ></audio>
      </div>
    </Modal>
  );
};

export default AudioPlayer;
