import React, { useEffect, useMemo, useRef, useState } from 'react';
import Switch from '../../../comopnents/Switch';
import FormInput from '../../../comopnents/FormInput';
import { useForm, Controller } from 'react-hook-form';
import useIcons from '../../../assets/icons/useIcons';
import Button from '../../../comopnents/Button';
import PaymentForm from '../../PaymentForm';
import useGetSavedCards from '../../../hooks/useGetSavedCards';
// import { yupResolver } from '@hookform/resolvers/yup';
import ImgVisa from '../../../assets/images/visa.png';
import Spinner from '../../../comopnents/Spinner';
import useUpgradeplan from '../../../hooks/useUpgradeplan';
import ConfirmationModal from './ConfirmationModal';
import useAddFund from '../../../hooks/useAddFund';

const initialValues = {
  topUpAmount: '',
  auto_topup: '',
};

function AddFunds() {
  const [selectedButton, setSelectedButton] = useState('new');
  const confirmButtonRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(20);
  const [show, setshow] = useState(false);
  const { mutate: addFundMutate, isLoading: addFundLoad } = useAddFund();

  const {
    mutate,
    data: savedCardData,
    isLoading: isLoadingSavedCards,
  } = useGetSavedCards();

  const savedCardListing = useMemo(
    () => savedCardData?.data?.data || [],
    [savedCardData],
  );

  useEffect(() => {
    mutate();
  }, []);

  const handleAmountChange = (amount) => {
    setSelectedAmount(amount);
  };

  const [showInputBox, setShowInputBox] = useState(false);

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    if (buttonName === 'saved') {
      setShowInputBox(true);
    } else {
      setShowInputBox(false);
    }
  };
  const { CardIcon } = useIcons();
  const {
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  function handleConfirm(type) {
    switch (type) {
      case 'saved-card':
        addFundMutate({
          type: 'saved-card',
          charge_amount: selectedAmount,
          card_id: selectedCard,
        });
        break;

      case 'new-card':
        confirmButtonRef?.current?.handleSubmit({
          paymentType: 'add-fund-newcard',
          charge_amount: selectedAmount,
        });
        break;

      default:
        break;
    }
  }
  return (
    <>
      <div className="w--full h--full">
        <div className="w--full h--full manageCardsPage">
          <h4 class="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
            Add Funds
          </h4>
          <div className="d--flex gap--md flex--column align-items--center  profilePage bg--white p--md radius--sm h--full overflow--auto box-shadow--xs w--full d--flex h--">
            <div className="w-max--600 w--full  d--flex flex--column gap--lg ">
              <div className="w--full m-t--md">
                <div className="label--control font--sm font--500 m-b--xs  text--black d--flex text--black ">
                  Choose Amount(USD)
                </div>

                <div className="d--flex align-items--center gap--sm">
                  {[20, 50, 100, 150, 200].map((amount) => (
                    <button
                      type="button"
                      className={`h-min--36 radius--sm font--md font--600 d--flex align-items--center justify-content--center w--full c--pointer ${selectedAmount === amount
                          ? 'bg--primary text--white'
                          : 'bg--white text--black border-full--black-200'
                        }`}
                      onClick={() => handleAmountChange(amount)}
                    >
                      {amount}
                    </button>
                  ))}
                </div>
              </div>

              {/* <div className="d--flex justify-content--between">
                <div className="label--control font--sm font--500 m-b--xs  text--black d--flex text--black ">
                  Enable Auto TopUp
                </div>
                <div>
                  <Controller
                    name="auto_topup"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        error={errors?.auto_topup?.message}
                        id={'auto_topup'}
                      />
                    )}
                  />
                </div>
              </div> */}
              <div className="d--flex flex--column ">
                <div className="label--control font--sm font--500 m-b--xs  text--black d--flex text--black ">
                  Choose a payment method
                </div>
                <div className="w--full d--flex gap--lg m-t--xs">
                  <div
                    className={`w--full h-min--150 radius--sm ${selectedButton === 'new'
                        ? 'border-full--primary'
                        : 'border-full--black-200'
                      } d--flex flex--column gap--md align-items--center justify-content--center c--pointer`}
                    onClick={() => handleButtonClick('new')}
                  >
                    <div
                      className={`w-min--60 h-min--60 w-max--60 h-max--60 radius--sm ${selectedButton === 'new'
                          ? 'bg--primary text--white'
                          : 'bg--black-100'
                        } d--flex align-items--center justify-content--center`}
                    >
                      <CardIcon width={35} height={35} />
                    </div>
                    <div
                      className={`font--md font--600 ${selectedButton === 'new' ? 'text--primary' : ''
                        } `}
                    >
                      New Card
                    </div>
                  </div>
                  <div
                    className={`w--full h-min--150 radius--sm ${selectedButton === 'saved'
                        ? 'border-full--primary'
                        : 'border-full--black-200'
                      } d--flex flex--column gap--md align-items--center justify-content--center c--pointer`}
                    onClick={() => handleButtonClick('saved')}
                  >
                    <div
                      className={`w-min--60 h-min--60 w-max--60 h-max--60 radius--sm ${selectedButton === 'saved'
                          ? 'bg--primary text--white'
                          : 'bg--black-100'
                        } d--flex align-items--center justify-content--center`}
                    >
                      <CardIcon width={35} height={35} />
                    </div>
                    <div
                      className={`font--md font--600 ${selectedButton === 'saved' ? 'text--primary' : ''
                        } `}
                    >
                      Saved Cards
                    </div>
                  </div>
                </div>
              </div>
              {showInputBox ? (
                <>
                  <div className="w--full">
                    <div className="label--control font--sm font--500 m-b--xs  text--black d--flex text--black ">
                      Card List
                    </div>
                    <div className="w--full">
                      {isLoadingSavedCards ? (
                        <Spinner />
                      ) : savedCardListing && savedCardListing?.length > 0 ? (
                        <>
                          {savedCardListing.map((cardItem) => {
                            return (
                              <label
                                htmlFor={cardItem?.card_id}
                                className="w--full d--flex align-items--center justify-content--between p-t--md p-b--md border-bottom--black-50 c--pointer"
                              >
                                <div className="d--flex align-items--center">
                                  <img height={16} src={ImgVisa} alt="Visa" />
                                  <div className="font--md">
                                    *******{cardItem?.last4}
                                  </div>
                                </div>
                                <div className="text--sm">
                                  <input
                                    className="radio"
                                    id={cardItem?.card_id}
                                    name="selectedCard"
                                    type="radio"
                                    value={cardItem?.card_id}
                                    onChange={(e) =>
                                      setSelectedCard(e.target.value)
                                    }
                                    checked={cardItem?.card_id === selectedCard}
                                  />
                                </div>
                              </label>
                            );
                          })}
                        </>
                      ) : (
                        <>No saved card found.</>
                      )}
                      <div className="d--flex justify-content--center m-t--md">
                        <div className="">
                          <Button
                            type="button"
                            variant="primary"
                            size="sm"
                            btnClasses="btn w-min--100"
                            disabled={!selectedCard || addFundLoad}
                            onClick={() => {
                              setshow('saved-card');
                            }}
                          >
                            {addFundLoad ? 'Please wait..' : `Pay`}
                          </Button>
                        </div>
                      </div>
                    </div>
                    {/* <FormInput /> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="d--flex flex--column ">
                    <Controller
                      name="card_holder_name"
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          type="text"
                          label="Card Holder Name"
                          placeholder="Name"
                          error={errors?.card_holder_name?.message}
                        />
                      )}
                    />
                  </div>

                  <PaymentForm
                    ref={confirmButtonRef}
                    isShowAlert={false}
                    actionFn={addFundMutate}
                  />

                  <div className="d--flex justify-content--center ">
                    <div className="">
                      <Button
                        type="button"
                        variant="primary"
                        size="sm"
                        btnClasses="btn w-min--100"
                        onClick={() => setshow('new-card')}
                        disabled={addFundLoad}
                      >
                        {addFundLoad ? 'Please wait..' : `Pay`}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        show={show}
        setshow={setshow}
        handleConfirm={handleConfirm}
      />
    </>
  );
}

export default AddFunds;
