import { Howl } from 'howler';
import moment from 'moment/moment';
import * as htmlToImage from 'html-to-image';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { AsYouType } from 'libphonenumber-js';

export const INCOMING_RING = 'incoming-call2.ogg';
export const OUTGOING_RING = 'phone_sounds_outgoing.mp3';
export const NOACTIVITY_RING = 'noEventRingTone.mp3';
export const DEFAULT_VOICEMAIL_NAME =
  'ad98d65d-fcf8-4d4d-bc77-ee1426c3433f.mp3';

export const Tone1 = 'tone_1.mp3';
export const Tone2 = 'tone_2.mp3';
export const Tone3 = 'tone_3.mp3';
export const Tone4 = 'tone_4.mp3';
export const Tone5 = 'tone_5.mp3';
export const Tone6 = 'tone_6.mp3';
export const Tone7 = 'tone_7.mp3';
export const Tone8 = 'tone_8.mp3';
export const Tone9 = 'tone_9.mp3';
export const Tone10 = 'tone_10.mp3';
export const Tone11 = 'tone_11.mp3';
export const Tone12 = 'tone_12.mp3';
export const Tone13 = 'tone_13.mp3';
export const Tone14 = 'tone_14.mp3';
export const Tone15 = 'tone_15.mp3';

export const IncomingRing = new Howl({
  src: [`${window.location.origin}/${INCOMING_RING}`],
  loop: true,
  volume: 0.7,
});

export const OutgoigRing = new Howl({
  src: [`${window.location.origin}/${OUTGOING_RING}`],
  loop: true,
});

export const toneLookup = [
  {
    name: 'Tone 1',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone1}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone1}`,
    value: 'tone_1.mp3',
  },
  {
    name: 'Tone 2',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone2}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone2}`,
    value: 'tone_2.mp3',
  },
  {
    name: 'Tone 3',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone3}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone3}`,
    value: 'tone_3.mp3',
  },
  {
    name: 'Tone 4',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone4}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone4}`,
    value: 'tone_4.mp3',
  },
  {
    name: 'Tone 5',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone5}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone5}`,
    value: 'tone_5.mp3',
  },
  {
    name: 'Tone 6',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone6}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone6}`,
    value: 'tone_6.mp3',
  },
  {
    name: 'Tone 7',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone7}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone7}`,
    value: 'tone_7.mp3',
  },
  {
    name: 'Tone 8',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone8}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone8}`,
    value: 'tone_8.mp3',
  },
  {
    name: 'Tone 9',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone9}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone9}`,
    value: 'tone_9.mp3',
  },
  {
    name: 'Tone 10',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone10}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone10}`,
    value: 'tone_10.mp3',
  },
  {
    name: 'Tone 11',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone11}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone11}`,
    value: 'tone_11.mp3',
  },
  {
    name: 'Tone 12',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone12}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone12}`,
    value: 'tone_12.mp3',
  },
  {
    name: 'Tone 13',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone13}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone13}`,
    value: 'tone_13.mp3',
  },
  {
    name: 'Tone 14',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone14}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone14}`,
    value: 'tone_14.mp3',
  },
  {
    name: 'Tone 15',
    toneObj: new Howl({
      src: [`${window.location.origin}/tones/${Tone15}`],
      loop: true,
    }),
    path: `${window.location.origin}/tones/${Tone15}`,
    value: 'tone_15.mp3',
  },
];

export const ADMIN = 1;
export const USER = 2;
export const EMPLOYEE = 3;
export const AGENCY_OWNER = 4;
export const AGENT = 5;
export const AGENCY_AGENT = 6;

export const pauseAllAudio = () => {
  IncomingRing.stop();
  OutgoigRing.stop();
  for (let index = 0; index < toneLookup.length; index++) {
    const element = toneLookup[index];
    element.toneObj.stop();
  }
};

export const memberAgentslabelsArray = [
  { label: 'Employees', value: 0 },
  { label: 'Agents', value: 1 },
];
export const roleLookup = {
  1: 'Admin',
  2: 'Agency',
  3: 'Employee',
  4: 'Sub Agency',
  5: 'Agent',
};
export const IdentityProofTypes = [
  { id: 1, label: 'Drivers License', value: 'drivers_license' },
  { id: 2, label: 'National ID', value: 'national_id' },
  { id: 3, label: 'Passport', value: 'passport' },
  { id: 4, label: 'Residence Permit', value: 'residence_permit' },
  { id: 5, label: 'Visa', value: 'visa' },
  { id: 6, label: 'Other', value: 'other' },
];
export const AddressProofTypes = [
  { id: 1, label: 'Copy of Phone Bill', value: 'phone_bill' },
  { id: 2, label: 'Utility Bill', value: 'utility_bill' },
  { id: 3, label: 'Rental Receipt', value: 'rental_receipt' },
  { id: 4, label: 'Other', value: 'other' },
];

export const addressProofMap = {
  phone_bill: 'Copy of Phone Bill',
  utility_bill: 'Utility Bill',
  rental_receipt: 'Rental Receipt',
  other: 'Other',
};
export const identityProofMap = {
  drivers_license: 'Drivers License',
  national_id: 'National ID',
  passport: 'Passport',
  residence_permit: 'Residence Permit',
  visa: 'Visa',
  other: 'Other',
};

export const portingNumberDocs = {
  Local: 'Customer_US_LOA_v20230306.pdf',
  'Toll-free': 'Customer_TF2_LOA_v20220228.pdf',
};
export const portingNumberDocsDownload = {
  Local: 'Customer_US_LOA.pdf',
  'Toll-free': 'Customer_TF2_LOA.pdf',
};

export const timeoutTypeLookup = {
  EXTENSION: 'Extension',
  VOICEMAIL: 'Voicemail',
  HANGUP: 'Hangup',
  IVR: 'IVR',
  NUMBER: 'Number',
  VOICEMAILGROUP: 'VOICEMAILGROUP',
};
export const timeoutValLookup = {
  Extension: 'EXTENSION',
  Voicemail: 'VOICEMAIL',
  Hangup: 'HANGUP',
  IVR: 'IVR',
  Number: 'NUMBER',
  VOICEMAILGROUP: 'VOICEMAILGROUP',
};

export const timeoutValLookupInverse = {
  EXTENSION: 'Extension',
  VOICEMAIL: 'Voicemail',
  HANGUP: 'Hangup',
  IVR: 'IVR',
  NUMBER: 'Number',
  VOICEMAILGROUP: 'VOICEMAILGROUP',
};

export const roleFilters = [
  {
    label: 'Agency',
    value: 2,
  },
  {
    label: 'Employee',
    value: 3,
  },
];

export function generateRandomNumber(length = 4) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
}

export function formatDate(date) {
  if (!date) return;
  return moment(date).format('MMM DD, YYYY,  h:mm A');
}

export const transcriptColors = {
  1: '#7ED7C1',
  2: '#B2533E',
  3: '#DC8686',
  4: '#EEF296',
  5: '#C683D7',
  6: '#435585',
  7: '#818FB4',
  8: '#39A7FF',
  9: '#FF9209',
  10: '#192655',
};

export const converDateTimeFormateApis = (timestring, format = '') => {
  if (!timestring) return '---';
  const utcMoment = moment.utc(timestring);
  const localMoment = utcMoment.local();
  let timeStrings = localMoment?.format();
  if (!timeStrings) return '---';
  return moment(timeStrings).format(format || 'MMM DD, hh:mm A');
};

export const SecondsTohhmmss = (totalSeconds) => {
  if (!totalSeconds) {
    totalSeconds = 0;
  }
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = totalSeconds - hours * 3600 - minutes * 60;
  // round seconds
  seconds = Math.round(seconds * 100) / 100;
  let result = hours === 0 ? '' : hours < 10 ? '0' + hours + ':' : hours + ':';
  result += minutes < 10 ? '0' + minutes : minutes;
  result += ':' + (seconds < 10 ? '0' + seconds : seconds);
  return result;
};

export const formatPhoneNumber = (number) => {
  if (!number) return;

  if (number && number?.length < 5) return number;
  if (number.includes('+')) {
    const phoneNumber = parsePhoneNumberFromString(number);
    return phoneNumber?.formatInternational() || number;
  } else {
    const formattedNumber = `+${number}`;
    const phoneNumber = parsePhoneNumberFromString(formattedNumber);
    return phoneNumber?.formatInternational() || number;
  }
};

export const durationToMMSS = (time) => {
  const duration = moment.duration(time, 'seconds');
  const minutes = Math.floor(duration.asMinutes()).toString().padStart(2, '0');
  const seconds = Math.floor(duration.asSeconds() - minutes * 60)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${seconds}`;
};

export function formatDateOnly(date) {
  if (!date) return;
  return moment(date).format('MMM DD, YYYY');
}

export function getInitials(name) {
  if (!name) return;
  return name ? name.charAt(0).toUpperCase() : 'NA';
}

export function stringToColour(str) {
  if (!str || !str.length) return;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

export const darkenColor = (hexColor, factor = 20) => {
  if (!hexColor) return '#fff';
  // Remove the '#' character if present
  hexColor = hexColor.replace('#', '');

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Darken the color by reducing the RGB values
  const newR = Math.max(0, r - factor);
  const newG = Math.max(0, g - factor);
  const newB = Math.max(0, b - factor);

  // Convert the darkened RGB back to hex
  const darkenedColor = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return darkenedColor;
};

export const lightenColor = (hexColor, factor = 20) => {
  if (!hexColor) return '#fff';

  // Remove the '#' character if present
  hexColor = hexColor.replace('#', '');

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Lighten the color by increasing the RGB values
  const newR = Math.min(255, r + factor);
  const newG = Math.min(255, g + factor);
  const newB = Math.min(255, b + factor);

  // Convert the lightened RGB back to hex
  const lightenedColor = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return lightenedColor;
};

export const noActivityRing = new Howl({
  src: [`${window.location.origin}/${NOACTIVITY_RING}`],
  loop: true,
});

export const getToken = () => {
  return localStorage.getItem('nph-acc-tkn') || null;
};

export const DateFilterTypes = [
  { label: 'Today', value: 'Today' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'This Week', value: 'This Week' },
  { label: 'Last 7 Days', value: 'Last 7 Days' },
  { label: 'Last 30 Days', value: 'Last 30 Days' },
  { label: 'This Month', value: 'This Month' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Select Date', value: 'Select Date' },
  { label: 'Custom Range', value: 'Custom' },
];
export const customDateFilterTypes = [
  { label: 'Today', value: 'Today' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'This Week', value: 'This Week' },
  { label: 'Last 7 Days', value: 'Last 7 Days' },
  { label: 'This Month', value: 'This Month' },
  { label: 'Last 30 Days', value: 'Last 30 Days' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Select Date', value: 'Select Date' },
  { label: 'Custom Range', value: 'Custom' },
];
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const loadCssFile = (filename, id) => {
  var fileref = document.createElement('link');
  fileref.rel = 'stylesheet';
  fileref.type = 'text/css';
  fileref.id = id;
  fileref.href = filename;
  document.getElementsByTagName('head')[0].appendChild(fileref);
};

export const downloadFileFromURL = (url = '') => {
  if (!url) return;
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('target', '_blank');
  element.setAttribute('download', 'document');
  element.click();
};

export const loadAudioFileAsync = (url) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function () {
      if (xhr.status === 200) {
        var arrayBuffer = xhr.response;
        resolve(arrayBuffer);
      } else {
        reject('Error loading audio file');
      }
    };
    xhr.onerror = function () {
      reject('Network error');
    };
    xhr.send();
  });
};

let timeoutID;
export const debounceFn = (cb, t) => {
  return function (param) {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    timeoutID = setTimeout(() => {
      cb(param);
    }, t);
  };
};

export const ayt = (number) => {
  return new AsYouType().input(number);
};

export const hoursArray = [
  // { start_time: '00:00', end_time: '00:00' },
  // { start_time: '00:00', end_time: '01:00' },
  // { start_time: '01:00', end_time: '02:00' },
  // { start_time: '02:00', end_time: '03:00' },
  // { start_time: '03:00', end_time: '04:00' },
  // { start_time: '04:00', end_time: '05:00' },
  // { start_time: '05:00', end_time: '06:00' },
  // { start_time: '06:00', end_time: '07:00' },
  // { start_time: '07:00', end_time: '08:00' },
  // { start_time: '08:00', end_time: '09:00' },
  { start_time: '09:00', end_time: '10:00' },
  { start_time: '10:00', end_time: '11:00' },
  { start_time: '11:00', end_time: '12:00' },
  { start_time: '12:00', end_time: '13:00' },
  { start_time: '13:00', end_time: '14:00' },
  { start_time: '14:00', end_time: '15:00' },
  { start_time: '15:00', end_time: '16:00' },
  { start_time: '16:00', end_time: '17:00' },
  { start_time: '17:00', end_time: '18:00' },
  { start_time: '18:00', end_time: '19:00' },
  { start_time: '19:00', end_time: '20:00' },
  { start_time: '20:00', end_time: '21:00' },
  { start_time: '21:00', end_time: '22:00' },
  // { start_time: '22:00', end_time: '23:00' },
  // { start_time: '23:00', end_time: '23:59' },
];

export function generateUUID() {
  var d = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
  return uuid;
}

export const generateUnixTimestamp = ({ type = 'day', isUtc = true, date }) => {
  let unixStartTimestamp;
  let unixEndTimestamp;
  switch (type) {
    case 'day':
      unixStartTimestamp = date.utc().startOf('day').valueOf();
      unixEndTimestamp = date.utc().endOf('day').valueOf();
      return {
        start: unixStartTimestamp,
        end: unixEndTimestamp,
      };
    case 'week':
      unixStartTimestamp = date.utc().startOf('week').valueOf();
      unixEndTimestamp = date.utc().endOf('week').valueOf();
      return {
        start: unixStartTimestamp,
        end: unixEndTimestamp,
      };
    case 'month':
      unixStartTimestamp = date.utc().startOf('month').valueOf();
      unixEndTimestamp = date.utc().endOf('month').valueOf();
      return {
        start: unixStartTimestamp,
        end: unixEndTimestamp,
      };

    default:
      break;
  }
};

export const get_browser = () => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

export const isPlatform = () => {
  const { userAgent, maxTouchPoints, platform } = navigator;
  let OS;
  if (userAgent.match(/Android/i)) {
    OS = 'android';
  } else if (
    userAgent.match(/iP(ad|hone|od)/i) ||
    (maxTouchPoints && maxTouchPoints > 2 && /MacIntel/.test(platform))
  ) {
    OS = 'ios';
  } else if (userAgent.match(/Mac(intosh| OS X)/i)) {
    OS = 'macos';
  } else if (userAgent.match(/Windows/i)) {
    OS = 'windows';
  }
  return OS;
};

export const getSessionInfo = () => {
  const browser = get_browser();
  const os = isPlatform();
  const browser_version = `${browser?.name} - ${browser?.version}`;
  const os_version = os;

  return {
    device_type: isElectron ? 'desktop' : 'browser',
    browser_version: browser_version ? browser_version : null,
    os_version: os_version ? os_version : null,
  };
};

export const departmentLabelsArray = [
  { label: 'Basic', value: 0 },
  { label: 'Operational Hours', value: 1 },
  { label: 'Voicemail', value: 2 },
  { label: 'Auto SMS Reply Setting', value: 3 },
];
export function getNext12HoursData(selectedLabel, dataArray) {
  // Find the index of the selected label in the dataArray
  let selectedIndex = dataArray.findIndex(
    (item) => item.label === selectedLabel,
  );

  // If the selected label is not found, return an empty array
  if (selectedIndex === -1) {
    return [];
  }

  // Get the current timestamp value
  let currentTimestamp = dataArray[selectedIndex].value;

  // Calculate the timestamp for 12 hours later
  let twelveHoursLaterTimestamp = currentTimestamp + 12 * 60 * 60; // 12 hours * 60 minutes * 60 seconds

  // Initialize an array to store the next 12 hours of data
  let next12HoursData = [];

  // Loop through the dataArray to collect the next 12 hours of data
  for (let i = selectedIndex; i < dataArray.length; i++) {
    next12HoursData.push(dataArray[i]);

    // If the timestamp exceeds twelve hours later, break the loop
    if (dataArray[i].value >= twelveHoursLaterTimestamp) {
      break;
    }
  }

  // If the selected time is not at the end of the array, include entries from the beginning to complete 12 hours
  if (next12HoursData.length < 12) {
    let remainingHours = 12 - next12HoursData.length;
    for (let i = 0; i < selectedIndex; i++) {
      next12HoursData.push(dataArray[i]);
      remainingHours--;

      // If the remaining hours are filled, break the loop
      if (remainingHours === 0) {
        break;
      }
    }
  }

  return next12HoursData;
}

export const minArray = [
  {
    label: '00',
  },
  {
    label: '05',
  },
  {
    label: '10',
  },
  {
    label: '15',
  },
  {
    label: '20',
  },
  {
    label: '25',
  },
  {
    label: '30',
  },
  {
    label: '35',
  },
  {
    label: '40',
  },
  {
    label: '45',
  },
  {
    label: '50',
  },
  {
    label: '55',
  },
  {
    label: '60',
  },
];

export function capitalizeWords(str, delimiter = '_') {
  const parts = str.split(delimiter);

  const capitalizedWords = [];

  parts.forEach((part) => {
    capitalizedWords.push(part.charAt(0).toUpperCase() + part.slice(1));
  });

  return capitalizedWords.join(' ');
}
export const isElectron = navigator.userAgent.includes('Electron');

export const takeScreenshot = async ({ elementId = '' }) => {
  if (!elementId) return null;
  const divElement = document.getElementById(elementId);
  const blob = await htmlToImage.toBlob(divElement);
  const file = new File([blob], 'screenshot.png', {
    type: blob.type,
    lastModified: Date.now(),
    size: blob.size,
  });
  return file;
};

export function notifyMe({ body = '', title = '' }) {
  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    const notification = new Notification(
      `You are mentioned in group ${title}`,
      { icon: '/assets/icons/fav.png', body: body },
    );
    // …
  } else if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        const notification = new Notification(
          `You are mentioned in group ${title}`,
          { icon: '/assets/icons/fav.png', body },
        );
        // …
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
}

export function getOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for Windows
  if (/windows phone/i.test(userAgent) || /win/i.test(userAgent)) {
    return 'Windows';
  }

  // Check for Android
  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // Check for iOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  // Check for macOS
  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    return 'macOS';
  }

  // Default to unknown
  return 'Unknown';
}