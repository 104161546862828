import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../Button';
import FormSelect from '../FormSelect';
import { generateTimeArray } from '../../pages/Dashboard/helpers';
import { getNext12HoursData } from '../../helpers/functions';
import useClickOutside from '../../hooks/useClickOutside';

const RenderOption = ({ item }) => {
  return <option value={item?.value}>{item?.label}</option>;
};

const TimePicker = ({
  setStartTimeLine,
  setEndTimeLine,
  showTimePicker,
  startTimeLine,
  endTimeLine,
}) => {
  const [startRange, setStartRange] = useState(startTimeLine);
  const [endRange, setEndRange] = useState(endTimeLine);
  const { timeArray } = generateTimeArray();
  const timePickerRef = useRef();
  const nextHours = useMemo(
    () =>
      getNext12HoursData(
        timeArray?.find((t) => t.value === parseInt(startRange))?.label,
        timeArray,
      ),
    [startRange],
  );

  function onApply() {
    setStartTimeLine(startRange);
    setEndTimeLine(endRange);
    showTimePicker(false);
  }

  function onClose() {
    showTimePicker(false);
  }

  useClickOutside([timePickerRef], () => {
    onClose();
  });

  return (
    <div
      className="position--absolute right--0 d--flex w-min--400 bg--primary-100 radius--full p--sm   top--40 box-shadow--xs align-items--center"
      ref={timePickerRef}
    >
      <FormSelect
        onChange={(e) => setStartRange(e.target.value)}
        value={startRange}
        placeholder={'Start time'}
        options={timeArray || []}
        renderOption={RenderOption}
        height="28"
        paddingLeft="0"
        paddingRight="0"
        extraClasses="border--0  bg--transparent"
      />
      <FormSelect
        onChange={(e) => setEndRange(e.target.value)}
        value={endRange}
        placeholder={'End time'}
        options={nextHours || []}
        renderOption={RenderOption}
        height="28"
        paddingLeft="0"
        paddingRight="0"
        extraClasses="border--0 bg--transparent"
      />

      <Button
        type="button"
        variant="primary"
        color="white"
        onClick={onApply}
        btnClasses="btn btn--xs  w-max--110 font--sm  radius--full"
      >
        Apply
      </Button>
      <Button
        type="button"
        variant="red"
        color="white"
        btnClasses="btn btn--xs  w-max--110 font--sm  radius--full"
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  );
};

export default TimePicker;
