import React, { useEffect, useState } from 'react';
import Button from '../../../comopnents/Button';
import usePortalAccess from '../../../hooks/useSetPortalAccess';

const portalArray = [
  { id: 1, label: 'Browser', value: 'web' },
  { id: 2, label: 'Desktop App', value: 'desktop' },
  { id: 3, label: 'Mobile', value: 'mobile' },
];

const PortalAccessModal = ({ handleClose = () => null, employeeData }) => {
  const [access, setAccess] = useState([]);

  useEffect(() => {
    if (employeeData && employeeData.portal_access) {
      setAccess(employeeData.portal_access);
    }
  }, [employeeData]);

  const { mutate: setPortalAccessMutate, isLoading: setPortalAccessLoad } =
    usePortalAccess({
      handleSuccess: () => {
        handleClose();
      },
    });

  function onSubmit() {
    setPortalAccessMutate({
      uuid: employeeData?.uuid,
      portal_access: access,
    });
  }
  return (
    <div
      className=" d--flex flex--column gap--md "
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d--flex flex--column gap--md overflow--auto h-min--200 h-max--300">
        {portalArray.map((item) => {
          return (
            <div className="d--flex gap--sm" key={item.id}>
              <input
                id={item.id}
                type="checkbox"
                className="form--control no--focus"
                checked={access.includes(item.value)}
                onChange={() => {
                  if (access.includes(item.value)) {
                    setAccess((prev) => {
                      return prev.filter((_) => _ !== item.value);
                    });
                  } else {
                    setAccess((_) => [..._, item.value]);
                  }
                }}
              />
              <label
                className={`label--control font--sm font--500  d--flex c--pointer`}
                htmlFor={item.id}
              >
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
      <div className="d--flex justify-content--between gap--md  p-t--sm">
        <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="button"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={setPortalAccessLoad || access.length === 0}
          onClick={onSubmit}
        >
          {setPortalAccessLoad ? 'Please wait..' : 'Update'}
        </Button>
      </div>
    </div>
  );
};

export default PortalAccessModal;
