import React, { useState } from 'react';
import Button from '../Button';
import useIcons from '../../assets/icons/useIcons';

export default function ConfirmComponent({
  children,
  label = 'Sure',
  confirmCallback = () => null,
  element = {},
}) {
  const { CheckIcon, CrossRoundIcon } = useIcons();
  const [confirm, setConfirm] = useState(false);
  return (
    <span className="d--flex align-items--start justify-content--start flex--column w--full  ">
      {confirm ? (
        <span className="d--flex align-items--center justify-content--center flex--column w--full gap--sm w-min--100">
          {label}
          <div className="d--flex justify-content--center gap--xs">
            <Button
              type="button"
              size="sm"
              variant="primary-100"
              color="primary"
              rounded
              btnClasses="w-min--32 h-min--32 w-max--32 h-max--32 w--full h--full d--flex align-items--center justify-content--center radius--sm c--pointer"
              onClick={() => setConfirm(false)}
              icon={<CrossRoundIcon width={30} height={30} />}
            />

            <Button
              type="submit"
              size="sm"
              variant="secondary-100"
              color="secondary"
              rounded
              btnClasses="w-min--32 h-min--32 w-max--32 h-max--32 w--full h--full d--flex align-items--center justify-content--center radius--sm c--pointer"
              onClick={() => confirmCallback(element)}
              icon={<CheckIcon width={30} height={30} />}
            />
          </div>
        </span>
      ) : (
        <span
          onClick={() => setConfirm(true)}
          className="w--full d--flex align-items--start text--danger p--2sm"
        >
          {children}
        </span>
      )}
    </span>
  );
}
