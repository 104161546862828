import React, { useState } from 'react';
import useIcons from '../../assets/icons/useIcons';
import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../../hooks/useAlert';
import Spinner from '../Spinner';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

const TableRow = ({
  row,
  enableMoreOption,
  showMoreOption,
  flexRender,
  rowSelection,
  moreDataFunction = () => null,
  fetcherKey = '',
  moreDataFnPayload,
  memoizedColumns,
}) => {
  const shouldHighlight =
    row?.original?.hasOwnProperty('seen_at') &&
      row?.original?.hasOwnProperty('recording_file')
      ? !row?.original?.seen_at && Boolean(row?.original?.recording_file)
        ? true
        : false
      : false;

  const { PlusIcon, MinusIcon } = useIcons();
  const { showAlert } = useAlert();

  const [relevantData, setRelevantData] = useState([]);
  const [showRelevantData, setShowRelevantData] = useState(false);

  const { isLoading: loadingMoreData, mutate: loadMoreDataFn } = useMutation({
    mutationFn: moreDataFunction,
    mutationKey: [`${fetcherKey}`],
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
    onSuccess: (data) => {
      setRelevantData(data?.data?.data || []);
    },
  });

  function handleMoreData() {
    const payload = moreDataFnPayload(row?.original);
    loadMoreDataFn(payload);
  }

  const tableInstance = useReactTable({
    debugAll: false,
    data: relevantData || [],
    columns: memoizedColumns || [],
    columnResizeMode: 'onChange',
    state: {
      rowSelection,
    },
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <tr
        key={row?.id}
        className={`${rowSelection?.[row?.index] ? 'selected' : ''} ${shouldHighlight ? 'font--600' : ''
          }`}
      >
        {enableMoreOption && (
          <td
            style={{
              width: '0.2%',
            }}
          >
            {showMoreOption(row?.original) && (
              <>
                {loadingMoreData ? (
                  <div className="d--flex">
                    <Spinner />
                  </div>
                ) : (
                  <div
                    className="c--pointer w-max--16 w-min--16 h-min--16 radius--xs bg--primary d--flex align-items--center justify-content--center text--white "
                    onClick={() => {
                      if (relevantData?.length > 0) {
                        setShowRelevantData((prev) => !prev);
                      } else {
                        handleMoreData();
                        setShowRelevantData(true);
                      }
                    }}
                  >
                    {showRelevantData ? (
                      <MinusIcon width={14} height={14} />
                    ) : (
                      <PlusIcon width={14} height={14} />
                    )}
                  </div>
                )}
              </>
            )}
          </td>
        )}
        {row?.getVisibleCells()?.map((cell) => {
          return (
            <td
              key={cell?.id}
              style={{
                textAlign: cell?.column?.columnDef?.meta?.textAlign ?? 'left',
                width:
                  cell?.column?.columnDef?.meta?.width ||
                  `${(100 / cell?.headerGroup?.headers?.length).toFixed(2)}%`,
                cursor: cell?.column?.columnDef?.meta?.shouldPointer
                  ? 'pointer'
                  : '',
              }}
            >
              <>
                {cell?.column?.id === 'action' ||
                  cell?.column?.columnDef?.meta?.colType === 'action'
                  ? flexRender(cell.column.columnDef.cell, cell.getContext())
                  : cell?.getValue() || parseInt(cell?.getValue()) === 0
                    ? flexRender(cell.column.columnDef.cell, cell.getContext())
                    : '---'}
              </>
            </td>
          );
        })}
      </tr>
      {/* {!loadingMoreData && (
        <tr>
          <Spinner />
        </tr>
      )} */}
      {showRelevantData && (
        <>
          {tableInstance?.getRowModel()?.rows &&
            tableInstance?.getRowModel()?.rows.length > 0 &&
            tableInstance?.getRowModel()?.rows?.map((row) => {
              return (
                <tr
                  key={row?.id}
                  className={`${rowSelection?.[row?.index] ? 'selected' : ''}`}
                >
                  <td
                    className="bg--black-50"
                    style={{
                      width: '2%',
                    }}
                  ></td>
                  {row?.getVisibleCells()?.map((cell) => {
                    return (
                      <td
                        className="bg--black-50"
                        key={cell?.id}
                        style={{
                          textAlign:
                            cell?.column?.columnDef?.meta?.textAlign ?? 'left',
                          width:
                            cell?.column?.columnDef?.meta?.width ||
                            `${(
                              100 / cell?.headerGroup?.headers?.length
                            ).toFixed(2)}%`,
                          cursor: cell?.column?.columnDef?.meta?.shouldPointer
                            ? 'pointer'
                            : '',
                        }}
                      >
                        <>
                          {cell?.column?.id === 'action' ||
                            cell?.column?.columnDef?.meta?.colType === 'action'
                            ? flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )
                            : cell?.getValue() ||
                              parseInt(cell?.getValue()) === 0
                              ? flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )
                              : '---'}
                        </>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </>
      )}
    </>
  );
};

export default TableRow;
