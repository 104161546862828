import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAlert } from '../hooks/useAlert';
import Alert from '../comopnents/Alert';

export default function OuterLayout() {
  const { alerts } = useAlert();

  return (
    <div className="w--full h--full">
      {/* <div className="radius--md  gap--xl w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box-shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300"> */}
      <Outlet />
      {/* </div> */}

      <div className="main-alert position--absolute d--flex flex--column gap--sm">
        {alerts?.map((alert) => (
          <Alert key={alert?.id} alert={alert} />
        ))}
      </div>
    </div>
  );
}
