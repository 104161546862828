import React from 'react';
import { NavLink } from 'react-router-dom';
import useIcons from '../../assets/icons/useIcons';
import { USER } from '../../helpers/functions';
import { useAuth } from '../../hooks/useAuth';
import { Outlet } from 'react-router-dom';

const Reports = () => {
  const {
    CallIcon,
    DashboardIcon,
    MessageIcon,
    UserIcon,
    UsersIcon,
    ListIcon,
    CallRecordIcon,
    VoicemailIcon,
  } = useIcons();
  const { user } = useAuth();
  const callTabs = [
    {
      id: 1,
      label: 'Overview',
      icon: DashboardIcon,
      path: '/reports/overview',
      access: [USER],
    },
    {
      id: 2,
      label: 'Calls',
      icon: CallIcon,
      path: '/reports/calls',
      access: [USER],
    },
    {
      id: 7,
      label: 'Call Recording',
      icon: CallRecordIcon,
      path: '/reports/call-recording',
      access: [USER],
    },
    {
      id: 8,
      label: 'Voicemail',
      icon: VoicemailIcon,
      path: '/reports/voicemail',
      access: [USER],
    },
    {
      id: 3,
      label: 'SMS',
      icon: MessageIcon,
      path: '/reports/sms',
      access: [USER],
    },
    {
      id: 4,
      label: 'Employees/Agents',
      icon: UserIcon,
      path: '/reports/employees',
      access: [USER],
    },
    // {
    //   id: 5,
    //   label: 'Agents',
    //   icon: UsersIcon,
    //   path: '/reports/agents',
    //   access: [USER],
    // },
    // {
    //   id: 6,
    //   label: 'Numbers',
    //   icon: ListIcon,
    //   path: '/reports/numbers',
    //   access: [USER],
    // },
  ];
  return (
    <div className="w--full h--full d--flex gap--md reportPage">
      <div className="w-min--200 w-max--200 w--full flex--column d--flex">
        <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
          Reports
        </h4>
        <div className="sideTab w--full h--full w-min--200 w-max--200 bg--white box-shadow--xs radius--sm p--md d--flex flex--column gap--md">
          {callTabs &&
            callTabs.map(({ id, label, icon: Icon, path, access }) => {
              if (![user?.role_id]?.find((role) => access?.includes(role))) {
                return;
              }
              return (
                <NavLink
                  to={path}
                  key={id}
                  data-link-hover
                  className={({ isActive }) =>
                    `${isActive ? 'bg--primary-100' : ''
                    }  d--flex align-items--center justify-content--start text--black  d--flex gap--sm p--sm radius--sm h-min--36 font--500`
                  }
                >
                  <Icon width={18} /> {label}
                </NavLink>
              );
            })}
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Reports;
