import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { setZohoToken } from '../../api';

export default function useZohoSetToken({ handleSuccess = () => null } = {}) {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: setZohoToken,
    mutationKey: 'setZohoToken',
    onSuccess: ({ data }) => {
      handleSuccess();
      showAlert({
        type: 'success',
        message: data?.message ?? 'Set token sucessfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
