import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { updateProfile } from '../../api';

export default function useProfileUpdate(shouldInvalidate = true) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const updateProfileRequest = useMutation({
    mutationFn: updateProfile,
    mutationKey: 'updateProfile',
    onSuccess: ({ data }) => {
      if (shouldInvalidate) {
        queryClient.invalidateQueries({
          queryKey: ['getUserDetails'],
          exact: false,
        });
        queryClient.invalidateQueries({
          queryKey: ['getMedia'],
          exact: false,
        });
      }
      showAlert({
        type: 'success',
        message: data?.message ?? 'Success',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...updateProfileRequest };
}
