import { useContext, useEffect, useState } from 'react';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';
import { useAuth } from '../../hooks/useAuth';
import { useSearchParams } from 'react-router-dom';
import Avatar from '../Avatar';
import useIcons from '../../assets/icons/useIcons';

const GetNameBySocketObCall = ({
  number = '',
  getAvatar = false,
  getNumber = true,
  avatarSize = '40',
}) => {
  const { user = null } = useAuth();
  const { socket = {} } = useContext(SipSocketContext);
  const [name, setName] = useState(null);
  const [data, setData] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const { UserIcon } = useIcons();

  const contactInfo =
    searchParams && searchParams?.get('x-cn')
      ? JSON.parse(searchParams?.get('x-cn'))
      : null;

  function handleGetContactName(n) {
    if (!n) return;

    socket?.sipCallSocket.emit(
      'dialer-phone-info',
      {
        domain: user?.domain,
        phone: n,
        uuid: user?.uuid,
      },
      async (res) => {
        const response = await res;
        if (response?.status === 200) {
          setData(response?.data);
          const name = `${response?.data?.first_name ?? ''} ${
            response?.data?.last_name ?? ''
          }`;
          setName(name);
          sessionStorage.setItem('cid', response?.data?.uuid);
        } else {
          setData(null);
          if (getNumber) {
            setName(number);
          } else {
            setName('');
          }
          if (sessionStorage.getItem('cid')) {
            sessionStorage.removeItem('cid');
          }
        }
      },
    );
  }

  useEffect(() => {
    if (contactInfo && Object.keys(contactInfo).length > 0) {
      const name = `${contactInfo?.first_name ?? ''} ${
        contactInfo?.last_name ?? ''
      }`;
      setName(name);
      sessionStorage.setItem('cid', contactInfo?.uuid);
    } else {
      if (number && socket?.sipCallSocket) {
        handleGetContactName(number);
      }
    }
  }, [number, socket?.sipCallSocket, contactInfo]);

  if (getAvatar) {
    if (
      (data && Object.keys(data).length > 0) ||
      (contactInfo && Object.keys(contactInfo).length > 0)
    ) {
      return (
        <Avatar
          size={avatarSize}
          first_name={data?.first_name || contactInfo?.first_name || 'U'}
          last_name={data?.last_name || contactInfo?.last_name || ''}
          shouldShowPresence={false}
          contactImage={data?.contact_pic || contactInfo?.contact_pic || null}
        />
      );
    } else {
      return <UserIcon />;
    }
  }

  return name;
};

export default GetNameBySocketObCall;
