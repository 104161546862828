import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCard } from '../../api';
import { useAlert } from '../useAlert';

export default function useDeleteCard({ handleSuccess = () => null } = {}) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: deleteCard,
    mutationKey: 'deleteCard',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['getSavedCards'],
        exact: false,
      });
      showAlert({
        type: 'success',
        message: data?.message ?? 'Card deleted successfully',
      });
      handleSuccess();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
