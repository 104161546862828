import React, { useState, useContext, createContext, useMemo } from 'react';
import { isElectron } from '../../helpers/functions';
const AuthContext = createContext(null);

export const Auth = ({ children }) => {
  const [user, setCurrentUser] = useState(null);
  const setUser = (data) => {
    localStorage.setItem('nph-user-role', data?.role_id);
    setCurrentUser({ token: data.token, role_id: data.role_id });
    localStorage.setItem('nph-acc-tkn', data.token);
  };

  const clearUser = () => {
    localStorage.removeItem('nph-acc-tkn');
    localStorage.clear();
    sessionStorage.clear();
    setCurrentUser(null);
    if (isElectron) {
      window.location.href = '/login';
    } else {
      window.location.href = '/';
    }
  };

  const setUserData = (payload) => {
    localStorage.setItem('nph-user-role', payload?.role_id);
    setCurrentUser({ ...payload });
  };

  const values = useMemo(
    () => ({ user, setUser, clearUser, setUserData, setCurrentUser }),
    [user],
  );
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
