import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import useIcons from '../../assets/icons/useIcons';
import {
  AGENCY_AGENT,
  AGENCY_OWNER,
  AGENT,
  EMPLOYEE,
  USER,
} from '../../helpers/functions';
import { useAuth } from '../../hooks/useAuth';

const MyAccountPage = () => {
  const {
    ProfileIcon,
    TransferIcon,
    AddCardIcon,
    CardIcon,
    TreeIcon,
    SupportIcon,
    UserInfoIcon,
    KeyIcon,
  } = useIcons();
  const { user } = useAuth();
  const profileTabs = [
    {
      id: 1,
      label: 'My Profile',
      icon: ProfileIcon,
      path: '/myaccount/account-info',
      access: [USER, AGENCY_OWNER, EMPLOYEE, AGENT, AGENCY_AGENT],
    },

    {
      id: 6,
      label: 'Agency Company Info',
      icon: UserInfoIcon,
      path: '/myaccount/agency-info',
      access: [AGENCY_OWNER],
    },
    {
      id: 5,
      label: 'Company Info',
      icon: UserInfoIcon,
      path: '/myaccount/company-info',
      access: [USER],
    },
    {
      id: 6,
      label: 'Settings',
      icon: KeyIcon,
      path: '/myaccount/change-password',
      access: [USER, AGENCY_OWNER, EMPLOYEE, AGENT, AGENCY_AGENT],
    },
    {
      id: 2,
      label: 'Transaction',
      icon: TransferIcon,
      path: '/myaccount/transaction',
      access: [USER],
    },
    {
      id: 3,
      label: 'Add Funds',
      icon: AddCardIcon,
      path: '/myaccount/addfunds',
      access: [USER],
    },
    {
      id: 3,
      label: 'Manage Cards',
      icon: CardIcon,
      path: '/myaccount/managecards',
      access: [USER],
    },
    {
      id: 3,
      label: 'Plan',
      icon: TreeIcon,
      path: '/myaccount/plan',
      access: [USER],
    },
    {
      id: 4,
      label: 'Support',
      icon: SupportIcon,
      path: '/myaccount/support',
      access: [USER, AGENCY_OWNER, EMPLOYEE, AGENT, AGENCY_AGENT],
    },
  ];
  return (
    <div className="w--full h--full d--flex gap--md callPage">
      <div className="w-min--200 w-max--200 w--full flex--column d--flex">
        <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
          My Account
        </h4>
        <div className="sideTab w--full h--full w-min--200 w-max--200 bg--white box-shadow--xs radius--sm p--md d--flex flex--column gap--md">
          {profileTabs &&
            profileTabs.map(
              ({ id, label, icon: Icon, path, access }, index) => {
                if (![user?.role_id]?.find((role) => access?.includes(role))) {
                  return <React.Fragment key={index}></React.Fragment>;
                }
                return (
                  <NavLink
                    to={path}
                    key={id}
                    data-link-hover
                    className={({ isActive }) =>
                      `${
                        isActive ? 'bg--primary-100' : ''
                      }  d--flex align-items--center justify-content--start text--black  d--flex gap--sm p--sm radius--sm h-min--36 font--500`
                    }
                  >
                    <Icon width={18} /> {label}
                  </NavLink>
                );
              },
            )}
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default MyAccountPage;
