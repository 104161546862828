import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import useProfileUpdate from '../../hooks/useProfileUpdate';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';

let timer = null;
const shouldInvalidate = false;

function formatSeconds(seconds) {
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  if (days > 0) {
    return `${days}d, ${hours}h, ${minutes}m, ${seconds}s`;
  }
  if (hours > 0) {
    return `${hours}h, ${minutes}m, ${seconds}s`;
  }
  if (minutes > 0) {
    return `${minutes}m, ${seconds}s`;
  }
  return `${seconds}s`;
}

const Timer = ({ time = null, setTimer, extension = '' }) => {
  const { setCurrentUser } = useAuth();
  const { user } = useAuth();
  const { socket } = useContext(SipSocketContext);
  const { mutate } = useProfileUpdate(shouldInvalidate);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (time) {
      timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(time));
      }, 1000);

      return () => {
        if (timer) {
          clearInterval(timer);
        }
      };
    }
  }, [time]);

  useEffect(() => {
    if (timeLeft < 0) {
      if (timer) {
        clearInterval(timer);
        setTimer(null);
        setTimeLeft(null);
      }
    }
  }, [timeLeft]);

  function calculateTimeLeft(time) {
    const eventTime = moment(time);
    const currentTime = moment();
    const duration = eventTime.diff(currentTime, 'seconds');

    return duration;
  }

  function statusChangeEvent(status) {
    socket?.sipCallSocket?.emit('user-presence-update', {
      doc: {
        userId: user?.extension,
        domain: user?.domain,
        uuid: user?.uuid,
        status: status,
      },
    });
  }

  if (!time) return null;

  return (
    <div className="d--flex align-items--center justify-content--center font--xs font--600 gap--sm">
      {timeLeft > 0 && <> {formatSeconds(timeLeft)}</>}
      {/* {timeLeft?.days ? <div>{timeLeft?.days}d</div> : null}
      {timeLeft?.hours ? <div>{timeLeft?.hours}h</div> : null}
      {timeLeft?.minutes ? <div>{timeLeft?.minutes}m</div> : null}
      {timeLeft?.seconds ? <div>{timeLeft?.seconds}s</div> : null} */}
    </div>
  );
};

export default Timer;
