import React, { useContext, useState } from 'react';
import TableManager from '../../../comopnents/TableManager';
import { reportsAgentsList } from '../../../api';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import useIcons from '../../../assets/icons/useIcons';
import useDropdownAgentListing from '../../../hooks/useDropdownAgentListing';
import AsideModal from '../../../comopnents/AsideModal';
import EmployeeAgentStatistics from '../Employees/EmployeeAgentStatistics';
import Avatar from '../../../comopnents/Avatar';

const Agents = ({ setActivity = () => null }) => {
  const [agentStats, setAgentStats] = useState({});
  const { socket = {} } = useContext(SipSocketContext);
  const [agentFilter, setAgentFilter] = useState('total');
  const [showActivity, setShowActivity] = useState({
    isShow: false,
    elementData: null,
  });
  const [showStatistics, setShowStatisics] = useState({
    isShow: false,
    elementData: null,
  });
  const { data: agentAllList = [] } = useDropdownAgentListing();
  const { _makeCall = () => null } = socket;
  const { EyeIcon, GraphIcon } = useIcons();
  const {
    total_agents = 0,
    active_agents = 0,
    inactive_agents = 0,
  } = agentStats || {};

  const handleMakeCall = (phoneNumber) => {
    if (phoneNumber) {
      _makeCall(phoneNumber, phoneNumber?.replaceAll(' ', ''));
    }
  };

  const columns = [
    {
      accessorKey: 'account_number',
      header: () => 'Agent ID',
      meta: {
        width: '3%',
      },
    },
    {
      accessorKey: 'first_name',
      header: () => 'Name',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <span className="d--flex gap--sm  align-items--center">
            <Avatar
              size="28"
              first_name={element?.first_name}
              last_name={element?.last_name}
              image={element.profile_pic}
              extension={element?.extension}
            />
            <span className="ellipsis--15">
              {element?.first_name} {element?.last_name}
            </span>
          </span>
        );
      },
      meta: {
        width: '8%',
      },
    },
    {
      accessorKey: 'extension',
      header: () => 'Ext',
      meta: {
        textAlign: 'center',
        width: '3%',
      },
    },
    // {
    //   accessorKey: 'total_calls',
    //   header: () => 'Calls',
    //   meta: {
    //     textAlign: 'center',
    //     width: "3%"
    //   },
    // },
    // {
    //   accessorKey: 'inbound_calls',
    //   header: () => 'Incoming',
    //   meta: {
    //     textAlign: 'center',
    //     width: "3%"
    //   },
    // },
    // {
    //   accessorKey: 'outbound_calls',
    //   header: () => 'Outgoing',
    //   meta: {
    //     textAlign: 'center',
    //     width: "3%"
    //   },
    // },
    // {
    //   accessorKey: 'missed_calls',
    //   header: () => 'Missed',
    //   meta: {
    //     textAlign: 'center',
    //     width: "3%"
    //   },
    // },
    // {
    //   accessorKey: 'voicemail',
    //   header: () => 'Voicemail',
    //   meta: {
    //     textAlign: 'center',
    //     width: "3%"
    //   },
    // },
    // {
    //   accessorKey: 'duration',
    //   header: () => 'Duration',
    //   cell: (props) => {
    //     return <> {SecondsTohhmmss(props?.getValue())} </>;
    //   },
    //   meta: {
    //     textAlign: 'center',
    //     width: "3%"
    //   },
    // },
    {
      accessorKey: 'is_active',
      header: () => 'Status',
      cell: (props) => {
        return (
          <span className=" d--flex justify-content--center align-items--center">
            {props.getValue() === 1 ? (
              <div
                data-tooltip={'Active'}
                tooltip-position="top"
                className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
              >
                A
              </div>
            ) : (
              <div
                data-tooltip={'Deactive'}
                tooltip-position="top"
                className="bg--danger radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
              >
                D
              </div>
            )}
          </span>
        );
      },
      meta: {
        textAlign: 'center',
        width: '3%',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        return (
          <>
            <div className="gap--xs w--full d--flex align-items--center justify-content--center">
              <div
                className="d--flex gap--xs  c--pointer"
                onClick={() => {
                  setShowStatisics({
                    isShow: true,
                    elementData: props?.row?.original,
                  });
                }}
              >
                <EyeIcon width={21} height={21} />
              </div>
              <div
                className="d--flex gap--xs  c--pointer"
                onClick={() => {
                  setActivity(props?.row?.original);
                  // setShowActivity({
                  //   isShow: true,
                  //   elementData: props?.row?.original,
                  // });
                }}
              >
                <GraphIcon />
              </div>
            </div>
          </>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '3%',
      },
    },
  ];

  const filterInitialValues = {
    filter: [
      {
        key: 'account_number',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter agent ID',
        inputType: 'number',
        inputLabel: 'Agent ID',
      },
      {
        key: 'name',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Select',
        inputType: 'select',
        inputLabel: 'Agent Name',
        inputOptions: agentAllList.map((item) => ({
          value: item?.name,
          label: item?.name,
        })),
      },
      {
        key: 'extension',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter extension number',
        inputType: 'number',
        inputLabel: 'Extension',
      },
    ],
  };

  function handleClose() {
    setShowStatisics({ isShow: false, elementData: null });
  }

  function handleCloseActivity() {
    setShowActivity({ isShow: false, elementData: null });
  }

  return (
    <div className="overviewPage d--flex flex--column gap--sm w--full">
      <div className="w--full d--flex flex--column">
        <div className=" m-b--xs h-max--40 h-min--36  d--flex justify-content--between align-items--center text--primary">
          <div className="font--md font--400">Agents</div>
        </div>
        <div className="d--flex  gap--md w--full">
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--75 c--pointer ${
              agentFilter === 'total' ? 'bg--secondary' : 'bg--white'
            }`}
            onClick={() => setAgentFilter('total')}
          >
            <div
              className={` font--2xl font--600 ${
                agentFilter === 'total' ? 'text--white' : 'text--black'
              } `}
            >
              {total_agents}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center  ${
                agentFilter === 'total' ? 'text--white' : 'text--primary'
              } `}
            >
              Total Agents
            </div>
          </div>
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--75 c--pointer ${
              agentFilter === 1 ? 'bg--secondary' : 'bg--white'
            }`}
            onClick={() => setAgentFilter(1)}
          >
            <div
              className={` font--2xl font--600 ${
                agentFilter === 1 ? 'text--white' : 'text--black'
              } `}
            >
              {active_agents}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center  ${
                agentFilter === 1 ? 'text--white' : 'text--primary'
              } `}
            >
              Active Agents
            </div>
          </div>
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--75 c--pointer ${
              agentFilter === 0 ? 'bg--secondary' : 'bg--white'
            }`}
            onClick={() => setAgentFilter(0)}
          >
            <div
              className={` font--2xl font--600 ${
                agentFilter === 0 ? 'text--white' : 'text--black'
              } `}
            >
              {inactive_agents}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center  ${
                agentFilter === 0 ? 'text--white' : 'text--primary'
              } `}
            >
              Inactive Agents
            </div>
          </div>
        </div>
      </div>
      <div className="h--full w--full reportPageTable employeeAgentTable m-t--lg">
        <TableManager
          {...{
            fetcherKey: 'reportsAgentsList',
            fetcherFn: reportsAgentsList,
            columns,
            shouldFilter: true,
            initialFilterState: filterInitialValues,
            extraParams: {
              type: agentFilter,
            },
            setApiResponse: ({ data }) => {
              setAgentStats(data?.data?.agent_stats);
            },
            selectCBForTableData: (tblData) => tblData?.data?.data?.data,
          }}
        />
      </div>
      {showStatistics?.isShow && (
        <AsideModal
          handleClose={handleClose}
          title={`Statistics for ${showStatistics?.elementData?.first_name} ${showStatistics?.elementData?.last_name} (${showStatistics?.elementData?.extension})`}
          footerComponent={null}
        >
          <EmployeeAgentStatistics
            handleClose={handleClose}
            showStatistics={showStatistics}
          />
        </AsideModal>
      )}
      {/* {showActivity?.isShow && (
        <AsideModal
          handleClose={handleCloseActivity}
          title={`Activity for ${showActivity?.elementData?.first_name} ${showActivity?.elementData?.last_name} (${showActivity?.elementData?.extension})`}
          footerComponent={null}
          width="full"
          extraClasses={'w-max--full'}
        >
          <ActivityManagement
            handleClose={handleClose}
            showActivity={showActivity}
          />
        </AsideModal>
      )} */}
    </div>
  );
};

export default Agents;
