import React from 'react';

const FormInput = (
  {
    placeholder,
    label = '',
    error = '',
    extraClasses = '',
    paddingLeft = 'md',
    paddingRight = 'md',
    height = '36',
    border = true,
    ...rest
  },
  ref,
) => {
  const text = error || label || '';
  return (
    <div className="w--full  d--flex flex--column">
      {text && text?.trim() ? (
        <label
          className={`label--control font--sm font--500 m-b--xs d--flex w--full  ${
            error ? 'text--danger' : 'text--black'
          }`}
        >
          {text}
        </label>
      ) : null}
      <input
        {...rest}
        ref={ref}
        placeholder={placeholder}
        className={`form--control w--full h-min--${height}  radius--sm p-l--${paddingLeft} p-r--${paddingRight} ${extraClasses} ${
          error
            ? 'border-full--danger'
            : border
            ? 'border-full--black-200'
            : 'border-full--transparent'
        }`}
      />
    </div>
  );
};

export default React.forwardRef(FormInput);
