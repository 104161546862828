/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import React, { useContext, useEffect, useState } from 'react';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';
import Tabbing from '../../comopnents/Tabbing';
import moment from 'moment-timezone';
import { generateUnixTimestamp } from '../../helpers/functions';
import useIcons from '../../assets/icons/useIcons';
import { generateTimeArray } from '../Dashboard/helpers';
import TimeLine from './TimeLine';
import TimePicker from '../../comopnents/TimePicker';
import CustomDatePicker from '../../comopnents/CustomDatePicker';
import ActivityCollapse from './ActivityCollapse';
import Spinner from '../../comopnents/Spinner';

const defaultDate = moment().format('YYYY-MM-DD');

const ActivityManagement = ({ handleClose, showActivity }) => {
  const name = `${showActivity?.elementData?.first_name} ${showActivity?.elementData?.last_name} (${showActivity?.elementData?.extension})`;
  const { CrossRoundIcon, CaretIcon } = useIcons();
  const [shouldShowTimePicker, showTimePicker] = useState(false);
  const [shouldShowDatePicker, showDatePicker] = useState(false);
  const [activityLoading, setActivityLoading] = useState(false);
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(defaultDate);
  const [dataType, setDataType] = useState('');
  const timezone =
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? undefined;
  const { timeArray } = generateTimeArray();
  const [startTimeLine, setStartTimeLine] = useState(
    timeArray?.find((t) => t.label === '09:00 AM')?.value,
  );
  const [endTimeLine, setEndTimeLine] = useState(
    timeArray?.find((t) => t.label === '06:00 PM')?.value,
  );
  const { socket } = useContext(SipSocketContext);
  const [activityTime, setActivityTime] = useState('day');
  const [, forceUpdate] = useState({});
  const [allActivity, setAllActivity] = useState([]);
  const [miscellaneousData, setMiscellaneousData] = useState({
    call_duration: '00:00',
    online_percentage: 0,
  });

  useEffect(() => {
    if (socket?.sipCallSocket?.connected) {
      setActivityLoading(true);
      socket?.sipCallSocket.emit(
        'user-activity-list',
        {
          userId: showActivity?.elementData?.extension,
          // userId: '5661', for test
          domain: socket?._userCreds?.domain,
          interval: 'custom' || activityTime,
          start:
            timeArray?.find((t) => t.value === parseInt(startTimeLine))
              ?.label ?? '9:00 AM',
          end:
            timeArray?.find((t) => t.value === parseInt(endTimeLine))?.label ??
            '6:00 PM',
          timezone,
          startDate,
          endDate,
          activity: dataType,

          // ...timeIntervalUnix,
        },
        async (res) => {
          const resp = await res;
          setTimeout(() => {
            forceUpdate({});
          }, 500);
          setMiscellaneousData({
            call_duration: resp?.call_duration,
            online_percentage: resp?.online_percentage,
          });
          setActivityLoading(false);
          setAllActivity(resp?.data || []);
        },
      );
    }
  }, [
    socket?.sipCallSocket,
    socket?.sipCallSocket?.connected,
    // timeIntervalUnix,
    startTimeLine,
    endTimeLine,
    activityTime,
    startDate,
    endDate,
    dataType,
  ]);

  // useEffect(() => {
  //   if (allActivity && allActivity?.length > 0) {
  //     drawLineBetweenDivs();
  //   }
  //   window.addEventListener('resize', drawLineBetweenDivs);
  //   return () => {
  //     window.removeEventListener('resize', drawLineBetweenDivs);
  //   };
  // }, [allActivity?.length, force]);

  // function drawLineBetweenDivs() {
  //   const elements = document.querySelectorAll('.line_activity');
  //   if (elements) {
  //     for (let index = 0; index < elements.length; index++) {
  //       const element = elements[index];
  //       element.remove();
  //     }
  //   }
  //   for (let index = 0; index < allActivity.length; index++) {
  //     const element = allActivity[index];
  //     const isActivity = element?.activity?.length > 0;
  //     if (isActivity) {
  //       let div1id;
  //       let div2id;
  //       let currentId;
  //       for (let index = 0; index < element?.activity.length; index++) {
  //         const activityItem = element?.activity[index];
  //         if (activityItem?.activity === 'online') {
  //           div1id = `online_${activityItem?.timestamp}`;
  //         }
  //         if (activityItem?.activity === 'offline' && div1id) {
  //           div2id = `offline_${activityItem?.timestamp}`;
  //           drawLineHelper(div1id, div2id);
  //           div1id = null;
  //           div2id = null;
  //         }
  //       }
  //       if (div1id && !div2id) {
  //         const parentElement = document.getElementById(div1id).parentElement;
  //         if (parentElement) {
  //           const childElements = parentElement.children;
  //           for (let i = 0; i < childElements.length; i++) {
  //             if (childElements[i].id.includes('current')) {
  //               currentId = childElements[i].id;
  //             }
  //           }
  //         }
  //         if (currentId) {
  //           drawLineHelper(div1id, currentId);
  //         }
  //       }
  //     }
  //   }
  // }

  // function drawLineHelper(div1Id, div2Id) {
  //   const div1 = document.getElementById(div1Id);
  //   const div2 = document.getElementById(div2Id);
  //   if (div1 && div2) {
  //     const div1Parent = div1.parentElement.getBoundingClientRect();
  //     const rect1 = div1.getBoundingClientRect();
  //     const rect2 = div2.getBoundingClientRect();
  //     const line = document.createElement('div');
  //     line.className = `line_activity`;
  //     line.style.position = 'absolute';
  //     line.style.top = rect1.top + rect1.height / 2 - div1Parent.top - 1 + 'px';
  //     line.style.left = rect1.right - div1Parent.left + 'px';
  //     line.style.width = Math.abs(rect1.left - rect2.left) - 8 + 'px'; // Adjust line length as needed
  //     line.style.height = '2px';
  //     line.style.backgroundColor = '#19b067';
  //     div1.parentNode.appendChild(line);
  //   }
  // }

  return (
    <div className=" d--flex flex--column  w--full  h--full">
      <div className=" m-b--xs h-max--40 h-min--36  d--flex gap--xs justify-content--between align-items--center text--primary">
        <div className="font--md font--400 w-min--80  d-flex">
          {showActivity?.type}
        </div>

        <div className="w--full d--flex justify-content--end gap--xs">
          <Tabbing
            tabsLabels={[
              { label: 'Day', value: 'day' },
              { label: 'Week', value: 'week' },
              { label: 'Month', value: 'month' },
            ]}
            setTab={(val) => {
              setActivityTime(val);
              if (val === 'day') {
                setStartDate(moment().format('YYYY-MM-DD'));
                setEndDate(moment().format('YYYY-MM-DD'));
              }
              if (val === 'week') {
                setStartDate(moment().startOf('week').format('YYYY-MM-DD'));
                setEndDate(moment().format('YYYY-MM-DD'));
              }
              if (val === 'month') {
                setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
                setEndDate(moment().format('YYYY-MM-DD'));
              }
            }}
            tab={activityTime}
          />
          <Tabbing
            tabsLabels={[
              { label: 'All', value: '' },
              { label: 'Call', value: 'call' },
              { label: 'Session', value: 'session' },
            ]}
            setTab={(val) => {
              setDataType(val);
            }}
            tab={dataType}
          />
          <div className="position--relative">
            <div
              className="d--flex align-items--center  bg--white radius--full h-min--32 p-l--sm p-r--sm w-min--130"
              onClick={() => showTimePicker((p) => !p)}
            >
              {timeArray?.find((t) => t.value === parseInt(startTimeLine))
                ?.label ?? '9:00 AM'}
              -
              {timeArray?.find((t) => t.value === parseInt(endTimeLine))
                ?.label ?? '9:00 AM'}
            </div>
            {shouldShowTimePicker ? (
              <TimePicker
                setStartTimeLine={setStartTimeLine}
                setEndTimeLine={setEndTimeLine}
                showTimePicker={showTimePicker}
                startTimeLine={startTimeLine}
                endTimeLine={endTimeLine}
              />
            ) : null}
          </div>
          <div className="position--relative">
            <div
              className="d--flex align-items--center  bg--white radius--full h-min--32 p-l--sm p-r--sm w-min--150 white--space-none z-index--sm"
              onClick={() => showDatePicker((p) => !p)}
            >
              {startDate}- {endDate}
            </div>
            {shouldShowDatePicker ? (
              <CustomDatePicker
                showDatePicker={showDatePicker}
                setStartDateMain={setStartDate}
                setEndDateMain={setEndDate}
              />
            ) : null}
          </div>

          <div className="d--flex font--sm text--primary  align-items--center justify-content--center flex--column">
            <div className="font--xs font--500 white--space-none d--flex gap--xs">
              Online %:
              <span className="text--red">
                {miscellaneousData?.online_percentage}%
              </span>
            </div>
            {/* <div className="font--sm font--500">|</div> */}
            <div className="font--xs font--500 white--space-none d--flex gap--xs">
              Call Duration:
              <span className="text--red">
                {miscellaneousData?.call_duration}
              </span>
            </div>
          </div>
        </div>

        <div className="d--flex align-items--center gap--sm">
          {/* enable later */}
        </div>
      </div>

      <div className="w--full bg--white h--full gap--lg d--flex flex--column mainAgentCalender box-shadow--xs  p--md radius--sm">
        <div className="d--flex justify-content--between align-items--center border-bottom--primary-100 p-b--md">
          <div className="font--md font--400 w-min--250"> {name}</div>
          <button
            type="button"
            className="font--md font--600 text--primary d--flex c--pointer radius--full"
            onClick={() => handleClose()}
          >
            <CrossRoundIcon width={30} height={30} />
          </button>
        </div>
        <div className="w--full  d--flex flex--column  gap--sm  agentCalendar h--full overflow--auto align-items--center">
          {activityLoading ? (
            <Spinner />
          ) : allActivity && allActivity.length > 0 ? (
            allActivity.map((activity, index) => {
              return (
                <ActivityCollapse
                  allActivity={allActivity}
                  singleDateActivity={activity}
                  activityTime={activityTime}
                  index={index}
                />
              );
            })
          ) : null}

          {/* {old activity below : do not delete} */}

          {/* <div className="w--full d--flex flex--column w-max--200 w-min--200 h--full ">
            <TimeLine
              array={calculateTimelineHour(allActivity)}
              title="UTC +05:30"
              start={startTimeLine}
              end={endTimeLine}
              accessLabel="hour"
            />
          </div> */}
          {/* {allActivity && allActivity.length > 0
            ? allActivity.map((activity) => (
                <TimeLine
                  array={calculateTimelineHour(allActivity)}
                  start={startTimeLine}
                  end={endTimeLine}
                  allActivity={allActivity}
                  currentActivity={activity}
                  showActivity={true}
                />
              ))
            : null} */}
        </div>
      </div>
    </div>
  );
};

export default ActivityManagement;
