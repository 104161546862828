import React, { useEffect } from 'react';
import FormInput from '../../../comopnents/FormInput';
import Button from '../../../comopnents/Button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import MultiDropDown from '../../../comopnents/MultiDropdown';
import useAgentSignup from '../../../hooks/useAgentSignup';

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  country: 'US',
  phone: '',
  extension: '',
  did_number: [],
  tenant_npn: '',
};

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required('First name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers'),
  last_name: yup
    .string()
    .required('Last name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Last name cannot contain numbers'),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required')
    .max(50, 'Maximum length can be 50 characters'),
  extension: yup
    .string()
    .required('Extension is required')
    .test(
      'maxLength',
      'Extension must be of length 3 or 4.',
      (val) =>
        !isNaN(val) &&
        (`${val}`.length === 4 || `${val}`.length === 3 || val !== '0000'),
    )
    .test(
      'noDecimal',
      'Extension cannot contain decimals',
      (val) => val && !val.includes('.'),
    ),
  phone: yup.string().required('Phone number is required'),
  // did_number: yup.array().min(1, 'Select atleast one DID Number'),
  tenant_npn: yup
    .string()
    .max(12, 'NPN cannot exceed 12 digits')
    .matches('^[0-9]*$', 'NPN can only contain digits')
    .required('NPH number is required'),
});

export const RenderDIDItem = ({ item }) => {
  return (
    <option key={item.uuid} value={item?.did_number}>
      {item.did_number}
    </option>
  );
};
export default function AddAgentsForm({
  didList = {},
  editMemberInstance = {},
  handleClose = () => null,
}) {
  const {
    watch,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const { mutate: addAgentMutate, isLoading: addAgentLoad } =
    useAgentSignup(handleClose);

  useEffect(() => {
    const { isEdit = false, editData = {} } = editMemberInstance;
    if (
      isEdit &&
      editData &&
      Object.keys(editData) &&
      Object.keys(editData).length > 0
    ) {
      const {
        first_name = '',
        last_name = '',
        email,
        phone,
        country,
        extension,
        tenant_npn,
      } = editData;
      reset({
        first_name,
        last_name,
        email,
        country,
        phone,
        extension,
        tenant_npn,
      });
    }
  }, []);
  async function onSubmit(values) {
    const { isEdit = false, editData = {} } = editMemberInstance;

    const {
      first_name = '',
      last_name = '',
      email = '',
      phone = '',
      country = '',
      extension = '',
      did_number = [],
      tenant_npn = '',
    } = values;
    const payload = {
      first_name,
      last_name,
      country,
      phone,
      extension,
      did_number: [...did_number],
      email,
      tenant_npn,
    };
    if (isEdit) {
      payload.uuid = editData?.uuid;
    }
    addAgentMutate(payload);
  }

  const mutatedList =
    didList?.data?.map((d) => {
      if (d?.get_assigned_caller_id?.length > 0 || d?.status === 'Y') {
        return { ...d, disableMe: true };
      } else {
        return { ...d, disableMe: false };
      }
    }) ?? [];

  const loading = isSubmitting || addAgentLoad;
  return (
    <form
      className="w--full h--full"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
        <div>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="First Name"
                placeholder="Enter your first name"
                maxLength={30}
                error={errors?.first_name?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="Last Name"
                maxLength={30}
                placeholder="Enter your last name"
                error={errors?.last_name?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="Email"
                placeholder="Enter your email address"
                error={errors?.email?.message}
                disabled={editMemberInstance?.isEdit}
                extraClasses={
                  editMemberInstance?.isEdit ? 'c--not-allowed' : ''
                }
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="extension"
            control={control}
            render={({ field }) => {
              const { onChange } = field;
              return (
                <FormInput
                  {...field}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value < 0) return;
                    if (`${e.target.value}`?.length <= 4) {
                      onChange(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  onKeyDown={(e) => {
                    if (['e', '+', '-'].includes(e.key)) e.preventDefault();
                  }}
                  label="Extension"
                  placeholder="Enter your extension"
                  error={errors?.extension?.message}
                />
              );
            }}
          />
        </div>
        <div>
          <label
            className={`label--control font--sm font--500 m-b--xs ${
              errors?.phone?.message ? 'text--danger' : 'text--black'
            }`}
          >
            {errors?.phone?.message || 'Phone'}
          </label>

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                control={control}
                defaultCountry={watch('country')}
                international={true}
                addInternationalOption={false}
                withCountryCallingCode={true}
                countryCallingCodeEditable={false}
                placeholder="Enter your phone"
                internationalIcon={() => null}
                flagComponent={() => null}
                countrySelectComponent={() => null}
                value={getValues(`phone`)}
                limitMaxLength={true}
                onChange={(phone) => setValue(`phone`, phone)}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                  errors?.phone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                }`}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="tenant_npn"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="NPN"
                placeholder="Enter npn"
                maxLength="12"
                error={errors?.tenant_npn?.message}
              />
            )}
          />
        </div>
        {!editMemberInstance?.isEdit && (
          <div>
            <Controller
              name="did_number"
              control={control}
              render={({ field }) => (
                <MultiDropDown
                  {...field}
                  labelKey={'did_number'}
                  valueKey={'did_number'}
                  label="DID Number"
                  optionList={mutatedList || []}
                  placeholder={`${watch('did_number')?.length} number(s)`}
                />
              )}
            />
          </div>
        )}
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={loading}
        >
          {loading ? 'Please wait..' : 'Save'}
        </Button>
      </div>
    </form>
  );
}
