import React from 'react';
import ForwardingFilters from '../../../../comopnents/ForwardingFilters';

const CustomForm = ({
  register,
  watch,
  clearErrors,
  errors,
  memberListData,
  departmentsData,
  setValue,
  ivrListData,
  greetingList,
  control,
  memberListLoad
}) => {
  return (
    <div className="d--flex gap--lg w--full">
      <div className="w--full h--full w-max--700">
        <div className="w--full">
          <div className="label--control font--sm font--600 m-b--xs text--primary m-b--md">
            During business hours, forward calls to
          </div>
          <ForwardingFilters
            register={register}
            watch={watch}
            clearErrors={clearErrors}
            errors={errors?.forward_call_actions?.during_business_hour}
            memberListData={memberListData}
            type={'forward_call_actions.during_business_hour.type'}
            value={'forward_call_actions.during_business_hour.value'}
            failover_type={
              'forward_call_actions.during_business_hour.failover.type'
            }
            failover_value={
              'forward_call_actions.during_business_hour.failover.value'
            }
            timeout={'forward_call_actions.during_business_hour.timeout'}
            setValue={setValue}
            departmentsData={departmentsData}
            ivrListData={ivrListData}
            greetingList={greetingList}
            control={control}
            memberListLoad={memberListLoad}
          />
        </div>
      </div>
      <div className="h--full border-left--primary-200 p-b--md "></div>
      <div className="w--full h--full w-max--700 p-b--sm">
        <div className="w--full ">
          <div className="label--control font--sm font--600 m-b--xs text--primary m-b--md">
            During closed hours, forward calls to
          </div>
          <ForwardingFilters
            register={register}
            watch={watch}
            clearErrors={clearErrors}
            errors={errors?.forward_call_actions?.closed_business_hour}
            memberListData={memberListData}
            type={'forward_call_actions.closed_business_hour.type'}
            value={'forward_call_actions.closed_business_hour.value'}
            failover_type={
              'forward_call_actions.closed_business_hour.failover.type'
            }
            failover_value={
              'forward_call_actions.closed_business_hour.failover.value'
            }
            timeout={'forward_call_actions.closed_business_hour.timeout'}
            setValue={setValue}
            departmentsData={departmentsData}
            ivrListData={ivrListData}
            greetingList={greetingList}
            control={control}
            memberListLoad={memberListLoad}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomForm;
