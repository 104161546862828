import { forwardRef, useImperativeHandle, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useAlert } from '../../hooks/useAlert';

const elStyle = {
  base: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    color: '#495057',
  },
};

const PaymentForm = (
  {
    type = '',
    actionFn = () => null,
    resetFn = () => null,
    selectedDidData = {},
    isShowAlert = true,
  },
  ref,
) => {
  const { showAlert } = useAlert();
  const stripe = useStripe();
  const elements = useElements();
  const [isStripeError, setisStripeError] = useState({
    isError: false,
    message: '',
    type: null,
  });

  const handlePayment = (type, token, saveCard, rest) => {
    const city = selectedDidData?.region_information?.find(
      (item) => item?.region_type === 'location',
    )?.region_name;
    switch (type) {
      case 'new-card':
        actionFn({
          // sku_id: selectedDidData?.sku_id,
          // did_id: selectedDidData?.id,
          // amount: selectedDidData?.price?.[0]?.cost,
          // period: selectedDidData?.price?.[0]?.period,
          did_city: city,
          did_number: selectedDidData?.phone_number,
          request_type: type, //new-card Or saved-card
          card_token: token,
          is_saved_card: saveCard ? 'Y' : 'N',
          // "card_id": ""
        });
        break;
      case 'signup':
        actionFn({
          ...rest,
          payment_token: token,
        });
        break;
      case 'upgradeplan':
        actionFn({
          ...rest,
          card_token: token,
        });
        break;
      case 'add-card':
        actionFn({
          card_token: token,
        });
        break;
      case 'add-fund-newcard':
        actionFn({
          type: 'new-card',
          charge_amount: rest?.charge_amount,
          payment_token: token,
        });
        break;

      default:
        return null;
    }
  };

  const handleSubmitForm = async (paymentType, saveCard, rest) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(cardElement);
    if (!error) {
      handlePayment(paymentType, token?.id, saveCard, rest);
    } else {
      if (isShowAlert) {
        showAlert({ type: 'secondary', message: error?.message });
      }
    }
  };

  const handleChange = (e) => {
    if (e?.error?.message) {
      setisStripeError({ isError: true, message: e?.error?.message });
    } else {
      setisStripeError({ isError: false, message: '' });
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit({ paymentType = '', saveCard, ...rest }) {
        handleSubmitForm(paymentType, saveCard, rest);
      },
    }),
    [stripe, elements],
  );

  return (
    <div className="d--flex gap--lg flex--column">
      {isStripeError?.message ? (
        <div className="text--danger">{isStripeError?.message ?? ''}</div>
      ) : null}

      <div className="w--full">
        <label className="label--control font--sm font--500 m-b--xs d--flex w--full">
          Card Number
        </label>
        <div className="form--control w--full h-min--36 radius--sm p-t--md p-l--md p-r--md  border-full--black-200">
          <CardNumberElement
            onChange={(e) => handleChange(e)}
            className="form--control"
            options={{
              style: elStyle,
              showIcon: true,
              placeholder: '**** **** **** ****',
            }}
          />
        </div>
      </div>
      <div className="d--flex gap--lg ">
        <div className="w--full">
          <label className="label--control font--sm font--500 m-b--xs d--flex w--full">
            Expiry Date
          </label>
          <div className="form--control w--full h-min--36 radius--sm p-t--md p-l--md p-r--md  border-full--black-200">
            <CardExpiryElement
              onChange={(e) => handleChange(e)}
              options={{
                style: elStyle,
              }}
            />
          </div>
        </div>
        <div className="w--full">
          <label className="label--control font--sm font--500 m-b--xs d--flex w--full">
            Security Code
          </label>
          <div className="form--control w--full h-min--36 radius--sm p-t--md p-l--md p-r--md border-full--black-200">
            <CardCvcElement
              onChange={(e) => handleChange(e)}
              options={{
                style: elStyle,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(PaymentForm);
