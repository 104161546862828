import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SingleDropdownMain from '../../../../comopnents/SingleDropdownMain';

const RenderItem = ({ item, onChange, setShow, setSearchValue }) => {
  return (
    <div
      className="text--black h-min--32 d--flex align-items--center justify-content--start p--sm w--full c--pointer"
      key={item?.deviceId}
      onClick={() => {
        onChange(item?.deviceId);
        setShow(false);
        setSearchValue('');
      }}
    >
      {item?.label}
    </div>
  );
};

const AVSettings = () => {
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [audioInputError, setAudioInputError] = useState('');
  const [audioOutputError, setAudioOutputError] = useState('');
  const [isTestingMic, setIsTestingMic] = useState(false);
  const { control, watch } = useForm({
    defaultValues: {
      input_device: '',
      output_device: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audioInputs = devices.filter(
        (device) => device.kind === 'audioinput',
      );
      const audioOutputs = devices.filter(
        (device) => device.kind === 'audiooutput',
      );
      setAudioOutputDevices(audioOutputs);
      setAudioInputDevices(audioInputs);
    });
  }, []);

  async function startMicTest() {
    const deviceId = watch('input_device');
    if (!deviceId) {
      setAudioInputError('Please select audio input device');
      return;
    }
    try {
      setAudioInputError('');
      if (window.micTestStream) {
        window.micTestStream.getAudioTracks().forEach((track) => {
          track.stop();
        });
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: deviceId ? { exact: deviceId } : undefined,
        },
      });
      window.micTestStream = stream;
      const audioElement = document.getElementById('test-mic-ref');
      audioElement.srcObject = stream;
      audioElement.play();
      setIsTestingMic(true);
      stream.getAudioTracks()[0].onended = () => {
        setIsTestingMic(false);
      };
    } catch (error) {
      setAudioInputError(error);
    }
  }
  async function stopMicTest() {
    try {
      if (window.micTestStream) {
        window.micTestStream.getAudioTracks().forEach((track) => {
          track.stop();
        });
      }
      window.micTestStream = null;
      setIsTestingMic(false);
      const audioElement = document.getElementById('test-mic-ref');
      audioElement.srcObject = null;
      audioElement.play();
    } catch (error) {
      // Handle any errors
      console.log(error);
    }
  }

  async function playTestSound() {
    const audioElement = document.getElementById('test-speaker-ref');
    const selectedDeviceId = watch('output_device');
    if (!selectedDeviceId) {
      setAudioOutputError('Please select audio output device');
      return;
    }
    try {
      setAudioOutputError('');
      await audioElement.setSinkId(selectedDeviceId);
      audioElement.play();
    } catch (error) {
      setAudioOutputError(error);
    }
  }

  return (
    <>
      <div className="w--full d--flex gap--md flex--column">
        <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
          Audio I/O Settings
        </div>
      </div>
      <form
        className="w--full h--full d--flex flex--column gap--md"
        autoComplete="off"
      >
        <audio id="test-mic-ref" hidden />
        <audio id="test-speaker-ref" src="/beep.mp3" hidden />
        <div className="d--flex w--full align-items--end gap--md">
          <div className="w--full mb-3 d--flex align-items--center flex--column">
            <div className="w--full  d--flex gap--lg justify-content--end">
              <Controller
                name={`input_device`}
                control={control}
                render={({ field }) => {
                  return (
                    <SingleDropdownMain
                      {...field}
                      labelKey={'label'}
                      valueKey="deviceId"
                      optionList={audioInputDevices}
                      component={RenderItem}
                      error={audioInputError}
                      label="Audio Input Devices"
                      placeholder="Select audio input device"
                    />
                  );
                }}
              />
            </div>
          </div>
          <button
            type="button"
            className="btn font--sm d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary w-max--120 c--pointer"
            onClick={() => {
              if (isTestingMic) {
                stopMicTest();
              } else {
                startMicTest();
              }
            }}
          >
            {isTestingMic ? 'Stop Test' : 'Test Mic'}
          </button>
        </div>

        <div className="d--flex align-items--end gap--md">
          <div className="w--full mb-3 d--flex align-items--center flex--column">
            <div className="w--full  d--flex gap--lg justify-content--end">
              <Controller
                name={`output_device`}
                control={control}
                render={({ field }) => {
                  return (
                    <SingleDropdownMain
                      {...field}
                      labelKey={'label'}
                      valueKey="deviceId"
                      optionList={audioOutputDevices}
                      component={RenderItem}
                      error={audioOutputError}
                      label="Audio Output Devices"
                      placeholder="Select audio output device"
                    />
                  );
                }}
              />
            </div>
          </div>
          <button
            type="button"
            className="btn font--sm d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary w-max--120 c--pointer"
            onClick={() => {
              playTestSound();
            }}
          >
            Test Speaker
          </button>
        </div>
      </form>
    </>
  );
};

export default AVSettings;
