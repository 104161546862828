import React, { useContext, useEffect, useState } from 'react';
import TableManager from '../../../comopnents/TableManager';
import useIcons from '../../../assets/icons/useIcons';
import Button from '../../../comopnents/Button';
import AsideModal from '../../../comopnents/AsideModal';
import AddMemberForm from './../AddMemberForm';
import { getMemberListing } from '../../../api';
import useDidList from '../../../hooks/useDidList';
import Dropdown from '../../../comopnents/Dropdown';
import useMemberDelete from '../../../hooks/useMemberDelete';
import { useAuth } from '../../../hooks/useAuth';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import { useQueryClient } from '@tanstack/react-query';
import {
  AGENCY_OWNER,
  USER,
  converDateTimeFormateApis,
  formatPhoneNumber,
  memberAgentslabelsArray,
} from '../../../helpers/functions';
import Avatar from '../../../comopnents/Avatar';
import ConfirmComponent from '../../../comopnents/ConfirmComponent';
import MultiDropDown from '../../../comopnents/MultiDropdown';
import useAccountVerify from '../../../hooks/useAccountVerify';
import useMemberActivate from '../../../hooks/useMemberActivate';
import useMemberDeactivate from '../../../hooks/useMemberDeactivate';
import Modal from '../../../comopnents/Modal';
import AssignDIDModal from '../AssignDIDModal';
import UnAssignDIDModal from '../UnAssignDIDModal';
import PortalAccessModal from '../PortalAccessModal';
import usePortalAccess from '../../../hooks/useSetPortalAccess';

const initialEditInstance = { isEdit: false, editData: {} };

const portalArray = [
  { id: 1, label: 'Browser', value: 'web' },
  { id: 2, label: 'Desktop', value: 'desktop' },
  { id: 3, label: 'Mobile', value: 'mobile' },
];

const AccessOption = ({ initialValues = [], employeeData }) => {
  const [access, setAccess] = useState([]);
  const { mutate: setPortalAccessMutate, isLoading: setPortalAccessLoad } =
    usePortalAccess();

  useEffect(() => {
    if (initialValues && initialValues.length > 0) {
      setAccess(initialValues);
    }
  }, [initialValues]);

  const getPlaceHolder = () => {
    let placeholder = [];
    for (let index = 0; index < access.length; index++) {
      const element = access[index];
      const label =
        portalArray.find((_) => _.value === element).label.split(' ')?.[0] ||
        '';
      placeholder.push(label);
    }
    return placeholder.join(', ');
  };
  function handleSubmit() {
    setPortalAccessMutate({
      uuid: employeeData?.uuid,
      portal_access: access,
    });
  }
  const isSame = (access, initialValues) => {
    let isSame = true;
    let array1 = initialValues;
    let array2 = access;
    if (access.length > initialValues.length) {
      array1 = access;
      array2 = initialValues;
    }
    for (let index = 0; index < array1.length; index++) {
      const element = array1[index];
      if (!array2.includes(element)) {
        isSame = false;
      }
    }
    return isSame;
  };

  return (
    <span className="d--flex align-items--center gap--xs">
      <MultiDropDown
        labelKey={'label'}
        valueKey={'value'}
        value={access ?? []}
        placeholder={setPortalAccessLoad ? 'Please wait...' : getPlaceHolder()}
        readOnly={false}
        optionList={portalArray || []}
        withCaret={true}
        height="28"
        border={false}
        paddingRight="xs"
        paddingLeft="xs"
        extraClasses="no--focus link"
        isSearchable={false}
        disabled={setPortalAccessLoad}
        onChange={(e) => {
          setAccess(e);
        }}
      />
      {!isSame(access, initialValues) && (
        <Button
          type="button"
          variant="primary"
          color="white"
          btnClasses="btn btn--xs w-max--40 font--xs radius--xl"
          onClick={handleSubmit}
        >
          Apply
        </Button>
      )}
    </span>
  );
};

const Employees = ({ tab, setTab }) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, data } = useDidList();
  const { user } = useAuth();
  const { socket = {} } = useContext(SipSocketContext);
  const { _makeCall = () => null } = socket;
  const [addMember, setAddMember] = useState(false);
  const [editMemberInstance, setEditMemberInstance] =
    useState(initialEditInstance);
  const [assignDIDInstance, setAssignDIDInstance] = useState(null);
  const [unAssignDIDInstance, setUnAssignDIDInstance] = useState(null);
  const [showAssignDID, setShowAssignDID] = useState(false);
  const [showPortalAccess, setShowPortalAccess] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);
  const [showUnAssignDID, setShowUnAssignDID] = useState(false);

  const ACCESS = [user?.role_id]?.find((role) =>
    [USER, AGENCY_OWNER]?.includes(role),
  );
  const handleMakeCall = (phoneNumber) => {
    if (phoneNumber) {
      _makeCall(phoneNumber, phoneNumber?.replaceAll(' ', ''));
    }
  };

  const handleSuccess = () =>
    queryClient.invalidateQueries({ queryKey: 'getMemberListing' });

  const { mutate: memberDeleteMutate } = useMemberDelete();
  const { mutate: memberAccountVerify } = useAccountVerify({ handleSuccess });
  const { mutate: memberActivate } = useMemberActivate({ handleSuccess });
  const { mutate: memberDeactivate } = useMemberDeactivate({ handleSuccess });

  const { MoreVIcon, CallIcon } = useIcons();

  useEffect(() => {
    mutate({ page: 1 });
  }, []);

  const handleDropListClick = ({ value }, data) => {
    switch (value) {
      case 'edit':
        setEditMemberInstance({ isEdit: true, editData: data });
        setAddMember(true);
        break;
      case 'assign_did':
        setAssignDIDInstance(data);
        setShowAssignDID(true);
        break;
      case 'portal_access':
        setEmployeeData(data);
        setShowPortalAccess(true);
        break;
      case 'unassign_did':
        setUnAssignDIDInstance(data);
        setShowUnAssignDID(true);
        break;
      case 'activate':
        memberActivate({ uuid: data?.uuid });
        break;
      case 'deactivate':
        memberDeactivate({ uuid: data?.uuid });
        break;
      default:
        break;
    }
  };

  const handlMemberDelete = (data) => {
    const { uuid = '' } = data;
    memberDeleteMutate({ uuid });
  };
  const handleVerifyAccount = (data) => {
    const { uuid = '' } = data;
    memberAccountVerify({ uuid });
  };

  const columns = [
    {
      accessorKey: 'first_name',
      header: () => 'Name',
      cell: (props) => {
        const element = props?.row?.original;
        const iAmAdmin = element?.uuid === user?.uuid;
        return (
          <span className="d--flex gap--sm  align-items--center">
            <Avatar
              size="28"
              first_name={element?.first_name}
              last_name={element?.last_name}
              image={element.profile_pic}
              extension={element?.extension}
            />
            <span className="ellipsis--15">
              {element?.first_name} {element?.last_name}
            </span>
            {iAmAdmin ? (
              <span className="text--danger font--600"> (You)</span>
            ) : (
              ''
            )}
          </span>
        );
      },
      meta: {
        width: '12%',
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      cell: (props) => {
        return formatPhoneNumber(props?.getValue());
      },
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },
    {
      accessorKey: 'extension',
      header: () => 'Extension',
      meta: {
        textAlign: 'center',
        width: '5%',
      },
    },
    {
      accessorKey: 'is_active',
      header: () => 'Status',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <div className="d--flex justify-content--center">
            {element?.verified === 0 && props?.getValue() === 0 ? (
              <div
                data-tooltip={'Invited'}
                tooltip-position="top"
                className="bg--info radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
              >
                I
              </div>
            ) : element?.verified === 1 && props?.getValue() === 1 ? (
              <div
                data-tooltip={'Active'}
                tooltip-position="top"
                className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
              >
                A
              </div>
            ) : props?.getValue() === 0 ? (
              <div
                data-tooltip={'Deactive'}
                tooltip-position="top"
                className="bg--danger radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
              >
                D
              </div>
            ) : (
              <>---</>
            )}
          </div>
        );
      },
      meta: {
        textAlign: 'center',
        width: '5%',
      },
    },
    {
      accessorKey: 'assigned_did_array',
      header: () => 'Virtual Number',
      cell: (props) => {
        const element = props?.row?.original || {};
        const {
          assigned_did_array = [],
          assigned_did = [],
          role_id,
          did = [],
        } = element;
        if (user?.role_id === role_id) {
          return (
            <div className="d--flex align-items--center justify-content--center">
              {did?.length === 0 ? (
                '---'
              ) : did?.length === 1 ? (
                formatPhoneNumber(did?.[0]?.did_number) || '---'
              ) : (
                <>
                  <div className="w-max--130 w-min--130">
                    <MultiDropDown
                      labelKey={'did_number'}
                      valueKey={'did_number'}
                      value={[]}
                      placeholder={`${did?.length} number(s)`}
                      readOnly={true}
                      optionList={did || []}
                      withCaret={true}
                      border={false}
                      height="28"
                      paddingRight="xs"
                      paddingLeft="xs"
                      extraClasses="no--focus link text--r"
                      isSearchable={false}
                    />
                  </div>
                </>
              )}
            </div>
          );
        } else {
          return (
            <div className="d--flex align-items--center justify-content--center">
              {assigned_did_array?.length === 0 ? (
                '---'
              ) : assigned_did_array?.length === 1 ? (
                formatPhoneNumber(assigned_did_array[0])
              ) : (
                <div className="w-max--130 w-min--130">
                  <MultiDropDown
                    labelKey={'caller_id'}
                    valueKey={'caller_id'}
                    value={[]}
                    placeholder={`${assigned_did_array?.length} number(s)`}
                    readOnly={true}
                    optionList={assigned_did || []}
                    withCaret={true}
                    border={false}
                    height="28"
                    paddingRight="xs"
                    paddingLeft="xs"
                    extraClasses="no--focus link text--r"
                    isSearchable={false}
                  />
                </div>
              )}
            </div>
          );
        }
      },
      meta: {
        width: '10%',
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'portal_access',
      header: () => 'Portal Access',
      cell: (props) => {
        return (
          <>
            {![USER, AGENCY_OWNER].includes(props?.row?.original?.role_id) ? (
              <AccessOption
                initialValues={props?.getValue()}
                employeeData={props?.row?.original}
              />
            ) : (
              '---'
            )}
          </>
        );
      },
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },
    {
      accessorKey: 'last_login_at',
      header: () => 'Last Login',
      cell: ({ row }) => {
        return converDateTimeFormateApis(row?.original?.last_login_at);
      },
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const iAmAdmin = element?.uuid === user?.uuid;
        const dropListOptions = [
          { title: 'Edit', value: 'edit' },
          {
            title: 'Set Portal Access',
            value: 'portal_access',
            // access: !(element?.verified === 0 && element.is_active === 0),
          },
          {
            title: 'Assign DID',
            value: 'assign_did',
            access: !(element?.verified === 0 && element.is_active === 0),
          },
          {
            title: 'Unassign DID',
            value: 'unassign_did',
            access: !(element?.verified === 0 && element.is_active === 0),
          },
          {
            title: 'Resend Invitation',
            value: 'resend-invitation',
            shouldConfirm: true,
            access: element.verified === 0 && element.is_active === 0,
          },
          {
            title: 'Deactivate',
            value: 'deactivate',
            access: element.verified === 1 && element.is_active === 1,
          },
          {
            title: 'Activate',
            value: 'activate',
            access: element.verified === 1 && element.is_active === 0,
          },
          {
            title: 'Delete',
            value: 'delete',
            shouldConfirm: true,
          },
        ];
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--end">
            {!iAmAdmin && (
              <div className="d--flex gap--xs">
                {element?.verified === 1 && element?.is_active === 1 ? (
                  <div
                    className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--success-200 text--success c--pointer"
                    onClick={() => handleMakeCall(`${element?.extension}`)}
                  >
                    <CallIcon width={15} height={15} />
                  </div>
                ) : null}

                {ACCESS && (
                  <Dropdown
                    extraClasses="w-min--150"
                    closeOnClickOutside={false}
                    dropList={{
                      component: ({ item }) => {
                        if (item?.shouldConfirm) {
                          return (
                            <ConfirmComponent
                              item={item}
                              element={element}
                              label="Please confirm?"
                              confirmCallback={(data) => {
                                if (item?.value === 'delete')
                                  handlMemberDelete(data);
                                else if (item?.value === 'resend-invitation')
                                  handleVerifyAccount(data);
                              }}
                            >
                              {item?.title}
                            </ConfirmComponent>
                          );
                        }
                        return (
                          <span
                            className="w--full d--flex justify-content--start p--2sm"
                            onClick={() => handleDropListClick(item, element)}
                          >
                            {item?.title}
                          </span>
                        );
                      },
                      data: dropListOptions,
                    }}
                    showcaret={false}
                  >
                    <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                      <MoreVIcon width={14} height={14} />
                    </div>
                  </Dropdown>
                )}
              </div>
            )}
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'right',
        width: '5%',
      },
    },
  ];

  const tableActions = [
    {
      id: 1,
      label: 'Add Employee',
      access: ACCESS,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddMember(true)}
          size="sm"
        >
          Add Employee
        </Button>
      ),
    },
  ];

  const handleClose = () => {
    setEditMemberInstance({ isEdit: false, editData: {} });
    setAddMember(false);
    setAssignDIDInstance(null);
    setUnAssignDIDInstance(null);
    setShowUnAssignDID(false);
    setShowAssignDID(false);
    setEmployeeData(null);
    setShowPortalAccess(false);
    mutate({ page: 1 });
  };
  return (
    <>
      <TableManager
        {...{
          fetcherKey: 'getMemberListing',
          fetcherFn: getMemberListing,
          columns: columns,
          tableActions,
          name: 'Employees',
          extraParams: {
            get_owner: 'Y',
          },
          shouldShowTotalCount: true,
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          withTabs: ![AGENCY_OWNER].includes(user?.role_id) ? true : false,
          tab,
          setTab,
          tabsLabels: memberAgentslabelsArray,
        }}
      />
      {addMember && (
        <AsideModal
          handleClose={handleClose}
          title={
            editMemberInstance?.isEdit ? 'Update Employee' : 'Add Employee'
          }
          footerComponent={null}
        >
          <AddMemberForm
            didListLoading={isLoading}
            didList={data?.data || {}}
            editMemberInstance={editMemberInstance}
            handleClose={handleClose}
          />
        </AsideModal>
      )}
      {showAssignDID && (
        <Modal
          handleClose={handleClose}
          title={'Assign DID'}
          footerComponent={null}
          height="300"
          size="md"
        >
          <AssignDIDModal
            assignDIDInstance={assignDIDInstance}
            handleClose={handleClose}
          />
        </Modal>
      )}
      {showPortalAccess && (
        <Modal
          handleClose={handleClose}
          title={'Set Portal Access'}
          footerComponent={null}
          height="300"
          size="md"
        >
          <PortalAccessModal
            employeeData={employeeData}
            handleClose={handleClose}
          />
        </Modal>
      )}
      {showUnAssignDID && (
        <Modal
          handleClose={handleClose}
          title={'Unassign DID'}
          footerComponent={null}
          height="300"
          size="md"
        >
          <UnAssignDIDModal
            unAssignDIDInstance={unAssignDIDInstance}
            handleClose={handleClose}
          />
        </Modal>
      )}
    </>
  );
};

export default Employees;

const filterInitialValues = {
  filter: [
    {
      key: 'first_name',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter first name',
      inputType: 'text',
      inputLabel: 'First Name',
    },
    {
      key: 'last_name',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter last name',
      inputType: 'text',
      inputLabel: 'Last Name',
    },
    {
      key: 'email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter email',
      inputType: 'email',
      inputLabel: 'Email',
    },
    {
      key: 'phone',
      value: '',
      isChecked: false,
      inputPlaceholder: 'phone number',
      inputType: 'phone-number',
      inputLabel: 'Phone Number',
    },
  ],
};
