import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setForwarding } from '../../api';
import { useAlert } from '../useAlert';
import { useNavigate } from 'react-router-dom';

export default function useSetForwarding() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const setForwardingRequest = useMutation({
    mutationFn: setForwarding,
    mutationKey: 'setForwarding',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['didList'], exact: false });
      navigate('/virtual-numbers');
      showAlert({
        type: 'success',
        message: data?.message ?? 'Forwarding updated successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...setForwardingRequest };
}
