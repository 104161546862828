import React, { useEffect, useRef, useState } from 'react';
import TableManager from '../../../comopnents/TableManager';
import { reportsSMSList } from '../../../api';
import {
  converDateTimeFormateApis,
  formatPhoneNumber,
} from '../../../helpers/functions';
import useAllNumberDetails from '../../../hooks/useAllNumberDetails';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

const SMS = () => {
  const tableRef = useRef(null);
  const [searchParams] = useSearchParams();
  const { getNumberName } = useAllNumberDetails();
  const [smsStatus, setSmsStatus] = useState({});
  const { inbound_sms = 0, outbound_sms = 0, total_sms = 0 } = smsStatus || {};
  const [smsFilter, setSMSFilter] = useState(
    searchParams.get('smsType') ?? 'total',
  );
  const [isEnabled, setIsEnabled] = useState(
    searchParams.get('smsType') ? false : true,
  );

  const columns = [
    {
      accessorKey: 'creation_date',
      header: () => 'Date/Time',
      cell: (props) => converDateTimeFormateApis(props.getValue()),
    },
    {
      accessorKey: 'from',
      header: () => 'From',
      cell: (props) => {
        return formatPhoneNumber(props?.getValue());
      },
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'via_did',
      header: () => 'Via Virtual Number',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <>
            <div className="d--flex flex--column justify-content--start align-items--center">
              {element?.did_name && <div>{element?.did_name}</div>}
              <div>{formatPhoneNumber(props?.getValue())}&nbsp;</div>
            </div>
          </>
        );
      },
      meta: {
        width: '12%',
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'to',
      header: () => 'To',
      cell: (props) => {
        return (
          <span className="d--flex flex--column align-items--center justify-content--start gap--sm">
            <div>{getNumberName(props?.getValue())}</div>
            <div className="font--xs">
              {getNumberName(props?.getValue())?.includes('+')
                ? ''
                : formatPhoneNumber(props?.getValue())}
            </div>
          </span>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'direction',
      header: () => 'Direction',
      cell: (props) => {
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className={
              props?.getValue() === 'Sent' ? 'text--success' : 'text--info'
            }
          >
            {props?.getValue()}
          </span>
        );
      },
      meta: {
        width: '5%',
      },
    },
  ];

  const filterInitialValues = {
    filter: [
      {
        key: 'from',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter From',
        inputType: 'text',
        inputLabel: 'From',
      },
      {
        key: 'to',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter To',
        inputType: 'text',
        inputLabel: 'To',
      },
      // {
      //   key: 'date',
      //   inputLabel: 'By Date',
      //   isChecked: false,
      //   value:
      //     searchParams.get('filter') === 'weekly'
      //       ? weeklyFilterObject
      //       : {
      //           form: '',
      //           to: '',
      //         },
      //   inputType: 'date',
      //   dateType: searchParams.get('filter') === 'weekly' ? 'This Week' : '',
      // },
    ],
  };
  useEffect(() => {
    if (searchParams.has('smsType')) {
      tableRef.current.overrideFilters(filterInitialValues?.filter);
      setIsEnabled(true);
    }
  }, [filterInitialValues?.filter, searchParams]);

  return (
    <div className="overviewPage d--flex flex--column gap--sm w--full">
      <div className="w--full d--flex flex--column">
        <div className=" m-b--xs h-max--40 h-min--36  d--flex justify-content--between align-items--center text--primary">
          <div className="font--md font--400">SMS</div>
        </div>
        <div className="d--flex  gap--md w--full ">
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--75 c--pointer ${smsFilter === 'total' ? 'bg--secondary' : 'bg--white'
              } `}
            onClick={() => {
              setSMSFilter('total');
            }}
          >
            <div
              className={` font--2xl font--600 ${smsFilter === 'total' ? 'text--white' : 'text--black'
                } `}
            >
              {total_sms}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center  ${smsFilter === 'total' ? 'text--white' : 'text--primary'
                } `}
            >
              Total SMS
            </div>
          </div>
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--75 c--pointer ${smsFilter === 'inbound' ? 'bg--secondary' : 'bg--white'
              } `}
            onClick={() => {
              setSMSFilter('inbound');
            }}
          >
            <div
              className={` font--2xl font--600 ${smsFilter === 'inbound' ? 'text--white' : 'text--black'
                } `}
            >
              {inbound_sms}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center  ${smsFilter === 'inbound' ? 'text--white' : 'text--primary'
                } `}
            >
              Received SMS
            </div>
          </div>
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--75 c--pointer ${smsFilter === 'outbound' ? 'bg--secondary' : 'bg--white'
              } `}
            onClick={() => {
              setSMSFilter('outbound');
            }}
          >
            <div
              className={` font--2xl font--600 ${smsFilter === 'outbound' ? 'text--white' : 'text--black'
                } `}
            >
              {outbound_sms}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center  ${smsFilter === 'outbound' ? 'text--white' : 'text--primary'
                } `}
            >
              Sent SMS
            </div>
          </div>
        </div>
      </div>
      <div className="h--full w--full reportPageTable m-t--lg">
        <TableManager
          {...{
            fetcherKey: 'reportsSMSList',
            fetcherFn: reportsSMSList,
            columns,
            shouldFilter: true,
            initialFilterState: filterInitialValues,
            extraParams: {
              type: smsFilter,
            },
            setApiResponse: ({ data }) => {
              setSmsStatus(data?.data?.sms_stats);
            },
            selectCBForTableData: (tblData) => tblData?.data?.data?.data,
            isEnabled,
            ref: tableRef,
            customFilters: true,
          }}
        />
      </div>
    </div>
  );
};

export default SMS;

const weeklyFilterObject = {
  from: moment().startOf('week').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
};
