import { useQuery } from '@tanstack/react-query';
import { getContactListing } from '../../api';
import { useEffect, useState } from 'react';

export default function useContactListing() {
  const [dropdownContactList, setDropdownContactList] = useState([]);

  const { data: contactList = [], ...rest } = useQuery({
    queryFn: () => getContactListing(),
    queryKey: ['getContactListing'],
    refetchOnWindowFocus: false,
    retry: false,
    gcTime: Infinity,
    refetchOnMount: false,
    select: (data) => data?.data?.data ?? [],
  });

  useEffect(() => {
    if (contactList?.length > 0) {
     const list =  contactList?.map((item) => {
        return {
          name: `${item?.first_name} ${item?.last_name}`,
          value: item?.phone,
        };
      });
      setDropdownContactList(list)
    }
  }, [contactList]);

  return { ...rest, contactList, dropdownContactList };
}
