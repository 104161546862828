import { forwardRef, useRef } from 'react';

import Button from '../../../../comopnents/Button';
import PaymentForm from '../../../PaymentForm';
import useAddCard from '../../../../hooks/useAddCard';

const AddCardForm = ({ handleClose = () => null }) => {
  const confirmButtonRef = useRef(null);

  const { mutate: addCardMutate, isLoading: addCardLoad } = useAddCard({
    handleSuccess,
  });
  function handleSuccess(data) {
    handleClose();
  }

  return (
    <div className="w--full bg--white radius--sm d--flex flex--column justify-content--between h--full">
      <div className='w--full h--full h-min--250'>
        <PaymentForm
          actionFn={addCardMutate}
          ref={confirmButtonRef}
          isShowAlert={false}
        />
      </div>
      <div className="d--flex justify-content--between gap--md p-t--lg border-top--primary-100 m-t--md">
        <Button
          type="button"
          variant="primary"
          color="white"
          size="md"
          btnClasses="btn w-min--150"
          onClick={() =>
            confirmButtonRef?.current?.handleSubmit({
              paymentType: 'add-card',
            })
          }
          disabled={addCardLoad}
        >
          {addCardLoad ? 'Please wait..' : 'Add Card'}
        </Button>
      </div>
    </div>
  );
};

export default forwardRef(AddCardForm);
