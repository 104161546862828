import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Avatar from '../../../comopnents/Avatar';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import AddContactForm from '../AddContactForm';
import { converDateTimeFormateApis } from '../../../helpers/functions';
import useEmployeeAgentList from '../../../hooks/useEmployeeAgentList';

const ContactDetails = () => {
  const [contactData, setContactData] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const uuid = searchParams?.get('contactId') ?? '';
  const numberToSave = searchParams?.get('numberToSave') ?? '';
  const { socket = {} } = useContext(SipSocketContext);
  const { data: employeeAgentList = [], isLoading: memberListLoad } =
    useEmployeeAgentList({ get_owner: 'Y' });

  useEffect(() => {
    if (uuid && socket?.sipCallSocket) {
      handleGetContactName(uuid);
    }
  }, [uuid, socket?.sipCallSocket]);
  useEffect(() => {
    if (numberToSave) {
      setContactData({ phone: numberToSave });
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('numberToSave'); // Remove the 'age' query parameter
      setSearchParams(newParams);
    }
  }, [numberToSave]);

  function handleGetContactName(uuid) {
    if (!uuid) return;
    socket?.sipCallSocket?.emit(
      'contact-detail',
      {
        contact_uuid: uuid,
      },
      async (res) => {
        const response = await res;
        if (response?.status === 200) {
          setContactData(response?.data);
          // sessionStorage.setItem('cid', response?.data?.id);
        } else {
          setContactData(null);
          // if (sessionStorage.getItem('cid')) {
          //   sessionStorage.removeItem('cid');
          // }
        }
      },
    );
  }

  const creator = useMemo(
    () =>
      memberListLoad
        ? null
        : employeeAgentList && employeeAgentList?.length > 0
        ? employeeAgentList.find((a) => a.uuid === contactData?.created_by)
        : null,
    [contactData?.created_by, memberListLoad],
  );

  const updator = useMemo(
    () =>
      memberListLoad
        ? null
        : employeeAgentList && employeeAgentList?.length > 0
        ? employeeAgentList.find((a) => a.uuid === contactData?.updated_by)
        : null,
    [contactData?.updated_by, memberListLoad],
  );

  return (
    <div className="w-max--500 w-min--500 bg--white radius--sm w--full position--relative">
      <div className="w--full d--flex flex--column ">
        <div className="d--flex flex--column gap--lg">
          <div className="h-min--150 w--full d--flex align-items--center justify-content--center">
            <div className="w--full position--absolute right--0 top--20 z-index--sm w-max--370 d--flex flex--column gap--sm">
              <div className="w--full font--sm font--500 d--flex align-items--center gap--md">
                <span className="d--flex w-min--100 label--control font--sm font--500 text--black">
                  Created at
                </span>
                <span className="font--400 d--flex text--black-900">
                  : {converDateTimeFormateApis(contactData?.created_at)}
                </span>
              </div>
              <div className="w--full font--sm font--500 d--flex align-items--center gap--md">
                <span className="d--flex w-min--100 label--control font--sm font--500 text--black">
                  Created by
                </span>
                <span className="font--400 d--flex text--black-900">
                  : {creator?.first_name ?? ''} {creator?.last_name ?? ''}
                </span>
              </div>
              <div className="w--full font--sm font--500 d--flex align-items--center gap--md">
                <span className="d--flex w-min--100 label--control font--sm font--500 text--black">
                  Last updated
                </span>
                <span className="font--400 d--flex text--black-900">
                  : {converDateTimeFormateApis(contactData?.updated_at)}
                </span>
              </div>
              <div className="w--full font--sm font--500 d--flex align-items--center gap--md">
                <span className="d--flex w-min--100 label--control font--sm font--500 text--black">
                  Last updated by
                </span>
                <span className="font--400 d--flex text--black-900">
                  : {updator?.first_name ?? ''} {updator?.last_name ?? ''}
                </span>
              </div>
            </div>
            {/* <div className="w-min--100 h-min--100 w-max--100 h-max--100 w--full h--full radius--full bg--contrast"></div> */}
          </div>
          <div className="w--full d--flex flex--column gap--md position--absolute">
            <AddContactForm
              editContactInstance={{ isEdit: true, editData: contactData }}
              handleClose={(uuid) => {
                if (uuid) {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.set('contactId', uuid); // Remove the 'age' query parameter
                  setSearchParams(newParams);
                  handleGetContactName(uuid);
                }
              }}
              formActivity={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
