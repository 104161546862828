import React, { useEffect, useState } from 'react';
import Button from '../Button';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input';
import FormInput from '../FormInput';
import { DateFilterTypes } from '../../helpers/functions';
import { useSearchParams } from 'react-router-dom';
import useIcons from '../../assets/icons/useIcons';
import Avatar from '../Avatar';
import Dropdown from '../Dropdown';
import MultiDropDown from '../MultiDropdown';

const handleInitialValues = (arr = [], optionList = '', valueKey) => {
  const newValues = [];
  const optionArr = optionList.map((option) => option?.['extension']) || [];
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (optionArr.includes(element)) {
      newValues.push(element);
    }
  }
  return newValues;
};

const MultiDropDownCustom = ({
  optionList = [],
  labelKey = 'name',
  valueKey = 'value',
  label = '',
  placeholder = '',
  readOnly = false,
  error = '',
  withCaret = false,
  border = true,
  extraClasses = '',
  dropdownClasses = '',
  paddingRight = 'md',
  paddingLeft = 'md',
  isMemberList = false,
  isSearchable = true,
  onChange,
  disabled,
  value,
  required,
}) => {
  const { CaretIcon } = useIcons();
  const [searchValue, setSearchValue] = useState('');

  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    if (value && value?.length > 0) {
      setSelectedValues(value);
    }
  }, [value]);

  const updatedInitialValues = handleInitialValues(
    selectedValues,
    optionList,
    valueKey,
  );
  const handleCheckBoxChange = (value, disableMe = false) => {
    if (disableMe) return;
    if (updatedInitialValues?.includes(value)) {
      const updatedValues = updatedInitialValues?.filter(
        (val) => val !== value,
      );
      setSelectedValues(updatedValues);
    } else {
      setSelectedValues([...updatedInitialValues, value]);
    }
  };

  const dropListNotification = {
    component: ({ item }) => {
      const disableMe = item?.disableMe ? item?.disableMe : false;
      const isUseFlag = item?.isUseFlag ? item?.isUseFlag : false;
      const fieldLabel =
        labelKey instanceof Array
          ? labelKey.map((key) => item?.[key]).join(' ') || 'NA'
          : item?.[labelKey] || 'NA';
      const fieldValue = item?.['extension'] || 'NA';
      const isChecked = value?.includes(fieldValue) ?? false;
      return (
        <label
          className={`w--full d--flex gap--sm align-items--center justify-content--start p--sm ${
            !readOnly ? ' c--pointer' : ''
          }`}
          htmlFor={fieldLabel}
          onClick={() => handleCheckBoxChange(fieldValue, disableMe)}
        >
          {!readOnly && (
            <input
              id={fieldLabel}
              type="checkbox"
              checked={isChecked}
              disabled={readOnly || disableMe}
            />
          )}
          {isMemberList ? (
            <Avatar
              first_name={item?.first_name}
              last_name={item?.last_name}
              image={item?.profile_pic}
              size="28"
              extension={item?.extension}
            />
          ) : null}
          <div className="d--flex flex--column">
            {fieldLabel}
            {isMemberList ? (
              <span className="font--xs font--400 text--grey">
                {item?.extension}
              </span>
            ) : null}
          </div>
          {disableMe || isUseFlag ? (
            <div className="bg--secondary radius--full p-l--sm  p-r--sm w-max--60 text--white d--flex justify-content--center align-items--center">
              In use
            </div>
          ) : null}
        </label>
      );
    },
    data: searchValue
      ? optionList.filter(
          (option) =>
            option?.[labelKey]?.toLowerCase().includes(searchValue) ||
            option?.['extension']?.toLowerCase().includes(searchValue),
        ) || []
      : optionList,
    isDisabled: readOnly,
  };
  return (
    <div className="w--full  d--flex flex--column">
      <Dropdown
        closeOnClickOutside={false}
        dropList={dropListNotification}
        showcaret={withCaret}
        caretComponent={CaretIcon}
        extraClasses={`w--full ${dropdownClasses} `}
      >
        <FormInput
          disabled={disabled}
          type="text"
          extraClasses={`w--full ${extraClasses}`}
          placeholder={
            placeholder ||
            `${updatedInitialValues?.length ?? 0} values are selected.`
          }
          value={searchValue}
          onChange={(e) => isSearchable && setSearchValue(e.target.value)}
          error={error}
          border={border}
          paddingRight={paddingRight}
          paddingLeft={paddingLeft}
          required={required}
        />
      </Dropdown>
    </div>
  );
};

const handleGetValue = (value, options = []) => {
  const reqObj =
    options?.filter((opt) => `${opt.extension}` === `${value}`)?.[0] ?? null;

  if (reqObj) {
    return `${reqObj?.first_name} ${reqObj.last_name}`;
  }
  return '';
};

export const SingleDropdownCustom = ({
  optionList = [],
  labelKey = 'first_name',
  label = '',
  placeholder = '',
  error = '',
  withCaret = false,
  border = true,
  extraClasses = '',
  paddingRight = 'md',
  paddingLeft = 'md',
  disabled,
  required,
  ...rest
}) => {
  const { CaretIcon } = useIcons();
  const [searchValue, setSearchValue] = useState('');

  const dropListNotification = {
    component: ({ item }) => {
      return (
        <div
          onClick={() => {
            // setSearchValue('');
            rest?.onChange(`${item?.value}`);
          }}
          className="w--full p--sm"
        >
          <div className="d--flex align-items--center gap--md">
            <Avatar
              first_name={item?.name?.split(' ')?.[0]}
              last_name={item?.name?.split(' ')?.[1] || ''}
              image={item?.profile_pic}
              size="28"
              extension={item?.extension}
            />
            <div>
              <div className="d--flex flex--column">{item?.[labelKey]}</div>
              {item?.value && (
                <div className="font--xs font--400 text--grey">
                  {item?.value}
                </div>
              )}
              {item?.phone && (
                <div className="font--xs font--400 text--grey">
                  Phone: {item?.phone}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    },
    data: rest?.value
      ? optionList.filter(
          (option) =>
            option?.[labelKey]?.toLowerCase().includes(rest?.value) ||
            option?.['value']?.toLowerCase().includes(rest?.value) ||
            option?.['extension']?.toLowerCase().includes(rest?.value),
        ) || []
      : optionList,
  };
  return (
    <div className="w--full  d--flex flex--column">
      <Dropdown
        closeOnClickOutside={true}
        dropList={dropListNotification}
        showcaret={withCaret}
        caretComponent={CaretIcon}
        extraClasses="w--full"
      >
        <FormInput
          type="text"
          extraClasses={`w--full ${extraClasses}`}
          label={label}
          placeholder={placeholder}
          value={rest?.value}
          onChange={(e) => rest?.onChange(e.target.value)}
          // onChange={(e) => setSearchValue(e.target.value)}
          error={error}
          border={border}
          paddingRight={paddingRight}
          paddingLeft={paddingLeft}
          disabled={disabled}
          required={required}
        />
      </Dropdown>
    </div>
  );
};

const GlobalFilter2 = ({
  filters,
  setFilters,
  initialFilterState,
  setOpenFilter,
  isSearching = false,
  search_filters = [],
}) => {
  const [, setSearchParams] = useSearchParams();
  const [isFilterAlreadyExist, setIsFilterAlreadyExist] = useState(false);
  const [state, setState] = useState(initialFilterState?.filter ?? []);

  useEffect(() => {
    if (Array.isArray(filters) && filters?.length > 0) {
      setState(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (search_filters.length > 0) {
      setIsFilterAlreadyExist(true);
    }
  }, [search_filters.length]);

  return (
    <form
      className="w--full h--full"
      onSubmit={(e) => {
        setSearchParams('');
        e.preventDefault();
        setOpenFilter(false);
        setFilters(state);
      }}
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
        {state.map((filter, index) => {
          const { access = true } = filter || {};
          if (!access) return;
          if (filter?.inputType === 'date') {
            return (
              <div
                key={filter?.key ?? index}
                className="d--flex gap--md h--full align-items--center"
              >
                <div className="w--full w-max--150">
                  <RenderLabel filter={filter} setState={setState} />
                </div>
                <div className="d--flex flex--column gap--md bg--primary-100 border-full--primary-100 p--md radius--sm w--full h--full align-items--center">
                  <div className="w--full d--flex flex--column gap--sm">
                    <RenderDateFilterTypes
                      filter={filter}
                      setState={setState}
                    />
                    <RenderDateInputs filter={filter} setState={setState} />
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div
              className="d--flex gap--md h--full align-items--center"
              key={filter?.key ?? index}
            >
              <div className="w--full w-max--150">
                <RenderLabel filter={filter} setState={setState} />
              </div>
              <div className="w--full">
                <RenderInputField filter={filter} setState={setState} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => {
            setSearchParams('');
            if (isAnyFilterChecked({ state })) {
              setOpenFilter(false);
              setFilters([]);
            } else {
              setOpenFilter(false);
            }
          }}
        >
          {isAnyFilterChecked({ state }) ? 'Clear' : 'Close'}
        </Button>
        <Button
          type="submit"
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={
            isSearching ||
            (!isFilterAlreadyExist && !isAnyFilterChecked({ state }))
          }
        >
          Search
        </Button>
      </div>
    </form>
  );
};

export default GlobalFilter2;

const RenderLabel = ({ filter, setState }) => {
  const {
    key = '',
    isChecked = false,
    inputLabel = '',
    isDisabled = false,
    enableCrossDisabling = false,
    crossDisablingFunction = () => null,
  } = filter;
  return (
    <div className="d--flex gap--sm ">
      <input
        id={key}
        type="checkbox"
        className="form--control no--focus"
        checked={isChecked}
        onChange={(e) => {
          handleUpdateState({
            filter,
            setState,
            key,
            valueToUpdate: { isChecked: e.target.checked, value: '' },
          });
        }}
        disabled={isDisabled}
      />
      <label
        className={`label--control font--sm font--500  d--flex c--pointer`}
        htmlFor={key}
      >
        {inputLabel}
      </label>
    </div>
  );
};

const RenderInputField = ({ filter, setState }) => {
  const {
    key = '',
    isChecked = false,
    inputType = '',
    inputPlaceholder = '',
    value = '',
    enableCrossDisabling = false,
    crossDisablingFunction = () => null,
  } = filter;

  const triggerEnable = ({ key = '', findByKey = 'key' }) => {
    setState((prev) => {
      const newState = [...prev];
      const index = newState.findIndex((value) => value?.[findByKey] === key);
      if (index > -1) {
        newState.splice(index, 1, {
          ...filter,
          ...{ ...newState[index], isDisabled: false },
        });
      }
      return newState;
    });
  };

  const triggerDisable = ({ key = '', findByKey = 'key' }) => {
    setState((prev) => {
      const newState = [...prev];
      const index = newState.findIndex((value) => value?.[findByKey] === key);
      if (index > -1) {
        newState.splice(index, 1, {
          ...filter,
          ...{ ...newState[index], isDisabled: true, isChecked: false },
        });
      }
      return newState;
    });
  };
  const resetDisable = () => {
    setState((prev) => {
      const newState = [...prev];
      for (let index = 0; index < newState.length; index++) {
        const element = newState[index];
        newState.splice(index, 1, {
          ...filter,
          ...{ ...element, isDisabled: false },
        });
      }

      return newState;
    });
  };
  const customValueUpdate = ({ targetKey = '', key = '', value = '' }) => {
    setState((prev) => {
      const newState = [...prev];
      const index = newState.findIndex((value) => value?.key === targetKey);
      if (index > -1) {
        newState.splice(index, 1, {
          ...filter,
          ...{ ...newState[index], [key]: value },
        });
      }
      return newState;
    });
  };

  if (inputType === 'text' || inputType === 'email' || inputType === 'number') {
    return (
      <div className="d--flex gap-sm">
        <FormInput
          required={isChecked}
          type={inputType}
          value={value}
          className=""
          placeholder={inputPlaceholder}
          disabled={!isChecked}
          onChange={(e) => {
            if (enableCrossDisabling) {
              crossDisablingFunction({
                setState,
                key,
                filter,
                valueToUpdate: { value: e.target.value },
                triggerDisable,
                triggerEnable,
                resetDisable,
              });
            }
            handleUpdateState({
              setState,
              key,
              filter,
              valueToUpdate: { value: e.target.value },
            });
          }}
        />
      </div>
    );
  } else if (inputType === 'select') {
    const { inputOptions = [] } = filter;
    return (
      <div className="d--flex gap-sm">
        <select
          required={isChecked}
          value={value}
          className="form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200"
          disabled={!isChecked}
          placeholder={inputPlaceholder}
          onChange={(e) => {
            if (enableCrossDisabling) {
              crossDisablingFunction({
                setState,
                key,
                filter,
                valueToUpdate: { value: e.target.value },
                triggerDisable,
                triggerEnable,
                resetDisable,
                customValueUpdate,
              });
            }
            handleUpdateState({
              setState,
              key,
              filter,
              valueToUpdate: { value: e.target.value },
            });
          }}
        >
          <option value={''}>{inputPlaceholder}</option>
          {inputOptions?.map((item) => {
            return (
              <option key={item?.value} value={item?.value}>
                {item?.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  } else if (inputType === 'multi-select') {
    const { value = [], inputOptions = [] } = filter;
    return (
      <div className="d--flex gap-sm">
        <MultiDropDownCustom
          value={value}
          required={value?.length === 0 ? isChecked : false}
          className="form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200"
          disabled={!isChecked}
          placeholder={`${value?.length} employee/agent(s)`}
          onChange={(value) => {
            handleUpdateState({
              setState,
              key,
              filter,
              valueToUpdate: { value },
            });
          }}
          labelKey={'label'}
          valueKey={'value'}
          label="Members"
          optionList={inputOptions || []}
          error={null}
          isMemberList={true}
          isSearchable={true}
        />
      </div>
    );
  } else if (inputType === 'multi-select-normal') {
    const { value = [], inputOptions = [] } = filter;
    return (
      <div className="d--flex gap-sm">
        <MultiDropDown
          value={value}
          required={isChecked}
          className="form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200"
          disabled={!isChecked}
          placeholder={`${value?.length} value(s) selected`}
          onChange={(value) =>
            handleUpdateState({
              setState,
              key,
              filter,
              valueToUpdate: { value },
            })
          }
          labelKey={'label'}
          valueKey={'value'}
          label=""
          optionList={inputOptions || []}
          error={null}
          isMemberList={false}
          isSearchable={true}
        />
      </div>
    );
  } else if (inputType === 'phone-number') {
    return (
      <PhoneInput
        numberInputProps={{
          required: isChecked,
        }}
        defaultCountry={'US'}
        international={true}
        addInternationalOption={false}
        withCountryCallingCode={true}
        placeholder={inputPlaceholder}
        internationalIcon={() => null}
        flagComponent={() => null}
        countrySelectComponent={() => null}
        value={value}
        limitMaxLength={true}
        disabled={!isChecked}
        onChange={(phone) =>
          handleUpdateState({
            setState,
            key,
            filter,
            valueToUpdate: { value: phone },
          })
        }
        className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md border-full--black-200`}
      />
    );
  } else if (inputType === 'custom-selects-name-number') {
    return <CustomSelectNameNumber filter={filter} setState={setState} />;
  }
  return <></>;
};

const RenderDateFilterTypes = ({ filter, setState }) => {
  const {
    dateFilterTypes = DateFilterTypes,
    dateType = '',
    isChecked,
    inputPlaceholder = 'Select filter type',
  } = filter;
  return (
    <select
      disabled={!isChecked}
      className="form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200"
      value={dateType}
      onChange={(e) =>
        handleUpdateState({
          setState,
          filter,
          findByKey: 'inputType',
          key: 'date',
          valueToUpdate: {
            dateType: e.target.value,
            value: generateDateObjectFromTo({ dateType: e.target.value }),
          },
        })
      }
    >
      <option value="">{inputPlaceholder}</option>
      {dateFilterTypes.map((item, index) => (
        <option value={item?.value} key={index}>
          {item?.label}
        </option>
      ))}
    </select>
  );
};
const CustomSelectNameNumber = ({ filter, setState }) => {
  const {
    key = '',
    isChecked = false,
    inputType = '',
    inputPlaceholder = 'Select',
    value = '',
    inputOptions = [],
  } = filter;
  return (
    <div className="d--flex gap--sm">
      <SingleDropdownCustom
        labelKey="name"
        optionList={inputOptions}
        onChange={(value) =>
          handleUpdateState({
            setState,
            key,
            filter,
            valueToUpdate: { value },
          })
        }
        required={isChecked}
        value={value}
        placeholder={`${value}`}
        disabled={!isChecked}
      />
    </div>
  );
};

const RenderDateInputs = ({ filter, setState }) => {
  const { dateType = '', value } = filter;
  if (dateType === 'Select Date') {
    return (
      <input
        name="selectDate"
        id="selectDate"
        type="date"
        value={value?.from ?? ''}
        placeholder="Select or type a date..."
        className="form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200 w--full"
        max={moment().format('YYYY-MM-DD')}
        onChange={(e) =>
          handleUpdateState({
            filter,
            setState,
            key: 'date',
            findByKey: 'inputType',
            valueToUpdate: {
              value: {
                from: e.target.value,
                to: e.target.value,
              },
            },
          })
        }
      />
    );
  }

  if (dateType === 'Custom') {
    return (
      <span className="d--flex gap--md">
        <input
          className={`form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200`}
          type="date"
          name="startDate"
          id="startDate"
          value={value?.from ?? ''}
          disabled={dateType !== 'Custom'}
          max={moment().format('YYYY-MM-DD')}
          onChange={(e) =>
            handleUpdateState({
              filter,
              setState,
              key: 'date',
              findByKey: 'inputType',
              valueToUpdate: {
                value: {
                  from: e.target.value,
                  to: value?.to,
                },
              },
            })
          }
        />
        <input
          className={`form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200`}
          type="date"
          name="endDate"
          id="endDate"
          value={value?.to ?? ''}
          disabled={dateType !== 'Custom'}
          max={moment().format('YYYY-MM-DD')}
          onChange={(e) =>
            handleUpdateState({
              filter,
              setState,
              key: 'date',
              findByKey: 'inputType',
              valueToUpdate: {
                value: {
                  from: value?.from,
                  to: e.target.value,
                },
              },
            })
          }
        />
      </span>
    );
  }

  return null;
};

const handleUpdateState = ({
  setState,
  key,
  findByKey = 'key',
  filter,
  valueToUpdate,
}) => {
  setState((prev) => {
    const newState = [...prev];
    const index = newState.findIndex((value) => value?.[findByKey] === key);
    if (index > -1) {
      newState.splice(index, 1, { ...filter, ...valueToUpdate });
    }
    return newState;
  });
};

// utils func
const generateDateObjectFromTo = ({ dateType }) => {
  let object = { from: '', to: '' };
  switch (dateType) {
    case 'Today':
      object = {
        from: moment(),
        to: moment(),
      };
      break;
    case 'Yesterday':
      object = {
        from: moment().subtract(1, 'days'),
        to: moment().subtract(1, 'days'),
      };
      break;
    case 'This Week':
      object = {
        from: moment().startOf('week'),
        to: moment(),
      };
      break;
    case 'Last 7 Days':
      object = {
        from: moment().subtract(6, 'days'),
        to: moment(),
      };
      break;
    case 'Last 30 Days':
      object = {
        from: moment().subtract(29, 'days'),
        to: moment(),
      };
      break;
    case 'This Month':
      object = {
        from: moment().startOf('month'),
        to: moment(),
      };
      break;
    case 'Last Month':
      object = {
        from: moment().subtract(1, 'month').startOf('month'),
        to: moment().subtract(1, 'month').endOf('month'),
      };
      break;
    default:
      object = { from: '', to: '' };
      break;
  }
  return {
    from: object.from ? object.from.format('YYYY-MM-DD') : '',
    to: object.to ? object.to.format('YYYY-MM-DD') : '',
  };
};

const isAnyFilterChecked = ({ state }) => {
  return state.find((item) => {
    if (item?.inputType !== 'date' && item?.isChecked === true) {
      return true;
    } else if (
      item?.inputType === 'date' &&
      item?.value?.from &&
      item?.value?.to
    ) {
      return true;
    } else {
      return false;
    }
  });
};
