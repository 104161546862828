import { useMutation } from '@tanstack/react-query';
import { forgotPassword } from '../../api';
import { useAlert } from '../useAlert';

export default function useForgotPassword() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: forgotPassword,
    mutationKey: 'forgotPassword',
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.message ?? 'Password reset email sent successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
