import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Button from '../../comopnents/Button';
import useIcons from '../../assets/icons/useIcons';
import FormInput from '../../comopnents/FormInput';
import useResetPassword from '../../hooks/useResetPassword';

const initialValues = {
  password: '',
  confirm_password: '',
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password field is required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  confirm_password: yup
    .string()
    .required('Confirm password field is required.')
    .when('password', {
      is: (newPassword) => newPassword && newPassword.length > 0,
      then: yup.string().oneOf([yup.ref('password')], 'Passwords do not match'),
    }),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { EyeOffIcon, EyeIcon } = useIcons();
  const [visibilityToggle, setVisibilityToggle] = useState({
    password: true,
    confirm_password: true,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  const { mutate: mutateResetPassword, isLoading } = useResetPassword({
    handleSuccess: () => navigate('/'),
  });

  const onSubmit = (values) => {
    if (id) {
      let payload = {
        password: values.password,
        token: id,
      };
      mutateResetPassword(payload);
    }
  };
  return (
    <div className="w--full  h--full d--flex align-items--center justify-content--center loginPage">
      <div className="radius--md  gap--xl w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box-shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
        <div className="w--full">
          <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center">
            <span className="d--flex m-b--lg w--full justify-content--between">
              <h3 className="font--2xl text--primary">Set Password</h3>
              <span></span>
            </span>
            <form
              className="w--full d--flex flex--column gap--lg"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <div className="position-relative">
                    <label
                      className={`label--control font--sm font--500 m-b--xs  text--black d--flex
                    ${errors?.password?.message ? 'text--red' : 'text--black'}
                    `}
                    >
                      {errors?.password?.message ?? 'Password'}
                    </label>
                    <div className="w--full position--relative">
                      <FormInput
                        {...field}
                        type={visibilityToggle.password ? 'password' : 'text'}
                        maxLength={50}
                        placeholder="Enter your password"
                      />
                      <div
                        className="pointer svg--sm position--absolute top--9 right--10 translate-middle-y inputIcon"
                        onClick={() =>
                          setVisibilityToggle((prev) => ({
                            ...prev,
                            password: !prev.password,
                          }))
                        }
                      >
                        {!visibilityToggle.password ? (
                          <EyeIcon width={20} height={20} />
                        ) : (
                          <EyeOffIcon width={20} height={20} />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              />
              <Controller
                name="confirm_password"
                control={control}
                render={({ field }) => (
                  <div className="position-relative">
                    <label
                      className={`label--control font--sm font--500 m-b--xs  text--black d--flex
                    ${
                      errors?.confirm_password?.message
                        ? 'text--red'
                        : 'text--black'
                    }
                    `}
                    >
                      {errors?.confirm_password?.message ?? 'Confirm Password'}
                    </label>
                    <div className="w--full position--relative">
                      <FormInput
                        {...field}
                        type={
                          visibilityToggle.confirm_password
                            ? 'password'
                            : 'text'
                        }
                        maxLength={50}
                        placeholder="Enter confirm password"
                      />
                      <div
                        className="pointer svg--sm position--absolute top--9 right--10 translate-middle-y inputIcon"
                        onClick={() =>
                          setVisibilityToggle((prev) => ({
                            ...prev,
                            confirm_password: !prev.confirm_password,
                          }))
                        }
                      >
                        {!visibilityToggle.confirm_password ? (
                          <EyeIcon width={20} height={20} />
                        ) : (
                          <EyeOffIcon width={20} height={20} />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              />
              <div className="form-group w--full">
                <Button
                  type="submit"
                  btnClasses="btn"
                  disabled={isSubmitting || isLoading}
                  size="md"
                  variant="primary"
                  color="white"
                >
                  {isLoading || isSubmitting ? 'Please wait...' : 'Submit'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
