import React, { useEffect, useState } from 'react';
import Button from '../../../comopnents/Button';
import useIcons from '../../../assets/icons/useIcons';
import useGetPlans from '../../../hooks/useGetPlans';
import { useAuth } from '../../../hooks/useAuth';
import Modal from '../../../comopnents/Modal';
import PaymentFormModal from './PaymentFormModal';

const selectOptions = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Annual',
    value: 'annual',
  },
];

const UpgradePlan = () => {
  const [openPaymentModal, setopenPaymentModal] = useState(false);
  const { TickFillIcon } = useIcons();
  const { mutate, data } = useGetPlans();
  const { user } = useAuth();
  const planListing = data?.data?.data?.data || [];
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const handlePlan = (val) => {
    setSelectedPlan(val);
  };

  useEffect(() => {
    mutate();
  }, []);

  const handleUpgradeButtonClick = (selectedId) => {
    setSelectedPlanId(selectedId);
    setopenPaymentModal(true);
  };

  return (
    <>
      <div className="upgradePlanPage pricingPage w--full h--full ">
        <div className="section priceBox p-t--80 overflow--hidden w--full h--full">
          <div className="w--full h--full d--flex flex--column justify-content--center align-items--center">
            <div className="w--full d--flex align-items--center justify-content--center m-b--sm">
              <div className="w--full d--flex align-items--center justify-content--center gap--xs w-max--300 border-full--black-100 p--xs  bg--white radius--sm">
                {selectOptions.map((item) => {
                  return (
                    <Button
                      variant={`${selectedPlan === item?.value
                        ? 'secondary btn'
                        : 'primary-100 btn'
                        }`}
                      color={`${selectedPlan === item?.value ? 'white' : 'primary'
                        }`}
                      onClick={() => handlePlan(item?.value)}
                    >
                      {item?.label}
                    </Button>
                  );
                })}

                {/* <select
                  value={selectedPlan}
                  className="form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200"
                  onChange={(e) => handlePlan(e.target.value)}
                >
                  {selectOptions?.map((item) => {
                    return (
                      <option key={item?.value} value={item?.value}>
                        {item?.label}
                      </option>
                    );
                  })}
                </select> */}
              </div>
            </div>
            <div className="w--full bg--white h--full gap--lg d--flex flex--column callForwardingForm overflow--auto box-shadow--xs  p--lg radius--sm ">
              <div className="w--full d--flex gap--xl flex-column--xs position--relative z-index--1 ">
                {planListing.map((plan, index) => (
                  <div
                    key={index}
                    className="w--full radius--sm p--xl bg--white d--flex flex--column gap--lg box-shadow--xs priceBoxItem position--relative "
                  >
                    <h3 className="font--xl font--900 text--c d--flex flex--column gap--md">
                      {selectedPlan === 'monthly' ? (
                        <span>${plan?.monthly_cost ?? ''}/mo</span>
                      ) : (
                        <span>${plan?.yearly_cost ?? ''}/yr</span>
                      )}
                    </h3>
                    <h4 className="font--xl font--600 text--c d--flex flex--column gap--md">
                      {plan.plan_name}
                      <div className="font--md font--400 m-b--2xl text--c text--grey">
                        {plan?.description ?? ''}
                      </div>
                    </h4>

                    {plan?.features?.map((p) => (
                      <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                        <div className="text--success">
                          <TickFillIcon width={20} height={20} />
                        </div>
                        {p}
                      </p>
                    ))}

                    {/* <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                      <div className="text--success">
                        <TickFillIcon width={20} height={20} />
                      </div>
                      {plan?.number_of_dids_text ?? ''}
                    </p>
                    <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                      <div className="text--success">
                        <TickFillIcon width={20} height={20} />
                      </div>
                      {plan?.number_of_member_text ?? ''}
                    </p>
                    <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                      <div className="text--success">
                        <TickFillIcon width={20} height={20} />
                      </div>
                      {plan?.free_calls_text ?? ''}
                    </p>
                    <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                      <div className="text--success">
                        <TickFillIcon width={20} height={20} />
                      </div>
                      {plan?.free_sms_text ?? ''}
                    </p> */}
                    {/* <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                      <div className="text--success">
                        <TickFillIcon width={20} height={20} />
                      </div>
                      {plan?.cloud_space_text ?? ''}
                    </p> */}

                    {/* &&
                    ((selectedPlan === 'monthly' &&
                      user?.plan_duration === 1) ||
                      (selectedPlan === 'annual' &&
                        user?.plan_duration === 12))  
                        Appen this logic below to check if plan is monthly or annualy
                        */}

                    {user?.plan_detail?.plan_uuid === plan?.uuid ? (
                      <Button
                        variant="primary"
                        color="white"
                        size="md"
                        btnClasses="btn w-min--150"
                        disabled={true}
                      >
                        Current plan
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        color="white"
                        size="md"
                        btnClasses="btn w-min--150"
                        onClick={() => handleUpgradeButtonClick(plan.uuid)}
                      >
                        Upgrade
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openPaymentModal ? (
        <Modal
          footerComponent={null}
          title="Upgrade Plan"
          handleClose={() => setopenPaymentModal(false)}
          shouldCloseOnClickOutside={false}
          height="400"
        >
          <PaymentFormModal
            plan_uuid={selectedPlanId}
            plan_duration={
              selectedPlan === 'monthly'
                ? 1
                : selectedPlan === 'annual'
                  ? 12
                  : ''
            }
          />
        </Modal>
      ) : null}
    </>
  );
};

export default UpgradePlan;
