import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Switch from '../../../../comopnents/Switch';
import useProfileUpdate from '../../../../hooks/useProfileUpdate';
import { useAuth } from '../../../../hooks/useAuth';

const MissedCallSettings = () => {
  const { user } = useAuth();

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      message: '',
      status: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (user?.missed_call_sms_settings) {
      reset(user?.missed_call_sms_settings);
    }
  }, [user?.missed_call_sms_settings]);

  const {
    mutateAsync: missedCallSmsMutate,
    isLoading: missedCallSmsUpdateLoad,
  } = useProfileUpdate();

  function onSubmit(values) {
    const payload = {
      missed_call_sms_settings: values,
    };
    missedCallSmsMutate(payload);
  }

  return (
    <>
      <div className="w--full d--flex gap--md flex--column">
        <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
          Auto SMS Reply Setting
        </div>
      </div>
      <form
        className="w--full h--full d--flex flex--column gap--md"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="d--flex w--full align-items--end gap--md">
          <div className="w--full mb-3 d--flex align-items--center flex--column">
            <div className="w--full d--flex gap--lg justify-content--start">
              <div className="label--control font--sm font--600 m-b--xs d--flex text--black">
                Enable
              </div>
              <label
                className="label--control gap--sm c--pointer font--sm font--500 m-b--xs d--flex align-items--center text--black"
                htmlFor="voicemail_email"
              >
                <Controller
                  name={`status`}
                  control={control}
                  render={({ field }) => {
                    return <Switch {...field} id={`status`} />;
                  }}
                />
              </label>
            </div>
            {watch('status') ? (
              <div className="w--full d--flex gap--lg justify-content--start">
                <Controller
                  name={`message`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <textarea
                        {...field}
                        className="form--control w-max--450 w-min--450 h-max--106 h-min--106 radius--sm p--md  border-full--black-200 m-b--sm"
                        rows={5}
                      />
                    );
                  }}
                />
              </div>
            ) : (
              <div className="w-max--450 w-min--450 h-max--106 h-min--106 m-b--sm p--md"></div>
            )}
          </div>
        </div>

        <div className="d--flex align-items--end gap--md">
          <button
            disabled={missedCallSmsUpdateLoad}
            type="submit"
            className="btn font--sm d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary w-max--120 c--pointer"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default MissedCallSettings;
