import React, { useContext } from 'react';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';
import Timer from '../../comopnents/SipComponents/Timer';
import GetName from '../../comopnents/GetName';
import TableManager from '../../comopnents/TableManager';
import { getMemberListing } from '../../api';
import Avatar from '../../comopnents/Avatar';
import { useAuth } from '../../hooks/useAuth';

const statusColorLookup = {
  Available: (
    <div className=" radius--sm bg--success  radius--full w--full h--full h-max--12 h-min--12 w-max--12 w-min--12 " />
  ),
  Unavailable: (
    <div className=" radius--sm bg--grey   radius--full w--full h--full h-max--12 h-min--12 w-max--12 w-min--12 " />
  ),
  Away: (
    <div className=" radius--sm bg--secondary  radius--full w--full h--full h-max--12 h-min--12 w-max--12 w-min--12 " />
  ),
  Busy: (
    <div className=" radius--sm bg--danger   radius--full w--full h--full h-max--12 h-min--12 w-max--12 w-min--12 " />
  ),
};

const LiveCalls = () => {
  const { socket } = useContext(SipSocketContext);
  const { user } = useAuth();
  const columns = [
    {
      accessorKey: 'first_name',
      header: () => 'Name',
      cell: (props) => {
        const element = props?.row?.original;
        const iAmAdmin = element?.uuid === user?.uuid;
        const isAgency = element?.role_id === 4;
        const isTenant = element?.role_id === 2;
        const fullname = `${element?.first_name} ${element?.last_name}`;
        return (
          <span className="d--flex gap--sm  align-items--center">
            <Avatar
              size="28"
              first_name={element?.first_name}
              last_name={element?.last_name}
              image={element?.profile_pic}
              extension={element?.extension}
            />
            {fullname}
            <span className="text--danger font--600">
              {iAmAdmin
                ? '(You)'
                : isAgency
                ? '(Agency)'
                : isTenant
                ? '(Tenant)'
                : ''}
            </span>
          </span>
        );
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        return (
          <span className="d--flex align-items--center justify-content--end gap--sm">
            {statusColorLookup[props?.getValue()]} {props?.getValue()}
          </span>
        );
      },
      meta: {
        textAlign: 'right',
      },
    },
  ];
  return (
    <div className=" w--full d--flex gap--lg ">
      <div className="w--full   d--flex flex--column ">
        <div className="w--full d--flex align-items--center justify-content--between  m-b--sm h-min--32">
          <div className="font--md font--400 d--flex align-items--center gap--sm w-min--200">
            <span className="d--flex ">Live Calls</span>
          </div>
        </div>
        <div className="w--full table--responsive bg--white table--responsive--full-scroll w--full  radius--sm bg--white p-t--0 p-b--xs  p-l--xs  p-r--xs">
          <table className="table border-bottom--black-100 ">
            <thead>
              <tr>
                <th scope="col" style={{ textAlign: 'center' }}>
                  From
                </th>
                <th scope="col" style={{ textAlign: 'center' }}>
                  To
                </th>
                <th scope="col" style={{ textAlign: 'center' }}>
                  Via Virtual Number
                </th>
                <th scope="col" style={{ textAlign: 'center' }}>
                  Direction
                </th>
                <th scope="col" style={{ textAlign: 'center' }}>
                  Status
                </th>
                <th scope="col" style={{ textAlign: 'center' }}>
                  Duration
                </th>
              </tr>
            </thead>
            <tbody>
              {socket?.onCall && Object.keys(socket?.onCall)?.length > 0 ? (
                Object.keys(socket?.onCall)?.map((extension) => {
                  const itemData = socket?.onCall?.[extension];
                  return (
                    <tr key={itemData?.['From-User']}>
                      <td style={{ textAlign: 'center' }}>
                        <GetName
                          number={
                            itemData?.Direction === 'initiator'
                              ? itemData?.['From-User']
                              : itemData?.['To-User']
                          }
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <GetName
                          number={
                            itemData?.Direction === 'initiator'
                              ? itemData?.['To-User']
                              : itemData?.['From-User']
                          }
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>---</td>
                      <td style={{ textAlign: 'center' }}>
                        {itemData?.Direction === 'recipient'
                          ? 'Incoming'
                          : 'Outgoing'}
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          textTransform: 'capitalize',
                        }}
                      >
                        {itemData?.State}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {itemData?.State === 'confirmed' ? (
                          <Timer start_time={+new Date(itemData?.Time)} />
                        ) : (
                          '---'
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    No Live Calls
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className=" w--sm  d--flex flex--column gap--md">
        <TableManager
          {...{
            fetcherKey: 'getMemberListing',
            fetcherFn: getMemberListing,
            showPagination: false,
            showPageCount: false,
            columns,
            name: `Agents`,
          }}
        />
      </div> */}
    </div>
  );
};

export default LiveCalls;
