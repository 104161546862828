import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { uploadMedia } from '../../api';

export default function useUploadMedia() {
  const { showAlert } = useAlert();
  const uploadMediaRequest = useMutation({
    mutationFn: uploadMedia,
    mutationKey: 'uploadMedia',
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...uploadMediaRequest };
}
