import React, { useEffect, useState } from 'react';
import FormSelect from '../../../comopnents/FormSelect';
// import FormInput from '../../../comopnents/FormInput';
import PhoneInput from 'react-phone-number-input';
import Button from '../../../comopnents/Button';
import { RenderDIDItem } from '..';
import useSendSMS from '../../../hooks/useSendSMS';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useIcons from '../../../assets/icons/useIcons';
import { AGENCY_OWNER, USER } from '../../../helpers/functions';
import { useAuth } from '../../../hooks/useAuth';
import useUploadMedia from '../../../hooks/useUploadMedia';

const initialValues = {
  to: '',
  text: '',
  file: '',
};
const validationSchema = yup.object().shape({
  to: yup.string().required('To is required'),
  text: yup.string().required('Text is required'),
  file: yup.mixed().optional(),
});

const SendMessageModal = ({
  didListing = [],
  smsDefaultDID,
  setSMSDefaultDid = () => null,
  handleClose = () => null,
  destinationNumber = '',
}) => {
  const [isSendingSMS, setIsSendingSMS] = useState(false);

  const { PinIcon } = useIcons();
  const { mutate: sendSMSMutate, isLoading: sendSMSLoad } = useSendSMS({
    handleSuccess,
  });
  const { mutateAsync: getUploadUrl } = useUploadMedia();

  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (destinationNumber) {
      setValue('to', destinationNumber);
    }
  }, [destinationNumber]);

  const handleSendSMS = async (data) => {
    const { file, ...values } = data;
    setIsSendingSMS(true);
    if (file) {
      const payload = {
        uuid: [USER, AGENCY_OWNER].includes(user?.role_id)
          ? user?.uuid
          : user?.parent_uuid || user?.uuid,
        file_name:
          file?.name || `attachment_file.${file?.name?.split('.')?.[1]}`,
        type: 'sms_media',
      };

      const res = await getUploadUrl(payload);
      const { filename, url: uploadUrl } = res?.data?.data || '';
      if (uploadUrl) {
        const uploadFileResponse = await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
        });
        if (uploadFileResponse.status === 200) {
          const payload = {
            from: smsDefaultDID,
            ...values,
            media: `${process.env.REACT_APP_MEDIA_URL}signed-url/${
              user?.parent_uuid || user?.uuid
            }/sms_media?filename=${filename}`,
            media_type: file?.type?.includes('image')
              ? 'image'
              : file?.type?.includes('audio')
              ? 'audio'
              : file?.type?.includes('video')
              ? 'video'
              : 'file',
            type: 'MMS',
          };
          sendSMSMutate(payload);
        }
      }
    } else {
      const payload = {
        from: smsDefaultDID,
        ...values,
        media: '',
        media_type: '',
        type: 'SMS',
      };
      sendSMSMutate(payload);
    }
    setIsSendingSMS(false);
  };
  function handleSuccess() {
    handleClose();
  }
  function handleFileSelect(e) {
    setValue('file', e.target.files[0]);
    e.target.value = '';
  }

  return (
    <div className="w--full p--lg bg--white radius--sm">
      <div className="w--full d--flex gap--lg flex--column">
        <div className="w--full">
          <FormSelect
            name="smsDefaultDID"
            label="From"
            placeholder="Select option"
            options={didListing || []}
            renderOption={RenderDIDItem}
            value={smsDefaultDID}
            onChange={(e) => {
              setSMSDefaultDid(e.target.value);
            }}
          />
        </div>
        <div className="w--full">
          <label
            className={`label--control font--sm font--500 m-b--xs   ${
              errors?.to?.message ? 'text--danger' : 'text--black'
            }`}
          >
            {errors?.to?.message ? 'To is required' : 'To'}
          </label>
          <Controller
            name="to"
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                control={control}
                defaultCountry={'US'}
                international={true}
                addInternationalOption={false}
                withCountryCallingCode={true}
                countryCallingCodeEditable={true}
                internationalIcon={() => null}
                flagComponent={() => null}
                countrySelectComponent={() => null}
                value={getValues(`to`)}
                limitMaxLength={true}
                onChange={(phone) => setValue(`to`, phone)}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                  errors?.phone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                }`}
                disabled={Boolean(destinationNumber)}
              />
            )}
          />
        </div>
        <div className="w--full">
          <label
            className={`label--control font--sm font--500 m-b--xs ${
              errors?.text?.message ? 'text--danger' : 'text--black'
            } d--flex`}
          >
            {errors?.text?.message || 'Text'}
          </label>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <textarea
                {...field}
                type="text"
                className="form--control w--full h-min--100  radius--sm p--md p-l--md p-r--md  border-full--black-200"
                style={{ resize: 'none' }}
                placeholder=""
              />
            )}
          />
        </div>
        {watch('file') && (
          <div className={`radius--sm d--flex flex--column gap--sm `}>
            <h3 className={`text--black m-b--sm font--400 `}>
              Attachment File :
            </h3>
            {watch('file')?.type?.includes('image') && (
              <img
                className={`w-max--300 w-min--300 h-max--200 h-min--200 radius--sm bg--black-300 object--contain overflow--hidden `}
                src={watch('file') ? URL.createObjectURL(watch('file')) : null}
                alt=""
              />
            )}
            {watch('file')?.type?.includes('video') && (
              <video
                className={`w-max--300 w-min--300 h-max--200 h-min--200 radius--sm bg--black-300 object--contain overflow--hidden `}
                src={watch('file') ? URL.createObjectURL(watch('file')) : null}
                controls
              />
            )}
            {watch('file')?.type?.includes('audio') && (
              <audio
                src={watch('file') ? URL.createObjectURL(watch('file')) : null}
                controls
                className={``}
              />
            )}
          </div>
        )}
        <div className="d--flex justify-content--end m-t--sm gap--md align-items--center">
          <label
            htmlFor="sms_attachment_modal"
            className="c--pointer"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={handleFileSelect}
          >
            <PinIcon />
          </label>
          <input
            type="file"
            name="sms_attachment_modal"
            id="sms_attachment_modal"
            accept="audio/*,video/*,image/*"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={handleFileSelect}
            hidden
          />
          <div className="w-max--150 w--full">
            <Button
              btnClasses="btn white--space-none"
              variant="primary"
              onClick={handleSubmit(handleSendSMS)}
              disabled={sendSMSLoad || isSendingSMS}
            >
              {sendSMSLoad || isSendingSMS ? 'Please wait..' : 'Send'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMessageModal;
