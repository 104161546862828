import React, { useEffect, useState } from 'react';
import Button from '../../../comopnents/Button';
import useIcons from '../../../assets/icons/useIcons';
import { Link } from 'react-router-dom';
import useGetPlans from '../../../hooks/useGetPlans';

const Pricing = () => {
  const { TickFillIcon, Curve2Icon } = useIcons();
  const { mutate, data } = useGetPlans();
  const planListing = data?.data?.data?.data || [];
  const [selectedPlan, setSelectedPlan] = useState('monthly');

  const handlePlan = (val) => {
    setSelectedPlan(val);
  };

  useEffect(() => {
    mutate();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="pricingPage w--full">
        <div className="section priceBox p-t--80 overflow--hidden">
          <div className="container">
            <div className="w--full d--flex flex--column justify-content--center align-items--center">
              <div className="priceBanner w--full w-max--800 gap--lg d--flex flex--column align-items--center justify-content--center m-b--xl p-b--80 position--relative">
                <h2 className="font--42 font--700 line-height--1-dot-2 text--c">
                  Discover Plan For you
                </h2>
                <p className="font--md text--grey line-height--2 text--c">
                  Lorem ipsum dolor sit amet consectetur. In porttitor massa
                  consectetur gravida velit. A lectus posuere proin interdum.
                  Quis amet quis cursus odio mauris integer. In amet sodales
                  varius arcu egestas.
                </p>
                <div className="w--full d--flex align-items--center justify-content--center m-b--sm">
                  <div className="w--full d--flex align-items--center justify-content--center gap--xs w-max--300 border-full--black-100 p--xs  bg--white radius--sm">
                    <Button
                      variant={`${
                        selectedPlan === 'monthly'
                          ? 'secondary btn'
                          : 'primary-100 btn'
                      }`}
                      color={`${
                        selectedPlan === 'monthly' ? 'white' : 'primary'
                      }`}
                      onClick={() => handlePlan('monthly')}
                    >
                      Monthly Plan
                    </Button>
                    <Button
                      variant={`${
                        selectedPlan === 'annual'
                          ? 'secondary btn'
                          : 'primary-100 btn'
                      }`}
                      color={`${
                        selectedPlan === 'annual' ? 'white' : 'primary'
                      }`}
                      onClick={() => handlePlan('annual')}
                    >
                      Annual Plan
                    </Button>
                  </div>
                </div>
                <div className="circleBox position--absolute">
                  <div className="text--success position--absolute animate mover">
                    <div className="border-full--success w-min--50 h-min--50 w-max--50 radius--full position--absolute mover animate"></div>
                  </div>
                  <div className="bg--success-100 w-min--50 h-min--50 w-max--50 radius--full position--absolute bounce--right animate"></div>
                </div>
              </div>
              <div className="w--full d--flex gap--xl flex-column--xs position--relative z-index--1">
                <div className="bigCircle position--absolute z-index---0">
                  <div className="bg--primary-100 w-min--200 h-min--200 w-max--200 radius--full position--absolute mover animate"></div>
                </div>
                {planListing.map((plan, index) => (
                  <div
                    key={index}
                    className="w--full r-b-left--lg r-b-right--lg p--xl bg--white d--flex flex--column gap--lg box-shadow--xs priceBoxItem position--relative "
                  >
                    {index === 1 && (
                      <div className="position--absolute w--full popularBtn border-full--red r-t-left--lg r-t-right--lg">
                        <Button
                          variant="red"
                          color="white"
                          size="lg"
                          btnClasses="btn w-min--150 r-t-left--lg  r-t-right--lg font--md"
                        >
                          Most popular plan
                        </Button>
                      </div>
                    )}

                    <h3 className="font--36 font--800 h-min--75 text--c">
                      {selectedPlan === 'monthly' ? (
                        <span>${plan?.monthly_cost ?? ''}/mo</span>
                      ) : (
                        <span>${plan?.yearly_cost ?? ''}/yr</span>
                      )}
                    </h3>
                    <h4 className="font--xl font--600 text--c d--flex flex--column gap--md">
                      {plan.plan_name}
                      <div className="font--md font--400 m-b--2xl text--c text--grey">
                        {plan?.description ?? ''}
                      </div>
                    </h4>

                    {plan?.features?.map((p) => (
                      <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                        <div className="text--success">
                          <TickFillIcon width={20} height={20} />
                        </div>
                        {p}
                      </p>
                    ))}

                    {/* <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                      <div className="text--success">
                        <TickFillIcon width={20} height={20} />
                      </div>
                      {plan?.number_of_dids_text ?? ''}
                    </p>
                    <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                      <div className="text--success">
                        <TickFillIcon width={20} height={20} />
                      </div>
                      {plan?.number_of_member_text ?? ''}
                    </p>
                    <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                      <div className="text--success">
                        <TickFillIcon width={20} height={20} />
                      </div>
                      {plan?.free_calls_text ?? ''}
                    </p>
                    <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                      <div className="text--success">
                        <TickFillIcon width={20} height={20} />
                      </div>
                      {plan?.free_sms_text ?? ''}
                    </p>
                    <p className="font--14 m-b--lg d--flex align-items--center gap--md text--grey">
                      <div className="text--success">
                        <TickFillIcon width={20} height={20} />
                      </div>
                      {plan?.cloud_space_text ?? ''}
                    </p> */}

                    <Link
                      key={plan?.uuid}
                      to={`/signup?plan_uuid=${plan?.uuid}&duration=${
                        selectedPlan === 'monthly' ? 1 : 12
                      }`}
                    >
                      <Button
                        variant="primary"
                        color="white"
                        size="md"
                        btnClasses="btn w-min--150"
                      >
                        Buy
                        {/* {selectedPlan === 'monthly' ? (
                          <span>Monthly Plan</span>
                        ) : (
                          <span>Annual Plan</span>
                        )} */}
                      </Button>
                    </Link>
                  </div>
                ))}

                <div className="curve2Icon  animate mover text--success position--absolute">
                  <Curve2Icon width={66} height={166} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bg--contrast p-t--80 p-b--80">
          <div className="container">
            <div className="w--full d--flex justify-content--center align-items--center">
              <div className="w--full w-max--800 gap--lg d--flex flex--column align-items--center justify-content--center">
                <h2 className="font--42 font--700 line-height--1-dot-2 text--c">
                  Start your 30-day free trial
                </h2>
                <p className="font--md text--grey line-height--2 text--c">
                  Lorem ipsum dolor sit amet consectetur. In porttitor massa
                  consectetur gravida velit. A lectus posuere proin interdum.
                  Quis amet quis cursus odio mauris integer. In amet sodales
                  varius arcu egestas.
                </p>
                <div className="w-max--300 d--flex gap--sm">
                  <Button
                    variant="purple"
                    color="white"
                    size="md"
                    btnClasses="btn w-min--150"
                  >
                    Read More
                  </Button>
                  <NavLink
                    to={'/login'}
                    className="w-max--120 w--full p-l--sm "
                  >
                    <Button
                      variant="success"
                      color="white"
                      size="md"
                      btnClasses="btn w-min--150"
                    >
                      Get Started
                    </Button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Pricing;
