import { useQuery } from '@tanstack/react-query';
import { dashboardStats } from '../../api';
import { useAlert } from '../useAlert';

export default function useDashboardStats() {
  const { showAlert } = useAlert();
  const dashboardStatsRequest = useQuery({
    queryFn: dashboardStats,
    queryKey: ['dashboardStats'],
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: true,
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...dashboardStatsRequest };
}
