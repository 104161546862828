import React, {
  useState,
  useMemo,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import useIcons from '../../assets/icons/useIcons';
import Spinner from '../Spinner';
import Button from '../Button';
import { useAlert } from '../../hooks/useAlert';
import AsideModal from '../AsideModal';
import GlobalFilter2 from '../GlobalFilter2';
import Tabbing from '../../comopnents/Tabbing';
import NoRecordImage from '../../assets/images/web/empty_item.svg';

import moment from 'moment';
import TableRow from './TableRow';
import { generateCustomDateObjectFromTo } from '../../pages/Dashboard/helpers';
import CustomDateFilter from './CustomDateFilter';
import { isElectron } from '../../helpers/functions';

const weekFilter = {
  from: moment().startOf('week').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
};
const monthFilter = {
  from: moment().startOf('month').format('YYYY-MM-DD'),
  to: moment().endOf('month').format('YYYY-MM-DD'),
};

const todayFilter = {
  from: moment().format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
};

const pageNumberListLimit = 5;
const TableManager = (
  {
    isCustomDesign = false,
    fetcherKey = '',
    fetcherFn = () => {},
    columns = [],
    tableActions = [],
    name = '',
    shouldShowColumnName = true,
    extraParams = {},
    showPagination = true,
    showHeader = true,
    shouldShowTotalCount = false,
    shouldShowDate = false,
    shouldFilter = false,
    initialFilterState,
    withTabs = false,
    showPageCount = true,
    showSpinner = true,
    tab = 0,
    setTab = () => null,
    tabsLabels = [],
    isEnabled = true, // only when api function is not avialable we pass isEnabled = false so no api error is thrown
    customTotalCount = false,
    setApiResponse = null, // this must be a call back function is needed
    selectCBForTableData = (tblData) => tblData?.data?.data,
    fromDashboard = false,
    showRefresh = true,
    setIsRefetching = () => null,
    showMoreOption = (elm) => false,
    enableMoreOption = false,
    moreDataFunction = () => null,
    moreDataFnPayload = () => null,
    customFilters = false,
    enableDefaultFilter = 'This Week',
  },
  ref,
) => {
  const {
    RefreshIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    FilterIcon,
  } = useIcons();
  const [filters, setFilters] = useState([]);
  const [dateFilterType, setDateFilterType] = useState('This Week');
  const [filter_date, setFilterDate] = useState(
    enableDefaultFilter
      ? generateCustomDateObjectFromTo({ dateType: enableDefaultFilter })
      : null,
  );

  const [openFilter, setOpenFilter] = useState(false);
  const { showAlert } = useAlert();
  const memoizedColumns = useMemo(() => columns, [columns]);
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [maxPageNumberListLimit, setMaxPageNumberListLimit] = useState(5);
  const [minPageNumberListLimit, setMinPageNumberListLimit] = useState(0);
  const memoizedSorting = useMemo(() => sorting, [sorting]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const search_filters = useMemo(() => {
    let response = [];
    if (filters.length > 0) {
      filters.forEach((filter) => {
        if (filter.key === 'date' && filter.value.from && filter.value.to) {
          response.push({
            key: filter.key,
            dateType: filter.dateType,
            value: filter.value,
          });
        } else if (
          (filter.key !== 'date' &&
            !(filter?.value instanceof Array) &&
            filter.value) ||
          (filter?.value instanceof Array && filter?.value?.length > 0)
        ) {
          response.push({
            key: filter.key,
            value: filter.value,
          });
        }
      });
    }
    return response;
  }, [filters]);

  let payload = {
    page: pageIndex + 1,
    limit: pageSize,
    sort: memoizedSorting[0] || {},
    filter: search_filters,
    filter_date,
    ...extraParams,
  };
  const {
    isSuccess,
    isLoading,
    isFetching,
    refetch,
    data: tbldata,
  } = useQuery({
    queryFn: ({ queryKey }) => fetcherFn(queryKey[1] || {}),
    queryKey: [`${fetcherKey}`, { ...payload }],
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
    enabled: isEnabled,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
  });

  useEffect(() => {
    setIsRefetching(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (isSuccess && tbldata && setApiResponse) {
      setApiResponse(tbldata);
    }
  }, [isSuccess, setApiResponse, tbldata]);

  // useEffect(() => {
  //   if (customFilters ) {
  //     const filterToSet =
  //       customDayTab === 'week'
  //         ? weekFilter
  //         : customDayTab === 'month'
  //         ? monthFilter
  //         : todayFilter;

  //     const filterLabel =
  //       customDayTab === 'week'
  //         ? 'This Week'
  //         : customDayTab === 'month'
  //         ? 'This Month'
  //         : 'Today';
  //     const newState = [...initialFilterState?.filter];
  //     if (filters?.length > 0) {
  //       for (let index = 0; index < filters.length; index++) {
  //         const filter = filters[index];
  //         if (filter?.isChecked) {
  //           const index = newState.findIndex(
  //             (value) => value?.key === filter?.key,
  //           );
  //           if (index > -1) {
  //             newState.splice(index, 1, filter);
  //           }
  //         }
  //       }
  //     }
  //     const index = newState.findIndex((value) => value?.key === 'date');
  //     if (!customFilters) {
  //       if (index > -1) {
  //         newState.splice(index, 1, {
  //           key: 'date',
  //           inputLabel: 'By Date',
  //           isChecked: true,
  //           value: filterToSet,
  //           inputType: 'date',
  //           dateType: filterLabel,
  //           access: true,
  //           isDisabled: true,
  //         });
  //       } else {
  //         newState.push({
  //           key: 'date',
  //           inputLabel: 'By Date',
  //           isChecked: true,
  //           value: filterToSet,
  //           inputType: 'date',
  //           dateType: filterLabel,
  //           access: true,
  //           isDisabled: true,
  //         });
  //       }
  //       setFilters(newState);
  //       setDateFilterType('');
  //       setFilterDate({ from: '', to: '' });
  //     }
  //   }
  // }, [customFilters, customDayTab, JSON.stringify(initialFilterState)]);

  const tableInstance = useReactTable({
    debugAll: false,
    data: selectCBForTableData(tbldata) || [],
    columns: memoizedColumns || [],
    columnResizeMode: 'onChange',
    state: {
      pagination,
      sorting: memoizedSorting,
      rowSelection,
    },
    manualPagination: true,
    manualSorting: true,
    pageCount: tbldata?.data?.lastPage ?? -1,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  const handleNextPage = () => {
    tableInstance?.nextPage();
    if (pageIndex === tableInstance?.getPageCount() - 1) {
      return false;
    } else {
      if (pageIndex + 2 > maxPageNumberListLimit) {
        setMaxPageNumberListLimit(maxPageNumberListLimit + pageNumberListLimit);
        setMinPageNumberListLimit(minPageNumberListLimit + pageNumberListLimit);
      }
    }
  };
  const handlePreviousPage = () => {
    tableInstance?.previousPage();
    if (pageIndex === 0) {
      return;
    } else {
      if (pageIndex % pageNumberListLimit === 0) {
        setMaxPageNumberListLimit(maxPageNumberListLimit - pageNumberListLimit);
        setMinPageNumberListLimit(minPageNumberListLimit - pageNumberListLimit);
      }
    }
  };
  const handleLastPage = () => {
    tableInstance?.setPageIndex(tableInstance?.getPageCount() - 1);
    const min = tableInstance.getPageCount() - 5;
    const max = tableInstance.getPageCount();

    setMaxPageNumberListLimit(max);
    setMinPageNumberListLimit(min);
  };
  const handleFirstPage = () => {
    tableInstance?.setPageIndex(0);
    const min = 0;
    const max = 5;
    setMaxPageNumberListLimit(max);
    setMinPageNumberListLimit(min);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        overrideFilters: (filters) => {
          // setFilters(filters);
        },
      };
    },
    [],
  );
  const removeDateFilter = () => {
    const newState = filters.filter((value) => value?.key !== 'date');

    setFilters(newState);
  };
  const dateFilter = useMemo(
    () => search_filters?.find((item) => item?.key === 'date') || null,
    [search_filters],
  );
  return (
    <>
      {showHeader && (
        <div className="w--full d--flex align-items--center justify-content--between  m-b--sm ">
          {!fromDashboard && (
            <div
              style={{ minWidth: '150px' }}
              className="font--md font--400 d--flex align-items--center gap--sm white--space-none"
            >
              {name ? (
                <span className="d--flex text--primary font--400">
                  {name}&nbsp;
                  {shouldShowDate && dateFilter ? (
                    <>
                      ({moment(dateFilter?.value?.from).format('DD MMM')}
                      &nbsp;-&nbsp;
                      {moment(dateFilter?.value?.to).format('DD MMM')})&nbsp;
                    </>
                  ) : null}
                  <>
                    {shouldShowTotalCount &&
                      !customTotalCount &&
                      `(${tbldata?.data?.totalRecords ?? 0})`}
                    {shouldShowTotalCount &&
                      customTotalCount &&
                      `| Total ${tbldata?.data?.totalRecords ?? 0} Record(s)`}
                  </>
                </span>
              ) : (
                <span></span>
              )}
              {(isLoading || isFetching) && isEnabled && showSpinner && (
                <span className="d--flex ">
                  <Spinner />
                </span>
              )}
            </div>
          )}

          {withTabs ? (
            <div
              className={`w--full d--flex align-items--center ${
                fromDashboard
                  ? 'justify-content--start'
                  : 'justify-content--center'
              } `}
            >
              <Tabbing tab={tab} setTab={setTab} tabsLabels={tabsLabels} />
            </div>
          ) : null}

          <div
            style={{ minWidth: '254px' }}
            className={`d--flex align-items--center justify-content--end gap--sm`}
          >
            {customFilters ? (
              <CustomDateFilter
                dateFilterType={dateFilterType}
                setDateFilterType={setDateFilterType}
                filter_date={filter_date}
                setFilterDate={setFilterDate}
                removeDateFilter={removeDateFilter}
              />
            ) : null}
            {tableActions && tableActions.length > 0 && (
              <span className="d--flex gap--sm">
                {tableActions.map(({ id, component: ElmComponent, access }) => {
                  if (!access) return;
                  return <span key={id}>{ElmComponent}</span>;
                })}
              </span>
            )}

            {shouldFilter && (
              <span className="position--relative">
                {search_filters && search_filters.length > 0 && (
                  <span className="bg--danger z-index--sm radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--60 text--white d--flex justify-content--center align-items--center position--absolute right--20 top---4">
                    {search_filters?.length}
                  </span>
                )}
                <span
                  data-tooltip="Filter"
                  tooltip-position={isElectron ? 'left' : 'top'}
                >
                  <Button
                    variant="white"
                    btnClasses="btn w-max--36 box-shadow--xs"
                    color="primary"
                    type="button"
                    icon={<FilterIcon width={20} height={20} />}
                    onClick={() => setOpenFilter(true)}
                    size="sm"
                  />
                </span>
              </span>
            )}
            {showRefresh && (
              <Button
                variant="white"
                btnClasses="btn w-max--36 box-shadow--xs z-index--sm"
                color="primary"
                type="button"
                icon={<RefreshIcon width={20} height={20} />}
                onClick={() => refetch()}
                size="sm"
                data-tooltip="Refresh"
                tooltip-position={isElectron ? 'left' : 'top'}
              />
            )}
          </div>
        </div>
      )}

      <div
        // style={{
        //   background:
        //     tableInstance?.getRowModel()?.rows?.length === 0
        //       ? '#fff'
        //       : '',
        // }}
        className={`w--full table--responsive ${
          isCustomDesign
            ? tableInstance?.getRowModel()?.rows?.length === 0
              ? 'bg--white'
              : 'table--responsive--collapse'
            : 'bg--white'
        } table--responsive--full-scroll w--full  radius--sm   h--full`}
      >
        <table className="table border-bottom--black-100">
          {shouldShowColumnName && (
            <thead>
              {tableInstance?.getHeaderGroups()?.map((headerGroup) => (
                <tr key={headerGroup?.id}>
                  {enableMoreOption && (
                    <td
                      style={{
                        width: '0.2%',
                      }}
                    ></td>
                  )}
                  {headerGroup?.headers?.map((header) => {
                    return (
                      <th
                        key={header?.id}
                        style={{
                          textAlign:
                            header?.column?.columnDef?.meta?.textAlign ??
                            'left',

                          width:
                            header?.column?.columnDef?.meta?.width ||
                            `${(
                              100 / header?.headerGroup?.headers?.length
                            ).toFixed(2)}%`,
                        }}
                      >
                        {header?.isPlaceholder ? null : header?.column.columnDef
                            .meta?.isSortable ? (
                          <div
                            onClick={header?.column?.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: <RefreshIcon />,
                              desc: <RefreshIcon />,
                            }[header.column.getIsSorted()] ?? <RefreshIcon />}
                          </div>
                        ) : (
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}

          <tbody>
            {tableInstance?.getRowModel()?.rows?.length > 0 &&
              tableInstance?.getRowModel()?.rows?.map((row) => {
                return (
                  <TableRow
                    row={row}
                    flexRender={flexRender}
                    enableMoreOption={enableMoreOption}
                    showMoreOption={showMoreOption}
                    rowSelection={rowSelection}
                    moreDataFunction={moreDataFunction}
                    moreDataFnPayload={moreDataFnPayload}
                    memoizedColumns={memoizedColumns}
                  />
                );
              })}
          </tbody>
        </table>
        {tableInstance?.getRowModel()?.rows?.length === 0 ||
        !tableInstance?.getRowModel()?.rows?.length ? (
          <>
            <div className="h--full d--flex align-items--center justify-conent--center w--full tableNoRecord">
              <span className="w--full d--flex justify-content--center flex--column gap--sm align-items--center">
                <img width={150} src={NoRecordImage} alt="No record found" />
                <span>No record found</span>
              </span>
            </div>
          </>
        ) : null}
      </div>
      {(showPageCount || showPagination) && (
        <div className="w--full d--flex align-items--center justify-content--between  m-t--md">
          {showPageCount && (
            <div className=" d--flex gap--sm align-items--center w--full">
              <span>
                <span className="font--600">Page </span>
                <span>
                  {tableInstance?.getState()?.pagination?.pageIndex + 1}
                </span>
                of <span>{tableInstance?.getPageCount()}</span>
              </span>
              <span>|</span>
              <span className="d--flex gap--sm align-items--center w-max--300 w--full">
                <span className="font--600">Show</span>
                <select
                  required
                  className="form--control w--full h-min--28 h-max--28 w-max--60  radius--sm p-l--xs p-r--xs"
                  value={String(
                    tableInstance?.getState()?.pagination?.pageSize,
                  )}
                  onChange={(e) => {
                    tableInstance?.setPageSize(Number(e.target.value));
                  }}
                >
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                </select>
                <span className="w--full d--flex">records per page</span>
              </span>
            </div>
          )}

          {showPagination &&
            !isLoading &&
            tableInstance?.getRowModel()?.rows?.length > 0 && (
              <div className="d--flex align-items--center justify-content--end">
                <div className="d--flex gap--xs">
                  <Button
                    onClick={() => handleFirstPage()}
                    disabled={!tableInstance?.getCanPreviousPage()}
                    btnClasses={` bg--primary-200 c--pointer h-min--28 h-max--28 r-t-left--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center  ${
                      !tableInstance?.getCanPreviousPage() ? 'disabled' : ''
                    } `}
                    type="button"
                    variant="primary"
                    color="primary"
                  >
                    <DoubleArrowLeftIcon width={18} height={18} />
                  </Button>
                  <Button
                    onClick={() => handlePreviousPage()}
                    disabled={!tableInstance?.getCanPreviousPage()}
                    btnClasses={` bg--primary-200 c--pointer h-min--28 h-max--32 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                      !tableInstance?.getCanPreviousPage() ? 'disabled' : ''
                    } `}
                    type="button"
                    variant="primary"
                    color="primary"
                  >
                    <ArrowLeftIcon width={18} height={18} />
                  </Button>

                  <div className="d--flex gap--xs align-item--center ">
                    {tableInstance?.getPageOptions()?.map((page, index) => {
                      const number = page + 1;
                      if (
                        number < maxPageNumberListLimit + 1 &&
                        number > minPageNumberListLimit
                      ) {
                        return (
                          <Button
                            key={page}
                            onClick={() => tableInstance?.setPageIndex(index)}
                            btnClasses={`  c--pointer h-min--28 h-max--28 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                              tableInstance?.getState()?.pagination
                                ?.pageIndex === index
                                ? ' bg--primary'
                                : 'bg--primary-200 '
                            } `}
                            type="button"
                            variant="primary"
                            color={` ${
                              tableInstance?.getState()?.pagination
                                ?.pageIndex === index
                                ? ' text--white'
                                : 'text--primary '
                            } `}
                          >
                            {page + 1}
                          </Button>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>

                  <Button
                    onClick={() => handleNextPage()}
                    disabled={!tableInstance?.getCanNextPage()}
                    btnClasses={` bg--primary-100 c--pointer h-min--28 h-max--32 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                      !tableInstance?.getCanNextPage() ? 'disabled' : ''
                    } `}
                    type="button"
                    variant="primary"
                    color="primary"
                  >
                    <ArrowRightIcon width={18} height={18} />
                  </Button>
                  <Button
                    onClick={() => handleLastPage()}
                    disabled={!tableInstance?.getCanNextPage()}
                    btnClasses={` bg--primary-100 c--pointer h-min--28 h-max--32 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                      !tableInstance?.getCanNextPage() ? 'disabled' : ''
                    } `}
                    type="button"
                    variant="primary"
                    color="primary"
                  >
                    <DoubleArrowRightIcon width={18} height={18} />
                  </Button>
                </div>
              </div>
            )}
        </div>
      )}
      {openFilter && (
        <AsideModal
          handleClose={() => setOpenFilter(false)}
          title="Filters"
          footerComponent={null}
        >
          <GlobalFilter2
            filters={filters}
            setFilters={setFilters}
            setOpenFilter={setOpenFilter}
            initialFilterState={initialFilterState}
            isSearching={isLoading || isFetching}
            search_filters={search_filters}
          />
        </AsideModal>
      )}
    </>
  );
};

export default forwardRef(TableManager);
