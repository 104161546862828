import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getPortingRequestData } from '../../../api';
import PhoneInput from 'react-phone-number-input';
import FormSelect from '../../../comopnents/FormSelect';
import Button from '../../../comopnents/Button';
import useIcons from '../../../assets/icons/useIcons';
import FormInput from '../../../comopnents/FormInput';
import {
  AddressProofTypes,
  IdentityProofTypes,
} from '../../../helpers/functions';
import { useAuth } from '../../../hooks/useAuth';
import useUploadMedia from '../../../hooks/useUploadMedia';
import usePortingRequest from '../../../hooks/usePortingRequest';
import * as yup from 'yup';

const countries = [{ label: 'United States', value: 'US' }];
const numberOptions = [
  {
    id: 1,
    label: 'Local',
    value: 'Local',
  },
  {
    id: 1,
    label: 'Toll-free',
    value: 'Toll-free',
  },
];

const RenderItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.value}
      value={item.value}
    >
      {item.label}
    </option>
  );
};

const RenderCountries = ({ item }) => {
  return (
    <option key={item?.value} value={item?.value}>
      {item?.label}
    </option>
  );
};
const RenderNumbers = ({ item }) => {
  return (
    <option key={item?.id} value={item?.value}>
      {item?.label}
    </option>
  );
};

const initialValueForFile = [
  {
    type: '',
    file: null,
  },
];
const initialValues = {
  did_number: '',
  country: 'US',
  number_type: '',

  first_name: '',
  last_name: '',
  phone_number: '',
  contact_email: '',
  identity_proofs: initialValueForFile,
  city: '',
  postal_code: '',
  address: '',
  state: '',
  address_proofs: initialValueForFile,
  id: undefined,
};

const validationSchema = yup.object().shape({
  did_number: yup.string().required('DID Number is required'),
  country: yup.string().required('Country is required'),
  number_type: yup.string().required('Number Type is required'),

  first_name: yup
    .string()
    .required('First name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers'),
  last_name: yup
    .string()
    .required('Last name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Last name cannot contain numbers'),
  phone_number: yup.string().required('Phone number is required'),
  contact_email: yup
    .string()
    .email('Contact email is invalid')
    .required('Contact email is required')
    .max(50, 'Maximum length can be 50 characters'),
  city: yup.string().required('City is required'),
  postal_code: yup
    .string()
    .required('Postal code is required')
    .matches(/^[0-9]+$/, 'Postal code cannot contains space or alphabet')
    .max(12, 'Postal code maximum length can be 12'),
  address: yup.string().required('Address is required'),
  identity_proofs: yup.array().when('id', {
    is: (value) => !Boolean(value),
    then: () =>
      yup.array().of(
        yup.object().shape({
          type: yup.string().required('Proof type is required'),
          file: yup.mixed().when('type', {
            is: (res) => Boolean(res),
            then: yup.mixed().required('File is required'),
          }),
        }),
      ),
  }),
  address_proofs: yup.array().when('id', {
    is: (value) => !Boolean(value),
    then: () =>
      yup.array().of(
        yup.object().shape({
          type: yup.string().required('Proof type is required'),
          file: yup.mixed().when('type', {
            is: (res) => Boolean(res),
            then: yup.mixed().required('File is required'),
          }),
        }),
      ),
  }),
});

const UpsertPortingRequest = () => {
  const { user } = useAuth();
  const { id = '' } = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { PlusIcon, TrashIcon } = useIcons();

  const {
    fields: identityFields,
    append: identityAppend,
    remove: identityRemove,
  } = useFieldArray({
    control,
    name: 'identity_proofs',
  });
  const {
    fields: addressFields,
    append: addressAppend,
    remove: addressRemove,
  } = useFieldArray({
    control,
    name: 'address_proofs',
  });

  const { data: portingRequestData, isSuccess } = useQuery({
    queryKey: ['get-porting-request-data'],
    queryFn: () => getPortingRequestData({ id }),
    select: (data) => data?.data?.data,
    enabled: Boolean(id),
    cacheTime: 0,
  });

  const { mutateAsync: getUploadUrl } = useUploadMedia();

  const { mutate: portingMutate, isLoading: portingLoad } = usePortingRequest({
    handleSuccess,
    isEdit: getValues('id') ? true : false,
  });

  const addIdentityField = () => {
    if (identityFields.length === 6) return;
    identityAppend({
      type: '',
      file: null,
    });
  };
  const addAddressField = () => {
    if (addressFields.length === 4) return;
    addressAppend({
      type: '',
      file: null,
    });
  };
  const removeIdentityField = (index) => {
    identityRemove(index);
  };
  const removeAddressField = (index) => {
    addressRemove(index);
  };

  useEffect(() => {
    if (portingRequestData && isSuccess) {
      const {
        did_number = '',
        country = '',
        number_type = '',

        first_name = '',
        last_name = '',
        phone_number = '',
        contact_email = '',

        city = '',
        postal_code = '',
        address = '',
        state = '',
        id = '',
      } = portingRequestData || {};
      reset({
        did_number,
        number_type,
        first_name,
        last_name,
        phone_number,
        contact_email,
        identity_proofs: initialValueForFile,
        country,
        city,
        postal_code,
        address,
        state,
        address_proofs: initialValueForFile,
        id,
      });
    }
    return () => {
      reset(initialValues);
    };
  }, [portingRequestData && isSuccess]);

  const handleOnSubmit = async (data) => {
    const { identity_proofs = [], address_proofs = [] } = data;
    setIsUploading(true);
    let new_address_proofs = [];
    let new_identity_proofs = [];
    for (let index = 0; index < identity_proofs.length; index++) {
      const proof = identity_proofs[index]?.file;
      const type = identity_proofs[index]?.type;
      if (proof) {
        const payload = {
          uuid: user?.parent_uuid || user?.uuid,
          file_name: proof?.name ?? '',
          type: 'porting_doc',
        };
        const res = await getUploadUrl(payload);
        const { filename, url: uploadUrl } = res?.data?.data || '';
        if (uploadUrl) {
          const uploadFileResponse = await fetch(uploadUrl, {
            method: 'PUT',
            body: proof,
          });

          if (uploadFileResponse.status === 200) {
            new_identity_proofs.push({
              type,
              file: filename,
            });
          }
        }
      }
    }
    for (let index = 0; index < address_proofs.length; index++) {
      const proof = address_proofs?.[index]?.file;
      const type = address_proofs?.[index]?.type;
      if (proof) {
        const payload = {
          uuid: user?.parent_uuid || user?.uuid,
          file_name: proof?.name ?? '',
          type: 'porting_doc',
        };
        const res = await getUploadUrl(payload);
        const { filename, url: uploadUrl } = res?.data?.data || '';
        if (uploadUrl) {
          const uploadFileResponse = await fetch(uploadUrl, {
            method: 'PUT',
            body: proof,
          });

          if (uploadFileResponse.status === 200) {
            new_address_proofs.push({
              type,
              file: filename,
            });
          }
        }
      }
    }
    setIsUploading(false);
    let payload = {
      ...data,
      address_proofs: new_address_proofs,
      identity_proofs: new_identity_proofs,
    };
    portingMutate(payload);
  };

  function handleSuccess() {
    navigate('/virtual-numbers/porting-requests');
  }
  const isLoading = portingLoad || isUploading;

  return (
    <div className="w--full">
      <div className="d--flex align-items--center justify-content--between m-b--sm ">
        <div className="font--md font--400 d--flex align-items--center gap--sm w--full w--full text--primary">
          New Porting Request
        </div>
      </div>
      <div className="portingPage overflow--auto  bg--white  box-shadow--xs  p--md radius--sm">
        <form
          className="w--full h--full d--flex gap--xl"
          onSubmit={handleSubmit(handleOnSubmit)}
          autoComplete="off"
        >
          <div className="d--flex gap--xl flex--column w--full w-max--700">
            <div>
              <label
                className={`label--control font--sm font--500 m-b--xs   ${
                  errors?.phone?.message ? 'text--danger' : 'text--black'
                }`}
              >
                {errors?.phone?.message ?? 'Virtual Number'}
              </label>
              <Controller
                name="did_number"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    control={control}
                    defaultCountry={'US'}
                    international={true}
                    addInternationalOption={false}
                    withCountryCallingCode={true}
                    countryCallingCodeEditable={false}
                    placeholder="Enter your virtual number"
                    internationalIcon={() => null}
                    flagComponent={() => null}
                    countrySelectComponent={() => null}
                    value={getValues(`did_number`)}
                    limitMaxLength={true}
                    onChange={(phone) => setValue(`did_number`, phone)}
                    className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                      errors?.did_number?.message
                        ? 'border-full--danger'
                        : 'border-full--black-200'
                    }`}
                  />
                )}
              />
            </div>
            <div className="w--full d--flex gap--md">
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    label="Country"
                    options={countries || []}
                    renderOption={RenderCountries}
                    error={errors?.country?.message}
                    disabled={true}
                  />
                )}
              />
              <Controller
                name="number_type"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    options={numberOptions || []}
                    label="Number type"
                    renderOption={RenderNumbers}
                    placeholder="Select"
                    error={errors?.number_type?.message}
                  />
                )}
              />
            </div>

            <div className="label--control font--sm font--500 d--flex gap--sm align-items--center h-min--36 m-t--md text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
              End User Registration Details
            </div>

            <div className="w--full d--flex flex--column gap--md">
              <div className="d--flex gap--md">
                <Controller
                  name="first_name"
                  control={control}
                  rules={{
                    onChange(e) {
                      setValue('first_name', e.target.value?.trimStart());
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="First Name"
                      placeholder="Enter your first name"
                      error={errors?.first_name?.message}
                    />
                  )}
                />
                <Controller
                  name="last_name"
                  control={control}
                  rules={{
                    onChange(e) {
                      setValue('last_name', e.target.value?.trimStart());
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="Last Name"
                      placeholder="Enter your last name"
                      error={errors?.last_name?.message}
                    />
                  )}
                />
              </div>
              <div className="d--flex gap--md">
                <div className="w--full">
                  <label
                    className={`label--control font--sm font--500 m-b--xs ${
                      errors?.phone_number?.message
                        ? 'text--danger'
                        : 'text--black'
                    }`}
                  >
                    {errors?.phone_number?.message || 'Phone number'}
                  </label>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        control={control}
                        defaultCountry={'US'}
                        international={true}
                        addInternationalOption={false}
                        withCountryCallingCode={true}
                        countryCallingCodeEditable={true}
                        placeholder="Enter phone number"
                        internationalIcon={() => null}
                        flagComponent={() => null}
                        countrySelectComponent={() => null}
                        value={watch(`phone_number`)}
                        limitMaxLength={true}
                        onChange={(phone) => setValue(`phone_number`, phone)}
                        className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                          errors?.phone_number?.message
                            ? 'border-full--danger'
                            : 'border-full--black-200'
                        }`}
                      />
                    )}
                  />
                </div>
                <Controller
                  name="contact_email"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="Contact email"
                      placeholder="Enter your contact email"
                      error={errors?.contact_email?.message}
                    />
                  )}
                />
              </div>

              <div className="w--full">
                <label className="label--control font--sm font--500 m-b--xs  text--black d--flex">
                  Proof Type
                </label>
                <div className="d--flex w--full flex--column align-items--center gap--sm ">
                  {identityFields.map((field, index) => {
                    return (
                      <div
                        className="d--flex gap--md align-items--center w--full"
                        key={field?.id}
                      >
                        <Controller
                          name={`identity_proofs[${index}].type`}
                          control={control}
                          render={({ field }) => (
                            <FormSelect
                              {...field}
                              options={
                                IdentityProofTypes.filter((proof) => {
                                  const otherSelectedValues =
                                    watch(`identity_proofs`).filter(
                                      (val, i) => i !== index,
                                    ) ?? [];
                                  const otherSelectedValuesArr =
                                    otherSelectedValues.map(
                                      (val) => val?.type,
                                    ) ?? [];
                                  if (
                                    otherSelectedValuesArr.includes(
                                      proof?.value,
                                    )
                                  ) {
                                    return false;
                                  } else {
                                    return true;
                                  }
                                }) || []
                              }
                              renderOption={RenderItem}
                              placeholder="Select"
                              label={' '}
                              error={
                                errors?.identity_proofs?.[index]?.type?.message
                              }
                            />
                          )}
                        />
                        <div className="d--flex align-items--end w--full">
                          <div className="w--full d--flex align-items--end gap--sm">
                            <FormInput
                              type="file"
                              accept="image/*,application/*"
                              id={`identity_proofs[${index}].file`}
                              name={`identity_proofs[${index}].file`}
                              label={' '}
                              onChange={(e) => {
                                setValue(
                                  `identity_proofs[${index}].file`,
                                  e.target.files[0],
                                  {
                                    shouldValidate: true,
                                  },
                                );
                              }}
                              error={
                                errors?.identity_proofs?.[index]?.file?.message
                              }
                            />
                            <div className="d--flex gap--xs m-b--xs">
                              <Button
                                disabled={identityFields.length === 6}
                                onClick={() => addIdentityField()}
                                btnClasses="w--full h--full h-max--32 w-max--32  w-min--32 h-min--32 d--flex align-items--center justify-content--center c--pointer"
                                rounded
                                size="sm"
                                variant="black-100"
                                color="black"
                              >
                                <PlusIcon width={18} height={18} />
                              </Button>
                              {index !== 0 && (
                                <Button
                                  disabled={identityFields.length === 1}
                                  onClick={() => removeIdentityField(index)}
                                  btnClasses="w--full h--full h-max--32 w-max--32  w-min--32 h-min--32 d--flex align-items--center justify-content--center c--pointer"
                                  rounded
                                  size="sm"
                                  variant="primary"
                                  color="white"
                                >
                                  <TrashIcon width={18} height={18} />
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="w--full d--flex flex--column gap--md">
            <div className="label--control font--sm font--500 d--flex gap--sm align-items--center h-min--36 m-t--md text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
              End User Address Details
            </div>
            <div className="d--flex flex--column gap--md endUserDetails">
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    label="Country"
                    options={countries || []}
                    renderOption={RenderCountries}
                    error={errors?.country?.message}
                    disabled={true}
                  />
                )}
              />
              <div className="d--flex gap--md">
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="City"
                      error={errors?.city?.message}
                    />
                  )}
                />
                <Controller
                  name="postal_code"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="Postal code"
                      maxLength={12}
                      error={errors?.postal_code?.message}
                    />
                  )}
                />
              </div>
              <div className="d--flex gap--md">
                <div className="w--full">
                  <label
                    className={`label--control font--sm font--500 m-b--xs d--flex  ${
                      errors?.address?.message ? 'text--danger' : 'text--black'
                    }`}
                  >
                    {errors?.address?.message ?? 'Address'}
                  </label>
                  <textarea
                    className="form--control w--full h-min--36  radius--sm p-l--md p-r--md p-t--sm p-b--sm  border-full--black-200"
                    value={watch('address')}
                    onChange={(e) => {
                      setValue('address', e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    name="address"
                    rows="1"
                    cols="150"
                  ></textarea>
                </div>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="State/Province/Region"
                      error={errors?.state?.message}
                    />
                  )}
                />
              </div>

              <div className="w--full">
                <label className="label--control font--sm font--500 m-b--xs d--flex w--full  text--black">
                  Proof Type
                </label>
                <div className="d--flex w--full flex--column align-items--center gap--sm ">
                  {addressFields.map((field, index) => {
                    return (
                      <div
                        className="d--flex gap--md align-items--center w--full"
                        key={field?.id}
                      >
                        <div className="d--flex w--full align-items--center gap--sm">
                          <Controller
                            name={`address_proofs[${index}].type`}
                            control={control}
                            render={({ field }) => (
                              <FormSelect
                                {...field}
                                options={
                                  AddressProofTypes.filter((proof) => {
                                    const otherSelectedValues =
                                      watch(`address_proofs`).filter(
                                        (val, i) => i !== index,
                                      ) ?? [];
                                    const otherSelectedValuesArr =
                                      otherSelectedValues.map(
                                        (val) => val?.type,
                                      ) ?? [];
                                    if (
                                      otherSelectedValuesArr.includes(
                                        proof?.value,
                                      )
                                    ) {
                                      return false;
                                    } else {
                                      return true;
                                    }
                                  }) || []
                                }
                                label={' '}
                                renderOption={RenderItem}
                                placeholder="Select"
                                error={
                                  errors?.address_proofs?.[index]?.type?.message
                                }
                              />
                            )}
                          />

                          <div className="w--full d--flex align-items--end gap--sm m-b--xs">
                            <FormInput
                              type="file"
                              accept="image/*,application/*"
                              id={`address_proofs[${index}].file`}
                              name={`address_proofs[${index}].file`}
                              label={' '}
                              onChange={(e) => {
                                setValue(
                                  `address_proofs[${index}].file`,
                                  e.target.files[0],
                                  {
                                    shouldValidate: true,
                                  },
                                );
                              }}
                              error={
                                errors?.address_proofs?.[index]?.file?.message
                              }
                            />
                            <div className="d--flex gap--xs">
                              <Button
                                disabled={addressFields.length === 4}
                                onClick={() => addAddressField()}
                                btnClasses="w--full h--full h-max--32 w-max--32  w-min--32 h-min--32 d--flex align-items--center justify-content--center c--pointer"
                                rounded
                                size="sm"
                                variant="black-100"
                                color="black"
                              >
                                <PlusIcon width={18} height={18} />
                              </Button>
                              {index !== 0 && (
                                <Button
                                  disabled={addressFields.length === 1}
                                  onClick={() => removeAddressField(index)}
                                  btnClasses="w--full h--full h-max--32 w-max--32  w-min--32 h-min--32 d--flex align-items--center justify-content--center c--pointer"
                                  rounded
                                  size="sm"
                                  variant="primary"
                                  color="white"
                                >
                                  <TrashIcon width={18} height={18} />
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className=" d--flex justify-content--center">
              <Button
                btnClasses="c--pointer btn w-max--100"
                type="button"
                variant="primary"
                color="white"
                disabled={isLoading}
                onClick={handleSubmit(handleOnSubmit)}
              >
                {isLoading ? 'Please wait..' : 'Submit'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpsertPortingRequest;
