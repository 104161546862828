import { useMutation } from '@tanstack/react-query';
import { signup } from '../../api';
import { useAlert } from '../useAlert';

export default function useSignup({ handleSuccess = () => null } = {}) {
  const { showAlert } = useAlert();
  const addAgencyRequest = useMutation({
    mutationFn: signup,
    mutationKey: 'signup',
    onSuccess: ({ data }) => {
      handleSuccess(data);
      showAlert({
        type: 'success',
        message: data?.message ?? '',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...addAgencyRequest };
}
