import React, { useState, useContext, createContext, useMemo } from 'react';
const CallSummaryContext = createContext(null);

export const CallSummaryContextWrapper = ({ children }) => {
  const [state, setState] = useState({
    active_number: '',
    start_time: '',
    end_time: '',
    uuid: '',
    isRecording: false,
    direction: '',
    phone_info_payload: null,
  });

  const setActiveNumber = (payload) => {
    setState((prev) => ({
      ...prev,
      active_number: payload.number,
      start_time: payload.start,
      uuid: payload.uuid,
      direction: payload?.direction,
    }));
  };
  const setEndTime = (value) => {
    setState((prev) => ({
      ...prev,
      end_time: value,
    }));
  };
  const clearCallSummary = () => {
    setState({
      active_number: '',
      start_time: '',
      end_time: '',
      uuid: '',
      isRecording: false,
      direction: '',
      phone_info_payload: null,
    });
  };
  const setIsCallRecording = (value) => {
    setState((prev) => ({ ...prev, isRecording: value }));
  };
  const setPhoneInfoPayload = (value) => {
    setState((prev) => ({ ...prev, phone_info_payload: value }));
  };
  const values = useMemo(
    () => ({
      ...state,
      setActiveNumber,
      setEndTime,
      clearCallSummary,
      setIsCallRecording,
      setPhoneInfoPayload,
    }),
    [state],
  );
  return (
    <CallSummaryContext.Provider value={values}>
      {children}
    </CallSummaryContext.Provider>
  );
};

export const useCallSummary = () => useContext(CallSummaryContext);
