import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { ivrList } from '../../api';

export default function useIVRList() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: ivrList,
    mutationKey: 'ivrList',

    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
