import React from 'react';
import { Controller } from 'react-hook-form';
import Switch from '../../../../comopnents/Switch';

const MissedCallSettings = ({ control, watch }) => {
  return (
    <div className="d--flex flex--column gap--lg">
      <div className="w--full d--flex gap--lg justify-content--start">
        <div className="label--control font--sm font--600 m-b--xs d--flex text--black">
          Enable
        </div>
        <label
          className="label--control gap--sm c--pointer font--sm font--500 m-b--xs d--flex align-items--center text--black"
          htmlFor="voicemail_email"
        >
          <Controller
            name={`status`}
            control={control}
            render={({ field }) => {
              return <Switch {...field} id={`status`} />;
            }}
          />
        </label>
      </div>
      {watch('status') ? (
        <div className="">
          {/* <label className="label--control font--sm font--500 m-b--xs  text--black d--flex align-items--center gap--sm ">
            SMS
          </label> */}
          <Controller
            name={`message`}
            control={control}
            render={({ field }) => {
              return (
                <textarea
                  {...field}
                  className="form--control w-max--450 w-min--450 h-max--106 h-min--106 radius--sm p--md  border-full--black-200 m-b--sm"
                  rows={5}
                />
              );
            }}
          />
        </div>
      ) : (
        <div className="w-max--450 w-min--450 h-max--106 h-min--106 m-b--sm p--md"></div>
      )}
    </div>
  );
};

export default MissedCallSettings;
