import { useMutation } from '@tanstack/react-query';
import { getPlans } from '../../api';

export default function useGetPlans() {
  const addAgencyRequest = useMutation({
    mutationFn: getPlans,
    mutationKey: 'getPlans',
 
  });
  return { ...addAgencyRequest };
}
