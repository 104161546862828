import React, { useEffect, useState } from 'react';
import LogoImgAdmin from '../../../assets/images/logo-admin.png';
import Button from '../../../comopnents/Button';
import useIcons from '../../../assets/icons/useIcons';
import { NavLink } from 'react-router-dom';

export const OuterHeader = ({ fromLogin = false }) => {
  const { MenuIcon } = useIcons();

  const [toggle, handleToggle] = useState(true);

  function StickToTop() {
    var header = document?.getElementById('sticky-header');
    var sticky = header?.offsetTop;

    if (header && !fromLogin) {
      if (window?.pageYOffset > sticky) {
        header.classList.add('headerOuter--sticky');
        header.classList.add('box-shadow--xs');
      } else {
        header.classList.remove('headerOuter--sticky');
        header.classList.remove('box-shadow--xs');
      }
    }
  }

  useEffect(() => {
    if (!fromLogin) {
      window.addEventListener('scroll', StickToTop);
      return () => window.removeEventListener('scroll', StickToTop, false);
    }
  }, []);

  return (
    <div
      className={`headerOuter position--relative w--full p-t--xl p-b--xl z-index--sm ${fromLogin ? 'bg--white-200' : ''
        }`}
      id="sticky-header"
    >
      <div className="container">
        <div className="w--full d--flex  align-items--center justify-content--between gap--lg flex-column--xs flex-column--sm  align-items-start--xs align-items-start--sm">
          <div className="logo w-max--200 ">
            <img src={LogoImgAdmin} alt="logo" className="w--full" />
          </div>

          <div
            onClick={(e) => handleToggle((prev) => !prev)}
            className="toggleBtn position--absolute bg--primary-100 text--purple border-full--black-100 w-min--40 w-max--40 h-min--40 h-max--40 d--flex align-items--center justify-content--center radius--sm"
          >
            <MenuIcon width={24} height={24} />
          </div>

          {toggle ? (
            <div
              data-dropdownslide="bottom"
              className="toggleData w--full d--flex align-items--center justify-content--center gap--xl navigation flex-column--xs flex-column--sm  align-items-start--xs align-items-start--sm"
            >
              <div className='w--full d--flex align-items--center justify-content--center gap--xl  flex-column--xs flex-column--sm  align-items-start--xs align-items-start--sm'>
                <NavLink
                  to={'/'}
                  className={({ isActive }) =>
                    `${isActive
                      ? 'text--primary border-bottom--primary'
                      : 'text--black-800 border-bottom--transparent'
                    } font--sm font--600 p--sm c--pointer navigationItems  border--2 `
                  }
                >
                  Home
                </NavLink>

                <NavLink
                  to={'/features'}
                  className={({ isActive }) =>
                    `${isActive
                      ? 'text--primary border-bottom--primary'
                      : 'text--black-800 border-bottom--transparent'
                    } font--sm font--600 p--sm c--pointer navigationItems  border--2 `
                  }
                >
                  Features
                </NavLink>
                {/* <NavLink
                to={'/products'}
                className={({ isActive }) =>
                  `${
                    isActive
                      ? 'text--primary border-bottom--primary'
                      : 'text--black-800 border-bottom--transparent'
                  } font--sm font--600 p--sm c--pointer navigationItems  border--2 `
                }
              >
                Products
              </NavLink> */}
                <NavLink
                  to={'/pricing'}
                  className={({ isActive }) =>
                    `${isActive
                      ? 'text--primary border-bottom--primary'
                      : 'text--black-800 border-bottom--transparent'
                    } font--sm font--600 p--sm c--pointer navigationItems  border--2 `
                  }
                >
                  Pricing
                </NavLink>

                <NavLink
                  to={'/company'}
                  className={({ isActive }) =>
                    `${isActive
                      ? 'text--primary border-bottom--primary'
                      : 'text--black-800 border-bottom--transparent'
                    } font--sm font--600 p--sm c--pointer navigationItems  border--2 `
                  }
                >
                  About Us
                </NavLink>
                <NavLink
                  to={'/contact-us'}
                  className={({ isActive }) =>
                    `${isActive
                      ? 'text--primary border-bottom--primary'
                      : 'text--black-800 border-bottom--transparent'
                    } font--sm font--600 p--sm c--pointer navigationItems  border--2 `
                  }
                >
                  Contact Us
                </NavLink>
              </div>
              <div data-dropdownslide="bottom" className='d--flex w-max--120 w--full'>
                <NavLink to={'/login'} className="w-max--120 w--full p-l--sm ">
                  <Button
                    type="submit"
                    size="md"
                    variant="primary"
                    color="white"
                    btnClasses="btn white--space-none"
                  >
                    Login
                  </Button>
                </NavLink>
              </div>
            </div>
          ) : null}

        </div>
      </div>
    </div>
  );
};
