import React from 'react';
import CallTab from './CallTab';
import { useAlert } from '../../../hooks/useAlert';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { contactActivityList } from '../../../api';
import Spinner from '../../../comopnents/Spinner';

const ContactActivity = () => {
  const { showAlert } = useAlert();
  let [searchParams] = useSearchParams();
  const uuid = searchParams?.get('contactId') ?? '';

  const { data, isFetching, isLoading } = useQuery({
    queryFn: ({ queryKey }) => contactActivityList(queryKey[1] || {}),
    queryKey: ['contactActivityList', { contact_uuid: uuid }],
    select: (data) => data?.data?.data,
    enabled: Boolean(uuid),
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });

  return (
    <div className="bg--white w--full radius--sm d--flex flex--column">
      <div className="w--full h--full">
        {isFetching || isLoading ? (
          <div className="d--flex align-items--center justify-content--center h--full w--full">
            <Spinner size="lg" />
          </div>
        ) : (
          <CallTab data={data} />
        )}
      </div>
    </div>
  );
};

export default ContactActivity;
