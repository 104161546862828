import moment from 'moment';

export const generateDateObjectFromTo = ({ dateType }) => {
  let object = { from: '', to: '' };
  switch (dateType) {
    case 'today':
      object = {
        from: moment(),
        to: moment(),
      };
      break;
    case 'yesterday':
      object = {
        from: moment().subtract(1, 'days'),
        to: moment().subtract(1, 'days'),
      };
      break;

    case '7_days':
      object = {
        from: moment().subtract(6, 'days'),
        to: moment(),
      };
      break;
    case '30_days':
      object = {
        from: moment().subtract(29, 'days'),
        to: moment(),
      };
      break;
    case 'this_month':
      object = {
        from: moment().startOf('month'),
        to: moment().endOf('month'),
      };
      break;
    case 'last_month':
      object = {
        from: moment().subtract(1, 'month').startOf('month'),
        to: moment().subtract(1, 'month').endOf('month'),
      };
      break;
    case 'week':
      object = {
        from: moment().startOf('week'),
        to: moment().endOf('week'),
      };
      break;
    default:
      object = { from: '', to: '' };
      break;
  }
  return {
    from: object.from ? object.from.format('YYYY-MM-DD') : '',
    to: object.to ? object.to.format('YYYY-MM-DD') : '',
  };
};
export const generateCustomDateObjectFromTo = ({ dateType }) => {
  let object = { from: '', to: '' };
  switch (dateType) {
    case 'Today':
      object = {
        from: moment(),
        to: moment(),
      };
      break;
    case 'Yesterday':
      object = {
        from: moment().subtract(1, 'days'),
        to: moment().subtract(1, 'days'),
      };
      break;

    case 'Last 7 Days':
      object = {
        from: moment().subtract(6, 'days'),
        to: moment(),
      };
      break;
    case 'Last 30 Days':
      object = {
        from: moment().subtract(29, 'days'),
        to: moment(),
      };
      break;
    case 'This Month':
      object = {
        from: moment().startOf('month'),
        to: moment().endOf('month'),
      };
      break;
    case 'Last Month':
      object = {
        from: moment().subtract(1, 'month').startOf('month'),
        to: moment().subtract(1, 'month').endOf('month'),
      };
      break;
    case 'This Week':
      object = {
        from: moment().startOf('week'),
        to: moment().endOf('week'),
      };
      break;
    default:
      object = { from: '', to: '' };
      break;
  }
  return {
    from: object.from ? object.from.format('YYYY-MM-DD') : '',
    to: object.to ? object.to.format('YYYY-MM-DD') : '',
  };
};

export const generateTimeArray = () => {
  const timeArray = [];

  for (let i = 0; i < 24; i++) {
    const unixTimestamp = moment().hour(i).minute(0).second(0).unix();
    const label = moment.unix(unixTimestamp).format('hh:mm A');
    timeArray.push({
      value: unixTimestamp,
      label: label,
      period: i < 12 ? 'AM' : 'PM',
    });
  }

  const amArray = timeArray.filter((time) => time.period === 'AM');
  const pmArray = timeArray.filter((time) => time.period === 'PM');

  return { amArray, pmArray, timeArray };
};
