import React from 'react';
// import useIcons from '../../../../assets/icons/useIcons';
// import doctorImg from '../../../../assets/images/doctor1.jpg';
import moment from 'moment';
import MMSPreview from '../MMSPreview';

var emojiRegex = /([\uD800-\uDBFF][\uDC00-\uDFFF])/g;
const urlPattern =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
const IncomingMessageItem = ({
  sms = {},
  lastElementRef = null,
  firstItemRef,
}) => {
  const { media = '', media_type = '', type = '' } = sms || {};
  return (
    <div
      className="incomingMsg w--full m-b--lg"
      ref={lastElementRef || firstItemRef}
      id={sms?.uuid}
    >
      <div className="w--sm w-max--500">
        <div className="w-max--500   w--full d--flex position--relative ">
          <div className="w--auto w-min--75 d--flex flex--column bg--primary radius--lg p--lg ">
            <div
              className="white--space-pre-wrap word-break--all flex--wrap text--white line-height--1-dot-5"
              dangerouslySetInnerHTML={{
                __html: sms?.text
                  ?.replace(
                    emojiRegex,
                    '<span class="d--flex word-break--all font--lg">$1</span>',
                  )
                  .replace(
                    urlPattern,
                    '<a href=$1 target="_blank" class="text--underline" >$1</a>',
                  ),
              }}
            />
            {type === 'MMS' && (
              <MMSPreview media={media} media_type={media_type} />
            )}
          </div>
        </div>

        <div className="p-l--sm w--full text--black-600 m-t--xs d--flex align-items--center">
          <span className=" text--black-600 font--xs">
            {moment(sms?.created_at).format('hh:mm a')}
          </span>
          {/* <div className="text--black-600 c--pointer w--full font--xs font--300 w-min--36 w-max--36  radius--sm d--flex align-items--center justify-content--center">
            <MoreHIcon width={16} height={16} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default IncomingMessageItem;
