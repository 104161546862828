import React from 'react';
import IncomingCallScreen from './IncomingCallScreen';
import OutgoingCallScreen from './OutgoingCallScreen';

const RingingScreen = ({ activeCall = {}, setaction }) => {
  const { _direction } = activeCall;

  return (
    <div className="w--full d--flex align-items--center justify-content--center flex--column">
      {/* {_direction === 'inbound' && <div className="h-min--250"></div>} */}
      <div className="w--full d--flex align-items--center justify-content--center flex--column gap--lg  ">
        <div className="w--full d--flex align-items--center justify-content--center gap--sm">
          {_direction === 'inbound' && (
            <IncomingCallScreen setaction={setaction} activeCall={activeCall} />
          )}
          {_direction === 'outbound' && (
            <OutgoingCallScreen setaction={setaction} activeCall={activeCall} />
          )}
        </div>
      </div>
    </div>
  );
};

export default RingingScreen;
