import moment from 'moment';
import React, { useRef } from 'react';
import Activity from './Activity';

const TimeLine = ({
  title = '',
  array = [],
  accessLabel = '',
  start = null,
  end = null,
  showActivity = false,
  allActivity = [],
  currentActivity = {},
}) => {
  const timelineRef = useRef();

  const startdateTime =
    moment(currentActivity?.startTimestamp).format('YYYY-MM-DD') || '';
  const givenDate = moment(startdateTime);
  const today = moment();

  if (!start || !end) {
    return;
  }

  return (
    <div className="h--full">
      {showActivity ? (
        <div className="w--full d--flex flex--column w-max--200 w-min--200 h--full align-items--center">
          <div className="text--primary font--sm font--500  h-min--36 text--c">
            {givenDate.isSame(today, 'day') ? 'Today' : startdateTime}
          </div>

          <div className="position--relative d--flex align-items--center flex--column h--full">
            {currentActivity &&
            currentActivity?.activity &&
            currentActivity?.activity?.length > 0
              ? currentActivity?.activity?.map((a) => {
                  return (
                    <Activity activity={a || {}} start={start} end={end} />
                  );
                })
              : null}
            {array && array?.length > 0
              ? array.map((time, index) => {
                  if (index === array.length - 1) {
                    return (
                      <div className="d--flex justify-content--center align-items--start position--relative">
                        <div className="text--sm font--500 position--absolute left--0 top---6 p-l--2xl">
                          {time?.[accessLabel] ?? ''}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className="h-min--60 h--full  d--flex justify-content--center align-items--start position--relative">
                      <div className="text--sm font--500 position--absolute left--0 top---6 p-l--2xl">
                        {time?.[accessLabel] ?? ''}
                      </div>
                      <div className="position--relative h--full">
                        <div className="w-min--8 bg--primary h-min--1 position--absolute right---4"></div>
                        <div className="h-min--60 h--full bg--primary w-min--1 w-max--1"></div>
                        <div className="w-min--8 bg--primary h-min--1 position--absolute right---4"></div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : (
        <div className="w--full d--flex flex--column w-max--200 w-min--200 h--full">
          <div className="text--primary font--sm font--500  h-min--36 text--c">
            {title}
          </div>
          <div
            className="w--full d--flex flex--column w-max--200 w-min--200 h--full"
            ref={timelineRef}
          >
            {array && array?.length > 0
              ? array.map((time, index) => {
                  if (index === array.length - 1) {
                    return (
                      <div className="d--flex justify-content--center align-items--start position--relative">
                        <div className="text--sm font--500 position--absolute left--0 top---6 p-l--2xl">
                          {time?.[accessLabel] ?? ''}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className="h-min--60 h--full  d--flex justify-content--center align-items--start position--relative">
                      <div className="text--sm font--500 position--absolute left--0 top---6 p-l--2xl">
                        {time?.[accessLabel] ?? ''}
                      </div>
                      <div className="position--relative h--full">
                        <div className="w-min--8 bg--primary h-min--1 position--absolute right---4"></div>
                        <div className="h-min--60 h--full bg--primary w-min--1 w-max--1"></div>
                        <div className="w-min--8 bg--primary h-min--1 position--absolute right---4"></div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeLine;
