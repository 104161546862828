import { useQuery } from '@tanstack/react-query';
import { getNameNumberFilter } from '../../api';

export default function useNameNumberFilterListing() {
  const { data: nameNumberList = [], ...rest } = useQuery({
    queryFn: () => getNameNumberFilter(),
    queryKey: ['getNameNumberFilter'],
    refetchOnWindowFocus: false,
    retry: false,
    gcTime: Infinity,
    refetchOnMount: false,
    select: (data) => data?.data?.data ?? [],
  });

  return { ...rest, nameNumberList };
}
