import React from 'react';
import FormInput from '../../../comopnents/FormInput';
import FormSelect from '../../../comopnents/FormSelect';
import Switch from '../../../comopnents/Switch';
import Button from '../../../comopnents/Button';
import useCountryList from '../../../hooks/useCountryList';
import { Controller } from 'react-hook-form';
import { businessHours, initialValues } from './constants';

const RenderCountryItem = ({ item }) => {
  return (
    <option vclassName="text--black" key={item.isoCode} value={item.isoCode}>
      {item.name}
    </option>
  );
};

const RenderTimeZoneItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.zoneName}
      value={item.zoneName}
    >
      {item.zoneName}
    </option>
  );
};

export default function CustomHourForm({
  watch,
  register,
  clearErrors,
  errors,
  control,
  handleClose = () => null,
  setValue,
}) {
  const countries = useCountryList();

  const selectedCountry = watch(
    'forward_call_actions.business_hours_operations.country',
  );

  const timezones = selectedCountry
    ? countries?.find((country) => country.isoCode === selectedCountry)
        ?.timezones
    : [];

  const cancelHandler = () => {
    setValue(
      'forward_call_actions.business_hours_operations',
      initialValues?.forward_call_actions?.business_hours_operations,
    );
    handleClose();
  };
  return (
    <div className="w--full h--full">
      <div className="overflow--auto dialogScroll d--flex flex--column gap--lg p--md">
        <div className="w--full">
          <Controller
            name="forward_call_actions.business_hours_operations.timezone"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                options={timezones || []}
                renderOption={RenderTimeZoneItem}
                placeholder="Please select timezone"
                label="Timezone"
                error={
                  errors?.forward_call_actions?.business_hours_operations
                    ?.timezone?.message
                }
              />
            )}
          />
        </div>
        <div className="w--full">
          <h5 className="font--600 font--sm m-b--md">Set Business Hour</h5>
          <div className="d--flex flex--column w--full gap--lg">
            {businessHours &&
              businessHours.map(({ label, id }) => {
                return (
                  <div
                    className="w--full d--flex gap--md align-items--center"
                    key={id}
                  >
                    <div className="w--full d--flex gap--xs font--600 font--sm">
                      <Controller
                        name={`forward_call_actions.business_hours_operations.custom_hours.${id}.open`}
                        control={control}
                        render={({ field }) => {
                          return (
                            <Switch
                              {...field}
                              error={
                                errors?.forward_call_actions
                                  ?.business_hours_operations?.custom_hours?.[
                                  id
                                ].open?.message
                              }
                              id={`forward_call_actions.business_hours_operations.custom_hours.${id}.open`}
                            />
                          );
                        }}
                      />
                      {label}
                    </div>
                    <div className="w--full d--flex gap--sm align-items--center">
                      <label className="font--sm font--400 text--grey">
                        From
                      </label>
                      <Controller
                        name={`forward_call_actions.business_hours_operations.custom_hours.${id}.start`}
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            type="time"
                            error={
                              errors?.forward_call_actions
                                ?.business_hours_operations?.custom_hours?.[id]
                                .start?.message
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="w--full d--flex gap--sm align-items--center">
                      <label className="font--sm font--400 text--grey">
                        To
                      </label>
                      <Controller
                        name={`forward_call_actions.business_hours_operations.custom_hours.${id}.end`}
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            type="time"
                            error={
                              errors?.forward_call_actions
                                ?.business_hours_operations?.custom_hours?.[id]
                                .end?.message
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => cancelHandler()}
        >
          Cancel
        </Button>

        <Button
          type="button"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          onClick={() => handleClose()}
          // disabled={loading}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
