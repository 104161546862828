import React from 'react';
import useIcons from '../../assets/icons/useIcons';
import Button from '../../comopnents/Button';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
  const { SearchIcon } = useIcons();
  const navigate = useNavigate();
  return (
    <div className="d--flex align-items--center justify-content--center w--full h--full flex--column gap--md">
      <span className=" text--black-100">
        <SearchIcon height={100} width={100} />
      </span>
      <h4 className="font--2xl font--600 m-b--0">Page Not Found</h4>
      <p className="m-b--0 text--c text--black-500">
        We are sorry the page you requested could not be found <br />
        please go back to the homepage
      </p>
      <div className="w--full w-max--150">
        <Button
          variant="primary"
          btnClasses="btn"
          rounded
          onClick={() => navigate('/')}
        >
          Go Home
        </Button>
      </div>
    </div>
  );
}
