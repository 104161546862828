import { useMutation } from '@tanstack/react-query';
import { didNumbersList } from '../../api';
import { useAlert } from '../useAlert';

export default function useDidNumbersList() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: didNumbersList,
    mutationKey: 'didNumbersList',
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
