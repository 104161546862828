import { assignDIDList } from '../../api';
import { useQuery } from '@tanstack/react-query';
import { useAlert } from '../useAlert';

export default function useAssignDIDList(uuid = '') {
  const { showAlert } = useAlert();
  const Request = useQuery({
    queryFn: () => assignDIDList(uuid),
    queryKey: ['assignDIDList', uuid],
    select: (data) => data?.data?.data || [],
    enabled: Boolean(uuid),
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
