import React from 'react';

import { OuterHeader } from './OuterHeader';
import { OuterFooter } from './OuterFooter';
import { EconomyGuides } from './EconomyGuides';
import { Outlet, useLocation } from 'react-router-dom';

const pathsForEconomyGuide = ['signup', 'verifyotp', 'payment-method'];
export default function Website() {
  const location = useLocation();
  const isHideEconomyGuid = pathsForEconomyGuide.includes(
    location.pathname?.split('/')?.[1],
  );
  return (
    <div className="w--full bg--white h--full position--relative outerPage">
      <OuterHeader />
      <div className='outerMainContent w--full'>

        <Outlet />
      </div>

      {isHideEconomyGuid ? null : <EconomyGuides />}
      <OuterFooter />
    </div>
  );
}
