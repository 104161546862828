import React, { useEffect } from 'react';
// import bannerImg from '../../../assets/images/web/banner-img.jpg';
import bannerImg from '../../../assets/images/web/bannerImg.svg';

import tabImg from '../../../assets/images/web/tab-img.svg';

import callImg1 from '../../../assets/images/web/call-1.jpg';
import callImg2 from '../../../assets/images/web/call-2.jpg';
import callImg3 from '../../../assets/images/web/call-3.jpg';
import callImg4 from '../../../assets/images/web/call-4.jpg';
import contactBanner from '../../../assets/images/web/contactbanner.png';
import multiTranslate from '../../../assets/images/web/multiTranslate.svg';
import Button from '../../../comopnents/Button';
import useIcons from '../../../assets/icons/useIcons';
import { NavLink } from 'react-router-dom';
import { getOperatingSystem } from '../../../helpers/functions';

const Home = () => {
  const {
    CollaborateIcon,
    CommunicationIcon,
    AutomationIcon,
    NextArrowIcon,
    PrevArrowIcon,
    CommaLeftIcon,
    CommaRightIcon,
    TryAngleIcon,
    WaveIcon,
    ArrowLongUpIcon,
    SquareFillIcon,
    PhoneSystemIcon,
    ContactCenterIcon,
    SalesServicesIcon,
    SquareFill2Icon,
    Curve2Icon,
    DotsMoreIcon,
  } = useIcons();
  function handleDownloadApp() {
    switch (getOperatingSystem()) {
      case 'Windows':
        window.open(process.env.REACT_APP_WINDOWS_APP_URL, '_blank');
        break;
      case 'iOS':
        window.open(
          'https://apps.apple.com/us/app/nextere-voice/id6497335247',
          '_blank',
        );
        break;
      case 'Android':
        window.open(
          'https://play.google.com/store/apps/details?id=com.nextere',
          '_blank',
        );
        break;
      case 'macOS':
        window.open(process.env.REACT_APP_MAC_APP_URL, '_blank');
        break;

      default:
        break;
    }
  }
  return (
    <div className="w--full mainHomePage">
      <div className="mainBanner section position--relative z-index--1 ">
        <div className="container">
          <div className="w--full d--flex align-items--center gap--3xl flex-column--xs flex-column--sm ">
            <div className="w--full gap--lg d--flex flex--column">
              <div className="triangle position--relative">
                <div className="text--primary position--absolute animate mover">
                  <TryAngleIcon width={50} height={45} />
                </div>
                <div className="bg--primary-100 w-min--50 h-min--50 w-max--50 radius--full position--absolute bounce--right animate"></div>
              </div>
              <h2 className="font--52 font--700 line-height--1-dot-2">
                AI-Powered customer <br></br> communication
              </h2>
              <p className="font--md text--grey line-height--1-dot-5">
                Revolutionize better customer communication across channels for
                seamless interactions with your team and customers- All in one
                platform.
              </p>
              <div className="d--flex gap--md">
                <div className="w-max--120 w--full">
                  <NavLink
                    to={'/pricing'}
                    className="w-max--120 w--full p-l--sm "
                  >
                    <Button
                      type="submit"
                      size="md"
                      variant="primary"
                      color="white"
                      btnClasses="btn white--space-none"
                    >
                      Get Started
                    </Button>
                  </NavLink>
                </div>
                {getOperatingSystem() !== 'Unknown' && (
                  <div className="w-max--170 w--full">
                    <NavLink to={'#'} className="w-max--120 w--full p-l--sm ">
                      <Button
                        type="button"
                        size="md"
                        variant="primary"
                        color="white"
                        btnClasses="btn white--space-none"
                        onClick={handleDownloadApp}
                      >
                        Download for {getOperatingSystem()}
                      </Button>
                    </NavLink>
                  </div>
                )}
              </div>
              {/* <div className="w--full arrowLongUpIcon position--relative">
                <div className="animate mover">
                  <ArrowLongUpIcon width={50} height={90} />
                </div>
                <div className="d--flex w-max--150 font--md text--black">
                  Let’s talk about your query.
                </div>
              </div> */}
            </div>
            <div className="w--full d--flex justify-content--end position--relative">
              <div className="circle position--relative">
                <div className="w-min--12 w-max--12 h-min--12 h-max--12 w--full h--full d--flex bg--success circle--purple position--absolute radius--full animate bounce--right"></div>
                <div className="w-min--12 w-max--12 h-min--12 h-max--12 w--full h--full d--flex bg--primary circle--primary position--absolute radius--full animate bounce--left"></div>
              </div>

              <img src={bannerImg} alt="bannerImg" className="w--full" />

              <div className="triangle bottom position--absolute">
                <div className="text--primary position--absolute animate mover">
                  <div className="border-full--success w-min--50 h-min--50 w-max--50 radius--full position--absolute mover animate"></div>
                </div>
                <div className="bg--success-100 w-min--50 h-min--50 w-max--50 radius--full position--absolute bounce--right animate"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="waveIcon position--absolute text--purple animate bounce--left">
          <WaveIcon width={120} height={60} />
        </div>
      </div>
      <div className="canDo section position--relative">
        <div className="bg--primary-100 border-full--purple border--2 w-min--12 h-min--12 w-max--12 radius--full position--absolute animationOne animate"></div>
        <div className="container">
          <div className="w--full d--flex gap--3xl justify-content--between align-items--center flex-column--xs flex-column--sm ">
            <div className="w--full order-xs--2">
              <div className="w--full d--flex justify-content--end bg--primary-200 p--md radius--md">
                <img src={tabImg} alt="bannerImg" className="w--full" />
              </div>
            </div>
            <div className="w--full d--flex align-items--end p-l--2xl p-l-0--xs ">
              <div className="w--full  w-max--550 p-l--2xl p-l-0--xs position--relative">
                <div className="bigCircle position--absolute">
                  <div className="bg--primary-100 w-min--60 h-min--60 w-max--60 radius--full position--absolute mover animate"></div>
                </div>
                <div className="bigCircle right position--absolute">
                  <div className="bg--primary-100 w-min--40 h-min--40 w-max--40 radius--full position--absolute mover animate"></div>
                </div>
                <h3 className="font--42 font--700 line-height--1-dot-2 m-b--lg">
                  What you can do with Nextere Voice
                </h3>

                <div className="d--flex flex--column gap--lg tabs w--full">
                  <div className="d--flex align-items--center gap--md p--md radius--md c--pointer tabItem border-full--purple bg--primary-100 ">
                    <div className="w-max--100 w--full d--flex align-items--center justify-content--center">
                      <CollaborateIcon />
                    </div>
                    <div className="w--full d--flex gap--sm flex--column ">
                      <h4 className="font--lg m-b--0 text--purple">
                        Collaborate
                      </h4>
                      <p className="font--md m-b--0 text--black-600 line-height--1-dot-5">
                        We believe in the power of collaboration, leadership,
                        and teamwork. Enhance efficiency and customer
                        communication with seamless interactions to resolve
                        their queries
                      </p>
                    </div>
                  </div>
                  <div className="d--flex align-items--center gap--md p--md radius--md c--pointer tabItem border-full--black-200  ">
                    <div className="w-max--100 w--full d--flex align-items--center justify-content--center">
                      <AutomationIcon />
                    </div>
                    <div className="w--full d--flex gap--sm flex--column ">
                      <h4 className="font--lg m-b--0 text--black-800">
                        Automation
                      </h4>
                      <p className="font--md m-b--0 text--black-600 line-height--1-dot-5">
                        Embrace an automated future with us and gain valuable
                        insights. Simplify your to-do list and save your time.
                      </p>
                    </div>
                  </div>
                  <div className="d--flex align-items--center gap--md p--md radius--md c--pointer tabItem border-full--black-200  ">
                    <div className="w-max--100 w--full d--flex align-items--center justify-content--center">
                      <CommunicationIcon />
                    </div>
                    <div className="w--full d--flex gap--sm flex--column ">
                      <h4 className="font--lg m-b--0 text--black-800">
                        Communication
                      </h4>
                      <p className="font--md m-b--0 text--black-600 line-height--1-dot-5">
                        Empower your business communication with interactive
                        features like in-chat messages
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="connectAnyTime section position--relative overflow--hidden">
        <div className="bigCircle position--absolute">
          <div className="bg--primary-100 w-min--200 h-min--200 w-max--200 radius--full position--absolute mover animate"></div>
        </div>
        <div className="container">
          <div className="w--full d--flex align-items--center gap--3xl flex-column--xs flex-column--sm  ">
            <div className="w--full gap--lg d--flex flex--column position--relative">
              <h3 className="font--42 font--700 line-height--1-dot-2 w-max--500">
                Connect, Anywhere, Anytime, and Enjoy.
              </h3>
              <p className="font--md text--grey line-height--1-dot-5">
                Experience a great future with our innovative and interactive
                communication platform across the world. Enhance customer
                engagement with seamless communication.
              </p>
              <div className="w-max--120 w--full">
                <NavLink
                  to={'/pricing'}
                  className="w-max--120 w--full p-l--sm "
                >
                  <Button
                    type="submit"
                    size="md"
                    variant="purple"
                    color="white"
                    btnClasses="btn white--space-none"
                  >
                    Get Started
                  </Button>
                </NavLink>
              </div>
              <div className="smallCircle position--absolute">
                <div className="bg--primary-100 w-min--100 h-min--100 w-max--100 radius--full position--absolute mover animate"></div>
              </div>
            </div>
            <div className=" w--full d--flex justify-content--end ">
              <div className="connectAnyTimeBox w--full d--flex justify-content--center align-items--center bg--primary p--md radius--md h-min--600 ">
                <div className="circleDashed w--full h--full w-min--400 w-max--400 h-min--400 h-max--100 border--2 border-full--white  radius--full position--relative">
                  <div className="w--full h--full groupImg">
                    <div class="call-img call-top position--absolute animate mover ">
                      <img
                        src={callImg3}
                        alt="bannerImg"
                        className="w--full w-min--150 w-max--150 h-min--200 radius--sm"
                      />
                    </div>
                    <div class="call-img call-right position--absolute animate bounce--right ">
                      <img
                        src={callImg2}
                        alt="bannerImg"
                        className="w--full w-min--150 w-max--150 h-min--200 radius--sm"
                      />
                    </div>
                    <div class="call-img call-bottom position--absolute animate mover ">
                      <img
                        src={callImg4}
                        alt="bannerImg"
                        className="w--full w-min--150 w-max--150 h-min--200 radius--sm"
                      />
                    </div>
                    <div class="call-img call-left position--absolute animate bounce--left ">
                      <img
                        src={callImg1}
                        alt="bannerImg"
                        className="w--full w-min--150 w-max--150 h-min--200 radius--sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w--full section contactSec  overflow--hidden">
        <div className="container">
          <div className="w--full d--flex gap--2xl align-items--center  flex-column--xs ">
            <div className="w--full d--flex justify-content--end imgSec position--relative z-index--2 ">
              <div className="dotsIcon  animate mover text--success position--absolute z-index---1">
                <DotsMoreIcon width={66} height={100} />
              </div>
              <img src={multiTranslate} alt="bannerImg" className="w--full " />
              <div className="curve2Icon  animate mover text--success position--absolute z-index---1">
                <Curve2Icon width={66} height={166} />
              </div>
            </div>
            <div className="w--full d--flex flex--column gap--lg contentText position--relative">
              {/* <div className="circleBox position--absolute">
                <div className="text--success position--absolute animate mover">
                  <div className="border-full--success w-min--50 h-min--50 w-max--50 radius--full position--absolute mover animate"></div>
                </div>
                <div className="bg--success-100 w-min--50 h-min--50 w-max--50 radius--full position--absolute bounce--right animate"></div>
              </div> */}
              {/* <h4 className="font--2xl font--600 text--secondary">
                Contact Us
              </h4> */}
              <h2 className="font--52 font--700 line-height--1-dot-2">
                Automate insights with AI-powered Conversation Intelligence
              </h2>
              <p className="font--md text--grey line-height--2">
                Unlock the intelligence you need to market with confidence.
                Nextere Voice's Conversation Intelligence® creates transcripts,
                highlights keywords, and automatically tags and qualifies leads
                so your team can focus on winning the sale.
              </p>
            </div>
          </div>
        </div>
        <div className="bigCircle position--absolute z-index---0">
          <div className="bg--primary-100 w-min--200 h-min--200 w-max--200 radius--full position--absolute mover animate"></div>
        </div>
      </div>
      <div className="impactFeatures section p-t--80 position--relative overflow--hidden bg--contrast">
        <div className="squareIcon right bottom position--absolute text--purple animate bounce--right">
          <SquareFillIcon width={200} height={200} />
        </div>
        <div className="container">
          <div className="w--full d--flex flex--column gap--xl">
            <div className="w--full  d--flex   justify-content--center ">
              <div className="w--full w-max--800 gap--lg d--flex flex--column">
                <h2 className="font--42 font--700 line-height--1-dot-2 text--c">
                  Why Nextere Voice.com
                </h2>
                <p className="font--md text--grey line-height--2 text--c">
                  Nextere Voice provides the world class telephony system
                </p>
              </div>
            </div>
            <div className="w--full d--grid grid--col-3 gap--lg gridSystem z-index--9 flex-column--xs">
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md ">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <PhoneSystemIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600 height--1-dot-5">
                  Affordable Service & Free Phone Numbers
                </h5>
                <p className="font--md text--grey line-height--2 ">
                  Users start as low as $12.74 per month with premium and free
                  numbers available. There’s no contract and no commitment.
                  Compare plans now!
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md ">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <ContactCenterIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600 height--1-dot-5">
                  Local & International Numbers
                </h5>
                <p className="font--md text--grey line-height--2 ">
                  Pay U.S. local rates to landline numbers in: Canada, France,
                  Germany, Israel, Italy, Puerto Rico, UK, USA and iNum global
                  numbers
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
              <div className="w--full border-full--black-100 radius--sm p--xl bg--white border--dashed d--flex flex--column gap--md ">
                <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full border-full--secondary text--secondary d--flex align-items--center justify-content--center">
                  <SalesServicesIcon width={40} height={40} />
                </div>
                <h5 className="font--lg font--600 line-height--1-dot-5">
                  Customize or Keep Your Current Number
                </h5>
                <p className="font--md text--grey line-height--2 ">
                  Choose from local, toll-free, and vanity numbers. Like your
                  current number? Bring it along! You can even save 15% when you
                  pay annually!
                </p>
                {/* <div className="btnSec transition">
                  <Button
                    variant="transparent"
                    color="secondary"
                    btnClasses="border-0 align-items--center d--flex gap--xs"
                  >
                    Read More <NextArrowIcon />
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="squareIcon left bottom position--absolute text--success animate mover">
          <SquareFill2Icon width={200} height={200} />
        </div>
      </div>
      {/* <div className="testimonialClient section position--relative">
        <div className="container">
          <div className="w--full d--flex justify-content--center align-items--center flex--column">
            <div className="w--full gap--lg d--flex flex--column  w-max--600 text--c">
              <h3 className="font--42 font--700 line-height--1-dot-2  text--c">
                Why Nextere Voice.com
              </h3>
              <p className="font--md text--grey line-height--1-dot-5">
                Our Clients send us bunch of smiles with our services and we
                love them
              </p>
            </div>
            <div className="d--full p--2xl bg--white radius--md box-shadow text--c w-max--900 gap--lg text--c d--flex flex--column m-t--lg position--relative">
              <h4 className="font--lg font--600 m-b--lg">
                Patience. Infinite patience
              </h4>
              <p className="font--md text--black-600 position--relative line-height--2">
                <span className="position--absolute text--primary commaLeftIcon">
                  <CommaLeftIcon />
                </span>
                Patience. Infinite patience. No shortcuts. Very well thought out
                and articulate communication. Clear milestones, deadlines, and
                fast work. Even if the client is being careless. The best
                part...always solving problems with great original ideas!.
                <span className="position--absolute text--primary commaRightIcon">
                  <CommaRightIcon />
                </span>
              </p>
              <div className="w--full d--flex flex--column ">
                <div className="d--flex w--full align-items--center justify-content--center gap--md font--md">
                  <div className="w-min--50 w-max--50 h-min--50 h-max--50 ">
                    <img
                      src={tabImg}
                      alt="tabImg"
                      className="w--full radius--full"
                    />
                  </div>
                  <div className="text--c font--600">David Ferry</div>
                </div>
                <div className="text--c font--md text--black-600 font--400 line-height--1-dot-5">
                  Director at Chobham Manor
                </div>
              </div>
              <div className="prevArrow c--pointer w-min--50 h-min--50 h-max--50 w-max--50 bg--primary d--flex align-items--center justify-content--center text--white radius--full position--absolute">
                <PrevArrowIcon />
              </div>
              <div className="nextArrow c--pointer w-min--50 h-min--50 h-max--50 w-max--50 bg--primary d--flex align-items--center justify-content--center text--white radius--full position--absolute">
                <NextArrowIcon />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
