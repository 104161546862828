export const routes = {
  LOGIN: {
    METHOD: 'POST',
    URL: 'login',
  },
  LOGOUT: {
    METHOD: 'POST',
    URL: 'logout',
  },
  DID_LIST: {
    METHOD: 'POST',
    URL: 'did/listing',
  },
  ADD_CARD: {
    METHOD: 'POST',
    URL: '/plan/add-card',
  },
  DELETE_CARD: {
    METHOD: 'DELETE',
    URL: 'user/card/delete',
  },
  SEND_OTP: {
    METHOD: 'POST',
    URL: '/send-otp',
  },
  RESEND_OTP: {
    METHOD: 'POST',
    URL: '/resend-otp',
  },
  PORTING_LIST: {
    METHOD: 'POST',
    URL: 'porting/listing',
  },
  PORTING_REQUEST: {
    METHOD: 'POST',
    URL: '/porting',
  },
  GET_PORTING_REQUEST_DATA: {
    METHOD: 'GET',
    URL: 'porting',
  },
  UPDATE_PORTING_REQUEST: {
    METHOD: 'POST',
    URL: 'porting',
  },
  SET_PASSWORD: {
    METHOD: 'PUT',
    URL: 'user/change-password',
  },
  DID_COUNTRY_LIST: {
    METHOD: 'POST',
    URL: 'did/country-list',
  },
  SIGN_UP: {
    METHOD: 'POST',
    URL: '/signup',
  },

  UPGRADE_PLAN: {
    METHOD: 'POST',
    URL: '/plan/upgrade',
  },
  DID_US_STATE_LIST: {
    METHOD: 'GET',
    URL: 'did/state',
  },
  DID_STATE_CITY_LIST: {
    METHOD: 'GET',
    URL: 'did/state-city-list',
  },
  DID_NUMBER_LIST: {
    METHOD: 'GET',
    URL: 'did/number-list',
  },
  GET_PLANS: {
    METHOD: 'GET',
    URL: 'plan/get-plans',
  },
  POST_PLAN: {
    METHOD: 'POST',
    URL: 'plan/get-plan',
  },
  DID_DETAILS: {
    METHOD: 'GET',
    URL: 'did/selected-did-details',
  },
  DID_NUMBER_PURCHASE: {
    METHOD: 'POST',
    URL: 'did/create-order',
  },
  CHECK_FREE_DID: {
    METHOD: 'GET',
    URL: 'did/free-did',
  },
  USER_DEAILS: {
    METHOD: 'GET',
    URL: 'user/get-user-details',
  },
  MEMBER_LISTING: {
    METHOD: 'POST',
    URL: 'member/listing',
  },
  CONTACT_LIST: {
    METHOD: 'POST',
    URL: 'contact/listing',
  },
  CONTACT_DELETE: {
    METHOD: 'DELETE',
    URL: 'contact/delete',
  },
  MEMBER_SIGNUP: {
    METHOD: 'POST',
    URL: 'member/signup',
  },
  USER_SEND_ACCOUNT_VERIFICATION_LINK: {
    METHOD: 'POST',
    URL: 'user/send-account-verification-link',
  },
  USER_VERIFY_ACCOUNT: {
    METHOD: 'POST',
    URL: 'user/verify-account',
  },
  RESET_PASSWORD: {
    METHOD: 'POST',
    URL: 'user/reset-password',
  },
  MEMBER_ACTIVATE: {
    METHOD: 'POST',
    URL: 'member/activate',
  },
  MEMBER_DEACTIVATE: {
    METHOD: 'POST',
    URL: 'member/deactivate',
  },
  AGENCY_ACTIVATE: {
    METHOD: 'POST',
    URL: 'agency/activate',
  },
  AGENCY_DEACTIVATE: {
    METHOD: 'POST',
    URL: 'agency/deactivate',
  },
  CONTACT_CREATE: {
    METHOD: 'POST',
    URL: 'contact/upsert',
  },
  MEMBER_DELETE: {
    METHOD: 'DELETE',
    URL: 'member/delete',
  },
  MEMBER_ASSIGN_UNASSIGN_DID: {
    METHOD: 'POST',
    URL: 'member/assign-unassign-did',
  },
  MEMBER_DEFAULT_DID: {
    METHOD: 'POST',
    URL: 'member/set-default-did',
  },
  SET_FORWARDING: {
    METHOD: 'POST',
    URL: 'did/set-forwarding',
  },
  SET_SETTINGS: {
    METHOD: 'POST',
    URL: 'did/set-settings',
  },
  GET_SETTINGS: {
    METHOD: 'POST',
    URL: 'did/get-settings',
  },
  REMOVE_FORWARDING: {
    METHOD: 'POST',
    URL: 'did/remove-forwarding',
  },
  CALL_HISTORY: {
    METHOD: 'POST',
    URL: 'calls/history',
  },
  CALL_STATUS: {
    METHOD: 'POST',
    URL: 'get-piechart-stats',
  },
  GET_DID_DETAILS: {
    METHOD: 'POST',
    URL: 'did/details',
  },
  USER_CREDENTIALS: {
    METHOD: 'GET',
    URL: '/user/user-credential',
  },
  UPLOAD_MEDIA: {
    METHOD: 'POST',
    URL: 'upload/url',
  },
  GET_MEDIA: {
    METHOD: 'GET',
    URL: 'signed-url',
  },
  DELETE_MEDIA: {
    METHOD: 'DELETE',
    URL: 'delete-object',
  },
  PROFILE_UPDATE: {
    METHOD: 'PUT',
    URL: 'user/profile-update',
  },
  PROFILE_PIC_DELETE: {
    METHOD: 'DELETE',
    URL: 'user/profile-pic-delete',
  },
  DASHBOARD_STATS: {
    METHOD: 'GET',
    URL: 'get-dashboard-stats',
  },
  CHECK_CONTACT: {
    METHOD: 'POST',
    URL: 'contact/check-contact',
  },

  //Agency Apis
  ADD_AGENCY: {
    METHOD: 'POST',
    URL: 'agency/upsert',
  },
  AGENCY_LIST: {
    METHOD: 'POST',
    URL: 'agency/listing',
  },
  AGENCY_DELETE: {
    METHOD: 'POST',
    URL: 'agency/delete',
  },
  AGENCY_MEMBER_LIST: {
    METHOD: 'POST',
    URL: 'agency/get-agency-member-list',
  },
  UNASSIGN_AGENCY_DID: {
    METHOD: 'POST',
    URL: 'did/unassign-agency-did',
  },
  //Ring Group Api
  RING_GROUP_LIST: {
    METHOD: 'POST',
    URL: 'ring_group/listing',
  },
  RING_GROUP_CREATE: {
    METHOD: 'POST',
    URL: 'ring_group/create',
  },
  RING_GROUP_EDIT: {
    METHOD: 'POST',
    URL: 'ring_group/edit',
  },
  RING_GROUP_DELETE: {
    METHOD: 'DELETE',
    URL: 'ring_group/delete',
  },
  //Greeting Apis
  GREETING_LIST: {
    METHOD: 'POST',
    URL: 'settings/greetings-listing',
  },
  GREETING_CREATE: {
    METHOD: 'POST',
    URL: 'settings/greetings-create',
  },
  GREETING_EDIT: {
    METHOD: 'POST',
    URL: 'settings/greetings-edit',
  },
  GREETING_DELETE: {
    METHOD: 'DELETE',
    URL: 'settings/greetings-delete',
  },
  AGENT_LIST: {
    METHOD: 'POST',
    URL: 'agent/listing',
  },
  AGENT_SIGNUP: {
    METHOD: 'POST',
    URL: 'agent/signup',
  },
  AGENT_DELETE: {
    METHOD: 'DELETE',
    URL: 'agent/delete',
  },

  //IVR Apis
  IVR_LIST: {
    METHOD: 'POST',
    URL: 'ivr/listing',
  },
  IVR_UPSERT: {
    METHOD: 'POST',
    URL: 'ivr/upsert',
  },
  IVR_DELETE: {
    METHOD: 'DELETE',
    URL: 'ivr/delete',
  },
  GET_EMPLOYEE_AGENT: {
    METHOD: 'POST',
    URL: 'member/get-employee-agent',
  },
  EMPLOYEE_AGENT_LIST: {
    METHOD: 'POST',
    URL: 'member/employee-agent-list',
  },
  TRANSACTION_LIST: {
    METHOD: 'POST',
    URL: 'billing/listing',
  },
  IDENTITY_LIST: {
    METHOD: 'POST',
    URL: 'did/identity/list',
  },
  IDENTITY_CREATE: {
    METHOD: 'POST',
    URL: 'did/identity/create',
  },
  IDENTITY_EDIT: {
    METHOD: 'POST',
    URL: 'did/identity/edit',
  },
  IDENTITY_DELETE: {
    METHOD: 'DELETE',
    URL: 'did/identity/delete',
  },
  PAYMENT: {
    METHOD: 'POST',
    URL: 'billing/payment',
  },
  GET_SAVED_CARDS: {
    METHOD: 'GET',
    URL: 'plan/get-cards',
  },
  ZOHO_GRANT_TOKEN: {
    METHOD: 'GET',
    URL: 'zoho/get-grant-token-url',
  },
  REMOVE_ZOHO: {
    METHOD: 'DELETE',
    URL: 'zoho/remove-account',
  },
  ZOHO_SET_TOKEN: {
    METHOD: 'POST',
    URL: 'zoho/set-token',
  },

  SMS_NUMBER_LIST: {
    METHOD: 'POST',
    URL: 'sms/number-list',
  },
  SEND_SMS: {
    METHOD: 'POST',
    URL: 'send-sms',
  },
  GET_SMS_LISTING: {
    METHOD: 'POST',
    URL: 'sms-list',
  },
  // reports apis
  REPORTS_OVERVIEW_STATS: {
    METHOD: 'POST',
    URL: 'reports/overview-stats',
  },
  REPORTS_CALL_LIST: {
    METHOD: 'POST',
    URL: 'reports/call-list',
  },
  REPORTS_CALL_RECORDING_LIST: {
    METHOD: 'POST',
    URL: 'reports/call-recording-list',
  },
  REPORTS_VOICEMAIL_LIST: {
    METHOD: 'POST',
    URL: 'reports/call-voicemail',
  },
  UNSEEN_VOICEMAIL_LIST: {
    METHOD: 'POST',
    URL: 'reports/call-voicemail/unseen-list',
  },
  REPORTS_SMS_LIST: {
    METHOD: 'POST',
    URL: 'reports/sms-list',
  },
  REPORTS_EMPLOYEES_LIST: {
    METHOD: 'POST',
    URL: 'reports/employees-list',
  },
  REPORTS_AGENTS_LIST: {
    METHOD: 'POST',
    URL: 'reports/agents-list',
  },
  REPORTS_NUMBER_STATS: {
    METHOD: 'GET',
    URL: 'reports/number-stats',
  },
  REPORTS_NUMBER_LIST: {
    METHOD: 'POST',
    URL: 'reports/number-list',
  },
  REPORTS_RECORDING_SEEN: {
    METHOD: 'POST',
    URL: 'reports/call-recording/seen',
  },
  GET_DETAILS: {
    METHOD: 'POST',
    URL: 'phone/info',
  },
  ALL_NUMBER_DETAILS: {
    METHOD: 'GET',
    URL: 'user/get-account-data',
  },
  DID_REGION_LIST: {
    METHOD: 'GET',
    URL: 'did/regions',
  },
  DID_NUMBERS_LIST: {
    METHOD: 'GET',
    URL: 'did/did-numbers',
  },
  DID_NUMBER_PURCHASE_NEW: {
    METHOD: 'POST',
    URL: 'did/did-numbers/purchase',
  },
  SUPPORT: {
    METHOD: 'POST',
    URL: 'support/add',
  },
  ADD_FUND: {
    METHOD: 'POST',
    URL: 'billing/add-fund ',
  },
  SET_AS_DEFAULT: {
    METHOD: 'POST',
    URL: 'user/set-default-card',
  },
  NAME_NUMBER_FILTER: {
    METHOD: 'GET',
    URL: 'user/get-account-data-role',
  },
  FORGOT_PASSWORD: {
    METHOD: 'POST',
    URL: 'user/forgot-password',
  },
  EMPLOYEE_AGENT_STATS: {
    METHOD: 'GET',
    URL: 'reports/employee',
  },
  UNSEEN_VOICEMAIL_COUNT: {
    METHOD: 'GET',
    URL: 'reports/call-voicemail/unseen',
  },
  FAILED_CALL_SCREENSHOT: {
    METHOD: 'POST',
    URL: 'call-screenshot-log',
  },
  SMS_SOCKET_COUNT: {
    METHOD: 'POST',
    URL: 'sms-socket-count',
  },
  SET_PORTAL_ACCESS: {
    METHOD: 'POST',
    URL: 'user/portal-access',
  },

  // Dropdown APIs
  DROPDOWN_EMPLOYEE_LISTING: {
    METHOD: 'GET',
    URL: 'member/member-all-list',
  },
  DROPDOWN_AGENT_LISTING: {
    METHOD: 'GET',
    URL: 'agent/agent-all-listing',
  },
  CONTACT_ACTIVITY_LIST: {
    METHOD: 'POST',
    URL: 'contact/activity/list',
  },
};
