import React, { useContext, useEffect, useMemo, useState } from 'react';
import TableManager from '../../comopnents/TableManager';
import useIcons from '../../assets/icons/useIcons';
import Button from '../../comopnents/Button';
import AsideModal from '../../comopnents/AsideModal';
import { getContactListing } from '../../api';
import Dropdown from '../../comopnents/Dropdown';
import { useAuth } from '../../hooks/useAuth';
import AddContactForm from './AddContactForm';
import {
  AGENCY_AGENT,
  AGENCY_OWNER,
  AGENT,
  EMPLOYEE,
  USER,
  formatPhoneNumber,
} from '../../helpers/functions';
import ConfirmComponent from '../../comopnents/ConfirmComponent';
import useContactDelete from '../../hooks/useContactDelete';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';
import SendMessageModal from '../Messages/SendMessageModal';
import Modal from '../../comopnents/Modal';
import useDidList from '../../hooks/useDidList';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Avatar from '../../comopnents/Avatar';
import ContactDetailsLayout from './ContactDetailLayout';
import useEmployeeAgentList from '../../hooks/useEmployeeAgentList';

const initialEditInstance = { isEdit: false, editData: {} };

export default function Contacts() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { mutate: contactDeleteMutate } = useContactDelete();
  const [searchParams, setSearchParams] = useSearchParams();
  const [addContact, setAddContact] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [smsModal, setSmsModal] = useState({ isOpen: false, number: null });
  const [editContactInstance, setEditContactInstance] =
    useState(initialEditInstance);
  const [smsDefaultDID, setSMSDefaultDid] = useState('');
  const queryClient = useQueryClient();
  const { MoreVIcon, CallIcon, MessageIcon, InfoSqureIcon } = useIcons();
  const { socket = {} } = useContext(SipSocketContext);
  const { _makeCall = () => null } = socket;

  const ACCESS = [user?.role_id]?.find((role) =>
    [USER, EMPLOYEE, AGENT, AGENCY_OWNER, AGENCY_AGENT]?.includes(role),
  );
  const { data: employeeAgentList = [] } = useEmployeeAgentList({
    get_owner: 'Y',
  });

  const { mutate: didListMutate, data: didListData } = useDidList();
  const didListing = useMemo(
    () => didListData?.data?.data ?? [],
    [didListData?.data?.data],
  );

  useEffect(() => {
    setSMSDefaultDid(user?.caller_id);
  }, [user?.caller_id]);

  useEffect(() => {
    didListMutate({ page: 1 });
  }, []);

  useEffect(() => {
    if (searchParams && searchParams.get('contactId')) {
      // if (searchParams && contactData && searchParams.get('contactId')) {
      setShowContactDetails(true);
    } else {
      setShowContactDetails(false);
      // setSearchParams('');
    }
  }, [searchParams]);

  const handleDropListClick = ({ value }, data) => {
    switch (value) {
      case 'edit':
        setEditContactInstance({ isEdit: true, editData: data });
        setAddContact(true);
        break;

      default:
        break;
    }
  };

  const handlContactDelete = (data) => {
    const { uuid = '' } = data;
    contactDeleteMutate({ contact_uuid: uuid });
  };

  const handleMakeCall = (phoneNumber, contactInfo) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('x-cn');
    setSearchParams(newParams);

    if (phoneNumber) {
      _makeCall(phoneNumber, phoneNumber?.replaceAll(' ', ''), contactInfo);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'first_name',
        header: () => 'Name',
        cell: (props) => {
          const element = props?.row?.original;
          const iAmAdmin = element?.uuid === user?.uuid;
          return (
            <div className="d--flex justify-content--start align-items--center gap--xs">
              <Avatar
                size="28"
                first_name={element?.first_name || 'U'}
                last_name={element?.last_name || ''}
                shouldShowPresence={false}
                contactImage={element?.contact_pic ?? null}
              />
              {element?.first_name} {element?.last_name}
              {iAmAdmin ? (
                <span className="text--danger font--600"> (You)</span>
              ) : (
                ''
              )}
            </div>
          );
        },
        meta: {
          width: '10%',
        },
      },
      {
        accessorKey: 'email',
        header: () => 'Email',
        cell: (props) => {
          return <span>{props.getValue()}</span>;
        },
        meta: {
          width: '15%',
        },
      },
      {
        accessorKey: 'phone',
        header: () => 'Phone',
        cell: (props) => formatPhoneNumber(props?.getValue()),

        meta: {
          textAlign: 'center',
          width: '15%',
        },
      },

      {
        accessorKey: 'dob',
        header: () => 'DOB',
        meta: {
          textAlign: 'center',
          width: '15%',
        },
      },
      {
        accessorKey: 'gender',
        header: () => 'Gender',
        cell: (props) => {
          return (
            <span>
              {props.getValue() === 'M'
                ? 'Male'
                : props.getValue() === 'F'
                ? 'Female'
                : '---'}
            </span>
          );
        },
        meta: {
          width: '5%',
        },
      },
      {
        accessorKey: 'mode',
        header: () => 'Contact Type',
        cell: (props) => {
          return (
            <>
              {props?.getValue()?.toLowerCase() === 'extension'
                ? 'Personal'
                : 'Company'}
            </>
          );
        },

        meta: {
          textAlign: 'center',
          width: '15%',
        },
      },
      {
        accessorKey: 'created_by',
        header: () => 'Created by',
        cell: (props) => {
          const creator =
            employeeAgentList && employeeAgentList?.length > 0
              ? employeeAgentList.find((a) => a.uuid === props.getValue())
              : null;

          return (
            <div
              className="avtarGroup--item d--flex justify-content--center"
              data-tooltip={`${creator?.first_name} ${creator?.last_name}`}
              tooltip-position="top"
            >
              <Avatar
                size="28"
                first_name={creator?.first_name || 'U'}
                last_name={creator?.last_name || ''}
                shouldShowPresence={false}
              />
            </div>
          );
        },

        meta: {
          textAlign: 'center',
          width: '15%',
        },
      },

      {
        accessorKey: 'action',
        header: () => 'Action',
        cell: (props) => {
          const element = props?.row?.original;
          return (
            <div className="gap--xs w--full d--flex align-items--center justify-content--center">
              {[USER, EMPLOYEE].includes(user?.role_id) && (
                <div
                  className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm text--info bg--info-100   c--pointer"
                  onClick={() => {
                    navigate(`/messages?sendSms=${element?.phone}`);
                    // setSmsModal({ isOpen: true, number: element?.phone });
                  }}
                >
                  <MessageIcon width={15} height={15} />
                </div>
              )}
              <div
                className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--success-200 text--success c--pointer"
                onClick={() => handleMakeCall(`${element?.phone}`, element)}
              >
                <CallIcon width={15} height={15} />
              </div>
              <span
                className="text--secondary c--pointer"
                onClick={() =>
                  navigate(
                    `/contacts?contactId=${element?.uuid}&showDialer=true`,
                  )
                }
              >
                <InfoSqureIcon width={33} height={33} />
              </span>
              <Dropdown
                extraClasses="w-min--150"
                closeOnClickOutside={false}
                dropList={{
                  component: ({ item }) => {
                    if (item?.shouldConfirm) {
                      return (
                        <ConfirmComponent
                          item={item}
                          element={element}
                          label="Please confirm?"
                          confirmCallback={(data) => handlContactDelete(data)}
                        >
                          {item?.title}
                        </ConfirmComponent>
                      );
                    }
                    return (
                      <span
                        className="w--full d--flex  justify-content--start p--2sm"
                        onClick={() => handleDropListClick(item, element)}
                      >
                        {item?.title}
                      </span>
                    );
                  },
                  data: [
                    { title: 'Edit', value: 'edit' },
                    { title: 'Delete', value: 'delete', shouldConfirm: true },
                  ],
                }}
                showcaret={false}
              >
                <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                  <MoreVIcon width={13} height={13} />
                </div>
              </Dropdown>
            </div>
          );
        },
        meta: {
          colType: 'action',
          textAlign: 'center',
          width: '5%',
        },
      },
    ];
  }, [employeeAgentList]);

  const tableActions = [
    {
      id: 1,
      access: ACCESS,
      label: 'Add Member',
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddContact(true)}
          size="sm"
        >
          Add Contact
        </Button>
      ),
    },
  ];

  const handleClose = () => {
    setEditContactInstance({ isEdit: false, editData: {} });
    setAddContact(false);
  };
  return (
    <div className="h--full w--full">
      {showContactDetails ? (
        <ContactDetailsLayout />
      ) : (
        <TableManager
          {...{
            fetcherKey: 'getContactListing',
            fetcherFn: getContactListing,
            columns,
            tableActions,
            name: 'Contacts',
            shouldFilter: true,
            initialFilterState: filterInitialValues,
          }}
        />
      )}
      {addContact && (
        <AsideModal
          handleClose={handleClose}
          title={editContactInstance?.isEdit ? 'Update Contact' : 'Add Contact'}
          footerComponent={null}
        >
          <AddContactForm
            editContactInstance={editContactInstance}
            handleClose={handleClose}
          />
        </AsideModal>
      )}
      {smsModal?.isOpen ? (
        <Modal
          footerComponent={null}
          title={'Send Message'}
          handleClose={() => setSmsModal({ isOpen: false, number: null })}
          shouldCloseOnClickOutside={false}
          height="400"
        >
          <SendMessageModal
            didListing={didListing}
            smsDefaultDID={smsDefaultDID}
            setSMSDefaultDid={setSMSDefaultDid}
            handleClose={() => {
              queryClient.invalidateQueries({
                queryKey: 'getSMSListing',
                exact: false,
              });
              setSmsModal({ isOpen: false, number: null });
            }}
            destinationNumber={smsModal?.number}
          />
        </Modal>
      ) : null}
    </div>
  );
}

const filterInitialValues = {
  filter: [
    {
      key: 'first_name',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter first name',
      inputType: 'text',
      inputLabel: 'First Name',
    },
    {
      key: 'last_name',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter last name',
      inputType: 'text',
      inputLabel: 'Last Name',
    },
    {
      key: 'phone',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter phone',
      inputType: 'phone-number',
      inputLabel: 'Phone Number',
    },
  ],
};
