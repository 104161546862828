import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/styles.scss';
import 'react-phone-number-input/style.css';
import App from './App';
import Spinner from './comopnents/Spinner';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
const appearance = {
  theme: 'stripe',
  hidePostalCode: true,
};
const options = {
  appearance,

  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900',
    },
  ],
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense
    fallback={
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    }
  >
    <Elements stripe={stripePromise} options={options}>
      <App />
    </Elements>
  </Suspense>,
);
