import moment from 'moment';
import React, { useEffect, useState } from 'react';

export const SecondsTohhmmss = (totalSeconds) => {
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = totalSeconds - hours * 3600 - minutes * 60;
  // round seconds
  seconds = Math.round(seconds * 100) / 100;
  let result = hours === 0 ? '' : hours < 10 ? '0' + hours + ':' : hours + ':';
  result += minutes < 10 ? '0' + minutes : minutes;
  result += ':' + (seconds < 10 ? '0' + seconds : seconds);
  return result;
};

const Timer = ({ start_time }) => {
  var [time, setTime] = useState('00:00');
  var call_start_time = moment(start_time);
  useEffect(() => {
    if (!start_time) return;
    const interval = setInterval(() => {
      var Current_time = moment();
      var second = Current_time.diff(call_start_time, 'seconds');
      setTime(SecondsTohhmmss(second));
    }, 1000);

    return () => clearInterval(interval);
  }, [start_time, call_start_time]);

  if (!start_time) return;
  return <div>{time}</div>;
};

export default Timer;
