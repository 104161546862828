import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { addFund } from '../../api';

export default function useAddFund() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: addFund,
    mutationKey: 'addFund',
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.message ?? 'Fund added successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
