import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useIcons from '../../../assets/icons/useIcons';
import { formatDateOnly } from '../../../helpers/functions';
import Dropdown from '../../../comopnents/Dropdown';
import Button from '../../../comopnents/Button';
import TableManager from '../../../comopnents/TableManager';
import { portingList } from '../../../api';
import AsideModal from '../../../comopnents/AsideModal';
import PreviewPortRequest from './PreviewPortRequest';

const PortingRequests = () => {
  const [previewModal, setPreviewModal] = useState({ show: false, data: null });
  const navigate = useNavigate();
  const { MoreVIcon } = useIcons();
  const handleDropListClick = (element, value) => {
    switch (value) {
      case 'edit':
        navigate(`/virtual-numbers/edit-porting-request/${element?.id}`);
        break;
      case 'preview':
        setPreviewModal({ show: true, data: element });
        break;
      default:
        return;
    }
  };

  const columns = [
    {
      accessorKey: 'did_number',
      header: () => 'Virtual Number',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'number_type',
      header: () => 'Type',
    },
    {
      accessorKey: 'created_at',
      header: () => 'Requested on',
      cell: (props) => formatDateOnly(props?.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Request Status',
    },
    {
      accessorKey: 'updated_at',
      header: () => 'Updated At',
      cell: (props) => formatDateOnly(props?.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',

      cell: (props) => {
        const element = props?.row?.original;
        const dropDownOptions = [
          { title: 'Edit', value: 'edit' },
          { title: 'Preview Request', value: 'preview' },
        ];
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--start">
            <Dropdown
              closeOnClickOutside={true}
              extraClasses="w-min--150"
              showcaret={false}
              dropList={{
                component: ({ item }) => (
                  <div
                    className="w--full d--flex  justify-content--start p--2sm"
                    onClick={() => {
                      if (item?.value === 'delete') {
                        return;
                      } else {
                        handleDropListClick(element, item?.value);
                      }
                    }}
                  >
                    {item?.title}
                  </div>
                ),
                data: dropDownOptions,
              }}
            >
              <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={13} height={13} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'left',
        width: '5%',
      },
    },
  ];

  const tableActions = [
    {
      id: 1,
      label: 'Add Port Request',
      access: true,
      component: (
        <Link to="/virtual-numbers/add-porting-request">
          <Button
            type="button"
            btnClasses="btn w-min--150"
            variant="primary"
            color="white"
            size="sm"
          >
            Add Port Request
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="h--full w--full virtualNumberPage">
      <TableManager
        {...{
          fetcherKey: 'portingList',
          fetcherFn: portingList,
          columns,
          tableActions,
          name: 'Porting Requests',
        }}
      />
      {previewModal?.show ? (
        <AsideModal
          handleClose={() => {
            setPreviewModal({ show: false, data: null });
          }}
          title={'Preview porting'}
          footerComponent={null}
        >
          <PreviewPortRequest data={previewModal?.data} />
        </AsideModal>
      ) : null}
    </div>
  );
};

export default PortingRequests;
