import React, { useContext, useEffect, useState } from 'react';
import TableManager from '../../comopnents/TableManager';
import { Bar } from 'react-chartjs-2';
import {
  agentList,
  callHistory,
  getContactListing,
  getMemberListing,
} from '../../api';
import { useAuth } from '../../hooks/useAuth';
import Avatar from '../../comopnents/Avatar';
import useIcons from '../../assets/icons/useIcons';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';
import useDashboardStats from '../../hooks/useDashboardStats';
import {
  EMPLOYEE,
  USER,
  converDateTimeFormateApis,
  durationToMMSS,
  formatPhoneNumber,
} from '../../helpers/functions';
import { useNavigate } from 'react-router-dom';
import Tabbing from '../../comopnents/Tabbing';
import useAllNumberDetails from '../../hooks/useAllNumberDetails';
import AudioPlayer from '../../comopnents/AudioPlayer';
import AsideModal from '../../comopnents/AsideModal';
import Transcripts2 from '../../comopnents/Transcripts2';
import useIVRList from '../../hooks/useIVRList';
import useRingGroupList from '../../hooks/useRingGroupList';
import { generateDateObjectFromTo } from './helpers';
import CustomToolTip from '../../comopnents/CustomToolTip';
import useCallRecordingSeen from '../../hooks/useCallRecordingSeen';
import { useQueryClient } from '@tanstack/react-query';
import Spinner from '../../comopnents/Spinner';
import Button from '../../comopnents/Button';
import { capitalizeWords } from '../ActivityManagement/TimeLine/Activity';
import CallScreenshotPreview from '../../comopnents/CallScreenshotPreview';
import useEmployeeAgentList from '../../hooks/useEmployeeAgentList';

const labels = ['', '', '', '', '', '', ''];

const initialGraphData = {
  labels,
  datasets: [
    {
      label: 'Received SMS',
      data: [0, 0, 0, 0, 0, 0, 0],
      backgroundColor: '#26c3f6',
    },
    {
      label: 'Sent SMS ',
      data: [0, 0, 0, 0, 0, 0, 0],
      backgroundColor: '#015023',
    },
  ],
};
const initialCallGraphData = {
  labels,
  datasets: [
    {
      label: 'Answered Calls',
      data: [0, 0, 0, 0, 0, 0, 0],
      backgroundColor: '#26c3f6',
    },
    {
      label: 'Outgoing Calls',
      data: [0, 0, 0, 0, 0, 0, 0],
      backgroundColor: '#015023',
    },
    {
      label: 'Missed Calls',
      data: [0, 0, 0, 0, 0, 0, 0],
      backgroundColor: '#FF4E43',
    },
    {
      label: 'Voicemail',
      data: [0, 0, 0, 0, 0, 0, 0],
      backgroundColor: '#b98077',
    },
  ],
};

const durationArrayForCalls = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'Last 7 Days',
    value: '7_days',
  },
  {
    label: 'Last 30 Days',
    value: '30_days',
  },
  {
    label: 'This Month',
    value: 'this_month',
  },
  {
    label: 'Last Month',
    value: 'last_month',
  },
];

const initialPieChartData = {
  labels: ['Answered', 'Outgoing', 'Missed'],
  datasets: [
    {
      label: 'Number of calls',
      data: [0, 0, 0],
      backgroundColor: ['#26c3f6', '#015023', '#ff4e43'],
      borderWidth: 1,
    },
  ],
};

const SeparatorItem = ({ list = [] }) => {
  const result =
    list?.map((option) => {
      return (
        <div
          key={option?.uuid}
          className="d--flex align-items--center gap--sm "
        >
          <Avatar
            first_name={option?.name?.split(' ')?.[0] || ''}
            last_name={option?.name?.split(' ')?.[1] || ''}
            inGroup={true}
            size="20"
            extension={option?.extension}
          />
          {option?.name}
        </div>
      );
    }) ?? null;

  return (
    <div>
      <div className="d--flex flex--column gap--sm h-max--350">{result}</div>
    </div>
  );
};

export default function Dashboard() {
  const { socket = {} } = useContext(SipSocketContext);
  const { _makeCall = () => null } = socket;
  const { mutate: callRecordingMutateSeen } = useCallRecordingSeen();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const [grahTabs, setGraphTabs] = useState('call_summary');
  const [screenShotPreviewData, setScreenshotPreviewData] = useState(null);
  const [rightTabs, setRightTabs] = useState('voicemail');
  const [bottomLeftTab, setBottomLeftTab] = useState('contact');
  const [callDurationFilter, setCallDurationFilter] = useState('today');
  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
  });
  const [showTranscript, setShowTranscript] = useState(false);
  const [voicemailRecordingTableLoad, setVoicemailRecordingTableLoad] =
    useState(false);
  const [contactAgentTableLoad, setContactAgentTableLoad] = useState(false);
  const [transcriptData, setTranscriptData] = useState(null);

  const {
    CallIcon,
    MissedCall,
    OutgoingCall,
    IncomingCall,
    SMSSendIcon,
    SMSReceivedIcon,
    MessageIcon,
    PlayIcon,
    HoldIcon,
    TranscriptsIcon,
    VoicemailIcon,
    UsersIcon,
    MobileIcon,
    MoreVIcon,
    RefreshIcon,
    VoicemailGroupIcon,
  } = useIcons();

  const navigate = useNavigate();
  const { data = {} } = useDashboardStats();
  // const { data: pieChartData, isLoading: loadingPieChartData } = useCallStatus({
  //   queryKey: generateDateObjectFromTo({ dateType: callDurationFilter }),
  // });
  const { getNumberName } = useAllNumberDetails();
  const { mutateAsync: ivrListMutate, data: ivrListData } = useIVRList();
  const { mutate: ringGroupListMutate, data: ringGroupListData } =
    useRingGroupList();
  const { data: employeeAgentList = [] } = useEmployeeAgentList({
    get_owner: 'Y',
  });

  useEffect(() => {
    ivrListMutate({ page: 1 });
    ringGroupListMutate({ page: 1 });
  }, []);

  const handleMakeCall = (phoneNumber) => {
    if (phoneNumber) {
      _makeCall(phoneNumber, phoneNumber?.replaceAll(' ', ''));
    }
  };
  const columns = [
    {
      accessorKey: 'first_name',
      header: () => 'Name',
      cell: (props) => {
        const element = props?.row?.original;
        const iAmAdmin = element?.uuid === user?.uuid;
        const isAgency = element?.role_id === 4;
        const isTenant = element?.role_id === 2;
        const fullname = `${element?.first_name} ${element?.last_name}`;
        const email = `${element?.email}`;
        return (
          <span className="d--flex gap--sm  align-items--center">
            <Avatar
              size="28"
              first_name={element?.first_name}
              last_name={element?.last_name}
              image={element?.profile_pic}
              extension={element?.extension}
            />
            <div className="d--flex flex--column gap--xs">
              <div className="w--full d--flex gap--xs">
                {fullname}
                <span className="text--danger font--600">
                  {iAmAdmin
                    ? '(You)'
                    : isAgency
                    ? '(Agency)'
                    : isTenant
                    ? '(Tenant)'
                    : ''}
                </span>
              </div>
              <div className="w--full font--xs">{email}</div>
            </div>
          </span>
        );
      },
    },
    {
      accessorKey: 'caller_id',
      header: () => 'Virtual number',
      meta: {
        width: '5%',
      },
    },

    {
      accessorKey: 'extension',
      header: () => 'Extension',
      meta: {
        width: '5%',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const iAmAdmin = element?.uuid === user?.uuid;

        return (
          <div className="d--flex justify-content--center">
            {iAmAdmin ? null : element?.verified === 1 &&
              element?.is_active === 1 ? (
              <div
                className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--success-200 text--success c--pointer "
                onClick={() => handleMakeCall(`${element?.extension}`)}
              >
                <CallIcon width={15} height={15} />
              </div>
            ) : null}
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];

  const callIconLookup = {
    Missed: (
      <span className="text--danger">
        <MissedCall width={18} height={18} />
      </span>
    ),
    Outbound: (
      <span className="text--success">
        <OutgoingCall width={18} height={18} />
      </span>
    ),
    Inbound: (
      <span className="text--primary">
        <IncomingCall width={18} height={18} />
      </span>
    ),
  };

  const handleSetTranscript = (data) => {
    setTranscriptData(data);
    setShowTranscript(true);
  };

  const recordingColumns = [
    {
      accessorKey: 'start_stamp',
      header: () => 'Date/Time',
      cell: (props) => {
        const element = props?.row?.original;
        const direction = element?.direction;
        return (
          <div className="d--flex align-items--center  justify-content--start gap--sm">
            {callIconLookup?.[direction] ?? ''}
            <div className="d--flex flex--column gap--xs">
              <div className="w--full">
                {converDateTimeFormateApis(
                  props?.row?.original?.start_stamp,
                  'MMM DD,',
                )}
              </div>
              <div className="w--full font--xs">
                {converDateTimeFormateApis(
                  props?.row?.original?.start_stamp,
                  'hh:mm A',
                )}
              </div>
            </div>
          </div>
        );
      },
      meta: {
        width: '5%',
      },
    },
    {
      accessorKey: 'caller_id_number',
      header: () => 'From',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <div className="w--full d--flex flex--column gap--sm">
            <div className="font--sm d--flex gap--sm ">
              <label className="w-min--36 font--600">From:</label>
              {getNumberName(props?.getValue(), false)}&nbsp;
              {props?.getValue()?.length < 5 ? `(${props?.getValue()})` : ''}
            </div>
            <div className="font--sm d--flex gap--sm ">
              <label
                className="w-min--36 font--600"
                data-tooltip="Virtual number"
                tooltip-position="left"
              >
                Via:
              </label>
              {formatPhoneNumber(element?.via_did)}
            </div>
            <div className="font--sm d--flex gap--sm ">
              <label className="w-min--36 font--600">To:</label>
              {getNumberName(element?.destination_number, false)}&nbsp;
              {element?.destination_number?.length < 5
                ? `(${element?.destination_number})`
                : ''}
            </div>
          </div>

          // <>
          //   {getNumberName(props?.getValue(), false)}&nbsp;
          //   {props?.getValue()?.length < 5 ? `(${props?.getValue()})` : ''}
          // </>
        );
      },
      meta: {
        width: '15%',
      },
    },
    {
      accessorKey: 'duration',
      header: () => 'Duration',
      cell: (props) => durationToMMSS(props.getValue()),
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },

    // {
    //   accessorKey: 'via_did',
    //   header: () => 'Via Virtual Number',
    //   cell: (props) => {
    //     return formatPhoneNumber(props?.getValue());
    //   },
    //   meta: {
    //     width: '20%',
    //   },
    // },
    // {
    //   accessorKey: 'destination_number',
    //   header: () => 'To',
    //   cell: (props) => {
    //     return (
    //       <div className="d--flex align-items--center gap--sm">
    //         {getNumberName(props?.getValue(), false)}&nbsp;
    //         {props?.getValue()?.length < 5 ? `(${props?.getValue()})` : ''}
    //       </div>
    //     );
    //   },
    //   meta: {
    //     width: '20%',
    //   },
    // },

    // {
    //   accessorKey: 'duration',
    //   header: () => 'Duration',
    //   cell: (props) => durationToMMSS(props.getValue()),
    //   meta: {
    //     textAlign: 'center',
    //     width: '10%',
    //   },
    // },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { recording_file = '', direction = '' } = element;
        const isPlaying =
          playingData?.playing && playingData?.file === recording_file;
        return (
          <div className="gap--xs w--full d--grid grid--2 w-max--60  align-items--center justify-content--end m--auto">
            {isPlaying ? (
              <div
                className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm c--pointer"
                onClick={() => {
                  setPlayingData({ playing: false, file: null });
                }}
              >
                <HoldIcon width={20} height={20} />
                {/* <AudioPlayer
                  onClose={() => {
                    setPlayingData({ playing: false, file: null });
                  }}
                  name={recording_file}
                /> */}
              </div>
            ) : (
              <div
                className={`c--pointer w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm ${
                  element?.seen_at
                    ? 'text--success bg--black-200'
                    : 'text--red bg--danger-200'
                } `}
                onClick={() => {
                  if (!element?.seen_at) {
                    callRecordingMutateSeen({
                      uuid: element?.xml_cdr_uuid,
                    });
                  }
                  setPlayingData({ playing: true, file: recording_file });
                }}
              >
                <PlayIcon width={16} height={16} />
              </div>
            )}
            <div
              data-tooltip={'Transcripts'}
              tooltip-position="left"
              onClick={() => handleSetTranscript(element)}
              className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm c--pointer"
            >
              <TranscriptsIcon width={32} height={32} />
            </div>
            <div
              className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--success-200 text--success c--pointer"
              onClick={() =>
                handleMakeCall(
                  direction === 'Outbound'
                    ? element?.destination_number
                    : `${element?.caller_id_number}`,
                )
              }
            >
              <CallIcon width={18} height={18} />
            </div>
            <div
              className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--info-100 text--info  c--pointer"
              onClick={() => {
                navigate(
                  `/messages?didId=${element?.via_did?.replace(
                    '+',
                    '',
                  )}&sendSms=${
                    ['Inbound', 'Missed'].includes(direction)
                      ? element?.caller_id_number?.replace('+', '')
                      : element?.destination_number?.replace('+', '')
                  }`,
                );
              }}
            >
              <MessageIcon width={20} height={20} />
            </div>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '2%',
      },
    },
  ];

  const voicemailColumns = [
    {
      accessorKey: 'start_stamp',
      header: () => 'Date/Time',
      cell: (props) => {
        return (
          <div className="d--flex align-items--center  justify-content--start gap--sm">
            <div className="w-min--12 w-max--12 m-r--sm"></div>
            <div className="d--flex flex--column gap--xs">
              <div className="w--full">
                {converDateTimeFormateApis(
                  props?.row?.original?.start_stamp,
                  'MMM DD,',
                )}
              </div>
              <div className="w--full font--xs">
                {converDateTimeFormateApis(
                  props?.row?.original?.start_stamp,
                  'hh:mm A',
                )}
              </div>
            </div>
          </div>
        );
      },
      meta: {
        width: '5%',
      },
    },

    {
      accessorKey: 'caller_id_number',
      header: () => 'From',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <div className="w--full d--flex flex--column gap--sm">
            <div className="font--sm d--flex gap--sm ">
              <label className="w-min--36 font--600">From:</label>
              {getNumberName(props?.getValue(), false)}&nbsp;
              {props?.getValue()?.length < 5 ? `(${props?.getValue()})` : ''}
            </div>
            <div className="font--sm d--flex gap--sm ">
              <label
                className="w-min--36 font--600"
                data-tooltip="Virtual number"
                tooltip-position="left"
              >
                Via:
              </label>
              {formatPhoneNumber(element?.via_did)}
            </div>
            <div className="font--sm d--flex gap--sm ">
              <label className="w-min--36 font--600">To:</label>
              {element?.forward_type === 'VOICEMAILGROUP' ||
              element?.forward_type === 'VOICEMAIL'
                ? element?.forward_name
                : capitalizeWords(element?.forward_type?.toLowerCase(), ' ')}
            </div>
          </div>

          // <>
          //   {getNumberName(props?.getValue(), false)}&nbsp;
          //   {props?.getValue()?.length < 5 ? `(${props?.getValue()})` : ''}
          // </>
        );
      },
      meta: {
        width: '15%',
      },
    },

    {
      accessorKey: 'duration',
      header: () => 'Duration',
      cell: (props) => durationToMMSS(props.getValue()),
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },

    // {
    //   accessorKey: 'via_did',
    //   header: () => 'Via Virtual Number',
    //   cell: (props) => {
    //     return formatPhoneNumber(props?.getValue());
    //   },
    //   meta: {
    //     textAlign: 'center',
    //     width: '20%',
    //   },
    // },

    // {
    //   accessorKey: 'forward_type',
    //   header: () => 'To',
    // },
    // {
    //   accessorKey: 'duration',
    //   header: () => 'Duration',
    //   cell: (props) => durationToMMSS(props.getValue()),
    //   meta: {
    //     textAlign: 'center',
    //     width: '10%',
    //   },
    // },

    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const {
          recording_file = '',
          xml_cdr_uuid = '',
          direction = '',
        } = element;
        const isPlaying =
          playingData?.playing &&
          playingData?.file === recording_file &&
          playingData?.uuid === xml_cdr_uuid;
        return (
          <div className="gap--xs w--full d--grid grid--2 w-max--60  align-items--center justify-content--end m--auto">
            {isPlaying ? (
              <div
                className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm c--pointer"
                onClick={() => {
                  setPlayingData({ playing: false, file: null, uuid: null });
                }}
              >
                <HoldIcon width={20} height={20} />
                {/* <AudioPlayer
                  onClose={() => {
                    setPlayingData({ playing: false, file: null, uuid: null });
                  }}
                  name={recording_file}
                /> */}
              </div>
            ) : (
              <div
                className={`c--pointer w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm ${
                  element?.seen_at
                    ? 'text--success bg--black-200'
                    : 'text--red bg--danger-200'
                } `}
                onClick={() => {
                  if (!element?.seen_at) {
                    callRecordingMutateSeen({
                      uuid: element?.xml_cdr_uuid,
                    });
                  }
                  setPlayingData({
                    playing: true,
                    file: recording_file,
                    uuid: xml_cdr_uuid,
                  });
                }}
              >
                <PlayIcon width={16} height={16} />
              </div>
            )}
            <div
              data-tooltip={'Transcripts'}
              tooltip-position="left"
              onClick={() => handleSetTranscript(element)}
              className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm c--pointer"
            >
              <TranscriptsIcon width={32} height={32} />
            </div>
            <div
              className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--success-200 text--success c--pointer"
              onClick={() => handleMakeCall(`${element?.caller_id_number}`)}
            >
              <CallIcon width={18} height={18} />
            </div>
            <div
              className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--info-100 text--info  c--pointer"
              onClick={() => {
                navigate(
                  `/messages?didId=${element?.via_did?.replace(
                    '+',
                    '',
                  )}&sendSms=${element?.caller_id_number?.replace('+', '')}`,
                );
              }}
            >
              <MessageIcon width={20} height={20} />
            </div>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '2%',
      },
    },
  ];

  const recentCallColumns = [
    {
      accessorKey: 'start_stamp',
      header: () => 'Date',
      cell: (props) => {
        const element = props?.row?.original;
        const direction = element?.direction;
        const forward_type = element?.forward_type;
        return (
          <div className="d--flex align-items--center  justify-content--start gap--sm">
            <div
              className={`${
                ['EXTENSION', 'DEPARTMENT', 'IVR'].includes(forward_type) &&
                direction === 'Missed'
                  ? 'c--pointer'
                  : ''
              }`}
              onClick={() => {
                if (
                  ['EXTENSION', 'DEPARTMENT', 'IVR'].includes(forward_type) &&
                  direction === 'Missed'
                ) {
                  setScreenshotPreviewData(element);
                }
              }}
            >
              {callIconLookup?.[direction] ?? ''}
            </div>
            <div className="d--flex flex--column gap--xs">
              <div className="w--full">
                {converDateTimeFormateApis(
                  props?.row?.original?.start_stamp,
                  'MMM DD,',
                )}
              </div>
              <div className="w--full font--xs">
                {converDateTimeFormateApis(
                  props?.row?.original?.start_stamp,
                  'hh:mm A',
                )}
              </div>
            </div>
          </div>
        );
      },
      meta: {
        width: '5%',
      },
    },
    {
      accessorKey: 'caller_id_number',
      header: () => 'From',
      cell: (props) => {
        const element = props?.row?.original;
        let members = [];
        if (element?.forward_type && element?.members) {
          members = element?.members?.split(',')?.map((item) => {
            return {
              name: getNumberName(item, false),
              extension: item,
            };
          });
        }
        let departmentName = '';
        if (
          element?.forward_type === 'DEPARTMENT' ||
          element?.forward_type === 'VOICEMAILGROUP'
        ) {
          departmentName =
            ringGroupListData?.data?.data?.find(
              (item) => item?.uuid === element?.forward_value,
            )?.title || 'Unknown';
        }
        let ivrName = '';
        if (element?.forward_type === 'IVR') {
          ivrName =
            ivrListData?.data?.data?.find(
              (item) => item?.uuid === element?.forward_value,
            )?.name || 'Unknown';
        }

        return (
          <div className="w--full d--flex flex--column gap--sm">
            <div className="font--sm d--flex gap--sm ">
              <label className="w-min--36 font--600">From:</label>
              {getNumberName(props?.getValue(), false)}
            </div>
            <div className="font--sm d--flex gap--sm ">
              <label
                className="w-min--36 font--600"
                data-tooltip="Virtual number"
                tooltip-position="left"
              >
                Via:
              </label>
              {formatPhoneNumber(element?.via_did)}
            </div>
            <div className="font--sm d--flex gap--sm ">
              <label className="w-min--36 font--600">To:</label>
              <>
                {element?.is_voicemail === 1 ? (
                  <span className="d--flex align-items--center  justify-content--start gap--sm ">
                    <span>
                      {element?.forward_type === 'VOICEMAILGROUP' ? (
                        <VoicemailGroupIcon width={20} height={20} />
                      ) : (
                        <VoicemailIcon width={20} height={20} />
                      )}
                    </span>
                    <div>
                      {element?.forward_type === 'VOICEMAILGROUP' ||
                      element?.forward_type === 'VOICEMAIL'
                        ? element?.forward_name
                        : capitalizeWords(
                            element?.forward_type?.toLowerCase(),
                            ' ',
                          )}
                      {/* {element?.forward_type === 'DEPARTMENT' ||
                      element?.forward_type === 'VOICEMAILGROUP' ? (
                        <div className="font--xs">{departmentName}</div>
                      ) : null} */}
                    </div>
                  </span>
                ) : (
                  <>
                    {element?.destination_number ? (
                      <>
                        <span
                          className="d--flex align-items--center  justify-content--start gap--sm "
                          style={{ textTransform: 'capitalize' }}
                        >
                          {getNumberName(element?.destination_number, false)}
                          {element?.destination_number?.length < 5
                            ? `(${formatPhoneNumber(
                                element?.destination_number,
                              )})`
                            : ''}
                        </span>
                      </>
                    ) : (
                      <span className="d--flex">
                        {element?.forward_type === 'EXTENSION' ? (
                          <>
                            Ext.
                            <span style={{ textTransform: 'capitalize' }}>
                              {getNumberName(element?.forward_value, false)}
                              &nbsp;(
                              {element?.forward_value})
                            </span>
                          </>
                        ) : element?.forward_type === 'DEPARTMENT' ||
                          element?.forward_type === 'VOICEMAILGROUP' ? (
                          <CustomToolTip
                            id={props?.row?.id}
                            options={members}
                            accessorKey="name"
                            separatorComponent={SeparatorItem}
                            controlled={true}
                            enableTooltip={members?.length > 0}
                          >
                            <span className="d--flex align-items--center gap--xs">
                              <UsersIcon />
                              <span>{departmentName}</span>
                            </span>
                          </CustomToolTip>
                        ) : element?.forward_type === 'IVR' ? (
                          <span className="d--flex align-items--center gap--xs">
                            <MobileIcon />
                            <span>{ivrName}</span>
                          </span>
                        ) : (
                          <>
                            {element?.forward_type}
                            {handleMembers(element?.members)}
                          </>
                        )}
                      </span>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        );
      },
      meta: {
        width: '15%',
      },
    },
    {
      accessorKey: 'duration',
      header: () => 'Duration',
      cell: (props) => durationToMMSS(props.getValue()),
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { recording_file = '', is_voicemail, direction = '' } = element;
        // if (!recording_file) return;
        const isPlaying =
          playingData?.playing && playingData?.file === recording_file;
        return (
          <div className="gap--xs w--full d--grid grid--2 w-max--60  align-items--center justify-content--end m--auto">
            {recording_file && (
              <>
                {isPlaying ? (
                  <div
                    className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm c--pointer"
                    onClick={() => {
                      setPlayingData({ playing: false, file: null });
                    }}
                  >
                    <HoldIcon width={20} height={20} />
                    {/* <AudioPlayer
                      onClose={() => {
                        setPlayingData({ playing: false, file: null });
                      }}
                      name={recording_file}
                    /> */}
                  </div>
                ) : (
                  <div
                    className={`c--pointer w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm ${
                      element?.seen_at
                        ? 'text--success bg--black-200'
                        : 'text--red bg--danger-200'
                    } `}
                    onClick={() => {
                      if (!element?.seen_at) {
                        callRecordingMutateSeen({
                          uuid: element?.xml_cdr_uuid,
                        });
                      }
                      setPlayingData({ playing: true, file: recording_file });
                    }}
                    data-tooltip={
                      is_voicemail === 1 ? 'Voicemail' : 'Recording'
                    }
                    tooltip-position="left"
                  >
                    <PlayIcon width={16} height={16} />
                  </div>
                )}
                <div
                  data-tooltip={'Transcripts'}
                  tooltip-position="left"
                  onClick={() => handleSetTranscript(element)}
                  className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm c--pointer"
                >
                  <TranscriptsIcon width={34} height={32} />
                </div>
              </>
            )}

            <div
              className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--success-200 text--success c--pointer"
              onClick={() =>
                handleMakeCall(
                  direction === 'Outbound'
                    ? element?.destination_number
                    : `${element?.caller_id_number}`,
                )
              }
            >
              <CallIcon width={18} height={18} />
            </div>

            <div
              className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--info-100 text--info  c--pointer"
              onClick={() => {
                navigate(
                  `/messages?didId=${element?.via_did?.replace(
                    '+',
                    '',
                  )}&sendSms=${
                    ['Inbound', 'Missed'].includes(direction)
                      ? element?.caller_id_number?.replace('+', '')
                      : element?.destination_number?.replace('+', '')
                  }`,
                );
              }}
            >
              <MessageIcon width={20} height={20} />
            </div>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '2%',
      },
    },
  ];

  // const isDataEmpty = pieChartData?.datasets?.[0]?.data?.every(
  //   (val) => val === 0,
  // );

  const handleClose = () => {
    setTranscriptData(null);
    setShowTranscript(false);
  };

  const contactColumns = [
    {
      accessorKey: 'first_name',
      header: () => 'Name',
      cell: (props) => {
        const element = props?.row?.original;
        const iAmAdmin = element?.uuid === user?.uuid;
        return (
          <span className="d--flex flex--column">
            <span>
              {element?.first_name} {element?.last_name}
            </span>
            <span className="font--xs">{element?.email}</span>
          </span>
        );
      },
      meta: {
        width: '15%',
      },
    },
    // {
    //   accessorKey: 'email',
    //   header: () => 'Email',
    //   cell: (props) => {
    //     return <span>{props.getValue()}</span>;
    //   },
    //   meta: {
    //     width: '15%',
    //   },
    // },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      cell: (props) => formatPhoneNumber(props?.getValue()),

      meta: {
        textAlign: 'center',
        width: '15%',
      },
    },
    {
      accessorKey: 'created_by',
      header: () => 'Created by',
      cell: (props) => {
        const creator =
          employeeAgentList && employeeAgentList?.length > 0
            ? employeeAgentList.find((a) => a.uuid === props.getValue())
            : null;
        return (
          <span
            className="avtarGroup--item d--flex justify-content--center"
            data-tooltip={capitalizeWords(
              `${creator?.first_name} ${creator?.last_name}`,
              ' ',
            )}
            tooltip-position="top"
          >
            <Avatar
              size="28"
              first_name={creator?.first_name || 'U'}
              last_name={creator?.last_name || ''}
              shouldShowPresence={false}
            />
          </span>
        );
      },

      meta: {
        textAlign: 'center',
        width: '15%',
      },
    },
    // {
    //   accessorKey: 'dob',
    //   header: () => 'DOB',
    //   meta: {
    //     textAlign: 'center',
    //     width: '15%',
    //   },
    // },
    // {
    //   accessorKey: 'gender',
    //   header: () => 'Gender',
    //   cell: (props) => {
    //     return (
    //       <span>
    //         {props.getValue() === 'M'
    //           ? 'Male'
    //           : props.getValue() === 'F'
    //             ? 'Female'
    //             : '---'}
    //       </span>
    //     );
    //   },
    //   meta: {
    //     width: '5%',
    //   },
    // },

    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            {[USER, EMPLOYEE].includes(user?.role_id) && (
              <div
                className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm text--info bg--info-100   c--pointer"
                onClick={() => {
                  navigate(`/messages?sendSms=${element?.phone}`);
                  // setSmsModal({ isOpen: true, number: element?.phone });
                }}
              >
                <MessageIcon width={15} height={15} />
              </div>
            )}
            <div
              className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--success-200 text--success c--pointer"
              onClick={() => handleMakeCall(`${element?.phone}`)}
            >
              <CallIcon width={15} height={15} />
            </div>
            {/* <Dropdown
              extraClasses="w-min--150"
              closeOnClickOutside={false}
              dropList={{
                component: ({ item }) => {
                  if (item?.shouldConfirm) {
                    return (
                      <ConfirmComponent
                        item={item}
                        element={element}
                        label="Please confirm?"
                        // confirmCallback={(data) => handlContactDelete(data)}
                      >
                        {item?.title}
                      </ConfirmComponent>
                    );
                  }
                  return (
                    <span
                      className="w--full d--flex  justify-content--start p--2sm"
                      // onClick={() => handleDropListClick(item, element)}
                    >
                      {item?.title}
                    </span>
                  );
                },
                data: [
                  { title: 'Edit', value: 'edit' },
                  { title: 'Delete', value: 'delete', shouldConfirm: true },
                ],
              }}
              showcaret={false}
            >
              <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={13} height={13} />
              </div>
            </Dropdown> */}
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];

  function handleMembers(members = '') {
    if (!members) return '';
    const extensions = members?.split(',');
    const names = [];
    for (let index = 0; index < extensions.length; index++) {
      const element = extensions[index];
      if (user?.extension === element) {
        names.unshift('You');
      } else {
        names.push(getNumberName(element, false));
      }
    }
    return ` (${names.join(',')})`;
  }
  const handleDropListClick = ({ value }, data) => {
    switch (value) {
      case 'call':
        handleMakeCall(data?.caller_id_number);
        break;
      case 'send_sms':
        navigate(
          `/messages?didId=${data?.via_did?.replace(
            '+',
            '',
          )}&sendSms=${data?.caller_id_number?.replace('+', '')}`,
        );
        // agencyActivate({ uuid: data?.uuid });
        break;

      default:
        break;
    }
  };

  return (
    <div id="asldhaskdj" className="w--full dashboardPage ">
      <div className="d--flex gap--md w--full flex--column">
        <div className="w--full d--flex align-items--center justify-content--center">
          {/* <div className="font--md font--400 d--flex align-items--center gap--sm w-min--200 text--primary w--full ">
            Dashboard
          </div> */}
          <div className="w--full d--flex justify-content--center">
            <Tabbing
              tab={callDurationFilter}
              setTab={setCallDurationFilter}
              tabsLabels={[
                {
                  label: 'Today',
                  value: 'today',
                },
                {
                  label: 'Yesterday',
                  value: 'yesterday',
                },
                {
                  label: 'This week',
                  value: 'week',
                },
              ]}
            />
          </div>
          {/* <div className='w--full d--flex justify-content--end'></div> */}
        </div>
        <div className="d--flex gap--md w--full flex--column dashboardPageContent p-r--xs">
          <div className="w--full d--flex justify-content--between gap--md  ">
            <>
              <div className=" w--full  d--flex flex--column gap--sm ">
                <div className="w--full d--flex justify-content--between align-items--center">
                  <div className="d--flex gap--sm align-items--center">
                    <span
                      className={`font--md ${
                        grahTabs === 'call_summary'
                          ? 'text--secondary font--600'
                          : 'font--500'
                      }  c--pointer`}
                      onClick={() => setGraphTabs('call_summary')}
                    >
                      Call Summary
                    </span>
                    <span>|</span>
                    <span
                      className={`font--md  ${
                        grahTabs === 'sms_summary'
                          ? 'text--secondary font--600'
                          : 'font--500'
                      }  c--pointer`}
                      onClick={() => setGraphTabs('sms_summary')}
                    >
                      SMS Summary
                    </span>
                  </div>
                  <div className="h-min--32"></div>
                </div>
                {grahTabs === 'call_summary' ? (
                  <div className="d--flex align-items--center justify-content--center h--full w--full bg--white box-shadow--xs p--md radius--sm barGraph">
                    <div className="w--full h--full d--flex align-items--center justify-content--center ">
                      {/* {isDataEmpty && !loadingPieChartData ? (
                        <div>No data available</div>
                      ) : ( */}
                      <Bar
                        className="graph"
                        options={{
                          maintainAspectRatio: true,
                          responsive: true,
                          plugins: {
                            legend: {
                              position: 'top',
                              labels: {
                                boxWidth: 15,
                                boxHeight: 15,
                              },
                            },
                            datalabels: {
                              display: false,
                            },
                          },
                          scales: {
                            y: {
                              ticks: {
                                stepSize: 1, // Set your desired step size here
                                beginAtZero: true, // Start the axis from zero
                              },
                            },
                          },
                          datasets: {
                            bar: {
                              barPercentage: 0.4,
                            },
                          },
                        }}
                        data={
                          data?.data?.data?.call_graph?.data ||
                          initialCallGraphData
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="h--full w--full bg--white box-shadow--xs p--md radius--sm barGraph ">
                      <div className="w--full h--full d--flex align-items--center justify-content--center">
                        <Bar
                          className="graph"
                          options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            plugins: {
                              legend: {
                                position: 'top',
                                labels: {
                                  boxWidth: 15,
                                  boxHeight: 15,
                                },
                              },
                              datalabels: {
                                display: false,
                              },
                            },
                            scales: {
                              y: {
                                ticks: {
                                  stepSize: 1, // Set your desired step size here
                                  beginAtZero: true, // Start the axis from zero
                                },
                              },
                            },
                            datasets: {
                              bar: {
                                barPercentage: 0.2,
                              },
                            },
                          }}
                          data={
                            data?.data?.data?.sms_graph?.data ??
                            initialGraphData
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="w--full h--full d--flex flex--column voiceTableSec">
                <div className="h--sm halfTable">
                  <TableManager
                    {...{
                      isCustomDesign: true,
                      fetcherKey: 'callHistory',
                      fetcherFn: callHistory,
                      columns: recentCallColumns,
                      name: 'Recent calls',
                      showPagination: false,
                      showPageCount: false,
                      showRefresh: true,
                      shouldShowColumnName: false,
                      extraParams: {
                        type: 'ALL',
                        limit: 10,
                        filter_date: generateDateObjectFromTo({
                          dateType: callDurationFilter,
                        }),
                      },
                    }}
                  />
                </div>
              </div>
            </>
          </div>
          {/* ------------ */}
          <div className="d--flex gap--md  w--full">
            <div className="w--full halfTable ">
              <div className="d--flex justify-content--between align-items--center">
                <div className="d--flex gap--sm align-items--center">
                  <span
                    className={`font--md  c--pointer ${
                      bottomLeftTab === 'contact'
                        ? 'text--secondary font--600'
                        : 'font--500'
                    }`}
                    onClick={() => setBottomLeftTab('contact')}
                  >
                    Contacts
                  </span>
                  <span>|</span>
                  <span
                    className={`font--md   c--pointer ${
                      bottomLeftTab === 'employee'
                        ? 'text--secondary font--600'
                        : 'font--500'
                    }`}
                    onClick={() => setBottomLeftTab('employee')}
                  >
                    Employee
                  </span>
                  <span>|</span>
                  <span
                    className={`font--md   c--pointer ${
                      bottomLeftTab === 'agent'
                        ? 'text--secondary font--600'
                        : 'font--500'
                    }`}
                    onClick={() => setBottomLeftTab('agent')}
                  >
                    Agents
                  </span>
                  {contactAgentTableLoad && <Spinner />}
                </div>
                <Button
                  variant="white"
                  btnClasses="btn w-max--36 box-shadow--xs"
                  color="primary"
                  type="button"
                  icon={<RefreshIcon width={20} height={20} />}
                  onClick={() => {
                    if (bottomLeftTab === 'contact') {
                      queryClient.invalidateQueries({
                        queryKey: ['getContactListing'],
                        exact: false,
                      });
                    }
                    if (bottomLeftTab === 'employee') {
                      queryClient.invalidateQueries({
                        queryKey: ['getMemberListing'],
                        exact: false,
                      });
                    }
                    if (bottomLeftTab === 'agent') {
                      queryClient.invalidateQueries({
                        queryKey: ['agentList'],
                        exact: false,
                      });
                    }
                  }}
                  size="sm"
                  data-tooltip="Refresh"
                  tooltip-position="top"
                />
              </div>

              {bottomLeftTab === 'contact' && (
                <TableManager
                  {...{
                    fetcherKey: 'getContactListing',
                    fetcherFn: getContactListing,
                    columns: contactColumns,
                    name: '',
                    showPagination: false,
                    showPageCount: false,
                    showRefresh: false,
                    showSpinner: false,
                    extraParams: {
                      limit: 10,
                      // filter_date: generateDateObjectFromTo({
                      //   dateType: callDurationFilter,
                      // }),
                    },
                    setIsRefetching: setContactAgentTableLoad,
                  }}
                />
              )}
              {bottomLeftTab === 'employee' && (
                <TableManager
                  {...{
                    fetcherKey: 'getMemberListing',
                    fetcherFn: getMemberListing,
                    columns,
                    name: 'Employees',
                    shouldShowColumnName: true,
                    showPagination: false,
                    showPageCount: false,
                    showRefresh: false,
                    extraParams: {
                      get_owner: 'Y',
                      // filter_date: generateDateObjectFromTo({
                      //   dateType: callDurationFilter,
                      // }),
                    },
                    fromDashboard: true,
                    setIsRefetching: setContactAgentTableLoad,
                  }}
                />
              )}

              {bottomLeftTab === 'agent' && (
                <TableManager
                  {...{
                    fetcherKey: 'agentList',
                    fetcherFn: agentList,
                    columns,
                    name: 'Agents',
                    shouldShowColumnName: true,
                    showPagination: false,
                    showPageCount: false,
                    showRefresh: false,
                    extraParams: {
                      get_owner: 'Y',
                      // filter_date: generateDateObjectFromTo({
                      //   dateType: callDurationFilter,
                      // }),
                    },
                    fromDashboard: true,
                    setIsRefetching: setContactAgentTableLoad,
                  }}
                />
              )}
            </div>
            <div className="w--full h--full d--flex flex--column ">
              <div className="w--full halfTable ">
                <div className="d--flex justify-content--between align-items--center">
                  <div className="d--flex gap--sm align-items--center">
                    <span
                      className={`font--md  c--pointer ${
                        rightTabs === 'voicemail'
                          ? 'text--secondary font--600'
                          : 'font--500'
                      }`}
                      onClick={() => setRightTabs('voicemail')}
                    >
                      Voicemail
                    </span>
                    <span>|</span>
                    <span
                      className={`font--md   c--pointer ${
                        rightTabs === 'recording'
                          ? 'text--secondary font--600'
                          : 'font--500'
                      }`}
                      onClick={() => setRightTabs('recording')}
                    >
                      Recording
                    </span>
                    {voicemailRecordingTableLoad && <Spinner />}
                  </div>
                  <Button
                    variant="white"
                    btnClasses="btn w-max--36 box-shadow--xs"
                    color="primary"
                    type="button"
                    icon={<RefreshIcon width={20} height={20} />}
                    onClick={() => {
                      if (rightTabs === 'voicemail') {
                        queryClient.invalidateQueries({
                          queryKey: [
                            'callHistory',
                            {
                              request_type: 'voicemail-list',
                            },
                          ],
                          exact: false,
                        });
                      }
                      if (rightTabs === 'recording') {
                        queryClient.invalidateQueries({
                          queryKey: [
                            'callHistory',
                            {
                              request_type: 'recording-listing',
                            },
                          ],
                          exact: false,
                        });
                      }
                    }}
                    size="sm"
                    data-tooltip="Refresh"
                    tooltip-position="top"
                  />
                </div>
                {rightTabs === 'voicemail' ? (
                  <TableManager
                    {...{
                      isCustomDesign: true,
                      fetcherKey: 'callHistory',
                      fetcherFn: callHistory,
                      columns: voicemailColumns,
                      name: 'Voicemail',
                      shouldShowColumnName: false,
                      showPagination: false,
                      showPageCount: false,
                      extraParams: {
                        type: 'ALL',
                        request_type: 'voicemail-list',
                        limit: 10,
                        filter_date: generateDateObjectFromTo({
                          dateType: callDurationFilter,
                        }),
                      },
                      showRefresh: false,
                      fromDashboard: true,
                      setIsRefetching: setVoicemailRecordingTableLoad,
                    }}
                  />
                ) : (
                  <TableManager
                    {...{
                      isCustomDesign: true,
                      fetcherKey: 'callHistory',
                      fetcherFn: callHistory,
                      columns: recordingColumns,
                      name: 'Recording',
                      shouldShowColumnName: false,
                      showPagination: false,
                      showPageCount: false,
                      showRefresh: false,
                      extraParams: {
                        type: 'ALL',
                        request_type: 'recording-listing',
                        limit: 10,
                        filter_date: generateDateObjectFromTo({
                          dateType: callDurationFilter,
                        }),
                      },
                      setIsRefetching: setVoicemailRecordingTableLoad,
                      fromDashboard: true,
                    }}
                  />
                )}
              </div>
            </div>
            {/* <div className="d--flex flex--column  w--full">
            <div className="w--full d--flex align-items--center  h-min--36">
              <Tooltip
                title="tooltip "
                data-tooltip="Tooltip "
                tooltip-position="left"
              >
                <div className="font--md text--primary">Statistics</div>
              </Tooltip>
            </div>
            <div className="w--full d--flex flex--column gap--md ">
              <div className="d--flex  w--full">
                <div className="d--flex gap--md w--full ">
                  <div
                    className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer"
                    onClick={() => {
                      if ([USER].includes(user?.role_id)) {
                        navigate('/reports/calls?callType=total');
                      }
                    }}
                  >
                    <div className="d--flex gap--xs align-items--center justify-content--between text--secondary ">
                      <div className="text--primary radius--sm bg--primary-100  w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center">
                        <CallIcon width={18} height={18} />
                      </div>
                      Total Calls
                    </div>
                    <div className="font--xl  font--600 text--black-400  w--full text--r">
                      {data?.data?.data?.total_calls ?? 0}
                    </div>
                  
                  </div>
                  <div
                    className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer"
                    onClick={() => {
                      if ([USER].includes(user?.role_id)) {
                        navigate('/reports/calls?callType=outbound');
                      }
                    }}
                  >
                    <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
                      <div className="text--success radius--sm bg--success-100  w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center">
                        <OutgoingCall width={18} height={18} />
                      </div>
                      Outgoing
                    </div>
                    <div className="font--xl  font--600 text--black-400  w--full text--r">
                      {data?.data?.data?.outgoing_calls ?? 0}
                    </div>
                  
                  </div>
                  <div
                    className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer"
                    onClick={() => {
                      if ([USER].includes(user?.role_id)) {
                        navigate('/reports/calls?callType=inbound');
                      }
                    }}
                  >
                    <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
                      <div className="text--info radius--sm bg--info-100  w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center">
                        <IncomingCall width={18} height={18} />
                      </div>
                      Answered
                    </div>
                    <div className="font--xl  font--600 text--black-400  w--full text--r">
                      {data?.data?.data?.incoming_calls ?? 0}
                    </div>
                  
                  </div>
                  <div
                    className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer"
                    onClick={() => {
                      if ([USER].includes(user?.role_id)) {
                        navigate('/reports/calls?callType=missed');
                      }
                    }}
                  >
                    <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
                      <div className="text--danger radius--sm bg--danger-100  w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center">
                        <MissedCall width={18} height={18} />
                      </div>
                      Missed
                    </div>
                    <div className="font--xl  font--600 text--black-400  w--full text--r">
                      {data?.data?.data?.missed_calls ?? 0}
                    </div>
                  
                  </div>
                </div>
              </div>
              <div className="d--flex  gap--md w--full">
                <div className="d--flex gap--md w--full ">
                  <div
                    className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer"
                    onClick={() => {
                      if ([USER].includes(user?.role_id)) {
                        navigate('/reports/sms?smsType=total');
                      }
                    }}
                  >
                    <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
                      <div className="text--primary radius--sm bg--primary-100  w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center">
                        <MessageIcon width={18} height={18} />
                      </div>
                      Total SMS
                    </div>
                    <div className="font--xl  font--600 text--black-400  w--full text--r">
                      {data?.data?.data?.total_sms ?? 0}
                    </div>
                  
                  </div>
                  <div
                    className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer"
                    onClick={() => {
                      if ([USER].includes(user?.role_id)) {
                        navigate('/reports/sms?smsType=inbound');
                      }
                    }}
                  >
                    <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
                      <div className="text--success radius--sm bg--success-100  w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center">
                        <SMSReceivedIcon width={18} height={18} />
                      </div>
                      Received
                    </div>
                    <div className="font--xl  font--600 text--black-400  w--full text--r">
                      {data?.data?.data?.incoming_sms ?? 0}
                    </div>
                  
                  </div>
                  <div
                    className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer"
                    onClick={() => {
                      if ([USER].includes(user?.role_id)) {
                        navigate('/reports/sms?smsType=outbound');
                      }
                    }}
                  >
                    <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
                      <div className="text--info radius--sm bg--info-100  w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center">
                        <SMSSendIcon width={18} height={18} />
                      </div>
                      Sent
                    </div>
                    <div className="font--lg  font--600 text--black-400 w--full text--r">
                      {data?.data?.data?.outgoing_sms ?? 0}
                    </div>
                  
                  </div>
                  <div
                    className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer"
                    onClick={() => {
                      if ([USER, AGENCY_OWNER].includes(user?.role_id)) {
                        navigate('/virtual-numbers');
                      }
                    }}
                  >
                    <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
                      <div className="text--danger radius--sm bg--danger-100  w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center">
                        <SMSSendIcon width={18} height={18} />
                      </div>
                      Numbers
                    </div>
                    <div className="font--xl  font--600 text--black-400  w--full text--r">
                      {data?.data?.data?.numbers ?? 0}
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
            <div className="w--full d--flex flex--column gap--sm ">
              <div className="w--full m-t--xs">
                <Tooltip
                  title="tooltip "
                  data-tooltip="Tooltip "
                  tooltip-position="left"
                >
                  <div className="font--md">Weekly SMS Stats</div>
                </Tooltip>
              </div>
              
            </div>
          </div> */}
          </div>
        </div>
      </div>
      {showTranscript && (
        <AsideModal
          handleClose={handleClose}
          title={'Call Intelligence'}
          footerComponent={null}
          width="md"
        >
          <Transcripts2
            transcript_file_url={`${
              process.env.REACT_APP_MEDIA_URL
            }signed-url/${user?.parent_uuid || user?.uuid}/recording?filename=${
              transcriptData?.transcript_file
            }`}
            recording_file_url={`${process.env.REACT_APP_MEDIA_URL}signed-url/${
              user?.parent_uuid || user?.uuid
            }/recording?filename=${transcriptData?.recording_file}`}
          />
          {/* <Transcripts
              transcript_file_url={transcriptData?.transcript_file_url}
            /> */}
        </AsideModal>
      )}
      {playingData?.file && (
        <AudioPlayer
          onClose={() => {
            setPlayingData({ playing: false, file: null });
          }}
          name={playingData?.file}
        />
      )}
      {screenShotPreviewData && (
        <CallScreenshotPreview
          handleCloseModal={() => setScreenshotPreviewData(null)}
          callData={screenShotPreviewData}
        />
      )}
    </div>
  );
}
