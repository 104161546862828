import { useMutation, useQueryClient } from '@tanstack/react-query';
import { memberSetDefaultDid } from '../../api';
import { useAlert } from '../useAlert';

export default function useSetDefaultDid() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const setDefaultDidRequest = useMutation({
    mutationFn: memberSetDefaultDid,
    mutationKey: 'memberSetDefaultDid',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(['didList']);
      queryClient.invalidateQueries(['getUserDetails']);
      showAlert({
        type: 'success',
        message: data?.message ?? 'Did updated successully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...setDefaultDidRequest };
}
