import { useMutation } from '@tanstack/react-query';
import { login } from '../../api';
import { useAuth } from '../useAuth';
import { useAlert } from '../useAlert';
import { useNavigate } from 'react-router-dom';
import { AGENT, isElectron } from '../../helpers/functions';

export default function useLogin({ handleSuccess = () => null } = {}) {
  const { setUser } = useAuth();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const loginRequest = useMutation({
    mutationFn: login,
    mutationKey: 'login',
    onSuccess: ({ data }) => {
      console.log('useLogin data', data);
      localStorage.setItem('device_track_uuid', data?.device_track_uuid);
      handleSuccess(data);
      setUser({ token: data?.data, role_id: data?.role_id });
      navigate('/dashboard');
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...loginRequest };
}
