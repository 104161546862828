import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import { useAuth } from '../../hooks/useAuth';
import useAllNumberDetails from '../../hooks/useAllNumberDetails';
import Spinner from '../Spinner';

const CallScreenshotPreview = ({ handleCloseModal = () => null, callData }) => {
  const [previewURL, setPreviewURL] = useState([]);
  const { user } = useAuth();
  const { getNumberName } = useAllNumberDetails();
  useEffect(() => {
    handlePreviewURL(callData);
  }, []);

  function handlePreviewURL(callData) {
    if (['EXTENSION'].includes(callData?.forward_type)) {
      const url = `${process.env.REACT_APP_MEDIA_URL}signed-url/${
        user?.parent_uuid || user?.uuid
      }/call_screenshot_log?filename=${callData?.sipcall_id}_${
        callData?.forward_value
      }`;
      setPreviewURL([{ src: url, extension: callData?.forward_value }]);
    }
    if (['DEPARTMENT', 'IVR'].includes(callData?.forward_type)) {
      const memberArray = callData?.members?.split(',') || [];
      const urls = [];
      for (let index = 0; index < memberArray.length; index++) {
        const member = memberArray[index];
        if (member) {
          const url = `${process.env.REACT_APP_MEDIA_URL}signed-url/${
            user?.parent_uuid || user?.uuid
          }/call_screenshot_log?filename=${callData?.sipcall_id}_${member}`;
          urls.push({ src: url, extension: member });
        }
      }
      setPreviewURL(urls);
    }
  }
  return (
    <Modal
      handleClose={() => handleCloseModal()}
      headerComponent={null}
      footerComponent={
        <>
          <button
            className="btn btn--secondary btn--md"
            onClick={() => handleCloseModal()}
          >
            Close
          </button>
        </>
      }
      keyboard={false}
      size="1000"
      height="500"
    >
      <div
        className="h--full w--full"
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        {previewURL.map((item) => {
          const name = getNumberName(item?.extension, false, false);
          return (
            <div
              className="d--flex flex--column align-items--center gap--md"
              style={{
                flex: '1 1 calc(33.33% - 10px)',
                boxSizing: 'border-box',
                textAlign: 'center',
                padding: '10px',
              }}
            >
              <div className="font--700 font--lg">
                {name}&nbsp;({item?.extension})
              </div>

              <ImageComponent src={item?.src} />
            </div>
          );
        })}
        {previewURL.length === 0 && (
          <div className="d--flex align-items--center justify-content--center w--full">
            No Screenshot Available
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CallScreenshotPreview;

const ImageComponent = ({ src }) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isImageBroken, setIsImageBroken] = useState(false);

  return (
    <>
      {isImageLoading && <Spinner />}
      {!isImageBroken && (
        <img
          className="c--pointer"
          src={src}
          onError={(e) => {
            setIsImageLoading(false);
            setIsImageBroken(true);
          }}
          onLoad={() => setIsImageLoading(false)}
          loading="lazy"
          onClick={() => {
            window.open(src);
          }}
        />
      )}
      {isImageBroken && (
        <div className="w--full h--full radius--lg border border-full--black h-min--400 d--flex align-items--center justify-content--center">
          No Screenshot Available
        </div>
      )}
    </>
  );
};
