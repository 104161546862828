'use client';
import React, { useEffect, useState } from 'react';
import useIcons from '../../assets/icons/useIcons';
import AudioPlayer from '../AudioPlayer';
import SingleDropdown from '../SingleDropdown';
import { Controller } from 'react-hook-form';
const forwardingOptions = [
  'Department',
  'IVR',
  'Number',
  'Voicemail',
  'Department Voicemail',
  'Hangup',
  'Extension',
];

const failoverOptions = ['Number', 'Voicemail', 'Hangup'];

const timeout_options = ['20', '30', '35', '40', '45'];

function DepartmentSelect({ option, register, departmentsData = [], value }) {
  const handleOptions = (option) => {
    switch (option) {
      case 'Department Voicemail':
        const departmentsListing = departmentsData?.data?.data ?? [];
        return (
          <>
            {departmentsListing &&
              departmentsListing.length > 0 &&
              departmentsListing.map((item) => {
                return (
                  <option key={item?.uuid} value={item?.uuid}>
                    {item?.title}
                  </option>
                );
              })}
          </>
        );
      case 'Department':
        const departmentsListing2 = departmentsData?.data?.data ?? [];
        return (
          <>
            {departmentsListing2 &&
              departmentsListing2.length > 0 &&
              departmentsListing2.map((item) => {
                return (
                  <option key={item?.uuid} value={item?.uuid}>
                    {item?.title}
                  </option>
                );
              })}
          </>
        );

      default:
        break;
    }
  };
  return (
    <select
      required
      {...register(value)}
      className={`form--control w--full h-min--32  radius--sm p-l--md p-r--md border-full--black-200 `}
    >
      <option value="">Select</option>
      {handleOptions(option)}
    </select>
  );
}
function IVRSelect({ option, register, ivrListData = [], value }) {
  const handleOptions = (option) => {
    switch (option) {
      case 'IVR':
        const ivrListing = ivrListData?.data?.data ?? [];
        return (
          <>
            {ivrListing &&
              ivrListing.length > 0 &&
              ivrListing.map((item) => {
                return (
                  <option key={item?.uuid} value={item?.uuid}>
                    {item?.name}
                  </option>
                );
              })}
          </>
        );

      default:
        break;
    }
  };
  return (
    <select
      required
      {...register(value)}
      className={`form--control w--full h-min--32  radius--sm p-l--md p-r--md border-full--black-200 `}
    >
      <option value="">Select</option>
      {handleOptions(option)}
    </select>
  );
}
function VoicemailSelect({
  option,
  register,
  greetingList = [],
  value,
  watch,
}) {
  const { HoldIcon, PlayIcon } = useIcons();
  const [isPlaying, setIsPlaying] = useState({
    isPlaying: false,
    playingFile: null,
  });
  const handleOptions = (option) => {
    switch (option) {
      case 'Voicemail':
        const greetingListing = greetingList ?? [];
        return (
          <>
            {greetingListing &&
              greetingListing.length > 0 &&
              greetingListing.map((item) => {
                return (
                  <option
                    key={item?.filename}
                    value={`${item?.filename}&${item?.uuid}`}
                  >
                    {item?.name}
                  </option>
                );
              })}
          </>
        );

      default:
        break;
    }
  };
  const voicemailVal = watch(value);
  return (
    <div className="d--flex align-items--center gap--md">
      <select
        {...register(value)}
        className={`form--control w--full h-min--32  radius--sm p-l--md p-r--md border-full--black-200 `}
      >
        <option value="">Select</option>
        {handleOptions(option)}
      </select>
      {voicemailVal && (
        <>
          {isPlaying.isPlaying ? (
            <div
              className="c--pointer"
              onClick={() => {
                setIsPlaying({ isPlaying: false, playingFile: null });
              }}
            >
              <HoldIcon width={20} height={20} />
              <AudioPlayer
                onClose={() => {
                  setIsPlaying(false);
                }}
                type="greeting"
                name={isPlaying.playingFile}
              />
            </div>
          ) : (
            <div
              className="c--pointer d--flex h--full align-items--center text--success"
              onClick={() => {
                setIsPlaying({
                  isPlaying: true,
                  playingFile: voicemailVal?.split('&')?.[0],
                });
              }}
            >
              <PlayIcon width={20} height={20} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
function ForwardInput({ option, register, value = '', setValue }) {
  return (
    <input
      {...register(value)}
      onChange={(e) =>
        setValue(
          value,
          e.target.value
            .toString()
            .replace(/[^+0-9*#]/g, '')
            .slice(0, 13),
        )
      }
      placeholder={'Enter Number'}
      className={`form--control w--full h-min--32 radius--sm p-l--md p-r--md border-full--black-200 `}
      required
    />
  );
}

function getOptionsMap(
  option,
  register,
  memberListData = [],
  departmentsData = [],
  ivrListData = {},
  value = '',
  setValue,
  control,
  errorMessage = '',
  failover_value = '',
  failover_type = '',
  memberListLoad = false,
) {
  switch (option) {
    case 'Extension':
      return (
        <>
          <Controller
            name={value}
            control={control}
            render={({ field }) => (
              <SingleDropdown
                {...field}
                labelKey={'first_name'}
                onChange={(val) => {
                  field.onChange(val);
                  if (failover_type === 'Voicemail') {
                    setValue(failover_value, val);
                  }
                }}
                optionListLoad={memberListLoad}
                optionList={
                  memberListData?.data?.data?.filter((item) => {
                    return item?.verified === 1 && item?.is_active === 1;
                  }) || []
                }
                error={errorMessage}
              />
            )}
          />
        </>
      );
    case 'Voicemail':
      return (
        <>
          <Controller
            name={value}
            control={control}
            render={({ field }) => (
              <SingleDropdown
                {...field}
                labelKey={'first_name'}
                optionList={
                  memberListData?.data?.data?.filter((item) => {
                    return item?.verified === 1 && item?.is_active === 1;
                  }) || []
                }
                error={errorMessage}
              />
            )}
          />
        </>
      );
    case 'Department Voicemail':
      return (
        <>
          <DepartmentSelect
            option={option}
            register={register}
            departmentsData={departmentsData}
            value={value}
          />
          <span className="w--full text--danger">{errorMessage}</span>
        </>
      );
    case 'Department':
      return (
        <>
          <DepartmentSelect
            option={option}
            register={register}
            departmentsData={departmentsData}
            value={value}
          />
          <span className="w--full text--danger">{errorMessage}</span>
        </>
      );
    case 'IVR':
      return (
        <>
          <IVRSelect
            option={option}
            register={register}
            ivrListData={ivrListData}
            value={value}
          />
          <span className="w--full text--danger">{errorMessage}</span>
        </>
      );
    case 'Number':
      return (
        <>
          <ForwardInput
            option={option}
            register={register}
            value={value}
            setValue={setValue}
          />
          <span className="w--full text--danger">{errorMessage}</span>
        </>
      );
    default:
      return null;
  }
}

function FilterItem({
  option,
  register,
  optionType,
  errors,
  memberListData = [],
  departmentsData = [],
  ivrListData = {},
  type = '',
  value = '',
  watch,
  setValue,
  control,
  failover_value,
  failover_type,
  memberListLoad
}) {
  return (
    <div className="d--flex align-items--center justify-content--between h-min--40 gap--sm">
      <div className="d--flex align-items--center gap--sm w--full">
        <input
          {...register(type)}
          className="radio"
          id={`${type}${option}`}
          type="radio"
          value={option}
          onChange={(e) => {
            setValue(type, e.target.value);
            setValue(value, '');
          }}
        />
        <label
          className={`font--sm font--500 ${
            errors?.type?.message ? 'text--danger' : ''
          }`}
          htmlFor={`${type}${option}`}
        >
          {option === 'Hangup' ? 'Hangup' : `Forward to ${option}`}
        </label>
      </div>

      <span className="d--flex w--full w-max--350 flex--column">
        {optionType === option && (
          <>
            {getOptionsMap(
              option,
              register,
              memberListData,
              departmentsData,
              ivrListData,
              value,
              setValue,
              control,
              errors?.value?.message,
              failover_value,
              watch(failover_type),
              memberListLoad
            )}
          </>
        )}
      </span>
    </div>
  );
}

export default function ({
  register,
  watch,
  clearErrors,
  errors,
  memberListData = [],
  type = '',
  value = '',
  failover_type = '',
  failover_value = '',
  timeout = '',
  setValue,
  departmentsData = [],
  ivrListData = {},
  greetingList = [],
  control,
  memberListLoad=false
}) {
  const optionType = watch(type);

  useEffect(() => {
    if (optionType) {
      clearErrors();
    }
  }, [optionType, clearErrors]);

  return (
    <div className="d--flex flex--column gap--md">
      {forwardingOptions && forwardingOptions.length > 0
        ? forwardingOptions.map((option) => (
            <FilterItem
              key={option}
              option={option}
              register={register}
              watch={watch}
              errors={errors}
              optionType={optionType}
              memberListData={memberListData}
              departmentsData={departmentsData}
              ivrListData={ivrListData}
              type={type}
              value={value}
              greetingList={greetingList}
              setValue={setValue}
              control={control}
              failover_value={failover_value}
              failover_type={failover_type}
            />
          ))
        : null}
      <span className="w--full text--danger">
        {errors?.type && errors?.type?.message}
      </span>
      {optionType === 'Extension' && (
        <div className="d--flex flex--column gap--md">
          <div className="d--flex w--full gap--sm align-items--center">
            <div className=" w--full text--grey">
              When user does not answer the call within
            </div>
            <div className="w--full w-max--350">
              <select
                required
                {...register(timeout)}
                className={`form--control w--full h-min--32  w-max--100 radius--sm p-l--md p-r--md border-full--black-200 `}
              >
                {timeout_options.map((number) => (
                  <option value={number} key={number}>
                    {number} sec
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="d--flex w--full gap--md  align-items--center">
            <div className="text--grey">forward all calls to</div>
            <select
              required
              {...register(failover_type)}
              onChange={(e) => {
                setValue(failover_type, e.target.value);
                setValue(failover_value, '');
              }}
              className={`form--control w--full h-min--32 w-max--150  radius--sm p-l--md p-r--md border-full--black-200 `}
            >
              {failoverOptions && failoverOptions.length > 0
                ? failoverOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))
                : null}
            </select>
            <div className="w-max--150 w-100">
              {getOptionsMap(
                watch(failover_type),
                register,
                memberListData,
                departmentsData,
                ivrListData,
                failover_value,
                setValue,
                control,
                errors?.failover?.value?.message,
                failover_value,
                watch(failover_type),
                memberListLoad
              )}
              {/* <span className="w--full text--danger">
                {errors?.failover?.value && errors?.failover?.value?.message}
              </span> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
