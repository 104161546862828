import React from 'react';
import {  SecondsTohhmmss } from '../../../helpers/functions';
import { employeeAgentStats } from '../../../api';
import { useQuery } from '@tanstack/react-query';

const EmployeeAgentStatistics = ({
  handleClose = () => null,
  showStatistics,
}) => {

  const { data: statsData } = useQuery(
    ['employeeAgentStats', showStatistics?.elementData?.uuid],
    () => employeeAgentStats(showStatistics?.elementData?.uuid),
    {
      select: (data) => data?.data?.data,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(showStatistics?.elementData?.uuid),
    },
  );

  
  return (
    <div className="overflow--auto dialogScroll d--flex flex--column gap--lg p--md">
      <div className="d--flex w--full flex--column gap--md">
        {/* <div className="font--sm font--600 text--primary d--flex gap--sm align-items--center">
          Statistics for&nbsp;
          {showStatistics?.elementData?.role_id === 3 ? 'employee' : 'agent'}
          <span className="font--md font--600 text--black">({showStatistics?.elementData?.extension})</span>
        </div> */}
        <div className="d--flex gap--md">
          <div className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer">
            <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
              {/* <div className="text--danger radius--sm bg--danger-100  w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center">
                                <SMSSendIcon width={18} height={18} />
                            </div> */}
              Total Calls
            </div>
            <div className="font--xl  font--600 text--black-400  w--full ">
              {statsData?.total_call}
            </div>
          </div>
          <div className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer">
            <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
              Inbound
            </div>
            <div className="font--xl  font--600 text--black-400  w--full ">
            {statsData?.total_inbound}
            </div>
          </div>
          <div className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer">
            <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
              Outbound
            </div>
            <div className="font--xl  font--600 text--black-400  w--full ">
            {statsData?.total_outbound}
            </div>
          </div>
          <div className="h--full w--full bg--white box-shadow--xs p--md radius--sm h-min--60 callBoxStat font--sm font--600 gap--sm d--flex flex--column c--pointer">
            <div className="d--flex gap--sm align-items--center justify-content--between text--secondary">
              Duration
            </div>
            <div className="font--xl  font--600 text--black-400  w--full ">
            {SecondsTohhmmss(statsData?.total_duration || 0)}
            </div>
          </div>
        </div>
      </div>
      <div className="d--flex w--full flex--column gap--md agentEmployeeModal">
        {/* <div className='font--sm font--600 text--primary d--flex gap--sm align-items--center'>Statestics for agent <span className='font--md font--600 text--black'>(1034)</span></div> */}
        <div className="w--full h-max--300  agentEmployeeTable">
          <div className="table--responsive ">
            <table className="table border-bottom--black-100 ">
              <thead>
                <tr>
                  <th>Inbound ({statsData?.total_inbound})</th>
                  <th>Received</th>
                  <th>Answered</th>
                  <th>Missed</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Ext</th>
                  <td>{statsData?.inbound?.ext?.received}</td>
                  <td>{statsData?.inbound?.ext?.answered}</td>
                  <td>{statsData?.inbound?.ext?.missed}</td>
                  <td>{SecondsTohhmmss(statsData?.inbound?.ext?.duration)}</td>
                </tr>
                <tr>
                  <th>Dept</th>
                  <td>{statsData?.inbound?.dept?.received}</td>
                  <td>{statsData?.inbound?.dept?.answered}</td>
                  <td>{statsData?.inbound?.dept?.missed}</td>
                  <td>{SecondsTohhmmss(statsData?.inbound?.dept?.duration)}</td>
                </tr>
                <tr>
                  <th>IVR</th>
                  <td>{statsData?.inbound?.ivr?.received}</td>
                  <td>{statsData?.inbound?.ivr?.answered}</td>
                  <td>{statsData?.inbound?.ivr?.missed}</td>
                  <td>{SecondsTohhmmss(statsData?.inbound?.ivr?.duration)}</td>
                </tr>
                <tr>
                  <th>Voicemail</th>
                  <td>{statsData?.inbound?.voicemail?.received}</td>
                  <td>{statsData?.inbound?.voicemail?.answered}</td>
                  <td>{statsData?.inbound?.voicemail?.missed}</td>
                  <td>{SecondsTohhmmss(statsData?.inbound?.voicemail?.duration)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="w--full h-max--300 agentEmployeeTable">
          <div className="table--responsive ">
            <table className="table border-bottom--black-100 ">
              <thead>
                <tr>
                  <th>Outbound ({statsData?.total_outbound})</th>
                  {/* <th>Received</th> */}
                  <th>Answered</th>
                  <th>Missed</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Call</th>
                  {/* <td>0</td> */}
                  <td>{statsData?.outbound?.answered}</td>
                  <td>{statsData?.outbound?.missed}</td>
                  <td>{SecondsTohhmmss(statsData?.outbound?.duration)}</td>
                </tr>
                {/* <tr>
                  <th>Dept</th>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                <tr>
                  <th>IVR</th>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAgentStatistics;