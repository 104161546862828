import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../../comopnents/FormInput';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../../../comopnents/Button';
import FormSelect from '../../../../comopnents/FormSelect';
import PhoneInput from 'react-phone-number-input';
import * as yup from 'yup';
import useCountryList from '../../../../hooks/useCountryList';
import PlanDetails from '../PlanDetails';
import useSignup from '../../../../hooks/useSignup';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetPlan from '../../../../hooks/useGetPlan';

const RenderTimeZoneItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.abbreviation}
      value={item.abbreviation}
    >
      {item.zoneName}
    </option>
  );
};

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  country: 'US',
  phone: '',
  timezone: '',
  tenant_company: '',
  tenant_npn: '',
  tenant_fein: '',
  acceptedTerms: false,
};
const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required('First name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers'),
  last_name: yup
    .string()
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Last name cannot contain numbers'),
  country: yup.string().required(`Country field is required.`),
  timezone: yup.string().required(`Timezone field is required.`),
  password: yup.string().required(`Password field is required.`),
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email')
    .max(50, 'Maximum length can be 50 characters'),
  phone: yup.string().required('Phone is required'),
  tenant_company: yup
    .string()
    .required('Tenant company is required')
    .matches(
      /^[A-Za-z'-]+[ A-Za-z'-]*$/,
      'Tenant company cannot contain numbers',
    ),
  tenant_npn: yup
    .string()
    .matches(/^[0-9]+$/, 'Tenant npn must contain only  numbers')
    .required('Tenant npn is required'),
  tenant_fein: yup
    .string()
    .matches(
      /^[A-Za-z0-9]+$/,
      'Tenant fein must contain only letters and numbers',
    )
    .required('Tenant fein is required'),
  timezone: yup.string().required('Timezone is required'),
  acceptedTerms: yup
    .boolean()
    .oneOf([true], 'Please agree to the terms and conditions.'),
});
function SignupForm() {
  const countries = useCountryList();
  const { mutate: signupMutate, isLoading: signupLoad } = useSignup({
    handleSuccess,
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const planUuid = searchParams.get('plan_uuid') || '';
  const duration = searchParams.get('duration') || '';
  const { mutate, data } = useGetPlan();
  const planDetails = data?.data?.data || {};

  useEffect(() => {
    if (planUuid && duration) {
      mutate({ plan_uuid: planUuid, plan_duration: duration });
    }
  }, [mutate, planUuid, duration]);

  const {
    control,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });
  const selectedCountry = watch('country');

  const timezones = selectedCountry
    ? countries?.find((country) => country.isoCode === selectedCountry)
        ?.timezones
    : [];

  function handleSuccess() {
    navigate(`/verifyotp?plan_uuid=${planUuid}&duration=${duration}`, {
      state: { userData: getValues() },
    });
  }
  const onSubmit = (values) => {
    const {
      first_name = '',
      last_name = '',
      email = '',
      password = '',
      phone = '',
      country = '',
      timezone = '',
      tenant_company = '',
      tenant_npn = '',
      tenant_fein = '',
    } = values;
    const payload = {
      first_name,
      last_name,
      phone,
      password,
      country,
      planID: planUuid,
      plan_duration: duration,
      timezone,
      email,
      tenant_npn,
      tenant_company,
      tenant_fein,
      type: 'signup',
    };
    signupMutate(payload);
  };

  return (
    <div className="d--flex ">
      <div className="container">
        <div className="d--flex ">
          <PlanDetails
            PlanDetails={planDetails}
            plan_duration={duration}
            plan_uuid={planUuid}
          />
          <div className="w--full radius--md  gap--xl w-max--600 p--xl m-b--md z-index--xs bg--white box-shadow--xs d--flex align-items--center justify-content--center flex--column  h-min--300">
            <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center">
              <h3 className="font--2xl text--primary">Create account</h3>
              <h5 className="font--md font--400">Sign up with email address</h5>
            </div>
            <form
              className="w--full h--full"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
                <div>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="text"
                        label="First Name*"
                        placeholder="Enter first name"
                        error={errors?.first_name?.message}
                        autoFocus={true}
                      />
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="text"
                        label="Last Name*"
                        placeholder="Enter last name"
                        error={errors?.last_name?.message}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="text"
                        label="Email*"
                        placeholder="Enter your email address"
                        error={errors?.email?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <label
                    className={`label--control font--sm font--500 m-b--xs   `}
                  >
                    Phone
                  </label>

                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        // disabled={fromDialpad}
                        control={control}
                        defaultCountry={watch('country')}
                        international={true}
                        addInternationalOption={false}
                        withCountryCallingCode={true}
                        countryCallingCodeEditable={false}
                        placeholder="Enter your phone"
                        error={errors?.phone?.message}
                        internationalIcon={() => null}
                        flagComponent={() => null}
                        countrySelectComponent={() => null}
                        value={getValues(`phone`)}
                        limitMaxLength={true}
                        onChange={(phone) => setValue(`phone`, phone)}
                        className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                          errors?.phone?.message
                            ? 'border-full--danger'
                            : 'border-full--black-200'
                        }`}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="tenant_company"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        placeholder="Enter tenant company"
                        maxLength={20}
                        label="Tenant Company*"
                        type="text"
                        error={errors?.tenant_company?.message}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="tenant_npn"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        placeholder="Enter tenant NPN"
                        maxLength={12}
                        label="Tenant NPN*"
                        type="text"
                        error={errors?.tenant_npn?.message}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="tenant_fein"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        placeholder="Enter tenant FEIN"
                        maxLength={12}
                        label="Tenant FEIN*"
                        type="text"
                        error={errors?.tenant_fein?.message}
                      />
                    )}
                  />
                </div>

                <div className="w--full">
                  <Controller
                    name="timezone"
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        {...field}
                        options={timezones || []}
                        renderOption={RenderTimeZoneItem}
                        placeholder="Please select timezone"
                        label="Timezone*"
                        error={errors?.timezone?.message}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="password"
                        label="Password*"
                        placeholder="Enter your password"
                        error={errors?.password?.message}
                      />
                    )}
                  />
                </div>

                <div className="w--full gap--sm d--flex align-items--center radio-check">
                  <Controller
                    control={control}
                    name="acceptedTerms"
                    render={({ field }) => (
                      <input {...field} id="exampleRadios1" type="checkbox" />
                    )}
                  />
                  <label
                    htmlFor="exampleRadios1"
                    className="label-control m-r--sm mb-1 d--flex gap--xs text--black-600"
                  >
                    I Agree with Terms and conditions
                  </label>
                </div>
                {errors.acceptedTerms && (
                  <p className="label--control font--sm font--500 m-b--xs  text--danger">
                    {errors.acceptedTerms.message}
                  </p>
                )}
              </div>

              <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md">
                <Button
                  type="submit"
                  size=""
                  variant="primary"
                  color="white"
                  btnClasses="btn"
                  disabled={signupLoad}
                >
                  {signupLoad ? 'Please wait..' : 'Create Account'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupForm;
