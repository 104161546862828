import { useMutation } from '@tanstack/react-query';
import { setPassword } from '../../api';
import { useAlert } from '../useAlert';
import { useAuth } from '../useAuth';

export default function useSetPassword() {
  const { clearUser } = useAuth();
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: setPassword,
    mutationKey: 'setPassword',
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.message ?? 'Password updated successully',
      });
      clearUser();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
