import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../comopnents/Button';
import FormInput from '../../../../comopnents/FormInput';
import * as yup from 'yup';
import GreetingRecorder from './GreetingRecorder';
import GreetingPlayer from './GreetingPlayer';
import useIcons from '../../../../assets/icons/useIcons';
import { useAuth } from '../../../../hooks/useAuth';
import useUploadMedia from '../../../../hooks/useUploadMedia';
import useGreetingCreate from '../../../../hooks/useGreetingCreate';
import { loadAudioFileAsync } from '../../../../helpers/functions';
import FormSelect from '../../../../comopnents/FormSelect';

let draggingTimeout;
const initialValues = {
  custom_greeting: 'file',
  greeting_name: '',
  file: null,
  recorded_file: null,
  type: 'greeting',
};
const audioContext = new AudioContext();

const validationSchema = yup.object().shape({
  custom_greeting: yup.string(),
  type: yup.string().required('Type is required'),
  greeting_name: yup
    .string()
    .required('Name is required')
    .test(
      'noSpecialCharacter',
      'Name can not contain only special characters',
      (val) => {
        const regex = /^[^\w\s]+$/;
        if (regex.test(val?.trim())) {
          return false;
        }
        return true;
      },
    )
    .test(
      'minLength',
      'Name cannot be empty',
      (val) => val && val?.trim().length > 0,
    ),
  file: yup.mixed().when('custom_greeting', {
    is: (val) => val === 'file',
    then: () => yup.mixed().required('File is required'),
  }),
  recorded_file: yup.mixed().when('custom_greeting', {
    is: (val) => val === 'record',
    then: () => yup.mixed().required('Recording is required'),
  }),
});

const RenderType = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.value}
      value={item.value}
    >
      {item.label}
    </option>
  );
};

const AddGreetingForm = ({ handleClose = () => null, type = '' }) => {
  const { user } = useAuth();
  const { CrossRoundIcon, UploadIcon } = useIcons();
  const [isUploading, setIsUploading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const { mutateAsync: getUploadUrl, isLoading: getUploadUrlLoad } =
    useUploadMedia();
  const { mutateAsync: createGreetingMutate, isLoading: createGreetingLoad } =
    useGreetingCreate();

  useEffect(() => {
    if (type) {
      setValue('type', type);
    }
  }, [type]);

  const onSubmit = async (values) => {
    const {
      custom_greeting,
      greeting_name,
      file,
      recorded_file,
      type = '',
    } = values;
    const arrayBuffer = await loadAudioFileAsync(
      URL.createObjectURL(
        custom_greeting === 'file' ? file?.[0] : recorded_file,
      ),
    );
    const { duration = null } = await audioContext.decodeAudioData(arrayBuffer);
    const refFile = custom_greeting === 'file' ? file?.[0] : recorded_file;
    if (refFile) {
      const payload = {
        uuid: user?.parent_uuid || user?.uuid,
        file_name: refFile?.name,
        type: 'greeting',
      };
      const res = await getUploadUrl(payload);
      const { filename, url: uploadUrl } = res?.data?.data || '';
      if (uploadUrl) {
        setIsUploading(true);
        const uploadFileResponse = await fetch(uploadUrl, {
          method: 'PUT',
          body: refFile,
        });
        setIsUploading(false);
        if (uploadFileResponse.status === 200) {
          const payload = {
            name: greeting_name,
            filename,
            size: refFile?.size,
            duration: duration.toFixed(0),
            type,
          };
          await createGreetingMutate(payload);
          handleClose();
        }
      }
    }
  };

  const isRecord = watch('custom_greeting') === 'record';
  const loading = getUploadUrlLoad || createGreetingLoad || isUploading;
  return (
    <form
      className="w--full h--full"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--lg p--md">
        <div className="w--full d--flex gap--md m-b--sm flex--column">
          {/* <label className="label--control font--sm m-b--0 font--500  text--black d--flex align-items--center w--full">
            Do You Want to Send Custom Greeting?
          </label> */}
          <div className="d--flex justify-content--center gap--lg m-t--md">
            <div className="d--flex gap--sm ">
              <div className="w--max-40">
                <Controller
                  name="custom_greeting"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="radio"
                      label=""
                      value={'file'}
                      checked={!isRecord}
                      id={'file'}
                    />
                  )}
                />
              </div>
              <label
                htmlFor="file"
                className="label--control font--sm m-b--0 font--500  text--black c--pointer"
              >
                Choose File
              </label>
            </div>
            <div className="d--flex  gap--sm c--pointer">
              <div className="w--max-40">
                <Controller
                  name="custom_greeting"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="radio"
                      label=""
                      value={'record'}
                      checked={isRecord}
                      id="record"
                    />
                  )}
                />
              </div>
              <label
                htmlFor="record"
                className="label--control font--sm m-b--0 font--500  text--black c--pointer"
              >
                Record
              </label>
            </div>
          </div>
        </div>

        {isRecord ? (
          <>
            <GreetingRecorder setValue={setValue} />
            <span className="text--danger">
              {errors?.recorded_file?.message}
            </span>
          </>
        ) : (
          <div>
            <div className="w--full">
              <input
                {...register('file')}
                type="file"
                id={'choose_file'}
                accept={'audio/*'}
                hidden
              />
              <div className="">
                <label
                  className={`label--control font--sm m-b--0 font--500 ${
                    errors?.file?.message ? 'text--danger' : 'text--black'
                  } d--flex align-items--center w--full`}
                >
                  Audio File
                </label>
                <label
                  htmlFor={'choose_file'}
                  className={`text--grey font--400 w--full radius--sm ${
                    errors?.file?.message
                      ? 'border-full--red'
                      : 'border-full--black-200'
                  } c--pointer h-min--150 d--flex align-items--center flex--column justify-content--center p-l--md`}
                  onDrop={(e) => {
                    e.preventDefault();
                    setIsDragging(false);

                    if (e.dataTransfer.files?.[0]?.type.includes('audio')) {
                      setValue('file', e.dataTransfer.files, {
                        shouldValidate: true,
                      });
                    } else {
                      setError(
                        'file',
                        { type: 'value', message: 'File type not supported' },
                        {
                          shouldFocus: true,
                        },
                      );
                      e.stopPropagation();
                    }
                  }}
                  onDragOver={(e) => {
                    setIsDragging(true);
                    if (draggingTimeout) {
                      clearTimeout(draggingTimeout);
                      draggingTimeout = setTimeout(() => {
                        setIsDragging((prev) => false);
                      }, 200);
                    } else {
                      draggingTimeout = setTimeout(() => {
                        setIsDragging((prev) => false);
                      }, 200);
                    }

                    e.preventDefault();
                  }}
                >
                  <span className="d--flex gap--sm text--grey flex--column d--flex justify-content--center align-items--center w--full">
                    <UploadIcon
                      width={isDragging ? 80 : 40}
                      height={isDragging ? 80 : 40}
                    />
                    <span className="text--grey">
                      {watch('file')?.[0]
                        ? watch('file')?.[0]?.name
                        : 'Upload File or Drag & Drop'}
                    </span>
                  </span>
                  <div className="m-t--md w--full d--flex justify-content--center ">
                    {watch('file')?.[0] && (
                      <>
                        <GreetingPlayer file={watch('file')?.[0]} />
                        <div
                          className="text--danger d--flex align-items--center justify-content--center w-max--40 w-min--40 h-min--40 radius--full c--pointer "
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setValue('file', null, { shouldValidate: true });
                          }}
                        >
                          <CrossRoundIcon width={40} height={40} />
                        </div>
                      </>
                    )}
                  </div>
                </label>
                <span className="text--danger">{errors?.file?.message}</span>
              </div>
            </div>
          </div>
        )}
        <div>
          <Controller
            name="greeting_name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="Name"
                placeholder="Enter name"
                error={errors?.greeting_name?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                options={[
                  {
                    label: 'Greeting',
                    value: 'greeting',
                  },
                  {
                    label: 'Voicemail',
                    value: 'voicemail',
                  },
                ]}
                renderOption={RenderType}
                placeholder="Please select type"
                label="Type"
                error={errors?.type?.message}
                disabled={Boolean(type)}
              />
            )}
          />
        </div>
      </div>

      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={loading}
        >
          {loading ? 'Please wait..' : 'Save'}
        </Button>
      </div>
    </form>
  );
};

export default AddGreetingForm;
