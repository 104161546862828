import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useCallSummary } from '../../../hooks/useCallSummary';
import Button from '../../Button';
import AddContactForm from '../../../pages/Contacts/AddContactForm';
import GetName from '../../GetName';
import moment from 'moment';
import { SecondsTohhmmss } from '../../../helpers/functions';
import { useAuth } from '../../../hooks/useAuth';
import useAllNumberDetails from '../../../hooks/useAllNumberDetails';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import { useSearchParams } from 'react-router-dom';
import Avatar from '../../Avatar';
import GetNameBySocket from '../../GetNameBySocket';
import GetNameBySocketObCall from '../../GetNameBySocketObCall';
import useIcons from '../../../assets/icons/useIcons';

const EndCallScreen = () => {
  const { user } = useAuth();
  const {
    active_number,
    start_time,
    end_time,
    clearCallSummary,
    direction,
    phone_info_payload,
  } = useCallSummary();
  const { socket } = useContext(SipSocketContext);

  const [showAddContact, setShowAddContact] = useState(false);
  const [isContactExist, setIsContactExist] = useState(false);
  const [activeCallData, setActiveCallData] = useState(null);
  const { numberList, getNumberName, getNumberImage } = useAllNumberDetails();
  const { UserIcon } = useIcons();
  useEffect(() => {
    if (
      active_number &&
      active_number?.length > 5 &&
      phone_info_payload &&
      Object.keys(phone_info_payload).length > 0 &&
      socket?.sipCallSocket
    ) {
      const { direction, ...rest } = phone_info_payload;
      if (direction === 'inbound') {
        socket?.sipCallSocket.emit(
          // 'phone-info'
          'dialer-phone-info',
          rest,
          async (res) => {
            const response = await res;
            if (response?.status === 200) {
              setActiveCallData({
                name: `${response?.data?.first_name} ${response?.data?.last_name}`,
                contact_pic: response?.data?.contact_pic || '',
              });
              setIsContactExist(true);
            }
          },
        );
      } else {
        socket?.sipCallSocket.emit(
          'dialer-phone-info',
          {
            domain: rest?.domain,
            phone: rest?.phone,
            uuid: user?.uuid,
          },
          async (res) => {
            const response = await res;
            if (response?.status === 200) {
              const name = `${response?.data?.first_name ?? ''} ${
                response?.data?.last_name ?? ''
              }`;
              setActiveCallData({
                name,
                contact_pic: response?.data?.contact_pic || '',
              });
              setIsContactExist(true);
            }
          },
        );
      }
    }

    return () => {
      setIsContactExist(false);
    };
  }, [numberList?.length, phone_info_payload]);

  useEffect(() => {
    return () => {
      clearCallSummary();
    };
  }, []);

  return (
    <div className="w--full bg--white  r-b-left--md r-b-right--md h-min--400 ">
      {!showAddContact ? (
        <div className=" h-min--450 h-max--450 overflow--auto  p--md">
          <h3 className="font--600 font--md m-b--sm">Contact information</h3>
          <div className="contact-info-list">
            <div className="contact-info-list-items p-b--md border-bottom--black-100 m-b--2sm">
              <div className="d--flex  gap--md w--full align-items--center justify-content--between ">
                <div className="d--flex  gap--md w--full align-items--center justify-content--start ">
                  {getNumberName(phone_info_payload?.phone, true, false)
                    ?.charAt(0)
                    ?.toUpperCase() ? (
                    <Avatar
                      first_name={
                        getNumberName(
                          phone_info_payload?._number,
                          false,
                          false,
                        )?.split(' ')?.[0]
                      }
                      last_name={
                        getNumberName(
                          phone_info_payload?._number,
                          false,
                          false,
                        )?.split(' ')?.[1] || ''
                      }
                      size="40"
                      image={getNumberImage(phone_info_payload?.phone, false)}
                      shouldShowPresence={false}
                    />
                  ) : activeCallData?.name ? (
                    <Avatar
                      size="40"
                      shouldShowPresence={false}
                      contactImage={activeCallData?.contact_pic}
                      first_name={activeCallData?.name?.split(' ')?.[0] || ''}
                      last_name={activeCallData?.name?.split(' ')?.[1] || ''}
                    />
                  ) : (
                    <UserIcon />
                  )}

                  <div className="contact-details">
                    <h5 className="font--2sm font--600">
                      {activeCallData ? (
                        `${activeCallData?.name}`
                      ) : (
                        <GetName number={active_number} />
                      )}
                    </h5>
                  </div>
                </div>

                {active_number?.length > 4 && !isContactExist && (
                  <Button
                    type="button"
                    variant="primary"
                    color="white"
                    btnClasses="btn  w-max--110 font--sm"
                    onClick={() => setShowAddContact(true)}
                  >
                    Add Contact
                  </Button>
                )}
              </div>
            </div>
          </div>

          <h3 className="font--600 font--md m-b--sm">Call information</h3>
          <div className="call-info-list">
            <div className="d--flex  m-b--2sm">
              <h5 className="font--2sm font--600">From :</h5>&nbsp;
              <p className="font--2sm text--black-600">
                {direction === 'outbound' ? (
                  `${user?.first_name} ${user?.last_name}`
                ) : (
                  <>
                    {activeCallData ? (
                      `${activeCallData?.name} `
                    ) : (
                      <GetName number={active_number} />
                    )}
                  </>
                )}
              </p>
            </div>
            <div className="d--flex  m-b--2sm">
              <h5 className="font--2sm font--600">To :</h5>&nbsp;
              <p className="font--2sm text--black-600">
                {direction === 'outbound' ? (
                  <>
                    {activeCallData ? (
                      `${activeCallData?.name}`
                    ) : (
                      <GetName number={active_number} />
                    )}
                  </>
                ) : (
                  `${user?.first_name} ${user?.last_name}`
                )}
              </p>
            </div>
            <div className="d--flex  m-b--2sm">
              <h5 className="font--2sm font--600">Duration :</h5>&nbsp;
              <p className="font--2sm text--black-600">
                {end_time
                  ? SecondsTohhmmss(
                      end_time?.diff(moment(start_time), 'seconds'),
                    )
                  : '00:00'}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="modalEndCall">
          <AddContactForm
            fromDialpad={true}
            handleClose={() => {
              setShowAddContact(false);
            }}
            editContactInstance={{
              editData: {
                phone: active_number,
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default EndCallScreen;
