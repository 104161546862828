import React, { useState } from 'react';
import Button from '../../../comopnents/Button';
import useUnAssignDID from '../../../hooks/useUnAssignDID';
import useAssignDIDList from '../../../hooks/useAssignDIDList';

const UnAssignDIDModal = ({
  unAssignDIDInstance,
  handleClose = () => null,
}) => {
  const { mutate: unassignDIDMutate, isLoading: unassignDidLoad } =
    useUnAssignDID({ handleSuccess: () => handleClose() });
  const { data: assignedDIDList } = useAssignDIDList(unAssignDIDInstance?.uuid);
  const [didNumbers, setDidNumbers] = useState([]);

  function onSubmit() {
    unassignDIDMutate({
      uuid: unAssignDIDInstance?.uuid,
      did_number: didNumbers,
    });
  }

  return (
    <div
      className=" d--flex flex--column gap--md "
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d--flex flex--column gap--md overflow--auto h-min--200 h-max--300">
        {assignedDIDList?.length > 0 &&
          assignedDIDList.map((item) => {
            return (
              <div className="d--flex gap--sm" key={item}>
                <input
                  id={item}
                  type="checkbox"
                  className="form--control no--focus"
                  checked={didNumbers.includes(item)}
                  onChange={() => {
                    if (didNumbers.includes(item)) {
                      setDidNumbers((prev) => {
                        return prev.filter((d) => d !== item);
                      });
                    } else {
                      setDidNumbers((prev) => [...prev, item]);
                    }
                  }}
                />
                <label
                  className={`label--control font--sm font--500  d--flex c--pointer`}
                  htmlFor={item}
                >
                  {item}
                </label>
              </div>
            );
          })}
      </div >
      <div className="d--flex justify-content--between gap--md  p-t--sm ">
        {/* <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button> */}
        <Button
          type="button"
          size=""
          variant="primary"
          color="contrast"
          btnClasses="btn"
          onClick={() => onSubmit()}
          disabled={unassignDidLoad || didNumbers.length === 0}
        >
          {unassignDidLoad ? 'Please wait..' : 'Submit'}
        </Button>
      </div>
    </div >
  );
};

export default UnAssignDIDModal;
