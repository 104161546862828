import React, { useEffect, useMemo, useRef, useState } from 'react';
import useIcons from '../../../../assets/icons/useIcons';
import CustomToolTip from '../../../../comopnents/CustomToolTip';
import moment from 'moment';

export function capitalizeWords(str, delimiter = '_') {
  if (!str) return '';

  const parts = str.split(delimiter);

  const capitalizedWords = [];

  parts.forEach((part) => {
    capitalizedWords.push(part.charAt(0).toUpperCase() + part.slice(1));
  });

  return capitalizedWords.join(' ');
}

const colorLookup = {
  online: 'success',
  offline: 'danger',
  device_online: 'success',
  device_offline: 'danger',
  call_start: 'success',
  call_end: 'danger',
};
const ActivityItem = (props) => {
  const currentActivity = props?.list;
  const isDataAvailable =
    currentActivity?.data && Object.keys(currentActivity?.data).length > 0
      ? true
      : false;
  const isCallEndActivity = currentActivity?.activity === 'call_end';
  const isCallStartActivity = currentActivity?.activity === 'call_start';
  const isDeviceOnlineActivity = currentActivity?.activity === 'device_online';
  const isDeviceOfflineActivity =
    currentActivity?.activity === 'device_offline';
  return (
    <>
      Activity : {capitalizeWords(currentActivity?.activity, '_')}
      {isDataAvailable ? (
        <div>
          {(isDeviceOnlineActivity || isDeviceOfflineActivity) && (
            <div style={{ textTransform: 'capitalize' }}>
              Device : {currentActivity?.data?.device_type}
            </div>
          )}
          {(isDeviceOnlineActivity || isDeviceOfflineActivity) && (
            <div>Browser : {currentActivity?.data?.browser_version}</div>
          )}
          {(isDeviceOnlineActivity || isDeviceOfflineActivity) && (
            <div style={{ textTransform: 'capitalize' }}>
              OS : {currentActivity?.data?.os_version}
            </div>
          )}

          {isCallEndActivity || isCallStartActivity ? (
            <>
              {!currentActivity?.data?.Direction === 'initiator' ? (
                <div>
                  From :{' '}
                  {currentActivity?.data?.Direction === 'initiator'
                    ? currentActivity?.data?.['From-User']
                    : currentActivity?.data?.['To-User']}
                </div>
              ) : null}
            </>
          ) : null}

          {isCallEndActivity || isCallStartActivity ? (
            <>
              {currentActivity?.data?.Direction === 'initiator' ? (
                <div>
                  To :{' '}
                  {currentActivity?.data?.Direction === 'initiator'
                    ? currentActivity?.data?.['To-User']
                    : currentActivity?.data?.['From-User']}
                </div>
              ) : null}
            </>
          ) : null}

          <div>
            Time :{moment(currentActivity?.timestamp).format('hh:mm A') || ''}
          </div>
        </div>
      ) : null}
    </>
  );
};

const ActivityIcon = ({ activity }) => {
  const { CallIcon, DesktopIcon, MobileIcon } = useIcons();
  const isDesktopDevice = activity?.data?.device_type === 'desktop';
  const isCallActivity = activity?.activity?.includes('call');
  const isOnlineActivity = activity?.activity === 'online';
  const isOfflineActivity = activity?.activity === 'offline';
  return (
    <>
      {isDesktopDevice && <DesktopIcon height={13} width={13} />}
      {!isDesktopDevice && (isOnlineActivity || isOfflineActivity) && (
        <DesktopIcon height={13} width={13} />
      )}
      {isCallActivity && <CallIcon height={13} width={13} />}
    </>
  );
};

const Activity = ({ activity = {}, start, end }) => {
  const [position, setPosition] = useState(0);
  const activityRef = useRef();

  useEffect(() => {
    calculatePosition();
    window.addEventListener('resize', calculatePosition);
    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
  }, [activity]);

  function calculatePosition() {
    const startHours = moment.unix(start).hours();
    const endHours = moment.unix(end).hours();
    const startMinutes = moment.unix(start).minutes();
    const endMinutes = moment.unix(end).minutes();
    const totalStartMinutes = startHours * 60 + startMinutes;
    const totalEndMinutes = endHours * 60 + endMinutes;
    const totalActivityHours = (totalEndMinutes - totalStartMinutes) / 60;
    const hourHeight =
      activityRef.current.parentElement.offsetHeight / totalActivityHours;
    const date = moment(activity?.timestamp);
    const hours = date.hours() - startHours;
    const minutes = date.minutes() - startMinutes;
    const totalMinutes = hours * 60 + minutes;
    const totalHours = totalMinutes / 60;
    const position = totalHours * hourHeight - 13;
    setPosition(position);
  }

  const hasActivity =
    activity?.activity && Object.keys(activity?.activity).length > 0;

  const isAllOffline = useMemo(
    () =>
      activity?.activityItem?.every((item) => {
        return item?.activity?.includes('offline');
      }),
    [activity?.activityItem],
  );
  if (!hasActivity) return;

  return (
    <div
      ref={activityRef}
      style={{
        top: position,
      }}
      className={`w-min--20 h-min--20  h-max--20 position--absolute   d--flex align-items--center justify-content--center`}
    >
      <div
        className={`w-min--20 right---10  h-min--20  h-max--20   z-index--sm w--full h--full radius--full border-full--${
          colorLookup[
            activity?.activityItem?.length > 0
              ? isAllOffline
                ? 'offline'
                : 'online'
              : activity?.activity
          ]
        } bg--white d--flex align-items--center justify-content--center bg--white`}
      >
        {activity?.activityItem?.length > 0 ? (
          isAllOffline ? null : (
            <span className="d--flex">
              <CustomToolTip
                enableTooltip={true}
                options={activity}
                separatorComponent={ActivityItem}
              >
                <span
                  className={`text--${colorLookup[activity?.activity]} d--flex`}
                >
                  <ActivityIcon activity={activity} />
                  {/* {GetIconLookup[activity?.activity]} */}
                </span>
              </CustomToolTip>
              {activity?.activityItem?.map((ac) => {
                return (
                  <CustomToolTip
                    enableTooltip={true}
                    options={ac}
                    separatorComponent={ActivityItem}
                  >
                    <span
                      className={`text--${colorLookup[ac?.activity]} d--flex`}
                    >
                      <ActivityIcon activity={activity} />
                      {/* {GetIconLookup[ac?.activity]} */}
                    </span>
                  </CustomToolTip>
                );
              })}
            </span>
          )
        ) : (
          <span className="d--flex">
            <CustomToolTip
              enableTooltip={true}
              options={activity}
              separatorComponent={ActivityItem}
            >
              <span
                className={`text--${colorLookup[activity?.activity]} d--flex`}
              >
                <ActivityIcon activity={activity} />
                {/* {GetIconLookup[activity?.activity]} */}
              </span>
            </CustomToolTip>
            {activity?.activityItem?.map((ac) => {
              return (
                <CustomToolTip
                  enableTooltip={true}
                  options={ac}
                  separatorComponent={ActivityItem}
                >
                  <span
                    className={`text--${colorLookup[ac?.activity]} d--flex`}
                  >
                    <ActivityIcon activity={activity} />
                    {/* {GetIconLookup[ac?.activity]} */}
                  </span>
                </CustomToolTip>
              );
            })}
          </span>
        )}
      </div>
    </div>
  );
};

export default Activity;
