import React from 'react';
import CallScreenLayout from '../CallScreenLayout';

const ActiveCallScreen = ({
  activeCallData = {},
  setaction,
  action,
  callActiveKey,
  setCallActiveKey,
  callSessions,
}) => {
  return (
    <CallScreenLayout
      activeCall={activeCallData}
      action={action}
      setaction={setaction}
      callActiveKey={callActiveKey}
      setCallActiveKey={setCallActiveKey}
      callSessions={callSessions}
    />
  );
};

export default ActiveCallScreen;
