import React, { useContext, useEffect, useMemo, useState } from 'react';
import useIcons from '../../assets/icons/useIcons';
import FormInput from '../FormInput';
import Button from '../Button';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';
import DialpadHeader from './DialpadHeader';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import ActiveCallScreen from './ActiveCallScreen';
import Timer from '../SipComponents/Timer';
import AddContactForm from '../../pages/Contacts/AddContactForm';
import { useMutation } from '@tanstack/react-query';
import { checkIfContactExist } from '../../api';
import {
  IncomingRing,
  ayt,
  debounceFn,
  toneLookup,
} from '../../helpers/functions';
import FlagWithCountryCode from '../FlagWithCountryCode';
import { useCallSummary } from '../../hooks/useCallSummary';
import EndCallScreen from './EndCallScreen';
import GetName from '../GetName';
import { useAuth } from '../../hooks/useAuth';
import useAllNumberDetails from '../../hooks/useAllNumberDetails';
import GetNameBySocket from '../GetNameBySocket';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AsideModal from '../AsideModal';
import GetNameBySocketObCall from '../GetNameBySocketObCall';
import Avatar from '../Avatar';

let zeroTimeout;
export default function Dialpad({
  setshow,
  callActiveKey,
  callSessions,
  setCallActiveKey,
  isModal = true,
  callLayout = false,
}) {
  const navigate = useNavigate();
  const [action, setaction] = useState('');
  const [addToContact, setAddToContact] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [addContact, setAddContact] = useState(false);
  const [contactToAdd, setContactToAdd] = useState('');
  const { CallIcon, RemoveIcon, BackDialerIcon } = useIcons();
  const { socket = {} } = useContext(SipSocketContext);
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const { _makeCall = () => null, _uaSessions, _status } = socket;
  const { user } = useAuth();
  const { active_number: oldActiveNumber } = useCallSummary();
  const { getNumberName, getNumberImage } = useAllNumberDetails();
  const { mutate } = useMutation({
    mutationFn: checkIfContactExist,
    mutationKey: 'checkIfContactExist',
    onSuccess: ({ data }) => {
      if (data?.message === 'NO') {
        setContactToAdd(data?.data);
      } else {
        setContactToAdd('');
        setAddToContact(false);
      }
    },
    onError: (error) => {
      setContactToAdd('');
      setAddToContact(false);
    },
  });

  const handleCloseAddContact = () => {
    setContactToAdd('');
    setAddToContact(false);
  };

  const handleMakeCall = () => {
    if (phoneNumber) {
      _makeCall(phoneNumber, phoneNumber?.replaceAll(' ', ''));
    }
  };
  const appendPhoneNumber = (number) => {
    setPhoneNumber((prev) => {
      if (!prev) return `+${number.toString()}`;
      return ayt(`${prev}${number.toString()}`);
    });
  };

  const handleNumberDelete = () => {
    setPhoneNumber((prev) => {
      return prev ? formatPhoneNumberIntl(prev.slice(0, -1)) : null;
    });
  };

  const handleKeyPress = (e) => {
    if (phoneNumber?.length && e.key === 'Enter' && user?.caller_id) {
      _makeCall(phoneNumber, phoneNumber?.replaceAll(' ', ''));
    }
  };

  const isCallExist = callSessions.length > 0;
  const activeCallData =
    callSessions.filter((call) => call?.callID === callActiveKey)?.[0] || {};

  useEffect(() => {
    if (isCallExist) {
      if (activeCallData && Object.keys(activeCallData).length > 0) {
        if (activeCallData?._number && activeCallData?._number?.length > 4) {
          mutate({
            contact_number: activeCallData?._number,
            domain: user?.domain,
          });
        }
      }
    }
  }, [isCallExist]);
  return (
    <div
      className={`dialpad w--full d--flex flex--column ${
        !isModal ? 'h--full' : ''
      }`}
    >
      <>
        <div className="w--full bg--primary text--white p--md r-t-left--md r-t-right--md gap--md d--flex flex--column ">
          <DialpadHeader
            setshow={setshow}
            isCallExist={isCallExist}
            callActiveKey={callActiveKey}
            callSessions={callSessions}
            setCallActiveKey={setCallActiveKey}
            action={action}
            setaction={setaction}
            callLayout={callLayout}
          />
          <>
            {!isCallExist && oldActiveNumber ? null : !isCallExist &&
              !oldActiveNumber ? (
              <div className="d--flex align-items--center justify-content--between p-b--md">
                <div className="d--flex flex--column gap--md w--full">
                  <div className="w--full d--flex align-items--end justify-content--between">
                    <FlagWithCountryCode
                      phoneNumber={phoneNumber}
                      className={'dialer-flag'}
                    />
                    <FormInput
                      type="text"
                      id="phoneNumberDialpadInput"
                      placeholder="Please enter number"
                      extraClasses="bg--transparent text--white font--3xl border--0 text--c"
                      height="40"
                      value={phoneNumber}
                      onChange={(e) => {
                        const formattedNumber = ayt(
                          e.target.value
                            .toString()
                            .replace(/[^+0-9*#]/g, '')
                            .slice(0, 13),
                        );
                        setPhoneNumber(formattedNumber);
                        handleCloseAddContact();

                        if (phoneNumber?.length > 9) {
                          debounceFn((e) => {
                            mutate({
                              contact_number: e.target.value,
                            });
                          }, 600)(e);
                        }
                      }}
                      onKeyDown={handleKeyPress}
                    />
                    {phoneNumber?.length ? (
                      <span
                        className="d--flex align-items--end font--600 font--lg c--pointer"
                        onClick={() => handleNumberDelete()}
                      >
                        <RemoveIcon width={35} height={35} />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d--flex align-items--center justify-content--center p-b--0 p-t--md">
                  {!action ? (
                    <div className="d--flex  gap--md w--full align-items--center justify-content--center flex--column">
                      <span
                        className="font--lg font--600 w-min--60 w-max--60 h-min--60 h-max--60 w--full h--full radius--full d--flex align-items--center justify-content--center text--primary bg--contrast "
                        onClick={() => {
                          if (callLayout) return;
                          if (
                            ['ringing', 'connecting'].includes(
                              activeCallData?._status,
                            )
                          ) {
                            return;
                          }
                          if (
                            activeCallData?._number?.length > 5 &&
                            sessionStorage?.getItem('cid')
                          ) {
                            const contactInfo =
                              searchParams && searchParams?.get('x-cn')
                                ? searchParams?.get('x-cn')
                                : null;
                            navigate(
                              `contacts?contactId=${sessionStorage.getItem(
                                'cid',
                              )}&showDialer=true&x-cn=${contactInfo}`,
                            );
                          }
                          if (
                            activeCallData?._number?.length > 5 &&
                            !sessionStorage?.getItem('cid')
                          ) {
                            navigate(
                              `contacts?contactId=""&showDialer=false&numberToSave=${activeCallData?._number}`,
                            );
                          }
                        }}
                      >
                        {['ringing', 'connecting'].includes(
                          activeCallData?._status,
                        ) ||
                        sessionStorage?.getItem('cid') ||
                        activeCallData?._number?.length < 5 ? (
                          <span>
                            {getNumberName(
                              activeCallData?._number,
                              false,
                              false,
                            )
                              ?.charAt(0)
                              ?.toUpperCase() ? (
                              <>
                                <Avatar
                                  first_name={
                                    getNumberName(
                                      activeCallData?._number,
                                      false,
                                      false,
                                    )?.split(' ')?.[0]
                                  }
                                  last_name={
                                    getNumberName(
                                      activeCallData?._number,
                                      false,
                                      false,
                                    )?.split(' ')?.[1] || ''
                                  }
                                  size="60"
                                  image={getNumberImage(
                                    activeCallData?._number,
                                    false,
                                  )}
                                  shouldShowPresence={false}
                                />
                              </>
                            ) : activeCallData?._direction === 'inbound' ? (
                              <>
                                <GetNameBySocket
                                  number={activeCallData?._number}
                                  getAvatar={true}
                                  avatarSize="60"
                                />
                              </>
                            ) : (
                              <GetNameBySocketObCall
                                number={activeCallData?._number}
                                getAvatar={true}
                                avatarSize="60"
                              />
                            )}
                          </span>
                        ) : (
                          <span className="font--xs d--flex justify-content--center">
                            Add <br /> contact
                          </span>
                        )}
                      </span>
                      <div className=" d--flex flex--column justify-content--center">
                        <div className="font--md font--600 d--flex justify-content--center">
                          {activeCallData?._number?.length < 5 ? (
                            <GetName number={activeCallData?._number} />
                          ) : activeCallData?._direction === 'inbound' ? (
                            <GetNameBySocket number={activeCallData?._number} />
                          ) : (
                            <GetNameBySocketObCall
                              number={activeCallData?._number}
                            />
                          )}
                        </div>
                        <div className="font--sm d--flex justify-content--center  m-t--sm">
                          {activeCallData?._number?.length < 5 ? 'EXT :' : ''}
                          &nbsp;{activeCallData?._number}
                        </div>
                        <div
                          className="font--sm d--flex justify-content--center  m-t--sm"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {activeCallData?._status}
                          {!['ringing', 'connecting'].includes(
                            activeCallData?._status,
                          ) && (
                            <>
                              &nbsp;|&nbsp;
                              <Timer start_time={activeCallData?._joined_at} />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <span
                        className="c--pointer m-r--md"
                        onClick={() => setaction('')}
                      >
                        <BackDialerIcon width={30} height={30} />
                      </span>

                      <div className="d--flex  gap--md w--full align-items--center justify-content--start m-b--sm ">
                        <span className="font--lg font--600 w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--primary bg--contrast  c--pointer">
                          {getNumberName(activeCallData?._number, true, false)
                            ?.charAt(0)
                            ?.toUpperCase() ? (
                            <Avatar
                              first_name={
                                getNumberName(
                                  activeCallData?._number,
                                  false,
                                  false,
                                )?.split(' ')?.[0]
                              }
                              last_name={
                                getNumberName(
                                  activeCallData?._number,
                                  false,
                                  false,
                                )?.split(' ')?.[1] || ''
                              }
                              size="40"
                              image={getNumberImage(
                                activeCallData?._number,
                                false,
                              )}
                              shouldShowPresence={false}
                            />
                          ) : activeCallData?._direction === 'inbound' ? (
                            <GetNameBySocket
                              number={activeCallData?._number}
                              getAvatar={true}
                            />
                          ) : (
                            <GetNameBySocketObCall
                              number={activeCallData?._number}
                              getAvatar={true}
                            />
                          )}
                        </span>
                        <div className=" d--flex flex--column justify-content--start">
                          <div className="font--md font--600">
                            {activeCallData?._number < 5 ? (
                              <GetName number={activeCallData?._number} />
                            ) : activeCallData?._direction === 'inbound' ? (
                              <GetNameBySocket
                                number={activeCallData?._number}
                              />
                            ) : (
                              <GetNameBySocketObCall
                                number={activeCallData?._number}
                              />
                            )}
                          </div>
                          <div
                            className="font--sm d--flex justify-content--start  m-t--sm"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {activeCallData?._status}
                            {!['ringing', 'connecting'].includes(
                              activeCallData?._status,
                            ) && (
                              <>
                                &nbsp;|&nbsp;
                                <Timer
                                  start_time={activeCallData?._joined_at}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        </div>
        {oldActiveNumber && !isCallExist ? (
          <EndCallScreen />
        ) : (
          <div
            className={`w--full bg--white  r-b-left--md r-b-right--md ${
              !isCallExist ? 'h-min--400' : 'h-min--300'
            } `}
          >
            {addToContact ? (
              <div className="d-flex flex-column">
                <AddContactForm
                  fromDialpad={true}
                  handleClose={() => handleCloseAddContact()}
                  editContactInstance={{
                    isEdit: true,
                    editData: { phone: contactToAdd },
                  }}
                />
              </div>
            ) : !isCallExist ? (
              <>
                <div className="d--grid grid-system h-min--350 h-max--350 overflow-auto m-t--sm m-b--lg ">
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onClick={() => appendPhoneNumber('1')}
                  >
                    1
                    <span class="d--flex text--black-400 font--xs">&nbsp;</span>
                  </span>

                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onClick={() => appendPhoneNumber('2')}
                  >
                    2<span class="d--flex text--black-400 font--xs">ABC</span>
                  </span>
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onClick={() => appendPhoneNumber('3')}
                  >
                    3<span class="d--flex text--black-400 font--xs">DEF</span>
                  </span>
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onClick={() => appendPhoneNumber('4')}
                  >
                    4<span class="d--flex text--black-400 font--xs">GHI</span>
                  </span>
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onClick={() => appendPhoneNumber('5')}
                  >
                    5<span class="d--flex text--black-400 font--xs">JKL</span>
                  </span>
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onClick={() => appendPhoneNumber('6')}
                  >
                    6<span class="d--flex text--black-400 font--xs">MNO</span>
                  </span>
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onClick={() => appendPhoneNumber('7')}
                  >
                    7<span class="d--flex text--black-400 font--xs">PQRS</span>
                  </span>
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onClick={() => appendPhoneNumber('8')}
                  >
                    8<span class="d--flex text--black-400 font--xs">TUV</span>
                  </span>
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onClick={() => appendPhoneNumber('9')}
                  >
                    9<span class="d--flex text--black-400 font--xs">WXYZ</span>
                  </span>
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center m-t--sm"
                    onClick={() => appendPhoneNumber('*')}
                  >
                    *
                  </span>
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onMouseDown={() => {
                      zeroTimeout = setTimeout(() => {
                        appendPhoneNumber('+');
                        clearTimeout(zeroTimeout);
                        zeroTimeout = null;
                      }, 500);
                    }}
                    onMouseUp={() => {
                      if (zeroTimeout) {
                        clearTimeout(zeroTimeout);
                        zeroTimeout = null;
                        appendPhoneNumber('0');
                      }
                    }}
                  >
                    0<span className="d--flex text--black-400">+</span>
                  </span>
                  <span
                    className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
                    onClick={() => appendPhoneNumber('#')}
                  >
                    #
                  </span>
                  <span className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center m-t--lg">
                    {/* <UserIcon width={35} height={35} /> */}
                  </span>
                  <span className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center m-t--lg">
                    <Button
                      variant="success"
                      rounded
                      btnClasses="btn w-max--50 btn--lg"
                      onClick={() => handleMakeCall()}
                      disabled={!phoneNumber || !user?.caller_id}
                    >
                      <CallIcon />
                    </Button>
                  </span>
                </div>
              </>
            ) : (
              <div className="d-flex flex-column ">
                <ActiveCallScreen
                  activeCallData={activeCallData}
                  action={action}
                  setaction={setaction}
                  callActiveKey={callActiveKey}
                  setCallActiveKey={setCallActiveKey}
                  callSessions={callSessions}
                />
              </div>
            )}
          </div>
        )}
      </>

      {addContact && (
        <AsideModal
          handleClose={() => {
            setAddContact(false);
          }}
          title={'Add Contact'}
          footerComponent={null}
        >
          <AddContactForm
            editContactInstance={{
              isEdit: true,
              editData: { phone: activeCallData?._number },
            }}
            handleClose={() => {
              setAddContact(false);
            }}
          />
        </AsideModal>
      )}
    </div>
  );
}
