import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { reportsOverviewStats } from '../../../api';
import { useNavigate } from 'react-router-dom';
import { SecondsTohhmmss } from '../../../helpers/functions';
import Tabbing from '../../../comopnents/Tabbing';
import moment from 'moment';

const Overview = () => {
  const navigate = useNavigate();

  const [customDayTab, customDaysetTab] = useState('week');

  const weekFilter = {
    from: moment().startOf('week').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  };
  const monthFilter = {
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().endOf('month').format('YYYY-MM-DD'),
  };

  const todayFilter = {
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  };

  const payload = useMemo(
    () =>
      customDayTab === 'week'
        ? weekFilter
        : customDayTab === 'month'
        ? monthFilter
        : todayFilter,
    [customDayTab],
  );

  const { data } = useQuery({
    queryFn: ({ queryKey }) => reportsOverviewStats(queryKey[1] || {}),
    queryKey: [
      `reports-overview-stats`,
      {
        filter_date: payload,
      },
    ],
    select: (data) => data?.data?.data,
  });

  const {
    total_sms = 0,
    incoming_sms = 0,
    outgoing_sms = 0,

    total_agents = 0,
    total_members = 0,

    virtual_number = 0,

    outbound = {},
    inbound = {},
    voicemail = {},
  } = data || {};
  return (
    <div className="overviewPage d--flex flex--column gap--sm w--full">
      <div className="w--full d--flex flex--column">
        <div className=" m-b--xs h-max--40 h-min--36  d--flex justify-content--between align-items--center text--primary">
          <div className="font--md font--400 white--space-none">
            Outbound Calls
          </div>
          <div className="w--full d--flex justify-content--end">
            <Tabbing
              tab={customDayTab}
              setTab={customDaysetTab}
              tabsLabels={[
                {
                  label: 'Today',
                  value: 'today',
                },
                {
                  label: 'This week',
                  value: 'week',
                },
                {
                  label: 'This Month',
                  value: 'month',
                },
              ]}
            />
          </div>
        </div>
        {/* d--grid grid--3 grid--3-rows  */}
        <div className="gap--md d--flex flex--column w--full overflow--auto rightSecScroll">
          <div className="w--full">
            <div className="d--flex gap--md">
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/calls?callType=outbound')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {outbound?.total}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Dialed
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/calls?callType=inbound')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {outbound?.answered}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Answered
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/calls?callType=missed')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {outbound?.missed}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Missed
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                // onClick={() => navigate('/reports/calls?callType=inbound')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {SecondsTohhmmss(outbound?.duration || 0)}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Duration
                </div>
              </div>
            </div>
          </div>

          <div className="w--full gap--sm d--flex flex--column">
            <div className="font--md font--400 text--primary">
              Inbound Calls
            </div>
            <div className="d--flex gap--md">
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/calls?callType=total')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {inbound?.total}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Received
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/calls?callType=inbound')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {inbound?.answered}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Answered
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/calls?callType=missed')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {inbound?.missed}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Missed
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/calls?callType=voicemail')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {inbound?.voicemail}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Voicemail
                </div>
              </div>
              {/* <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                // onClick={() => navigate('/reports/calls?callType=inbound')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {SecondsTohhmmss(inbound?.duration || 0)}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Duration
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="w--full gap--sm d--flex flex--column">
            <div className="font--md font--400 text--primary">Voicemail</div>
            <div className="d--flex gap--md">
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                // onClick={() => navigate('/reports/calls?callType=total')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {voicemail?.total}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Total
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                // onClick={() => navigate('/reports/calls?callType=inbound')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {voicemail?.received}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Received
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                // onClick={() => navigate('/reports/calls?callType=missed')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {voicemail?.missed}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Missed
                </div>
              </div>
              <div
                className="w--full radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                // onClick={() => navigate('/reports/calls?callType=inbound')}
              >
            
              </div>
            </div>
          </div> */}
          <div className="w--full gap--sm d--flex flex--column">
            <div className="font--md font--400 text--primary">SMS</div>
            <div className="d--flex gap--md">
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/sms?smsType=total')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {total_sms}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Total
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/sms?smsType=outbound')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {outgoing_sms}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Sent
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/sms?smsType=inbound')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {incoming_sms}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Receive
                </div>
              </div>
              <div className="w--full"></div>
            </div>
          </div>
          <div className="w--full gap--sm d--flex flex--column">
            <div className="font--md font--400 text--primary">Others</div>
            <div className="d--flex gap--md">
              <div className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem">
                <div
                  className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox"
                  onClick={() => navigate('/reports/employees')}
                >
                  {total_agents}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Agents
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/reports/employees')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {total_members}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Employees
                </div>
              </div>
              <div
                className="w--full bg--white radius--sm d--flex flex--column align-items--center justify-content--center p--sm gap--md h-min--100 c--pointer overviewPageItem"
                onClick={() => navigate('/virtual-numbers')}
              >
                <div className=" font--lg font--600  radius--full w-min--100 p--md bg--secondary-100 d--flex align-items--center justify-content--center overviewPageItemBox">
                  {virtual_number}
                </div>
                <div className=" font--md font--500 d--flex align-items--center gap--sm justify-content--center text--secondary">
                  Virtual Numbers
                </div>
              </div>
              <div className="w--full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
