import React, { useContext, useEffect } from 'react';
import useIcons from '../../../assets/icons/useIcons';
import { useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import useDidList from '../../../hooks/useDidList';
import { useCallSummary } from '../../../hooks/useCallSummary';
import { useSearchParams } from 'react-router-dom';
const ConnectedScreen = ({ activeCall, setaction }) => {
  const { user: userData } = useAuth();
  const { mutate, data } = useDidList();
  const listDID = data?.data?.data || [];
  const {
    CallIcon,
    DialIcon2,
    RecordIcon,
    CallTransferIcon,
    NotesIcons,
    TagsIcons,
    AssignIcons,
    AddCall,
    HoldIcon,
    MickOffIcon,
    MickIcon,
    PlayIcon,
  } = useIcons();
  const { socket = {} } = useContext(SipSocketContext);
  const { setIsCallRecording, isRecording } = useCallSummary();
  const { _number = '', _status, _direction, callID } = activeCall;
  const { _terminate, _muteCall, _toggleHold, _uaSessions, _sendDtmf } = socket;

  let [searchParams] = useSearchParams();

  const contactID =
    sessionStorage?.getItem('cid') || searchParams?.get('contactId') || '';

  const requiredObj =
    listDID &&
    listDID.length > 0 &&
    listDID?.filter((d) => d.did_number === userData?.caller_id)?.[0]?.[
      'outgoing_call_recording'
    ]
      ? 'Y'
      : 'N';

  const isUARecordingEnabled =
    _direction === 'inbound'
      ? !!_uaSessions?.[callID]?._request?.headers?.['X-Record']
      : requiredObj === 'Y';

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    if (!isRecording) {
      setIsCallRecording(isUARecordingEnabled);
    }
  }, [isUARecordingEnabled]);

  const callRecording = (uuid) => {
    if (_number?.length <= 4) return;
    if (isRecording) {
      _sendDtmf(uuid, `*3`);
      setIsCallRecording(false);
    } else {
      _sendDtmf(uuid, `*2`);
      setIsCallRecording(true);
    }
  };
  return (
    <>
      {/* <div className="d--grid grid-system h-min--300 h-max--300 overflow-auto"> */}
      <div className=" h-min--350 h-max--350 overflow-auto ">
        <div className="p--md bg--primary d--grid grid-system-4 h-min--150">
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm ">
            <span
              className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--transparent  c--pointer"
              onClick={() => _muteCall(callID)}
            >
              {_status === 'mute' ? <MickOffIcon /> : <MickIcon />}
            </span>
            <span className="font--sm text--white">
              {_status === 'mute' ? 'Unmute' : 'Mute'}
            </span>
          </span>
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm ">
            <span
              className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--transparent  c--pointer"
              onClick={() => _toggleHold(callID)}
            >
              {_status === 'hold' ? (
                <PlayIcon width={20} height={20} />
              ) : (
                <HoldIcon width={20} height={20} />
              )}
            </span>
            <span className="font--sm text--white">
              {_status === 'hold' ? 'Unhold' : 'Hold'}
            </span>
          </span>
          <span
            className="items d--flex flex--column align-items--center justify-content--center gap--sm "
            onClick={() => callRecording(callID)}
          >
            {_number?.length <= 4 ? (
              <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center bg--transparent c--not-allowed opacity--sm">
                <RecordIcon width={20} height={20} />
              </span>
            ) : isRecording ? (
              <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--danger bg--transparent c--pointer">
                <RecordIcon width={20} height={20} />
              </span>
            ) : (
              <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--transparent  c--pointer">
                <RecordIcon width={20} height={20} />
              </span>
            )}
            <span className="font--sm text--white">Record</span>
          </span>
          <span
            className="items d--flex flex--column align-items--center justify-content--center gap--sm "
            onClick={() => setaction('dial')}
          >
            <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--transparent  c--pointer">
              <DialIcon2 width={19} height={19} />
            </span>
            <span className="font--sm text--white">Keypad</span>
          </span>
        </div>
        <div className="p--md  d--grid grid-system gap--xl p-l--2xl p-r--2xl m-t---3xl ">
          <span
            className={`items d--flex flex--column align-items--center justify-content--center gap--sm w--full h-min--75 h-max--75 bg--white radius--md box-shadow   ${
              contactID ? 'c--pointer' : 'c--not-allowed'
            } `}
            onClick={() => {
              if (!contactID) return;
              setaction('notes');
            }}
          >
            <span
              className={`w--full  d--flex align-items--center justify-content--center ${
                contactID ? '' : 'opacity--sm'
              } `}
            >
              <NotesIcons />
            </span>
            <span
              className={`font--sm font--600  ${
                contactID ? '' : 'opacity--sm'
              }`}
            >
              Notes
            </span>
          </span>
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm w--full h-min--75 h-max--75 bg--white radius--md box-shadow  c--not-allowed">
            <span className="w--full  d--flex align-items--center justify-content--center opacity--sm">
              <TagsIcons width={22} height={22} />
            </span>
            <span className="font--sm font--600 opacity--sm">Tags</span>
          </span>
          <span
            className="items d--flex flex--column align-items--center justify-content--center gap--sm w--full h-min--75 h-max--75 bg--white radius--md box-shadow  c--pointer"
            onClick={() => setaction('attended_transfer')}
          >
            <span className="w--full  d--flex align-items--center justify-content--center  ">
              <CallTransferIcon />
            </span>
            <span className="font--sm font--600 ">Transfer</span>
          </span>
          {/* <span className="items d--flex flex--column align-items--center justify-content--center gap--sm w--full h-min--75 h-max--75 bg--white radius--md box-shadow">
            <span className="w--full  d--flex align-items--center justify-content--center   c--pointer">
              <AssignIcons />
            </span>
            <span className="font--sm font--600 ">Assign</span>
          </span> */}
        </div>
        <div className="p-b--lg p-t--lg  d--grid grid-system m-t--xl">
          <div></div>
          {/* <span className="items d--flex flex--column align-items--center justify-content--center gap--sm">
            <span className="w--full  d--flex align-items--center justify-content--center   c--pointer">
              <AddCall />
            </span>
            <span className="font--sm font--600 ">Add Call</span>
          </span> */}
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm">
            <div className="heading heading--md mb-2">
              <span className="d--flex flex--column align-items--center justify-content--center">
                <span
                  style={{ transform: 'rotate(135deg)' }}
                  className={
                    'w-min--50 w-max--50 h-min--50 h-max--50 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--red  c--pointer bg bg--dark-red'
                  }
                  onClick={() => {
                    _terminate(callID);
                  }}
                >
                  <CallIcon />
                </span>
              </span>
            </div>
          </span>
          <div></div>
          {/* <span
            className="items d--flex flex--column align-items--center justify-content--center gap--sm"
            onClick={() => setaction('attended_transfer')}
          >
            <span className=" w--full  d--flex align-items--center justify-content--center  c--pointer">
              <CallTransferIcon />
            </span>
            <span className="text--sm font--600">Transfer</span>
          </span> */}
        </div>

        {/* <span className="items d--flex flex--column align-items--center justify-content--center gap--sm">
          &nbsp;
        </span>
        <span className="items d--flex flex--column align-items--center justify-content--center gap--sm">
          &nbsp;
        </span>
      
        <span className="items d--flex flex--column align-items--center justify-content--center gap--sm">
          &nbsp;
        </span> */}
      </div>
    </>
  );
};

export default ConnectedScreen;
