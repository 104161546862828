import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import useIcons from '../../assets/icons/useIcons';
import { AGENCY_OWNER, USER } from '../../helpers/functions';
import { useAuth } from '../../hooks/useAuth';

const Call = () => {
  const {
    CallIcon,
    CallRecordIcon,
    VoicemailIcon,
    CallHistoryIcon,
    MusicIcon,
    UsersIcon,
    MobileIcon,
  } = useIcons();
  const { user } = useAuth();
  const callTabs = [
    {
      id: 1,
      label: 'Call Forwarding',
      icon: CallIcon,
      path: '/call/call-forwarding',
      access: [USER, AGENCY_OWNER],
    },

    {
      id: 2,
      label: 'Call Recording',
      icon: CallRecordIcon,
      path: '/call/call-recording',
      access: [USER, AGENCY_OWNER],
    },
    {
      id: 3,
      label: 'Voicemail',
      icon: VoicemailIcon,
      path: '/call/voicemail',
      access: [USER, AGENCY_OWNER],
    },
    {
      id: 4,
      label: 'Recent Calls',
      icon: CallHistoryIcon,
      path: '/call/call-history',
      access: [USER, AGENCY_OWNER],
    },
    {
      id: 5,
      label: 'Custom Greetings',
      icon: MusicIcon,
      path: '/call/greetings',
      access: [USER, AGENCY_OWNER],
    },
    {
      id: 6,
      label: 'Department',
      icon: UsersIcon,
      path: '/call/department',
      access: [USER, AGENCY_OWNER],
    },
    {
      id: 7,
      label: 'IVR Menu',
      icon: MobileIcon,
      path: '/call/ivr',
      access: [USER, AGENCY_OWNER],
    },
  ];
  return (
    <div className="w--full h--full d--flex gap--md callPage">
      <div className="w-min--200 w-max--200 w--full flex--column d--flex">
        <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">Call</h4>
        <div className="sideTab w--full h--full w-min--200 w-max--200 bg--white box-shadow--xs radius--sm p--md d--flex flex--column gap--md">
          {callTabs &&
            callTabs.map(({ id, label, icon: Icon, path, access }) => {
              if (![user?.role_id]?.find((role) => access?.includes(role))) {
                return;
              }
              return (
                <NavLink
                  to={path}
                  key={id}
                  data-link-hover
                  className={({ isActive }) =>
                    `${isActive ? 'bg--primary-100' : ''
                    }  d--flex align-items--center justify-content--start text--black  d--flex gap--sm p--sm radius--sm h-min--36 font--500`
                  }
                >
                  <Icon width={18} /> {label}
                </NavLink>
              );
            })}

          {/* <NavLink
          data-link-hover
          className="d--flex align-items--center justify-content--start text--black  d--flex gap--sm p--sm radius--sm h-min--36 font--500"
        >
          <CallRecordIcon width={18} /> Call Recording
        </NavLink>
        <NavLink
          data-link-hover
          className="d--flex align-items--center justify-content--start text--black  d--flex gap--sm p--sm radius--sm h-min--36 font--500"
        >
          <VoicemailIcon width={18} /> Voicemail
        </NavLink>
        <NavLink
          data-link-hover
          className="d--flex align-items--center justify-content--start text--black  d--flex gap--sm p--sm radius--sm h-min--36 font--500"
        >
          <CallHistoryIcon width={18} /> Call History
        </NavLink> */}
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default Call;
