import React from 'react';
import Button from '../../../../comopnents/Button';
import Modal from '../../../../comopnents/Modal';

const ConfirmationModal = ({ show, setshow, handleConfirm = () => null }) => {
  return (
    <>
      {show && (
        <Modal
          handleClose={() => setshow(false)}
          title={'Please confirm'}
          headerComponent={true}
          shouldCloseOnClickOutside={true}
          footerComponent={
            <div className='gap--sm d--flex w--full'>
              <Button
                type="button"
                variant="contrast"
                color="primary"
                btnClasses="btn"
                onClick={() => setshow(false)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="primary"
                color="white"
                btnClasses="btn"
                onClick={() => {
                  setshow(false);
                  handleConfirm(show);
                }}
              >
                OK
              </Button>
            </div>
          }
          size="500"
          height="250"
        >
          <div className="font--md m-b--0 text--black-600 line-height--1-dot-5">
            Are you sure about proceeding the payment?
          </div>
        </Modal>
      )}
    </>
  );
};

export default ConfirmationModal;
