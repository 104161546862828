import React, { useEffect } from 'react';
import useIcons from '../../../assets/icons/useIcons';
import aboutImg1 from '../../../assets/images/web/aboutUs-1.png';
import aboutMission from '../../../assets/images/web/aboutMission.png';
import whatwedo from '../../../assets/images/web/whatwedo.png';
const Company = () => {
  const { SafeIcon, TeamIcon, TickFillIcon, CurveIcon, FiveCornIcon } =
    useIcons();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="w--full companyPage">
        <div className="companyPage w--full">
          <div className="aboutUS section p-t--80">
            <div className="container">
              <div className="w--full d--flex align-items--center gap--2xl flex-column--xs flex-column--sm">
                <div className="w--full d--flex flex--column  gap--lg position--relative">
                  <h3 className="font--42 font--700 line-height--1-dot-2 m-b--xs">
                    About Us
                  </h3>
                  <p className="font--md text--grey line-height--2">
                    Nextere Voice revolutionized customer communication with
                    cutting-edge language, speech, and voice recognition
                    technology. Our platform offers interactive and seamless
                    communication platforms to enhance customer engagement.
                  </p>
                  <div className="w--full d--flex gap--lg">
                    <div className="w-min--75 w-max--75 h-min--75 h-max--75 radius--sm bg--contrast d--flex align-items--center justify-content--center">
                      <SafeIcon width={55} height={55} />
                    </div>
                    <div className="w--full d--flex flex--column gap--sm">
                      <h5 className="font--lg font--600">Safe and secured</h5>
                      <p className="font--md text--grey line-height--2">
                        We tailored our services with security and privacy over
                        data. Stay calm with our services over confidential
                        information.
                      </p>
                    </div>
                  </div>
                  <div className="w--full d--flex gap--lg">
                    <div className="w-min--75 w-max--75 h-min--75 h-max--75 radius--sm bg--contrast d--flex align-items--center justify-content--center">
                      <TeamIcon width={55} height={55} />
                    </div>
                    <div className="w--full d--flex flex--column gap--sm">
                      <h5 className="font--lg font--600">Highly expert team</h5>
                      <p className="font--md text--grey line-height--2">
                        Our highly proficient team always tries to find
                        innovative and creative solutions to resolve customer
                        queries and services.
                      </p>
                    </div>
                  </div>
                  <div className="fiveCornIcon animate bounce--right text--success position--absolute">
                    <FiveCornIcon width={80} height={80} />
                  </div>
                </div>
                <div className="w--full">
                  <img
                    src={aboutImg1}
                    alt="bannerImg"
                    className="w--full radius--md"
                  />
                </div>
              </div>
            </div>

            <div className="curveIcon animate mover text--success position--absolute">
              <CurveIcon width={80} height={150} />
            </div>
          </div>
          <div className="missionValue section ">
            <div className="container">
              <div className="d--flex w--full gap--xl flex-column--xs flex-column--sm">
                <div className="w--full w-max--400 order--xs--2">
                  <img
                    src={aboutMission}
                    alt="bannerImg"
                    className="w--full radius--md"
                  />
                </div>
                <div className="w--full d--flex flex--column gap--lg">
                  <h3 className="font--42 font--700 line-height--1-dot-2">
                    Our Mission and Values
                  </h3>
                  <p className="font--md text--grey line-height--2">
                    Nextere’s voice mission is to satisfy their customer'
                    requirements and communicate with their interactive
                    solutions. Our values include customer satisfaction, better
                    communication services, innovation & creativity with
                    responsibility. We encourage you to deliver seamless and
                    efficient services
                  </p>
                  <div className="w--full d--flex gap--xl w--full flex-column--xs">
                    <div className="bg--primary-100 p--2xl radius--sm d--flex gap--xl flex--column w--full">
                      <div className="d--flex align-items--center font--sm gap--md">
                        <span className="text--primary">
                          <TickFillIcon />
                        </span>{' '}
                        Customer Satisfaction
                      </div>
                      <div className="d--flex align-items--center font--sm gap--md">
                        <span className="text--primary">
                          <TickFillIcon />
                        </span>{' '}
                        Teamwork and collaboration
                      </div>
                      <div className="d--flex align-items--center font--sm gap--md">
                        <span className="text--primary">
                          <TickFillIcon />
                        </span>{' '}
                        Innovation and creativity
                      </div>
                    </div>
                    <div className="bg--primary-100 p--2xl radius--sm d--flex gap--xl flex--column w--full">
                      <div className="d--flex align-items--center font--sm gap--md">
                        <span className="text--primary">
                          <TickFillIcon />
                        </span>{' '}
                        Responsibility
                      </div>
                      <div className="d--flex align-items--center font--sm gap--md">
                        <span className="text--primary">
                          <TickFillIcon />
                        </span>{' '}
                        Empowerment and Leadership
                      </div>
                      <div className="d--flex align-items--center font--sm gap--md">
                        <span className="text--primary">
                          <TickFillIcon />
                        </span>{' '}
                        Flexibility and Custom solutions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="missionValue bg--contrast d--flex gap--2xl p-b--0 flex-column--xs flex-column--sm">
            <div className="w--full order--xs--2">
              <img src={whatwedo} alt="bannerImg" className="w--full h--full" />
            </div>
            <div className="w--full d--flex flex--column  gap--lg p--2xl">
              <h3 className="font--42 font--700 line-height--1-dot-2 m-b--xs">
                What we do
              </h3>
              <p className="font--md text--grey line-height--2">
                Nextere’s Voice specializes in fostering customer communication.
                We offer seamless and custom interactions with speech
                recognition and natural language processing technology. Our
                platforms offer better customer engagement streamline
                communication and enhance customer satisfaction. Experience a
                better voice of the future with Nextere’s voice. Nextere’s voice
                establishes interactive communication solutions for better
                customer engagement anytime, anywhere, and everywhere.
              </p>
              <p className="font--md text--grey line-height--2">
                Nextere’s voice establishes interactive communication solutions
                for better customer engagement anytime, anywhere, and
                everywhere. Nextere’s Voice platform focuses on 24/7 hrs
                customer service with repetitive tasks with high-value
                activities and strategies. Experience better future customer
                services with Nextere and Unlock the new levels of business and
                growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Company;
