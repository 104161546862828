import React, { useEffect, useMemo, useRef, useState } from 'react';
import TableManager from '../../../comopnents/TableManager';
import {
  callHistory,
  particularCallHistory,
  unseenVoicemailList,
} from '../../../api';
import {
  AGENCY_AGENT,
  AGENT,
  EMPLOYEE,
  converDateTimeFormateApis,
  durationToMMSS,
  formatPhoneNumber,
  isElectron,
} from '../../../helpers/functions';
import useIcons from '../../../assets/icons/useIcons';
import AudioPlayer from '../../../comopnents/AudioPlayer';
import AsideModal from '../../../comopnents/AsideModal';
// import Transcripts2 from '../../../comopnents/Transcripts2';
import moment from 'moment';
import useAllNumberDetails from '../../../hooks/useAllNumberDetails';
import { useAuth } from '../../../hooks/useAuth';
import useMemberAgentsAllListing from '../../../hooks/useMemberAgentsAllListing';
import useRingGroupList from '../../../hooks/useRingGroupList';
import Transcripts2 from '../../../comopnents/Transcripts2';
import useCallRecordingSeen from '../../../hooks/useCallRecordingSeen';
import useGreetingList from '../../../hooks/useGreetingList';
import { capitalizeWords } from '../../ActivityManagement/TimeLine/Activity';
import useContactListing from '../../../hooks/useContactListing';
import useDidList from '../../../hooks/useDidList';
import VoicemailSideModal from '../../../comopnents/Header/VoicemailSideModal';
import { useSearchParams } from 'react-router-dom';
import Tabbing from '../../../comopnents/Tabbing';
import { useQueryClient } from '@tanstack/react-query';

export const FilterItem = ({ item }) => {
  return (
    <option className="text--black" key={item.uuid} value={item?.value}>
      {item.label}
    </option>
  );
};

export default function Voicemail() {
  const queryClient = useQueryClient();
  const {
    PlayIcon,
    HoldIcon,
    MissedCall,
    OutgoingCall,
    IncomingCall,
    TranscriptsIcon,
    VoicemailIcon,
    VoicemailGroupIcon,
  } = useIcons();
  const [tab, setTab] = useState('unseen');
  const { getNumberName } = useAllNumberDetails();
  const { dropdownContactList } = useContactListing();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate: callRecordingMutateSeen } = useCallRecordingSeen();
  const [isEnabled, setIsEnabled] = useState(false);
  const tableRef = useRef();
  const [showTranscript, setShowTranscript] = useState(false);
  const [transcriptData, setTranscriptData] = useState(null);
  const [showUnseenVoicemail, setShowUnseenVoicemail] = useState(null);
  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
    uuid: null,
  });
  const [memberListAll, setMemberListALL] = useState([]);
  const { user } = useAuth();

  const handleSetTranscript = (data) => {
    setTranscriptData(data);
    setShowTranscript(true);
  };

  const { mutate: didListMutate, data: didListData } = useDidList();
  const { mutateAsync: mutateAsyncALL, data: dataALL } =
    useMemberAgentsAllListing();
  // const { mutateAsync: ivrListMutate, data: ivrListData } = useIVRList();
  const { mutate: ringGroupListMutate, data: ringGroupListData } =
    useRingGroupList();
  const { mutate: greetingListMutate, data: greetingListData } =
    useGreetingList();

  useEffect(() => {
    if (!dataALL?.data) {
      mutateAsyncALL({ get_owner: 'Y', page: 1 });
    } else {
      setMemberListALL(dataALL?.data?.data ?? []);
    }
  }, [dataALL]);

  useEffect(() => {
    // ivrListMutate();
    ringGroupListMutate({ page: 1 });
    greetingListMutate({ page: 1 });
    didListMutate({ page: 1 });
    queryClient.invalidateQueries({
      queryKey: ['unseenVoiceMailCount'],
      exact: false,
    });
  }, []);

  useEffect(() => {
    if (searchParams && searchParams?.get('showunseenvoicemail')) {
      if (searchParams?.get('showunseenvoicemail') === 'true') {
        setShowUnseenVoicemail(true);
        setSearchParams('');
      }
    }
  }, [searchParams]);

  const callIconLookup = {
    Missed: (
      <span className="text--danger">
        <MissedCall width={16} height={16} />
      </span>
    ),
    Outbound: (
      <span className="text--success">
        <OutgoingCall width={16} height={16} />
      </span>
    ),
    Inbound: (
      <span className="text--primary">
        <IncomingCall width={16} height={16} />
      </span>
    ),
  };

  const columns = [
    {
      accessorKey: 'start_stamp',
      header: () => 'Date/Time',
      cell: (props) => {
        return converDateTimeFormateApis(props?.row?.original?.start_stamp);
      },
    },

    {
      accessorKey: 'caller_id_number',
      header: () => 'From',
      cell: (props) => {
        return (
          <div className="d--flex flex--column">
            <div>
              {getNumberName(props?.getValue(), false)?.includes('+')
                ? formatPhoneNumber(props?.getValue())
                : getNumberName(props?.getValue(), false)}
            </div>
            {props?.getValue()?.length < 5 && (
              <div className="font--xs">{`Ext. ${props?.getValue()}`}</div>
            )}
          </div>
        );
      },
      meta: {
        width: '15%',
      },
    },

    {
      accessorKey: 'caller_destination',
      header: () => 'Via Virtual Number',
      cell: (props) => {
        return formatPhoneNumber(props?.getValue());
      },
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },

    {
      accessorKey: 'forward_type',
      header: () => 'To',
      cell: (props) => {
        const elm = props?.row?.original;
        const res =
          props?.getValue() === 'VOICEMAILGROUP' ||
          props?.getValue() === 'VOICEMAIL'
            ? elm?.forward_name
            : props?.getValue();
        return (
          <div className="d--flex align-items--center gap--sm">
            {props?.getValue() === 'VOICEMAILGROUP' ? (
              <VoicemailGroupIcon width={20} height={20} />
            ) : (
              <VoicemailIcon width={20} height={20} />
            )}
            {capitalizeWords(res?.toLowerCase(), ' ')}
          </div>
        );
      },
    },
    {
      accessorKey: 'duration',
      header: () => 'Duration',
      cell: (props) => durationToMMSS(props.getValue()),
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },

    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { recording_file = '', xml_cdr_uuid = '' } = element;
        const isPlaying =
          playingData?.playing &&
          playingData?.file === recording_file &&
          playingData?.uuid === xml_cdr_uuid;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            {isPlaying ? (
              <span
                className="c--pointer"
                onClick={() => {
                  setPlayingData({ playing: false, file: null, uuid: null });
                }}
              >
                <HoldIcon width={20} height={20} />
                <AudioPlayer
                  onClose={() => {
                    setPlayingData({ playing: false, file: null, uuid: null });
                  }}
                  name={recording_file}
                />
              </span>
            ) : (
              <span
                className={`c--pointer ${
                  element?.seen_at ? 'text--success' : 'text--red'
                } `}
                onClick={() => {
                  if (!element?.seen_at) {
                    callRecordingMutateSeen({
                      uuid: element?.xml_cdr_uuid,
                    });
                  }
                  setPlayingData({
                    playing: true,
                    file: recording_file,
                    uuid: xml_cdr_uuid,
                  });
                }}
              >
                <PlayIcon width={20} height={20} />
              </span>
            )}
            <div
              data-tooltip={'Transcripts'}
              tooltip-position="left"
              onClick={() => handleSetTranscript(element)}
            >
              <TranscriptsIcon />
            </div>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];

  const filterInitialValues = useMemo(() => {
    return {
      filter: [
        // {
        //   key: 'direction',
        //   value: '',
        //   isChecked: false,
        //   inputPlaceholder: 'Select',
        //   inputType: 'select',
        //   inputLabel: 'Call Direction',
        //   inputOptions: [
        //     {
        //       value: 'All',
        //       label: 'All',
        //     },
        //     {
        //       value: 'Inbound',
        //       label: 'Incoming',
        //     },
        //     {
        //       value: 'Outbound',
        //       label: 'Outbound',
        //     },
        //   ],
        //   isDisabled: false,
        //   enableCrossDisabling: true,
        //   crossDisablingFunction: ({
        //     valueToUpdate = {},
        //     triggerDisable = () => null,
        //     triggerEnable = () => null,
        //     resetDisable = () => null,
        //     customValueUpdate = () => null,
        //   }) => {
        //     const { value } = valueToUpdate;
        //     if (value === 'Inbound') {
        //       triggerEnable({ key: 'department' });
        //       triggerEnable({ key: 'ivr' });
        //       customValueUpdate({
        //         targetKey: 'extension',
        //         key: 'inputLabel',
        //         value: 'Extension',
        //       });
        //     }
        //     if (value === 'Outbound') {
        //       triggerDisable({ key: 'department' });
        //       triggerDisable({ key: 'ivr' });
        //       customValueUpdate({
        //         targetKey: 'extension',
        //         key: 'inputLabel',
        //         value: 'User',
        //       });
        //     }
        //     if (value === 'All') {
        //       resetDisable();
        //       customValueUpdate({
        //         targetKey: 'extension',
        //         key: 'inputLabel',
        //         value: 'Extension',
        //       });
        //     }
        //   },
        // },
        {
          key: 'name',
          value: '',
          isChecked: false,
          inputPlaceholder: 'Enter Name/Number',
          inputType: 'custom-selects-name-number',
          inputLabel: 'Name/Number',
          inputOptions: dropdownContactList,
        },
        {
          key: 'extension',
          value: '',
          isChecked: false,
          inputPlaceholder: 'Enter Extension Number',
          inputType: 'multi-select',
          inputLabel: 'Extension',
          inputOptions: memberListAll.map((item) => {
            return {
              label: `${item?.first_name} ${item?.last_name}`,
              value: item?.extension,
              first_name: item?.first_name,
              last_name: item?.last_name,
              profile_pic: item?.profile_pic,
              extension: item?.extension,
            };
          }),
          access: ![AGENT, EMPLOYEE, AGENCY_AGENT].includes(user?.role_id),
        },
        {
          key: 'did_number',
          value: '',
          isChecked: false,
          inputPlaceholder: 'Enter virtual number',
          inputType: 'select',
          inputLabel: 'Virtual Number',
          inputOptions: didListData?.data?.data?.map((item) => {
            return {
              label: item?.did_number,
              value: item?.did_number,
            };
          }),
        },
        {
          key: 'department',
          value: '',
          isChecked: false,
          inputPlaceholder: 'Enter Department',
          inputType: 'select',
          inputLabel: 'Department',
          inputOptions: ringGroupListData?.data?.data?.map((item) => {
            return {
              label: item?.title,
              value: item?.uuid,
            };
          }),
        },

        // {
        //   key: 'ivr',
        //   value: '',
        //   isChecked: false,
        //   inputPlaceholder: 'Enter IVR',
        //   inputType: 'select',
        //   inputLabel: 'IVR',
        //   inputOptions: ivrListData?.data?.data?.map((item) => {
        //     return {
        //       label: item?.name,
        //       value: item?.uuid,
        //     };
        //   }),
        //   access: ![AGENT, EMPLOYEE].includes(user?.role_id),
        // },

        {
          key: 'duration',
          value: '',
          isChecked: false,
          inputPlaceholder: 'Enter Duration',
          inputType: 'select',
          inputLabel: 'Duration',
          inputOptions: [
            {
              value: '0-2',
              label: '0-2',
            },
            {
              value: '2-5',
              label: '2-5',
            },
            {
              value: '5-10',
              label: '5-10',
            },
            {
              value: '10-50',
              label: '10-50',
            },
            {
              value: '50',
              label: '50',
            },
          ],
        },
        // {
        //   key: 'date',
        //   inputLabel: 'By Date',
        //   isChecked: true,
        //   value: weeklyFilterObject,
        //   inputType: 'date',
        //   dateType: 'This Week',
        //   access: true,
        // },
        // {
        //   key: 'date',
        //   inputLabel: 'By Date',
        //   isChecked: false,
        //   value: {
        //     form: '',
        //     to: '',
        //   },
        //   inputType: 'date',
        //   dateType: '',
        // },
      ],
    };
  }, [
    ringGroupListData?.data?.data,
    // ivrListData?.data?.data,
    memberListAll,
    didListData?.data?.data,
  ]);

  useEffect(() => {
    if (filterInitialValues?.filter) {
      const newState = [...filterInitialValues?.filter];
      const index = newState.findIndex((value) => value?.key === 'date');
      if (index > -1) {
        newState.splice(index, 1, {
          key: 'date',
          inputLabel: 'By Date',
          isChecked: true,
          value: weeklyFilterObject,
          inputType: 'date',
          dateType: 'This Week',
          access: true,
        });
      }
      tableRef?.current?.overrideFilters(newState);
      setIsEnabled(true);
    }
  }, [filterInitialValues?.filter]);

  const handleClose = () => {
    setTranscriptData(null);
    setShowTranscript(false);
  };

  return (
    <div className="h--full w--full">
      {isElectron ? (
        <>
          {tab === 'unseen' && (
            <div>
              <TableManager
                {...{
                  fetcherKey: 'unseenVoicemailList',
                  fetcherFn: unseenVoicemailList,
                  columns,
                  name: `New Voicemails`,
                  shouldFilter: false,
                  // shouldShowTotalCount: true,
                  showMoreOption: (data) => {
                    return data?.count > 1;
                  },
                  enableMoreOption: true,
                  moreDataFunction: particularCallHistory,
                  moreDataFnPayload: (data) => {
                    return data?.xml_cdr_uuid;
                  },
                  customFilters: false,
                  showHeader: true,
                  showPagination: true,
                  showPageCount: true,
                  showRefresh: false,
                  enableDefaultFilter: '',
                  tabsLabels: [
                    { label: 'New voicemails', value: 'unseen' },
                    { label: 'All voicemails', value: 'all' },
                  ],
                  tab,
                  setTab,
                  withTabs: true,
                }}
              />
              {playingData?.playing && (
                <AudioPlayer
                  onClose={() => {
                    setPlayingData({ playing: false, file: null, uuid: null });
                  }}
                  name={playingData?.file}
                />
              )}
            </div>
          )}
          {tab === 'all' && (
            <div>
              <TableManager
                {...{
                  fetcherKey: 'callHistory',
                  fetcherFn: callHistory,
                  columns,
                  shouldFilter: true,
                  initialFilterState: filterInitialValues,
                  name: `All Voicemails`,
                  extraParams: {
                    type: 'ALL',
                    request_type: 'voicemail-list',
                  },
                  shouldShowTotalCount: false,
                  customTotalCount: false,
                  ref: tableRef,
                  isEnabled,
                  showMoreOption: (data) => {
                    return data?.count > 1;
                  },
                  enableMoreOption: false,
                  moreDataFunction: particularCallHistory,
                  moreDataFnPayload: (data) => {
                    return data?.xml_cdr_uuid;
                  },
                  customFilters: true,
                  tabsLabels: [
                    { label: 'New voicemails', value: 'unseen' },
                    { label: 'All voicemails', value: 'all' },
                  ],
                  tab,
                  setTab,
                  withTabs: true,
                }}
              />
              {playingData?.playing && (
                <AudioPlayer
                  onClose={() => {
                    setPlayingData({ playing: false, file: null, uuid: null });
                  }}
                  name={playingData?.file}
                />
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {showUnseenVoicemail ? (
            <>
              <VoicemailSideModal
                handleClose={() => setShowUnseenVoicemail(false)}
              />
            </>
          ) : (
            <TableManager
              {...{
                fetcherKey: 'callHistory',
                fetcherFn: callHistory,
                columns,
                shouldFilter: true,
                initialFilterState: filterInitialValues,
                name: `Voicemail  `,
                extraParams: {
                  type: 'ALL',
                  request_type: 'voicemail-list',
                },
                shouldShowTotalCount: false,
                customTotalCount: false,
                ref: tableRef,
                isEnabled,
                showMoreOption: (data) => {
                  return data?.count > 1;
                },
                enableMoreOption: false,
                moreDataFunction: particularCallHistory,
                moreDataFnPayload: (data) => {
                  return data?.xml_cdr_uuid;
                },
                customFilters: true,
              }}
            />
          )}
        </>
      )}
      {showTranscript && (
        <AsideModal
          handleClose={handleClose}
          title={'Call Intelligence'}
          footerComponent={null}
          width="md"
        >
          <Transcripts2
            transcript_file_url={transcriptData?.transcript_file_url}
            recording_file_url={`${process.env.REACT_APP_MEDIA_URL}signed-url/${
              user?.parent_uuid || user?.uuid
            }/recording?filename=${transcriptData?.recording_file}`}
          />
        </AsideModal>
      )}
    </div>
  );
}

const weeklyFilterObject = {
  from: moment().startOf('week').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
};
