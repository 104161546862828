import React from 'react';
import TableManager from '../../../comopnents/TableManager';
import Button from '../../../comopnents/Button';
import useIcons from '../../../assets/icons/useIcons';
import ConfirmComponent from '../../../comopnents/ConfirmComponent';
import Dropdown from '../../../comopnents/Dropdown';

const Scripts = () => {
  const { MoreVIcon } = useIcons();

  function dummyDataFn() {
    return {
      data: {
        success: true,
        message: 'Success',
        totalRecords: 3,
        currentPage: 1,
        lastPage: 1,
        perPage: 10,
        data: [
          {
            date: '24 Jan 2021',
            name: 'Hardy',
            title: 'Testing',
            description: 'This is a testing script',
          },
        ],
      },
    };
  }

  const columns = [
    {
      accessorKey: 'date',
      header: () => 'Date',
    },
    {
      accessorKey: 'title',
      header: () => 'Title',
    },
    {
      accessorKey: 'description',
      header: () => 'Description',
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <Dropdown
              extraClasses="w-min--150"
              closeOnClickOutside={false}
              dropList={{
                component: ({ item }) => {
                  if (item?.shouldConfirm) {
                    return (
                      <ConfirmComponent
                        item={item}
                        element={element}
                        label="Please confirm?"
                      // confirmCallback={(data) => handlContactDelete(data)}
                      >
                        {item?.title}
                      </ConfirmComponent>
                    );
                  }
                  return (
                    <span
                      className="w--full d--flex  justify-content--start p--2sm"
                    // onClick={() => handleDropListClick(item, element)}
                    >
                      {item?.title}
                    </span>
                  );
                },
                data: [
                  { title: 'Edit', value: 'edit' },
                  { title: 'Delete', value: 'delete', shouldConfirm: true },
                ],
              }}
              showcaret={false}
            >
              <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={13} height={13} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '3%',
      },
    },
  ];
  const tableActions = [
    {
      id: 1,
      label: 'Add',
      access: true,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          size="sm"
        >
          Add
        </Button>
      ),
    },
  ];
  return (
    <div className="w--full h--full">
      <TableManager
        fetcherKey={'script-listing'}
        fetcherFn={dummyDataFn}
        columns={columns}
        name={'Scripts'}
        shouldFilter={false}
        tableActions={tableActions}
      />
    </div>
  );
};

export default Scripts;
