import { useMutation, useQueryClient } from '@tanstack/react-query';
import { memberSignup } from '../../api';
import { useAlert } from '../useAlert';

export default function useMemberSignup(handleClose) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: memberSignup,
    mutationKey: 'memberSignup',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['getMemberListing'],
        exact: false,
      });
      queryClient.refetchQueries({
        queryKey: ['getAllNumberDetails'],
        exact: true,
      });
      showAlert({
        type: 'success',
        message: data?.message ?? 'Member added sucessfully',
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
