import React, { useContext, useEffect, useState } from 'react';
import Button from '../../Button';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import { useAuth } from '../../../hooks/useAuth';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

const Notes = () => {
  const { socket = {} } = useContext(SipSocketContext);
  let [searchParams] = useSearchParams();
  const [text, setText] = useState('');
  const { user = null } = useAuth();
  const [notes, setNotes] = useState([]);

  const { _uiSessions = {}, _uaSessions = {} } = socket;

  let activeCallKey = Object.keys(_uiSessions)?.[0];

  const callID =
    _uaSessions?.[activeCallKey]?._request?.headers?.['X-Cid']?.[0]?.raw || '';

  // const sipcallID = _uaSessions?.[activeCallKey]?._id || '';

  const sipcallID = _uaSessions?.[activeCallKey]?._request?.call_id || '';

  const contactID =
    sessionStorage?.getItem('cid') || searchParams?.get('contactId') || '';

  useEffect(() => {
    if (contactID && socket?.sipCallSocket) {
      getContactActivity(contactID);
    }
  }, [contactID, socket?.sipCallSocket]);

  function getContactActivity(contactID) {
    socket?.sipCallSocket.emit(
      'contact-activity',
      {
        contact_uuid: contactID,
      },
      async (res) => {
        const response = await res;
        if (response?.status === 200) {
          const data = response?.data ?? [];
          let notes = [];
          if (data && data?.length > 0) {
            data?.forEach((doc) => {
              for (let i = 0; i < doc?.note?.length; i++) {
                const element = doc?.note?.[i];
                notes.push(element);
              }
              setNotes(notes);
            });
          } else {
            setNotes([]);
          }
        } else {
          setNotes([]);
        }
      },
    );
  }

  function handleSendNotes() {
    if (!text) return;
    socket?.sipCallSocket.emit('contact-note', {
      creator_uuid: user?.uuid,
      contact_uuid: contactID,
      callID,
      sipcallID,
      note: text,
    });

    setNotes((prevNotes) => [
      ...prevNotes,
      {
        note: text,
        creator_uuid: user?.uuid,
        created_at: moment().format('YYYY-MM-DD'),
      },
    ]);

    setText('');
  }

  return (
    <div className="h-min--385 h-max--385 overflow--auto  p--md ">
      <div className="notes-list h-min--250 h-max--250 overflow--auto">
        {notes && notes.length > 0
          ? notes.map((note) => (
              <div
                className="notes-list-items bg--contrast p--md radius--sm m-b--2sm"
                key={note?.created_at}
              >
                <p className="m-b--0">{note?.note}</p>
              </div>
            ))
          : null}
      </div>

      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="form--control w--full  radius--sm p--md  border-full--black-200 m-b--sm"
        rows={5}
      ></textarea>
      <div className="w--full">
        <Button
          type="button"
          variant="primary"
          color="white"
          btnClasses="btn   font--sm m-b--sm"
          onClick={() => handleSendNotes()}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Notes;
