import React, { useEffect, useState } from 'react';
import FormInput from '../FormInput';
import Dropdown from '../Dropdown';
import useIcons from '../../assets/icons/useIcons';
import Avatar from '../Avatar';

const handleGetValue = (value, options = []) => {
  const reqObj =
    options?.filter((opt) => `${opt.extension}` === `${value}`)?.[0] ?? null;

  if (reqObj) {
    return `${reqObj?.first_name} ${reqObj.last_name}`;
  }
  return '';
};

const SingleDropdownMain = ({
  optionList = [],
  labelKey = 'first_name',
  label = '',
  placeholder = '',
  error = '',
  withCaret = false,
  border = true,
  extraClasses = '',
  valueKey = '',
  paddingRight = 'md',
  paddingLeft = 'md',
  component: Component = null,
  optionListLoad = false,
  ...rest
}) => {
  const { CaretIcon } = useIcons();
  const [searchValue, setSearchValue] = useState('');

  const dropListNotification = {
    component: ({ item, setShow = () => {} }) => {
      if (Component) {
        return (
          <Component
            item={item}
            onChange={rest?.onChange}
            setSearchValue={setSearchValue}
            setShow={setShow}
          />
        );
      }
    },
    data: searchValue
      ? optionList.filter((option) =>
          option?.[labelKey]?.toLowerCase().includes(searchValue),
        ) || []
      : optionList,
  };
  return (
    <div className="w--full  d--flex flex--column">
      <Dropdown
        closeOnClickOutside={false}
        dropList={dropListNotification}
        showcaret={withCaret}
        caretComponent={CaretIcon}
        extraClasses="w--full"
        withSearch={true}
        search={searchValue}
        setSearch={setSearchValue}
        manualCloseOnClick={true}
      >
        <FormInput
          readOnly
          type="text"
          extraClasses={`w--full ${extraClasses}`}
          label={label}
          placeholder={
            optionListLoad
              ? 'Please wait...'
              : rest?.value
              ? handleGetValue(`${rest?.value}`, optionList)
              : placeholder
          }
          value={
            !rest?.value ||
            rest?.value === undefined ||
            rest?.value === 'undefined' ||
            typeof rest?.value === 'object'
              ? ''
              : optionList.find((option) =>
                  option?.[valueKey]?.toLowerCase().includes(rest?.value),
                )?.[labelKey]
          }
          error={error}
          border={border}
          paddingRight={paddingRight}
          paddingLeft={paddingLeft}
          disabled={optionListLoad}
        />
      </Dropdown>
    </div>
  );
};

export default SingleDropdownMain;
