import { useContext, useEffect, useState } from 'react';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';
import { useAuth } from '../../hooks/useAuth';
import Avatar from '../Avatar';
import useIcons from '../../assets/icons/useIcons';

const GetNameBySocket = ({
  number = '',
  getAvatar = false,
  getNumber = true,
  avatarSize = '40',
}) => {
  const { user = null } = useAuth();
  const { socket = {} } = useContext(SipSocketContext);
  const [data, setData] = useState(null);
  const { _uiSessions = {}, _uaSessions = {} } = socket;
  const { UserIcon } = useIcons();
  let activeCallKey = Object.keys(_uiSessions)?.[0];

  const forwardType =
    _uaSessions?.[activeCallKey]?._request?.headers?.['X-Forwardtype']?.[0]
      ?.raw ?? undefined;

  const forwardValue =
    _uaSessions?.[activeCallKey]?._request?.headers?.['X-Forwardvalue']?.[0]
      ?.raw ?? undefined;

  const [name, setName] = useState(null);

  function handleGetContactName(n) {
    if (!n) return;
    socket?.sipCallSocket.emit(
      'dialer-phone-info',
      // 'phone-info',
      {
        domain: user?.domain,
        phone: n,
        mode: forwardType,
        // uuid: forwardValue,
        uuid: user?.uuid,
      },
      async (res) => {
        const response = await res;
        if (response?.status === 200) {
          setData(response?.data);
          const name = `${response?.data?.first_name ?? ''} ${
            response?.data?.last_name ?? ''
          }`;
          setName(name);
          sessionStorage.setItem('cid', response?.data?.uuid);
        } else {
          setData(null);
          if (getNumber) {
            setName(number);
          } else {
            setName('');
          }
          if (sessionStorage.getItem('cid')) {
            sessionStorage.removeItem('cid');
          }
        }
      },
    );
  }

  useEffect(() => {
    if (number && socket?.sipCallSocket) {
      handleGetContactName(number);
    }
  }, [number, socket?.sipCallSocket]);

  if (getAvatar) {
    if (data && Object.keys(data).length > 0) {
      return (
        <Avatar
          size={avatarSize}
          first_name={data?.first_name || 'U'}
          last_name={data?.last_name || ''}
          shouldShowPresence={false}
          contactImage={data?.contact_pic ?? null}
        />
      );
    } else {
      return <UserIcon />;
    }

    // return name?.charAt(0) === '+'
    //   ? name?.charAt(1)?.toUpperCase() || ''
    //   : name?.charAt(0)?.toUpperCase() || '';
  }
  return name;
};

export default GetNameBySocket;
