import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormInput from '../../../comopnents/FormInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import imageCompression from 'browser-image-compression';
import ImageCropModal from '../../../comopnents/ImageCropModal';
import useUploadMedia from '../../../hooks/useUploadMedia';
import Spinner from '../../../comopnents/Spinner';
import useProfileUpdate from '../../../hooks/useProfileUpdate';
import { useAuth } from '../../../hooks/useAuth';
import Avatar from '../../../comopnents/Avatar';
const validationSchema = yup.object().shape({
  agency_name: yup.string().required(`Company is required.`),
});

const AgencyInfo = () => {
  const { user } = useAuth();
  const [companyImage, setCompanyImage] = useState(null);
  const [isImageUploading, setImageUpload] = useState(false);
  const { mutateAsync: getUploadUrl } = useUploadMedia();
  const { mutateAsync: profileUpdateMutate, isLoading: profileUpdateLoad } =
    useProfileUpdate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      agency_name: user?.agency_name ?? '',
    },
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (user) {
      reset({
        agency_name: user?.agency_name ?? '',
      });
    }
  }, [user]);

  async function onSubmit(values) {
    const payload = {
      agency_name: values?.agency_name ?? '',
      agency_uuid: user?.agency?.uuid,
    };

    profileUpdateMutate(payload);
  }

  const handleFileSelect = async (e) => {
    let file = e.target.files[0];
    if (file) {
      try {
        imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          maxIteration: 20,
        })
          .then((compressedFile) => {
            const reader = new FileReader();
            reader.onload = () => {
              setCompanyImage(reader.result);
            };
            reader.readAsDataURL(compressedFile);
          })
          .catch((err) => console.log('err while compressing', err));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFileUpload = async (data) => {
    fetch(data)
      .then((res) => res.blob())
      .then(async (file) => {
        setCompanyImage(null);
        setImageUpload(true);
        const payload = {
          uuid: user?.uuid,
          file_name: 'companyLogo.png',
          type: 'company_logo',
        };
        const res = await getUploadUrl(payload);
        const { filename, url: uploadUrl } = res?.data?.data || '';
        if (uploadUrl) {
          const uploadFileResponse = await fetch(uploadUrl, {
            method: 'PUT',
            body: file,
          });
          if (uploadFileResponse.status === 200) {
            await profileUpdateMutate({
              agency_logo: filename,
              agency_uuid: user?.agency?.uuid,
            });
            setImageUpload(false);
          }
        }
        setImageUpload(false);
      })
      .catch((err) => {
        console.log({ err });
        setImageUpload(false);
      });
  };
  console.log({ user });
  return (
    <>
      <div className="w--full">
        <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
          Agency Info
        </h4>
        <div className="d--flex gap--md flex--column profilePage bg--white p--md radius--sm h--full overflow--auto box-shadow--xs w--full ">
          <div className="w--full">
            <div className="label--control font--sm font--500 d--flex gap--sm align-items--center m-b--xs  h-min--36 text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm   m-b--lg">
              Agency Info
              <div className="d--flex">
                {(isImageUploading || profileUpdateLoad) && <Spinner />}
              </div>
            </div>
            <form
              className="w--full d--flex flex--column gap--lg"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <div className="d--flex gap--lg w--full">
                <div className="w--full w-max--120 d--flex align-items--center justify-content--center  radius--sm">
                  <Avatar
                    image={user?.agency_logo}
                    first_name={user?.agency?.name}
                    shouldShowPresence={false}
                    size="120"
                    type="company_logo"
                    parent_uuid={user?.uuid}
                  />
                </div>
                <div className="w--full d--flex flex--column gap--lg">
                  <div className="d--flex gap--md">
                    <div className="d--flex w--full position--relative">
                      <div className="w--full  d--flex flex--column position--absolute">
                        <FormInput
                          type="file"
                          accept="image/*"
                          id="filename"
                          name="filename"
                          label={
                            isImageUploading ? 'Please wait' : 'Agency logo'
                          }
                          onChange={(e) => {
                            handleFileSelect(e);
                            e.target.value = '';
                          }}
                        />
                      </div>
                    </div>
                    <Controller
                      name="agency_name"
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          type="text"
                          label={'Agency Name'}
                          placeholder={'Enter agency name'}
                          error={errors?.agency_name?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="w--full d--flex align-items--center justify-content--center m-t--md">
                    <button
                      type="submit"
                      className="btn font--sm d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary w-max--200 c--pointer"
                      disabled={isSubmitting || profileUpdateLoad}
                    >
                      {isSubmitting || profileUpdateLoad
                        ? 'Please wait..'
                        : 'Update Agency'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ImageCropModal
        {...{
          image: companyImage,
          setImage: setCompanyImage,
          setImageUpload,
          upload: (data) => handleFileUpload(data),
        }}
      />
    </>
  );
};

export default AgencyInfo;
