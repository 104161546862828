import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import {  setAsDefault } from '../../api';

export default function useSetAsDefault() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient()
  const Request = useMutation({
    mutationFn: setAsDefault,
    mutationKey: 'setAsDefault',
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.message ?? 'Fund added successfully',
      });
      queryClient.invalidateQueries(['getSavedCards'])
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
