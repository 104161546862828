import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { support } from '../../api';

export default function useSupportQuery() {
  const { showAlert } = useAlert();
  const uploadMediaRequest = useMutation({
    mutationFn: support,
    mutationKey: 'support',
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.message ?? 'Query added successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...uploadMediaRequest };
}
