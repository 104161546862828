import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormInput from '../../../comopnents/FormInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import imageCompression from 'browser-image-compression';
import ImageCropModal from '../../../comopnents/ImageCropModal';
import useUploadMedia from '../../../hooks/useUploadMedia';
import Spinner from '../../../comopnents/Spinner';
import useProfileUpdate from '../../../hooks/useProfileUpdate';
import { useAuth } from '../../../hooks/useAuth';
import Avatar from '../../../comopnents/Avatar';

const validationSchema = yup.object().shape({
  tenant_company: yup
    .string()
    .required(`Company is required`)
    .max(30, 'Maximum length can be 30 characters'),
  tenant_npn: yup
    .string()
    .required(`NPH is required`)
    .matches(/^[0-9]+$/, 'NPH cannot contains space or alphabet'),
  tenant_fein: yup
    .string()
    .required(`FEIN is required`)
    .matches(/^[A-Za-z0-9]+$/, 'FEIN contains only aplhanumeric character'),
});

const CompanyInfo = () => {
  const [companyImage, setCompanyImage] = useState(null);
  const [isImageUploading, setImageUpload] = useState(false);
  const { mutateAsync: getUploadUrl } = useUploadMedia();
  const { mutateAsync: profileUpdateMutate, isLoading: profileUpdateLoad } =
    useProfileUpdate();
  const { user } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tenant_company: '',
      tenant_npn: '',
      tenant_fein: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (user) {
      reset({
        tenant_company: user?.tenant_company ?? '',
        tenant_npn: user?.tenant_npn ?? '',
        tenant_fein: user?.tenant_fein ?? '',
      });
    }
  }, [user]);
  async function onSubmit(values) {
    const payload = {
      tenant_company: values?.tenant_company ?? '',
      tenant_npn: values?.tenant_npn ?? '',
      tenant_fein: values?.tenant_fein ?? '',
    };

    profileUpdateMutate(payload);
  }

  const handleFileSelect = async (e) => {
    let file = e.target.files[0];
    if (file) {
      try {
        imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          maxIteration: 20,
        })
          .then((compressedFile) => {
            const reader = new FileReader();
            reader.onload = () => {
              setCompanyImage(reader.result);
            };
            reader.readAsDataURL(compressedFile);
          })
          .catch((err) => console.log('err while compressing', err));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFileUpload = async (data) => {
    fetch(data)
      .then((res) => res.blob())
      .then(async (file) => {
        setCompanyImage(null);
        setImageUpload(true);
        const payload = {
          uuid: user?.uuid,
          file_name: 'companyLogo.png',
          type: 'company_logo',
        };
        const res = await getUploadUrl(payload);
        const { filename, url: uploadUrl } = res?.data?.data || '';
        if (uploadUrl) {
          const uploadFileResponse = await fetch(uploadUrl, {
            method: 'PUT',
            body: file,
          });
          if (uploadFileResponse.status === 200) {
            await profileUpdateMutate({ company_logo: filename });
            setImageUpload(false);
          }
        }
        setImageUpload(false);
      })
      .catch((err) => {
        console.log({ err });
        setImageUpload(false);
      });
  };

  return (
    <div className="w--full h--full supoortPage">
      <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
        Company Info
      </h4>
      <div className="d--flex gap--md flex--column profilePage bg--white p--md radius--sm h--full overflow--auto box-shadow--xs w--full ">
        <div className="w--full">
          <div className="label--control font--sm font--500 d--flex gap--sm align-items--center m-b--xs  h-min--36 text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm   m-b--lg">
            Company Details
            <div className="d--flex">
              {(isImageUploading || profileUpdateLoad) && <Spinner />}
            </div>
          </div>
          <div className="w--full d--flex justify-content--center">
            <div className="w-max--600 w--full">
              <form
                className="w--full d--flex flex--column justify-content--center align-items--center gap--lg"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div className="d--flex gap--lg w--full">
                  <div className="w--full w-max--120 d--flex  justify-content--center radius--sm">
                    <Avatar
                      image={user?.company_logo}
                      first_name={user?.tenant_company}
                      shouldShowPresence={false}
                      size="120"
                      type="company_logo"
                    />
                  </div>
                  <div className="w--full d--flex flex--column gap--lg">
                    <div className="d--flex flex--column gap--md">
                      <div className="d--flex w--full position--relative">
                        <div className="w--full  d--flex flex--column ">
                          <FormInput
                            type="file"
                            accept="image/*"
                            id="filename"
                            name="filename"
                            label={
                              isImageUploading ? 'Please wait' : 'Company logo'
                            }
                            onChange={(e) => {
                              handleFileSelect(e);
                              e.target.value = '';
                            }}
                          />
                        </div>
                      </div>
                      <div className="w--full">
                        <Controller
                          name="tenant_company"
                          control={control}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              type="text"
                              label={'Company Name'}
                              placeholder={'Enter company name'}
                              error={errors?.tenant_company?.message}
                              maxLength={30}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="d--flex flex--column gap--md">
                      <Controller
                        name="tenant_fein"
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            type="text"
                            label="FEIN"
                            placeholder="Enter fein"
                            maxLength="12"
                            error={errors?.tenant_fein?.message}
                          />
                        )}
                      />
                      <Controller
                        name="tenant_npn"
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            type="text"
                            label="NPN"
                            placeholder="Enter npn"
                            maxLength="12"
                            error={errors?.tenant_npn?.message}
                          />
                        )}
                      />
                    </div>
                    <div className="w--full d--flex align-items--center justify-content--center m-t--md">
                      <button
                        type="submit"
                        className="btn font--sm d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary w-max--200 c--pointer"
                        disabled={isSubmitting || profileUpdateLoad}
                      >
                        {isSubmitting || profileUpdateLoad
                          ? 'Please wait..'
                          : 'Update Company'}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <ImageCropModal
                {...{
                  image: companyImage,
                  setImage: setCompanyImage,
                  setImageUpload,
                  upload: (data) => handleFileUpload(data),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
