import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import FormSelect from '../../../comopnents/FormSelect';
import FormInput from '../../../comopnents/FormInput';
import Button from '../../../comopnents/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AGENCY_OWNER, USER } from '../../../helpers/functions';
import { useAuth } from '../../../hooks/useAuth';
import useUploadMedia from '../../../hooks/useUploadMedia';
import useSupportQuery from '../../../hooks/useSupportQuery';

const Issues = [
  {
    id: 1,
    label: 'My Account',
    value: 'My Account',
  },
  {
    id: 2,
    label: 'Payment',
    value: 'Payment',
  },
  {
    id: 3,
    label: 'Virtual Number',
    value: 'Virtual Number',
  },
  {
    id: 4,
    label: 'Call',
    value: 'Call',
  },
];

const priorityOptions = [
  { label: 'High', value: 'H' },
  { label: 'Medium', value: 'M' },
  { label: 'Low', value: 'L' },
];

const RenderItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.value}
      value={item.value}
    >
      {item.label}
    </option>
  );
};

const validationSchema = yup.object().shape({
  issue_type: yup.string().required(`Issue type is required`),
  description: yup.string().required(`Description is required`),
});

function Support() {
  const { user } = useAuth();
  const { mutateAsync: getUploadUrl, isLoading: isLoadinguseUploadMedia } =
    useUploadMedia();

  const { mutateAsync: mutateSupport, isLoading: isLoadinguseSupportQuery } =
    useSupportQuery();

  const [isLoading, setIsLoading] = useState(false);

  const load = isLoadinguseUploadMedia || isLoadinguseSupportQuery || isLoading;

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      issue_type: '',
      attachment_file: null,
      description: '',
      priority: 'M',
    },
    mode: 'onChange',
  });

  const handleFileUpload = async (data) => {
    return new Promise(async (resolve, reject) => {
      const payload = {
        uuid: user?.parent_uuid || user?.uuid,
        file_name: data?.name,
        type: 'support',
      };
      try {
        const res = await getUploadUrl(payload);
        if (res?.data?.data?.url) {
          const uploadFileResponse = await fetch(res?.data?.data?.url, {
            method: 'PUT',
            body: data,
          });
          if (uploadFileResponse?.status === 200) {
            resolve(res?.data?.data?.filename);
          } else {
            resolve('');
          }
        }
      } catch (error) {
        console.log('🚀 ~ returnnewPromise ~ error:', error);
        resolve('');
      }
    });
  };

  const onSubmit = async (values) => {
    if (values?.attachment_file) {
      setIsLoading(true);
      const filename = await handleFileUpload(values?.attachment_file);
      await mutateSupport({ ...values, attachment_file: filename });
      setIsLoading(false);
      reset();
    } else {
      await mutateSupport(values);
      reset();
    }
  };

  return (
    <div className="w--full h--full supoortPage">
      <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
        Support
      </h4>
      <div className="d--flex gap--md flex--column profilePage bg--white p--md radius--sm h--full overflow--auto box-shadow--xs">
        <div className="w--full d--flex gap--md flex--column">
          <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
            Support Details
          </div>
        </div>
        <div className="d--flex justify-content--center">
          <div className="d--flex flex--column gap--xl w-max--600">
            <div className="d--flex flex--column gap--xl ">
              <Controller
                name="issue_type"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    options={Issues || []}
                    renderOption={RenderItem}
                    placeholder="Select issue"
                    label="Choose issue type"
                    error={errors?.issue_type?.message}
                  />
                )}
              />
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    options={priorityOptions || []}
                    renderOption={RenderItem}
                    placeholder="Select priority"
                    label="Select priority"
                    error={errors?.priority?.message}
                  />
                )}
              />
              <FormInput
                type="file"
                accept="image/jpg,image/jpeg,image/png,application/pdf"
                id={`attachment_file`}
                label={'Attachment File'}
                name={`attachment_file`}
                onChange={(e) => {
                  setValue(`attachment_file`, e.target.files[0], {
                    shouldValidate: true,
                  });
                }}
                error={errors?.attachment_file?.message}
              />
            </div>
            <div className="w--full">
              <label
                className={`label--control font--sm font--500 m-b--xs d--flex  ${
                  errors?.description?.message ? 'text--danger' : 'text--black'
                }`}
              >
                {errors?.description?.message ?? 'Add Detailed Description'}
              </label>
              <textarea
                className="form--control w--full h-min--100  radius--sm p-l--md p-r--md p-t--sm p-b--sm  border-full--black-200"
                value={watch('description')}
                onChange={(e) => {
                  setValue('description', e.target.value, {
                    shouldValidate: true,
                  });
                }}
                placeholder="Any questions or remarks? Just write us a message!"
                name="description"
                rows="5"
                cols="150"
              ></textarea>
            </div>

            <div className="w--full align-items--center d--flex justify-content--center">
              <Button
                type="button"
                btnClasses="btn w-max--200"
                variant="primary"
                color="white"
                disable={load}
                onClick={handleSubmit(onSubmit)}
              >
                {load ? 'Please wait..' : 'Submit'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
