import React, { useContext, useEffect, useRef, useState } from 'react';
import TableManager from '../../../comopnents/TableManager';
import { particularCallHistory, reportsCallList } from '../../../api';
import {
  AGENT,
  EMPLOYEE,
  SecondsTohhmmss,
  USER,
  converDateTimeFormateApis,
  durationToMMSS,
  formatPhoneNumber,
} from '../../../helpers/functions';
import useIcons from '../../../assets/icons/useIcons';
import AudioPlayer from '../../../comopnents/AudioPlayer';
import useAllNumberDetails from '../../../hooks/useAllNumberDetails';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import useMemberAgentsAllListing from '../../../hooks/useMemberAgentsAllListing';
import useIVRList from '../../../hooks/useIVRList';
import useRingGroupList from '../../../hooks/useRingGroupList';
import { useAuth } from '../../../hooks/useAuth';
import useNameNumberFilterListing from '../../../hooks/useNameNumberFilterListing';
import AsideModal from '../../../comopnents/AsideModal';
import Transcripts2 from '../../../comopnents/Transcripts2';
import { useNavigate } from 'react-router-dom';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import CustomToolTip from '../../../comopnents/CustomToolTip';
import Avatar from '../../../comopnents/Avatar';
import useCallRecordingSeen from '../../../hooks/useCallRecordingSeen';
import { capitalizeWords } from '../../ActivityManagement/TimeLine/Activity';
import useContactListing from '../../../hooks/useContactListing';
import useDidList from '../../../hooks/useDidList';
import CallScreenshotPreview from '../../../comopnents/CallScreenshotPreview';

const SeparatorItem = ({ list = [] }) => {
  const result =
    list?.map((option) => {
      return (
        <div
          key={option?.uuid}
          className="d--flex align-items--center gap--sm "
        >
          <Avatar
            first_name={option?.name?.split(' ')?.[0] || ''}
            last_name={option?.name?.split(' ')?.[1] || ''}
            inGroup={true}
            size="20"
            extension={option?.extension}
          />
          {option?.name}
        </div>
      );
    }) ?? null;

  return (
    <div>
      <div className="d--flex flex--column gap--sm h-max--350">{result}</div>
    </div>
  );
};

const Calls = () => {
  const { socket = {} } = useContext(SipSocketContext);
  const { _makeCall = () => null } = socket;
  const [callStats, setCallStats] = useState({});
  const [screenShotPreviewData, setScreenshotPreviewData] = useState(null);

  const [searchParams] = useSearchParams();
  const tableRef = useRef(null);
  const { getNumberName, numberList } = useAllNumberDetails();
  const { nameNumberList } = useNameNumberFilterListing();
  const { dropdownContactList } = useContactListing();

  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
  });
  const { mutate: callRecordingMutateSeen } = useCallRecordingSeen();
  const [showTranscript, setShowTranscript] = useState(false);
  const [transcriptData, setTranscriptData] = useState(null);

  const [callFilter, setCallFilter] = useState(
    searchParams.get('callType') ?? 'total',
  );
  const [isEnabled, setIsEnabled] = useState(
    searchParams.get('callType') ? false : true,
  );
  const [memberListAll, setMemberListALL] = useState([]);
  const { user } = useAuth();
  const {
    MissedCall,
    OutgoingCall,
    IncomingCall,
    PlayIcon,
    HoldIcon,
    VoicemailIcon,
    TranscriptsIcon,
    UsersIcon,
    MobileIcon,
    MoreVIcon,
    MessageIcon,
    CallIcon,
    VoicemailGroupIcon,
  } = useIcons();
  const navigate = useNavigate();

  const { mutate: didListMutate, data: didListData } = useDidList();

  const { mutateAsync: mutateAsyncALL, data: dataALL } =
    useMemberAgentsAllListing();
  const { mutateAsync: ivrListMutate, data: ivrListData } = useIVRList();
  const { mutate: ringGroupListMutate, data: ringGroupListData } =
    useRingGroupList();
  useEffect(() => {
    if (!dataALL?.data) {
      mutateAsyncALL({ get_owner: 'Y', page: 1 });
    } else {
      setMemberListALL(dataALL?.data?.data ?? []);
    }
  }, [dataALL]);
  useEffect(() => {
    ivrListMutate({ page: 1 });
    ringGroupListMutate({ page: 1 });
    didListMutate({ page: 1 });
  }, []);

  const callIconLookup = {
    Missed: (
      <span className="text--danger">
        <MissedCall width={16} height={16} />
      </span>
    ),
    Outbound: (
      <span className="text--success">
        <OutgoingCall width={16} height={16} />
      </span>
    ),
    Inbound: (
      <span className="text--primary">
        <IncomingCall width={16} height={16} />
      </span>
    ),
  };

  const {
    total_calls = 0,
    inbound_calls = 0,
    total_duration = 0,
    outbound_calls,
    missed_calls = 0,
    voicemail = 0,
  } = callStats || {};

  const getStatusItem = (status = '') => {
    switch (status) {
      case 'SUCCESS':
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className="text--success"
          >
            {status?.toLowerCase()}
          </span>
        );
      case 'CANCEL':
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className="text--danger"
          >
            {status?.toLowerCase()}
          </span>
        );

      default:
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className="text--secondary white--space-none"
          >
            {status?.replaceAll('_', ' ')?.toLowerCase()}
          </span>
        );
    }
  };

  function handleMembers(members = '') {
    if (!members) return '';
    const extensions = members?.split(',');
    const names = [];
    for (let index = 0; index < extensions.length; index++) {
      const element = extensions[index];
      if (user?.extension === element) {
        names.unshift('You');
      } else {
        names.push(getNumberName(element, false));
      }
    }
    return ` (${names.join(',')})`;
  }

  const columns = [
    {
      accessorKey: 'start_stamp',
      header: () => 'Date/Time',
      cell: (props) => {
        const element = props?.row?.original;
        const direction = element?.direction;
        const { status = '', forward_type = '' } = element || {};
        return (
          <div className="d--flex align-items--center  justify-content--start gap--sm">
            {['VOICEMAIL'].includes(status) ? (
              <>
                {forward_type === 'VOICEMAIL' && (
                  <VoicemailIcon width={20} height={20} />
                )}
                {forward_type === 'VOICEMAILGROUP' && (
                  <VoicemailGroupIcon width={20} height={20} />
                )}
              </>
            ) : (
              <div
                className={`${
                  ['EXTENSION', 'DEPARTMENT', 'IVR'].includes(forward_type) &&
                  direction === 'Missed'
                    ? 'c--pointer'
                    : ''
                }`}
                onClick={() => {
                  if (
                    ['EXTENSION', 'DEPARTMENT', 'IVR'].includes(forward_type) &&
                    direction === 'Missed'
                  ) {
                    setScreenshotPreviewData(element);
                  }
                }}
              >
                {callIconLookup?.[direction] ?? ''}
              </div>
            )}
            {converDateTimeFormateApis(props?.row?.original?.start_stamp)}
          </div>
        );
      },
      meta: {
        width: '20%',
      },
    },
    {
      accessorKey: 'caller_id_number',
      header: () => 'From',
      cell: (props) => {
        return (
          <div className="d--flex flex--column">
            <div>
              {getNumberName(props?.getValue(), false)?.includes('+')
                ? formatPhoneNumber(props?.getValue())
                : getNumberName(props?.getValue(), false)}
            </div>
            {props?.getValue()?.length < 5 && (
              <div className="font--xs">{`Ext. ${props?.getValue()}`}</div>
            )}
          </div>
        );
      },
      meta: {
        width: '20%',
      },
    },
    {
      accessorKey: 'via_did',
      header: () => 'Via Virtual Number',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <>
            <div className="d--flex flex--column justify-content--start align-items--center">
              {element?.did_name && <div>{element?.did_name}</div>}
              <div>{formatPhoneNumber(props?.getValue())}&nbsp;</div>
            </div>
          </>
        );
      },
      meta: {
        width: '12%',
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'accountcode',
      header: () => 'To',
      cell: (props) => {
        const element = props?.row?.original;
        let members = [];
        if (element?.forward_type && element?.members) {
          members = element?.members?.split(',')?.map((item) => {
            return {
              name: getNumberName(item, false),
              extension: item,
            };
          });
        }
        let departmentName = '';
        if (
          element?.forward_type === 'DEPARTMENT' ||
          element?.forward_type === 'VOICEMAILGROUP'
        ) {
          departmentName =
            ringGroupListData?.data?.data?.find(
              (item) => item?.uuid === element?.forward_value,
            )?.title || 'Unknown';
        }
        let ivrName = '';
        if (element?.forward_type === 'IVR') {
          ivrName =
            ivrListData?.data?.data?.find(
              (item) => item?.uuid === element?.forward_value,
            )?.name || 'Unknown';
        }
        return (
          <>
            {element?.is_voicemail === 1 ? (
              <span className="d--flex align-items--center  justify-content--start gap--sm">
                <span>
                  {element?.forward_type === 'VOICEMAILGROUP' ? (
                    <VoicemailGroupIcon width={20} height={20} />
                  ) : (
                    <VoicemailIcon width={20} height={20} />
                  )}
                </span>
                <div>
                  {element?.forward_type === 'VOICEMAILGROUP' ? (
                    element?.forward_name ? (
                      <CustomToolTip
                        id={props?.row?.id}
                        options={members}
                        accessorKey="name"
                        separatorComponent={SeparatorItem}
                        controlled={true}
                        enableTooltip={members?.length > 0}
                      >
                        <span className="d--flex align-items--center gap--xs">
                          <span>{element?.forward_name}</span>
                        </span>
                      </CustomToolTip>
                    ) : (
                      'Department Voicemail'
                    )
                  ) : element?.forward_type === 'VOICEMAIL' ? (
                    element?.forward_name ? (
                      <span className="d--flex align-items--center gap--xs">
                        <span>
                          {element?.forward_name}{' '}
                          <div className="font--xs">{`Ext. ${element?.forward_value}`}</div>
                        </span>
                      </span>
                    ) : (
                      'Voicemail'
                    )
                  ) : (
                    capitalizeWords(element?.forward_type?.toLowerCase(), ' ')
                  )}
                  {/* {element?.forward_type === 'DEPARTMENT' ||
                  element?.forward_type === 'VOICEMAILGROUP' ? (
                    <div className="font--xs">{departmentName}</div>
                  ) : null} */}
                </div>
              </span>
            ) : (
              <>
                {element?.destination_number ? (
                  <>
                    <span
                      className="d--flex align-items--center  justify-content--start gap--sm"
                      style={{ textTransform: 'capitalize' }}
                    >
                      <div className="d--flex flex--column">
                        <div>
                          {getNumberName(element?.destination_number, false)}
                        </div>
                        {element?.destination_number?.length < 5 && (
                          <div className="font--xs">{`Ext. ${element?.destination_number}`}</div>
                        )}
                        {element?.destination_number?.length > 5 &&
                          !getNumberName(
                            element?.destination_number,
                            false,
                          )?.includes('+') && (
                            <div className="font--xs">{`Ph. ${formatPhoneNumber(
                              element?.destination_number,
                            )}`}</div>
                          )}
                      </div>
                    </span>
                  </>
                ) : (
                  <span className="d--flex">
                    {element?.forward_type === 'EXTENSION' ? (
                      <>
                        <div className="d--flex flex--column">
                          <div>
                            {getNumberName(element?.forward_value, false)}
                          </div>
                          {element?.forward_value?.length < 5 && (
                            <div className="font--xs">{`Ext. ${element?.forward_value}`}</div>
                          )}
                          {element?.forward_value?.length > 5 &&
                            !getNumberName(
                              element?.forward_value,
                              false,
                            )?.includes('+') && (
                              <div className="font--xs">{`Ph. ${formatPhoneNumber(
                                element?.forward_value,
                              )}`}</div>
                            )}
                        </div>

                        {/* Ext.
                      <span style={{ textTransform: 'capitalize' }}>
                        {getNumberName(element?.forward_value, false)}&nbsp;(
                        {element?.forward_value})
                      </span> */}
                      </>
                    ) : element?.forward_type === 'DEPARTMENT' ||
                      element?.forward_type === 'VOICEMAILGROUP' ? (
                      <CustomToolTip
                        id={props?.row?.id}
                        options={members}
                        accessorKey="name"
                        separatorComponent={SeparatorItem}
                        controlled={true}
                        enableTooltip={members?.length > 0}
                      >
                        <span className="d--flex align-items--center gap--xs">
                          <UsersIcon />
                          <span>{departmentName}</span>
                        </span>
                      </CustomToolTip>
                    ) : element?.forward_type === 'IVR' ? (
                      <span className="d--flex align-items--center gap--xs">
                        <MobileIcon />
                        <span>{ivrName}</span>
                      </span>
                    ) : (
                      <>
                        {element?.forward_type}
                        {handleMembers(element?.members)}
                      </>
                    )}

                    {/* <CustomToolTip
                    id={props?.row?.id}
                    options={members}
                    accessorKey="name"
                    separatorComponent={SeparatorItem}
                    controlled={true}
                    enableTooltip={members?.length > 0}
                  >
                    {element?.forward_type}
                  </CustomToolTip> */}
                    {/* {element?.forward_type}
                  {element?.forward_type === 'EXTENSION'
                    ? ` (${element?.forward_value})`
                    : ''} */}
                  </span>
                )}
              </>
            )}
          </>
        );
      },
      meta: {
        width: '20%',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        return <>{getStatusItem(props?.getValue())}</>;
      },
      meta: {
        width: '5%',
      },
    },
    {
      accessorKey: 'duration',
      header: () => 'Duration',
      cell: (props) => durationToMMSS(props.getValue()),
      meta: {
        textAlign: 'center',
        width: '5%',
      },
    },
    {
      accessorKey: 'charge',
      header: () => 'Charge',
      meta: {
        textAlign: 'center',
        width: '5%',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { recording_file = '', is_voicemail, direction = '' } = element;
        const isPlaying =
          playingData?.playing && playingData?.file === recording_file;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--end">
            {recording_file && (
              <>
                {isPlaying ? (
                  <span
                    className="c--pointer"
                    onClick={() => {
                      setPlayingData({ playing: false, file: null });
                    }}
                  >
                    <HoldIcon width={20} height={20} />
                    <AudioPlayer
                      onClose={() => {
                        setPlayingData({ playing: false, file: null });
                      }}
                      name={recording_file}
                    />
                  </span>
                ) : (
                  <span
                    className={`c--pointer ${
                      element?.seen_at ? 'text--success' : 'text--red'
                    } `}
                    onClick={() => {
                      if (!element?.seen_at) {
                        callRecordingMutateSeen({
                          uuid: element?.xml_cdr_uuid,
                        });
                      }
                      setPlayingData({ playing: true, file: recording_file });
                    }}
                  >
                    <span
                      data-tooltip={
                        is_voicemail === 1 ? 'Voicemail' : 'Recording'
                      }
                      tooltip-position="left"
                    >
                      <PlayIcon width={20} height={20} />
                    </span>
                  </span>
                )}
                {/* <div
                data-tooltip={'Transcripts'}
                tooltip-position="left"
                onClick={() => handleSetTranscript(element)}
              >
                <TranscriptsIcon />
              </div> */}
              </>
            )}

            <div
              className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--success-200 text--success c--pointer"
              onClick={() =>
                handleMakeCall(
                  direction === 'Outbound'
                    ? element?.destination_number
                    : `${element?.caller_id_number}`,
                )
              }
            >
              <CallIcon width={15} height={15} />
            </div>
            {[USER, EMPLOYEE].includes(user?.role_id) &&
              ['Inbound', 'Missed'].includes(direction) && (
                <div
                  className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm text--info bg--info-100 c--pointer"
                  onClick={() => {
                    navigate(
                      `/messages?didId=${element?.via_did?.replace(
                        '+',
                        '',
                      )}&sendSms=${element?.caller_id_number?.replace(
                        '+',
                        '',
                      )}`,
                    );
                  }}
                >
                  <MessageIcon width={15} height={15} />
                </div>
              )}
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];

  const filterInitialValues = {
    filter: [
      {
        key: 'direction',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Select',
        inputType: 'select',
        inputLabel: 'Call Direction',
        inputOptions: [
          {
            value: 'All',
            label: 'All',
          },
          {
            value: 'Inbound',
            label: 'Incoming',
          },
          {
            value: 'Outbound',
            label: 'Outbound',
          },
          {
            value: 'Missed',
            label: 'Missed',
          },
        ],
        isDisabled: false,
        enableCrossDisabling: true,
        crossDisablingFunction: ({
          valueToUpdate = {},
          triggerDisable = () => null,
          triggerEnable = () => null,
          resetDisable = () => null,
          customValueUpdate = () => null,
        }) => {
          const { value } = valueToUpdate;
          if (value === 'Inbound') {
            triggerEnable({ key: 'department' });
            triggerEnable({ key: 'ivr' });
            customValueUpdate({
              targetKey: 'extension',
              key: 'inputLabel',
              value: 'Extension',
            });
          }
          if (value === 'Outbound') {
            triggerDisable({ key: 'department' });
            triggerDisable({ key: 'ivr' });
            customValueUpdate({
              targetKey: 'extension',
              key: 'inputLabel',
              value: 'User',
            });
          }
          if (value === 'All') {
            resetDisable();
            customValueUpdate({
              targetKey: 'extension',
              key: 'inputLabel',
              value: 'Extension',
            });
          }
        },
      },
      {
        key: 'name',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Name/Number',
        inputType: 'custom-selects-name-number',
        inputLabel: 'Name/Number',
        inputOptions: dropdownContactList,
        isDisabled: false,
      },
      {
        key: 'extension',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Extension Number',
        inputType: 'multi-select',
        inputLabel: 'Extension',
        inputOptions: memberListAll.map((item) => {
          return {
            label: `${item?.first_name} ${item?.last_name}`,
            value: item?.extension,
            first_name: item?.first_name,
            last_name: item?.last_name,
            profile_pic: item?.profile_pic,
            extension: item?.extension,
          };
        }),
        isDisabled: false,
      },
      {
        key: 'did_number',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter virtual number',
        inputType: 'select',
        inputLabel: 'Virtual Number',
        inputOptions: didListData?.data?.data?.map((item) => {
          return {
            label: item?.did_number,
            value: item?.did_number,
          };
        }),
      },
      {
        key: 'department',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Department',
        inputType: 'select',
        inputLabel: 'Department',
        inputOptions: ringGroupListData?.data?.data?.map((item) => {
          return {
            label: item?.title,
            value: item?.uuid,
          };
        }),
        isDisabled: false,
      },

      {
        key: 'ivr',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter IVR',
        inputType: 'select',
        inputLabel: 'IVR',
        inputOptions: ivrListData?.data?.data?.map((item) => {
          return {
            label: item?.name,
            value: item?.uuid,
          };
        }),
        access: ![AGENT, EMPLOYEE].includes(user?.role_id),
        isDisabled: false,
      },
      {
        key: 'status',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Select',
        inputType: 'multi-select-normal',
        inputLabel: 'Status',
        inputOptions: [
          {
            value: 'SUCCESS',
            label: 'Success',
          },
          {
            value: 'CANCEL',
            label: 'Cancel',
          },
          {
            value: 'FAILURE',
            label: 'Failure',
          },
          {
            value: 'VOICEMAIL',
            label: 'Voicemail',
          },
          {
            value: 'UNAVAILABLE ',
            label: 'Unavailable',
          },
          {
            value: 'BUSY',
            label: 'Busy',
          },
          {
            value: 'NO ANSWER',
            label: 'No Answer',
          },
        ],
      },
      {
        key: 'duration',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Duration',
        inputType: 'select',
        inputLabel: 'Duration',
        inputOptions: [
          {
            value: '0-2',
            label: '0-2',
          },
          {
            value: '2-5',
            label: '2-5',
          },
          {
            value: '5-10',
            label: '5-10',
          },
          {
            value: '10-50',
            label: '10-50',
          },
          {
            value: '50',
            label: '50',
          },
        ],
        isDisabled: false,
      },
      // {
      //   key: 'date',
      //   inputLabel: 'By Date',
      //   isChecked: false,
      //   value:
      //     searchParams.get('filter') === 'weekly'
      //       ? weeklyFilterObject
      //       : {
      //           form: '',
      //           to: '',
      //         },
      //   inputType: 'date',
      //   dateType: searchParams.get('filter') === 'weekly' ? 'This Week' : '',
      //   isDisabled: false,
      // },
    ],
  };

  useEffect(() => {
    if (searchParams.has('callType')) {
      tableRef.current.overrideFilters(filterInitialValues?.filter);
      setIsEnabled(true);
    }
  }, [filterInitialValues?.filter, searchParams]);

  const handleClose = () => {
    setTranscriptData(null);
    setShowTranscript(false);
  };

  const handleSetTranscript = (data) => {
    setTranscriptData(data);
    setShowTranscript(true);
  };

  function handleDropListClick({ value }, data) {
    switch (value) {
      case 'call':
        handleMakeCall(data?.caller_id_number);
        break;
      case 'send_sms':
        navigate(
          `/messages?didId=${data?.via_did?.replace(
            '+',
            '',
          )}&sendSms=${data?.caller_id_number?.replace('+', '')}`,
        );
        // agencyActivate({ uuid: data?.uuid });
        break;

      default:
        break;
    }
  }

  function handleMakeCall(phoneNumber) {
    if (phoneNumber) {
      _makeCall(phoneNumber, phoneNumber?.replaceAll(' ', ''));
    }
  }

  return (
    <div className="overviewPage d--flex flex--column gap--sm w--full">
      <div className="w--full d--flex flex--column">
        <div className=" m-b--xs h-max--40 h-min--36  d--flex justify-content--between align-items--center text--primary">
          <div className="font--md font--400">Calls</div>
        </div>
        <div className="d--flex  gap--md w--full">
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--60 c--pointer ${
              callFilter === 'total' ? 'bg--secondary' : 'bg--white'
            } `}
            onClick={() => setCallFilter('total')}
          >
            <div
              className={` font--2xl font--600 ${
                callFilter === 'total' ? 'text--white' : ''
              } `}
            >
              {total_calls}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center  ${
                callFilter === 'total' ? 'text--white' : 'text--primary'
              } `}
            >
              Total Calls
            </div>
          </div>
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--60 c--pointer ${
              callFilter === 'outbound' ? 'bg--secondary' : 'bg--white'
            } `}
            onClick={() => setCallFilter('outbound')}
          >
            <div
              className={` font--2xl font--600 ${
                callFilter === 'outbound' ? 'text--white' : 'text--black'
              } `}
            >
              {outbound_calls ?? 0}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center  ${
                callFilter === 'outbound' ? 'text--white' : 'text--primary'
              } `}
            >
              Outgoing Calls
            </div>
          </div>
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--60 c--pointer ${
              callFilter === 'inbound' ? 'bg--secondary' : 'bg--white'
            } `}
            onClick={() => setCallFilter('inbound')}
          >
            <div
              className={` font--2xl font--600 ${
                callFilter === 'inbound' ? 'text--white' : 'text--black'
              } `}
            >
              {inbound_calls}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center   ${
                callFilter === 'inbound' ? 'text--white' : 'text--primary'
              } `}
            >
              Answered Calls
            </div>
          </div>
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--60 c--pointer ${
              callFilter === 'missed' ? 'bg--secondary' : 'bg--white'
            } `}
            onClick={() => setCallFilter('missed')}
          >
            <div
              className={` font--2xl font--600 ${
                callFilter === 'missed' ? 'text--white' : 'text--black'
              } `}
            >
              {missed_calls}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center  ${
                callFilter === 'missed' ? 'text--white' : 'text--primary'
              } `}
            >
              Missed Calls
            </div>
          </div>
          <div
            data-link-hover-secondary
            className={`w--full radius--sm d--flex flex--column align-items--center justify-content--center p--md gap--sm h-min--60 c--pointer ${
              callFilter === 'voicemail' ? 'bg--secondary' : 'bg--white'
            } `}
            onClick={() => setCallFilter('voicemail')}
          >
            <div
              className={` font--2xl font--600 ${
                callFilter === 'voicemail' ? 'text--white' : 'text--black'
              } `}
            >
              {voicemail}
            </div>
            <div
              className={`font--sm font--500 d--flex align-items--center gap--sm justify-content--center  ${
                callFilter === 'voicemail' ? 'text--white' : 'text--primary'
              } `}
            >
              Voicemails
            </div>
          </div>
        </div>
      </div>
      <div className="h--full w--full reportPageTable m-t--lg">
        <TableManager
          {...{
            fetcherKey: 'reportsCallList',
            fetcherFn: reportsCallList,
            columns,
            shouldFilter: true,
            initialFilterState: filterInitialValues,
            setApiResponse: ({ data }) => {
              setCallStats(data?.data?.call_stats);
            },
            selectCBForTableData: (tblData) => tblData?.data?.data?.data,
            extraParams: {
              type: callFilter,
            },
            isEnabled,
            ref: tableRef,
            showMoreOption: (data) => {
              return data?.count > 1;
            },
            enableMoreOption: true,
            moreDataFunction: particularCallHistory,
            moreDataFnPayload: (data) => {
              return data?.xml_cdr_uuid;
            },
            customFilters: true,
          }}
        />
      </div>
      {showTranscript && (
        <AsideModal
          handleClose={handleClose}
          title={'Call Intelligence'}
          footerComponent={null}
          width="md"
        >
          <Transcripts2
            transcript_file_url={transcriptData?.transcript_file_url}
            recording_file_url={`${process.env.REACT_APP_MEDIA_URL}signed-url/${
              user?.parent_uuid || user?.uuid
            }/recording?filename=${transcriptData?.recording_file}`}
          />
          {/* <Transcripts
              transcript_file_url={transcriptData?.transcript_file_url}
            /> */}
        </AsideModal>
      )}
      {screenShotPreviewData && (
        <CallScreenshotPreview
          handleCloseModal={() => setScreenshotPreviewData(null)}
          callData={screenShotPreviewData}
        />
      )}
    </div>
  );
};

export default Calls;

const weeklyFilterObject = {
  from: moment().startOf('week').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
};
