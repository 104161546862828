import React from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

const FlagWithCountryCode = ({ phoneNumber, className }) => {
  if (!phoneNumber || phoneNumber?.length < 6) return <></>;
  const number = parsePhoneNumber(
    phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`,
  );
  if (!number || !number?.country) return <></>;
  return (
    <span className={className}>
      {flags[number.country]({ title: number.country })}
    </span>
  );
};
export default FlagWithCountryCode;
