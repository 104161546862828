import React, { useEffect } from 'react';
import useIcons from '../../../assets/icons/useIcons';
import FormInput from '../../../comopnents/FormInput';
import Button from '../../../comopnents/Button';
import contactBanner from '../../../assets/images/web/contactbanner.png';

const ContactUs = () => {
  const {
    LocationFillIcon,
    Curve2Icon,
    DotsMoreIcon,
    MessageIcon,
    CallIcon,
    FaxIcon,
  } = useIcons();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contactPage w--full ">
      <div className="w--full section contactSec p-t--80 overflow--hidden">
        <div className="container">
          <div className="w--full d--flex gap--2xl align-items--center  flex-column--xs ">
            <div className="w--full d--flex flex--column gap--lg contentText position--relative">
              <div className="circleBox position--absolute">
                <div className="text--success position--absolute animate mover">
                  <div className="border-full--success w-min--50 h-min--50 w-max--50 radius--full position--absolute mover animate"></div>
                </div>
                <div className="bg--success-100 w-min--50 h-min--50 w-max--50 radius--full position--absolute bounce--right animate"></div>
              </div>
              {/* <h4 className="font--2xl font--600 text--secondary">
                Contact Us
              </h4> */}
              <h2 className="font--52 font--700 line-height--1-dot-2">
                Contact Us
              </h2>
              <p className="font--md text--grey line-height--2">
                Our professionals and dedicated team at Nextere Voice are always
                ready to resolve customer queries and to get in touch with them.
                Contact us for more customized solutions and better customer
                communication strategies. We looking forward to get in
                collaborate with you!
              </p>
            </div>
            <div className="w--full d--flex justify-content--end imgSec position--relative z-index--2 ">
              <div className="dotsIcon  animate mover text--success position--absolute z-index---1">
                <DotsMoreIcon width={66} height={100} />
              </div>
              <img src={contactBanner} alt="bannerImg" className="w--full " />
              <div className="curve2Icon  animate mover text--success position--absolute z-index---1">
                <Curve2Icon width={66} height={166} />
              </div>
            </div>
          </div>
        </div>
        <div className="bigCircle position--absolute z-index---0">
          <div className="bg--primary-100 w-min--200 h-min--200 w-max--200 radius--full position--absolute mover animate"></div>
        </div>
      </div>
      <div className="w--full section contactSec ">
        <div className="container">
          <div className=" w--full d--flex  justify-content--end   h--full">
            <div className="contactBox  w--full d--flex  box-shadow--lg p--3xl radius--sm flex-column--xs position--relative">
              <div className="w--full w-max--350 bg--primary p--lg text--white d--flex flex--column gap--xl contactAddress position--absolute">
                <h3 className="font--2xl font--600 line-height--1-dot-2 m-b--md ">
                  Contact Us
                </h3>
                <div className="d--flex flex--column gap--sm">
                  <div className="d--flex align-items--center gap--md font--md font--600">
                    <LocationFillIcon />
                    Address
                  </div>
                  <div className="font--sm line-height--1-dot-5 p-l--2xl">
                    Ernesto Pena <br />
                    1 Ludlam Dr. Miami Spring Florida, 33166 <br />
                    USA <br />
                  </div>
                </div>
                <div className="d--flex flex--column gap--sm">
                  <div className="d--flex align-items--center gap--md font--md font--600">
                    <MessageIcon />
                    Email Address
                  </div>
                  <div className="font--md line-height--1-dot-5 p-l--2xl">
                    contact@nextere.com
                  </div>
                </div>
                <div className="d--flex flex--column gap--sm">
                  <div className="d--flex align-items--center gap--md font--md font--600">
                    <CallIcon />
                    Phone Number
                  </div>
                  <div className="font--md line-height--1-dot-5 p-l--2xl">
                    +1 985 259 5684
                  </div>
                </div>
                {/* <div className="d--flex flex--column gap--sm">
                  <div className="d--flex align-items--center gap--md font--md font--600">
                    <FaxIcon />
                    Fax Number
                  </div>
                  <div className="font--md line-height--1-dot-5">
                    +91- 123456789
                  </div>
                </div> */}
              </div>
              <div className="w--full d--flex flex--column gap--xl contactFormSec ">
                <div className="w--full">
                  <p className="font--xl text--grey line-height--2">
                    Have you any query?{' '}
                  </p>
                  <h3 className="font--42 font--700 line-height--1-dot-2 m-b--xs">
                    Just write down query
                  </h3>
                </div>
                <div className="d--flex gap--lg w--full flex-column--xs">
                  <div className="w--full">
                    <FormInput placeholder="First Name" height="50" />
                  </div>
                  <div className="w--full">
                    <FormInput placeholder="First Name" height="50" />
                  </div>
                </div>
                <div className="d--flex gap--lg w--full flex-column--xs">
                  <div className="w--full">
                    <FormInput placeholder="First Name" height="50" />
                  </div>
                  <div className="w--full">
                    <FormInput placeholder="First Name" height="50" />
                  </div>
                </div>
                <div className="d--flex gap--lg w--full">
                  <div className="w--full">
                    <textarea className="form--control w--full h-min--200  radius--sm p-l--md p-r--md  border-full--black-200"></textarea>
                  </div>
                </div>
                <div className="w--full  d--flex justify-content--end">
                  <div className="w-max--150 w--full">
                    <Button
                      type="submit"
                      size="lg"
                      variant="secondary"
                      color="white"
                      btnClasses="btn white--space-none font--md"
                    >
                      Send Query
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
