export const initialValues = {
  did_number: '',
  enable_greeting: false,
  forward_call_actions: {
    greeting_filename: '',
    business_hour_type: '24h',
    business_hours_operations: {
      country: 'US',
      timezone: '',
      custom_hours: {
        monday: {
          open: false,
          start: '',
          end: '',
        },

        tuesday: {
          open: false,
          start: '',
          end: '',
        },

        wednesday: {
          open: false,
          start: '',
          end: '',
        },

        thursday: {
          open: false,
          start: '',
          end: '',
        },

        friday: {
          open: false,
          start: '',
          end: '',
        },

        saturday: {
          open: false,
          start: '',
          end: '',
        },

        sunday: {
          open: false,
          start: '',
          end: '',
        },
      },
    },
    during_business_hour: {
      failover: {
        type: '',
        value: '',
      },
    },
  },
};

export const businessHours = [
  {
    id: 'monday',
    label: 'Monday',
  },
  {
    id: 'tuesday',
    label: 'Tuesday',
  },
  {
    id: 'wednesday',
    label: 'Wednesday',
  },
  {
    id: 'thursday',
    label: 'Thursday',
  },
  {
    id: 'friday',
    label: 'Friday',
  },
  {
    id: 'saturday',
    label: 'Saturday',
  },
  {
    id: 'sunday',
    label: 'Sunday',
  },
];
