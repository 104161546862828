import React, { useState } from 'react';
import TransferBtnComponent from './TransferBtnComponent';
import Spinner from '../../Spinner';
import FormInput from '../../FormInput';
import useEmployeeAgentList from '../../../hooks/useEmployeeAgentList';
import { useAuth } from '../../../hooks/useAuth';
import Avatar from '../../Avatar';

const TransferCallComponent = ({
  setAction,
  activeCall,
  isAttended = false,
}) => {
  const { _number = '' } = activeCall;
  const [number, setNumber] = useState('');
  const { user } = useAuth();
  const { data: employeeAgentList = [], isLoading: memberListLoad } =
    useEmployeeAgentList();

  // # dtmf to remove the person to whom we transfered the call
  // * dtmf to remove the person to perosn who is transfering the call

  return (
    <>
      <div className="list_invite p--md">
        <div className="d--flex align-items--center justify-content--between gap--sm">
          <FormInput
            name="phone"
            type="text"
            height="36"
            placeholder="Type number"
            value={number}
            onKeyDown={() => null}
            onChange={(e) =>
              setNumber(
                e.target.value
                  .toString()
                  .replace(/[^+0-9*#]/g, '')
                  .slice(0, 15),
              )
            }
          />
          <div className="d--flex gap--sm">
            <TransferBtnComponent
              ext={number}
              isAttended={isAttended}
              activeCall={activeCall}
            />
          </div>
        </div>
        {/* <hr /> */}
        <div className="h-min--370 h-max--370 overflow--auto w--full p-b--sm p-t--sm p-r--xs m-t--sm border-top--black-100 gap--sm d--flex flex--column">
          {memberListLoad ? (
            <div>
              <Spinner />
            </div>
          ) : employeeAgentList && employeeAgentList?.length > 0 ? (
            employeeAgentList?.map((item) => {
              const {
                first_name = 'Unknown',
                last_name = '',
                extension,
                uuid = '',
                profile_pic = '',
              } = item;
              if (user?.uuid === uuid) return;
              if (`${extension}` === `${_number}`) return;
              const name = `${first_name} ${last_name || ''}`;
              return (
                <div
                  className={`d--flex w-full c--pointer justify-content--between align-items--center`}
                  htmlFor={item?.uuid}
                  key={item?.uuid}
                >
                  <div className="d--flex w--full gap--md">
                    <Avatar
                      extension={extension}
                      first_name={first_name}
                      last_name={last_name}
                      image={profile_pic}
                    />
                    {/* <div className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--sm d--flex align-items--center justify-content--center text--primary bg--contrast  c--pointer">
                      {name?.charAt(0)?.toUpperCase()}
                    </div> */}

                    <div className="d--flex flex--column">
                      <div
                        className="text--sm font--600"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {name || ''}
                      </div>
                      <div className="text--sm ">{extension || ''}</div>
                    </div>
                  </div>
                  <div className="gutter gutter--1">
                    <TransferBtnComponent
                      key={item?.extension}
                      ext={item?.extension}
                      isAttended={isAttended}
                      activeCall={activeCall}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text text--sm text-center">No records</div>
          )}
        </div>
      </div>
    </>
  );
};

export default TransferCallComponent;
