import React from 'react';
import FormInput from '../../../comopnents/FormInput';
import FormSelect from '../../../comopnents/FormSelect';
import {
  addressProofMap,
  downloadFileFromURL,
  identityProofMap,
} from '../../../helpers/functions';
import useIcons from '../../../assets/icons/useIcons';

const countries = [{ label: 'United States', value: 'US' }];
const numberOptions = [
  {
    id: 1,
    label: 'Local',
    value: 'Local',
  },
  {
    id: 1,
    label: 'Toll-free',
    value: 'Toll-free',
  },
];
const RenderCountries = ({ item }) => {
  return (
    <option key={item?.value} value={item?.value}>
      {item?.label}
    </option>
  );
};
const RenderNumbers = ({ item }) => {
  return (
    <option key={item?.id} value={item?.value}>
      {item?.label}
    </option>
  );
};

const typeOptions = [
  {
    label: 'Personal',
    value: 'P',
  },
  {
    label: 'Buisness',
    value: 'B',
  },
];

const RenderItem = ({ item }) => {
  return (
    <option className="text--black" key={item.value} value={item.value}>
      {item.label}
    </option>
  );
};

const PreviewPortRequest = ({ data }) => {
  const { PDFIcon } = useIcons();
  return (
    <div className="w--full d--flex flex--column gap--md">
      <div className="overflow--auto  w--full d--flex flex--column gap--md p--md">
        <div className="d--flex w--full">
          <FormInput
            type="text"
            value={data?.did_number}
            label="DID Number"
            disabled={true}
          />
        </div>
        <div className="d--flex  gap--md align-items--end m-t--md">
          <span className="w--full">
            <FormSelect
              value={data?.country}
              label="Country"
              options={countries || []}
              renderOption={RenderCountries}
              disabled={true}
            />
          </span>
          <span className="w--full">
            <FormSelect
              options={numberOptions || []}
              label="Number type"
              renderOption={RenderNumbers}
              placeholder="Select"
              disabled={true}
              value={data?.number_type}
            />
          </span>
        </div>

        <div className="w--full d--flex flex--column gap--md">
          <div className="label--control font--sm font--500 d--flex gap--sm align-items--center h-min--36 m-t--md text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
            End User Registration Details
          </div>

          <div className="d--flex gap--md">
            <FormInput
              type="text"
              label="First Name"
              placeholder="Enter your first name"
              value={data?.first_name}
              disabled={true}
            />

            <FormInput
              type="text"
              label="Last Name"
              placeholder="Enter your last name"
              disabled={true}
              value={data?.last_name}
            />
          </div>
          <div className="d--flex gap--md">
            <FormInput
              type="text"
              label="Phone number"
              placeholder="Enter phone number"
              disabled={true}
              value={data?.phone_number}
            />

            <FormInput
              type="text"
              label="Contact email"
              placeholder="Enter your contact email"
              disabled={true}
              value={data?.contact_email}
            />
          </div>

          <div>
            <label
              className={`label--control font--sm font--500 m-b--xs d--flex text--black`}
            >
              Identity Proofs
            </label>
            <div className="d--flex flex--column gap--xs">
              {data?.identity_proofs && data?.identity_proofs?.length > 0 ? (
                data?.identity_proofs?.map((proof, index) => {
                  return (
                    <div className="d--flex gap--md align-items--center">
                      <div className="w-min--20">{index + 1}.</div>
                      <div className="w-min--100">
                        {identityProofMap?.[proof?.type] ?? 'Document'}
                      </div>
                      {proof?.file?.includes('pdf') ? (
                        <div className="text--red">
                          <PDFIcon width={20} height={20} />
                        </div>
                      ) : (
                        <img
                          width="20"
                          src={`${process.env.REACT_APP_MEDIA_URL}signed-url/${data?.user_uuid}/porting_doc?filename=${proof?.file}`}
                        />
                      )}
                      <div
                        className="text--primary font--sm font--500 c--pointer"
                        onClick={() =>
                          downloadFileFromURL(
                            `${process.env.REACT_APP_MEDIA_URL}signed-url/${data?.user_uuid}/porting_doc?filename=${proof?.file}`,
                          )
                        }
                      >
                        Download
                      </div>
                    </div>
                  );
                })
              ) : (
                <>No identity proof found</>
              )}
            </div>
          </div>
          {/* Address */}
          <div className="label--control font--sm font--500 d--flex gap--sm align-items--center h-min--36 m-t--md text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
            End User Address Details
          </div>
          <div className="gap--md d--flex flex--column">
            <FormSelect
              label="Country"
              options={countries || []}
              renderOption={RenderCountries}
              disabled={true}
              value={data?.country}
            />

            <div className="d--flex gap--md">
              <FormInput
                type="text"
                label="City"
                disabled={true}
                value={data?.city}
              />

              <FormInput
                type="text"
                label="Postal code"
                disabled={true}
                value={data?.postal_code}
              />
            </div>
            <div className="w--full">
              <label
                className={`label--control font--sm font--500 m-b--xs d--flex text--black`}
              >
                Address
              </label>
              <textarea
                className="form--control w--full h-min--100  radius--sm p-l--md p-r--md  border-full--black-200"
                value={data?.address}
                name="address"
                rows="5"
                cols="150"
                disabled={true}
              ></textarea>
            </div>

            <FormInput
              type="text"
              label="State/Province/Region"
              value={data?.state}
              disabled={true}
            />
            <div>
              <label
                className={`label--control font--sm font--500 m-b--xs d--flex text--black`}
              >
                Address Proofs
              </label>
              {data?.address_proofs && data?.address_proofs?.length > 0 ? (
                data?.address_proofs?.map((proof, index) => {
                  return (
                    <div className="d--flex gap--md align-items--center">
                      <div className="w-min--20">{index + 1}.</div>
                      <div className="w-min--150">
                        {addressProofMap?.[proof?.type] ?? 'Document'}
                      </div>
                      {proof?.file?.includes('pdf') ? (
                        <div className="text--red">
                          <PDFIcon width={20} height={20} />
                        </div>
                      ) : (
                        <img
                          width="20"
                          src={`${process.env.REACT_APP_MEDIA_URL}signed-url/${data?.user_uuid}/porting_doc?filename=${proof?.file}`}
                        />
                      )}
                      <div
                        className="text--primary font--sm font--500 c--pointer"
                        onClick={() =>
                          downloadFileFromURL(
                            `${process.env.REACT_APP_MEDIA_URL}signed-url/${data?.user_uuid}/porting_doc?filename=${proof?.file}`,
                          )
                        }
                      >
                        Download
                      </div>
                    </div>
                  );
                })
              ) : (
                <>No address proof found</>
              )}
            </div>
          </div>
        </div>

        <div className="w--full d--flex flex--column gap--md"></div>
      </div>
    </div>
  );
};

export default PreviewPortRequest;
