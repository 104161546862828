import React from 'react';
import Button from '../Button';
import useIcons from '../../assets/icons/useIcons';
import { useAlert } from '../../hooks/useAlert';

export default function Alert({ alert, icon: Icon }) {
  const {
    CrossRoundIcon,
    WarningOutlineIcon,
    SuccessOutlineIcon,
    InfoOutlineIcon,
  } = useIcons();

  const { clearAlert } = useAlert();
  const iconLookup = {
    success: <SuccessOutlineIcon width={30} height={30} />,
    warning: <WarningOutlineIcon width={30} height={30} />,
    info: <InfoOutlineIcon width={30} height={30} />,
    secondary: <WarningOutlineIcon width={30} height={30} />,
  };
  return (
    <div
      key={alert?.id}
      data-alert
      id={alert?.id}
      className={`gap--md bg--${alert?.type} text--white`}
    >
      {(Icon || iconLookup[alert?.type]) && (
        <Button
          btnClasses="btn w-max--28 h-max--28 c--pointer"
          type="button"
          variant="transparent"
          icon={
            Icon
              ? Icon
              : iconLookup[alert?.type]
              ? iconLookup[alert?.type]
              : null
          }
          color="white"
        />
      )}
      {alert?.message}
      {/* <Button
        onClick={() => clearAlert(alert?.id)}
        btnClasses="w-max--32 h-max--32 position--absolute c--pointer"
        type="button"
        variant={alert?.type}
        icon={<CrossRoundIcon />}
        color="white"
        rounded
      /> */}
    </div>
  );
}
