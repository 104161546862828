import React, { useState } from 'react';
import AsideModal from '../../../comopnents/AsideModal';
import TableManager from '../../../comopnents/TableManager';
import Avatar from '../../../comopnents/Avatar';
import { agencyMemberList } from '../../../api';

export default function AgencyMemberList({ agency_uuid, children }) {
  const [showAgencyMembers, setShowAgencyMembers] = useState(false);

  const handleClose = () => {
    setShowAgencyMembers(false);
  };

  const columns = [
    {
      accessorKey: 'first_name',
      header: () => 'First Name',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <span className="d--flex gap--sm  align-items--center">
            <Avatar
              size="28"
              first_name={props.getValue()}
              last_name={element?.last_name}
              image={element?.profile_pic}
              extension={element?.extension}
            />
            {props.getValue()}
          </span>
        );
      },
    },
    {
      accessorKey: 'last_name',
      header: () => 'Last Name',
      cell: (props) => {
        return (
          <span className="d--flex gap--sm  align-items--center">
            {props.getValue()}
          </span>
        );
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
    },

    {
      accessorKey: 'extension',
      header: () => 'Extension',
      meta: {
        textAlign: 'center',
        width: '3%',
      },
    },
  ];

  return (
    <span className="agencyEmployeeModal">
      <span onClick={() => setShowAgencyMembers(true)}>{children}</span>
      {showAgencyMembers ? (
        <AsideModal
          handleClose={handleClose}
          title={`Agency Employees`}
          footerComponent={null}
          width="sm"
        >
          <div className="p-l--sm p-r--sm">
            <TableManager
              {...{
                showHeader: false,
                fetcherKey: 'agencyMemberList',
                fetcherFn: agencyMemberList,
                columns,
                name: 'Members',
                showPagination: false,
                extraParams: {
                  agency_uuid: agency_uuid,
                },
              }}
            />
          </div>
        </AsideModal>
      ) : null}
    </span>
  );
}
