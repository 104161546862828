import React, { useEffect, useState } from 'react';
import useIcons from '../../../../assets/icons/useIcons';
import useGreetingList from '../../../../hooks/useGreetingList';
import AudioPlayer from '../../../../comopnents/AudioPlayer';
import { Controller } from 'react-hook-form';
import SingleDropdownMain from '../../../../comopnents/SingleDropdownMain';
import AsideModal from '../../../../comopnents/AsideModal';
import AddGreetingForm from '../../CustomGreetings/AddGreetingForm';

const RenderGreetingItem = ({ item, onChange, setSearchValue, setShow }) => {
  return (
    <div
      className="text--black h-min--32 d--flex align-items--center justify-content--start p--sm w--full"
      key={item.filename}
      onClick={() => {
        setSearchValue('');
        onChange(item.filename);
        setShow(false);
      }}
    >
      {item.name}
    </div>
  );
};

const Voicemail = ({ watch, control, errors, register }) => {
  const { HoldIcon, PlayIcon, PlusIcon } = useIcons();
  const [playingData, setPlayingData] = useState({
    isPlaying: false,
    file: null,
  });

  const [addGlobalGreeting, setAddGlobalGreeting] = useState(false);
  const {
    mutate: greetingMutate,
    data: greetingListing,
    isLoading: greetingListLoad,
  } = useGreetingList();

  function handleClose() {
    setAddGlobalGreeting(false);
  }

  useEffect(() => {
    greetingMutate();
  }, []);

  return (
    <div className="d--flex flex--column gap--lg">
      <div className="font--md font--600  text--primary">Voicemail setting</div>
      <div className="d--flex flex--column">
        <label className="label--control font--sm font--500 m-b--xs text--black d--flex align-items--center gap--sm ">
          Select Message
          <span
            className="bg--primary-100 text--primary radius--full w-max--20 w-min--20  h-max--20 h-min--20 d--flex align-items--center justify-content--center c--pointer"
            data-tooltip={'Add New Voicemail'}
            tooltip-position="right"
            onClick={() => setAddGlobalGreeting(true)}
          >
            <PlusIcon width={10} height={10} />
          </span>
        </label>
        <div className="d--flex align-items--end w--full gap--sm">
          <Controller
            name="vm_file"
            control={control}
            render={({ field }) => (
              <SingleDropdownMain
                {...field}
                labelKey={'name'}
                valueKey="filename"
                optionList={
                  greetingListing?.data?.data?.filter(
                    (item) => item?.type === 'voicemail',
                  ) ?? []
                }
                optionListLoad={greetingListLoad}
                component={RenderGreetingItem}
                error={errors?.vm_file?.message}
              />
            )}
          />
          {watch('vm_file') && (
            <>
              {watch('vm_file') === playingData?.file ? (
                <span
                  className="c--pointer m-b--sm"
                  onClick={() => {
                    setPlayingData({
                      isPlaying: false,
                      file: null,
                    });
                  }}
                >
                  <HoldIcon width={20} height={20} />
                  <AudioPlayer
                    type="greeting"
                    onClose={() => {
                      setPlayingData({
                        isPlaying: false,
                        file: null,
                      });
                    }}
                    name={watch('vm_file')}
                  />
                </span>
              ) : (
                <span
                  className="c--pointer text--success m-b--sm"
                  onClick={() => {
                    setPlayingData({
                      playing: true,
                      file: watch('vm_file'),
                    });
                  }}
                >
                  <PlayIcon width={20} height={20} />
                </span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="d--flex gap--md">
        <label className="label--control font--sm font--500 m-b--xs  text--black">
          Send Voicemail to email
        </label>
        <div className="d--flex gap--md align-items--center">
          <div className="d--flex align-items--center gap--sm ">
            <input
              {...register('email_vm')}
              className="radio"
              id="email_vm_no"
              type="radio"
              value="N"
              checked={watch('email_vm') === 'N'}
            />
            <label htmlFor="email_vm_no" className="font--sm c--pointer">
              No
            </label>
          </div>
          <div className="d--flex align-items--center gap--sm ">
            <input
              {...register('email_vm')}
              className="radio"
              id="email_vm_yes"
              type="radio"
              value="Y"
              checked={watch('email_vm') === 'Y'}
            />
            <label htmlFor="email_vm_yes" className="font--sm c--pointer">
              Yes
            </label>
          </div>
        </div>
      </div>

      {addGlobalGreeting ? (
        <AsideModal
          handleClose={handleClose}
          title={'Add Voicemail'}
          footerComponent={null}
        >
          <AddGreetingForm handleClose={handleClose} type="voicemail" />
        </AsideModal>
      ) : null}
    </div>
  );
};

export default Voicemail;
