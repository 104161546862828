import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { ringGroupList } from '../../api';

export default function useRingGroupList() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: ringGroupList,
    mutationKey: 'ringGroupList',
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
