import React, { useContext, useState } from 'react';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import FormInput from '../../FormInput';
import useIcons from '../../../assets/icons/useIcons';

const DTMFKeypadComponent = ({ setAction, activeCall }) => {
  const { ArrowLeftIcon } = useIcons();
  const { socket } = useContext(SipSocketContext);
  const { _sendDtmf = () => null } = socket;
  const [dtmf, setDtmf] = useState('');
  const handleKeyPress = (val) => {
    setDtmf((prev) => prev + val);
    _sendDtmf(activeCall?.callID, val);
  };
  return (
    <>
      <div className="d--flex align-items--center gap--sm p--md">
        {/* <div
          className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--sm d--flex align-items--center justify-content--center text--primary bg--contrast  c--pointer"
          onClick={() => setAction(null)}
        >

          <ArrowLeftIcon />
        </div> */}
        <FormInput
          name="phone"
          height="40"
          type="text"
          value={dtmf}
          onKeyDown={() => null}
          onChange={() => null}
        />
      </div>
      <div className="d--grid grid-system h-min--370 h-max--370 overflow-auto m-b--sm">
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('1')}
        >
          1
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('2')}
        >
          2
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('3')}
        >
          3
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('4')}
        >
          4
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('5')}
        >
          5
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('6')}
        >
          6
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('7')}
        >
          7
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('8')}
        >
          8
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('9')}
        >
          9
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('*')}
        >
          *
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('0')}
        >
          0
        </span>
        <span
          className="items font--600 font--2xl c--pointer flex--column w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          onClick={() => handleKeyPress('#')}
        >
          #
        </span>
      </div>
    </>
  );
};

export default DTMFKeypadComponent;
