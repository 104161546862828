import React from 'react';
import appleStore from '../../../assets/images/web/appleStore.png';
import googlePlay from '../../../assets/images/web/googlePlay.png';
import desktop from '../../../assets/images/web/windowDownload.png';
import mac from '../../../assets/images/web/macDownload.png';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export const OuterFooter = () => {
  const navigate = useNavigate();
  return (
    <div className="footer bg--primary-800  ">
      <div className="container">
        <div className="w--full d--flex gap--xl text--white p-t--sm p-b--sm flex-column--xs">
          <div className="w--full w-max--300 d--flex flex--column  gap--sm  p--md border-right--purple ">
            <h5 className="font--lg font--600 p-b--md">Download Nextere App</h5>
            <div className="d--flex flex--column gap--sm w--full">
              <div className="w--full d--flex gap--sm flex-column--sm">
                <img
                  src={appleStore}
                  alt="appleStore"
                  className="c--pointer"
                  width={130}
                  onClick={() => {
                    window.open(
                      'https://apps.apple.com/us/app/nextere-voice/id6497335247',
                      '_blank',
                    );
                  }}
                />
                <img
                  src={googlePlay}
                  alt="googlePlay"
                  className="c--pointer"
                  width={130}
                  onClick={() => {
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.nextere',
                      '_blank',
                    );
                  }}
                />
              </div>
              <div className="w--full d--flex gap--sm flex-column--sm">
                <img
                  src={desktop}
                  alt="desktopApp"
                  className="c--pointer"
                  width={130}
                  onClick={() => {
                    window.open(
                      process.env.REACT_APP_WINDOWS_APP_URL,
                      '_blank',
                    );
                  }}
                />
                <img
                  src={mac}
                  alt="macApp"
                  className="c--pointer"
                  width={130}
                  onClick={() => {
                    window.open(process.env.REACT_APP_MAC_APP_URL, '_blank');
                  }}
                />
              </div>
            </div>

            {/* <div className="logo w-max--200">
              <img src={LogoImg} alt="logo" className="w--full" />
            </div> */}
            {/* <p className="font--14 font--300 line-height--2">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of letters.
            </p> */}
          </div>
          <div className="w--full d--flex flex--column list gap--lg border-right--purple">
            <h5 className="font--lg font--600 p-t--md">Features</h5>
            <div className="d--flex d--flex flex--column gap--lg p-b--lg">
              <div
                className="font--14 c--pointer listItem"
                onClick={() => navigate('/company')}
              >
                About
              </div>
              <div
                className="font--14 c--pointer listItem"
                onClick={() => navigate('/pricing')}
              >
                Plan & Pricing
              </div>
              <div
                className="font--14 c--pointer listItem"
                onClick={() => navigate('/features')}
              >
                Features
              </div>
            </div>
          </div>
          <div className="w--full d--flex flex--column list gap--lg border-right--purple">
            <h5 className="font--lg font--600 p-t--md">Support</h5>
            <div className="d--flex d--flex flex--column gap--lg p-b--lg">
              <div
                className="font--14 c--pointer listItem"
                onClick={() => navigate('/contact-us')}
              >
                Contact us
              </div>
              <div
                className="font--14 c--pointer listItem"
                onClick={() => navigate('/terms-conditions')}
              >
                Terms & Conditions
              </div>
              <div
                className="font--14 c--pointer listItem"
                onClick={() => navigate('privacy-policy')}
              >
                Privacy Policy
              </div>
              {/* <div className="font--14 c--pointer listItem">Blog</div> */}
            </div>
          </div>
          <div className="w--full d--flex flex--column list gap--lg ">
            <h5 className="font--lg font--600 p-t--md">Address</h5>
            <div className="d--flex d--flex flex--column gap--lg p-b--lg">
              <p className="font--14 font--300 line-height--2">
                Ernesto Pena <br />
                1 Ludlam Dr. Miami Spring Florida, 33166 <br />
                USA <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w--full p--md bg--primary">
        <p className="text--c text--white font--sm">
          @{moment().year()} nextere. All rights reserved.
        </p>
      </div>
    </div>
  );
};
