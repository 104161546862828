import { useMutation, useQueryClient } from '@tanstack/react-query';
import { memberDelete } from '../../api';
import { useAlert } from '../useAlert';

export default function useMemberDelete() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const memberDeleteRequest = useMutation({
    mutationFn: memberDelete,
    mutationKey: 'memberDelete',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['getMemberListing'],
        exact: false,
      });
      queryClient.refetchQueries({
        queryKey: ['getAllNumberDetails'],
        exact: true,
      });
      showAlert({
        type: 'success',
        message: data?.message ?? 'Member deleted successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...memberDeleteRequest };
}
