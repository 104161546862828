import React from 'react';
import SingleDropdown from '../SingleDropdown';
import { Controller } from 'react-hook-form';

const ForwardToInput = ({
  register,
  forward_type_key = '',
  forward_value_key = '',
  forwardOptions = [],
  memberListData = [],
  memberListLoad = false,
  ringGroupListLoad = false,
  ivrListLoad = false,
  greetingListLoad = false,
  departmentList = [],
  ivrList = [],
  greetingList = [],
  watch,
  control,
  setValue,
  forward_type_error = '',
  forward_value_error = '',
}) => {
  const handleOptions = (option) => {
    switch (option) {
      case 'Extension':
        const memberListing = memberListData ?? [];
        return (
          <>
            {memberListing &&
              memberListing.length > 0 &&
              memberListing.map((item) => {
                return (
                  <option key={item?.extension} value={item?.extension}>
                    {item?.first_name} {item?.last_name}&nbsp;({item?.extension}
                    )
                  </option>
                );
              })}
          </>
        );
      case 'Department':
        const departmentListing = departmentList ?? [];
        return (
          <>
            {departmentListing &&
              departmentListing.length > 0 &&
              departmentListing.map((item) => {
                return (
                  <option key={item?.uuid} value={item?.uuid}>
                    {item?.title}
                  </option>
                );
              })}
          </>
        );
      case 'VOICEMAILGROUP':
        const departmentListing2 = departmentList ?? [];
        return (
          <>
            {departmentListing2 &&
              departmentListing2.length > 0 &&
              departmentListing2.map((item) => {
                return (
                  <option key={item?.uuid} value={item?.uuid}>
                    {item?.title}
                  </option>
                );
              })}
          </>
        );
      case 'Voicemail':
        const greetingListing = greetingList ?? [];

        return (
          <>
            {greetingListing &&
              greetingListing.length > 0 &&
              greetingListing.map((item) => {
                return (
                  <option key={item?.filename} value={item?.filename}>
                    {item?.name}
                  </option>
                );
              })}
          </>
        );
      case 'IVR':
        const ivrListing = ivrList ?? [];
        return (
          <>
            {ivrListing &&
              ivrListing.length > 0 &&
              ivrListing.map((item) => {
                return (
                  <option key={item?.uuid} value={item?.uuid}>
                    {item?.name}
                  </option>
                );
              })}
          </>
        );

      default:
        break;
    }
  };

  function getOptionsMap({
    forward_type_key_value,
    register,
    memberListData = [],
    forward_value_key: value = '',
    setValue = () => null,
    forward_value_key_value: optionVal,
    forward_value_error,
    memberListLoad,
  }) {
    switch (forward_type_key_value) {
      case 'Extension':
        return (
          <Controller
            name={`${value}`}
            control={control}
            render={({ field }) => (
              <SingleDropdown
                {...field}
                labelKey={'first_name'}
                optionList={
                  memberListData?.filter((item) => {
                    return item?.verified === 1 && item?.is_active === 1;
                  }) || []
                }
                placeholder={memberListLoad ? 'Please wait' : 'Select'}
                optionListLoad={memberListLoad}
                error={forward_value_error}
              />
            )}
          />
        );
      case 'Voicemail':
        return (
          <Controller
            name={`${value}`}
            control={control}
            render={({ field }) => (
              <SingleDropdown
                {...field}
                labelKey={'first_name'}
                optionList={
                  memberListData?.filter((item) => {
                    return item?.verified === 1 && item?.is_active === 1;
                  }) || []
                }
                optionListLoad={memberListLoad}
                error={forward_value_error}
              />
            )}
          />
        );
      case 'Department':
        return (
          <select
            required
            {...register(value)}
            className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md border-full--black-200 `}
            disabled={ringGroupListLoad}
          >
            {ringGroupListLoad ? (
              <option value={optionVal}>Please wait...</option>
            ) : null}
            <option value="">Select</option>
            {handleOptions(forward_type_key_value)}
          </select>
        );
      case 'VOICEMAILGROUP':
        return (
          <select
            required
            {...register(value)}
            className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md border-full--black-200 `}
            disabled={ringGroupListLoad}
          >
            {ringGroupListLoad ? (
              <option value={optionVal}>Please wait...</option>
            ) : null}
            <option value="">Select</option>
            {handleOptions(forward_type_key_value)}
          </select>
        );
      case 'IVR':
        return (
          <select
            required
            {...register(value)}
            className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md border-full--black-200 `}
            disabled={ivrListLoad}
          >
            {ivrListLoad ? (
              <option value={optionVal}>Please wait...</option>
            ) : null}
            <option value="">Select</option>
            {handleOptions(forward_type_key_value)}
          </select>
        );
      case 'Number':
        return (
          <div>
            {forward_value_error?.trim() && (
              <label
                className={`label--control font--sm font--500 m-b--xs d--flex w--full text--danger`}
              >
                {forward_value_error}
              </label>
            )}
            <input
              {...register(value)}
              onChange={(e) =>
                setValue(
                  value,
                  e.target.value
                    .toString()
                    .replace(/[^+0-9*#]/g, '')
                    .slice(0, 13),
                )
              }
              placeholder={'Enter Number'}
              className={`form--control w--full h-min--36 radius--sm p-l--md p-r--md ${forward_value_error ? 'border-full--danger' : 'border-full--black-200'}  `}
            />
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div className="d--flex gap--md w--full">
      <select
        required
        {...register(forward_type_key, {
          onChange() {
            setValue(forward_value_key, '');
          },
        })}
        className={`form--control w--full h-min--36 w-max--150  radius--sm p-l--md p-r--md border-full--black-200 `}
      >
        <option key="" value={''}>
          Select Option
        </option>
        {forwardOptions && forwardOptions.length > 0
          ? forwardOptions.map((option) => (
            <option
              key={option}
              value={
                option === 'Department Voicemail' ? 'VOICEMAILGROUP' : option
              }
            >
              {option}
            </option>
          ))
          : null}
      </select>
      <div className="w--full w-min--150 d--flex align-items--center">
        {getOptionsMap({
          forward_type_key_value: watch(forward_type_key),
          register,
          memberListData,
          forward_value_key,
          setValue,
          forward_value_key_value: watch(forward_value_key),
          forward_value_error,
          memberListLoad,
        })}
      </div>
    </div>
  );
};

export default ForwardToInput;
