import React from 'react';

const MMSPreview = ({ media = '', media_type = '' }) => {
  return (
    <div className="d--flex gap--md m-t--sm">
      {media_type?.includes('image') && (
        <img
          onClick={() => {
            window.open(media, '_blank');
          }}
          className={`w-max--200 w-min--200 h-max--100 h-min--100 radius--sm bg--black object--contain overflow--hidden c--pointer`}
          src={media}
          alt=""
        />
      )}
      {media_type?.includes('video') && (
        <video
          className={`w-max--200 w-min--200 h-max--100 h-min--100 radius--sm bg--black-300 object--contain overflow--hidden `}
          src={media}
          controls
        />
      )}
      {media_type?.includes('audio') && (
        <audio src={media} controls className={``} />
      )}
    </div>
  );
};

export default MMSPreview;
