import { useEffect, useState } from 'react';
import Basic from './Basic';
import OperationalHours from './OperationalHours';
import Voicemail from './Voicemail';
import {
  departmentLabelsArray,
  timeoutTypeLookup,
  timeoutValLookup,
  timeoutValLookupInverse,
} from '../../../helpers/functions';
import Tabbing from '../../../comopnents/Tabbing';
import Button from '../../../comopnents/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useRingGroupCreate from '../../../hooks/useRingGroupCreate';
import useRingGroupEdit from '../../../hooks/useRingGroupEdit';
import useRingGroupList from '../../../hooks/useRingGroupList';
import useGreetingList from '../../../hooks/useGreetingList';
import useIVRList from '../../../hooks/useIVRList';
import useMemberAgentsAllListing from '../../../hooks/useMemberAgentsAllListing';
import MissedCallSettings from './MissedCallSettings';

const basicValidationSchema = yup.object({
  title: yup
    .string()
    .required('Name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Name cannot contain numbers'),
  members: yup.array().min(1, 'Atleast 1 Member is required'),
  timeout: yup.string().required('Required'),
  timeout_type: yup.string().required('Timeout type is required'),
  timeout_value: yup
    .string()
    .nullable()
    .when('timeout_type', {
      is: (res) => ['Extension', 'Number', 'Voicemail', 'IVR'].includes(res),
      then: yup.string().required('Timeout value is required'),
    }),
});

const hoursValidationSchema = yup.object().shape({
  business_hour_type: yup.string().required(`Business hour type is required.`),
  business_hours_operations: yup.object().when('business_hour_type', {
    is: 'custom',
    then: yup.object({
      country: yup.string().required('Country is required'),
      timezone: yup.string().required('Timezone is required'),
      closed_business_hour: yup.object({
        type: yup.string().required(`Forward type is required`),
        value: yup.string().when('type', {
          is: (val) => val,
          then: yup.string().required('Forward value is required'),
        }),
      }),
      custom_hours: yup.object({
        monday: yup.object({
          start: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
          end: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
        }),
        tuesday: yup.object({
          start: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
          end: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
        }),
        wednesday: yup.object({
          start: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
          end: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
        }),
        thursday: yup.object({
          start: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
          end: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
        }),
        friday: yup.object({
          start: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
          end: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
        }),
        saturday: yup.object({
          start: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
          end: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
        }),
        sunday: yup.object({
          start: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
          end: yup.string().when('open', {
            is: true,
            then: yup.string().required('Required'),
          }),
        }),
      }),
    }),
  }),
});

const basicInitialValues = {
  title: '',
  greeting: '',
  members: [],
  timeout: '',
  timeout_type: '',
  timeout_value: '',
};
const hoursInitialValues = {
  call_record: 'N',
  business_hour_type: '24h',
  failover: {
    type: '',
    value: '',
  },
  business_hours_operations: {
    country: 'US',
    timezone: '',
    closed_business_hour: {
      type: '',
      value: '',
    },
    custom_hours: {
      monday: {
        open: true,
        start: '09:00',
        end: '18:00',
      },

      tuesday: {
        open: true,
        start: '09:00',
        end: '18:00',
      },

      wednesday: {
        open: true,
        start: '09:00',
        end: '18:00',
      },

      thursday: {
        open: true,
        start: '09:00',
        end: '18:00',
      },

      friday: {
        open: true,
        start: '09:00',
        end: '18:00',
      },

      saturday: {
        open: false,
        start: '',
        end: '',
      },

      sunday: {
        open: false,
        start: '',
        end: '',
      },
    },
  },
};
const voicemailInitialValues = {
  vm_file: '',
  email_vm: 'N',
};

export default function DepartmentForm({
  handleClose = () => null,
  editRingGroupInstance,
}) {
  const [tab, setTab] = useState(0);
  const [memberListAll, setMemberListALL] = useState([]);
  const [greetingList, setGreetingList] = useState([]);

  const { mutateAsync: createRingGroupMutate, isLoading: createRingGroupLoad } =
    useRingGroupCreate();
  const { mutateAsync: editRingGroupMutate, isLoading: editRingGroupLoad } =
    useRingGroupEdit();

  const {
    mutate: ringGroupListMutate,
    data: ringGroupListData,
    isLoading: ringGroupListLoad,
  } = useRingGroupList();
  const {
    mutate: ivrListMutate,
    data: ivrListData,
    isLoading: ivrListLoad,
  } = useIVRList();
  const {
    mutate,
    data: greetingListData,
    isLoading: greetingListLoad,
  } = useGreetingList();

  const {
    mutateAsync: mutateAsyncALL,
    data: dataALL,
    isLoading: memberListLoad,
  } = useMemberAgentsAllListing();

  useEffect(() => {
    if (!greetingListData?.data) {
      mutate();
    } else {
      setGreetingList(greetingListData?.data?.data ?? []);
    }
  }, [greetingListData]);

  useEffect(() => {
    if (!dataALL?.data) {
      mutateAsyncALL({ get_owner: 'Y' });
    } else {
      setMemberListALL(dataALL?.data?.data ?? []);
    }
  }, [dataALL]);

  const {
    watch: basicWatch,
    control: basicControl,
    formState: { errors: basicErrors },
    register: basicRegister,
    setValue: basicSetValue,
    trigger: basicTrigger,
    getValues: basicGetValues,
  } = useForm({
    resolver: yupResolver(basicValidationSchema),
    defaultValues: basicInitialValues,
    mode: 'onChange',
  });

  const {
    watch: hoursWatch,
    control: hoursControl,
    formState: { errors: hoursErrors },
    setValue: hoursSetValue,
    register: hoursRegister,
    trigger: hoursTrigger,
    getValues: hoursGetValues,
  } = useForm({
    resolver: yupResolver(hoursValidationSchema),
    defaultValues: hoursInitialValues,
    mode: 'onChange',
  });
  const {
    watch: voicemailWatch,
    control: voicemailControl,
    formState: { errors: voicemailErrors },
    setValue: voicemailSetValue,
    register: voicemailRegister,
    trigger: voicemailTrigger,
    getValues: voicemailGetValues,
  } = useForm({
    defaultValues: voicemailInitialValues,
    mode: 'onChange',
  });

  const {
    watch: missedCallSettingWatch,
    control: missedCallSettingControl,
    handleSubmit: missedCallSettingSubmit,
    setValue: missedCallSettingSetValue,
    formState: { errors: missedCallSettingErrors },
  } = useForm({
    defaultValues: {
      message: '',
      status: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    ringGroupListMutate();
    ivrListMutate();
  }, []);

  useEffect(() => {
    if (editRingGroupInstance && editRingGroupInstance?.isEdit) {
      const {
        title = '',
        greeting = '',
        timeout = '',
        timeout_type = '',
        timeout_value,
        members = [],
        forward_call_actions = {},
        vm_setting = {},
        missed_call_sms_settings,
      } = editRingGroupInstance?.editData || {};
      const refMembers = members.map((item) => item?.uuid || '');
      basicSetValue('title', title);
      basicSetValue('greeting', greeting);
      basicSetValue('members', refMembers);
      basicSetValue('timeout', timeout);
      basicSetValue('timeout_type', timeoutTypeLookup[timeout_type]);
      basicSetValue(
        'timeout_value',
        timeoutTypeLookup[timeout_type] === 'Extension'
          ? parseInt(timeout_value)
          : timeout_value,
      );
      const {
        business_hour_type = '',
        call_record = '',
        business_hours_operations,
      } = forward_call_actions || {};
      if (forward_call_actions) {
        hoursSetValue('call_record', call_record);
        hoursSetValue('business_hour_type', business_hour_type);
        if (business_hours_operations) {
          hoursSetValue('business_hours_operations', business_hours_operations);
          if (business_hours_operations?.closed_business_hour?.type) {
            hoursSetValue(
              'business_hours_operations.closed_business_hour.type',
              timeoutValLookupInverse[
                business_hours_operations?.closed_business_hour.type
              ],
            );
            hoursSetValue(
              'business_hours_operations.closed_business_hour.value',
              business_hours_operations?.closed_business_hour.value,
            );
          }
        }
        if (business_hour_type === '24h' && forward_call_actions?.failover) {
          hoursSetValue('failover', forward_call_actions?.failover);
        } else {
          if (business_hours_operations?.failover) {
            hoursSetValue('failover', business_hours_operations?.failover);
          }
        }
      }
      if (vm_setting) {
        voicemailSetValue('vm_file', vm_setting?.vm_file);
        voicemailSetValue('email_vm', vm_setting?.email_vm);
      }
      if (missed_call_sms_settings) {
        missedCallSettingSetValue(
          'message',
          missed_call_sms_settings?.message || '',
        );
        missedCallSettingSetValue(
          'status',
          missed_call_sms_settings?.status || false,
        );
      }
    }
  }, [
    editRingGroupInstance,
    greetingList,
    memberListAll,
    ringGroupListData?.data?.data,
  ]);

  const handleTab = (tab) => {
    switch (tab) {
      case 0:
        return (
          <Basic
            watch={basicWatch}
            control={basicControl}
            errors={basicErrors}
            register={basicRegister}
            setValue={basicSetValue}
            memberListAll={memberListAll}
            memberListLoad={memberListLoad}
            greetingList={greetingList}
            greetingListLoad={greetingListLoad}
            ringGroupListData={ringGroupListData}
            ringGroupListLoad={ringGroupListLoad}
            ivrListData={ivrListData}
            ivrListLoad={ivrListLoad}
          />
        );
      case 1:
        return (
          <OperationalHours
            watch={hoursWatch}
            control={hoursControl}
            errors={hoursErrors}
            setValue={hoursSetValue}
            register={hoursRegister}
            memberListAll={memberListAll}
            memberListLoad={memberListLoad}
            greetingList={greetingList}
            greetingListLoad={greetingListLoad}
            ringGroupListData={ringGroupListData}
            ringGroupListLoad={ringGroupListLoad}
            ivrListData={ivrListData}
            ivrListLoad={ivrListLoad}
          />
        );
      case 2:
        return (
          <Voicemail
            watch={voicemailWatch}
            control={voicemailControl}
            errors={voicemailErrors}
            register={voicemailRegister}
            setValue={voicemailSetValue}
          />
        );
      case 3:
        return (
          <MissedCallSettings
            control={missedCallSettingControl}
            errors={missedCallSettingErrors}
            watch={missedCallSettingWatch}
          />
        );
      default:
        break;
    }
  };

  const onSubmit = async (values) => {
    const {
      title = '',
      members = [],
      greeting = '',
      timeout,
      timeout_type,
      timeout_value,
    } = basicGetValues();
    const { vm_file, email_vm } = voicemailGetValues();
    const {
      business_hour_type,
      business_hours_operations,
      call_record = 'N',
    } = hoursGetValues();
    const payload = {
      title,
      members,
      timeout,
      timeout_type: timeoutValLookup[timeout_type],
      timeout_value,
      greeting,

      forward_call_actions: {
        call_record,
        business_hour_type,
      },

      vm_setting: {
        vm_file: vm_file || 'DEFAULT', // default value for vm_file : DEFAULT
        email_vm,
      },
      missed_call_sms_settings: values,
    };
    if (business_hour_type === '24h') {
      payload.forward_call_actions.failover = {
        type: timeoutValLookup[timeout_type],
        value: timeout_value,
      };
    } else {
      payload.forward_call_actions.business_hours_operations =
        business_hours_operations;

      payload.forward_call_actions.business_hours_operations.closed_business_hour =
        {
          type: timeoutValLookup[
            business_hours_operations.closed_business_hour.type
          ],
          value: business_hours_operations.closed_business_hour.value,
        };

      payload.forward_call_actions.business_hours_operations.failover = {
        type: timeoutValLookup[timeout_type],
        value: timeout_value,
      };
    }

    if (editRingGroupInstance?.isEdit) {
      payload.uuid = editRingGroupInstance?.editData?.uuid;
      await editRingGroupMutate(payload);
    } else {
      await createRingGroupMutate(payload);
    }
    handleClose();
  };

  async function handleSubmitButton() {
    switch (tab) {
      case 0:
        const res1 = await basicTrigger();
        if (res1) {
          setTab(1);
        }
        break;
      case 1:
        const res2 = await hoursTrigger();
        if (res2) {
          setTab(2);
        }
        break;
      case 2:
        const res3 = await voicemailTrigger();
        if (res3) {
          setTab(3);
        }
        break;
      case 3:
        missedCallSettingSubmit(onSubmit)();
        break;

      default:
        break;
    }
  }

  const loading = createRingGroupLoad || editRingGroupLoad;

  return (
    <>
      <div className="overflow--auto dialogScroll d--flex flex--column gap--lg p--md">
        <div className="w--full d--flex align-items--center justify-content--center">
          <Tabbing
            tab={tab}
            setTab={async (val) => {
              if (tab === 0 && [1, 2, 3].includes(val)) {
                const res1 = await basicTrigger();
                if (res1) {
                  setTab(val);
                }
              }
              if (tab === 1 && [2, 3].includes(val)) {
                const res1 = await hoursTrigger();
                if (res1) {
                  setTab(val);
                }
              }
              if (tab === 2 && val === 3) {
                const res1 = await voicemailTrigger();
                if (res1) {
                  setTab(val);
                }
              }
              if (val < tab) {
                setTab(val);
              }
            }}
            tabsLabels={departmentLabelsArray}
          />
        </div>

        {handleTab(tab)}
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="button"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          onClick={() => handleSubmitButton()}
          disabled={loading}
        >
          {loading ? 'Please wait' : tab === 3 ? 'Submit' : 'Next'}
        </Button>
      </div>
    </>
  );
}
