import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addcard } from '../../api';
import { useAlert } from '../useAlert';

export default function useAddCard({ handleSuccess = () => null } = {}) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const addCardRequest = useMutation({
    mutationFn: addcard,
    mutationKey: 'addcard',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(['getSavedCards']);
      showAlert({
        type: 'success',
        message: data?.message ?? 'Card added sucessfully',
      });
      handleSuccess();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...addCardRequest };
}
