import React, { useRef, useState } from 'react';
import Button from '../Button';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import useClickOutside from '../../hooks/useClickOutside';

const CustomDatePicker = ({
  showDatePicker,
  setStartDateMain,
  setEndDateMain,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const datePickerRef = useRef();
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  function onApply() {
    setStartDateMain(moment(startDate).format('YYYY-MM-DD'));
    setEndDateMain(moment(endDate).format('YYYY-MM-DD'));
    showDatePicker(false);
  }

  function onClose() {
    setStartDate(null);
    setEndDate(null);
    showDatePicker(false);
  }

  useClickOutside([datePickerRef], () => {
    onClose();
  });

  return (
    <div
      className="position--absolute right--0 z-index--sm  d--flex  gap--xs w-min--300 bg--primary-100 radius--full p--sm   top--40 box-shadow--xs align-items--center "
      ref={datePickerRef}
    >
      <DatePicker
        placeholderText={'Select date'}
        className="form--control w--full h-min--28   radius--sm p-l--0 p-r--0 border--0  bg--transparent border-full--black-200 w-min--200"
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        monthsShown={2}
        isClearable={true}
        inputFormat="YYYY/MM/DD"
      />

      <Button
        type="button"
        variant="primary"
        color="white"
        btnClasses="btn btn--xs  w-max--110 font--sm radius--full"
        onClick={onApply}
      >
        Apply
      </Button>
      <Button
        type="button"
        variant="red"
        color="white"
        btnClasses="btn btn--xs  w-max--110 font--sm  radius--full"
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  );
};

export default CustomDatePicker;
