import React, { useEffect, useState } from 'react';
import TableManager from '../../comopnents/TableManager';
import useIcons from '../../assets/icons/useIcons';
import Button from '../../comopnents/Button';
import AsideModal from '../../comopnents/AsideModal';
import { didList } from '../../api';
import {
  AGENCY_OWNER,
  USER,
  converDateTimeFormateApis,
  formatPhoneNumber,
} from '../../helpers/functions';
import Dropdown from '../../comopnents/Dropdown';
import useSetDefaultDid from '../../hooks/useSetDefaultDID';
import useRemoveForwarding from '../../hooks/useRemoveForwarding';
import { useNavigate } from 'react-router-dom';
import BuyNumberForm from './BuyNumberForm';
import { useAuth } from '../../hooks/useAuth';
import useUnassignAgencyDid from '../../hooks/useUnassignAgencyDid';
import useRingGroupList from '../../hooks/useRingGroupList';
import useIVRList from '../../hooks/useIVRList';
import useGreetingList from '../../hooks/useGreetingList';
import { Link } from 'react-router-dom';
import SettingsModal from './SettingsModal';
import Avatar from '../../comopnents/Avatar';
import useAgencyListing from '../../hooks/useAgencyListing';
import Modal from '../../comopnents/Modal';
import AudioPlayer from '../../comopnents/AudioPlayer';
import FormSelect from '../../comopnents/FormSelect';
import useMemberAgentsAllListing from '../../hooks/useMemberAgentsAllListing';

const getforwardValue = (
  type = '',
  value = '',
  departmentList = [],
  ivrList = [],
  greetingList = [],
  memberList = [],
) => {
  if (type === 'DEPARTMENT' || type === 'VOICEMAILGROUP') {
    const refDep =
      departmentList?.filter((dep) => dep?.uuid === value)?.[0] || [];
    if (refDep?.title) {
      return ` (${refDep?.title})`;
    } else {
      return '';
    }
  } else if (type === 'IVR') {
    const refIvr = ivrList?.filter((ivr) => ivr?.uuid === value)?.[0] || [];
    if (refIvr?.name) {
      return ` (${refIvr?.name})`;
    } else {
      return '';
    }
  } else if (['VOICEMAIL'].includes(type)) {
    // const refGreeting =
    //   greetingList?.filter((greeting) => greeting?.filename === value)?.[0] ||
    //   [];
    const refGreeting =
      memberList?.filter((m) => m?.extension === value)?.[0] || [];

    if (refGreeting?.first_name) {
      return ` (${
        refGreeting?.first_name + ' ' + refGreeting?.last_name ?? ''
      })`;
    } else {
      return '';
    }
  } else {
    if (value) {
      return ` (${value})`;
    } else {
      return '';
    }
  }
};

const GetForwardingData = ({
  data,
  ringGroupListData = {},
  ivrListData = {},
  greetingListData = {},
  memberListData = {},
}) => {
  if (!data) {
    return null;
  }
  const departmentList = ringGroupListData?.data?.data || [];
  const ivrList = ivrListData?.data?.data || [];
  const greetingList = greetingListData?.data?.data || [];
  const memberList = memberListData?.data?.data || [];

  return (
    <span style={{ textTransform: 'capitalize' }}>
      {data?.during_business_hour?.type === 'VOICEMAILGROUP'
        ? 'Department Voicemail'
        : data?.during_business_hour?.type?.toLowerCase()}
      {getforwardValue(
        data?.during_business_hour?.type,
        data?.during_business_hour?.value,
        departmentList,
        ivrList,
        greetingList,
        memberList,
      )}
    </span>
  );
};

export default function VirtualNumbers() {
  const { MoreVIcon, HoldIcon, PlayIcon } = useIcons();
  const navigate = useNavigate();
  const { mutate: setDefaultDidMutate } = useSetDefaultDid();
  const { mutate: unassignDidMutate } = useUnassignAgencyDid();
  const { mutate: removeForwardingMutate } = useRemoveForwarding();
  const { mutate: ringGroupListMutate, data: ringGroupListData } =
    useRingGroupList();
  const { mutate: ivrListMutate, data: ivrListData } = useIVRList();
  const { mutate: greetingListMutate, data: greetingListData } =
    useGreetingList();
  const { mutate: memberListMutate, data: memberListData } =
    useMemberAgentsAllListing();
  const { data: agencyListing = [] } = useAgencyListing();

  const { user } = useAuth();
  const ACCESS = [user?.role_id]?.find((role) =>
    [USER, AGENCY_OWNER]?.includes(role),
  );
  const [showBuyVirtualNumber, setShowBuyVirtualNumber] = useState(false);
  const [showAssignedToMembers, setShowAssignedToMembers] = useState({
    isShow: false,
    listing: [],
  });
  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
  });
  const [filterAgencyUuid, setFilterAgencyUuid] = useState('');
  const [showSettingsModal, setShowSettingsModal] = useState({
    show: false,
    data: null,
  });

  useEffect(() => {
    ringGroupListMutate({ page: 1 });
    ivrListMutate({ page: 1 });
    greetingListMutate({ page: 1 });
    memberListMutate({ get_owner: 'Y', page: 1 });
  }, []);

  const handleSetDefaultDid = (data) => {
    const payload = {
      did_number: data?.did_number,
    };
    setDefaultDidMutate(payload);
  };
  const handleUnassignAgentDid = (data) => {
    const payload = {
      did_number: data?.did_number,
    };
    unassignDidMutate(payload);
  };
  const handleRemoveForwarding = (data) => {
    const payload = {
      uuid: data?.uuid ?? '',
    };
    removeForwardingMutate(payload);
  };
  const handleSetForwarding = (data) => {
    const { uuid } = data;
    navigate(`/call/call-forwarding?${uuid}`);
  };

  const handleDropListClick = ({ value = '' }, data) => {
    switch (value) {
      case 'default_did':
        handleSetDefaultDid(data);
        break;
      case 'unassign_did':
        handleUnassignAgentDid(data);
        break;
      case 'remove_forwarding':
        handleRemoveForwarding(data);
        break;
      case 'set_forwarding':
      case 'update_forwarding':
        handleSetForwarding(data);
        break;
      case 'settings':
        setShowSettingsModal({ show: true, data });
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Buy Date',
      cell: ({ row }) => {
        return converDateTimeFormateApis(row?.original?.created_at);
      },
      meta: {
        width: '12%',
      },
    },
    {
      accessorKey: 'did_number',
      header: () => 'Virtual Number',
      cell: ({ row }) => {
        const element = row?.original;
        return (
          <div className="d--flex flex--column justify-content--center align-items--center">
            <div>{element?.name}</div>
            {element?.is_active_caller_id === 1 ? (
              <span className="text--success">(Default Caller ID)</span>
            ) : (
              <></>
            )}
            <div>{formatPhoneNumber(element?.did_number)}&nbsp;</div>
          </div>
        );
      },

      meta: {
        textAlign: 'center',
        width: '12%',
      },
    },
    {
      accessorKey: 'renewal_date',
      header: () => 'Renewal Date',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'forward_call_actions',
      header: () => 'Forwarded To',
      cell: ({ row }) => {
        return (
          <GetForwardingData
            key={row.id}
            data={row?.original?.forward_call_actions}
            ringGroupListData={ringGroupListData}
            ivrListData={ivrListData}
            greetingListData={greetingListData}
            memberListData={memberListData}
          />
        );
      },
    },
    {
      accessorKey: 'forward_call_actions.call_record',
      header: () => 'Recording',
      cell: (props) => (props?.getValue() === 'N' ? 'No' : 'Yes'),
      meta: {
        textAlign: 'center',
        width: '5%',
      },
    },
    {
      accessorKey: 'forward_call_actions.business_hour_type',
      header: () => 'Business Hrs',
      cell: (props) => (props?.getValue() === '24h' ? '24 Hours' : 'Custom'),
      meta: {
        width: '5%',
      },
    },
    {
      accessorKey: 'forward_call_actions.greeting_filename',
      header: () => 'Greeting',
      cell: ({ row }) => {
        const element = row?.original ?? {};
        const { greeting_filename: filename = '' } =
          element?.forward_call_actions;
        const isPlaying =
          playingData?.playing && playingData?.file === filename;
        return (
          <span>
            {isPlaying ? (
              <span
                className="c--pointer"
                onClick={() => {
                  setPlayingData({ playing: false, file: null });
                }}
              >
                <HoldIcon width={20} height={20} />
                <AudioPlayer
                  type="greeting"
                  onClose={() => {
                    setPlayingData({ playing: false, file: null });
                  }}
                  name={filename}
                />
              </span>
            ) : (
              <span
                className="c--pointer text--success"
                onClick={() => {
                  setPlayingData({ playing: true, file: filename });
                }}
              >
                <PlayIcon width={20} height={20} />
              </span>
            )}
          </span>
        );
      },
      meta: {
        textAlign: 'center',
        width: '5%',
      },
    },
    {
      accessorKey: 'agency_name',
      header: () => 'Agency',
      // cell: ({ row, getValue }) => {
      //   const element = row?.original ?? {};
      //   return (
      //     <span>
      //       {getValue()} ({element?.agency_owner_name})
      //     </span>
      //   );
      // },
    },
    {
      accessorKey: 'get_assigned_users',
      header: () => 'Assigned To',
      cell: (props) => {
        const list = props.getValue() || [];
        return (
          <div className="w--full d--flex justify-content--start avtarGroup ellipsis--15">
            {list && list?.length > 0
              ? list?.map((member, index) => {
                  if (index == 3) {
                    const count = list?.length - 3;
                    return (
                      <div
                        className="avtarGroup--item c--pointer"
                        data-tooltip={`+${count}`}
                        tooltip-position="top"
                        onClick={() =>
                          setShowAssignedToMembers({
                            isShow: true,
                            listing: list,
                          })
                        }
                      >
                        <div
                          className={`text--primary overflow--hidden radius--full  bg--primary-100 w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center`}
                        >
                          <div
                            className={`d--flex radius--full align-items--center justify-content--center w-max--28 w-min--28  h-max--28 h-min--28`}
                            style={{
                              fontWeight: 600,
                              fontSize: `${28 / 2.3}px`,
                            }}
                          >
                            +{count}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (index > 3) {
                    return;
                  }
                  return (
                    <div>
                      {member?.agency_name
                        ? member?.agency_name
                        : member?.first_name}{' '}
                      {!member?.agency_name ? member?.last_name : ''}
                    </div>
                    // <div
                    //   className="avtarGroup--item"
                    //   data-tooltip={
                    //     member?.agency_name
                    //       ? member?.agency_name
                    //       : `${member?.first_name} ${member?.last_name}`
                    //   }
                    //   tooltip-position="top"
                    // >
                    //   <Avatar
                    //     size="28"
                    //     first_name={
                    //       member?.agency_name
                    //         ? member?.agency_name
                    //         : member?.first_name
                    //     }
                    //     last_name={member?.last_name}
                    //   />
                    // </div>
                  );
                })
              : '---'}
          </div>
        );
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const isForwardingData =
          element?.forward_call_actions &&
          Object.keys(element?.forward_call_actions);
        const dropDownOptions = [
          { title: 'Set Default Caller ID', value: 'default_did' },
          {
            title: `${isForwardingData ? 'Update' : 'Set'} Forwarding`,
            value: `${isForwardingData ? 'update' : 'set'}_forwarding`,
          },
        ];
        if (isForwardingData) {
          dropDownOptions.push({
            title: 'Remove Forwarding',
            value: 'remove_forwarding',
          });
        }
        const isOwner = [USER].includes(user?.role_id);
        const isAgencyOwner = [AGENCY_OWNER].includes(user?.role_id);
        const isAgencyAssigned = element?.agency_uuid ?? false;
        if (isAgencyOwner) {
          dropDownOptions.push({
            title: 'Settings',
            value: 'settings',
          });
        }
        if (isOwner && !isAgencyAssigned) {
          dropDownOptions.push({
            title: 'Settings',
            value: 'settings',
          });
        }
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            {ACCESS ? (
              element?.agency_uuid && user?.role_id === 4 ? (
                <Dropdown
                  extraClasses="w-min--150"
                  closeOnClickOutside={true}
                  dropList={{
                    component: ({ item }) => (
                      <div
                        className="w--full d--flex  justify-content--start p--2sm"
                        onClick={() => handleDropListClick(item, element)}
                      >
                        {item?.title}
                      </div>
                    ),
                    data: dropDownOptions,
                  }}
                  showcaret={false}
                >
                  <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                    <MoreVIcon width={13} height={13} />
                  </div>
                </Dropdown>
              ) : element?.agency_uuid ? (
                <Dropdown
                  extraClasses="w-min--150"
                  closeOnClickOutside={true}
                  dropList={{
                    component: ({ item }) => (
                      <div
                        className="w--full d--flex  justify-content--start p--2sm"
                        onClick={() => handleDropListClick(item, element)}
                      >
                        {item?.title}
                      </div>
                    ),
                    data: [{ title: 'Unassign DID', value: 'unassign_did' }],
                  }}
                  showcaret={false}
                >
                  <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                    <MoreVIcon width={15} height={15} />
                  </div>
                </Dropdown>
              ) : (
                <Dropdown
                  extraClasses="w-min--150"
                  closeOnClickOutside={true}
                  dropList={{
                    component: ({ item }) => (
                      <div
                        className="w--full d--flex  justify-content--start p--2sm"
                        onClick={() => handleDropListClick(item, element)}
                      >
                        {item?.title}
                      </div>
                    ),
                    data: dropDownOptions,
                  }}
                  showcaret={false}
                >
                  <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                    <MoreVIcon width={15} height={15} />
                  </div>
                </Dropdown>
              )
            ) : null}
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];
  const tableActions = [
    {
      id: 1,
      access: [USER].includes(user?.role_id),
      component: (
        <FormSelect
          height="32"
          value={filterAgencyUuid}
          options={agencyListing || []}
          onChange={(e) => setFilterAgencyUuid(e.target.value)}
          renderOption={({ item }) => {
            return (
              <option key={item?.uuid} value={item?.uuid}>
                {item?.user?.agency_name}
              </option>
            );
          }}
          placeholder="Select Agency"
        />
      ),
    },
    {
      id: 1,
      label: 'Port Number',
      access: [USER].includes(user?.role_id),
      component: (
        <Link to="porting-requests">
          <Button
            type="button"
            btnClasses="btn w-min--150"
            variant="primary"
            color="white"
            size="sm"
          >
            Port Number
          </Button>
        </Link>
      ),
    },
    {
      id: 2,
      label: 'Buy Virtual Number',
      access: [USER].includes(user?.role_id),
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setShowBuyVirtualNumber(true)}
          size="sm"
        >
          Buy Virtual Number
        </Button>
      ),
    },
  ];
  return (
    <div className="h--full w--full virtualNumberPage">
      <TableManager
        {...{
          fetcherKey: 'didList',
          fetcherFn: didList,
          columns,
          tableActions,
          name: 'Virtual Numbers',
          extraParams: {
            all_did: 'Y',
            filter: filterAgencyUuid
              ? [{ key: 'agency_uuid', value: filterAgencyUuid }]
              : [],
          },
          shouldShowTotalCount: true,
        }}
      />
      {showBuyVirtualNumber && (
        <AsideModal
          handleClose={() => setShowBuyVirtualNumber(false)}
          title="Buy Virtual Number"
          footerComponent={null}
        >
          <BuyNumberForm handleClose={() => setShowBuyVirtualNumber(false)} />
        </AsideModal>
      )}
      {showSettingsModal?.show && (
        <AsideModal
          handleClose={() => setShowSettingsModal({ show: false, data: null })}
          title="Settings"
          footerComponent={null}
        >
          <SettingsModal
            handleClose={() =>
              setShowSettingsModal({ show: false, data: null })
            }
            showSettingsModal={showSettingsModal}
          />
        </AsideModal>
      )}
      {showAssignedToMembers?.isShow && (
        <Modal
          handleClose={() =>
            setShowAssignedToMembers({ isShow: false, listing: [] })
          }
          title="Assigned To"
          footerComponent={null}
        >
          <div className="w--full table--responsive bg--white  w--full  radius--sm bg--white p-t--0 p-b--xs  p-l--xs  p-r--xs">
            <table className="table border-bottom--black-100 ">
              <tbody>
                {showAssignedToMembers.listing?.length > 0 ? (
                  showAssignedToMembers.listing?.map((member) => {
                    const firstName = member?.agency_uuid
                      ? member?.agency_name
                      : member?.first_name;
                    return (
                      <tr>
                        <td className="d--flex gap--md align-items--center">
                          <Avatar
                            first_name={firstName}
                            last_name={member?.last_name || ''}
                            extension={member?.extension}
                          />
                          <div>
                            {firstName} {member?.last_name || ''}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={1} style={{ textAlign: 'center' }}>
                      Not assigned
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </div>
  );
}
