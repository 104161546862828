import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../comopnents/Button';
import PaymentForm from '../../PaymentForm';
import { useQuery } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import { getSavedCards } from '../../../api';
import flags from 'react-phone-number-input/flags';
import useDidNumberPurchaseNew from '../../../hooks/useDidNumberPurchaseNew';

const BuyConfirmationModal = ({
  handleClose = () => null,
  handleSuccess = () => null,
  selectedDidData = {},
}) => {
  const { showAlert } = useAlert();
  const [tab, setTab] = useState(0);
  const [saveCard, setSaveCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');
  const confirmButtonRef = useRef(null);

  const { data: cardListing = [] } = useQuery({
    queryFn: getSavedCards,
    queryKey: ['getSavedCards'],
    select: (data) => data?.data?.data || [],
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
    retry: false,
  });

  useEffect(() => {
    if (cardListing && cardListing.length > 0) {
      const primaryCardData =
        cardListing?.find((item) => item?.is_primary) || null;
      if (primaryCardData) {
        setSelectedCard(primaryCardData?.card_id);
      } else {
        setSelectedCard(cardListing?.[0]?.card_id);
      }
    }
  }, [cardListing?.length]);

  const { mutate: didPurchaseMutate, isLoading: didPurchaseLoad } =
    useDidNumberPurchaseNew(handlePurchaseSucces);

  function handlePurchaseSucces() {
    handleClose();
    handleSuccess();
  }

  return (
    <div className="h--full d--flex flex--column gap--lg w--full">
      <h2 className="font--2xl font--600 text--c">
        {selectedDidData?.phone_number}
      </h2>
      <div className="d--flex text--sm gap--xs align-items--center justify-content--center">
        <div className="d--flex gap--sm align-items--center">
          <span className="w-min--28 w-max--28 d--flex">
            {flags['US']({ title: 'US' })}
          </span>
          United States
        </div>
        /<div>{selectedDidData?.state}</div>
        <div style={{ textTransform: 'capitalize' }}>
          {selectedDidData?.region_information
            ?.find((item) => item?.region_type === 'location')
            ?.region_name?.toLowerCase()}
        </div>
      </div>
      <div className="w--full d--flex align-items--center justify-content--center">
        <p className="text--sm text--grey  text--c w-max--300">
          You&apos;re about to purchase this Nextere number. Nextere number are
          subscription based.
        </p>
      </div>
      <h3 className=" d--flex  align-items--center justify-content--center text--c d--flex font--xl ">
        ${selectedDidData?.cost}
        <span className="text--black-400 d--flex font--xl ">/Month</span>
      </h3>

      <div className="w--full d--flex align-items--center justify-content--center">
        <div className="w--full d--flex align-items--center justify-content--center gap--xs w-max--300 border-full--black-100 p--xs  bg--white radius--sm">
          <Button
            variant={`${tab === 0 ? 'secondary btn' : 'primary-100 btn'}`}
            color={`${tab === 0 ? 'white' : 'primary'}`}
            onClick={() => setTab(0)}
          >
            New Card
          </Button>
          <Button
            variant={`${tab === 1 ? 'secondary btn' : 'primary-100 btn'}`}
            color={`${tab === 1 ? 'white' : 'primary'}`}
            onClick={() => setTab(1)}
          >
            Saved Cards
          </Button>
        </div>
      </div>

      <div className="w--full h-min--200 h-max--200 overflow--auto">
        {tab === 0 ? (
          <>
            <PaymentForm
              type="new-card"
              ref={confirmButtonRef}
              selectedDidData={selectedDidData}
              actionFn={didPurchaseMutate}
            />
            <div className="w--full d--flex gap--sm text--grey m-t--md">
              <input
                type="checkbox"
                className="form--control"
                name="saveCard"
                onChange={(e) => setSaveCard(e.target.checked)}
              />
              Save this card for future transactions
            </div>
          </>
        ) : (
          <>
            {cardListing?.length > 0
              ? cardListing?.map((item) => {
                  return (
                    <label
                      // onClick={() => setSelectedCard(item.card_id)}
                      key={item.card_id}
                      className="label--control font--sm font--500 m-b--xs d--flex w--full d--flex align-items--center justify-content--between h-min--40 border-bottom--black-100 p-l--xs p-r--xs"
                    >
                      **** **** **** {item.last4}
                      <input
                        id={item?.card_id}
                        className="form--control"
                        name="cards"
                        value={item?.card_id}
                        checked={item?.card_id === selectedCard}
                        onChange={(e) => setSelectedCard(e.target.value)}
                        type="radio"
                      />
                    </label>
                  );
                })
              : 'No saved card found!'}
          </>
        )}
      </div>

      <div className="d--flex gap--md">
        <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="button"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          onClick={() => {
            const city = selectedDidData?.region_information?.find(
              (item) => item?.region_type === 'location',
            )?.region_name;
            if (tab === 0) {
              confirmButtonRef?.current?.handleSubmit({
                paymentType: tab === 0 ? 'new-card' : 'saved-card',
                saveCard: saveCard ? true : false,
              });
            } else {
              didPurchaseMutate({
                // sku_id: selectedDidData?.sku_id,
                // did_id: selectedDidData?.id,
                // amount: selectedDidData?.price?.[0]?.cost,
                // period: selectedDidData?.price?.[0]?.period,
                did_city: city,
                did_number: selectedDidData?.phone_number,
                request_type: 'saved-card',
                card_id: selectedCard,
              });
            }
          }}
        >
          {didPurchaseLoad ? 'Please wait...' : 'Proceed for payment'}
        </Button>
      </div>
    </div>
  );
};

export default BuyConfirmationModal;
