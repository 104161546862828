import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useIcons from '../../../assets/icons/useIcons';
import IncomingMessageItem from './IncomingMessageItem';
import OutgoingMessageItem from './OutgoingMessageItem';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getSMSListing } from '../../../api';
import { useAlert } from '../../../hooks/useAlert';
import EmojiPicker from 'emoji-picker-react';
import useSendSMS from '../../../hooks/useSendSMS';
import useClickOutside from '../../../hooks/useClickOutside';
import moment from 'moment';
import useAllNumberDetails from '../../../hooks/useAllNumberDetails';
import Avatar from '../../../comopnents/Avatar';
import Button from '../../../comopnents/Button';
import Spinner from '../../../comopnents/Spinner';
import { AGENCY_OWNER, USER } from '../../../helpers/functions';
import { useAuth } from '../../../hooks/useAuth';
import useUploadMedia from '../../../hooks/useUploadMedia';

const ChatBody = ({ activeChat = '', smsDefaultDID }) => {
  const isMouseScrolled = useRef(false);
  const { user } = useAuth();
  const { mutateAsync: getUploadUrl } = useUploadMedia();
  const [isSendingSMS, setIsSendingSMS] = useState(false);
  const { getNumberName } = useAllNumberDetails();
  const emojiPickerRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [text, setText] = useState('');
  const [attachmentFile, setAttachmentFile] = useState(null);
  const lastElementRef = useRef(null);
  const { SmileIcon, SendIcon, RefreshIcon, PinIcon, CrossRoundIcon } =
    useIcons();
  const { showAlert } = useAlert();
  const observer = useRef(null);
  const [topRecordId, setTopRecordId] = useState(null);
  const [scrollPos, setScrollPos] = useState(0);

  const {
    data: smsList = [],
    isFetchingNextPage,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [`getSMSListing`, activeChat?.to, activeChat?.from],
    queryFn: ({ pageParam, queryKey }) =>
      getSMSListing({
        to: queryKey?.[1] ?? '',
        from: queryKey?.[2] ?? '',
        direction: activeChat?.direction,
        page: pageParam || 1,
        limit: 50,
      }),
    initialPageParam: 1,
    getNextPageParam: (data) => {
      const currentPage = data?.data?.currentPage;
      const lastPage = data?.data?.lastPage;
      if (currentPage < lastPage) {
        return currentPage + 1;
      } else {
        return null;
      }
    },
    enabled: Boolean(activeChat?.id),
    select: (data) => {
      const flatData =
        data?.pages
          ?.map((page) => {
            return page?.data?.data;
          })
          ?.flat()
          ?.reverse() ?? [];
      return flatData;
    },
    cacheTime: 30000,
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });

  const handleScroll = () => {
    const parent = document.getElementById('chat-content-box');
    const parentElm = document.getElementById('chatContent-flow');
    let firstRecord;
    for (let index = 0; index < parent.childNodes.length; index++) {
      const element = parent.childNodes[index];
      if (element?.id) {
        firstRecord = element;
        break;
      }
    }
    if (firstRecord) {
      setTopRecordId(firstRecord.id);
      const scrollPositionFromBottom =
        parentElm.scrollHeight - parentElm.scrollTop - parentElm.clientHeight;
      setScrollPos(scrollPositionFromBottom);
    }
  };

  useLayoutEffect(() => {
    if (topRecordId) {
      const topRecord = document.getElementById(topRecordId);
      if (topRecord && isMouseScrolled.current) {
        const parentElm = document.getElementById('chatContent-flow');
        parentElm.scrollTop = scrollPos;
      }
    }
  }, [smsList, topRecordId]);

  const firstItemRef = React.useCallback(
    (node) => {
      if (!node) return;
      if (observer.current) observer.current?.disconnect();
      observer.current = new IntersectionObserver((nodes) => {
        if (nodes[0].isIntersecting) {
          if (
            hasNextPage &&
            !isFetchingNextPage &&
            !isFetching &&
            isMouseScrolled.current
          )
            fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasNextPage, isFetchingNextPage],
  );

  const { mutate: sendSMSMutate, isLoading } = useSendSMS({
    handleSuccess() {
      refetch();
      setText('');
      setAttachmentFile(null);
    },
  });

  useClickOutside([emojiPickerRef], () => setShowEmojiPicker(false));

  useEffect(() => {
    document
      ?.getElementById('chat-content-box')
      ?.addEventListener('wheel', setIsMouseScroll);

    return () => {
      // Detect mouse scroll
      document
        ?.getElementById('chat-content-box')
        ?.removeEventListener('wheel', setIsMouseScroll);
      // Reset mouse scroll flag on mouse up
    };
  }, []);

  function setIsMouseScroll() {
    isMouseScrolled.current = true;
  }
  useLayoutEffect(() => {
    if (lastElementRef?.current?.id) {
      document
        .getElementById(lastElementRef?.current?.id)
        .scrollIntoView({ behavior: 'auto' });
    }
  }, [lastElementRef?.current?.innerText]);

  const handleSendSMS = async () => {
    if (!text && !attachmentFile) return;
    setIsSendingSMS(true);
    if (attachmentFile) {
      const payload = {
        uuid: [USER, AGENCY_OWNER].includes(user?.role_id)
          ? user?.uuid
          : user?.parent_uuid || user?.uuid,
        file_name:
          attachmentFile?.name ||
          `attachment_file.${attachmentFile?.name?.split('.')?.[1]}`,
        type: 'sms_media',
      };

      const res = await getUploadUrl(payload);
      const { filename, url: uploadUrl } = res?.data?.data || '';
      if (uploadUrl) {
        const uploadFileResponse = await fetch(uploadUrl, {
          method: 'PUT',
          body: attachmentFile,
        });
        if (uploadFileResponse.status === 200) {
          const payload = {
            from: smsDefaultDID,
            to:
              activeChat?.direction === 'Outbound'
                ? activeChat?.to
                : activeChat?.from,
            text: text,
            media: `${process.env.REACT_APP_MEDIA_URL}signed-url/${
              user?.parent_uuid || user?.uuid
            }/sms_media?filename=${filename}`,
            media_type: attachmentFile?.type?.includes('image')
              ? 'image'
              : attachmentFile?.type?.includes('audio')
              ? 'audio'
              : attachmentFile?.type?.includes('video')
              ? 'video'
              : 'file',
            type: 'MMS',
          };
          sendSMSMutate(payload);
        }
      }
    } else {
      const payload = {
        from: smsDefaultDID,
        to:
          activeChat?.direction === 'Outbound'
            ? activeChat?.to
            : activeChat?.from,
        text: text,
        media: '',
        media_type: '',
        type: 'SMS',
      };
      sendSMSMutate(payload);
    }
    setIsSendingSMS(false);
  };

  function handleFileSelect(e) {
    setAttachmentFile(e.target.files[0]);
    e.target.value = '';
  }
  const first_name = getNumberName(
    activeChat?.direction === 'Outbound' ? activeChat?.to : activeChat?.from,
  )
    .split(' ')
    .at(0);
  const last_name = getNumberName(
    activeChat?.direction === 'Outbound' ? activeChat?.to : activeChat?.from,
  )
    .split(' ')
    .at(1);

  return (
    <>
      <div className="chatHead h--full h-max--60 border-bottom--black-100 d--flex p-t--md p-b--md p-l--md p-r--md">
        <div className="d--flex gap--sm  align-items--center w--full ">
          {first_name?.includes('+') || last_name?.includes('+') ? (
            <Avatar
              first_name={'N'}
              last_name={'A'}
              shouldShowPresence={false}
            />
          ) : (
            <Avatar
              first_name={first_name}
              last_name={last_name}
              shouldShowPresence={false}
            />
          )}
          <div className="d--flex align-items--center m-l--md">
            <div className="w--full d--flex flex--column  gap--xs">
              <div
                className="w--full font--sm font--500"
                style={{ textTransform: 'capitalize' }}
              >
                {getNumberName(
                  activeChat?.direction === 'Outbound'
                    ? activeChat?.to
                    : activeChat?.from,
                )}
              </div>
              {activeChat?.direction === 'Outbound'
                ? activeChat?.to
                : activeChat?.from}
            </div>
            {(isFetchingNextPage || isFetching) && <Spinner />}
          </div>
        </div>
        <div className="w--full w--max--300 d--flex justify-content--end align-items--center">
          <div className="text--black-600 c--pointer w--full font--xs font--300 w-min--36 w-max--36 h-min--36 h-max--36 radius--sm d--flex align-items--center justify-content--center">
            <Button
              variant="white"
              btnClasses="btn w-max--36 box-shadow--xs"
              color="primary"
              type="button"
              icon={<RefreshIcon width={20} height={20} />}
              onClick={() => refetch()}
              size="sm"
              data-tooltip="Refresh"
              tooltip-position="top"
            />
          </div>
          {/* <div className="text--black-600 c--pointer w--full font--xs font--300 w-min--36 w-max--36 h-min--36 h-max--36 radius--sm d--flex align-items--center justify-content--center">
            <SearchIcon width={20} height={20} />
          </div>
          <div className="text--black-600 c--pointer w--full font--xs font--300 w-min--36 w-max--36 h-min--36 h-max--36 radius--sm d--flex align-items--center justify-content--center">
            <CallIcon width={20} height={20} />
          </div>
          <div className="text--black-600 c--pointer w--full font--xs font--300 w-min--36 w-max--36 h-min--36 h-max--36 radius--sm d--flex align-items--center justify-content--center">
            <MoreVIcon width={16} height={16} />
          </div> */}
        </div>
      </div>
      <div
        id="chatContent-flow"
        className="chatContent overflow--auto h--full p-t--md p-b--md p-l--xl p-r--xl position--relative"
        onScroll={handleScroll}
      >
        {/* <div
          className="dayItem m-b--xs  w-max--100 text--black radius--xs p-t--xs p-b--xs p-l--md p-r--md bg--black-50 font--xs border-full--black-50 position--sticky z-index--sm text--c"
          id="date-time-label-box"
        >
          Today
        </div> */}
        <div id="chat-content-box">
          {smsList &&
            smsList.length > 0 &&
            smsList.map((sms, index, arr) => {
              const currentSMSDate = moment(sms?.created_at);
              const nextSMSDate = arr?.[index + 1]
                ? moment(arr?.[index + 1]?.created_at)
                : null;
              let isSameDate = true;
              if (nextSMSDate && currentSMSDate) {
                isSameDate = currentSMSDate.isSame(nextSMSDate, 'day');
              }
              if (sms?.direction === 'Outbound') {
                return (
                  <React.Fragment key={sms?.uuid}>
                    {index === 0 && (
                      <div className="w--full d--flex justify-content--center">
                        <div
                          className="dayItem m-b--xs  w-max--100 text--black radius--xs p-t--xs p-b--xs p-l--md p-r--md bg--black-50 font--xs border-full--black-50 z-index--sm text--c"
                          id="date-time-label-box"
                        >
                          {currentSMSDate?.format('DD MMMM')}
                        </div>
                      </div>
                    )}
                    <OutgoingMessageItem
                      sms={sms}
                      lastElementRef={
                        arr.length - 1 === index ? lastElementRef : null
                      }
                      firstItemRef={index === 0 ? firstItemRef : null}
                    />
                    {!isSameDate && (
                      <div className="w--full d--flex justify-content--center">
                        <div
                          className="dayItem m-b--xs  w-max--100 text--black radius--xs p-t--xs p-b--xs p-l--md p-r--md bg--black-50 font--xs border-full--black-50 z-index--sm text--c"
                          id="date-time-label-box"
                        >
                          {nextSMSDate?.format('DD MMMM')}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              } else {
                return (
                  <>
                    {index === 0 && (
                      <div className="w--full d--flex justify-content--center">
                        <div
                          className="dayItem m-b--xs  w-max--100 text--black radius--xs p-t--xs p-b--xs p-l--md p-r--md bg--black-50 font--xs border-full--black-50 z-index--sm text--c"
                          id="date-time-label-box"
                        >
                          {currentSMSDate?.format('DD MMMM')}
                        </div>
                      </div>
                    )}
                    <IncomingMessageItem
                      sms={sms}
                      lastElementRef={
                        arr.length - 1 === index ? lastElementRef : null
                      }
                      firstItemRef={index === 0 ? firstItemRef : null}
                    />
                    {!isSameDate && (
                      <div className="w--full d--flex justify-content--center">
                        <div
                          className="dayItem m-b--xs  w-max--100 text--black radius--xs p-t--xs p-b--xs p-l--md p-r--md bg--black-50 font--xs border-full--black-50 z-index--sm text--c"
                          id="date-time-label-box"
                        >
                          {nextSMSDate?.format('DD MMMM')}
                        </div>
                      </div>
                    )}
                  </>
                );
              }
            })}
        </div>
      </div>
      <div className="chatFooter position--relative h--full h-max--60 border-top--black-100 p-t--sm p-b--sm p-l--md p-r--md d--flex gap--sm">
        <div
          className="text--black-600 c--pointer w--full font--xs font--300 w-min--36 w-max--36  radius--sm d--flex align-items--center justify-content--center"
          onClick={() => setShowEmojiPicker((prev) => !prev)}
        >
          <SmileIcon width={20} height={20} />
        </div>
        {showEmojiPicker && (
          <div className="position--absolute smileBox" ref={emojiPickerRef}>
            <EmojiPicker
              onEmojiClick={(emojiObject) =>
                setText((prev) => prev + emojiObject.emoji)
              }
              pickerStyle={{
                width: '100%',
                maxWidth: '350px',
                height: '100%',
                minHeight: '400px',
                maxHeight: '480px',
              }}
            />
          </div>
        )}
        <div className="w--full position--relative">
          {attachmentFile && (
            <AttachmentPreview
              attachmentFile={attachmentFile}
              isSendingSMS={isSendingSMS}
              isLoading={isLoading}
              setAttachmentFile={setAttachmentFile}
            />
          )}
          <textarea
            className="form--control w--full radius--sm p-t--md p-b--sm p-r--sm p-l--sm  border--0 h-min--36"
            value={text}
            onChange={(e) => setText(e.target.value)}
            name="text-message"
            rows="2"
            cols="100"
            placeholder="Type your message..."
          ></textarea>
        </div>
        <div className="d--flex gap--sm align-items--center">
          <label
            htmlFor="sms_attachment"
            className="text--black-600 c--pointer w--full font--xs font--300 w-min--36 w-max--36  radius--sm d--flex align-items--center justify-content--center"
            onChange={handleFileSelect}
          >
            <PinIcon width={20} height={20} />
            <input
              type="file"
              name="sms_attachment"
              id="sms_attachment"
              hidden
              accept="audio/*,video/*,image/*"
            />
          </label>
          {isSendingSMS || isLoading ? (
            <Spinner size="md" />
          ) : (
            <div
              className="c--pointer w--full font--xs font--300 w-min--36 w-max--36 h-min--36 h-max--36 bg--primary text--white  radius--full d--flex align-items--center justify-content--center"
              onClick={() => !isSendingSMS && !isLoading && handleSendSMS()}
            >
              <SendIcon width={20} height={20} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatBody;

const AttachmentPreview = React.memo(
  ({ attachmentFile, isSendingSMS, isLoading, setAttachmentFile }) => {
    const { CrossRoundIcon } = useIcons();

    return (
      <div
        className={`radius--sm border border-full--black-300 bg--white position--absolute p--md d--flex flex--column gap--sm `}
        style={{ bottom: '60px' }}
      >
        {(isSendingSMS || isLoading) && (
          <div
            className="d--flex position--absolute"
            style={{
              transform: 'translate(-50%,-50%)',
              top: '50%',
              left: '50%',
            }}
          >
            <Spinner size="md" />
          </div>
        )}
        <div
          className="d--flex position--absolute c--pointer"
          onClick={() => setAttachmentFile(null)}
          style={{
            top: '0',
            right: '0',
          }}
        >
          <CrossRoundIcon />
        </div>
        <h3
          className={`text--black m-b--sm font--400 ${
            isSendingSMS || isLoading ? 'opacity--sm' : ''
          }`}
        >
          Attachment File
        </h3>
        {attachmentFile?.type?.includes('image') && (
          <img
            className={`w-max--300 w-min--300 h-max--200 h-min--200 radius--sm bg--black-300 object--contain overflow--hidden ${
              isSendingSMS || isLoading ? 'opacity--sm' : ''
            }`}
            src={attachmentFile ? URL.createObjectURL(attachmentFile) : null}
            alt=""
          />
        )}
        {attachmentFile?.type?.includes('video') && (
          <video
            className={`w-max--300 w-min--300 h-max--200 h-min--200 radius--sm bg--black-300 object--contain overflow--hidden ${
              isSendingSMS || isLoading ? 'opacity--sm' : ''
            }`}
            src={attachmentFile ? URL.createObjectURL(attachmentFile) : null}
            controls
          />
        )}
        {attachmentFile?.type?.includes('audio') && (
          <audio
            src={attachmentFile ? URL.createObjectURL(attachmentFile) : null}
            controls
            className={`${isSendingSMS || isLoading ? 'opacity--sm' : ''}`}
          />
        )}
      </div>
    );
  },
);
