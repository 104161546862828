import { useContext, useState } from 'react';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import useIcons from '../../../assets/icons/useIcons';

const TransferBtnComponent = ({ ext, isAttended, activeCall }) => {
  const { TranferIcon, SpeakerPhoneIcon, OutgoingCall } = useIcons();
  const { socket } = useContext(SipSocketContext);
  const {
    _referUser = () => null,
    _sendDtmf = () => null,
    _terminate = () => null,
  } = socket;
  const [shouldTransfer, setshouldTransfer] = useState(false);
  const [speakFirst, setSpeakFirst] = useState(false);

  const transferHandler = (ext) => {
    if (!ext) return;

    if (isAttended) {
      setshouldTransfer(true);
    } else {
      _referUser(activeCall?.callID, `${ext}`);
    }
  };

  const handleSpeakFirst = (ext) => {
    if (!ext) return;
    setSpeakFirst(true);
    _sendDtmf(activeCall?.callID, `*1${ext}#`);
  };

  const handleTransferNow = (ext) => {
    if (!ext) return;
    _referUser(activeCall?.callID, `${ext}`, true);
  };

  return speakFirst ? (
    <div
      data-tooltip="Transfer now"
      tooltip-position="left"
      className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--sm d--flex align-items--center justify-content--center text--primary bg--contrast  c--pointer"
      onClick={() => _terminate(activeCall?.callID)}
    >
      <TranferIcon />
      {/* Transfer now */}
    </div>
  ) : shouldTransfer ? (
    <span className="d--flex gap--sm">
      {/* <div
        data-tooltip="Speak first"
        tooltip-position="left"
        className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--sm d--flex align-items--center justify-content--center text--primary bg--contrast  c--pointer"
        onClick={() => handleSpeakFirst(ext)}
      >
        <SpeakerPhoneIcon />
      </div> */}
      <div
        data-tooltip="Transfer now"
        tooltip-position="left"
        className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--sm d--flex align-items--center justify-content--center text--primary bg--contrast  c--pointer"
        onClick={() => handleTransferNow(ext)}
      >
        <OutgoingCall />
        {/* Transfer now */}
      </div>
    </span>
  ) : (
    <div
      data-tooltip="Transfer call"
      tooltip-position="left"
      className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--sm d--flex align-items--center justify-content--center text--primary bg--contrast  c--pointer"
      onClick={() => transferHandler(ext)}
    >
      <TranferIcon />
      {/* Transfer call */}
    </div>
  );
};

export default TransferBtnComponent;
