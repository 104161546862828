import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import FormInput from '../../../../comopnents/FormInput';
import FormSelect from '../../../../comopnents/FormSelect';
import AudioPlayer from '../../../../comopnents/AudioPlayer';
import useIcons from '../../../../assets/icons/useIcons';
import MultiDropDown from '../../../../comopnents/MultiDropdown';
import ForwardToInput from '../../../../comopnents/ForwardToInput';
import SingleDropdownMain from '../../../../comopnents/SingleDropdownMain';
import AsideModal from '../../../../comopnents/AsideModal';
import AddGreetingForm from '../../CustomGreetings/AddGreetingForm';

const ringingTimeOptions = ['15', '20', '25', '30', '45'];
const forwardingOptions = [
  'Extension',
  'Number',
  'IVR',
  'Voicemail',
  'Department Voicemail',
  'Hangup',
];

const RenderGreetingItem = ({ item, onChange, setSearchValue, setShow }) => {
  return (
    <div
      className="text--black h-min--32 d--flex align-items--center justify-content--start p--sm w--full"
      key={item.filename}
      onClick={() => {
        setSearchValue('');
        onChange(item.filename);
        setShow(false);
      }}
    >
      {item.name}
    </div>
  );
};
const RenderRingingTimeOption = ({ item }) => {
  return (
    <option className="text--black c--pointer" key={item} value={item}>
      {item}
    </option>
  );
};

const Basic = ({
  watch,
  control,
  errors,
  register,
  setValue,
  memberListAll,
  greetingList,
  greetingListLoad = false,
  memberListLoad = false,
  ringGroupListLoad = false,
  ivrListLoad = false,
  ringGroupListData,
  ivrListData,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [addGlobalGreeting, setAddGlobalgreeting] = useState(false);
  const { HoldIcon, PlayIcon, PlusIcon } = useIcons();

  function handleClose() {
    setAddGlobalgreeting(false);
  }

  const isGreeting = watch('greeting');

  return (
    <>
      <form
        className="w--full h--full d--flex flex--column gap--lg"
        autoComplete="off"
      >
        <div>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="Name"
                placeholder="Enter Name"
                error={errors?.title?.message}
              />
            )}
          />
        </div>
        <div className="w--full d--flex flex--column">
          <label className="label--control font--sm font--500 m-b--xs  text--black d--flex align-items--center gap--sm ">
            Greeting (optional)
            <span
              className="bg--primary-100 text--primary radius--full w-max--20 w-min--20  h-max--20 h-min--20 d--flex align-items--center justify-content--center c--pointer"
              data-tooltip={'Add New Greeting'}
              tooltip-position="right"
              onClick={() => setAddGlobalgreeting(true)}
            >
              <PlusIcon width={10} height={10} />
            </span>
          </label>
          <div className="d--flex align-items--center gap--md">
            <Controller
              name="greeting"
              control={control}
              render={({ field }) => (
                <SingleDropdownMain
                  {...field}
                  labelKey={'name'}
                  valueKey="filename"
                  optionList={
                    greetingList?.filter(
                      (greeting) => greeting?.type === 'greeting',
                    ) || []
                  }
                  component={RenderGreetingItem}
                  optionListLoad={greetingListLoad}
                  error={errors?.greeting?.message}
                />
              )}
            />
            {isGreeting && (
              <>
                {isPlaying ? (
                  <div
                    className="c--pointer"
                    onClick={() => {
                      setIsPlaying(false);
                    }}
                  >
                    <HoldIcon width={20} height={20} />
                    <AudioPlayer
                      onClose={() => {
                        setIsPlaying(false);
                      }}
                      type="greeting"
                      name={watch('greeting')}
                    />
                  </div>
                ) : (
                  <div
                    className="c--pointer d--flex h--full align-items--center text--success"
                    onClick={() => {
                      setIsPlaying(true);
                    }}
                  >
                    <PlayIcon width={20} height={20} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div>
          <Controller
            name="members"
            control={control}
            render={({ field }) => (
              <MultiDropDown
                {...field}
                labelKey={['first_name', 'last_name']}
                valueKey={'uuid'}
                label="Members"
                optionList={
                  memberListAll?.filter((item) => {
                    return item?.verified === 1 && item?.is_active === 1;
                  }) || []
                }
                optionListLoad={memberListLoad}
                placeholder={`${watch('members')?.length} member(s)`}
                error={errors?.members?.message}
                isMemberList={true}
              />
            )}
          />
        </div>
        <div>
          <div className="d--flex flex--column gap--md">
            <div className="d--flex align-items--center gap--md w--full">
              <label className="label--control font--sm font--500 m-b--xs  text--black w-min--150">
                Allow phone ringing for
              </label>
              <span className="w--full w-max--100">
                <Controller
                  name="timeout"
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      {...field}
                      options={ringingTimeOptions || []}
                      renderOption={RenderRingingTimeOption}
                      placeholder="Select Option"
                      error={errors?.timeout?.message}
                    />
                  )}
                />
              </span>
              <span>sec</span>
            </div>
            <div className="d--flex align-items--center gap--md w--full">
              <label className="label--control font--sm font--500 m-b--xs  text--black w-min--150">
                After that forward to
              </label>
              <div className="w--full">
                <ForwardToInput
                  control={control}
                  register={register}
                  watch={watch}
                  forwardOptions={forwardingOptions}
                  forward_type_key="timeout_type"
                  forward_value_key="timeout_value"
                  setValue={setValue}
                  memberListData={memberListAll}
                  memberListLoad={memberListLoad}
                  departmentList={ringGroupListData?.data?.data || []}
                  ringGroupListLoad={ringGroupListLoad}
                  ivrList={ivrListData?.data?.data || []}
                  ivrListLoad={ivrListLoad}
                  greetingList={greetingList}
                  greetingListLoad={greetingListLoad}
                />
                <label
                  className={`label--control font--sm font--500 m-b--xs text--danger`}
                >
                  {errors?.timeout_type?.message ||
                    errors?.timeout_value?.message}
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>

      {addGlobalGreeting ? (
        <AsideModal
          handleClose={handleClose}
          title={'Add Greeting'}
          footerComponent={null}
        >
          <AddGreetingForm handleClose={handleClose} type={'greeting'} />
        </AsideModal>
      ) : null}
    </>
  );
};

export default Basic;
