import { useMutation } from '@tanstack/react-query';
import { checkFreeDID } from '../../api';
import { useAlert } from '../useAlert';

export default function useCheckFreeDid(fn = () => null) {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: checkFreeDID,
    mutationKey: 'checkFreeDID',
    onSuccess: ({ data }) => {
      fn();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
