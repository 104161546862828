import React, { useState } from 'react';
import useIcons from '../../../../assets/icons/useIcons';
import moment from 'moment';
import { capitalizeWords, generateUUID } from '../../../../helpers/functions';
import { Popover } from '@mui/material';
import { useAuth } from '../../../../hooks/useAuth';

// border-full--${
//   borderLookup[
//     isActivityItemExist
//       ? isAllOffline
//         ? 'offline'
//         : 'online'
//       : activityType
//   ]
// }

const colorLookup = {
  online: 'success',
  offline: 'danger',
  device_online: 'success',
  device_offline: 'danger',
  call_start: 'success',
  call_end: 'danger',
  voicemail: 'primary',
  voicemailgroup: 'primary',
  missedcall: 'danger',
};

const ActivityIcon = ({ activity }) => {
  const {
    CallIcon,
    DesktopIcon,
    VoicemailGroupIcon,
    VoicemailIcon,
    MissedCall,
    IncomingCall,
    OutgoingCall,
    BrowserIcon,
    MobileIcon,
  } = useIcons();
  const isDesktopDevice = activity?.data?.device_type === 'desktop';
  const isMobileDevice = activity?.data?.device_type === 'mobile';
  const isBrowser = activity?.data?.device_type === 'browser';
  const isCallEnd = activity?.activity === 'call_end';
  const isCallActivity =
    activity?.activity?.includes('call') && activity?.activity !== 'missedcall';
  const isOnlineActivity = activity?.activity === 'online';
  const isOfflineActivity = activity?.activity === 'offline';
  const isVoicemailActivity = activity?.activity === 'voicemail';
  const isVoicemailGroupActivity = activity?.activity === 'voicemailgroup';
  const isMissCallActivity = activity?.activity === 'missedcall';
  return (
    <>
      {isDesktopDevice && (
        <span className={`text--${colorLookup[activity?.activity]}`}>
          <DesktopIcon height={18} width={18} />
        </span>
      )}
      {isMobileDevice && (
        <span className={`text--${colorLookup[activity?.activity]}`}>
          <MobileIcon height={18} width={18} />
        </span>
      )}
      {isBrowser && (
        <span className={`text--${colorLookup[activity?.activity]}`}>
          <BrowserIcon height={18} width={18} />
        </span>
      )}
      {!activity?.data?.device_type &&
        (isOnlineActivity || isOfflineActivity) && (
          <span className={`text--${colorLookup[activity?.activity]}`}>
            <DesktopIcon height={18} width={18} />
          </span>
        )}
      {isCallActivity ? (
        isCallEnd ? (
          <span className={`text--${colorLookup[activity?.activity]}`}>
            <CallIcon height={18} width={18} />
          </span>
        ) : activity?.data?.Direction === 'initiator' ? (
          <span className={`text--${colorLookup['call_start']}`}>
            <OutgoingCall height={18} width={18} />
          </span>
        ) : (
          <span className={`text--${colorLookup['voicemailgroup']}`}>
            <IncomingCall height={18} width={18} />
          </span>
        )
      ) : null}
      {isVoicemailActivity && (
        <span className={`text--${colorLookup[activity?.activity]}`}>
          <VoicemailIcon height={18} width={18} />
        </span>
      )}
      {isVoicemailGroupActivity && (
        <span className={`text--${colorLookup[activity?.activity]}`}>
          <VoicemailGroupIcon height={18} width={18} />
        </span>
      )}
      {isMissCallActivity && (
        <span className={`text--${colorLookup[activity?.activity]}`}>
          <MissedCall height={18} width={18} />
        </span>
      )}
    </>
  );
};

const ToolTipItem = (props) => {
  const { user } = useAuth();

  const currentActivity = props?.list;
  const isDataAvailable =
    currentActivity?.data && Object.keys(currentActivity?.data).length > 0
      ? true
      : false;
  const isCallEndActivity = currentActivity?.activity === 'call_end';
  const isCallStartActivity = currentActivity?.activity === 'call_start';
  const isDeviceOnlineActivity =
    currentActivity?.activity === 'device_online' ||
    currentActivity?.activity === 'online';

  const isVoicemailActivity = currentActivity?.activity === 'voicemail';
  const isVoicemailGroupActivity =
    currentActivity?.activity === 'voicemailgroup';
  const isMissCallActivity = currentActivity?.activity === 'missedcall';

  const isDeviceOfflineActivity =
    currentActivity?.activity === 'device_offline' ||
    currentActivity?.activity === 'offline';

  return (
    <div className="bg--primary text--white p--md d--flex gap--sm flex--column">
      {/* Activity : {capitalizeWords(currentActivity?.activity, '_')} */}
      {isDataAvailable ? (
        <div className="d--flex gap--sm flex--column">
          {(isDeviceOnlineActivity || isDeviceOfflineActivity) && (
            <div style={{ textTransform: 'capitalize' }}>
              Device : {currentActivity?.data?.device_type}
            </div>
          )}
          {(isDeviceOnlineActivity || isDeviceOfflineActivity) &&
            currentActivity?.data?.device_type !== 'app' && (
              <div>Browser : {currentActivity?.data?.browser_version}</div>
            )}
          {(isDeviceOnlineActivity || isDeviceOfflineActivity) && (
            <div style={{ textTransform: 'capitalize' }}>
              OS : {currentActivity?.data?.os_version}
            </div>
          )}
          {isCallEndActivity || isCallStartActivity ? (
            <>
              {currentActivity?.data?.Direction !== 'initiator' ? (
                <div>
                  From :
                  {currentActivity?.data?.Direction === 'initiator'
                    ? currentActivity?.data?.['From-User']
                    : currentActivity?.data?.['To-User']}
                </div>
              ) : null}
            </>
          ) : null}

          {isCallEndActivity || isCallStartActivity ? (
            <>
              {currentActivity?.data?.Direction === 'initiator' ? (
                <div>
                  To :
                  {currentActivity?.data?.Direction === 'initiator'
                    ? currentActivity?.data?.['To-User']
                    : currentActivity?.data?.['From-User']}
                </div>
              ) : null}
            </>
          ) : null}

          {isMissCallActivity ? (
            <div>Status :{currentActivity?.data?.status}</div>
          ) : null}
          {isMissCallActivity ? (
            <div>From :{currentActivity?.data?.from}</div>
          ) : null}
          {isMissCallActivity ? (
            <div>
              Via :{currentActivity?.data?.didNumber}
              {currentActivity?.data?.didName
                ? `(${currentActivity?.data?.didName})`
                : null}
            </div>
          ) : null}
          {isVoicemailActivity || isVoicemailGroupActivity ? (
            <div>
              Via :
              {currentActivity?.data?.DID || currentActivity?.data?.didNumber}
              {currentActivity?.data?.DIDName || currentActivity?.data?.didName
                ? `(${
                    currentActivity?.data?.DIDName ||
                    currentActivity?.data?.didName
                  })`
                : null}
            </div>
          ) : null}
          {isVoicemailActivity || isVoicemailGroupActivity ? (
            <div>
              From :{currentActivity?.data?.From || currentActivity?.data?.from}
            </div>
          ) : null}

          <div>
            Time :{moment(currentActivity?.timestamp).format('hh:mm A') || ''}
          </div>
          {isMissCallActivity && currentActivity?.data?.status === 'online' ? (
            <div className="d--flex">
              <div
                className=" d--flex border-full--contrast align-items--center justify-content--center gap--sm radius--sm p--sm c--pointer w--full"
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_MEDIA_URL}signed-url/${
                      user?.parent_uuid || user?.uuid
                    }/call_screenshot_log?filename=${
                      currentActivity?.data?.callID ||
                      currentActivity?.data?.CallID
                    }_${currentActivity?.data?.extension}`,
                  )
                }
              >
                View Screenshot
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
const PopoverItem = ({
  activity,
  id = '',
  single = true,
  activityType = '',
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl((prev) => null);
  };
  return (
    <>
      {single ? (
        <>
          <span
            aria-owns={id}
            className="d--flex w-min--16 w-max--16 h-min--16 justify-content--center c--pointer"
            aria-haspopup="true"
            onClick={handlePopoverOpen}
            // onMouseEnter={handlePopoverOpen}
            // onMouseLeave={handlePopoverClose}
          >
            <span
              className={` d--flex align-items--center ${
                activity?.activity === 'call_end' ? 'rotate--135' : ''
              }`}
            >
              <ActivityIcon activity={activity} />
            </span>
          </span>
          <Popover
            id={id}
            // sx={{
            //   pointerEvents: 'none',
            // }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            // disableRestoreFocus
            // onMouseLeave={handlePopoverClose}
          >
            <ToolTipItem list={activity} />
          </Popover>
        </>
      ) : (
        <>
          <span className="d--flex flex--column">
            <span
              className="d--flex  w-min--20 w-max--20 h-min--17 justify-content--center align-items--center c--pointer"
              aria-owns={id}
              aria-haspopup="true"
              onClick={handlePopoverOpen}
              //   onMouseEnter={handlePopoverOpen}
              //   onMouseLeave={handlePopoverClose}
            >
              <span
                className={`text--${colorLookup[activityType]} d--flex align-items--center`}
              >
                <ActivityIcon activity={activity} />
              </span>
            </span>
            <Popover
              id={id}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              //   disableRestoreFocus
              //   onMouseLeave={handlePopoverClose}
            >
              <ToolTipItem list={activity} />
            </Popover>
            {activity?.activityItem?.map((ac) => {
              const uuid = generateUUID();
              return <InsideActivity activity={ac} id={uuid} />;
            })}
          </span>
        </>
      )}
    </>
  );
};

export default PopoverItem;

const InsideActivity = ({ activity, id }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span
        aria-owns={id}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        // onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
        className="d--flex  w-min--20 w-max--20 h-min--17 justify-content--center align-items--center c--pointer"
      >
        <span className={`text--${colorLookup[activity?.activity]} d--flex`}>
          <ActivityIcon activity={activity} />
        </span>
      </span>
      <Popover
        id={id}
        // sx={{
        //   pointerEvents: 'none',
        // }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        // disableRestoreFocus
        // onMouseLeave={handlePopoverClose}
      >
        <ToolTipItem list={activity} />
      </Popover>
    </>
  );
};
