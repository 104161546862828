import React, { useState } from 'react';
import FormInput from '../FormInput';
import Dropdown from '../Dropdown';
import useIcons from '../../assets/icons/useIcons';
import Avatar from '../Avatar';

const handleGetValue = (value, options = []) => {
  const reqObj =
    options?.filter((opt) => `${opt.extension}` === `${value}`)?.[0] ?? null;

  if (reqObj) {
    return `${reqObj?.first_name} ${reqObj.last_name}`;
  }
  return '';
};

const SingleDropdown = ({
  optionList = [],
  labelKey = 'first_name',
  label = '',
  placeholder = '',
  error = '',
  withCaret = false,
  border = true,
  extraClasses = '',
  paddingRight = 'md',
  paddingLeft = 'md',
  optionListLoad = false,
  ...rest
}) => {
  const { CaretIcon } = useIcons();
  const [searchValue, setSearchValue] = useState('');

  const dropListNotification = {
    component: ({ item }) => {
      return (
        <div
          onClick={() => {
            setSearchValue('');
            rest?.onChange(`${item?.extension}`);
          }}
          className="w--full p--sm"
        >
          <div className="d--flex align-items--center gap--md">
            <Avatar
              first_name={item?.first_name}
              last_name={item?.last_name}
              image={item?.profile_pic}
              extension={item?.extension}
              size="28"
            />
            <div>
              <div className="d--flex flex--column">
                {item?.first_name} {item?.last_name}
              </div>
              <div className="font--xs font--400 text--grey">
                EXT: {item?.extension}
              </div>
              <div className="font--xs font--400 text--grey">
                {item?.tenant_npn ? <> NPN: {item?.tenant_npn}</> : null}
              </div>
            </div>
          </div>
        </div>
      );
    },
    data: searchValue
      ? optionList.filter((option) =>
          option?.[labelKey]?.toLowerCase().includes(searchValue),
        ) || []
      : optionList,
  };
  return (
    <div className="w--full  d--flex flex--column">
      <Dropdown
        closeOnClickOutside={true}
        dropList={dropListNotification}
        showcaret={withCaret}
        caretComponent={CaretIcon}
        extraClasses="w--full"
      >
        <FormInput
          type="text"
          extraClasses={`w--full ${extraClasses}`}
          label={label}
          placeholder={
            optionListLoad
              ? 'Please wait...'
              : handleGetValue(`${rest?.value}`, optionList)
          }
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          error={error}
          border={border}
          paddingRight={paddingRight}
          paddingLeft={paddingLeft}
          disabled={optionListLoad}
        />
      </Dropdown>
    </div>
  );
};

export default SingleDropdown;
