import { useMutation } from '@tanstack/react-query';
import { getSettings } from '../../api';
import { useAlert } from '../useAlert';

export default function useGetSettings() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: getSettings,
    mutationKey: 'getSettings',

    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
