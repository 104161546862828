import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormSelect from '../../../comopnents/FormSelect';
import Switch from '../../../comopnents/Switch';
import Button from '../../../comopnents/Button';
import useDidList from '../../../hooks/useDidList';
import useSetForwarding from '../../../hooks/useSetForwarding';
import { useLocation } from 'react-router-dom';
import Spinner from '../../../comopnents/Spinner';
import { useAuth } from '../../../hooks/useAuth';
import { EMPLOYEE, USER } from '../../../helpers/functions';
import AsideModal from '../../../comopnents/AsideModal';
import CustomHourForm from './CustomHourForm';
import AlwaysOpen from './AlwaysOpenForm';
import CustomForm from './CustomForm';
import { initialValues } from './constants';
import useRingGroupList from '../../../hooks/useRingGroupList';
import useIVRList from '../../../hooks/useIVRList';
import useGreetingList from '../../../hooks/useGreetingList';
import AudioPlayer from '../../../comopnents/AudioPlayer';
import useIcons from '../../../assets/icons/useIcons';
import useMemberAgentsAllListing from '../../../hooks/useMemberAgentsAllListing';
import AddGreetingForm from '../CustomGreetings/AddGreetingForm';
import SingleDropdownMain from '../../../comopnents/SingleDropdownMain';

const keyLookup = {
  EXTENSION: 'Extension',
  HANGUP: 'Hangup',
  VOICEMAIL: 'Voicemail',
  NUMBER: 'Number',
  DEPARTMENT: 'Department',
  IVR: 'IVR',
  VOICEMAILGROUP: 'Department Voicemail',
};
const valueLookup = {
  Extension: 'EXTENSION',
  Hangup: 'HANGUP',
  Voicemail: 'VOICEMAIL',
  Number: 'NUMBER',
  Department: 'DEPARTMENT',
  IVR: 'IVR',
  'Department Voicemail': 'VOICEMAILGROUP',
};
const validationSchema = yup.object({
  did_number: yup.string().required(`Number field is required.`),
  enable_greeting: yup.mixed(),
  forward_call_actions: yup.object({
    greeting_filename: yup
      .string()
      .test('Greeting is required', (value, schema) => {
        if (schema?.from?.[1]?.value?.enable_greeting === true) {
          if (!value) {
            return schema.createError({
              message: 'Greeting is required',
            });
          } else {
            return true;
          }
        } else {
          return true;
        }
      }),
    during_business_hour: yup.object({
      type: yup.mixed().required('This field is required'),
      value: yup.string().when('type', {
        is: (val) => ['Voicemail', 'Extension'].includes(val),
        then: yup.string().required('This field is required'),
      }),
      failover: yup.object().when('type', {
        is: (val) => val === 'Extension',
        then: yup.object({
          value: yup.string().when('type', {
            is: (val) => ['Number', 'Voicemail'].includes(val),
            then: () => yup.string().required('This field is required'),
          }),
        }),
      }),
    }),
    closed_business_hour: yup.object().when('business_hour_type', {
      is: (val) => val === 'custom',
      then: yup.object({
        type: yup.mixed().required('This field is required'),
        value: yup.string().when('type', {
          is: (val) => ['Voicemail', 'Extension'].includes(val),
          then: yup.string().required('This field is required'),
        }),
        failover: yup.object().when('type', {
          is: (val) => val === 'Extension',
          then: yup.object({
            value: yup.string().when('type', {
              is: (val) => ['Number', 'Voicemail'].includes(val),
              then: () => yup.string().required('This field is required'),
            }),
          }),
        }),
      }),
    }),
  }),
});

const RenderDIDItem = ({ item }) => {
  return (
    <option
      className="text--black"
      key={item.uuid}
      value={JSON.stringify({ did_number: item?.did_number, uuid: item?.uuid })}
    >
      {item.did_number}&nbsp;{item?.name ? `(${item?.name})` : ''}
    </option>
  );
};
const RenderGreetingItem = ({ item }) => {
  return (
    <option
      className="text--black"
      key={item.filename}
      value={`${item?.filename}`}
    >
      {item.name}
    </option>
  );
};

const RenderGreetingItemNew = ({ item, onChange, setSearchValue, setShow }) => {
  return (
    <div
      className="text--black h-min--32 d--flex align-items--center justify-content--start p--sm w--full"
      key={item.filename}
      onClick={() => {
        setSearchValue('');
        onChange(item.filename);
        setShow(false);
      }}
    >
      {item.name}
    </div>
  );
};

const removeBusinessHoursEmptyObj = (objData) => {
  for (let obj in objData) {
    if (!objData[obj]?.open) {
      delete objData[obj];
    }
  }
  return objData;
};

export default function CallForwarding() {
  const [addGlobalGreeting, setAddGlobalgreeting] = useState(false);

  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
  });

  const {
    control,
    handleSubmit,
    register,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });
  const { search } = useLocation();
  const { user } = useAuth();
  const { HoldIcon, PlayIcon, PlusIcon } = useIcons();
  const ACCESS = [user?.role_id]?.find((role) =>
    [USER, EMPLOYEE]?.includes(role),
  );
  const { mutate: setForwardingMutate, isLoading: setForwardLoad } =
    useSetForwarding();
  const {
    mutate: didListMutate,
    data: didListData,
    isLoading: didListLoad,
  } = useDidList();
  const {
    mutate: memberListMutate,
    data: memberListData,
    isLoading: memberListLoad,
  } = useMemberAgentsAllListing();
  const {
    mutate: greetingMutate,
    data: greetingData,
    isLoading: greetingListLoad,
  } = useGreetingList();
  const {
    mutate: ringGroupListMutate,
    data: ringGroupListData,
    isLoading: ringGroupListLoad,
  } = useRingGroupList();
  const {
    mutate: ivrListMutate,
    data: ivrListData,
    isLoading: ivrListLoad,
  } = useIVRList();

  const [isUpdateFwd, setIsUpdateFwd] = useState(false);
  const [customHours, setCustomHours] = useState(false);
  const refUuid = search?.replace('?', '');
  useEffect(() => {
    memberListMutate({ get_owner: ACCESS ? 'Y' : 'N', page: 1 });
    ringGroupListMutate({ page: 1 });
    didListMutate({ page: 1 });
    ivrListMutate({ page: 1 });
    greetingMutate({ page: 1 });
  }, []);

  useEffect(() => {
    if (watch('did_number')) {
      const refData =
        didListData?.data?.data.find(
          (item) =>
            item?.did_number === JSON.parse(watch('did_number'))?.did_number,
        ) ?? {};

      setIsUpdateFwd(refData?.forward_call_actions ? true : false);
      if (refData?.forward_call_actions) {
        const greetingList = greetingData?.data?.data || [];
        const greeting_filename =
          greetingList?.find(
            (item) =>
              item?.filename ===
              refData?.forward_call_actions?.greeting_filename,
          )?.filename || '';
        setValue('forward_call_actions', refData?.forward_call_actions);
        setValue(
          'forward_call_actions.business_hours_operations.country',
          'US',
        );
        setValue(
          'forward_call_actions.during_business_hour.type',
          keyLookup?.[
            refData?.forward_call_actions?.during_business_hour?.type
          ],
        );
        setValue(
          'forward_call_actions.greeting_filename',
          `${greeting_filename}`,
        );
        if (
          refData?.forward_call_actions?.during_business_hour?.type ===
          'VOICEMAIL'
        ) {
          const voicemail_uuid =
            greetingList?.find(
              (item) =>
                item?.filename ===
                refData?.forward_call_actions?.during_business_hour?.value,
            )?.uuid || '';
          setValue(
            'forward_call_actions.during_business_hour.value',
            `${refData?.forward_call_actions?.during_business_hour?.value}`,
          );
          // setValue(
          //   'forward_call_actions.during_business_hour.value',
          //   `${refData?.forward_call_actions?.during_business_hour?.value}&${voicemail_uuid}`,
          // );
        }
        if (
          refData?.forward_call_actions?.during_business_hour?.type ===
          'EXTENSION'
        ) {
          const failover_uuid =
            greetingList?.find(
              (item) =>
                item?.filename ===
                refData?.forward_call_actions?.during_business_hour?.failover
                  ?.value,
            )?.uuid || '';
          setValue(
            'forward_call_actions.during_business_hour.timeout',
            refData?.forward_call_actions?.during_business_hour?.timeout,
          );
          setValue(
            'forward_call_actions.during_business_hour.failover.type',
            keyLookup?.[
              refData?.forward_call_actions?.during_business_hour?.failover
                ?.type
            ],
          );
          setValue(
            'forward_call_actions.during_business_hour.failover.value',
            refData?.forward_call_actions?.during_business_hour?.failover
              ?.value,
          );
          // setValue(
          //   'forward_call_actions.during_business_hour.failover.value',
          //   refData?.forward_call_actions?.during_business_hour?.failover
          //     ?.type === 'VOICEMAIL'
          //     ? `${refData?.forward_call_actions?.during_business_hour?.failover?.value}&${failover_uuid}`
          //     : refData?.forward_call_actions?.during_business_hour?.failover
          //         ?.value,
          // );
        }
        setValue(
          'forward_call_actions.closed_business_hour.type',
          keyLookup?.[
            refData?.forward_call_actions?.closed_business_hour?.type
          ],
        );
        if (
          refData?.forward_call_actions?.closed_business_hour?.type ===
          'VOICEMAIL'
        ) {
          const voicemail_uuid =
            greetingList?.find(
              (item) =>
                item?.filename ===
                refData?.forward_call_actions?.closed_business_hour?.value,
            )?.uuid || '';

          setValue(
            'forward_call_actions.closed_business_hour.value',
            `${refData?.forward_call_actions?.closed_business_hour?.value}`,
          );
          // setValue(
          //   'forward_call_actions.closed_business_hour.value',
          //   `${refData?.forward_call_actions?.closed_business_hour?.value}&${voicemail_uuid}`,
          // );
        }
        if (
          refData?.forward_call_actions?.closed_business_hour?.type ===
          'EXTENSION'
        ) {
          const failover_uuid =
            greetingList?.find(
              (item) =>
                item?.filename ===
                refData?.forward_call_actions?.closed_business_hour?.failover
                  ?.value,
            )?.uuid || '';
          setValue(
            'forward_call_actions.closed_business_hour.timeout',
            refData?.forward_call_actions?.closed_business_hour?.timeout,
          );
          setValue(
            'forward_call_actions.closed_business_hour.failover.type',
            keyLookup?.[
              refData?.forward_call_actions?.closed_business_hour?.failover
                ?.type
            ],
          );
          setValue(
            'forward_call_actions.closed_business_hour.failover.value',
            refData?.forward_call_actions?.closed_business_hour?.failover
              ?.value,
          );
          // setValue(
          //   'forward_call_actions.closed_business_hour.failover.value',
          //   refData?.forward_call_actions?.closed_business_hour?.failover
          //     ?.type === 'VOICEMAIL'
          //     ? `${refData?.forward_call_actions?.closed_business_hour?.failover?.value}&${failover_uuid}`
          //     : refData?.forward_call_actions?.closed_business_hour?.failover
          //         ?.value,
          // );
        }

        setValue(
          'enable_greeting',
          refData?.forward_call_actions?.greeting_filename ? true : false,
        );
        setValue(
          'forward_call_actions.call_record',
          refData?.forward_call_actions?.call_record === 'Y',
        );
      } else {
        setIsUpdateFwd(false);
        const { forward_call_actions, enable_greeting } = initialValues;
        setValue('forward_call_actions', forward_call_actions);
        setValue(
          'forward_call_actions.greeting_filename',
          forward_call_actions?.greeting_filename,
        );
        setValue('enable_greeting', enable_greeting);
        setValue('forward_call_actions.call_record', false);
        // if (greetingData?.data?.data?.[0]) {
        //   setValue(
        //     'forward_call_actions.during_business_hour.failover.value',
        //     `${greetingData?.data?.data?.[0]?.filename}&${greetingData?.data?.data?.[0]?.uuid}`,
        //   );
        //   setValue(
        //     'forward_call_actions.closed_business_hour.failover.value',
        //     `${greetingData?.data?.data?.[0]?.filename}&${greetingData?.data?.data?.[0]?.uuid}`,
        //   );
        // }
      }
    }
  }, [
    watch('did_number'),
    greetingData,
    memberListData,
    ringGroupListData,
    ivrListData,
  ]);

  useEffect(() => {
    if (
      didListData?.data?.data instanceof Array &&
      didListData?.data?.data.length > 0 &&
      search
    ) {
      const refData =
        didListData?.data?.data.filter((item) => item?.uuid === refUuid)?.[0] ||
        {};
      const refValue = { did_number: refData?.did_number, uuid: refData?.uuid };
      setValue('did_number', JSON.stringify(refValue));
      const isForwardDataAvailable = Boolean(refData?.forward_call_actions);
      setIsUpdateFwd(isForwardDataAvailable);
      if (isForwardDataAvailable) {
        const greetingList = greetingData?.data?.data || [];
        const greeting_filename =
          greetingList?.find(
            (item) =>
              item?.filename ===
              refData?.forward_call_actions?.greeting_filename,
          )?.filename || '';

        setValue('forward_call_actions', refData?.forward_call_actions);
        setValue(
          'forward_call_actions.business_hours_operations.country',
          'US',
        );
        setValue(
          'forward_call_actions.during_business_hour.type',
          keyLookup?.[
            refData?.forward_call_actions?.during_business_hour?.type
          ],
        );
        setValue(
          'forward_call_actions.greeting_filename',
          `${greeting_filename}`,
        );
        if (
          refData?.forward_call_actions?.during_business_hour?.type ===
          'VOICEMAIL'
        ) {
          const voicemail_uuid =
            greetingList?.find(
              (item) =>
                item?.filename ===
                refData?.forward_call_actions?.during_business_hour?.value,
            )?.uuid || '';
          setValue(
            'forward_call_actions.during_business_hour.value',
            `${refData?.forward_call_actions?.during_business_hour?.value}`,
          );
          // setValue(
          //   'forward_call_actions.during_business_hour.value',
          //   `${refData?.forward_call_actions?.during_business_hour?.value}&${voicemail_uuid}`,
          // );
        }
        if (
          refData?.forward_call_actions?.during_business_hour?.type ===
          'EXTENSION'
        ) {
          const failover_uuid =
            greetingList?.find(
              (item) =>
                item?.filename ===
                refData?.forward_call_actions?.during_business_hour?.failover
                  ?.value,
            )?.uuid || '';
          setValue(
            'forward_call_actions.during_business_hour.timeout',
            refData?.forward_call_actions?.during_business_hour?.timeout,
          );
          setValue(
            'forward_call_actions.during_business_hour.failover.type',
            keyLookup?.[
              refData?.forward_call_actions?.during_business_hour?.failover
                ?.type
            ],
          );
          // setValue(
          //   'forward_call_actions.during_business_hour.failover.value',
          //   refData?.forward_call_actions?.during_business_hour?.failover
          //     ?.type === 'VOICEMAIL'
          //     ? `${refData?.forward_call_actions?.during_business_hour?.failover?.value}&${failover_uuid}`
          //     : refData?.forward_call_actions?.during_business_hour?.failover
          //         ?.value,
          // );
          setValue(
            'forward_call_actions.during_business_hour.failover.value',
            refData?.forward_call_actions?.during_business_hour?.failover
              ?.value,
          );
        }
        setValue(
          'forward_call_actions.closed_business_hour.type',
          keyLookup?.[
            refData?.forward_call_actions?.closed_business_hour?.type
          ],
        );
        if (
          refData?.forward_call_actions?.closed_business_hour?.type ===
          'VOICEMAIL'
        ) {
          const voicemail_uuid =
            greetingList?.find(
              (item) =>
                item?.filename ===
                refData?.forward_call_actions?.closed_business_hour?.value,
            )?.uuid || '';

          setValue(
            'forward_call_actions.closed_business_hour.value',
            `${refData?.forward_call_actions?.closed_business_hour?.value}`,
          );
          // setValue(
          //   'forward_call_actions.closed_business_hour.value',
          //   `${refData?.forward_call_actions?.closed_business_hour?.value}&${voicemail_uuid}`,
          // );
        }
        if (
          refData?.forward_call_actions?.closed_business_hour?.type ===
          'EXTENSION'
        ) {
          const failover_uuid =
            greetingList?.find(
              (item) =>
                item?.filename ===
                refData?.forward_call_actions?.closed_business_hour?.failover
                  ?.value,
            )?.uuid || '';
          setValue(
            'forward_call_actions.closed_business_hour.timeout',
            refData?.forward_call_actions?.closed_business_hour?.timeout,
          );
          setValue(
            'forward_call_actions.closed_business_hour.failover.type',
            keyLookup?.[
              refData?.forward_call_actions?.closed_business_hour?.failover
                ?.type
            ],
          );
          // setValue(
          //   'forward_call_actions.closed_business_hour.failover.value',
          //   refData?.forward_call_actions?.closed_business_hour?.failover
          //     ?.type === 'VOICEMAIL'
          //     ? `${refData?.forward_call_actions?.closed_business_hour?.failover?.value}&${failover_uuid}`
          //     : refData?.forward_call_actions?.closed_business_hour?.failover
          //         ?.value,
          // );
          setValue(
            'forward_call_actions.closed_business_hour.failover.value',
            refData?.forward_call_actions?.closed_business_hour?.failover
              ?.value,
          );
        }

        setValue(
          'enable_greeting',
          refData?.forward_call_actions?.greeting_filename ? true : false,
        );
        setValue(
          'forward_call_actions.call_record',
          refData?.forward_call_actions?.call_record === 'Y',
        );
      }
    }
  }, [
    didListData,
    memberListData,
    greetingData,
    ringGroupListData,
    ivrListData,
  ]);

  async function onSubmit(values) {
    const {
      enable_greeting = '',
      forward_call_actions = {},
      did_number,
    } = values;
    const {
      business_hour_type = '',
      call_record,
      closed_business_hour = {},
      during_business_hour = {},
      business_hours_operations = {},
      greeting_filename = '',
    } = forward_call_actions || {};
    const updatedCustomHours = removeBusinessHoursEmptyObj(
      business_hours_operations?.custom_hours,
    );
    let refData;
    if (during_business_hour?.type === 'Department') {
      refData = ringGroupListData?.data?.data?.find(
        (item) => item?.uuid === during_business_hour?.value,
      )?.title;
    }
    if (during_business_hour?.type === 'IVR') {
      refData = ivrListData?.data?.data?.find(
        (item) => item?.uuid === during_business_hour?.value,
      )?.name;
    }
    if (during_business_hour?.type === 'Department Voicemail') {
      refData = ringGroupListData?.data?.data?.find(
        (item) => item?.uuid === during_business_hour?.value,
      )?.title;
    }
    if (during_business_hour?.type === 'Extension') {
      refData = memberListData?.data?.data?.find(
        (item) => item?.extension === during_business_hour?.value,
      );
      let name = `${refData?.first_name || ''} ${refData?.last_name || ''}`;
      refData = name;
    }
    const payload = {
      uuid: JSON.parse(did_number)?.uuid || '',

      forward_type: ['HANGUP', 'NUMBER'].includes(
        valueLookup?.[forward_call_actions?.during_business_hour?.type],
      )
        ? ''
        : valueLookup?.[forward_call_actions?.during_business_hour?.type] || '',
      // forward_uuid:
      //   forward_call_actions?.during_business_hour?.type === 'Voicemail'
      //     ? forward_call_actions?.during_business_hour?.value?.split(
      //         '&',
      //       )?.[1] || ''
      //     : forward_call_actions?.during_business_hour?.type === 'Number'
      //     ? ''
      //     : forward_call_actions?.during_business_hour?.value,
      forward_uuid:
        forward_call_actions?.during_business_hour?.type === 'Number'
          ? ''
          : forward_call_actions?.during_business_hour?.value,
      forward_call_actions: {
        call_record: call_record ? 'Y' : 'N',
        business_hour_type,
        during_business_hour: {
          type: valueLookup?.[during_business_hour?.type],
          // value:
          //   during_business_hour?.type === 'Voicemail'
          //     ? during_business_hour?.value?.split('&')?.[0]
          //     : during_business_hour?.value,
          value: during_business_hour?.value,
          name: [
            'IVR',
            'Department',
            'Department Voicemail',
            'Extension',
          ].includes(during_business_hour?.type)
            ? refData
            : '',
        },
        greeting_filename: enable_greeting ? greeting_filename : '',
      },
    };
    if (during_business_hour?.type === 'Extension') {
      payload.forward_call_actions.during_business_hour.timeout =
        during_business_hour.timeout;
      payload.forward_call_actions.during_business_hour.failover = {
        type: valueLookup?.[during_business_hour?.failover?.type] || '',
        // value:
        //   during_business_hour?.failover?.type === 'Voicemail'
        //     ? during_business_hour?.failover?.value?.split('&')?.[0]
        //     : during_business_hour?.failover?.value,
        value: during_business_hour?.failover?.value,
      };
    }
    if (business_hour_type === 'custom') {
      payload.forward_call_actions.closed_business_hour = {
        type: valueLookup?.[closed_business_hour?.type],
        // value:
        //   closed_business_hour?.type === 'Voicemail'
        //     ? closed_business_hour?.value?.split('&')?.[0]
        //     : closed_business_hour?.value,
        value: closed_business_hour?.value,
      };
      if (closed_business_hour?.type === 'Extension') {
        payload.forward_call_actions.closed_business_hour.timeout =
          closed_business_hour.timeout;
        payload.forward_call_actions.closed_business_hour.failover = {
          type: valueLookup?.[closed_business_hour?.failover?.type] || '',
          // value:
          //   closed_business_hour?.failover?.type === 'Voicemail'
          //     ? closed_business_hour?.failover?.value?.split('&')?.[0]
          //     : closed_business_hour?.failover?.value,
          value: closed_business_hour?.failover?.value,
        };
      }
      payload.forward_call_actions.business_hours_operations =
        business_hours_operations;
      payload.forward_call_actions.business_hours_operations.custom_hours =
        updatedCustomHours;
    }

    setForwardingMutate(payload);
  }

  const handleClose = () => {
    setCustomHours(false);
  };

  function handleCloseGreeting() {
    greetingMutate();
    setAddGlobalgreeting(false);
  }
  const loading = isSubmitting || setForwardLoad;

  const is24hrs = watch('forward_call_actions.business_hour_type') === '24h';

  return (
    <>
      {' '}
      <form
        className="w--full h--full d--flex  justify-content-center   flex--column"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className="w--full d--flex align-items--center justify-content--between  m-b--sm ">
          <div className="font--md font--400 d--flex align-items--center gap--sm w--full">
            <div className="d--flex w--full text--primary">
              {' '}
              Call Forwarding
            </div>
          </div>
          <div className="d--flex align-items--center justify-content--end gap--sm w--full">
            <Button
              btnClasses="btn w-max--100 btn--sm"
              type="button"
              variant="primary"
              color="white"
              disabled={loading}
              size="sm"
              onClick={() => {
                reset(initialValues);
              }}
            >
              Reset
            </Button>
            <Button
              type="submit"
              btnClasses="btn w-max--100 white--space-none btn--sm"
              variant="primary"
              color="white"
              disabled={loading}
              size="sm"
            >
              {loading ? 'Please wait..' : isUpdateFwd ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>

        <div className="w--full h--full d--flex  justify-content-center ">
          {didListLoad || memberListLoad || ringGroupListLoad || ivrListLoad ? (
            <div className="w-max--500  p--xl radius--sm m--auto  d--flex align-items--center justify-content-center">
              <Spinner size="md" />
            </div>
          ) : (
            <div className="w--full  h--full d--flex justify-content--between flex--column">
              <div
                className="w--full h--full d--flex flex--column gap--sm  justify-content--between"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div className="w--full bg--white h--full gap--lg d--flex flex--column callForwardingForm overflow--auto box-shadow--xs  p--md radius--sm">
                  <div className="w--full  w-max--700">
                    <div className="w--full d--flex gap--md align-items-center m-b--sm">
                      <label className="label--control font--sm  m-b--0  text--grey d--flex align-items--center w--full">
                        Select DID for which you want to set forwarding
                      </label>
                      <div className="w--full w-max--350">
                        <Controller
                          name="did_number"
                          control={control}
                          render={({ field }) => (
                            <FormSelect
                              {...field}
                              options={didListData?.data?.data ?? []}
                              renderOption={RenderDIDItem}
                              placeholder="Select"
                              error={errors?.did_number?.message}
                              height="32"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="w--full d--flex gap--md align-items--center m-b--sm h-min--40">
                      <label className="label--control font--sm m-b--0  text--grey d--flex align-items--center w--full">
                        Do you want to play welcome greeting?
                      </label>
                      <div className="w--full w-max--350 d--flex align-items--center gap--md">
                        <Controller
                          name="enable_greeting"
                          control={control}
                          render={({ field }) => (
                            <Switch {...field} id={'enable_greeting'} />
                          )}
                        />

                        <>
                          {watch('enable_greeting') && (
                            <>
                              <Controller
                                name="forward_call_actions.greeting_filename"
                                control={control}
                                render={({ field }) => (
                                  <SingleDropdownMain
                                    {...field}
                                    labelKey={'name'}
                                    valueKey="filename"
                                    optionListLoad={greetingListLoad}
                                    optionList={
                                      greetingData?.data?.data?.filter(
                                        (item) => item?.type === 'greeting',
                                      ) || []
                                    }
                                    component={RenderGreetingItemNew}
                                    error={
                                      errors?.forward_call_actions
                                        ?.greeting_filename?.message
                                    }
                                  />
                                )}
                              />
                              <div className="d--flex gap--sm">
                                {watch(
                                  'forward_call_actions.greeting_filename',
                                ) && (
                                  <>
                                    {watch(
                                      'forward_call_actions.greeting_filename',
                                    ) === playingData?.file ? (
                                      <span
                                        className="c--pointer"
                                        onClick={() => {
                                          setPlayingData({
                                            playing: false,
                                            file: null,
                                          });
                                        }}
                                      >
                                        <HoldIcon width={20} height={20} />
                                        <AudioPlayer
                                          type="greeting"
                                          onClose={() => {
                                            setPlayingData({
                                              playing: false,
                                              file: null,
                                            });
                                          }}
                                          name={watch(
                                            'forward_call_actions.greeting_filename',
                                          )}
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        className="c--pointer text--success"
                                        onClick={() => {
                                          setPlayingData({
                                            playing: true,
                                            file: watch(
                                              'forward_call_actions.greeting_filename',
                                            ),
                                          });
                                        }}
                                      >
                                        <PlayIcon width={20} height={20} />
                                      </span>
                                    )}
                                  </>
                                )}

                                <span
                                  className=" bg--primary-100 text--primary radius--full   w-max--20 w-min--20  h-max--20 h-min--20 d--flex align-items--center justify-content--center"
                                  data-tooltip={'Add New Greeting'}
                                  tooltip-position="right"
                                  onClick={() => setAddGlobalgreeting(true)}
                                >
                                  <PlusIcon width={10} height={10} />
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    </div>
                    <div className="w--full d--flex gap--md align-items--center m-b--sm h-min--40">
                      <label className="label--control font--sm m-b--0  text--grey d--flex align-items--center w--full">
                        Do you want to record call?
                      </label>
                      <div className="w--full w-max--350">
                        <Controller
                          name={'forward_call_actions.call_record'}
                          control={control}
                          render={({ field }) => (
                            <Switch
                              {...field}
                              error={
                                errors?.forward_call_actions?.call_record
                                  ?.message
                              }
                              id={'forward_call_actions.call_record'}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="w--full d--flex gap--md align-items--center m-b--sm h-min--40">
                      <label className="label--control font--sm m-b--0  text--grey d--flex align-items--center w--full">
                        When do you want to receive call?
                      </label>
                      <div className="w--full w-max--350 d--flex gap--lg">
                        <div className="d--flex align-items--center gap--sm ">
                          <input
                            {...register(
                              'forward_call_actions.business_hour_type',
                            )}
                            className="radio"
                            id="24h"
                            type="radio"
                            value="24h"
                            checked={is24hrs}
                          />
                          <label htmlFor="24h" className="font--sm c--pointer">
                            24 Hours
                          </label>
                        </div>
                        <div className="d--flex align-items--center gap--sm ">
                          <input
                            {...register(
                              'forward_call_actions.business_hour_type',
                            )}
                            className="radio"
                            id="custom"
                            value="custom"
                            type="radio"
                            checked={!is24hrs}
                          />
                          <label
                            htmlFor="custom"
                            className="font--sm c--pointer"
                          >
                            Custom
                          </label>
                        </div>
                        {watch('forward_call_actions.business_hour_type') ===
                          'custom' && (
                          <div
                            className="d--flex align-items--center gap--sm c--pointer text--danger font--600"
                            onClick={() => setCustomHours(true)}
                          >
                            + Add Hours
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {is24hrs ? (
                    <AlwaysOpen
                      watch={watch}
                      register={register}
                      clearErrors={clearErrors}
                      errors={errors}
                      memberListData={memberListData}
                      setValue={setValue}
                      departmentsData={ringGroupListData}
                      ivrListData={ivrListData}
                      greetingList={greetingData?.data?.data || []}
                      control={control}
                      memberListLoad={memberListLoad}
                    />
                  ) : (
                    <CustomForm
                      watch={watch}
                      register={register}
                      clearErrors={clearErrors}
                      errors={errors}
                      memberListData={memberListData}
                      setValue={setValue}
                      departmentsData={ringGroupListData}
                      ivrListData={ivrListData}
                      greetingList={greetingData?.data?.data || []}
                      control={control}
                      memberListLoad={memberListLoad}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {customHours && (
          <AsideModal
            handleClose={handleClose}
            title="Edit Business Hour"
            footerComponent={null}
          >
            <CustomHourForm
              watch={watch}
              register={register}
              clearErrors={clearErrors}
              errors={errors}
              handleClose={handleClose}
              control={control}
              setValue={setValue}
            />
          </AsideModal>
        )}
      </form>
      {addGlobalGreeting ? (
        <AsideModal
          handleClose={handleCloseGreeting}
          title={'Add Greeting'}
          footerComponent={null}
        >
          <AddGreetingForm handleClose={handleCloseGreeting} />
        </AsideModal>
      ) : null}
    </>
  );
}
