import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteProfilePic } from '../../api';
import { useAlert } from '../useAlert';

export default function useDeleteProfilePic() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: deleteProfilePic,
    mutationKey: 'deleteProfilePic',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['getUserDetails'],
        exact: false,
      });
      showAlert({
        type: 'success',
        message: data?.message ?? 'Profile pic deleted successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
