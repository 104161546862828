import React from 'react';
import TableManager from '../../../comopnents/TableManager';
import {
  downloadFileFromURL,
  formatDateOnly,
  formatPhoneNumber,
} from '../../../helpers/functions';
import { billingList } from '../../../api';
import Button from '../../../comopnents/Button';
import useIcons from '../../../assets/icons/useIcons';
import { useAuth } from '../../../hooks/useAuth';

function Transaction() {
  const { user } = useAuth();
  const { DownloadIcon } = useIcons();
  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Billing Date',
      cell: ({ row }) => {
        return formatDateOnly(row?.original?.created_at);
      },
    },

    {
      accessorKey: 'billno',
      header: () => 'Transaction ID',
      meta: {
        width: '15%',
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'plan.plan_name',
      header: () => 'Plan Name',
      meta: {
        width: '15%',
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'did.did_number',
      header: () => 'Virtual Number',
      cell: (props) => {
        return formatPhoneNumber(props?.getValue());
      },
      meta: {
        width: '15%',
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'transactions_type',
      header: () => 'Transaction Type',

      meta: {
        width: '10%',
      },
    },
    {
      accessorKey: 'total_amount',
      header: () => 'Amount ($)',

      meta: {
        width: '10%',
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
    },
  ];

  return (
    <div className="w--full h--full">
      <TableManager
        fetcherKey={'billingList'}
        fetcherFn={billingList}
        columns={columns}
        name={'Transactions'}
        shouldFilter={false}
      />
    </div>
  );
}

export default Transaction;
