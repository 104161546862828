// import React from 'react'
// import Button from '../../../comopnents/Button'
// import blogImg1 from "../../../assets/images/web/blog-img-1.svg"
// import blogImg2 from "../../../assets/images/web/blog-img-2.jpg"
// import blogImg3 from "../../../assets/images/web/blog-img-3.jpg"
// import useIcons from '../../../assets/icons/useIcons'

// export const EconomyGuides = () => {
//     const { NextArrowIcon, CalenderStrokeIcon, Curve3Icon, Curve4Icon } = useIcons();
//     return (
//         <div className='marketEconomy section position--relative overflow--hidden'>
//             <div className='curve3Icon right  animate bounce--right text--success position--absolute'>
//                 <Curve3Icon width={180} height={150} />
//             </div>
//             <div className='container'>
//                 <div className='w--full d--flex justify-content--center align-items--center flex--column gap--xl'>
//                     <div className='w--full gap--lg d--flex flex--column  w-max--800 text--c'>
//                         <h3 className='font--42 font--700 line-height--1-dot-2  text--c'>Keep up with the market and economy with our guides.</h3>
//                         <p className='font--md text--grey line-height--1-dot-5'>Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication.</p>
//                     </div>
//                     <div className='w--full d--flex gap--lg flex-column--xs flex-column--sm '>
//                         <div className='box-shadow flex--column d--flex items p-b--md radius--sm'>
//                             <div className='w--full h--full d--flex imgSec h-min--300 h-max--300 overflow--hidden d--flex'>
//                                 <img src={blogImg1} alt="tabImg" className='w--full h-min--300 h-max--300 r-t-left--sm r-t-right--sm object--cover' />
//                             </div>
//                             <div className='w--full p--lg d--flex flex--column gap--md bottomSec'>
//                                 <div className='d--flex gap--sm align-items--center text--black-800 font--14'>
//                                     <CalenderStrokeIcon />
//                                     Nov 12, 2023
//                                 </div>
//                                 <h5 className='font--md font--600'>Product Owner or Product Manager: Who Owns the Product?</h5>
//                                 <p className='font--14 text--black-600 line-height--1-dot-5'>Lorem ipsum dolor sit amet. Et aliquid rerum sit distinctio unde et dolores labore aut veniam incidunt aut corporis officia</p>
//                                 <div className='btnSec transition'>

//                                     <Button variant='transparent' color='success' btnClasses='border-0 align-items--center d--flex gap--xs'>Read More <NextArrowIcon /></Button>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='box-shadow flex--column d--flex items p-b--md radius--sm'>
//                             <div className='w--full h--full d--flex imgSec h-min--300 h-max--300 overflow--hidden d--flex'>
//                                 <img src={blogImg2} alt="tabImg" className='w--full h-min--300 h-max--300 r-t-left--sm r-t-right--sm object--cover' />
//                             </div>
//                             <div className='w--full p--lg d--flex flex--column gap--md bottomSec'>
//                                 <div className='d--flex gap--sm align-items--center text--black-800 font--14'>
//                                     <CalenderStrokeIcon />
//                                     Nov 12, 2023
//                                 </div>
//                                 <h5 className='font--md font--600'>Product Owner or Product Manager: Who Owns the Product?</h5>
//                                 <p className='font--14 text--black-600 line-height--1-dot-5'>Lorem ipsum dolor sit amet. Et aliquid rerum sit distinctio unde et dolores labore aut veniam incidunt aut corporis officia</p>
//                                 <div className='btnSec transition'>

//                                     <Button variant='transparent' color='success' btnClasses='border-0 align-items--center d--flex gap--xs'>Read More <NextArrowIcon /></Button>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='box-shadow flex--column d--flex items p-b--md radius--sm'>
//                             <div className='w--full h--full d--flex imgSec h-min--300 h-max--300 overflow--hidden d--flex'>
//                                 <img src={blogImg3} alt="tabImg" className='w--full h-min--300 h-max--300 r-t-left--sm r-t-right--sm object--cover' />
//                             </div>
//                             <div className='w--full p--lg d--flex flex--column gap--md bottomSec'>
//                                 <div className='d--flex gap--sm align-items--center text--black-800 font--14'>
//                                     <CalenderStrokeIcon />
//                                     Nov 12, 2023
//                                 </div>
//                                 <h5 className='font--md font--600'>Product Owner or Product Manager: Who Owns the Product?</h5>
//                                 <p className='font--14 text--black-600 line-height--1-dot-5'>Lorem ipsum dolor sit amet. Et aliquid rerum sit distinctio unde et dolores labore aut veniam incidunt aut corporis officia</p>
//                                 <div className='btnSec transition'>

//                                     <Button variant='transparent' color='success' btnClasses='border-0 align-items--center d--flex gap--xs'>Read More <NextArrowIcon /></Button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className='curve3Icon left  animate bounce--right text--success position--absolute'>
//                 <Curve4Icon width={120} height={100} />
//             </div>
//         </div>
//     )
// }

export const EconomyGuides = () => {
  return null;
};
