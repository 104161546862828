import React, { useContext, useEffect, useState } from 'react';
import {
  darkenColor,
  getInitials,
  lightenColor,
  stringToColour,
} from '../../helpers/functions';
import { useAuth } from '../../hooks/useAuth';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';
import useIcons from '../../assets/icons/useIcons';
import OnlineImage from '../../assets/images/web/online.png';
import HolidayImage from '../../assets/images/web/holiday.png';
import AwayImage from '../../assets/images/web/away.png';
import BusyImage from '../../assets/images/web/busy.png';
import DNDImage from '../../assets/images/web/do-not-disturb.png';
import OfflineImage from '../../assets/images/web/offline.png';
import PhoneCallImage from '../../assets/images/web/phone-call.png';
import { Popover } from '@mui/material';
import moment from 'moment';
import Timer from '../Timer';

const statusColorLookup = {
  online: (
    <div className=" radius--sm bg--success  radius--full w--full h--full h-max--12 h-min--12 w-max--12 w-min--12 " />
  ),
  holiday: (
    <div className=" radius--sm bg--grey   radius--full w--full h--full h-max--12 h-min--12 w-max--12 w-min--12 " />
  ),
  away: (
    <div className=" radius--sm bg--secondary  radius--full w--full h--full h-max--12 h-min--12 w-max--12 w-min--12 " />
  ),
  busy: (
    <div className=" radius--sm bg--danger   radius--full w--full h--full h-max--12 h-min--12 w-max--12 w-min--12 " />
  ),
  dnd: (
    <div className=" radius--sm bg--warning   radius--full w--full h--full h-max--12 h-min--12 w-max--12 w-min--12 " />
  ),
};

const statusImageLookup = {
  online: <img src={OnlineImage} alt="OnlineImage" />,
  holiday: <img src={HolidayImage} alt="HolidayImage" />,
  away: <img src={AwayImage} alt="AwayImage" />,
  busy: <img src={BusyImage} alt="BusyImage" />,
  dnd: <img src={DNDImage} alt="DNDImage" />,
  Online: <img src={OnlineImage} alt="OnlineImage" />,
  Offline: <img src={OfflineImage} alt="OnlineImage" />,
  Call: <img src={PhoneCallImage} alt="OnlineImage" />,
};

const statusArray = [
  { title: 'Online', value: 'online' },
  { title: 'Holiday', value: 'holiday' },
  { title: 'Away', value: 'away' },
  { title: 'Busy', value: 'busy' },
  { title: 'Do not disturb', value: 'dnd' },
  { title: 'Online', value: 'Online' },
  { title: 'Offline', value: 'Offline' },
  { title: 'Call', value: 'Call' },
];

const backgroundlookup = {
  online: 'bg--success',
  holiday: 'bg--grey',
  away: 'bg--secondary',
  busy: 'bg--danger',
  dnd: 'bg--warning',

  Online: 'bg--success',
  Offline: 'bg--black-800',
  Call: 'bg--white',
  '': 'bg--black-800',
};

function Avatar({
  first_name = '',
  last_name = '',
  image,
  contactImage = '',
  size = '32',
  type = 'profile',
  parent_uuid = '',
  count = 0,
  showCount = false,
  extension = '',
  shouldShowPresence = true,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const { CallIcon } = useIcons();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const { socket = {} } = useContext(SipSocketContext);
  const isOnCall =
    socket?.onCall?.[`${extension}`]?.['State'] === 'confirmed' ||
    socket?.onCall?.[`${extension}_hw`]?.['State'] === 'confirmed' ||
    socket?.onCall?.[`${extension}_web`]?.['State'] === 'confirmed'
      ? true
      : false;
  const [myPresentStatus, setPresentStatus] = useState('');
  const [awayTime, setAwayTime] = useState(null);
  const { user } = useAuth();
  const [mediaUrl, setMediaUrl] = useState('');
  const nameColour = lightenColor(
    stringToColour(`${first_name} ${last_name}`),
    70,
  );
  const lightColor = lightenColor(nameColour, 90);
  const darkColor = darkenColor(nameColour, 80);

  useEffect(() => {
    if (image || contactImage) {
      if (contactImage) {
        setMediaUrl(contactImage);
      } else {
        setMediaUrl(
          `${process.env.REACT_APP_MEDIA_URL}signed-url/${
            parent_uuid || user?.parent_uuid || user?.uuid
          }/${type}?filename=${image}`,
        );
      }
    }
  }, [image, user?.parent_uuid, user?.uuid, contactImage]);

  useEffect(() => {
    if (extension && shouldShowPresence) {
      const amIOnline =
        socket?.presentUsers?.length > 0
          ? socket?.presentUsers?.find(
              (user) =>
                user?.userId === extension ||
                `${user.userId}` === `${extension}`,
            )?.online || false
          : false;

      const myStatus =
        socket?.presentUsers?.length > 0
          ? socket?.presentUsers?.find(
              (user) =>
                user?.userId === extension ||
                `${user.userId}` === `${extension}`,
            )?.status || 'Offline'
          : 'Offline';

      const time =
        socket?.presentUsers?.length > 0
          ? socket?.presentUsers?.find(
              (user) =>
                user?.userId === extension ||
                `${user.userId}` === `${extension}`,
            )?.timeObj || null
          : null;

      let currentStatus = myStatus;

      if (myStatus === 'online' && !amIOnline) {
        currentStatus = 'Offline';
      }

      if (time) {
        setAwayTime(time);
      }

      //do not remove this code
      // setPresentStatus(isOnCall ? 'Call' : amIOnline ? myStatus : 'Offline');
      setPresentStatus(isOnCall ? 'Call' : currentStatus);
    }
  }, [socket?.presentUsers?.length, extension, shouldShowPresence, isOnCall]);

  return (
    <div className="position--relative">
      <div
        className={`border border-full--black-50 text--primary overflow--hidden radius--full  bg--primary-100 w-max--${size} w-min--${size}  h-max--${size} h-min--${size} d--flex align-items--center justify-content--center `}
      >
        {(image || contactImage) && mediaUrl ? (
          <img
            src={mediaUrl}
            alt="profile"
            className="radius--full object--cover"
          />
        ) : (
          <div
            className={`d--flex radius--full align-items--center justify-content--center w-max--${size} w-min--${size}  h-max--${size} h-min--${size}`}
            style={{
              fontWeight: 600,
              fontSize: `${size / 2.3}px`,
              color: lightColor,
              background: darkColor,
            }}
          >
            {showCount ? (
              `+${count}`
            ) : (
              <>
                {getInitials(first_name)}
                {getInitials(last_name)}
              </>
            )}
          </div>
        )}
      </div>
      {shouldShowPresence ? (
        <div
          // data-tooltip={myPresentStatus}
          // tooltip-position="top"
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className={`radius--sm position--absolute bottom--0 right--0 onlineCircle c--pointer ${backgroundlookup[myPresentStatus]} border border-full--white   radius--full w--full h--full h-max--12 h-min--12 w-max--12 w-min--12 d--flex align-item--center justify-content--center`}
          style={{
            // bottom: '-2px',
            // right: '-3px',
            position: 'absolute',
          }}
        >
          {/* {myPresentStatus === 'Call' ? (
            <span className=" text--danger d--flex align-item--center justify-content--center">
              <CallIcon width={10} height={10} />
            </span>
          ) : null} */}

          <span className=" text--danger d--flex align-item--center justify-content--center">
            {statusImageLookup[myPresentStatus]}
          </span>

          <Popover
            id="mouse-over-popover"
            className="presencePopover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div className="d--flex align-items--center justify-content--center  font--sm font--600 gap--sm  h-max--28 h-min--28 w--full p--md">
              <div className=" h-max--16 h-min--16 w-max--16 w-min--16">
                {statusImageLookup[myPresentStatus]}
              </div>
              <div className="white--space-none  d--flex flex--column justify-content--center">
                <div>
                  {statusArray &&
                    statusArray.length > 0 &&
                    statusArray?.find(
                      (status) => status?.value === myPresentStatus,
                    )?.title}
                </div>

                {awayTime &&
                awayTime?.holiday_start_date &&
                myPresentStatus === 'holiday' ? (
                  <div className="font--xs font--400 d--flex justify-content--center gap--sm ">
                    {moment(awayTime?.holiday_start_date)?.format('DD-MM-YYYY')}{' '}
                    - {moment(awayTime?.holiday_end_date)?.format('DD-MM-YYYY')}
                  </div>
                ) : null}

                {awayTime &&
                awayTime?.holiday_start_date &&
                myPresentStatus === 'away' ? (
                  <div className="font--xs font--400 d--flex justify-content--center gap--sm ">
                    for{' '}
                    <Timer
                      time={awayTime?.holiday_start_date}
                      setTimer={setAwayTime}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </Popover>
        </div>
      ) : null}
    </div>
  );
}

export default React.memo(Avatar);
