import React, { useEffect, useState } from 'react';
import useIcons from '../../../assets/icons/useIcons';
import moment from 'moment';
import AudioPlayer from '../../../comopnents/AudioPlayer';
import AsideModal from '../../../comopnents/AsideModal';
import Transcripts2 from '../../../comopnents/Transcripts2';
import { useAuth } from '../../../hooks/useAuth';
import { capitalizeWords, formatPhoneNumber, SecondsTohhmmss } from '../../../helpers/functions';
import useAllNumberDetails from '../../../hooks/useAllNumberDetails';
import useIVRList from '../../../hooks/useIVRList';
import useRingGroupList from '../../../hooks/useRingGroupList';
import CustomToolTip from '../../../comopnents/CustomToolTip';

const CallItemCollapse = ({ item = {}, ivrListData, ringGroupListData }) => {
  const { user = null } = useAuth();
  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
  });
  const { getNumberName } = useAllNumberDetails();

  const [showTranscript, setShowTranscript] = useState(false);
  const [transcriptData, setTranscriptData] = useState(null);
  const {
    MissedCall,
    OutgoingCall,
    IncomingCall,
    WatchIcon,
    NotesIcons,
    TranscriptsIcon,
    PlayIcon,
    VoicemailIcon,
    CrossRoundIcon,
    VoicemailGroupIcon,
    UsersIcon,
    MobileIcon,
  } = useIcons();

  const [show, setShow] = useState(false);

  const color = {
    Inbound: 'text--primary',
    Outbound: 'text--success',
    Missed: 'text--danger',
  };

  const callIconLookup = {
    Inbound: <IncomingCall width={18} height={18} />,
    Outbound: <OutgoingCall width={18} height={18} />,
    Missed: <MissedCall width={18} height={18} />,
  };

  const handleSetTranscript = (data) => {
    setTranscriptData(data);
    setShowTranscript(true);
  };

  const handleClose = () => {
    setTranscriptData(null);
    setShowTranscript(false);
  };

  const isMissedCall = item?.duration === 0;

  let departmentName = '';
  if (item?.type === 'department' || item?.type === 'voicemailgroup') {
    departmentName =
      ringGroupListData?.data?.data?.find((_) => _?.uuid === item?.value)
        ?.title || 'Unknown';
  }
  let ivrName = '';
  if (item?.type === 'ivr') {
    ivrName =
      ivrListData?.data?.data?.find((_) => _?.uuid === item?.value)?.name ||
      'Unknown';
  }

  const getStatusItem = (status = '') => {
    switch (status) {
      case 'SUCCESS':
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className="text--success"
          >
            {status?.toLowerCase()}
          </span>
        );
      case 'CANCEL':
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className="text--danger"
          >
            {status?.toLowerCase()}
          </span>
        );

      default:
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className="text--secondary white--space-none"
          >
            {status?.replaceAll('_', ' ')?.toLowerCase() || '---'}
          </span>
        );
    }
  };

  return (
    <div className="d--flex gap--sm flex--column w--full h--full align-items--center h-min--85 w--full">
      <div className="w--full d--flex  gap--sm  w-min--100 h--full align-items--center justify-content--between  h-min--40 w--full  ">
        <div className="w--full font--sm d--flex align-items--center gap--sm w-max--100">
          <span
            className={`w-min--20 ${
              color[isMissedCall ? 'Missed' : item?.direction]
            } `}
          >
            {item?.isVoicemail ? (
              <VoicemailIcon width={18} height={18} />
            ) : (
              callIconLookup[isMissedCall ? 'Missed' : item?.direction]
            )}
          </span>{' '}
          <div>
            <div>{moment(item?.created_at)?.format('MMM DD,')}</div>
            <div className="font--xs">
              {moment(item?.created_at)?.format('hh:mm A')}
            </div>
          </div>
        </div>
        <div className="font--sm d--flex flex--column gap--sm w--full w-max--200 justify-content--center align-items--center">
          {item?.didName && <span>{item?.didName}</span>}
          {formatPhoneNumber(item?.didNumber)}
        </div>
        <div className="w--full" style={{ textTransform: 'capitalize' }}>
          {item?.type}
        </div>
        {/* <div className="w--full">{item?.phone}</div> */}
        <div className="w--full">
          <>
            {item?.isVoicemail === 1 ? (
              <span className="d--flex align-items--center  justify-content--start gap--sm">
                <span>
                  {item?.type === 'voicemailgroup' ? (
                    <VoicemailGroupIcon width={20} height={20} />
                  ) : (
                    <VoicemailIcon width={20} height={20} />
                  )}
                </span>
                <div>
                  {item?.type === 'voicemailgroup' ? (
                    departmentName ? (
                      <span className="d--flex align-items--center gap--xs">
                        <span>{departmentName}</span>
                      </span>
                    ) : (
                      'Department Voicemail'
                    )
                  ) : item?.type === 'voicemail' ? (
                    item?.name ? (
                      <span className="d--flex align-items--center gap--xs">
                        <span>
                          {item?.name}{' '}
                          <div className="font--xs">{`Ext. ${item?.value}`}</div>
                        </span>
                      </span>
                    ) : (
                      'Voicemail'
                    )
                  ) : (
                    capitalizeWords(item?.type?.toLowerCase(), ' ')
                  )}
                </div>
              </span>
            ) : (
              <>
                <span className="d--flex">
                  {item?.type === 'extension' ? (
                    <>
                      <div className="d--flex flex--column">
                        <div>{getNumberName(item?.value, false)}</div>
                        {item?.value?.length < 5 && (
                          <div className="font--xs">{`Ext. ${item?.value}`}</div>
                        )}
                        {item?.value?.length > 5 &&
                          !getNumberName(item?.value, false)?.includes('+') && (
                            <div className="font--xs">{`Ph. ${formatPhoneNumber(
                              item?.value,
                            )}`}</div>
                          )}
                      </div>
                    </>
                  ) : item?.type === 'department' ||
                    item?.type === 'voicemailgroup' ? (
                    <span className="d--flex align-items--center gap--xs">
                      <UsersIcon />
                      <span>{departmentName}</span>
                      {item?.extension ? (
                        <span>({getNumberName(item?.extension, false)})</span>
                      ) : null}
                    </span>
                  ) : item?.type === 'ivr' ? (
                    <span className="d--flex align-items--center gap--xs">
                      <MobileIcon />
                      <span>{ivrName}</span>
                    </span>
                  ) : (
                    <>{item?.type}</>
                  )}
                </span>
              </>
            )}
          </>
        </div>
        <div className="w--full d--flex flex--column gap--sm">
          {getStatusItem(item?.status)}
        </div>

        <div className="w--full font--sm d--flex align-items--center gap--sm">
          {SecondsTohhmmss(item?.duration)}
        </div>

        {/* <div className="w--full font--sm d--flex align-items--center gap--sm">
          Charge
        </div> */}

        <div className="w--full w-max--60 d--flex gap--xs align-items--center justify-content--end">
          {item?.recordfile ? (
            <>
              <div
                className=" d--flex align-items--center justify-content--center radius--sm c--pointer"
                onClick={() => handleSetTranscript(item?.recordfile)}
              >
                <TranscriptsIcon width={25} height={25} />
              </div>
              <div
                className="c--pointer  d--flex align-items--center justify-content--center radius--sm text--success  "
                onClick={() => {
                  setPlayingData({ playing: true, file: item?.recordfile });
                }}
              >
                <PlayIcon width={25} height={25} />
              </div>
            </>
          ) : null}
          {item?.note && item?.note?.length > 0 ? (
            <div
              className="  h--full radius--sm  text--primary d--flex align-items--center justify-content--center c--pointer"
              onClick={() => setShow((prev) => !prev)}
            >
              <NotesIcons width={25} height={25} />
            </div>
          ) : null}
        </div>
      </div>
      {show ? (
        <div className="collapseContent overflow--auto h-max--300  d--flex bg--white w--full d--flex flex--column gap--sm m-t--md m-b--md p-r--sm">
          {item?.note && item?.note?.length > 0 ? (
            item?.note?.map((note) => (
              <div
                className="w--full d--flex justify-content--start"
                key={note?.created_at}
              >
                <div className=" bg--black-100 p--md  radius--sm text--dark line-height--1-dot-5 d--flex flex--column gap--sm w--full">
                  {note?.note}
                  <div className="font--xs d--flex align-items--center gap--sm text--grey">
                    <WatchIcon width={12} height={12} />{' '}
                    {moment(item?.created_at)?.format('MMM DD, hh:mm A')}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="w--full d--flex align-items--center justify-content--center">
              No Notes Available
            </div>
          )}
          <div className="w-min--25 d--flex justify-content--center"></div>
        </div>
      ) : null}

      {playingData?.file && (
        <AudioPlayer
          onClose={() => {
            setPlayingData({ playing: false, file: null });
          }}
          name={playingData?.file}
        />
      )}

      {showTranscript && (
        <AsideModal
          handleClose={handleClose}
          title={'Call Intelligence'}
          footerComponent={null}
          width="md"
        >
          <Transcripts2
            transcript_file_url={`${
              process.env.REACT_APP_MEDIA_URL
            }signed-url/${
              user?.parent_uuid || user?.uuid
            }/recording?filename=${transcriptData}`}
            recording_file_url={`${process.env.REACT_APP_MEDIA_URL}signed-url/${
              user?.parent_uuid || user?.uuid
            }/recording?filename=${transcriptData}`}
          />
        </AsideModal>
      )}
    </div>
  );
};

const CallTab = ({ data = null }) => {
  const { CrossRoundIcon } = useIcons();
  const { mutateAsync: ivrListMutate, data: ivrListData } = useIVRList();
  const { mutate: ringGroupListMutate, data: ringGroupListData } =
    useRingGroupList();

  useEffect(() => {
    ivrListMutate();
    ringGroupListMutate();
  }, []);

  return (
    <div className="w--full d--flex flex--column p--md">
      <div className="d--flex gap--sm w--full h-min--40">
        <div className="d--flex align-items--center font--600 text--secondary w--full w-max--100">
          Date/Time
        </div>
        <div className="d--flex align-items--center justify-content--center font--600 text--secondary w--full w-max--200">
          Via Virtual Number
        </div>
        <div className="d--flex align-items--center font--600 text--secondary w--full">
          Mode
        </div>
        {/* <div className="d--flex align-items--center font--600 text--secondary w--full">
          From
        </div> */}
        <div className="d--flex align-items--center font--600 text--secondary w--full">
          To
        </div>
        <div className="d--flex align-items--center font--600 text--secondary w--full">
          Status
        </div>
        <div className="d--flex align-items--center font--600 text--secondary w--full">
          Duration
        </div>
        {/* <div className="d--flex align-items--center font--600 text--secondary w--full">
          Charge
        </div> */}
        <div className="d--flex align-items--center font--600 text--secondary w--full w-max--60">
          Action
        </div>
      </div>
      <div className="d--flex flex--column gap--sm overflow--auto callListContact">
        {data &&
          data.length > 0 &&
          data.map((item) => (
            <CallItemCollapse
              key={item?.id}
              item={item}
              ivrListData={ivrListData}
              ringGroupListData={ringGroupListData}
            />
          ))}
      </div>
    </div>
  );
};

export default CallTab;
