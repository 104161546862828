import React, { useContext, useEffect, useMemo, useState } from 'react';
import useDidList from '../../hooks/useDidList';
import { useAuth } from '../../hooks/useAuth';
import ChatList from './ChatList';
import ChatBody from './ChatBody';
import Button from '../../comopnents/Button';
import Modal from '../../comopnents/Modal';
import { useQueryClient } from '@tanstack/react-query';
import SendMessageModal from './SendMessageModal';
import { useSearchParams } from 'react-router-dom';
import { formatPhoneNumber } from '../../helpers/functions';
import { SipSocketContext } from '../../hooks/useSip/SipSocketContext';

export const RenderDIDItem = ({ item }) => {
  return (
    <option
      className="text--black c--pointer"
      key={item.did_number}
      value={item?.did_number}
    >
      {formatPhoneNumber(item.did_number)}
    </option>
  );
};

const Messages = () => {
  const { user } = useAuth();
  const [activeChat, setActiveChat] = useState('');
  const [smsDefaultDID, setSMSDefaultDid] = useState('');
  const [openModalImport, setOpenModalImport] = useState(false);
  const [sendSmsThroughURL, setSendSmsThroughURL] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { mutate: didListMutate, data: didListData } = useDidList();
  const didListing = useMemo(
    () => didListData?.data?.data ?? [],
    [didListData?.data?.data],
  );
  const { socket: { getUnreadInboundSMSCount = () => null } = {} } =
    useContext(SipSocketContext);

  useEffect(() => {
    didListMutate();
  }, []);

  useEffect(() => {
    getUnreadInboundSMSCount();
  }, []);

  useEffect(() => {
    if (
      searchParams &&
      searchParams.get('didId') &&
      didListing &&
      didListing?.length > 0
    ) {
      const DidId = searchParams.get('didId') || '';
      const refData =
        didListing?.find(
          (item) =>
            item?.uuid === DidId ||
            item?.did_number?.replace('+', '') === DidId?.replace('+', ''),
        ) || null;
      if (refData) {
        setSMSDefaultDid(refData?.did_number);
      }
    } else {
      if (user?.caller_id) {
        setSMSDefaultDid(user?.caller_id);
      }
    }
    if (
      searchParams &&
      searchParams.get('sendSms') &&
      didListing &&
      didListing?.length > 0
    ) {
      const sendTo = searchParams.get('sendSms') || '';
      setSendSmsThroughURL(sendTo);
      setOpenModalImport(true);
      setSearchParams('');
    }
  }, [didListing?.length, user]);

  return (
    <div className="MessagePage w--full h--full d--flex  flex--column">
      <div className="font--md font--400 m-b--sm h-max--40  d--flex justify-content--between align-items--center">
        <div className="text--primary">Messages</div>
        <div>
          <Button
            type="button"
            btnClasses="btn"
            variant="primary"
            color="white"
            onClick={() => setOpenModalImport(true)}
            size="sm"
          >
            New Message
          </Button>
        </div>
      </div>
      <div className="d--flex w--full h--full gap--md">
        <ChatList
          smsDefaultDID={smsDefaultDID}
          setSMSDefaultDid={setSMSDefaultDid}
          didListing={didListing}
          activeChat={activeChat}
          setActiveChat={setActiveChat}
        />
        <div className="w--full chatRightContent bg--white box-shadow--xs radius--sm d--flex flex--column ">
          {activeChat ? (
            <ChatBody smsDefaultDID={smsDefaultDID} activeChat={activeChat} />
          ) : (
            <div className="h--full d--flex justify-content--center align-items--center">
              No Chat Selected
            </div>
          )}
        </div>
      </div>
      {openModalImport ? (
        <Modal
          footerComponent={null}
          title={'Send Message'}
          handleClose={() => setOpenModalImport(false)}
          shouldCloseOnClickOutside={false}
          height="400"
        >
          <SendMessageModal
            didListing={didListing}
            smsDefaultDID={smsDefaultDID}
            setSMSDefaultDid={setSMSDefaultDid}
            handleClose={() => {
              queryClient.invalidateQueries({
                queryKey: ['getSMSListing'],
                exact: false,
              });
              setOpenModalImport(false);
            }}
            destinationNumber={sendSmsThroughURL}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default Messages;
