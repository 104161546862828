import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import PlanDetails from '../PlanDetails';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../../comopnents/FormInput';
import Button from '../../../../comopnents/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useGetPlan from '../../../../hooks/useGetPlan';
import useSignup from '../../../../hooks/useSignup';
import ConfirmationModal from '../ConfirmationModal';
import PaymentForm from '../../../PaymentForm';

const initialValues = {
  card_holder_name: '',
};

const validationSchema = yup.object().shape({
  card_holder_name: yup
    .string()
    .matches(
      /^[A-Za-z'-]+[ A-Za-z'-]*$/,
      'Card holder name cannot contain numbers',
    )
    .required('Card holder name is required'),
});

function Payment() {
  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  const { mutate, data } = useGetPlan();
  const { mutate: signUpMutate, isLoading: paymentLoad } = useSignup({
    handleSuccess,
  });
  const [searchParams] = useSearchParams();
  const planUuid = searchParams.get('plan_uuid') || '';
  const duration = searchParams.get('duration') || '';
  const planDetails = data?.data?.data || {};
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uuid = urlParams.get('uuid');
  const confirmButtonRef = useRef(null);

  useEffect(() => {
    if (planUuid && duration) {
      mutate({ plan_uuid: planUuid, plan_duration: duration });
    }
  }, [mutate, planUuid, duration]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  function handleSuccess() {
    navigate('/login');
  }

  const handleSubmitForm = async () => {
    setshow(false);
    confirmButtonRef?.current?.handleSubmit({
      paymentType: 'signup',
      planID: planUuid,
      uuid: uuid,
      type: 'payment',
    });
  };

  return (
    <div className="d--flex h--full PaymentFormPage">
      <div className="container">
        <div className="d--flex ">
          <PlanDetails
            PlanDetails={planDetails}
            plan_duration={duration}
            plan_uuid={planUuid}
          />
          <div className="w--full radius--md p-t--3xl m-t--3xl m-b--lg gap--lg l w-max--600 p--xl z-index--xs bg--white box-shadow d--flex align-items--center justify-content--center flex--column  h-min--300">
            <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center">
              <h4 className="font--2xl text--primary">Payment</h4>
            </div>
            <form
              className="w--full h--full"
              onSubmit={handleSubmit(handleSubmitForm)}
              autoComplete="off"
            >
              <ConfirmationModal
                show={show}
                setshow={setshow}
                onConfirm={() => {
                  handleSubmitForm();
                }}
              />
              <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
                <div>
                  <Controller
                    name="card_holder_name"
                    control={control}
                    rules={{
                      onChange(e) {
                        setValue(
                          'card_holder_name',
                          e.target.value?.trimStart(),
                        );
                      },
                    }}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="text"
                        label="Card Holder Name"
                        placeholder="Name"
                        error={errors?.card_holder_name?.message}
                      />
                    )}
                  />
                </div>
                <PaymentForm
                  ref={confirmButtonRef}
                  type="signup"
                  actionFn={signUpMutate}
                />
              </div>
            </form>
            <div className="w--full p-r--md p-l--md">
              <Button
                size=""
                variant="primary"
                color="white"
                btnClasses="btn"
                onClick={() => setshow(true)}
              >
                {paymentLoad ? (
                  'Please wait.....'
                ) : (
                  <>
                    Pay&nbsp;
                    {duration === '1'
                      ? `$${planDetails.monthly_cost}`
                      : `$${planDetails.yearly_cost}`}
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
