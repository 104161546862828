import React, { useState } from 'react';
import TableManager from '../../../comopnents/TableManager';
import useIcons from '../../../assets/icons/useIcons';
import AudioPlayer from '../../../comopnents/AudioPlayer';
import AsideModal from '../../../comopnents/AsideModal';
import { useAuth } from '../../../hooks/useAuth';
import {
  AGENCY_OWNER,
  EMPLOYEE,
  USER,
  formatPhoneNumber,
} from '../../../helpers/functions';
import Button from '../../../comopnents/Button';
import { ringGroupList } from '../../../api';
import Dropdown from '../../../comopnents/Dropdown';
import ConfirmComponent from '../../../comopnents/ConfirmComponent';
import useRingGroupDelete from '../../../hooks/useRingGroupDelete';
import Avatar from '../../../comopnents/Avatar';
import moment from 'moment';
import DepartmentForm from './DepartmentForm';

const initialEditInstance = { isEdit: false, editData: {} };
const RingGroup = () => {
  const { user } = useAuth();
  const { PlayIcon, HoldIcon, MoreVIcon } = useIcons();
  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
  });
  const [addRingGroup, setAddRingGroup] = useState(false);
  const [editRingGroupInstance, setEditRingGroupInstance] =
    useState(initialEditInstance);
  const ACCESS = [user?.role_id]?.find((role) =>
    [USER, EMPLOYEE, AGENCY_OWNER]?.includes(role),
  );
  const { mutate: mutateDeleteRingGroup } = useRingGroupDelete();
  const handleRingGroupDelete = (item) => {
    mutateDeleteRingGroup({ uuid: item?.uuid });
  };

  const handleDropListClick = ({ value }, data) => {
    switch (value) {
      case 'edit':
        setEditRingGroupInstance({ isEdit: true, editData: data });
        setAddRingGroup(true);
        break;

      default:
        break;
    }
  };

  const tableActions = [
    {
      id: 1,
      access: ACCESS,
      label: 'Add Department',
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddRingGroup(true)}
          size="sm"
        >
          Add Department
        </Button>
      ),
    },
  ];

  const columns = [
    {
      accessorKey: 'title',
      header: () => 'Name',
      cell: (props) => props.getValue(),
    },
    {
      accessorKey: 'members',
      header: () => 'Members',
      cell: (props) => {
        return (
          <div className="w--full d--flex justify-content--center avtarGroup">
            {props.getValue()?.map((member, index) => {
              if (index === 3) {
                const remainingCount = props.getValue().length - 3;
                var result = props
                  .getValue()
                  .reduce(function (acc, obj, index) {
                    if (index > 2) {
                      if (index === 3) {
                        return acc + obj.name;
                      } else {
                        return acc + ', ' + obj.name;
                      }
                    } else {
                      return acc;
                    }
                  }, '');
                return (
                  <div
                    className="avtarGroup--item"
                    data-tooltip={result}
                    tooltip-position="right"
                  >
                    <Avatar
                      size="28"
                      count={remainingCount}
                      showCount={true}
                      extension={member?.extension}
                      first_name={'+'}
                      shouldShowPresence={false}
                    />
                  </div>
                );
              }
              if (index > 3) {
                return;
              }
              return (
                <div
                  className="avtarGroup--item"
                  data-tooltip={member?.name}
                  tooltip-position="top"
                >
                  <Avatar
                    size="28"
                    extension={member?.extension}
                    first_name={member?.name?.split(' ')?.[0] || 'U'}
                    last_name={member?.name?.split(' ')?.[1] || ''}
                  />
                </div>
              );
            })}
          </div>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'timeout',
      header: () => 'Timeout Duration',
      meta: {
        textAlign: 'center',
      },
      cell: (props) => {
        return <> {props.getValue()} sec</>;
      },
    },
    {
      accessorKey: 'timeout_type',
      header: () => 'Timeout Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { timeout_value = 'NA', timeout_type = 'NA' } = element;
        return (
          <>
            {['EXTENSION', 'NUMBER'].includes(timeout_type) ? (
              <>
                {timeout_type} ({timeout_value})
              </>
            ) : (
              <>{timeout_type}</>
            )}
          </>
        );
      },
    },
    {
      accessorKey: 'did_number',
      header: () => 'Virtual Number',
      cell: (props) => formatPhoneNumber(props?.getValue()),
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { greeting = '' } = element;
        const isPlaying =
          playingData?.playing && playingData?.file === greeting;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <div className="w-min--36">
              {greeting && (
                <>
                  {isPlaying ? (
                    <span
                      className="c--pointer"
                      onClick={() => {
                        setPlayingData({ playing: false, file: null });
                      }}
                    >
                      <HoldIcon width={20} height={20} />
                      <AudioPlayer
                        type="greeting"
                        onClose={() => {
                          setPlayingData({ playing: false, file: null });
                        }}
                        name={greeting}
                      />
                    </span>
                  ) : (
                    <span
                      className="c--pointer text--success"
                      onClick={() => {
                        setPlayingData({ playing: true, file: greeting });
                      }}
                    >
                      <PlayIcon width={20} height={20} />
                    </span>
                  )}
                </>
              )}
            </div>
            <Dropdown
              extraClasses="w-min--150 bg--black-200"
              closeOnClickOutside={false}
              dropList={{
                component: ({ item }) => {
                  if (item?.shouldConfirm) {
                    return (
                      <ConfirmComponent
                        item={item}
                        element={element}
                        label="Please confirm?"
                        confirmCallback={(data) => handleRingGroupDelete(data)}
                      >
                        {item?.title}
                      </ConfirmComponent>
                    );
                  }
                  return (
                    <span
                      className="w--full d--flex  justify-content--start p--2sm"
                      onClick={() => handleDropListClick(item, element)}
                    >
                      {item?.title}
                    </span>
                  );
                },
                data: [
                  { title: 'Edit', value: 'edit' },
                  { title: 'Delete', value: 'delete', shouldConfirm: true },
                ],
              }}
              showcaret={false}
            >
              <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={13} height={13} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'right',
        width: '5%',
      },
    },
  ];

  const handleClose = () => {
    setAddRingGroup(false);
    setEditRingGroupInstance({ isEdit: false, editData: {} });
  };
  return (
    <div className="h--full w--full">
      <TableManager
        {...{
          fetcherKey: 'ringGroupList',
          fetcherFn: ringGroupList,
          columns,
          name: `Department (${moment().subtract(6, 'days').format('DD MMM')} -
          ${moment().format('DD MMM')}
        ) `,
          tableActions,
          shouldShowTotalCount: true,
          customTotalCount: true,
        }}
      />
      {addRingGroup && (
        <AsideModal
          handleClose={handleClose}
          title={
            editRingGroupInstance?.isEdit ? 'Edit Department' : 'Add Department'
          }
          footerComponent={null}
        >
          <DepartmentForm
            handleClose={handleClose}
            editRingGroupInstance={editRingGroupInstance}
          />
        </AsideModal>
      )}
    </div>
  );
};

export default RingGroup;
