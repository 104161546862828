import React, { useContext, useEffect } from 'react';
import useIcons from '../../../assets/icons/useIcons';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import { OutgoigRing } from '../../../helpers/functions';

const OutgoingCallScreen = ({ activeCall = {}, setaction }) => {
  const {
    CallIcon,
    TransferIcon,
    MickOffIcon,
    MickIcon,
    DialIcon,
    HoldIcon,
    RecordIcon,
    DialIcon2,
    CallTransferIcon,
    NotesIcons,
    TagsIcons,
  } = useIcons();
  const { callID, _status = '' } = activeCall;
  const { socket = {} } = useContext(SipSocketContext);
  const { _terminate = () => null } = socket;

  // useEffect(() => {
  //   const myTone = OutgoigRing;

  //   if (['ringing'].includes(_status)) {
  //     myTone.play();
  //   } else {
  //     myTone.pause();
  //   }
  //   return () => {
  //     myTone.pause();
  //   };
  // }, [_status]);

  return (
    <>
      <div className="h-min--350 h-max--350 overflow-auto w--full">
        <div className="p--md bg--primary d--grid grid-system-4 h-min--150">
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm">
            <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--transparent  opacity--sm c--not-allowed">
              <MickIcon />
            </span>
            <span className="font--sm text--white  opacity--sm ">Mute</span>
          </span>
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm">
            <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--transparent  opacity--sm c--not-allowed">
              <HoldIcon width={20} height={20} />
            </span>
            <span className="font--sm text--white  opacity--sm ">Hold</span>
          </span>
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm">
            <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center text--white justify-content--center bg--transparent c--not-allowed opacity--sm">
              <RecordIcon width={20} height={20} />
            </span>
            <span className="font--sm text--white  opacity--sm ">Record</span>
          </span>
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm">
            <span className="w-min--40 w-max--40 h-min--40 h-max--40 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--transparent opacity--sm c--not-allowed">
              <DialIcon2 width={19} height={19} />
            </span>
            <span className="font--sm text--white  opacity--sm ">Keypad</span>
          </span>
        </div>
        <div className="p--md  d--grid grid-system gap--xl p-l--2xl p-r--2xl m-t---3xl">
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm w--full h-min--75 h-max--75 bg--white radius--md box-shadow c--not-allowed">
            <span className="w--full  d--flex align-items--center justify-content--center opacity--sm">
              <NotesIcons />
            </span>
            <span className="font--sm font--600  opacity--sm ">Notes</span>
          </span>
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm w--full h-min--75 h-max--75 bg--white radius--md box-shadow  c--not-allowed">
            <span className="w--full  d--flex align-items--center justify-content--center opacity--sm">
              <TagsIcons width={22} height={22} />
            </span>
            <span className="font--sm font--600  opacity--sm ">Tags</span>
          </span>
          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm w--full h-min--75 h-max--75 bg--white radius--md box-shadow   c--not-allowed">
            <span className="w--full  d--flex align-items--center justify-content--center opacity--sm ">
              <CallTransferIcon />
            </span>
            <span className="font--sm font--600  opacity--sm ">Transfer</span>
          </span>
        </div>
        <div className="p-b--lg p-t--lg   d--grid grid-system m-t--xl">
          <div></div>

          <span className="items d--flex flex--column align-items--center justify-content--center gap--sm">
            <div className="heading heading--md mb-2">
              <span className="d--flex flex--column align-items--center justify-content--center">
                <span
                  style={{ transform: 'rotate(135deg)' }}
                  className={
                    'w-min--50 w-max--50 h-min--50 h-max--50 w--full h--full radius--full d--flex align-items--center justify-content--center text--white bg--red  c--pointer bg bg--dark-red'
                  }
                  onClick={() => {
                    _terminate(callID);
                  }}
                >
                  <CallIcon />
                </span>
              </span>
            </div>
          </span>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default OutgoingCallScreen;
