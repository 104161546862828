import React, { useState } from 'react';
import TableManager from '../../../comopnents/TableManager';
import { greetingList } from '../../../api';
import useIcons from '../../../assets/icons/useIcons';
import {
  AGENCY_OWNER,
  DEFAULT_VOICEMAIL_NAME,
  EMPLOYEE,
  USER,
  formatBytes,
} from '../../../helpers/functions';
import AudioPlayer from '../../../comopnents/AudioPlayer';
import Button from '../../../comopnents/Button';
import { useAuth } from '../../../hooks/useAuth';
import AsideModal from '../../../comopnents/AsideModal';
import AddGreetingForm from './AddGreetingForm';
import useGreetingDelete from '../../../hooks/useGreetingDelete';
import Dropdown from '../../../comopnents/Dropdown';
import ConfirmComponent from '../../../comopnents/ConfirmComponent';
import moment from 'moment';

const CustomGreetings = () => {
  const { user } = useAuth();
  const { PlayIcon, HoldIcon, MoreVIcon } = useIcons();
  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
  });
  const [addGreeting, setAddGreeting] = useState(false);

  const ACCESS = [user?.role_id]?.find((role) =>
    [USER, EMPLOYEE, AGENCY_OWNER]?.includes(role),
  );

  const { mutateAsync: greetingDeleteMutate } = useGreetingDelete();

  const handleGreetingDelete = ({ uuid = '' }) => {
    greetingDeleteMutate({
      uuid,
    });
  };
  const tableActions = [
    {
      id: 1,
      access: ACCESS,
      label: 'Add',
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddGreeting(true)}
          size="sm"
        >
          Add
        </Button>
      ),
    },
  ];

  const columns = [
    {
      accessorKey: 'name',
      header: () => 'Name',
      cell: (props) => (
        <div className="d--flex gap--sm">
          {props.getValue()}
          {props?.row?.original?.filename === DEFAULT_VOICEMAIL_NAME && (
            <div className="text--success">(Default)</div>
          )}
        </div>
      ),
      meta: {
        width: '20%',
      },
    },
    {
      accessorKey: 'duration',
      header: () => 'Duration ',
      cell: (props) => `${props.getValue()} sec`,
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'size',
      header: () => 'Size',
      cell: (props) => formatBytes(props.row.original?.size),
    },
    {
      accessorKey: 'type',
      header: () => 'Type',
      cell: (props) => (
        <span style={{ textTransform: 'capitalize' }}>{props.getValue()}</span>
      ),
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { filename = '', uuid = '' } = element;
        const isPlaying =
          playingData?.playing && playingData?.file === filename;

        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            {isPlaying ? (
              <span
                className="c--pointer"
                onClick={() => {
                  setPlayingData({ playing: false, file: null });
                }}
              >
                <HoldIcon width={20} height={20} />
              </span>
            ) : (
              <span
                className="c--pointer text--success"
                onClick={() => {
                  setPlayingData({ playing: true, file: filename });
                }}
              >
                <PlayIcon width={20} height={20} />
              </span>
            )}
            {filename !== DEFAULT_VOICEMAIL_NAME ? (
              <Dropdown
                extraClasses="w-min--150 bg--black-200"
                closeOnClickOutside={false}
                dropList={{
                  component: ({ item }) => {
                    if (item?.shouldConfirm) {
                      return (
                        <ConfirmComponent
                          item={item}
                          element={element}
                          label="Please confirm?"
                          confirmCallback={(data) => handleGreetingDelete(data)}
                        >
                          {item?.title}
                        </ConfirmComponent>
                      );
                    }
                  },
                  data: [
                    { title: 'Delete', value: 'delete', shouldConfirm: true },
                  ],
                }}
                showcaret={false}
              >
                <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                  <MoreVIcon width={13} height={13} />
                </div>
              </Dropdown>
            ) : (
              <div className="w-min--28"></div>
            )}
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];

  const handleClose = () => {
    setAddGreeting(false);
  };

  return (
    <div className="h--full w--full ">
      <TableManager
        {...{
          fetcherKey: 'greetingList',
          fetcherFn: greetingList,
          columns,
          name: `Custom Greetings (${moment()
            .subtract(6, 'days')
            .format('DD MMM')} -
          ${moment().format('DD MMM')}
        ) `,
          tableActions,
          shouldShowTotalCount: true,
          customTotalCount: true,
        }}
      />
      {addGreeting ? (
        <AsideModal
          handleClose={handleClose}
          title={'Add'}
          footerComponent={null}
        >
          <AddGreetingForm handleClose={handleClose} />
        </AsideModal>
      ) : null}
      {playingData?.file && (
        <AudioPlayer
          type="greeting"
          onClose={() => {
            setPlayingData({ playing: false, file: null });
          }}
          name={playingData?.file}
        />
      )}
    </div>
  );
};

export default CustomGreetings;
