import React from 'react';
import useUserDetails from '../../hooks/useUserDetails';
import { Navigate } from 'react-router-dom';
import Spinner from '../../comopnents/Spinner';
import { useAuth } from '../../hooks/useAuth';

export default function Unauthorized() {
  const { user } = useAuth();
  useUserDetails();

  if (user?.role_id) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  return (
    <div className="d--flex align-items--center justify-content--center h--full w--full">
      <Spinner size="lg" />
    </div>
  );
}
