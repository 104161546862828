import React, { useEffect, useState } from 'react';
import Tabbing from '../../Tabbing';
import { generateCustomDateObjectFromTo } from '../../../pages/Dashboard/helpers';
import { customDateFilterTypes } from '../../../helpers/functions';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../Button';

const CustomDateFilter = ({
  dateFilterType,
  setDateFilterType = () => null,
  setFilterDate = () => null,
  removeDateFilter = () => null,
  filter_date,
}) => {
  const [customDate, setCustomDate] = useState({ from: '', to: '' });

  useEffect(() => {
    if (filter_date) {
      setCustomDate(filter_date);
    }
  }, [filter_date]);

  function onApply() {
    setFilterDate(customDate);
  }

  return (
    <div className="w--full d--flex justify-content--center gap--sm">
      {/* <Tabbing
        tab={dateFilterType}
        setTab={(val) => {
          setDateFilterType(val);
          setFilterDate(
            generateCustomDateObjectFromTo({
              dateType: val,
            }),
          );
        }}
        tabsLabels={[
          {
            label: 'Today',
            value: 'Today',
          },
          {
            label: 'This week',
            value: 'This Week',
          },
          {
            label: 'This Month',
            value: 'This Month',
          },
        ]}
      /> */}
      <span className="d--flex gap--sm">
        <select
          // disabled={!isChecked}
          className="form--control w--full w-min--170 h-min--32 font--sm radius--sm p-l--md p-r--md border-full--black-200"
          value={dateFilterType}
          onChange={(e) => {
            removeDateFilter();
            if (['Select Date', 'Custom'].includes(e.target.value)) {
              setDateFilterType(e.target.value);
            } else {
              setDateFilterType(e.target.value);
              setFilterDate(
                generateCustomDateObjectFromTo({
                  dateType: e.target.value,
                }),
              );
            }
          }}
        >
          <option value="">Select custom date</option>
          {customDateFilterTypes.map((item, index) => (
            <option value={item?.value} key={index}>
              {item?.label}
            </option>
          ))}
        </select>
        {dateFilterType === 'Select Date' && (
          <>
            <input
              name="selectDate"
              id="selectDate"
              type="date"
              value={filter_date?.from ?? ''}
              placeholder="Select or type a date..."
              className="form--control w--full h-min--32 font--sm radius--sm p-l--md p-r--md border-full--black-200 w--full"
              max={moment().format('YYYY-MM-DD')}
              onChange={(e) =>
                setFilterDate({
                  from: e.target.value,
                  to: e.target.value,
                })
              }
            />
          </>
        )}
        {dateFilterType === 'Custom' && (
          <span className="d--flex gap--sm z-index--2sm p-l--sm p-r--sm  p-t--xs  p-b--xs bg--white radius--sm">
            <DatePicker
              placeholderText={'Select date'}
              className="form--control w--full h-min--28   radius--sm p-l--0 p-r--0 border--0  bg--transparent border-full--black-200 w-min--200"
              selected={customDate?.from ?? ''}
              onChange={(dates) => {
                const [start, end] = dates;
                setCustomDate({
                  from: start,
                  to: end,
                });
              }}
              startDate={customDate?.from ?? ''}
              endDate={customDate?.to ?? ''}
              selectsRange
              monthsShown={2}
              isClearable={true}
              maxDate={new Date()}
              inputFormat="YYYY/MM/DD"
            />
            <Button
              type="button"
              variant="primary"
              color="white"
              btnClasses="btn btn--xs  w-max--110 font--sm radius--full"
              onClick={onApply}
            >
              Apply
            </Button>
            {/* <Button
              type="button"
              variant="red"
              color="white"
              btnClasses="btn btn--xs  w-max--110 font--sm  radius--full"
              onClick={removeDateFilter}
            >
              Close
            </Button> */}
            {/* <input
              className={`form--control w--full h-min--32 font--sm radius--sm p-l--md p-r--md border-full--black-200`}
              type="date"
              name="startDate"
              id="startDate"
              value={filter_date?.from ?? ''}
              max={moment().format('YYYY-MM-DD')}
              onChange={(e) =>
                setFilterDate({
                  from: e.target.value,
                  to: filter_date?.to,
                })
              }
            />
            <input
              className={`form--control w--full h-min--32 font--sm radius--sm p-l--md p-r--md border-full--black-200`}
              type="date"
              name="endDate"
              id="endDate"
              value={filter_date?.to ?? ''}
              max={moment().format('YYYY-MM-DD')}
              onChange={(e) =>
                setFilterDate({
                  from: filter_date?.from,
                  to: e.target.value,
                })
              }
            /> */}
          </span>
        )}
      </span>
    </div>
  );
};

export default CustomDateFilter;
