import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { greetingList } from '../../api';

export default function useGreetingList() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: greetingList,
    mutationKey: 'greetingList',

    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
