import React from 'react';
import useIcons from '../../assets/icons/useIcons';
import Button from '../../comopnents/Button';
import { useNavigate } from 'react-router-dom';

export default function Error500Page() {
  const navigate = useNavigate();
  const { DesktopIcon } = useIcons();
  return (
    <div className="d--flex align-items--center justify-content--center w--full h--full flex--column gap--md">
      <span className=" text--black-100">
        <DesktopIcon height={100} width={100} />
      </span>
      <h4 className="font--2xl font--600 m-b--0">Error 500</h4>
      <p className="m-b--0 text--c text--black-500">
        We are sorry the page you requested could not be found <br />
        please go back to the homepage
      </p>
      <div className="w--full w-max--150" onClick={() => navigate('/')}>
        <Button variant="primary" btnClasses="btn" rounded>
          Go Home
        </Button>
      </div>
    </div>
  );
}
