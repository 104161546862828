import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import useIcons from '../../../assets/icons/useIcons';
import moment from 'moment';
import { minArray } from '../../../helpers/functions';
import ActivityItem from './ActivityItem';

const CollapseHeader = ({ setShow, show }) => {
  const { CaretIcon } = useIcons();

  return (
    <div
      style={{
        opacity: show ? '1' : '0.5',
      }}
      className="d--flex w--full  justify-content--between align-items--center c--pointer h-min--40 w--full p--md bg--black-100 radius--sm "
      onClick={() => setShow((prev) => !prev)}
    >
      {/* <div className="w-min--60 d--flex justify-content--center"></div> */}
      <div
        id="main-hour-line"
        className="w--full d--flex  w-min--100 h--full justify-content--between"
      >
        {minArray && minArray?.length > 0
          ? minArray.map(({ label }) => {
              return (
                <div className="text--sm font--500 text--primary">
                  {label ?? ''}
                </div>
              );
            })
          : null}
      </div>
      <div className="w-min--25 d--flex justify-content--center">
        <CaretIcon width={20} height={20} />
      </div>
    </div>
  );
};

const CollapseContent = ({ hours, singleDateActivity }) => {
  function filterActivityByHour(hour) {
    const filteredActivity = [];
    const time24hr = moment(hour, 'hh:mm A').format('HH');
    for (let index = 0; index < singleDateActivity.length; index++) {
      const element = singleDateActivity[index];
      const activityTime = moment(element?.timestamp).format('HH');
      if (time24hr === activityTime) {
        filteredActivity.push(element);
      }
    }
    return filteredActivity;
  }
  return (
    <div className="collapseContent overflow--auto p--md d--flex">
      <div className="d--flex flex--column gap--xl w-min--60 position--absolute left--0 ">
        {hours &&
          hours?.map(({ hour }) => {
            return (
              <div className="h-min--16 h-max--16 text--primary">{hour}</div>
            );
          })}
      </div>
      <div className="w--full d--flex flex--column gap--xl">
        {hours &&
          hours?.map(({ hour }) => {
            const time = moment(hour, 'hh:mm A').format('DD.MM.YYYY.HH:mm:ss');
            const filteredActivity = filterActivityByHour(hour);
            const isActivityExist = filteredActivity?.length > 0;
            return (
              <div className="h-min--16 h-max--16 d--flex align-items--center">
                {isActivityExist && (
                  <div
                    id={`drawable-line-${time}`}
                    className={`radius--md  w--full position--relative d--flex`}
                  >
                    {Array.from({ length: 12 }, (_, i) => i + 1).map((val) => {
                      return (
                        <div className="bg--primary h-min--1 h-max--1 w--full">
                          <div
                            className="h-min--8 w-min--1 w-max--1 h-max--8 bg--primary"
                            style={{
                              top: '50%',
                              transform: 'translateY(-50%)',
                            }}
                          ></div>
                        </div>
                      );
                    })}

                    {filteredActivity &&
                      filteredActivity?.length > 0 &&
                      filteredActivity?.map((activity) => {
                        return <ActivityItem activity={activity} hour={hour} />;
                      })}
                  </div>
                )}
                {(!filteredActivity || !isActivityExist) && (
                  <div
                    id={`drawable-line-${time}`}
                    className={`d--flex align-items--center w--full`}
                  >
                    <div className="bg--warning h-min--1 w--full"></div>
                    <div className="border-full--warning  right---10 z-index--xs  h--full radius--full bg--white d--flex align-items--center justify-content--center white--space-none p-l--sm p-r--sm h-min--25">
                      No action
                    </div>
                    <div className="bg--warning h-min--1 w--full"></div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div className="w-min--25 d--flex justify-content--center"></div>
    </div>
  );
};

const ActivityCollapse = ({
  allActivity = [],
  singleDateActivity = {},
  activityTime,
  index,
}) => {
  const [show, setShow] = useState(false);
  const [hours, setHours] = useState([]);

  useEffect(() => {
    if (allActivity && allActivity?.length > 0) {
      const res = calculateTimelineHour(allActivity);
      if (res && res.length > 0) {
        res.splice(res?.length - 1, 1);
        setHours(res);
      }
      // console.log('🚀 ~ useEffect ~ res:', res.splice(res?.length - 1, 1));
    }

    if (index === 0) {
      setShow(true);
    }
  }, [allActivity]);

  const startDateTime =
    moment(singleDateActivity?.startTimestamp).format('YYYY-MM-DD') || '';

  const givenDate = moment(startDateTime);

  const today = moment();

  return (
    <div className="w--full d--flex position--relative">
      <div className="w--full d--flex flex--column w-max--100  w-min--100 h--full">
        <div className=" font--sm font--500  h-min--36 text--c  d--flex align-items--center">
          {givenDate.isSame(today, 'day')
            ? 'Today'
            : activityTime === 'week'
            ? moment(singleDateActivity?.startTimestamp).format('ddd')
            : startDateTime}
        </div>
      </div>
      <div className="w--full ">
        <CollapseHeader setShow={setShow} show={show} />
        {show ? (
          <CollapseContent
            hours={hours}
            singleDateActivity={singleDateActivity?.activity}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ActivityCollapse;

function calculateTimelineHour(activityArray) {
  if (!activityArray?.length) {
    return [];
  }
  const hourActivity = [];
  const startItem = activityArray[0].startTimestamp;
  const endItem = activityArray[0].endTimestamp;
  const startHour = moment(startItem);
  const endHour = moment(endItem);
  const currentHour = moment(startHour);
  while (currentHour.isSameOrBefore(endHour)) {
    const hour = currentHour.format('hh:mm A');

    hourActivity.push({
      hour,
    });
    currentHour.add(1, 'hour');
  }
  return hourActivity;
}
