import { useMutation } from '@tanstack/react-query';
import { resendotp } from '../../api';
import { useAlert } from '../useAlert';

export default function useResendOtp() {
  const { showAlert } = useAlert();
  const resendOtpRequest = useMutation({
    mutationFn: resendotp,
    mutationKey: 'resendotp',
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.message ?? '',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...resendOtpRequest };
}
