import React, { useRef, useState } from 'react';
import EditInfo from './EditInfo';
import { useAuth } from '../../../hooks/useAuth';
import Avatar from '../../../comopnents/Avatar';
import FormInput from '../../../comopnents/FormInput';
import imageCompression from 'browser-image-compression';
import ImageCropModal from '../../../comopnents/ImageCropModal';
import useUploadMedia from '../../../hooks/useUploadMedia';
import Spinner from '../../../comopnents/Spinner';
import useProfileUpdate from '../../../hooks/useProfileUpdate';
import { AGENCY_OWNER, USER } from '../../../helpers/functions';
import useIcons from '../../../assets/icons/useIcons';
import useDeleteProfilePic from '../../../hooks/useDeleteProfilePic';
import Button from '../../../comopnents/Button';

const optionsforCompressing = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  maxIteration: 20,
};
export default function AccountInfo() {
  const { TrashIcon, PencilIcon, TickFillIcon } = useIcons();
  const { user } = useAuth();
  const fileInputRef = useRef(null);
  const { mutateAsync: getUploadUrl } = useUploadMedia();
  const { mutateAsync: profileUpdateMutate } = useProfileUpdate();
  const {
    mutateAsync: profilePicDeleteMutate,
    isLoading: profilePicDeleteLoad,
  } = useDeleteProfilePic();
  const [isImageUploading, setImageUpload] = useState(false);
  const [image, setImage] = useState(null);
  const [extension, setExtension] = useState({
    isEdit: false,
    extensionVal: '',
    validation: '',
  });

  const handleFile = () => {
    fileInputRef?.current?.click();
  };

  const handleDelete = async () => {
    await profilePicDeleteMutate();
  };

  const handleFileSelect = async (e) => {
    let file = e.target.files[0];
    if (file) {
      try {
        imageCompression(file, optionsforCompressing)
          .then((compressedFile) => {
            const reader = new FileReader();
            reader.onload = () => {
              setImage(reader.result);
            };
            reader.readAsDataURL(compressedFile);
          })
          .catch((err) => console.log('err while compressing', err));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFileUpload = async (data) => {
    fetch(data)
      .then((res) => res.blob())
      .then(async (file) => {
        setImage(null);
        setImageUpload(true);
        const payload = {
          uuid: [USER, AGENCY_OWNER].includes(user?.role_id)
            ? user?.uuid
            : user?.parent_uuid || user?.uuid,
          file_name: 'profile_image.png',
          type: 'profile',
        };
        const res = await getUploadUrl(payload);
        const { filename, url: uploadUrl } = res?.data?.data || '';
        if (uploadUrl) {
          const uploadFileResponse = await fetch(uploadUrl, {
            method: 'PUT',
            body: file,
          });
          if (uploadFileResponse.status === 200) {
            await profileUpdateMutate({ profile_pic: filename });
            setImageUpload(false);
          }
        }
        setImageUpload(false);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  return (
    <div className="w--full">
      <h4 className="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">
        Information
      </h4>
      <div className="d--flex gap--md flex--column profilePage bg--white p--md radius--sm h--full overflow--auto box-shadow--xs">
        <div className="w--full d--flex gap--md flex--column">
          <div className="label--control font--sm font--500 m-b--xs  h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
            Personal Info
            <div className="d--flex">
              {(isImageUploading || profilePicDeleteLoad) && <Spinner />}
            </div>
          </div>
          <div className="d--flex gap--xl ">
            <div className="w--full d--flex gap--3xl ">
              <div className="d--flex position--relative avatarImage">
                <Avatar
                  image={user?.profile_pic}
                  first_name={user?.first_name}
                  last_name={user?.last_name}
                  extension={user?.extension}
                  size="100"
                />
                <div className="w--full  d--flex flex--column position--absolute">
                  <FormInput
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    id="filename"
                    name="filename"
                    height="100"
                    extraClasses="opacity--0 p-t--xl c--pointer"
                    onChange={(e) => {
                      handleFileSelect(e);
                      e.target.value = '';
                    }}
                  />
                </div>
                <div className="overlayImage position--absolute radius--full w--full h--full d--none justify-content--center align-items--center gap--sm ">
                  <div
                    className="text--danger c--pointer w-min--28 h-min--28 w-max--28 h-max--28 radius--sm bg--white d--flex align-items--center justify-content--center"
                    onClick={handleFile}
                  >
                    <PencilIcon width={18} />
                  </div>

                  <div
                    className="text--danger c--pointer w-min--28 h-min--28 w-max--28 h-max--28 radius--sm bg--white d--flex align-items--center justify-content--center"
                    onClick={handleDelete}
                  >
                    <TrashIcon width={18} />
                  </div>
                </div>
              </div>

              <div className="d--flex flex--column gap--md w--full">
                <div className="w--full d--flex gap--3xl">
                  <div className="d--flex flex--column gap--sm w--full">
                    <div className="font--sm font--600">First Name</div>
                    <div className="font--sm ">{user?.first_name || '---'}</div>
                  </div>
                  <div className="d--flex flex--column gap--sm w--full">
                    <div className="font--sm font--600">Last Name</div>
                    <div className="font--sm ">{user?.last_name || '---'}</div>
                  </div>
                </div>
                <div className="d--flex flex--column gap--sm">
                  <div className="font--sm font--600">Phone Number</div>
                  <div className="font--sm gap--sm d--flex">{user?.phone}</div>
                </div>
              </div>
            </div>
            <div className="w--full d--flex gap--3xl">
              <div className="d--flex flex--column gap--md w--full">
                <div className="d--flex flex--column gap--sm">
                  <div className="font--sm font--600">Email</div>
                  <div className="font--sm "> {user?.email}</div>
                </div>
                <div className="d--flex flex--column gap--sm">
                  <div className="font--sm font--600">Account Number</div>
                  <div className="font--sm ">{user?.account_number}</div>
                </div>
              </div>
              <div className="d--flex flex--column gap--md w--full">
                <div className="font--sm font--600">Extension</div>
                <div className="font--sm ">{user?.extension}</div>
                {/* <div className="d--flex flex--column gap--sm">
                  <div className="font--sm font--600">
                    <label
                      className={`label--control m-b--xs d--flex w--full  ${
                        extension.validation ? 'text--danger' : ''
                      }`}
                    >
                      {extension.validation
                        ? extension.validation
                        : 'Extension'}
                    </label>
                  </div>
                  {!extension.isEdit ? (
                    <div className="d--flex align-items--center gap--sm">
                      <div className="font--sm">{user?.extension}</div>
                      <div
                        className="font--sm text--primary"
                        onClick={() =>
                          setExtension((prev) => ({
                            ...prev,
                            isEdit: true,
                            extensionVal: user?.extension,
                          }))
                        }
                      >
                        <PencilIcon width={18} />
                      </div>
                    </div>
                  ) : (
                    <div className="d--flex gap--sm  align-items--center">
                      <FormInput
                        type="number"
                        label=""
                        placeholder="Enter extension"
                        value={extension.extensionVal}
                        onChange={(e) => {
                          const inputValue = e.target?.value;
                          if(inputValue < 0 || inputValue === "0000" || inputValue?.length > 4 ) return
                          if (inputValue?.length < 4) {
                            setExtension((prev) => ({
                              ...prev,
                              extensionVal: e.target?.value,
                              validation: 'Please enter valid extension',
                            }));
                          } else {
                            setExtension((prev) => ({
                              ...prev,
                              extensionVal: e.target?.value,
                              validation: '',
                            }));
                          }
                        }}
                        onKeyDown={(e) => {
                          if (['e', '+', '-'].includes(e.key))
                            e.preventDefault();
                        }}
                      />
                      <div className="d--flex gap--xs align-items--center">
                        <span className="text--success c--pointer">
                          <TickFillIcon width={20} height={20} />
                        </span>
                        <span
                          className="text--danger  c--pointer"
                          onClick={() =>
                            setExtension((prev) => ({
                              ...prev,
                              isEdit: false,
                              extensionVal: user?.extension,
                              validation: '',
                            }))
                          }
                        >
                          <TrashIcon width={20} height={20} />
                        </span>
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="w--full d--flex gap--xl">
          <EditInfo user={user} />
        </div>
        <ImageCropModal
          {...{
            image,
            setImage,
            setImageUpload,
            upload: (data) => handleFileUpload(data),
          }}
        />
      </div>
    </div>
  );
}
