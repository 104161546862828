import React from 'react';
import Spinner from '../Spinner';
import { useQuery } from '@tanstack/react-query';
import { getDetails } from '../../api';

const GetName = ({ number = '' }) => {
  const { isError, isSuccess, data, isLoading, isFetching, isPending } =
    useQuery({
      queryFn: ({ queryKey }) => getDetails(queryKey[1] || {}),
      queryKey: [`getName${number}`, { ext: number }],
      enabled: Boolean(number),
    });

  if (isLoading || isFetching || isPending) {
    <Spinner />;
  }
  if (isError) {
    return number;
  }

  if (isSuccess) {
    if (data?.data?.success) {
      if (data?.data?.data) {
        return `${data?.data?.data?.first_name ?? '---'} ${
          data?.data?.data?.last_name ?? ''
        }`;
      }
      return number;
    }
    return number;
  }
};

export default GetName;
