import React, { useEffect, useState } from 'react';
import TableManager from '../../../comopnents/TableManager';
import useIcons from '../../../assets/icons/useIcons';
import AudioPlayer from '../../../comopnents/AudioPlayer';
import { useAuth } from '../../../hooks/useAuth';
import { AGENCY_OWNER, EMPLOYEE, USER } from '../../../helpers/functions';
import Button from '../../../comopnents/Button';
import AsideModal from '../../../comopnents/AsideModal';
import AddIVR from './AddIVR';
import { ivrList } from '../../../api';
import Dropdown from '../../../comopnents/Dropdown';
import ConfirmComponent from '../../../comopnents/ConfirmComponent';
import useIVRDelete from '../../../hooks/useIVRDelete';
import useGreetingList from '../../../hooks/useGreetingList';
import moment from 'moment';

const initialEditInstance = { isEdit: false, editData: {} };
const IVR = () => {
  const { user } = useAuth();

  const { PlayIcon, HoldIcon, MoreVIcon } = useIcons();
  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
    rowId: '',
  });
  const [addIVR, setAddIVR] = useState(false);
  const [greetingList, setGreetingList] = useState([]);
  const [editIVRInstance, setEditIVRInstance] = useState(initialEditInstance);
  const ACCESS = [user?.role_id]?.find((role) =>
    [USER, EMPLOYEE, AGENCY_OWNER]?.includes(role),
  );

  const { mutate: deleteIVRMutate } = useIVRDelete();
  const { mutate, data: greetingListData } = useGreetingList();

  useEffect(() => {
    if (!greetingListData?.data) {
      mutate({ page: 1 });
    } else {
      setGreetingList(greetingListData?.data?.data ?? []);
    }
  }, [greetingListData]);

  const handleDropListClick = ({ value }, data) => {
    switch (value) {
      case 'edit':
        setEditIVRInstance({ isEdit: true, editData: data });
        setAddIVR(true);
        break;

      default:
        break;
    }
  };

  const tableActions = [
    {
      id: 1,
      access: ACCESS,
      label: 'Add IVR',
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddIVR(true)}
          size="sm"
        >
          Add IVR
        </Button>
      ),
    },
  ];

  const columns = [
    {
      accessorKey: 'name',
      header: () => 'Name',
      cell: (props) => props.getValue(),
    },
    {
      accessorKey: 'greeting_short',
      header: () => 'Menu Action Message',
      cell: (props) => {
        const element = props.row.original;
        let menu_message =
          (greetingList &&
            greetingList.filter(
              (file) => file?.filename === element?.greeting_short,
            )?.[0]?.name) ||
          'NA';
        return (
          <span className="d-flex align-items-center gutter gutter--1 pr-3 ">
            {menu_message !== 'NA' && <>{menu_message}</>}
            {menu_message === 'NA' && 'NA'}
          </span>
        );
      },
    },
    // {
    //   accessorKey: 'max_timeouts',
    //   header: () => 'Timeout',
    //   cell: (props) => `${props.getValue()} sec`,
    // },
    {
      accessorKey: 'exec_on_max_failures',
      header: () => 'Invalid Action',
      cell: (props) => {
        let timeout_action = JSON.parse(
          props.row.original?.exec_on_max_failures,
        );
        return timeout_action?.type;
      },
    },
    {
      accessorKey: 'exec_on_max_timeouts',
      header: () => 'Timeout Action',
      cell: (props) => {
        let timeout_action = JSON.parse(
          props.row.original?.exec_on_max_timeouts,
        );
        return timeout_action?.type;
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { greeting_short = '' } = element;
        const isPlaying =
          playingData?.playing &&
          playingData?.file === greeting_short &&
          playingData?.rowId === element?.uuid;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            {isPlaying ? (
              <span
                className="c--pointer"
                onClick={() => {
                  setPlayingData({ playing: false, file: null, rowId: '' });
                }}
              >
                <HoldIcon width={20} height={20} />
                <AudioPlayer
                  onClose={() => {
                    setPlayingData({ playing: false, file: null, rowId: '' });
                  }}
                  type="greeting"
                  name={greeting_short}
                />
              </span>
            ) : (
              <span
                className="c--pointer text--success"
                onClick={() => {
                  setPlayingData({
                    playing: true,
                    file: greeting_short,
                    rowId: element?.uuid,
                  });
                }}
              >
                <PlayIcon width={20} height={20} />
              </span>
            )}
            <Dropdown
              extraClasses="w-min--150 bg--black-200"
              closeOnClickOutside={false}
              dropList={{
                component: ({ item }) => {
                  if (item?.shouldConfirm) {
                    return (
                      <ConfirmComponent
                        item={item}
                        element={element}
                        label="Please confirm?"
                        confirmCallback={(data) => handleIVRDelete(data)}
                      >
                        {item?.title}
                      </ConfirmComponent>
                    );
                  }
                  return (
                    <span
                      className="w--full d--flex  justify-content--start p--2sm"
                      onClick={() => handleDropListClick(item, element)}
                    >
                      {item?.title}
                    </span>
                  );
                },
                data: [
                  { title: 'Edit', value: 'edit' },
                  { title: 'Delete', value: 'delete', shouldConfirm: true },
                ],
              }}
              showcaret={false}
            >
              <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={13} height={13} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];
  const handleClose = () => {
    setAddIVR(false);
    setEditIVRInstance(initialEditInstance);
  };
  const handleIVRDelete = ({ uuid = '' }) => {
    deleteIVRMutate({ uuid });
  };
  return (
    <div className="h--full w--full ">
      <TableManager
        {...{
          fetcherKey: 'ivrList',
          fetcherFn: ivrList,
          columns,
          name: `IVR (${moment().subtract(6, 'days').format('DD MMM')} -
          ${moment().format('DD MMM')}
        ) `,
          tableActions,
          shouldShowTotalCount: true,
          customTotalCount: true,
        }}
      />
      {addIVR && (
        <AsideModal
          handleClose={handleClose}
          title={editIVRInstance?.isEdit ? 'Edit IVR' : 'Add IVR'}
          footerComponent={null}
        >
          <AddIVR handleClose={handleClose} editIVRInstance={editIVRInstance} />
        </AsideModal>
      )}
    </div>
  );
};

export default IVR;
