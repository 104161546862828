import React, { useEffect, useState } from 'react';
import Button from '../../../comopnents/Button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useDIDDetails from '../../../hooks/useDIDDetails';
import useDidNumberPurchase from '../../../hooks/useDidNumberPurchase';
import flags from 'react-phone-number-input/flags';
import Spinner from '../../../comopnents/Spinner';
import BuyConfirmationModal from '../BuyConfirmationModal';
import Modal from '../../../comopnents/Modal';
import useCheckFreeDid from '../../../hooks/useCheckFreeDid';
import AsyncSelect from 'react-select/async';
import useDIDRegionList from '../../../hooks/useDIDRegionList';
import useDidNumbersList from '../../../hooks/useDidNumbersList';
import useDidNumberPurchaseNew from '../../../hooks/useDidNumberPurchaseNew';

const initialValues = {
  country: 'US',
  region: '',
  state: '',
  did_number: '',
};

const validationSchema = yup.object().shape({
  country: yup.string().required('Country is required'),
});

let promiseTimeout;

export default function BuyNumberForm({ handleClose = () => null }) {
  const [selectedDidData, setSelectedDidData] = useState(null);
  const [showConfirmation, setshowConfirmation] = useState(false);
  const [isFreeDidAvailable, setIsFreeDidAvailable] = useState(true);
  const { mutateAsync: checkFreeDidMutate, isLoading: checkFreeDidLoad } =
    useCheckFreeDid();

  const {
    mutate: didNumberListMutate,
    data: didNumberList,
    isLoading: didListLoad,
  } = useDidNumbersList();

  const { mutateAsync: didRegionsMutate } = useDIDRegionList();

  const {
    mutate: mutateDIDDetails,
    data: didDetails,
    isLoading: didDetailsLoad,
  } = useDIDDetails();
  const { mutate: didPurchaseMutate, isLoading: didPurchaseLoad } =
    useDidNumberPurchaseNew(handleClose);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (didDetails?.data?.data) {
      setSelectedDidData((prev) => ({
        ...prev,
        did_cost: didDetails?.data?.data?.data,
      }));
    }
  }, [didDetails]);

  useEffect(() => {
    if (watch('region')) {
      didNumberListMutate(watch('region'));
    }
  }, [watch('region')]);

  useEffect(() => {
    if (watch('did_number')) {
      (async () => {
        const res = await checkFreeDidMutate();
        if (res?.data?.data?.ResponseCode === 0) {
          setIsFreeDidAvailable(false);
        } else {
          setIsFreeDidAvailable(true);
        }
      })();
    }
  }, [watch('did_number')]);

  const purchaseDid = async () => {
    setshowConfirmation(false);
    const payload = {
      did_number: watch('did_number'),
    };
    didPurchaseMutate(payload);
  };
  async function onSubmit(values) {
    if (isFreeDidAvailable) {
      purchaseDid();
      return;
    }
    if (selectedDidData) {
      setshowConfirmation(true);
    }
  }

  const loading = isSubmitting || didPurchaseLoad;

  const filterOptions = async (inputValue) => {
    if (inputValue && inputValue.length > 3) {
      const options = await didRegionsMutate(inputValue);
      const regions = options?.data?.data || [];
      return regions
        .filter((i) =>
          i?.city?.toLowerCase().includes(inputValue?.toLowerCase()),
        )
        .map((item) => {
          return {
            label: `${item.city} ${
              item?.province ? `(${item?.province})` : ''
            }`,
            value: `${item?.state}_${item.city}`,
          };
        });
    } else {
      return [];
    }
  };
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      if (promiseTimeout) {
        clearTimeout(promiseTimeout);
      }
      promiseTimeout = setTimeout(() => {
        resolve(filterOptions(inputValue));
      }, 1000);
    });

  return (
    <form
      className="w--full h--full virtualForm"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
        <div className="d--flex align-items--center gap--sm">
          <span className="w-min--28 w-max--28 d--flex">
            {flags['US']({ title: 'US' })}
          </span>
          <div className="label--control font--sm font--400 m-b--xs  text--black">
            United States
          </div>
        </div>
        <div>
          <label className="label--control font--sm font--500 m-b--xs  text--black d--flex align-items--end gap--sm">
            Select Region
          </label>
          <Controller
            name="region"
            control={control}
            render={() => (
              <AsyncSelect
                isClearable
                cacheOptions
                defaultOptions
                classNamePrefix={'react-select-input'}
                loadOptions={promiseOptions}
                placeholder="Type name of city or state"
                onChange={(e) => {
                  setValue('region', e?.value);
                }}
              />
            )}
            rules={{ required: true }}
          />
        </div>

        {!didListLoad && didNumberList?.data?.data?.length > 0 ? (
          <div>
            <label className="label--control font--sm font--500 m-b--xs  text--black d--flex gap--sm">
              Select Virtual Number
            </label>
            <div className="w--full d--grid grid--3 gap--sm h-max--400 overflow--auto virtualNumberList">
              {didNumberList?.data?.data.map((did) => {
                return (
                  <div
                    className={`${
                      selectedDidData?.phone_number === did?.phone_number
                        ? 'bg--primary text--white'
                        : 'bg--contrast'
                    }  h-min--36 h-max--36 radius--sm d--flex align-items--center justify-content--center c--pointer`}
                    onClick={() => {
                      setValue('did_number', did?.phone_number);
                      setSelectedDidData(did);
                      mutateDIDDetails();
                    }}
                  >
                    {did?.phone_number}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="w--28 h--max-50 m-t--xl d--flex align-items--center justify-content--center">
            {watch('region') && (
              <>
                {!didListLoad && <label for="">No data found</label>}
                {didListLoad && <Spinner size="sm" />}
              </>
            )}
          </div>
        )}
      </div>

      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={loading}
        >
          {loading
            ? 'Please wait..'
            : selectedDidData
            ? isFreeDidAvailable
              ? 'Buy for free'
              : `Buy $${selectedDidData?.cost}`
            : 'Buy'}
        </Button>
      </div>
      {showConfirmation && (
        <Modal
          headerComponent={null}
          footerComponent={null}
          handleClose={() => setshowConfirmation(false)}
          shouldCloseOnClickOutside={false}
          height="150"
          radius="sm"
        >
          <div
            className="d--flex flex--column align-items--center justify-content--center h-min--150 "
            onClick={(e) => e.stopPropagation()}
          >
            <BuyConfirmationModal
              selectedDidData={selectedDidData}
              handleSuccess={() => handleClose()}
              handleClose={() => {
                setshowConfirmation(false);
              }}
            />
          </div>
        </Modal>
      )}
    </form>
  );
}
