import React, { useState } from 'react';
import Button from '../../../comopnents/Button';
import useAvailableDidList from '../../../hooks/useAvailableDidList';
import useAssignDID from '../../../hooks/useAssignDID';

const AssignDIDModal = ({ assignDIDInstance, handleClose = () => null }) => {
  const [didNumbers, setDidNumbers] = useState([]);
  const { data: availableDidList = [] } = useAvailableDidList();
  const { mutate, isLoading } = useAssignDID({
    handleSuccess: () => handleClose(),
  });

  function onSubmit() {
    if (didNumbers?.length > 0) {
      mutate({
        uuid: assignDIDInstance?.uuid,
        did_number: didNumbers,
      });
    }
  }
  return (
    <div
      className=" d--flex flex--column gap--md "
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d--flex flex--column gap--md overflow--auto h-min--200 h-max--300">
        {availableDidList?.length > 0 &&
          availableDidList.map((item) => {
            return (
              <div className="d--flex gap--sm" key={item?.uuid}>
                <input
                  id={item?.uuid}
                  type="checkbox"
                  className="form--control no--focus"
                  checked={didNumbers.includes(item?.did_number)}
                  onChange={() => {
                    if (didNumbers.includes(item?.did_number)) {
                      setDidNumbers((prev) => {
                        return prev.filter(
                          (did_number) => did_number !== item?.did_number,
                        );
                      });
                    } else {
                      setDidNumbers((prev) => [...prev, item?.did_number]);
                    }
                  }}
                />
                <label
                  className={`label--control font--sm font--500  d--flex c--pointer`}
                  htmlFor={item?.uuid}
                >
                  {item?.did_number}
                </label>
              </div>
            );
          })}
      </div>
      <div className="d--flex justify-content--between gap--md  p-t--sm">
        {/* <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={handleClose}
        >
          Cancel
        </Button> */}
        <Button
          type="button"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={isLoading || didNumbers.length === 0}
          onClick={onSubmit}
        >
          {isLoading ? 'Please wait..' : 'Assign'}
        </Button>
      </div>
    </div>
  );
};

export default AssignDIDModal;
