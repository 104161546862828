import { useMutation } from '@tanstack/react-query';
import { portingRequest, updatePortingRequest } from '../../api';
import { useAlert } from '../useAlert';

export default function usePortingRequest({
  handleSuccess = () => null,
  isEdit = false,
} = {}) {
  const { showAlert } = useAlert();
  const portingRequestMutation = useMutation({
    mutationFn: isEdit ? updatePortingRequest : portingRequest,
    mutationKey: 'portingRequest',
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.message ?? '',
      });
      handleSuccess();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...portingRequestMutation };
}
