import React, { useMemo, useState } from 'react';
import useIcons from '../../../assets/icons/useIcons';
import Modal from '../../../comopnents/Modal';
import AddCardForm from './AddCardForm';
import TableManager from '../../../comopnents/TableManager';
import { formatDateOnly } from '../../../helpers/functions';
import Button from '../../../comopnents/Button';
import { getSavedCards } from '../../../api';
import Dropdown from '../../../comopnents/Dropdown';
import useSetAsDefault from '../../../hooks/useSetAsDefault';
import ConfirmComponent from '../../../comopnents/ConfirmComponent';
import useDeleteCard from '../../../hooks/useDeleteCard';

function ManageCards() {
  const { AddCardIcon, MoreVIcon } = useIcons();
  const [openPaymentModal, setopenPaymentModal] = useState(false);
  const { mutate: setDefaultMutate } = useSetAsDefault();

  const { mutate: deleteCardMutate } = useDeleteCard();
  const handleDropListClick = ({ value }, data) => {
    switch (value) {
      case 'default':
        setDefaultMutate({ card_id: data?.card_id });
        break;
      case 'delete':
        deleteCardMutate({ card_id: data?.card_id });
        break;
      default:
        break;
    }
  };

  const tableActions = useMemo(
    () => [
      {
        id: 1,
        access: true,
        component: (
          <Button
            type="button"
            btnClasses="btn w-min--150"
            variant="primary"
            color="white"
            onClick={() => setopenPaymentModal(true)}
            size="sm"
          >
            <AddCardIcon width={20} height={20} />
            &nbsp;Add Card
          </Button>
        ),
      },
    ],
    [],
  );

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: ({ row }) => {
        return formatDateOnly(row?.original?.created_at);
      },
    },

    {
      accessorKey: 'last4',
      header: () => 'Last 4 Digits',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'exp_year',
      header: () => 'Expiry Year',
      meta: {
        textAlign: 'center',
      },
    },

    {
      accessorKey: 'is_primary',
      header: () => 'Is Primary',
      cell: (props) => {
        return props?.getValue() === 0 ? 'No' : 'Yes';
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        if (element?.is_primary) return;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <Dropdown
              extraClasses="w-min--150"
              closeOnClickOutside={false}
              dropList={{
                component: ({ item }) => {
                  if (item?.shouldConfirm) {
                    return (
                      <ConfirmComponent
                        item={item}
                        element={element}
                        label="Please confirm?"
                        confirmCallback={(data) => {
                          if (item?.value === 'delete') {
                            deleteCardMutate({ card_id: data?.card_id });
                          }
                        }}
                      >
                        {item?.title}
                      </ConfirmComponent>
                    );
                  }
                  return (
                    <span
                      className="w--full d--flex  justify-content--start p--2sm"
                      onClick={() => handleDropListClick(item, element)}
                    >
                      {item?.title}
                    </span>
                  );
                },
                data: [
                  {
                    title: 'Set as default',
                    value: 'default',
                    access: element?.is_primary === 0,
                  },
                  {
                    title: 'Delete',
                    value: 'delete',
                    shouldConfirm: true,
                    access: element?.is_primary === 0,
                  },
                ],
              }}
              showcaret={false}
            >
              <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={13} height={13} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        width: '5%',
      },
    },
  ];
  return (
    <div className="w--full h--full">
      <TableManager
        fetcherKey={'getSavedCards'}
        fetcherFn={getSavedCards}
        columns={columns}
        name={'Manage Cards'}
        shouldFilter={false}
        tableActions={tableActions}
      />
      {openPaymentModal ? (
        <Modal
          footerComponent={null}
          title="Add Card"
          handleClose={() => setopenPaymentModal(false)}
          shouldCloseOnClickOutside={false}
          height="400"
        >
          <AddCardForm handleClose={() => setopenPaymentModal(false)} />
        </Modal>
      ) : null}
    </div>
    // <div className="w--full h--full manageCardsPage">
    //   <h4 class="font--md font--400 m-b--md h-max--40 m-t--sm text--primary">Manage Cards</h4>
    //   <div className="d--flex gap--md flex--column profilePage bg--white p--md radius--sm h--full overflow--auto box-shadow--xs w--full ">
    //     <div className="d--flex gap--md w--full flex--wrap">
    //       <div className="w--full h-min--200 overflow--auto h-max--200 m-b--md">
    //         {getcardlisting?.length > 0
    //           ? getcardlisting?.map((item) => {
    //               console.log(item);
    //               return (
    //                 <div className="w--full h-min--100 w-max--250 w-min--250 p--md bg--primary-100 radius--sm border-full--black-200 d--flex flex--column gap--md  c--pointer">
    //                   <div className="d--flex align-items--center justify-content--between">
    //                     <div>
    //                       <img src={ImgVisa} alt="ImgVisa" width={40} />
    //                     </div>
    //                     <div className="d--flex align-items--center gap--sm">
    //                       {item.is_primary === 1 ? (
    //                         <div className="radius--full h-min--28 w-min--100 bg--white d--flex align-items--center justify-content--center c--pointer">
    //                           Primary
    //                         </div>
    //                       ) : null}

    //                       <MoreVIcon width={15} height={15} />
    //                     </div>
    //                   </div>
    //                   <div className="font--md font--600">
    //                     Visa **** ****
    //                     {item.last4}
    //                   </div>
    //                   <div className="font--sm font--600 text--black-400">
    //                     Expiry Date {item.exp_year}
    //                   </div>
    //                   <div className="font--xs font--600 text--r">
    //                     LAST USED 22-02-2024
    //                   </div>
    //                 </div>
    //               );
    //             })
    //           : 'No saved card found!'}
    //       </div>

    //       <div
    //         className="w--full h-min--100 w-max--250 w-min--250   d--flex flex--column d--flex align-items--center justify-content--center gap--xs c--pointer"
    //         onClick={() => setopenPaymentModal(true)}
    //       >
    //         <div className="w-min--60 h-min--60 w-max--60 h-max--60 radius--sm  text--primary d--flex align-items--center justify-content--center">
    //           <AddCardIcon width={45} height={45} />
    //         </div>
    //         <div className="font--md font--600 text--primary">Add Cards</div>
    //       </div>
    //     </div>
    //   </div>

    // </div>
  );
}

export default ManageCards;
