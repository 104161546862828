import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import FormInput from '../../../comopnents/FormInput';
import Button from '../../../comopnents/Button';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import useAddContact from '../../../hooks/useAddContact';
import { useCallSummary } from '../../../hooks/useCallSummary';
import imageCompression from 'browser-image-compression';
import ImageCropModal from '../../../comopnents/ImageCropModal';
import { AGENCY_OWNER, USER } from '../../../helpers/functions';
import { useAuth } from '../../../hooks/useAuth';
import useUploadMedia from '../../../hooks/useUploadMedia';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import noImage from '../../../assets/images/no_image.jpg';
import useIcons from '../../../assets/icons/useIcons';
import Avatar from '../../../comopnents/Avatar';

const optionsforCompressing = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  maxIteration: 20,
};

const initialValues = {
  contact_pic: '',
  first_name: '',
  last_name: '',
  gender: 'M',
  email: '',
  dob: '',
  phone: '',
  company: '',
  address: [{ street: '', city: '', state: '', zip: '' }],
  // notes: '',
  webpage: '',
};

const validationSchema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  // .matches(/^[A-Za-z]+$/, 'First name cannot contain space or numbers'),
  last_name: yup.string().required('Last name is required'),
  // .matches(/^[A-Za-z]+$/, 'Last name cannot contain space or numbers'),
  email: yup
    .string()
    // .required('Email is required')
    .email('Invalid email')
    .max(50, 'Maximum length can be 50 characters'),
  // dob: yup.string().required('DOB is required'),
  // phone: yup.string().required('Phone is required'),
  // gender: yup.string().required('Gender is required'),
});

export default function AddContactForm({
  editContactInstance = {},
  handleClose = () => null,
  fromDialpad = false,
  formActivity = false,
}) {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [imageUpload, setImageUpload] = useState(null);
  const { direction = '' } = useCallSummary();
  const { user } = useAuth();
  const { UserIcon, PencilIcon, TrashIcon } = useIcons();
  const { socket = {} } = useContext(SipSocketContext);
  const { _uiSessions = {}, _uaSessions = {} } = socket;

  let activeCallKey = Object.keys(_uiSessions)?.[0];

  const forwardValue = useMemo(() => {
    return (
      _uaSessions?.[activeCallKey]?._request?.headers?.['X-Forwardvalue']?.[0]
        ?.raw ?? null
    );
  }, [activeCallKey, _uaSessions]);

  const forwardType = useMemo(() => {
    return (
      _uaSessions?.[activeCallKey]?._request?.headers?.['X-Forwardtype']?.[0]
        ?.raw ?? null
    );
  }, [activeCallKey, _uaSessions]);

  const {
    watch,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const { fields: addressFields } = useFieldArray({
    control,
    name: 'address',
  });
  const { mutateAsync: getUploadUrl } = useUploadMedia();

  const { mutate: addContactMutate, isLoading: addMemberLoad } = useAddContact(
    handleClose,
    fromDialpad,
  );

  useEffect(() => {
    const { isEdit = false, editData = {} } = editContactInstance;

    if (
      isEdit &&
      editData &&
      Object.keys(editData) &&
      Object.keys(editData).length > 0 &&
      !fromDialpad
    ) {
      const {
        first_name = '',
        last_name = '',
        email = '',
        dob = '',
        gender = '',
        phone = '',
        company = '',
        address,
        contact_pic = '',
        webpage = '',
      } = editData;

      reset({
        first_name,
        last_name,
        email,
        dob,
        gender,
        phone,
        company,
        address,
        contact_pic,
        webpage,
      });
    } else if (fromDialpad) {
      setValue('phone', editData?.phone);
    }
  }, [editContactInstance, fromDialpad]);

  async function onSubmit(values) {
    const { isEdit = false, editData = {} } = editContactInstance;
    const {
      first_name = '',
      last_name = '',
      dob = '',
      phone = '',
      email,
      gender,
      company,
      address,
      webpage,
      contact_pic,
    } = values;
    const payload = {
      contact_pic: editData?.contact_pic,
      first_name,
      last_name,
      dob,
      email,
      gender,
      company,
      phone,
      address,
      webpage,
      domain: user?.domain,
      extension: user?.extension,
      mode:
        _uiSessions?.[activeCallKey]?._direction === 'inbound'
          ? forwardType
          : formActivity || isEdit
          ? editContactInstance?.editData?.mode || 'Extension'
          : 'Extension',
      mode_uuid:
        _uiSessions?.[activeCallKey]?._direction === 'inbound'
          ? forwardValue
          : formActivity || isEdit
          ? editContactInstance?.editData?.mode_uuid || user?.extension
          : user?.extension,
      // save_type: fromDialpad
      //   ? direction === 'outbound'
      //     ? 'outgoing'
      //     : 'incoming'
      //   : 'normal',
    };
    if (isEdit || formActivity) {
      payload.contact_uuid = editData?.uuid;
    }
    if (contact_pic && typeof contact_pic !== 'string') {
      setImageUpload(true);
      const uploadPayload = {
        uuid: [USER, AGENCY_OWNER].includes(user?.role_id)
          ? user?.uuid
          : user?.parent_uuid || user?.uuid,
        file_name: 'contact_img.png',
        type: 'contact',
      };
      const res = await getUploadUrl(uploadPayload);
      const { filename, url: uploadUrl } = res?.data?.data || '';
      if (uploadUrl) {
        const uploadFileResponse = await fetch(uploadUrl, {
          method: 'PUT',
          body: contact_pic,
        });
        if (uploadFileResponse.status === 200) {
          payload.contact_pic = `${process.env.REACT_APP_MEDIA_URL}signed-url/${
            user?.parent_uuid || user?.uuid
          }/contact?filename=${filename}`;
          setImageUpload(false);
        }
      }
      setImageUpload(false);
    }
    addContactMutate(payload);
  }

  const handleFileSelect = async (e) => {
    let file = e.target.files[0];
    if (file) {
      try {
        imageCompression(file, optionsforCompressing)
          .then((compressedFile) => {
            const reader = new FileReader();
            reader.onload = () => {
              setImage(reader.result);
            };
            reader.readAsDataURL(compressedFile);
          })
          .catch((err) => console.log('err while compressing', err));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFileUpload = async (data) => {
    fetch(data)
      .then((res) => res.blob())
      .then(async (file) => {
        setImage(null);
        setValue('contact_pic', file);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const loading = isSubmitting || addMemberLoad;
  return (
    <form
      className="w--full h--full"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={`overflow--auto d--flex flex--column gap--md p--md bodyForm ${
          !fromDialpad ? 'dialogScroll' : ''
        }`}
      >
        <div className="d--flex position--relative avatarImage w-max--100 radius--full">
          <label
            htmlFor="contact_image_upload"
            className={`border c--pointer border-full--black-50 text--primary overflow--hidden radius--full  bg--primary-100 w-max--100 w-min--100  h-max--100 h-min--100 d--flex align-items--center justify-content--center `}
          >
            {watch('contact_pic') ? (
              <img
                src={
                  typeof watch('contact_pic') === 'string' &&
                  watch('contact_pic')
                    ? watch('contact_pic')
                    : watch('contact_pic')
                    ? URL.createObjectURL(watch('contact_pic'))
                    : noImage
                }
                alt="profile"
                className="radius--full object--cover h--full w--full"
              />
            ) : watch('first_name') ? (
              <Avatar
                size="100"
                first_name={watch('first_name') || 'U'}
                last_name={watch('last_name') || ''}
                shouldShowPresence={false}
              />
            ) : (
              <UserIcon />
            )}
          </label>
          <div className="w--full d--flex flex--column position--absolute">
            <FormInput
              type="file"
              accept="image/*"
              ref={fileInputRef}
              id="contact_image_upload"
              name="filename"
              height="100"
              extraClasses="opacity--0 p-t--xl c--pointer"
              onChange={(e) => {
                handleFileSelect(e);
                e.target.value = '';
              }}
              hidden
            />
          </div>
          {/* <div className="overlayImage position--absolute radius--full w--full h--full d--none justify-content--center align-items--center gap--sm w-max--100">
            <div
              className="text--danger c--pointer w-min--28 h-min--28 w-max--28 h-max--28 radius--sm bg--white d--flex align-items--center justify-content--center"
              // onClick={handleFile}
            >
              <PencilIcon width={18} />
            </div>

            <div
              className="text--danger c--pointer w-min--28 h-min--28 w-max--28 h-max--28 radius--sm bg--white d--flex align-items--center justify-content--center"
              // onClick={handleDelete}
            >
              <TrashIcon width={18} />
            </div>
          </div> */}
        </div>
        <div className="d--flex flex--column gap--md innerScrollForm">
          <div className="d--flex gap--md">
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="First Name"
                  placeholder="Enter your first name"
                  error={errors?.first_name?.message}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Last Name"
                  placeholder="Enter your last name"
                  error={errors?.last_name?.message}
                />
              )}
            />
          </div>
          <div className="w--full d--flex gap--md align-items--center h-min--32">
            <label
              className={`label--control font--sm font--500 ${
                errors?.gender?.message ? 'text--danger' : 'text--black'
              }`}
            >
              {errors?.gender?.message ?? 'Gender'}
            </label>
            <div className="w--full w-max--350 d--flex gap--lg">
              <div className="d--flex align-items--center gap--sm ">
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      className="radio"
                      id="male"
                      type="radio"
                      value="M"
                      checked={watch('gender') === 'M'}
                    />
                  )}
                />
                <label htmlFor="male" className="font--sm c--pointer">
                  Male
                </label>
              </div>
              <div className="d--flex align-items--center gap--sm ">
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      className="radio"
                      id="female"
                      type="radio"
                      value="F"
                      checked={watch('gender') === 'F'}
                    />
                  )}
                />
                <label htmlFor="female" className="font--sm c--pointer">
                  Female
                </label>
              </div>
            </div>
          </div>
          <div>
            <Controller
              name="company"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Company"
                  placeholder="Enter company"
                  error={errors?.company?.message}
                />
              )}
            />
          </div>
          <div className="d--flex flex--column gap--md w--full">
            {addressFields.map((field, index) => (
              <div
                key={field.id}
                className="d--flex gap--md align-items--start w--full"
              >
                <div className="d--flex gap--md flex--column w--full">
                  <div className="d--flex gap--md w--full">
                    <Controller
                      name={`address.${index}.street`}
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          type="text"
                          label="Street"
                          placeholder="Enter Street"
                          error={errors?.company?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`address.${index}.city`}
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          type="text"
                          label="City"
                          placeholder="Enter City"
                          error={errors?.company?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="d--flex gap--md w--full">
                    <Controller
                      name={`address.${index}.state`}
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          type="text"
                          label="State"
                          placeholder="Enter State"
                          error={errors?.company?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`address.${index}.zip`}
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          type="number"
                          label="Zip"
                          placeholder="Enter Zip"
                          error={errors?.company?.message}
                        />
                      )}
                    />
                  </div>
                </div>
                {/* <div className="d--flex gap--xs m-t--lg  white--space-none w-min--60">
                {index !== 0 && (
                  <Button
                    type="button"
                    variant="contrast"
                    rounded
                    color="black"
                    btnClasses="w-max--28 h-max--28 w-min--28 h-min--28 font--md c--pointer"
                    onClick={() => addressremove(index)}
                  >
                    -
                  </Button>
                )}

                <Button
                  type="button"
                  variant="contrast"
                  rounded
                  color="black"
                  btnClasses="w-max--28 h-max--28 w-min--28 h-min--28 font--md c--pointer"
                  onClick={() =>
                    addressappend({ street: '', city: '', state: '', zip: '' })
                  }
                >
                  +
                </Button>
              </div> */}
              </div>
            ))}
          </div>
          <div>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Email"
                  placeholder="Enter your email"
                  error={errors?.email?.message}
                />
              )}
            />
          </div>
          {/* <div>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="Notes"
                placeholder="Enter Notes"
                error={errors?.notes?.message}
              />
            )}
          />
        </div> */}
          <div>
            <Controller
              name="webpage"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Webpage"
                  placeholder="Enter link"
                  error={errors?.webpage?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="dob"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="date"
                  label="DOB"
                  placeholder="Enter your name"
                  error={errors?.dob?.message}
                  max={new Date().toISOString().split('T')[0]}
                />
              )}
            />
          </div>
          <div>
            <label
              className={`label--control font--sm font--500 m-b--xs   ${
                errors?.phone?.message ? 'text--danger' : 'text--black'
              }`}
            >
              {errors?.phone?.message ? 'Phone is required' : 'Phone'}
            </label>

            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  disabled={fromDialpad || editContactInstance?.isEdit}
                  control={control}
                  defaultCountry={'US'}
                  international={true}
                  addInternationalOption={false}
                  withCountryCallingCode={true}
                  countryCallingCodeEditable={true}
                  placeholder="Enter your phone"
                  internationalIcon={() => null}
                  flagComponent={() => null}
                  countrySelectComponent={() => null}
                  value={getValues(`phone`)}
                  limitMaxLength={true}
                  onChange={(phone) => setValue(`phone`, phone)}
                  className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                    errors?.phone?.message
                      ? 'border-full--danger'
                      : 'border-full--black-200'
                  }`}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100 formFoot">
        {!formActivity && (
          <Button
            type="button"
            size=""
            variant="contrast"
            color="primary"
            btnClasses="btn"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={loading || imageUpload}
        >
          {loading || imageUpload ? 'Please wait..' : 'Save'}
        </Button>
      </div>
      <ImageCropModal
        {...{
          image,
          setImage,
          setImageUpload: () => null,
          upload: (data) => handleFileUpload(data),
        }}
      />
    </form>
  );
}
