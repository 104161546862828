import { useMutation, useQueryClient } from '@tanstack/react-query';
import { upgradeplan } from '../../api';
import { useAlert } from '../useAlert';

export default function useUpgradeplan({ handleSuccess = () => null } = {}) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const upgradePlanRequest = useMutation({
    mutationFn: upgradeplan,
    mutationKey: 'upgradeplan',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('getUserDetails');
      showAlert({
        type: 'success',
        message: data?.message ?? '',
      });
      handleSuccess(data);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...upgradePlanRequest };
}
