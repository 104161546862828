import { useMutation } from '@tanstack/react-query';
import { getPlan } from '../../api';

export default function useGetPlan() {
  const addAgencyRequest = useMutation({
    mutationFn: getPlan,
    mutationKey: 'getPlan',
  });
  return { ...addAgencyRequest };
}
