import React from 'react';

const FormSelect = (
  {
    placeholder,
    label,
    error,
    extraClasses = '',
    options = [],
    renderOption: RenderOption,
    height = '36',
    paddingLeft = 'md',
    paddingRight = 'md',
    optionListLoad = false,
    ...rest
  },
  ref,
) => {
  const text = error || label || '';
  return (
    <div className="w--full  d--flex flex--column">
      {text && (
        <label
          className={`label--control font--sm font--500 m-b--xs  ${
            error ? 'text--danger' : 'text--black'
          }`}
        >
          {text}
        </label>
      )}

      <select
        {...rest}
        ref={ref}
        placeholder={placeholder}
        className={`form--control w--full h-min--${height}   radius--sm p-l--${paddingLeft} p-r--${paddingRight} ${extraClasses} ${
          error ? 'border-full--danger' : 'border-full--black-200'
        }`}
        disabled={optionListLoad}
      >
        {optionListLoad && (
          <option className="text--black" value={rest?.value}>
            Please wait...
          </option>
        )}
        <option className="text--black" value="">
          Select
        </option>
        {options &&
          options.length > 0 &&
          options.map((item) => <RenderOption key={item.uuid} item={item} />)}
      </select>
    </div>
  );
};

export default React.forwardRef(FormSelect);
