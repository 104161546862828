import { useState } from 'react';
import Recorder from '../../../../../comopnents/Recorder';
import moment from 'moment';
import useIcons from '../../../../../assets/icons/useIcons';
import { useAlert } from '../../../../../hooks/useAlert';

const GreetingRecorder = ({ setValue }) => {
  const { MickIcon, CheckIcon, CrossRoundIcon } = useIcons();
  const [isRecording, setIsRecording] = useState(false);
  const [playRecordedFile, setPlayRecordedFile] = useState(false);
  const [blobUrl, setBlobUrl] = useState('');
  const { showAlert } = useAlert();
  const getAudioBlob = (metaData) => {
    const file = new File(
      metaData?.buffer,
      `greetingRecording${moment().unix()}.mp3`,
      {
        type: metaData?.blob.type,
        lastModified: Date.now(),
        size: metaData?.blob.size,
      },
    );
    const blobURL = window.URL.createObjectURL(metaData?.blob);
    setBlobUrl(blobURL);
    setValue('recorded_file', file, { shouldValidate: true });
  };

  return (
    <div className="d--flex flex--column w--full justify-content--center gap--lg border-full--primary-200 radius--sm h-min--150 p--md ">
      <div className="d--flex w--full justify-content--center align-items--center   ">
        <div className="bg--contrast d--flex align-items--center justify-content--center w-max--60 w-min--60 h-min--60 radius--full  ">
          <MickIcon width={40} height={40} />
        </div>
      </div>
      {isRecording && (
        <div className="d--flex w--full flex--column justify-content--center align-items--center p-l--md p-r--md">
          <div className="font--md m-b--sm">Listening</div>
          <div className="text--danger ">
            <Recorder getAudioBlob={getAudioBlob} />
          </div>
        </div>
      )}
      <div className="w--full d--flex justify-content--center">
        {!isRecording && !playRecordedFile && (
          <div
            className="w-min--150 h-min--36 d--flex justify-content--center align-items--center bg--danger  text--white radius--xl  c--pointer"
            onClick={() => {
              navigator.mediaDevices
                .getUserMedia({
                  audio: true,
                  video: false,
                })
                .then(() => {
                  setIsRecording(true);
                })
                .catch(() => {
                  showAlert({
                    type: 'secondary',
                    message: 'Microphone access is denied',
                  });
                });
            }}
          >
            Start Recording
          </div>
        )}
        {playRecordedFile && blobUrl && (
          <div className="d--flex  flex--column justify-content--center align-items--center ">
            <div className="d--flex p-l--md p-r--md p-b--md w--full w-min--300">
              <audio
                className="w--full h-max--40"
                preload="auto"
                src={blobUrl}
                controls
              ></audio>
              <div
                className="text--danger d--flex align-items--center justify-content--center w-max--40 w-min--40 h-min--40 radius--full c--pointer "
                onClick={() => {
                  setIsRecording(false);
                  setPlayRecordedFile(false);
                  setBlobUrl('');
                  setTimeout(() => {
                    setValue('recorded_file', null, { shouldValidate: true });
                  }, 500);
                }}
              >
                <CrossRoundIcon width={40} height={40} />
              </div>
            </div>
            <div
              className="w-min--150 w-max--150 h-min--36 d--flex justify-content--center align-items--center bg--danger text--white radius--xl c--pointer "
              onClick={() => {
                setIsRecording(true);
                setPlayRecordedFile(false);
                setBlobUrl('');
              }}
            >
              Record Again
            </div>
          </div>
        )}
        {isRecording && (
          <div className="d--flex align-items--center justify-content--center gap--sm w--full ">
            <div
              className="text--danger d--flex align-items--center justify-content--center w-max--40 w-min--40 h-min--40 radius--full c--pointer "
              onClick={() => {
                setIsRecording(false);
                setTimeout(() => {
                  setValue('recorded_file', null, { shouldValidate: true });
                }, 500);
              }}
            >
              <CrossRoundIcon width={40} height={40} />
            </div>
            <div
              className="text--success  d--flex align-items--center justify-content--center w-max--40 w-min--40 h-min--40 radius--full c--pointer"
              onClick={() => {
                setPlayRecordedFile(true);
                setIsRecording(false);
              }}
            >
              <CheckIcon width={40} height={40} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GreetingRecorder;
