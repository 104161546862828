import { useMutation, useQueryClient } from '@tanstack/react-query';
import { reportsRecordingSeen } from '../../api';

export default function useCallRecordingSeen({
  handleSuccess = () => null,
} = {}) {
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: reportsRecordingSeen,
    mutationKey: 'reportsRecordingSeen',
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['callHistory'],
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: ['reportsCallRecordingList'],
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: ['reportsCallList'],
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: ['reportsVoicemailList'],
        exact: false,
      });
      // queryClient.invalidateQueries({
      //   queryKey: ['unseenVoicemailList'],
      //   exact: false,
      // });
      queryClient.invalidateQueries({
        queryKey: ['unseenVoiceMailCount'],
        exact: false,
      });
      handleSuccess();
    },
  });
  return { ...Request };
}
