import { useMutation, useQueryClient } from '@tanstack/react-query';
import { agencyDelete } from '../../api';
import { useAlert } from '../useAlert';

export default function useAgencyDelete() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const agencyDeleteRequest = useMutation({
    mutationFn: agencyDelete,
    mutationKey: 'agencyDelete',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['agencyList'], exact: false });
      showAlert({
        type: 'success',
        message: data?.message ?? 'Agency deleted successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...agencyDeleteRequest };
}
