import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { ivrUpsert } from '../../api';

export default function useIVRUpsert() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: ivrUpsert,
    mutationKey: 'ivrUpsert',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['ivrList'],
        exact: false,
      });
      showAlert({
        type: 'success',
        message: data?.message ?? 'IVR upserted successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
