import React, { useEffect, useState } from 'react';
import {
  AGENT,
  converDateTimeFormateApis,
  durationToMMSS,
  EMPLOYEE,
  formatPhoneNumber,
} from '../../../helpers/functions';
import TableManager from '../../../comopnents/TableManager';
import { particularCallHistory, reportsVoicemailList } from '../../../api';
import useIcons from '../../../assets/icons/useIcons';
import AudioPlayer from '../../../comopnents/AudioPlayer';
import useAllNumberDetails from '../../../hooks/useAllNumberDetails';
import useMemberAgentsAllListing from '../../../hooks/useMemberAgentsAllListing';
import useIVRList from '../../../hooks/useIVRList';
import useRingGroupList from '../../../hooks/useRingGroupList';
import { useAuth } from '../../../hooks/useAuth';
import useNameNumberFilterListing from '../../../hooks/useNameNumberFilterListing';
import AsideModal from '../../../comopnents/AsideModal';
import Transcripts2 from '../../../comopnents/Transcripts2';
import useCallRecordingSeen from '../../../hooks/useCallRecordingSeen';
import { capitalizeWords } from '../../ActivityManagement/TimeLine/Activity';
import useContactListing from '../../../hooks/useContactListing';
import useDidList from '../../../hooks/useDidList';

const Voicemail = () => {
  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
    uuid: null,
  });
  const [memberListAll, setMemberListALL] = useState([]);
  const [showTranscript, setShowTranscript] = useState(false);
  const [transcriptData, setTranscriptData] = useState(null);

  const {
    PlayIcon,
    HoldIcon,
    MissedCall,
    OutgoingCall,
    IncomingCall,
    TranscriptsIcon,
    VoicemailIcon,
    VoicemailGroupIcon,
  } = useIcons();
  const { getNumberName } = useAllNumberDetails();
  const { nameNumberList } = useNameNumberFilterListing();
  const { dropdownContactList } = useContactListing();

  const { mutate: callRecordingMutateSeen } = useCallRecordingSeen();
  const { user } = useAuth();

  const { mutate: didListMutate, data: didListData } = useDidList();

  const { mutateAsync: mutateAsyncALL, data: dataALL } =
    useMemberAgentsAllListing();
  // const { mutateAsync: ivrListMutate, data: ivrListData } = useIVRList();
  const { mutate: ringGroupListMutate, data: ringGroupListData } =
    useRingGroupList();

  useEffect(() => {
    if (!dataALL?.data) {
      mutateAsyncALL({ get_owner: 'Y', page: 1 });
    } else {
      setMemberListALL(dataALL?.data?.data ?? []);
    }
  }, [dataALL]);

  useEffect(() => {
    // ivrListMutate();
    ringGroupListMutate({ page: 1 });
    didListMutate({ page: 1 });
  }, []);

  const handleSetTranscript = (data) => {
    setTranscriptData(data);
    setShowTranscript(true);
  };

  const callIconLookup = {
    Missed: (
      <span className="text--danger">
        <MissedCall width={16} height={16} />
      </span>
    ),
    Outbound: (
      <span className="text--success">
        <OutgoingCall width={16} height={16} />
      </span>
    ),
    Inbound: (
      <span className="text--primary">
        <IncomingCall width={16} height={16} />
      </span>
    ),
  };

  const columns = [
    {
      accessorKey: 'start_stamp',
      header: () => 'Date/Time',
      cell: (props) => {
        return converDateTimeFormateApis(props?.row?.original?.start_stamp);
      },
    },
    {
      accessorKey: 'caller_id_number',
      header: () => 'From',
      cell: (props) => {
        return (
          <div className="d--flex flex--column">
            <div>
              {getNumberName(props?.getValue(), false)?.includes('+')
                ? formatPhoneNumber(props?.getValue())
                : getNumberName(props?.getValue(), false)}
            </div>
            {props?.getValue()?.length < 5 && (
              <div className="font--xs">{`Ext. ${props?.getValue()}`}</div>
            )}
          </div>
        );
      },
      meta: {
        width: '15%',
      },
    },

    {
      accessorKey: 'via_did',
      header: () => 'Via Virtual Number',
      cell: (props) => formatPhoneNumber(props?.getValue()),
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },
    {
      accessorKey: 'forward_type',
      header: () => 'To',
      cell: (props) => {
        const elm = props?.row?.original;
        const res =
          props?.getValue() === 'VOICEMAILGROUP' ||
          props?.getValue() === 'VOICEMAIL'
            ? elm?.forward_name
            : props?.getValue();
        return (
          <div className="d--flex align-items--center gap--sm">
            {props?.getValue() === 'VOICEMAILGROUP' ? (
              <VoicemailGroupIcon width={20} height={20} />
            ) : (
              <VoicemailIcon width={20} height={20} />
            )}
            {capitalizeWords(res?.toLowerCase(), ' ')}
          </div>
        );
      },
    },
    {
      accessorKey: 'duration',
      header: () => 'Duration',
      cell: (props) => durationToMMSS(props.getValue()),
      meta: {
        textAlign: 'center',
        width: '10%',
      },
    },

    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { recording_file = '', xml_cdr_uuid = '' } = element;
        const isPlaying =
          playingData?.playing &&
          playingData?.file === recording_file &&
          playingData?.uuid === xml_cdr_uuid;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            {isPlaying ? (
              <span
                className="c--pointer"
                onClick={() => {
                  setPlayingData({ playing: false, file: null, uuid: null });
                }}
              >
                <HoldIcon width={20} height={20} />
                <AudioPlayer
                  onClose={() => {
                    setPlayingData({ playing: false, file: null, uuid: null });
                  }}
                  name={recording_file}
                />
              </span>
            ) : (
              <span
                className={`c--pointer ${
                  element?.seen_at ? 'text--success' : 'text--red'
                } `}
                onClick={() => {
                  if (!element?.seen_at) {
                    callRecordingMutateSeen({
                      uuid: element?.xml_cdr_uuid,
                    });
                  }
                  setPlayingData({
                    playing: true,
                    file: recording_file,
                    uuid: xml_cdr_uuid,
                  });
                }}
              >
                <PlayIcon width={20} height={20} />
              </span>
            )}
            <div
              data-tooltip={'Transcripts'}
              tooltip-position="left"
              onClick={() => handleSetTranscript(element)}
            >
              <TranscriptsIcon />
            </div>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];

  const filterInitialValues = {
    filter: [
      // {
      //   key: 'direction',
      //   value: '',
      //   isChecked: false,
      //   inputPlaceholder: 'Select',
      //   inputType: 'select',
      //   inputLabel: 'Call Direction',
      //   inputOptions: [
      //     {
      //       value: 'All',
      //       label: 'All',
      //     },
      //     {
      //       value: 'Inbound',
      //       label: 'Incoming',
      //     },
      //     {
      //       value: 'Outbound',
      //       label: 'Outbound',
      //     },
      //   ],
      //   isDisabled: false,
      //   enableCrossDisabling: true,
      //   crossDisablingFunction: ({
      //     valueToUpdate = {},
      //     triggerDisable = () => null,
      //     triggerEnable = () => null,
      //     resetDisable = () => null,
      //     customValueUpdate = () => null,
      //   }) => {
      //     const { value } = valueToUpdate;
      //     if (value === 'Inbound') {
      //       triggerEnable({ key: 'department' });
      //       triggerEnable({ key: 'ivr' });
      //       customValueUpdate({
      //         targetKey: 'extension',
      //         key: 'inputLabel',
      //         value: 'Extension',
      //       });
      //     }
      //     if (value === 'Outbound') {
      //       triggerDisable({ key: 'department' });
      //       triggerDisable({ key: 'ivr' });
      //       customValueUpdate({
      //         targetKey: 'extension',
      //         key: 'inputLabel',
      //         value: 'User',
      //       });
      //     }
      //     if (value === 'All') {
      //       resetDisable();
      //       customValueUpdate({
      //         targetKey: 'extension',
      //         key: 'inputLabel',
      //         value: 'Extension',
      //       });
      //     }
      //   },
      // },
      {
        key: 'name',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Name/Number',
        inputType: 'custom-selects-name-number',
        inputLabel: 'Name/Number',
        inputOptions: dropdownContactList,
      },
      {
        key: 'extension',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Extension Number',
        inputType: 'multi-select',
        inputLabel: 'Extension',
        inputOptions: memberListAll.map((item) => {
          return {
            label: `${item?.first_name} ${item?.last_name}`,
            value: item?.extension,
            first_name: item?.first_name,
            last_name: item?.last_name,
            profile_pic: item?.profile_pic,
            extension: item?.extension,
          };
        }),
      },
      {
        key: 'did_number',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter virtual number',
        inputType: 'select',
        inputLabel: 'Virtual Number',
        inputOptions: didListData?.data?.data?.map((item) => {
          return {
            label: item?.did_number,
            value: item?.did_number,
          };
        }),
      },
      {
        key: 'department',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Department',
        inputType: 'select',
        inputLabel: 'Department',
        inputOptions: ringGroupListData?.data?.data?.map((item) => {
          return {
            label: item?.title,
            value: item?.uuid,
          };
        }),
      },

      // {
      //   key: 'ivr',
      //   value: '',
      //   isChecked: false,
      //   inputPlaceholder: 'Enter IVR',
      //   inputType: 'select',
      //   inputLabel: 'IVR',
      //   inputOptions: ivrListData?.data?.data?.map((item) => {
      //     return {
      //       label: item?.name,
      //       value: item?.uuid,
      //     };
      //   }),
      //   access: ![AGENT, EMPLOYEE].includes(user?.role_id),
      // },
      {
        key: 'duration',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Duration',
        inputType: 'select',
        inputLabel: 'Duration',
        inputOptions: [
          {
            value: '0-2',
            label: '0-2',
          },
          {
            value: '2-5',
            label: '2-5',
          },
          {
            value: '5-10',
            label: '5-10',
          },
          {
            value: '10-50',
            label: '10-50',
          },
          {
            value: '50',
            label: '50',
          },
        ],
      },
      // {
      //   key: 'date',
      //   inputLabel: 'By Date',
      //   isChecked: false,
      //   value: {
      //     form: '',
      //     to: '',
      //   },
      //   inputType: 'date',
      //   dateType: '',
      // },
    ],
  };

  const handleClose = () => {
    setTranscriptData(null);
    setShowTranscript(false);
  };

  return (
    <div className="h--full w--full">
      <TableManager
        {...{
          fetcherKey: 'reportsVoicemailList',
          fetcherFn: reportsVoicemailList,
          columns,
          name: `Voicemail`,
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          shouldShowTotalCount: true,
          showMoreOption: (data) => {
            return data?.count > 1;
          },
          enableMoreOption: true,
          moreDataFunction: particularCallHistory,
          moreDataFnPayload: (data) => {
            return data?.xml_cdr_uuid;
          },
          customFilters: true,
        }}
      />
      {showTranscript && (
        <AsideModal
          handleClose={handleClose}
          title={'Call Intelligence'}
          footerComponent={null}
          width="md"
        >
          <Transcripts2
            transcript_file_url={transcriptData?.transcript_file_url}
            recording_file_url={`${process.env.REACT_APP_MEDIA_URL}signed-url/${
              user?.parent_uuid || user?.uuid
            }/recording?filename=${transcriptData?.recording_file}`}
          />
        </AsideModal>
      )}
    </div>
  );
};

export default Voicemail;
