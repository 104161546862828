import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import PaymentForm from '../../../PaymentForm';
import Button from '../../../../comopnents/Button';
import FormInput from '../../../../comopnents/FormInput';
import ConfirmationModal from '../../../Website/Pricing/ConfirmationModal';
import useUpgradeplan from '../../../../hooks/useUpgradeplan';
import { useNavigate } from 'react-router-dom';
import useGetSavedCards from '../../../../hooks/useGetSavedCards';

const initialValues = {
  card_holder_name: '',
};

const validationSchema = yup.object().shape({
  card_holder_name: yup
    .string()
    .matches(/^[A-Za-z ]+$/, 'Card holder name must contain only alphabets')
    .required('Card holder is required'),
});

function PaymentFormModal({ plan_uuid, plan_duration }) {
  const [selectedCard, setSelectedCard] = useState('');
  const { mutate: getCardListingMutate, data } = useGetSavedCards();
  const getcardlisting = data?.data?.data || [];
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const { mutate: upgradePlanMutate, isLoading: upgradePlanLoad } =
    useUpgradeplan({
      handleSuccess,
    });
  const [show, setshow] = useState(false);
  const confirmButtonRef = useRef(null);

  const {
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });
  useEffect(() => {
    getCardListingMutate();
  }, []);

  useEffect(() => {
    if (getcardlisting && getcardlisting.length > 0) {
      const primaryCardData =
        getcardlisting?.find((item) => item?.is_primary) || null;
      if (primaryCardData) {
        setSelectedCard(primaryCardData?.card_id);
      } else {
        setSelectedCard(getcardlisting?.[0]?.card_id);
      }
    }
  }, [getcardlisting?.length]);

  function handleSuccess() {
    navigate('/myaccount/plan');
  }

  const handleSubmitForm = async () => {
    if (tab === 0) {
      confirmButtonRef?.current?.handleSubmit({
        paymentType: 'upgradeplan',
        plan_uuid: plan_uuid,
        request_type: 'new-card',
        plan_duration: plan_duration,
      });
    } else {
      upgradePlanMutate({
        plan_uuid: plan_uuid,
        card_id: selectedCard,
        request_type: 'saved-card',
        plan_duration: plan_duration,
      });
    }
  };

  return (
    <div>
      <div className="d--flex w--full h--full PaymentFormPageSmall">
        <div className="d--flex w--full ">
          <div className="w--full radius--md  gap--xl w-max--600 p--md bg--white d--flex align-items--center justify-content--center flex--column  h-min--300">
            <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center">
              <h4 className="font--2xl text--primary">Payment</h4>
            </div>

            <div className="w--full d--flex align-items--center justify-content--center">
              <div className="w--full d--flex align-items--center justify-content--center gap--xs w-max--300 border-full--black-100 p--xs  bg--white radius--sm">
                <Button
                  variant={`${tab === 0 ? 'secondary btn' : 'primary-100 btn'}`}
                  color={`${tab === 0 ? 'white' : 'primary'}`}
                  onClick={() => setTab(0)}
                >
                  New Card
                </Button>
                <Button
                  variant={`${tab === 1 ? 'secondary btn' : 'primary-100 btn'}`}
                  color={`${tab === 1 ? 'white' : 'primary'}`}
                  onClick={() => setTab(1)}
                >
                  Saved Cards
                </Button>
              </div>
            </div>
            {tab === 0 && (
              <>
                <div className="w--full h--full h-min--230 " autoComplete="off">
                  <ConfirmationModal
                    show={show}
                    setshow={setshow}
                    onConfirm={() => {
                      handleSubmitForm();
                      setshow(false);
                    }}
                  />
                  <div className="overflow--auto  d--flex flex--column gap--md ">
                    <div>
                      <Controller
                        name="card_holder_name"
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            type="text"
                            label="Card Holder Name"
                            placeholder="Name"
                            error={errors?.card_holder_name?.message}
                          />
                        )}
                      />
                    </div>
                    <PaymentForm
                      ref={confirmButtonRef}
                      type="upgradeplan"
                      actionFn={upgradePlanMutate}
                    />
                  </div>
                </div>
                <div className="w--full  ">
                  <Button
                    variant="primary"
                    color="white"
                    btnClasses="btn"
                    onClick={() => setshow(true)}
                  >
                    {upgradePlanLoad ? 'Please wait...' : 'Proceed for payment'}
                  </Button>
                </div>
              </>
            )}
            {tab === 1 && (
              <div className="w--full d--flex justify-content--between h--full flex--column">
                <div className="w--full h-min--230 overflow--auto h-max--230 m-b--xl">
                  {getcardlisting?.length > 0
                    ? getcardlisting?.map((item) => {
                        const isChecked = item?.card_id === selectedCard;
                        return (
                          <label
                            htmlFor={item?.card_id}
                            key={item?.card_id}
                            className="label--control w--full font--sm font--500 m-b--xs d--flex w--full d--flex align-items--center justify-content--between h-min--40 border-bottom--black-100 p-l--xs p-r--xs"
                          >
                            **** **** **** {item.last4}
                            <input
                              id={item?.card_id}
                              className="form--control"
                              type="radio"
                              name="cards"
                              value={item?.card_id}
                              onChange={(e) => {
                                setSelectedCard(e.target.value);
                              }}
                              checked={isChecked}
                            />
                          </label>
                        );
                      })
                    : 'No saved card found!'}
                </div>
                <ConfirmationModal
                  show={show}
                  setshow={setshow}
                  onConfirm={() => {
                    handleSubmitForm();
                    setshow(false);
                  }}
                />
                <div className="w--full ">
                  <Button
                    variant="primary"
                    color="white"
                    btnClasses="btn"
                    type="button"
                    onClick={() => setshow(true)}
                  >
                    {upgradePlanLoad ? 'Please wait...' : 'Proceed for payment'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentFormModal;
