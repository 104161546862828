import React, { useEffect } from 'react';
import { router } from './router/web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { Auth } from './hooks/useAuth';
import { Alert } from './hooks/useAlert';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels,
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});
if (process.env.REACT_APP_MODE === 'PROD') {
  console.log = () => {}; // Override console.log in production
}

export default function App() {
  return (
    <Alert>
      <Auth>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </Auth>
    </Alert>
  );
}
