import { useMutation } from '@tanstack/react-query';
import { didDetails } from '../../api';
import { useAlert } from '../useAlert';

export default function useDIDDetails() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: didDetails,
    mutationKey: ['didDetails'],
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
