import { apiClient } from './axios';
import { routes } from './routes';

export const login = (value) => {
  return apiClient({
    method: routes.LOGIN.METHOD,
    url: routes.LOGIN.URL,
    data: value,
  });
};

export const addcard = (value) => {
  return apiClient({
    method: routes.ADD_CARD.METHOD,
    url: routes.ADD_CARD.URL,
    data: value,
  });
};
export const deleteCard = (value) => {
  return apiClient({
    method: routes.DELETE_CARD.METHOD,
    url: routes.DELETE_CARD.URL,
    data: value,
  });
};

export const logout = (value) => {
  return apiClient({
    method: routes.LOGOUT.METHOD,
    url: routes.LOGOUT.URL,
    data: value,
  });
};

export const sendOtp = (value) => {
  return apiClient({
    method: routes.SEND_OTP.METHOD,
    url: routes.SEND_OTP.URL,
    data: value,
  });
};
export const resendotp = (value) => {
  return apiClient({
    method: routes.RESEND_OTP.METHOD,
    url: routes.RESEND_OTP.URL,
    data: value,
  });
};

export const portingList = (value) => {
  return apiClient({
    method: routes.PORTING_LIST.METHOD,
    url: routes.PORTING_LIST.URL,
    data: value,
  });
};
export const portingRequest = (value) => {
  return apiClient({
    method: routes.PORTING_REQUEST.METHOD,
    url: routes.PORTING_REQUEST.URL,
    data: value,
  });
};
export const getPortingRequestData = (payload) => {
  return apiClient({
    method: routes.GET_PORTING_REQUEST_DATA.METHOD,
    url: `${routes.GET_PORTING_REQUEST_DATA.URL}/${payload?.id}`,
  });
};
export const updatePortingRequest = (data) => {
  return apiClient({
    method: routes.UPDATE_PORTING_REQUEST.METHOD,
    url: `${routes.UPDATE_PORTING_REQUEST.URL}/${data?.id}`,
    data,
  });
};

export const getPlans = (value) => {
  return apiClient({
    method: routes.GET_PLANS.METHOD,
    url: routes.GET_PLANS.URL,
    data: value,
  });
};

export const verifyOtp = (value) => {
  return apiClient({
    method: routes.VERIFY_OTP.METHOD,
    url: routes.VERIFY_OTP.URL,
    data: value,
  });
};

export const getPlan = (value) => {
  return apiClient({
    method: routes.POST_PLAN.METHOD,
    url: routes.POST_PLAN.URL,
    data: value,
  });
};

export const setPassword = (value) => {
  return apiClient({
    method: routes.SET_PASSWORD.METHOD,
    url: routes.SET_PASSWORD.URL,
    data: value,
  });
};
export const forgotPassword = (value) => {
  return apiClient({
    method: routes.FORGOT_PASSWORD.METHOD,
    url: routes.FORGOT_PASSWORD.URL,
    data: value,
  });
};

export const didList = (value) => {
  return apiClient({
    method: routes.DID_LIST.METHOD,
    url: routes.DID_LIST.URL,
    data: value,
  });
};

export const didCountryList = (value) => {
  return apiClient({
    method: routes.DID_COUNTRY_LIST.METHOD,
    url: routes.DID_COUNTRY_LIST.URL,
    data: value,
  });
};
export const didUSStateList = () => {
  return apiClient({
    method: routes.DID_US_STATE_LIST.METHOD,
    url: routes.DID_US_STATE_LIST.URL,
  });
};
export const didStateCityList = (stateId = '') => {
  return apiClient({
    method: routes.DID_STATE_CITY_LIST.METHOD,
    url: `${routes.DID_STATE_CITY_LIST.URL}/${stateId}`,
  });
};
export const didNumberList = (cityId = '') => {
  return apiClient({
    method: routes.DID_NUMBER_LIST.METHOD,
    url: `${routes.DID_NUMBER_LIST.URL}/${cityId}`,
  });
};
export const didDetails = (did = '') => {
  return apiClient({
    method: routes.DID_DETAILS.METHOD,
    url: `${routes.DID_DETAILS.URL}`,
  });
};
export const didNumberPurchase = (value) => {
  return apiClient({
    method: routes.DID_NUMBER_PURCHASE.METHOD,
    url: `${routes.DID_NUMBER_PURCHASE.URL}`,
    data: value,
  });
};
export const didNumberPurchaseNew = (value) => {
  return apiClient({
    method: routes.DID_NUMBER_PURCHASE_NEW.METHOD,
    url: `${routes.DID_NUMBER_PURCHASE_NEW.URL}`,
    data: value,
  });
};
export const didRegionList = (value) => {
  return apiClient({
    method: routes.DID_REGION_LIST.METHOD,
    url: `${routes.DID_REGION_LIST.URL}/${value}`,
  });
};
export const didNumbersList = (value) => {
  return apiClient({
    method: routes.DID_NUMBERS_LIST.METHOD,
    url: `${routes.DID_NUMBERS_LIST.URL}/${value}`,
  });
};
export const checkFreeDID = () => {
  return apiClient({
    method: routes.CHECK_FREE_DID.METHOD,
    url: routes.CHECK_FREE_DID.URL,
  });
};

export const getUserDetails = (value) => {
  return apiClient({
    method: routes.USER_DEAILS.METHOD,
    url: routes.USER_DEAILS.URL,
    data: value,
  });
};

export const getMemberListing = (value) => {
  return apiClient({
    method: routes.MEMBER_LISTING.METHOD,
    url: routes.MEMBER_LISTING.URL,
    data: value,
  });
};

export const getContactListing = (value) => {
  return apiClient({
    method: routes.CONTACT_LIST.METHOD,
    url: routes.CONTACT_LIST.URL,
    data: value,
  });
};

export const memberSignup = (value) => {
  return apiClient({
    method: routes.MEMBER_SIGNUP.METHOD,
    url: routes.MEMBER_SIGNUP.URL,
    data: value,
  });
};

export const userSendAccountVerificationLink = (value) => {
  return apiClient({
    method: routes.USER_SEND_ACCOUNT_VERIFICATION_LINK.METHOD,
    url: routes.USER_SEND_ACCOUNT_VERIFICATION_LINK.URL,
    data: value,
  });
};

export const userVerifyAccount = (value) => {
  return apiClient({
    method: routes.USER_VERIFY_ACCOUNT.METHOD,
    url: routes.USER_VERIFY_ACCOUNT.URL,
    data: value,
  });
};
export const resetPassword = (value) => {
  return apiClient({
    method: routes.RESET_PASSWORD.METHOD,
    url: routes.RESET_PASSWORD.URL,
    data: value,
  });
};
export const memberActivate = (value) => {
  return apiClient({
    method: routes.MEMBER_ACTIVATE.METHOD,
    url: routes.MEMBER_ACTIVATE.URL,
    data: value,
  });
};

export const memberDeactivate = (value) => {
  return apiClient({
    method: routes.MEMBER_DEACTIVATE.METHOD,
    url: routes.MEMBER_DEACTIVATE.URL,
    data: value,
  });
};
export const agencyActivate = (value) => {
  return apiClient({
    method: routes.AGENCY_ACTIVATE.METHOD,
    url: routes.AGENCY_ACTIVATE.URL,
    data: value,
  });
};

export const agencyDeactivate = (value) => {
  return apiClient({
    method: routes.AGENCY_DEACTIVATE.METHOD,
    url: routes.AGENCY_DEACTIVATE.URL,
    data: value,
  });
};
export const createContact = (value) => {
  return apiClient({
    method: routes.CONTACT_CREATE.METHOD,
    url: routes.CONTACT_CREATE.URL,
    data: value,
  });
};

export const deleteContact = (value) => {
  return apiClient({
    method: routes.CONTACT_DELETE.METHOD,
    url: routes.CONTACT_DELETE.URL,
    data: value,
  });
};

export const memberDelete = (value) => {
  return apiClient({
    method: routes.MEMBER_DELETE.METHOD,
    url: routes.MEMBER_DELETE.URL,
    data: value,
  });
};

export const agencyDelete = (value) => {
  return apiClient({
    method: routes.AGENCY_DELETE.METHOD,
    url: routes.AGENCY_DELETE.URL,
    data: value,
  });
};

export const memberAssignUnassignDid = (value) => {
  return apiClient({
    method: routes.MEMBER_ASSIGN_UNASSIGN_DID.METHOD,
    url: routes.MEMBER_ASSIGN_UNASSIGN_DID.URL,
    data: value,
  });
};

export const memberSetDefaultDid = (value) => {
  return apiClient({
    method: routes.MEMBER_DEFAULT_DID.METHOD,
    url: routes.MEMBER_DEFAULT_DID.URL,
    data: value,
  });
};

export const setForwarding = (value) => {
  return apiClient({
    method: routes.SET_FORWARDING.METHOD,
    url: routes.SET_FORWARDING.URL,
    data: value,
  });
};
export const setSettings = (value) => {
  return apiClient({
    method: routes.SET_SETTINGS.METHOD,
    url: routes.SET_SETTINGS.URL,
    data: value,
  });
};
export const getSettings = (value) => {
  return apiClient({
    method: routes.GET_SETTINGS.METHOD,
    url: routes.GET_SETTINGS.URL,
    data: value,
  });
};

export const removeForwarding = (value) => {
  return apiClient({
    method: routes.REMOVE_FORWARDING.METHOD,
    url: routes.REMOVE_FORWARDING.URL,
    data: value,
  });
};

export const callHistory = (value) => {
  return apiClient({
    method: routes.CALL_HISTORY.METHOD,
    url: routes.CALL_HISTORY.URL,
    data: value,
  });
};
export const particularCallHistory = (uuid = '') => {
  return apiClient({
    method: routes.CALL_HISTORY.METHOD,
    url: `${routes.CALL_HISTORY.URL}/${uuid}`,
  });
};
export const callStatus = (value) => {
  return apiClient({
    method: routes.CALL_STATUS.METHOD,
    url: routes.CALL_STATUS.URL,
    data: value,
  });
};

export const getDIDDetails = (value) => {
  return apiClient({
    method: routes.GET_DID_DETAILS.METHOD,
    url: routes.GET_DID_DETAILS.URL,
    data: value,
  });
};

export const signup = (value) => {
  return apiClient({
    method: routes.SIGN_UP.METHOD,
    url: routes.SIGN_UP.URL,
    data: value,
  });
};

export const upgradeplan = (value) => {
  return apiClient({
    method: routes.UPGRADE_PLAN.METHOD,
    url: routes.UPGRADE_PLAN.URL,
    data: value,
  });
};

export const userCredentials = () => {
  return apiClient({
    method: routes.USER_CREDENTIALS.METHOD,
    url: routes.USER_CREDENTIALS.URL,
  });
};

export const uploadMedia = (value) => {
  return apiClient({
    headers: { 'Content-Type': 'multipart/form-data' },
    baseURL: process.env.REACT_APP_MEDIA_URL,
    method: routes.UPLOAD_MEDIA.METHOD,
    url: routes.UPLOAD_MEDIA.URL,
    data: value,
  });
};

export const getMedia = ({ parent_uuid = '', type = '', filename = '' }) => {
  return apiClient({
    baseURL: process.env.REACT_APP_MEDIA_URL,
    method: routes.GET_MEDIA.METHOD,
    url: `${routes.GET_MEDIA.URL}/${parent_uuid}/${type}?filename=${filename}`,
  });
};

export const deleteMedia = ({ parent_uuid = '', type = '', filename = '' }) => {
  return apiClient({
    baseURL: process.env.REACT_APP_MEDIA_URL,
    method: routes.DELETE_MEDIA.METHOD,
    url: `${routes.DELETE_MEDIA.URL}/${parent_uuid}/${type}?filename=${filename}`,
  });
};

export const updateProfile = (values) => {
  return apiClient({
    method: routes.PROFILE_UPDATE.METHOD,
    url: routes.PROFILE_UPDATE.URL,
    data: values,
  });
};
export const deleteProfilePic = (values) => {
  return apiClient({
    method: routes.PROFILE_PIC_DELETE.METHOD,
    url: routes.PROFILE_PIC_DELETE.URL,
    data: values,
  });
};

export const dashboardStats = (values) => {
  return apiClient({
    method: routes.DASHBOARD_STATS.METHOD,
    url: routes.DASHBOARD_STATS.URL,
    data: values,
  });
};

export const checkIfContactExist = (values) => {
  return apiClient({
    method: routes.CHECK_CONTACT.METHOD,
    url: routes.CHECK_CONTACT.URL,
    data: values,
  });
};

export const addAgency = (values) => {
  return apiClient({
    method: routes.ADD_AGENCY.METHOD,
    url: routes.ADD_AGENCY.URL,
    data: values,
  });
};

export const agencyList = (values) => {
  return apiClient({
    method: routes.AGENCY_LIST.METHOD,
    url: routes.AGENCY_LIST.URL,
    data: values,
  });
};

export const agencyMemberList = (values) => {
  return apiClient({
    method: routes.AGENCY_MEMBER_LIST.METHOD,
    url: routes.AGENCY_MEMBER_LIST.URL,
    data: values,
  });
};
export const unassignAgencyDid = (values) => {
  return apiClient({
    method: routes.UNASSIGN_AGENCY_DID.METHOD,
    url: routes.UNASSIGN_AGENCY_DID.URL,
    data: values,
  });
};

// Ring Group Apis

export const ringGroupList = (values) => {
  return apiClient({
    method: routes.RING_GROUP_LIST.METHOD,
    url: routes.RING_GROUP_LIST.URL,
    data: values,
  });
};

export const ringGroupCreate = (values) => {
  return apiClient({
    method: routes.RING_GROUP_CREATE.METHOD,
    url: routes.RING_GROUP_CREATE.URL,
    data: values,
  });
};

export const ringGroupEdit = (values) => {
  return apiClient({
    method: routes.RING_GROUP_EDIT.METHOD,
    url: routes.RING_GROUP_EDIT.URL,
    data: values,
  });
};

export const ringGroupDelete = (values) => {
  return apiClient({
    method: routes.RING_GROUP_DELETE.METHOD,
    url: routes.RING_GROUP_DELETE.URL,
    data: values,
  });
};

// Greetings Apis

export const greetingList = (values) => {
  return apiClient({
    method: routes.GREETING_LIST.METHOD,
    url: routes.GREETING_LIST.URL,
    data: values,
  });
};

export const greetingCreate = (values) => {
  return apiClient({
    method: routes.GREETING_CREATE.METHOD,
    url: routes.GREETING_CREATE.URL,
    data: values,
  });
};

export const greetingEdit = (values) => {
  return apiClient({
    method: routes.GREETING_EDIT.METHOD,
    url: routes.GREETING_EDIT.URL,
    data: values,
  });
};

export const greetingDelete = (values) => {
  return apiClient({
    method: routes.GREETING_DELETE.METHOD,
    url: routes.GREETING_DELETE.URL,
    data: values,
  });
};

//IVR Apis

export const ivrList = (values) => {
  return apiClient({
    method: routes.IVR_LIST.METHOD,
    url: routes.IVR_LIST.URL,
    data: values,
  });
};

export const ivrUpsert = (values) => {
  return apiClient({
    method: routes.IVR_UPSERT.METHOD,
    url: routes.IVR_UPSERT.URL,
    data: values,
  });
};

export const ivrDelete = (values) => {
  return apiClient({
    method: routes.IVR_DELETE.METHOD,
    url: routes.IVR_DELETE.URL,
    data: values,
  });
};

export const agentList = (values) => {
  return apiClient({
    method: routes.AGENT_LIST.METHOD,
    url: routes.AGENT_LIST.URL,
    data: values,
  });
};

export const agentSignup = (values) => {
  return apiClient({
    method: routes.AGENT_SIGNUP.METHOD,
    url: routes.AGENT_SIGNUP.URL,
    data: values,
  });
};

export const agentDelete = (values) => {
  return apiClient({
    method: routes.AGENT_DELETE.METHOD,
    url: routes.AGENT_DELETE.URL,
    data: values,
  });
};
export const getEmployeAgent = (values) => {
  return apiClient({
    method: routes.GET_EMPLOYEE_AGENT.METHOD,
    url: routes.GET_EMPLOYEE_AGENT.URL,
    data: values,
  });
};
export const employeeAgentList = (values) => {
  return apiClient({
    method: routes.EMPLOYEE_AGENT_LIST.METHOD,
    url: routes.EMPLOYEE_AGENT_LIST.URL,
    data: values,
  });
};
export const billingList = (values) => {
  return apiClient({
    method: routes.TRANSACTION_LIST.METHOD,
    url: routes.TRANSACTION_LIST.URL,
    data: values,
  });
};
export const payment = (values) => {
  return apiClient({
    method: routes.PAYMENT.METHOD,
    url: routes.PAYMENT.URL,
    data: values,
  });
};
export const getSavedCards = (values) => {
  return apiClient({
    method: routes.GET_SAVED_CARDS.METHOD,
    url: routes.GET_SAVED_CARDS.URL,
    data: values,
  });
};
export const getZohoTokenURL = () => {
  return apiClient({
    method: routes.ZOHO_GRANT_TOKEN.METHOD,
    url: routes.ZOHO_GRANT_TOKEN.URL,
  });
};
export const removeZoho = () => {
  return apiClient({
    method: routes.REMOVE_ZOHO.METHOD,
    url: routes.REMOVE_ZOHO.URL,
  });
};
export const setZohoToken = (values) => {
  return apiClient({
    method: routes.ZOHO_SET_TOKEN.METHOD,
    url: routes.ZOHO_SET_TOKEN.URL,
    data: values,
  });
};
export const identityList = (values) => {
  return apiClient({
    method: routes.IDENTITY_LIST.METHOD,
    url: routes.IDENTITY_LIST.URL,
    data: values,
  });
};
export const identityCreate = (values) => {
  return apiClient({
    method: routes.IDENTITY_CREATE.METHOD,
    url: routes.IDENTITY_CREATE.URL,
    data: values,
  });
};
export const identityDelete = (identityId = '') => {
  return apiClient({
    method: routes.IDENTITY_DELETE.METHOD,
    url: `${routes.IDENTITY_DELETE.URL}/${identityId}`,
  });
};
export const identityEdit = (values) => {
  return apiClient({
    method: routes.IDENTITY_EDIT.METHOD,
    url: routes.IDENTITY_EDIT.URL,
    data: values,
  });
};

export const smsNumberListing = (values) => {
  return apiClient({
    method: routes.SMS_NUMBER_LIST.METHOD,
    url: routes.SMS_NUMBER_LIST.URL,
    data: values,
  });
};
export const sendSMS = (values) => {
  return apiClient({
    method: routes.SEND_SMS.METHOD,
    url: routes.SEND_SMS.URL,
    data: values,
  });
};
export const getSMSListing = (values) => {
  return apiClient({
    method: routes.GET_SMS_LISTING.METHOD,
    url: routes.GET_SMS_LISTING.URL,
    data: values,
  });
};

// reports apis
export const reportsOverviewStats = (data) => {
  return apiClient({
    method: routes.REPORTS_OVERVIEW_STATS.METHOD,
    url: routes.REPORTS_OVERVIEW_STATS.URL,
    data,
  });
};
export const reportsCallList = (data) => {
  return apiClient({
    method: routes.REPORTS_CALL_LIST.METHOD,
    url: routes.REPORTS_CALL_LIST.URL,
    data,
  });
};
export const particularReportsCallList = (uuid = '') => {
  return apiClient({
    method: routes.REPORTS_CALL_LIST.METHOD,
    url: `${routes.REPORTS_CALL_LIST.URL}/${uuid}`,
  });
};
export const reportsCallRecordingList = (data) => {
  return apiClient({
    method: routes.REPORTS_CALL_RECORDING_LIST.METHOD,
    url: routes.REPORTS_CALL_RECORDING_LIST.URL,
    data,
  });
};
export const particularReportsCallRecordingList = (uuid = '') => {
  return apiClient({
    method: routes.REPORTS_CALL_RECORDING_LIST.METHOD,
    url: `${routes.REPORTS_CALL_RECORDING_LIST.URL}/${uuid}`,
  });
};
export const reportsVoicemailList = (data) => {
  return apiClient({
    method: routes.REPORTS_VOICEMAIL_LIST.METHOD,
    url: routes.REPORTS_VOICEMAIL_LIST.URL,
    data,
  });
};
export const unseenVoicemailList = (data) => {
  return apiClient({
    method: routes.UNSEEN_VOICEMAIL_LIST.METHOD,
    url: routes.UNSEEN_VOICEMAIL_LIST.URL,
    data,
  });
};
export const particularReportsVoicemailList = (uuid = '') => {
  return apiClient({
    method: routes.REPORTS_VOICEMAIL_LIST.METHOD,
    url: `${routes.REPORTS_VOICEMAIL_LIST.URL}/${uuid}`,
  });
};
export const reportsSMSList = (data) => {
  return apiClient({
    method: routes.REPORTS_SMS_LIST.METHOD,
    url: routes.REPORTS_SMS_LIST.URL,
    data,
  });
};
export const reportsEmployeesList = (data) => {
  return apiClient({
    method: routes.REPORTS_EMPLOYEES_LIST.METHOD,
    url: routes.REPORTS_EMPLOYEES_LIST.URL,
    data,
  });
};
export const reportsAgentsList = (data) => {
  return apiClient({
    method: routes.REPORTS_AGENTS_LIST.METHOD,
    url: routes.REPORTS_AGENTS_LIST.URL,
    data,
  });
};
export const reportsNumberStats = () => {
  return apiClient({
    method: routes.REPORTS_NUMBER_STATS.METHOD,
    url: routes.REPORTS_NUMBER_STATS.URL,
  });
};
export const reportsNumberList = (data) => {
  return apiClient({
    method: routes.REPORTS_NUMBER_LIST.METHOD,
    url: routes.REPORTS_NUMBER_LIST.URL,
    data,
  });
};
export const reportsRecordingSeen = (data) => {
  return apiClient({
    method: routes.REPORTS_RECORDING_SEEN.METHOD,
    url: routes.REPORTS_RECORDING_SEEN.URL,
    data,
  });
};
export const getDetails = (data) => {
  return apiClient({
    method: routes.GET_DETAILS.METHOD,
    url: routes.GET_DETAILS.URL,
    data,
  });
};
export const getAllNumberDetails = (data) => {
  return apiClient({
    method: routes.ALL_NUMBER_DETAILS.METHOD,
    url: routes.ALL_NUMBER_DETAILS.URL,
    data,
  });
};
export const support = (data) => {
  return apiClient({
    method: routes.SUPPORT.METHOD,
    url: routes.SUPPORT.URL,
    data,
  });
};
export const addFund = (data) => {
  return apiClient({
    method: routes.ADD_FUND.METHOD,
    url: routes.ADD_FUND.URL,
    data,
  });
};
export const setAsDefault = (data) => {
  return apiClient({
    method: routes.SET_AS_DEFAULT.METHOD,
    url: routes.SET_AS_DEFAULT.URL,
    data,
  });
};
export const getNameNumberFilter = (data) => {
  return apiClient({
    method: routes.NAME_NUMBER_FILTER.METHOD,
    url: routes.NAME_NUMBER_FILTER.URL,
    data,
  });
};
export const unAssignDid = ({ uuid, ...rest }) => {
  return apiClient({
    method: 'POST',
    url: `user/${uuid}/unassign-did`,
    data: rest,
  });
};
export const assignDID = ({ uuid, ...rest }) => {
  return apiClient({
    method: 'POST',
    url: `user/${uuid}/assign-did`,
    data: rest,
  });
};
export const assignDIDList = (uuid) => {
  return apiClient({
    method: 'GET',
    url: `user/${uuid}/assign-did`,
  });
};
export const availableDidList = (data) => {
  return apiClient({
    method: 'POST',
    url: `did/available-listing`,
    data,
  });
};
export const employeeAgentStats = (uuid) => {
  return apiClient({
    method: routes.EMPLOYEE_AGENT_STATS.METHOD,
    url: `${routes.EMPLOYEE_AGENT_STATS.URL}/${uuid}`,
  });
};

export const dropdownEmployeeListing = (data) => {
  return apiClient({
    method: routes.DROPDOWN_EMPLOYEE_LISTING.METHOD,
    url: routes.DROPDOWN_EMPLOYEE_LISTING.URL,
    data,
  });
};
export const dropdownAgentListing = (data) => {
  return apiClient({
    method: routes.DROPDOWN_AGENT_LISTING.METHOD,
    url: routes.DROPDOWN_AGENT_LISTING.URL,
    data,
  });
};
export const unseenVoiceMailCount = (data) => {
  return apiClient({
    method: routes.UNSEEN_VOICEMAIL_COUNT.METHOD,
    url: routes.UNSEEN_VOICEMAIL_COUNT.URL,
    data,
  });
};
export const failedCallScreenshot = (data) => {
  return apiClient({
    method: routes.FAILED_CALL_SCREENSHOT.METHOD,
    url: routes.FAILED_CALL_SCREENSHOT.URL,
    data,
  });
};
export const smsSocketCount = (data) => {
  return apiClient({
    method: routes.SMS_SOCKET_COUNT.METHOD,
    url: routes.SMS_SOCKET_COUNT.URL,
    data,
  });
};

export const contactActivityList = (data) => {
  return apiClient({
    method: routes.CONTACT_ACTIVITY_LIST.METHOD,
    url: routes.CONTACT_ACTIVITY_LIST.URL,
    data,
  });
};
export const setPortalAccess = (data) => {
  return apiClient({
    method: routes.SET_PORTAL_ACCESS.METHOD,
    url: routes.SET_PORTAL_ACCESS.URL,
    data,
  });
};
