import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import FormInput from '../../../comopnents/FormInput';
import useIcons from '../../../assets/icons/useIcons';
import { getSMSListing } from '../../../api';
import ChatListItem from './ChatListItem';
import Spinner from '../../../comopnents/Spinner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SipSocketContext } from '../../../hooks/useSip/SipSocketContext';
import CustomSingleDropdown from '../../../comopnents/CustomSingleDropdown';
import { capitalizeWords } from '../../../helpers/functions';

const ChatList = ({
  smsDefaultDID,
  setSMSDefaultDid = () => null,
  didListing = [],
  activeChat,
  setActiveChat = () => null,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const { showAlert } = useAlert();
  const { SearchIcon } = useIcons();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { socket: { unreadInboundCount = [] } = {} } =
    useContext(SipSocketContext);

  const { data: smsNumberList = [], isLoading } = useQuery({
    queryKey: [`getSMSListing`, smsDefaultDID],
    queryFn: ({ queryKey }) =>
      getSMSListing({ caller_id: queryKey?.[1] ?? '' }),
    enabled: Boolean(smsDefaultDID),
    select: (data) => data?.data?.data ?? [],
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });

  const RenderDIDItem = ({ item }) => {
    const unreadCounts = unreadInboundCount?.filter(
      (d) => item?.did_number === d?.didNumber,
    );
    const totalCount =
      unreadCounts.reduce((item, acc) => {
        const count = acc?.count + item;
        return count;
      }, 0) || 0;
    const assignedUserData =
      item?.get_assigned_users?.length > 0
        ? item?.get_assigned_users?.[0]
        : null;
    const assignedName = assignedUserData
      ? `${capitalizeWords(
          assignedUserData?.first_name,
          ' ',
        )} ${capitalizeWords(assignedUserData?.last_name || '', ' ')}`
      : '';
    return (
      <div className="d--flex align-items--center justify-content--between c--pointer">
        {assignedName && (
          <div className="d--flex gap--sm">
            <div>{assignedName}</div>
            <div className="font--xs">({item?.did_number})</div>
          </div>
        )}
        {!assignedName && <div>{item?.did_number}</div>}
        {totalCount && totalCount > 0 ? (
          <div className="w--full font--xs font--300 bg--red text--white w-min--20 w-max--20 h-min--20 h-max--20 radius--full d--flex align-items--center justify-content--center">
            {totalCount}
          </div>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    if (searchParams) {
      const chatId = searchParams.get('chatId') || '';
      const refData =
        smsNumberList?.find(
          (item) => item?.caller_number?.toString().replace('+', '') === chatId,
        ) || null;
      if (refData) {
        const params = new URLSearchParams(searchParams);

        // Update the desired parameter or add a new one
        params.set('chatId', refData?.uuid);
        setSearchParams(params.toString());
        setActiveChat(refData);
      }
    }
  }, [searchParams, smsNumberList]);

  function getCustomLabel(value, options = [], valueKey = '') {
    const item =
      options?.filter((opt) => `${opt?.[valueKey]}` === `${value}`)?.[0] ??
      null;
    const assignedUserData =
      item?.get_assigned_users?.length > 0
        ? item?.get_assigned_users?.[0]
        : null;
    const assignedName = assignedUserData
      ? `${capitalizeWords(
          assignedUserData?.first_name,
          ' ',
        )} ${capitalizeWords(assignedUserData?.last_name || '', ' ')} (${
          item?.[valueKey]
        })`
      : item?.[valueKey];
    return assignedName;
  }
  return (
    <div className="chatListSec w--full w-max--300 bg--white box-shadow--xs d--flex flex--column radius--sm">
      <div className="font--md font--600 gap--sm d--flex align-items--center justify-content--between p--md">
        <div className="w--full ">
          <CustomSingleDropdown
            optionList={didListing || []}
            value={smsDefaultDID}
            valueKey="did_number"
            labelKey="did_number"
            onChange={(val) => {
              setSearchParams('');
              const refDidData = didListing?.find(
                (item) => item?.did_number === val,
              );
              setSMSDefaultDid(val);
              if (refDidData?.uuid) {
                navigate(`/messages?didId=${refDidData?.uuid}`);
              } else {
                navigate(`/messages`);
              }
              setActiveChat('');
            }}
            RenderOptionItem={RenderDIDItem}
            showCustomLabel={true}
            getCustomLabel={getCustomLabel}
          />
        </div>
      </div>
      <div className="w--full  p-l--md p-r--md d--flex gap--sm ">
        <div className="w--full position--relative searchFilter">
          <FormInput
            name="search"
            placeholder="Search"
            height="32"
            paddingLeft="2xl"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span className="inputIcon position--absolute text--grey-600">
            <SearchIcon width={18} height={18} />
          </span>
        </div>
      </div>
      <div className="w--full h--full overflow--auto chatList p--md d--flex flex--column">
        {isLoading && smsDefaultDID && (
          <div className="w--full d--flex align-items--center justify-content--center">
            <Spinner />
          </div>
        )}
        {!smsDefaultDID && (
          <div className="w--full d--flex align-items--center justify-content--center">
            No DID is selected
          </div>
        )}
        {smsNumberList && smsNumberList.length > 0 ? (
          smsNumberList.map((chat, index) => {
            if (searchValue) {
              if (
                chat?.to?.toLowerCase()?.includes(searchValue?.toLowerCase())
              ) {
                return (
                  <ChatListItem
                    key={chat?.uuid}
                    chatData={chat}
                    activeChat={activeChat}
                    setActiveChat={setActiveChat}
                    smsDefaultDID={smsDefaultDID}
                  />
                );
              }
            } else {
              return (
                <ChatListItem
                  key={chat?.uuid}
                  chatData={chat}
                  activeChat={activeChat}
                  setActiveChat={setActiveChat}
                  smsDefaultDID={smsDefaultDID}
                />
              );
            }
          })
        ) : (
          <>
            {!isLoading && (
              <div className="w--full d--flex align-items--center justify-content--center">
                No chat available
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChatList;
