import { useQuery } from '@tanstack/react-query';
import { getUserDetails } from '../../api';
import { useAlert } from '../useAlert';
import { useAuth } from '../useAuth';
import { getToken } from '../../helpers/functions';

export default function useUserDetails(sipData = {}) {
  const { showAlert } = useAlert();
  const token = getToken();
  const { setUserData, clearUser } = useAuth();
  const userDetailsRequest = useQuery({
    queryFn: getUserDetails,
    queryKey: ['getUserDetails'],
    onSuccess: async ({ data }) => {
      setUserData(data?.data);

      if (
        sipData &&
        (!sipData?._status || sipData?._status !== 'registered') &&
        Object.keys(sipData).includes('_start') &&
        sipData?.readyForConnection
      ) {
        if (data?.data?.status === 'online') {
          sipData?._start();
        } else {
          sipData?._stop();
        }
      }
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
      clearUser();
    },
    enabled: (token ? true : false) && sipData?.readyForConnection,
  });
  return { ...userDetailsRequest };
}
